import React, { useEffect, useState } from 'react';
import LoginForm from 'components/Authentication/AuthenticationLoginForm';
import ImagesLogin from 'components/ImagesSlider/ImagesLogin';
import { AuthContext } from 'contexts/Auth/AuthContext';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useHistory, useLocation } from 'react-router-dom';
import { LoginFormSSO } from 'components/Authentication/AuthenticationLoginForm/LoginSSO/LoginSSO';
import HeaderSignForm from 'components/User/HeaderSignForm/HeaderSignForm';
import { useTranslation } from 'react-i18next';
const Logo = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo.svg`);
import './styles.scss';

export interface LoginPageProps {
    isAuthStorage: boolean;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
    defaultPassword?: boolean;

}

const LoginPage: React.FC<LoginPageProps> = ({ isAuthStorage, setAuthStorage, defaultPassword }) => {
    const authCtx = React.useContext(AuthContext);
    const [whiteLabel, setWhiteLabel] = useState<any>();
    const search = useLocation()?.search;
    const auth = new URLSearchParams(search).get('auth');
    const history = useHistory();
    const { t: translator } = useTranslation();
    const [errorMsg, setErrorMsg] = React.useState<string>();

    useDocumentTitle('');
    useEffect(() => {
        if (authCtx?.config) {
            console.log(authCtx?.config);
            setWhiteLabel(authCtx?.config?.find(item => item?.page_name == "login"));
        }
    }, [authCtx]);

    useEffect(() => {
        if (!auth) {
            history.push("/login?auth=otp", { replace: true });
        }
    }, []);
    return (
        <div className="min-h-screen flex loginPage">
            <div className="w-full max-w-sm 2xl:max-w-md  ">
                <ImagesLogin whiteLabel={whiteLabel} />
            </div>
            <div className=" w-full flex  flex-col justify-between  items-center" >
                <div className="flex-1 flex flex-col pb-12 px-4 sm:px-6  lg:px-20 xl:px-24 justify-center">
                    <div className="w-full overflow-hidden">
                        <div className="mx-auto w-full max-w-sm md:w-w_115 md:max-w-none">
                            <img className='mx-auto max-h-[148px] mb-6' src={whiteLabel?.data?.web?.logo || Logo.default} alt="logo" />
                            <HeaderSignForm
                                title={whiteLabel?.data?.web?.title || translator('SIGN_IN_TO')}
                                subTitle={auth === 'otp' ? '' : "Enter your details below"}
                                errorMsg={errorMsg}
                                textInside="in"
                            />
                            {auth === 'otp' ? <LoginFormSSO setAuthStorage={setAuthStorage} whiteLabel={whiteLabel} />
                                : <LoginForm setAuthStorage={setAuthStorage} defaultPassword={defaultPassword} whiteLabel={whiteLabel} errorMsg={errorMsg} setErrorMsg={setErrorMsg} />}
                        </div>
                    </div>
                </div>
                <p className="text-center text-sm font-normal my-10 text-gray-700">
                    Powered by OOOLAB’s <a href="http://thelearningos.com/" target="_blank" className="text-primary-500 font-semibold">LearningOS</a>
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
