import { CourseOverview } from './CourseOverview/CourseOverview';
import './CourseGradebook.scss';
import { GradebookList } from './GradebookList/GradebookList';
import { GradebookDetails } from './GradebookDetails/GradebookDetails';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';

export const CourseGradebook = () => {
    const query = useQueryParamsURL();

    const learnerId = query.get('learnerId');
    const learningGroupId = query.get('learningGroupId');

    return (
        <div className="courseGradebook">
            {learnerId || learningGroupId ? (
                <GradebookDetails />
            ) : (
                <>
                    <CourseOverview />
                    <GradebookList />
                </>
            )}
        </div>
    );
};
