import { parseParams } from 'utils/commonFun';
import httpClient, { RestfulService } from './restful.service';
import { HOST_URL } from 'constant/api.const';

const getCourse = (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}`);
};
const getCourseSettings = (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}/settings`);
};
const createCourse = (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses`, payload);
};
const updateCourse = (payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}`, payload);
};

const deleteCourse = (payload) => {
    return RestfulService.deleteApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}`);
};

const checkHasActiveLearner = (payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload?.id}/precheck`);
};

const enrollPeople = (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/enroll`, payload);
};

const getEnrollments = (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/enrollments`, payload);
};

const inviteInstructor = (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/invite-instructor`, payload);
};

const getInstructorEnrolled = (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/instructors`, payload);
};

const getEnrollmentDetail = (workspaceId, courseId, enrollmentId) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}`);
};

const getMemberOfEnrollment = (workspaceId, courseId, enrollmentId, payload) => {
    return RestfulService.getApi(
        HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/members`,
        payload
    );
};

const updateEnrollmentDetail = (workspaceId, courseId, enrollmentId, payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}`, payload);
};

const updateDataInstructorInvited = (workspaceId, courseId, instructorId, payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/instructors/${instructorId}`, payload);
};

const updateCourseSetting = async (payload) => {
    return RestfulService.putApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}/settings`, payload);
};

const getFieldData = async (workspaceId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/field-data`, payload);
};

const addNewValueForCourseFieldData = async (workspaceId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/field-data`, payload);
};

const createCourseSyllabus = async (payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}/syllabuses`, payload);
};

const updateCourseSyllabusOrder = async (payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}/syllabuses/sort`, {
        syllabuses: payload?.syllabuses,
    });
};

const deleteCourseSyllabus = async (payload) => {
    return RestfulService.deleteApi(
        HOST_URL + `/workspaces/${payload.workspaceId}/courses/${payload.id}/syllabuses/${payload.syllabus_id}`
    );
};

const getSessions = async (workspaceId, courseId, payload, isSessionStatus = false) => {
    const baseUrl = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/sessions`;
    const params = new URLSearchParams(payload as any);
    if (isSessionStatus) {
        params.append('session_status', 'rescheduled');
        params.append('session_status', 'active');
    }
    const url = `${baseUrl}?${params.toString()}`;
    return RestfulService.getApi(url);
};

const getSessionsList = async (workspaceId, courseId, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/sessions`;
    return httpClient.get(url, { params, paramsSerializer: (p) => parseParams(p) });
};

const cancelMultiSessions = async (workspaceId, courseId, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/sessions/bulk-cancel`;
    return httpClient.post(url, params);
};

const getLearnerFromSessions = async (workspaceId, courseId, params?: any) => {
    return httpClient.get(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/more-learners`, {
        params,
        paramsSerializer: (p) => parseParams(p),
    });
};

const addLearnerToSessions = async (workspaceId, courseId, bodyParams) => {
    return httpClient.post(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/add-learners`, bodyParams);
};

const getSessionDetail = (workspaceId: string, courseId: string, sessionId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/sessions/${sessionId}`;
    return RestfulService.getApi(url, { params });
};

const getGradebooks = async (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/gradebooks`, payload);
};

const getGradebooksByGroup = async (workspaceId, courseId, learnerId) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/learners/${learnerId}/gradebooks`);
};

const getGradebooksLearningGroup = async (workspaceId, courseId, payload) => {
    return RestfulService.getApi(
        HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/learning_groups/${payload?.learning_group_ids}/gradebooks`,
        payload
    );
};

const getGradebooksOverview = async (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/gradebooks/stat`, payload);
};

const getPeopleOfCourse = (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/simple-enrollments`, payload);
};

const createSingleSession = (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/single`, payload);
};

const updateSession = (workspaceId, courseId, payload) => {
    const id = payload?.id;
    delete payload?.id;
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/${id}`, payload);
};
const updateSessionPast = (workspaceId, courseId, payload) => {
    const id = payload?.id;
    delete payload?.id;
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/${id}/past`, payload);
};
const createFixedSession = (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/fixed`, payload);
};

const getLessonOfCourse = async (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/lessons`, payload);
};

const cancelSession = async (workspaceId, course_id, session_id, payload) => {
    return RestfulService.deleteApi(HOST_URL + `/workspaces/${workspaceId}/courses/${course_id}/sessions/${session_id}`, payload);
};

const getDetailSession = async (workspaceId, courseId, sessionId) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/${sessionId}`);
};

const getCountPeople = async (workspaceId, courseId) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/count-people`);
};

const getinstructorAvailability = async (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/instructor-availability`, payload);
};

const getCourseAssignments = async (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/assignments`, payload);
};

const getCourseLearningMaterials = (workspaceId, courseId, enrollmentId, params?: any) => {
    return httpClient.get(`/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/learning-progress`, { params });
};

const getLearnerLearningMaterials = (workspaceId, courseId, enrollmentId, learnerId, params?: any) => {
    return httpClient.get(`/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/learners/${learnerId}/learning-progress`, { params });
};

const getCourseExercises = async (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/exercises`, payload);
};

const updateAssignment = async (workspaceId, courseId, payload) => {
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/assignments/${payload.id}`, payload);
};

const getAssignmentsAttempt = (workspaceId, courseId, assignmentId, attemptId) => {
    return httpClient.get(`/workspaces/${workspaceId}/courses/${courseId}/assignments/${assignmentId}/attempts/${attemptId}`);
};

const getAssignmentsGrade = (workspaceId, courseId, assignmentId) => {
    return httpClient.get(`/workspaces/${workspaceId}/courses/${courseId}/assignments/${assignmentId}/grade`);
};

const getAssignmentDetail = (workspaceId, courseId, assignmentId) => {
    return httpClient.get(`/workspaces/${workspaceId}/courses/${courseId}/assignments/${assignmentId}`);
};

const updateSessionAttendances = (workspaceId, courseId, payload) => {
    const id = payload?.id;
    delete payload?.id;
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/${id}/attendances`, payload);
};

const addFeedbackScore = (workspaceId, courseId, assignmentId, attemptId, payload) => {
    return httpClient.post(`/workspaces/${workspaceId}/courses/${courseId}/assignments/${assignmentId}/attempts/${attemptId}/task-results/grade`, payload);
};

const getListAttendanceOfSession = (workspaceId, courseId, sessionId, payload) => {
    return RestfulService.getApi(
        HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/${sessionId}/attendances`,
        payload
    );
};

const updateAttendanceOfSession = (workspaceId, courseId, sessionid, payload) => {
    return RestfulService.postApi(
        HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/${sessionid}/attendances`,
        payload
    );
};

const studyHistory = (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/study-histories/sessions`, payload);
};

const studyHistoryIndividual = (workspaceId, courseId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/study-histories`, payload);
};

const getTeachingAssistants = (workspaceId, courseId, payload) => {
    const clonePlayload = { ...payload };
    delete clonePlayload.instructor_course_ids;
    var serialiseObject = function (obj) {
        var pairs = [];
        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }
            if (Object.prototype.toString.call(obj[prop]) == '[object Object]') {
                pairs.push(serialiseObject(obj[prop]));
                continue;
            }
            pairs.push(prop + '=' + obj[prop]);
        }
        return pairs.join('&');
    };

    const instructor_course_ids = payload?.instructor_course_ids;
    let queryString = serialiseObject(clonePlayload);

    instructor_course_ids?.forEach((id) => {
        queryString = queryString + `&instructor_course_ids=${id}`;
    });

    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/teaching-assistants?${queryString}`);
};

const addTeachingAssistant = (workspaceId: string, courseId: string, params) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/teaching-assistants`;
    return RestfulService.postApi(url, params);
};

const updateInstructorOfEnrollment = (workspaceId: string, courseId: string, enrollmentId: string, params) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/instructors`;
    return RestfulService.postApi(url, params);
};

const getTemplates = (workspaceId, payload) => {
    return RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/course-templates`, payload);
};
const createCourseFromTemplate = (workspaceId, templateId) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/course-templates/${templateId}/create-course`);
};
const deleteCourseTemplate = (workspaceId, templateId) => {
    return RestfulService.deleteApi(HOST_URL + `/workspaces/${workspaceId}/course-templates/${templateId}`);
};

const CreateCustomReport = (workspaceId: string, courseId: string, params) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/custom-reports`;
    return RestfulService.postApi(url, params);
};

const getCustomReportById = (workspaceId: string, courseId: string, customReportId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/custom-reports/${customReportId}`;
    return RestfulService.getApi(url);
};

const updateCustomReport = (workspaceId: string, courseId: string, customReportId: string, params) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/custom-reports/${customReportId}`;
    return RestfulService.patchApi(url, params);
};

const deleteCustomReport = (workspaceId: string, courseId: string, customReportId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/custom-reports/${customReportId}`;
    return RestfulService.deleteApi(url);
};

const getCustomReport = (workspaceId: string, courseId: string, params) => {
    const cloneParams = { ...params };
    delete cloneParams.learner_ids;
    delete cloneParams.learning_group_ids;

    var serialiseObject = function (obj) {
        var pairs = [];
        for (var prop in obj) {
            if (!obj.hasOwnProperty(prop)) {
                continue;
            }
            if (Object.prototype.toString.call(obj[prop]) == '[object Object]') {
                pairs.push(serialiseObject(obj[prop]));
                continue;
            }
            pairs.push(prop + '=' + obj[prop]);
        }
        return pairs.join('&');
    };

    let queryString = serialiseObject(cloneParams);
    const learner_ids = params?.learner_ids;
    const learning_group_ids = params?.learning_group_ids;

    if (learner_ids?.length) {
        learner_ids?.forEach((id) => {
            queryString = queryString + `&learner_ids=${id}`;
        });
    }

    if (learning_group_ids?.length) {
        learning_group_ids?.forEach((id) => {
            queryString = queryString + `&learning_group_ids=${id}`;
        });
    }

    const url = `/workspaces/${workspaceId}/courses/${courseId}/custom-reports?${queryString}`;
    return RestfulService.getApi(url);
};

const getCourseResources = ({ workspaceId, courseId }, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/resources`;
    return httpClient.get(url, { params });
};

const uploadCourseResource = ({ workspaceId, courseId }, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/resources`;
    return httpClient.post(url, bodyParams);
};

const importCourseResource = ({ workspaceId, courseId }, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/resources/import`;
    return httpClient.post(url, bodyParams);
};

const deleteCourseResourceId = ({ workspaceId, courseId, resourceId }) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/resources/${resourceId}`;
    return httpClient.delete(url);
};

const generateAssignments = (workspaceId: string, courseId: string, learningPathId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learning-paths/${learningPathId}/generate-assignments`;
    return httpClient.post(url);
};

const getLearners = (workspaceId, courseId, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners`;
    return httpClient.get(url, { params });
};

const getParents = (workspaceId, courseId, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/parents`;
    return httpClient.get(url, { params });
};

const getListCertificate = (workspaceId, courseId, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/certificates`;
    return httpClient.get(url, { params });
};
const onCertificateCourse = (workspaceId: string, courseId: string, leanerId: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/certificates/learners/${leanerId}`;
    return httpClient.post(url, bodyParams);
};

const getLeanerCertificate = (workspaceId, courseId, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners`;
    return httpClient.get(url, { params });
};
const deleteCertificateCourse = (workspaceId: string, courseId: string, leanerId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/certificates/learners/${leanerId}`;
    return httpClient.delete(url);
};

const addAssignmentToCourse = (workspaceId: string, courseId: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/exercises`;
    return httpClient.post(url, bodyParams);
};

const getCourseGrades = (workspaceId: string, courseId: string, params: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/assignments`;
    return httpClient.get(url, { params });
};

const updateAssignmentBulk = async (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/assignments/bulk`, payload);
};
const assignAssignment = (workspaceId: string, courseId: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/assign-assignments`;
    return httpClient.post(url, bodyParams);
};

const updateStatusOfEnrollmentMember = (workspaceId: string, courseId: string, enrollmentId: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/members/change-status`;
    return httpClient.post(url, bodyParams);
};

const getMemberCanAddToEnrollment = (workspaceId: string, courseId: string, enrollmentId: string, params: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/more-members`;
    return httpClient.get(url, { params });
};

const addMemberToEnrollment = (workspaceId: string, courseId: string, enrollmentId: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/add-members`;
    return httpClient.post(url, bodyParams);
};
const getSummaryCreditOfEnrollment = (workspaceId: string, courseId: string, learnerId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners/${learnerId}/sale-contracts/stat`;
    return httpClient.get(url);
};

const getCreditHistoryOfEnrollment = (workspaceId: string, courseId: string, learnerId: string, params: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners/${learnerId}/sale-contracts`;
    return httpClient.get(url, { params });
};

const cancelCreditPackage = (workspaceId: string, courseId: string, learnerId: string, saleContractId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners/${learnerId}/sale-contracts/${saleContractId}/cancel`;
    return httpClient.post(url);
};

const getSaleContractAvailable = (workspaceId: string, courseId: string, learnerId: string, params: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners/${learnerId}/sale-contracts/available`;
    return httpClient.get(url, { params });
};

const addSaleContractToCourse = (workspaceId: string, courseId: string, learnerId: string, saleContractId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learners/${learnerId}/sale-contracts/${saleContractId}`;
    return httpClient.post(url);
};

const precheck = (workspace_id: string, course_id: string) => {
    return httpClient.get(`/workspaces/${workspace_id}/courses/${course_id}/precheck`);
};

// const getAllCourseLearner = (workspaceId: string, courseId: string, params: any) => {
//     const url = `/workspaces/${workspaceId}/courses/${courseId}/all-learners`;
//     return httpClient.get(url, { params });
// };
const getCreditBalanceIndividual = (workspaceId: string, creditId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/${creditId}`;
    return httpClient.get(url, { params });
};

const getRefundCredits = (workspaceId: string, courseId: string, enrollmentId: string, learnerId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/enrollments/${enrollmentId}/refund-credits/${learnerId}`;
    return httpClient.get(url);
};

const getCreditBalanceLearningGroup = (workspaceId: string, params: { enrollment_id: string; membership_id: string }) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/get-detail`;
    return httpClient.get(url, { params });
};

const getTransactionCreditBalance = (workspaceId: string, creditId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/${creditId}/transactions`;
    return httpClient.get(url, { params });
};

const getTransactionByIdCreditBalance = (workspaceId: string, creditId: string, transactionId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/${creditId}/transactions/${transactionId}`;
    return httpClient.get(url, { params });
};

const depositCreditBalance = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/deposit`;
    return httpClient.post(url, params);
};

const refundCreditBalance = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/refund`;
    return httpClient.post(url, params);
};

const getCreditAccounts = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/credit-accounts`;
    return httpClient.get(url, { params });
};

const updateNoteTransactionByIdCreditBalance = (workspaceId: string, creditId: string, transactionId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/${creditId}/transactions/${transactionId}/note`;
    return httpClient.patch(url, params);
};

const getMoreLearnerAddToSession = (workspaceId: string, courseId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/sessions/more-learners`;
    return httpClient.get(url, { params });
};

const getMoreInstructorFromSessions = async (workspaceId, courseId, params?: any) => {
    return httpClient.get(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/more-instructors`, {
        params,
        paramsSerializer: (p) => parseParams(p),
    });
};

const addMoreAttendeesToSessions = async (workspaceId, courseId, bodyParams) => {
    return httpClient.post(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/sessions/add-attendees`, bodyParams);
};

export const preCheckInstructorInCourse = (workspaceId: string, courseId: string, instructorId: string) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/instructors/${instructorId}/pre-check`;
    return httpClient.get(url);
};

export const getLearningGroupsCoursePeople = (workspaceId: string, courseId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learning-groups`;
    return httpClient.get(url, { params });
};

export const getDetailLearningGroupsCoursePeople = (workspaceId: string, courseId: string, learningGroupId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${courseId}/learning-groups/${learningGroupId}`;
    return httpClient.get(url, { params });
};

const enrollLearnerToCourse = (workspaceId, courseId, payload) => {
    return RestfulService.postApi(HOST_URL + `/workspaces/${workspaceId}/courses/${courseId}/enrollments`, payload);
};

export default {
    getParents,
    getLearners,
    createCourse,
    getCourse,
    getCourseSettings,
    deleteCourse,
    updateCourse,
    enrollPeople,
    getEnrollments,
    inviteInstructor,
    getInstructorEnrolled,
    getEnrollmentDetail,
    getMemberOfEnrollment,
    updateEnrollmentDetail,
    updateDataInstructorInvited,
    checkHasActiveLearner,
    updateCourseSetting,
    getFieldData,
    createCourseSyllabus,
    updateCourseSyllabusOrder,
    deleteCourseSyllabus,
    getSessions,
    getSessionsList,
    cancelMultiSessions,
    getLearnerFromSessions,
    addLearnerToSessions,
    getSessionDetail,
    getPeopleOfCourse,
    createSingleSession,
    getLessonOfCourse,
    cancelSession,
    getCountPeople,
    updateSession,
    createFixedSession,
    getinstructorAvailability,
    getCourseAssignments,
    getCourseLearningMaterials,
    getLearnerLearningMaterials,
    updateAssignment,
    getAssignmentsGrade,
    getAssignmentsAttempt,
    getAssignmentDetail,
    updateSessionPast,
    updateSessionAttendances,
    addFeedbackScore,
    getGradebooks,
    getGradebooksOverview,
    getListAttendanceOfSession,
    updateAttendanceOfSession,
    getDetailSession,
    studyHistory,
    getGradebooksLearningGroup,
    getGradebooksByGroup,
    studyHistoryIndividual,
    getTeachingAssistants,
    addTeachingAssistant,
    getCourseResources,
    uploadCourseResource,
    importCourseResource,
    deleteCourseResourceId,
    getTemplates,
    createCourseFromTemplate,
    deleteCourseTemplate,
    addNewValueForCourseFieldData,
    getCustomReport,
    CreateCustomReport,
    updateCustomReport,
    deleteCustomReport,
    generateAssignments,
    getListCertificate,
    onCertificateCourse,
    getLeanerCertificate,
    deleteCertificateCourse,
    getCourseExercises,
    addAssignmentToCourse,
    getCourseGrades,
    updateAssignmentBulk,
    assignAssignment,
    updateStatusOfEnrollmentMember,
    getMemberCanAddToEnrollment,
    addMemberToEnrollment,
    getSummaryCreditOfEnrollment,
    getCreditHistoryOfEnrollment,
    cancelCreditPackage,
    getSaleContractAvailable,
    addSaleContractToCourse,
    updateInstructorOfEnrollment,
    precheck,
    // getAllCourseLearner,
    getCreditBalanceIndividual,
    getRefundCredits,
    getCreditBalanceLearningGroup,
    getTransactionCreditBalance,
    getTransactionByIdCreditBalance,
    depositCreditBalance,
    refundCreditBalance,
    getCreditAccounts,
    updateNoteTransactionByIdCreditBalance,
    getMoreLearnerAddToSession,
    getMoreInstructorFromSessions,
    addMoreAttendeesToSessions,
    preCheckInstructorInCourse,
    getCustomReportById,
    getLearningGroupsCoursePeople,
    getDetailLearningGroupsCoursePeople,
    enrollLearnerToCourse
};
