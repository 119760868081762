import { FC } from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { classNames, UUID } from 'utils/commonFun';
import ErrorMessageForm from 'components/ErrorMessageForm';

type DatePickerFieldProps = {
    control: Control<any>;
    name: string;
    placeholder?: string;
    isRequired?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    formatDate?: string;
    className?: string;
    classNameInput?: string;
    errors?: any;
    label?: string;
    rules?: any;
    minDate?: any;
    maxDate?: any;
    positionIcon?: 'left' | 'right';
};

export const DatePickerField: FC<DatePickerFieldProps> = ({
    control,
    name,
    placeholder = '',
    isRequired = false,
    isClearable = true,
    disabled = false,
    formatDate = 'yyyy/MM/dd',
    className = '',
    classNameInput = '',
    errors,
    label,
    rules,
    minDate,
    maxDate,
    positionIcon = 'left'
}) => {
    const { t: translator } = useTranslation();
    const id = UUID();

    return (
        <div className={classNames('flex flex-col space-y-1', className)}>
            {!!label && (
                <label htmlFor={id} className="font-semibold text-xs text-ooolab_dark_1">
                    {label}
                    {isRequired && <span className="text-red-500">*</span>}
                </label>
            )}
            <div className="w-full relative">
                <Controller
                    rules={{
                        required: {
                            value: isRequired,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                        ...rules,
                    }}
                    control={control}
                    name={name}
                    render={({ field: { onChange, value, ref } }) => (
                        <DatePicker
                            id={id}
                            isClearable={!!value && !disabled && isClearable}
                            disabled={disabled}
                            ref={() => ref}
                            minDate={minDate}
                            maxDate={maxDate}
                            placeholderText={placeholder}
                            selected={value}
                            onChange={onChange}
                            dateFormat={formatDate}
                            className={classNames(
                                'pl-8 py-2.5 w-full text-xs text-ooolab_dark_1 font-normal rounded border border-ooolab_blue_4 focus-within:border-[#2684FF] hover:border-[#B3B3B3]',
                                !!errors && errors[name] && '!border-red-500',
                                positionIcon === 'right' && '!pl-2',
                                classNameInput,
                            )}
                        />
                    )}
                />
                <CalendarIcon className={`w-5 h-5 text-ooolab_dark_2 absolute top-2 ${positionIcon === 'right' ? 'right-2' : 'left-2'}`} />
            </div>
            {!!errors && <ErrorMessageForm errors={errors} name={name} classes="-mt-1" />}
        </div>
    );
};

type TimePickerFieldProps = {
    control: Control<any>;
    name: string;
    placeholder?: string;
    isRequired?: boolean;
    isClearable?: boolean;
    disabled?: boolean;
    formatDate?: string;
    className?: string;
    errors?: any;
    label?: string;
    rules?: any;
    startTime?: any;
    endTime?: any;
    type?: 'signle' | 'time-range';
    disableInput?: boolean;
    timeDurations?: number;
    filterTimeFunc?: any;
    valueSelected?: any;
    onChangeValue?: any;
    positionIcon?: 'left' | 'right';
};

export const TimePickerField: FC<TimePickerFieldProps> = ({
    control,
    name,
    placeholder = '',
    isRequired = false,
    isClearable = true,
    disabled = false,
    formatDate = 'HH:mm',
    className,
    errors,
    label,
    rules,
    startTime,
    endTime,
    disableInput = false,
    timeDurations = 30,
    filterTimeFunc,
    valueSelected,
    onChangeValue,
    positionIcon = 'left',
    ...props
}) => {
    const { t: translator } = useTranslation();
    const id = UUID();

    const filterTime = (time) => {
        const lowerBound = startTime ? startTime : new Date().setHours(0, 0);
        const upperBound = endTime ? endTime : new Date().setHours(23, 59);

        return time >= lowerBound && time <= upperBound;
    };

    return (
        <div className={classNames('flex flex-col space-y-1 relative', className)}>
            {!!label && (
                <label htmlFor={id} className="font-semibold text-xs text-ooolab_dark_1">
                    {label}
                    {isRequired && <span className="text-red-500">*</span>}
                </label>
            )}
            <div className="w-full relative">
                <Controller
                    rules={{
                        required: {
                            value: isRequired,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                        ...rules,
                    }}
                    control={control}
                    name={name}
                    render={({ field }) => {
                        return (
                            //@ts-ignore
                            <DatePicker
                                id={id}
                                isClearable={!!field.value && !disabled && isClearable}
                                disabled={disabled}
                                ref={() => field.ref}
                                filterTime={filterTimeFunc ? filterTimeFunc : filterTime}
                                timeIntervals={timeDurations}
                                placeholderText={placeholder}
                                selected={field.value}
                                showTimeSelect
                                showTimeSelectOnly
                                onChange={(newValues) => {
                                    field.onChange(newValues);
                                    onChangeValue?.(newValues);
                                }}
                                dateFormat={formatDate}
                                timeFormat="HH:mm"
                                className={classNames(
                                    'pl-8 py-2.5 w-full text-xs text-ooolab_dark_1 font-normal rounded border border-ooolab_blue_4 focus-within:border-[#2684FF] hover:border-[#B3B3B3]',
                                    !!errors && errors[name] && '!border-red-500',
                                    positionIcon === 'right' && '!pl-2' 
                                )}
                                onKeyDown={(e) => (disableInput ? e.preventDefault() : null)}
                                {...props }
                            />
                        );
                    }}
                />
                <ClockIcon className={`w-5 h-5 text-ooolab_dark_2 absolute top-2 ${positionIcon === 'right' ? 'right-2' : 'left-2'}`} />
            </div>
            {!!errors && <div><ErrorMessageForm errors={errors} name={name} classes="!mt-0.5" /></div>}
        </div>
    );
};
