type FileType = '.zip' | '.pdf' | '.jpg' | '.jpeg' | '.png' | 'unknown';

interface FileHeaderMapping {
    [key: string]: FileType;
}

const fileHeaderMapping: FileHeaderMapping = {
    '50 4b 03 04': '.zip',
    '25 50 44 46': '.pdf',
    'ff d8 ff e0': '.jpg',
    'ff d8 ff e1': '.jpeg',
    '89 50 4e 47': '.png',
};

export const checkFileHeader = (file: File): Promise<FileType> => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (event: ProgressEvent<FileReader>) => {
            try {
                const arrayBuffer = event.target?.result as ArrayBuffer;
                const uint8Array = new Uint8Array(arrayBuffer);

                // Get the first 4 bytes of the file
                const header = uint8Array.slice(0, 4);
                const headerHex = Array.from(header)
                    .map((byte) => byte.toString(16).padStart(2, '0'))
                    .join(' ');

                // Check header with mapping
                const fileType = fileHeaderMapping[headerHex] || 'unknown';
                resolve(fileType);
            } catch (error) {
                reject('Unable to read file');
            }
        };
        fileReader.onerror = () => reject('Error reading file');
        fileReader.readAsArrayBuffer(file);
    });
};
