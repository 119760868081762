import React, { useEffect, useMemo, useState } from 'react';
import './SideLeft.scss';
import { CaretIcon } from 'assets/icon';
import { TASKS_STATUS } from 'components/GradeSubmission/AssignmentGrade/AssignmentGrade';

export const SideLeft = ({ statusGrades, assignmentDetail, attemptSelected, onSelectAttempt }) => {
    const [activeDisclosurePanel, setActiveDisclosurePanel] = useState({});

    const TASKS = useMemo(() => {
        return Object.keys(TASKS_STATUS)?.map((el) => {
            return {
                value: el,
                label: TASKS_STATUS[el],
                attempts: statusGrades[el],
            };
        });
    }, [statusGrades]);

    useEffect(() => {
        let obj;
        for (const el in TASKS_STATUS) {
            obj = {
                ...obj,
                [el]: el === attemptSelected?.status,
            };
        }

        setActiveDisclosurePanel(obj);
    }, [statusGrades, attemptSelected]);

    return (
        <div className="sideLeft px-4 select-none">
            {TASKS?.map((task, index) => {
                return (
                    <div key={index}>
                        <div
                            className="py-3 cursor-pointer"
                            onClick={() => {
                                setActiveDisclosurePanel({
                                    ...activeDisclosurePanel,
                                    [task.value]: !activeDisclosurePanel[task.value],
                                });
                            }}
                        >
                            <div className={`sideLeft-header ${activeDisclosurePanel[task.value] ? 'active' : ''}`}>
                                <span className={`${activeDisclosurePanel[task.value] ? 'rotate-90' : null} text-gray-500`}>
                                    <CaretIcon size={24} />
                                </span>
                                <span className="sideLeft-label">{task.label}</span>
                                <span className="sideLeft-count">{task?.attempts?.length}</span>
                            </div>
                        </div>
                        <div className={`sideLeft-container transition-all duration-300 ease-in-out ${activeDisclosurePanel[task.value] ? '' : '!hidden'}`}>
                            {task?.attempts?.length > 0 ? (
                                task?.attempts?.map((att) => (
                                    <div
                                        key={att?.id}
                                        onClick={() => onSelectAttempt(att?.assignment_id, att?.id)}
                                        className={`sideLeftUser ${attemptSelected?.id == att?.id ? 'active' : ''}`}
                                    >
                                        <div
                                            className="sideLeftUser-avatar"
                                            style={{ backgroundImage: `url(${att?.learner?.avatar_url})` }}
                                        ></div>
                                        <div className="flex-1 truncate">
                                            <div className="sideLeftUser-name truncate">{att?.learner?.display_name}</div>
                                            <p className="text-gray-700 text-xs">
                                                Attempt Order:{' '}
                                                {att?.order_number != null && assignmentDetail?.maximum_of_submission != null
                                                    ? `${att?.order_number}/${assignmentDetail?.maximum_of_submission}`
                                                    : '-'}
                                            </p>
                                        </div>
                                        {att?.status === 'completed' && (
                                            <div className={`grade ${attemptSelected?.id == att?.id ? '!bg-gray-50' : ''}`}>
                                                {Math.round(att?.final_score || 0) + '%'}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="px-8 text-gray-500 text-sm">No Task.</div>
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};
