import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import FsBackend from 'i18next-fs-backend';
import { getLocalStorageAuthData } from '../utils/handleLocalStorage';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const LANGUAGE_DEFAULT = process.env.REACT_APP_LANGUAGE_DEFAULT;
const REACT_APP_LANGUAGE_URL = process.env.REACT_APP_LANGUAGE_URL;

//get from local
// declare global {
//     interface NodeRequire {
//         context: (
//             directory: string,
//             useSubdirectories: boolean,
//             regExp: RegExp
//         ) => {
//             keys: () => string[];
//             (id: string): any;
//         };
//     }
// }
// function importAll(r) {
//     let imports: { [key: string]: any } = {};
//     r.keys().forEach((key: string) => (imports[key] = r(key)));
//     return imports;
// }

// const imports = {
//     en: importAll(require.context(`./locales/${process.env.REACT_APP_WHITELABEL}/en`, false, /\.json$/)),
//     fr: importAll(require.context(`./locales/${process.env.REACT_APP_WHITELABEL}/fr`, false, /\.json$/)),
//     vi: importAll(require.context(`./locales/${process.env.REACT_APP_WHITELABEL}/vi`, false, /\.json$/)),
// };

// const locales = Object.keys(imports);

// const getLocaleMessages = () =>
//     locales.reduce(
//         (messages, locale) => ({
//             ...messages,
//             [locale]: {
//                 translation: Object.values(imports[locale]).reduce(
//                     // @ts-ignore
//                     (message, current) => ({ ...message, ...current }),
//                     {}
//                 ),
//             },
//         }),
//         {}
//     );

// const resources = getLocaleMessages();

// if (resources) {
//     setTimeout(() => {
//         document.getElementById('loader')?.remove();
//         document.getElementById('root').style.opacity = '1';
//     }, 3000);

//     i18n.use(initReactI18next).init({
//         resources,
//         compatibilityJSON: 'v3',
//         fallbackLng: LANGUAGE_DEFAULT,
//         interpolation: {
//             escapeValue: false, // prevent i18n from encoding special characters
//         },
//         react: {
//             useSuspense: false,
//         },
//         backend: {
//             backends: [HttpBackend, FsBackend],
//             backendOptions: [
//                 {
//                     loadPath: `${REACT_APP_LANGUAGE_URL}/${process.env.REACT_APP_WHITELABEL}/{{lng}}.json`,
//                 },
//             ],
//         },
//     });
//     i18n.changeLanguage(getLocalStorageAuthData()?.language || LANGUAGE_DEFAULT);
// }

// get from CDN
i18n
  .use(HttpBackend)
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: LANGUAGE_DEFAULT,
    backend: {
      backends: [
        HttpBackend,
        FsBackend
      ],
      backendOptions: [{
        loadPath: `${REACT_APP_LANGUAGE_URL}/${process.env.REACT_APP_WHITELABEL}/{{lng}}.json`
      }]
    },
    react: {
      useSuspense: false
    },
    interpolation: {
      escapeValue: false, // prevent i18n from encoding special characters
    }
  });
i18n.changeLanguage(getLocalStorageAuthData()?.language || LANGUAGE_DEFAULT);

i18n?.on('loaded', function(loaded) {
  setTimeout(() => {
    document.getElementById('loader')?.remove();
    document.getElementById('root').style.opacity = '1';
  }, 1400);
})

export default i18n;