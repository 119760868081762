import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import './Table.scss';
import { useHistory, useParams } from 'react-router-dom';
import { User } from '_shared/components/User/User';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
}

const ViewTable: FC<IViewTable> = ({ data, refreshData }) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string, userId: string } = useParams();
    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('SKILL')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row.original?.skill_level?.skill?.name} />;
                },
                accessor: 'skill',
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={translator('Level')} />,
                accessor: 'skill_level',
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row.values?.skill_level?.name} />;
                },
                width: '160',
            },
            {
                Header: () => <RenderTableHeader value={translator('AVERAGE_GRADE')} />,
                accessor: 'avg_overall_grade',
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            value={
                                row.values?.avg_overall_grade !== null ? (row.values?.avg_overall_grade?.toFixed(0) || 0) + '%' : '-'
                            }
                        />
                    );
                },
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES_COMPLETION_%')} />,
                accessor: 'total_completed_courses',
                Cell: ({ row }: any) => {
                    const comleteionPerTotal =
                        ' (' + row.original?.total_completed_courses + '/' + row.original?.total_associated_courses + ')';
                    const href = `/workspace/${params?.id}/management/users/${params?.userId}?tab=gradebook`;
                    let value = (row.original?.avg_course_completion?.toFixed(0) || 0) + '%' + comleteionPerTotal;
                    if (row.original?.avg_course_completion === null) value = '-';
                    return (
                        <div
                            onClick={() =>
                                history.push(href, {
                                    skill: {
                                        ...row?.original,
                                        id: row?.original?.skill_level?.skill?.id,
                                        name: row?.original?.skill_level?.skill?.name,
                                        value: row?.original?.skill_level?.skill?.id,
                                        levels: [{ ...row?.original?.skill_level, name: row?.original?.skill_level?.name, value: row?.original?.skill_level?.id}], // eslint-disable-line
                                    },
                                })
                            }
                            className="w-full cursor-pointer"
                        >
                            <RenderTableCell className="text-primary-500 font-semibold" value={value} />
                        </div>
                    );
                },
                width: '160',
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNING_GROUP.LEARNING_GROUP')} />,
                accessor: 'learning_group',
                Cell: ({ row }: any) => {
                    if (!row?.original?.status) return <RenderTableCell value="" />;
                    return (
                        <div
                            className="w-full text-primary-500 font-semibold"
                            onClick={() => history.push(`/workspace/${params.id}/management/learning-groups/${row?.original?.id}`)}
                        >
                            <RenderTableCell
                                className="text-primary-500 font-semibold"
                                value={translator(row?.values?.learning_group)}
                            />
                        </div>
                    );
                },
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={translator('ADDED_BY')} />,
                accessor: 'created_by',
                Cell: ({ row }: any) => {
                    if (!row?.values?.created_by) return <RenderTableCell value="" />;
                    return (
                        <div className="w-full">
                            <User
                                name={row?.values?.created_by?.display_name}
                                avatar={row?.values?.created_by?.avatar_url}
                                link={`/workspace/${params.id}/management/users/${row?.values?.created_by?.id}?tab=information`}
                            />
                        </div>
                    );
                },
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={row?.original}
                                refreshData={refreshData}
                                position={row?.index > data?.length / 2 ? 'bottom-0' : ''}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('NO_SKILLS')}
            // emptyIcon={NO_CONTENT}
        />
    );
};

export default ViewTable;
