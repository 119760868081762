import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoginFormFooter: React.FC = () => {
    const { t } = useTranslation();

    return (
        <Link to="/password/forgot" className="text-sm font-normal text-primary-500 hover:underline">
            {t('FORGOTTEN_PASSWORD')}
        </Link>
    );
};

export default LoginFormFooter;
