import { FC, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import FilterSearch from '../components/FIlterSearch/FilterSearch';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustomMultipleSelect';
import { Column } from 'react-table';
import { defaultPagination } from 'constant/util.const';
import NO_CONTENT_IMG from 'assets/img/empty-state/library.png';
import TablePagination from 'components/V2/Pagination/Pagination';
import libraryService from 'services/library.service';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { LibraryTypeEnum, LIBRARY_ICONS } from '../LibraryContants';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { UploadContext } from 'contexts/Upload';
import { DocumentPreview } from '../components/DocumentPreview/DocumentPreview';
import ModalCreateFolder from './Modals/ModalCreateFolder';
import ModalRename from './Modals/ModalRename';
import ModalDelete from './Modals/ModalDelete';
import ActionMenu from './ActionMenu';
import Popup from 'components/V2/Popup/Popup';
import DetailsFilePopup from '../components/DetailsFilePopup/DetailsFilePopup';
import workspaceService from 'services/workspace.service';
import h5pService from 'services/h5p.service';
import { PopoverNewContent } from '../components/PopoverNewContent/PopoverNewContent';
import DuplicateAction from './Modals/DuplicateAction';
import ModalShareLink from 'components/V2/Modals/ModalShareLink';
import { ConfirmModal, LoadingPopup, Toastify } from '_shared';
import ModalMoveFile from './Modals/ModalMoveTo/ModalMoveTo';
import { LESSON_STATUS, LESSON_STATUS_CODE } from '../LessonBuilder/constants';
import DialogComponent from 'components/Dialog/DialogComponent';
import { SCORMPackage } from '../components/SCORMPackage/SCORMPackage';
import lessonService from 'services/lesson.service';
import { OpenBox } from 'assets/icon';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import uploadService from 'services/upload.service';
import { clearStatus, setUploadStates } from 'store/features/librarySlice';
import { ProgressUploadFile } from './ProgressUploadFile/ProgressUploadFile';
import { UploadState } from 'types/Library.type';
import { debounce } from 'lodash';
import { ModalGetLink } from 'components/V2/Modals/ModalGetLink';
import { UploadScorm } from 'components/ScormComponent/UploadScorm';

interface TabLibraryProps { }

let folderId = null;
let filter = null;

const TabLibrary: FC<TabLibraryProps> = ({ }) => {
    const params: any = useParams();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const { t: translation } = useTranslation();
    const dispatch = useAppDispatch();
    const { fileList, statusUpload } = useAppSelector((state) => state.library);
    const uploadContext: any = useContext(UploadContext);

    const [pagination, setPagination] = useState({ ...defaultPagination, object_types: '', h5p_types: '', custom_fields: '' });
    const [dataTable, setDateTable] = useState<any>(null);
    const [indexPage, setIndexPage] = useState('0-0');
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
    const [isOpenModalNewFolder, setOpenModalNewFolder] = useState(false);
    const [isOpenModalRename, setOpenModalRename] = useState(false);
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [isOpenModalDuplicate, setOpenModalDuplicate] = useState(false);
    const [openModalShareLink, setOpenModalShareLink] = useState(false);
    const [linkShare, setLinkShare] = useState<string>('');
    const [isOpenModalMoveFile, setOpenModalMoveFile] = useState(false);
    const [openModalGetLinkVideo, setOpenModalGetLinkVideo] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [selectDocument, setSelectDocument] = useState(null);
    const [popupDetails, setPopupDetails] = useState<boolean>(false);
    const [itemSelected, setItemSelected] = useState<any>(null);
    const [loadingViewDetails, setLoadingViewDetails] = useState<boolean>(false);
    const [isMulti, setIsMulti] = useState<boolean>(false);
    const [loadingDownload, setLoadingDownload] = useState<boolean>(false);
    const [loadingMoveFile, setLoadingMoveFile] = useState<boolean>(false);
    const [openSCORM, setOpenSCORM] = useState(null);
    const [openModalStatus, setOpenModalStatus] = useState(false);
    const [uploadFile, setUploadFile] = useState<boolean>(null);
    const [processListUpload, setProcessListUpload] = useState<UploadState[]>([]);

    folderId = params?.folderId || null;

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const handleUploadFile = (data) => {
        const uploadAPIs = [];
        setUploadFile(true);
        data.forEach((item, index) => {
            let payload: any = {
                access_token: JSON.parse(localStorage.getItem('token_google')),
                file_id: item.id ?? '',
                file_name: item.name ?? '',
            };
            if (folderId) {
                payload = {
                    ...payload,
                    parent_id: folderId,
                };
            }
            uploadAPIs.push(
                uploadService
                    .uploadFileFromGoogle(params?.id, payload)
                    .then(() => {
                        setProcessListUpload((prev) => {
                            const newState = [...prev];
                            newState[index] = {
                                name: item.name,
                                status: 'success',
                                loading: false,
                            };
                            return newState;
                        });
                    })
                    .catch((err) => {
                        setProcessListUpload((prev) => {
                            const newState = [...prev];
                            newState[index] = {
                                name: item.name,
                                status: 'error',
                                loading: false,
                                error: err?.response?.data?.error?.description || 'Upload failed',
                            };
                            return newState;
                        });
                    })
            );
        });
        Promise.all(uploadAPIs).finally(() => {
            setUploadFile(false);
            dispatch(clearStatus());
            dispatch(setUploadStates(processListUpload));
        });
    };

    useEffect(() => {
        if (fileList?.length && statusUpload === 'start') {
            setProcessListUpload(
                fileList?.map((item) => ({
                    name: item.name,
                    status: 'pending',
                    loading: true,
                }))
            );
            handleUploadFile(fileList);
        }
    }, [fileList]);

    const {
        userState: { result },
    } = useContext(UserContext);

    const getList = (page: number = 1, order: string = 'desc') => {
        setLoadingTable(true);

        const payload = {
            ...pagination,
            ...filter,
            parent_id: folderId && filter?.searchFolder === 'this_folder' ? folderId : null,
            page: page || pagination?.page,
            order: order || pagination?.order,
        };
        if (!filter?.custom_fields) {
            delete payload.custom_fields;
        }
        delete payload?.total;
        delete payload?.searchFolder;
        !payload?.parent_id && delete payload?.parent_id;
        libraryService
            .getListLibrary(params.id, payload)
            .then((res) => {
                setDateTable(res?.data?.items);
                setPagination({
                    ...pagination,
                    page: res?.data?.page,
                    per_page: res.data?.per_page,
                    order: res?.data?.order,
                    total: res?.data?.total,
                    sort_by: res?.data?.sort_by,
                });
                setIndexPage(getIndexPage(page, res.data?.per_page, res.data?.items?.length));
            })
            .finally(() => setLoadingTable(false));
    };

    const getListDebounce = useCallback(
        debounce((page, order?: string) => getList(page, order), 500),
        []
    );

    useEffect(() => {
        return () => {
            folderId = null;
            filter = null;
        };
    }, []);

    useEffect(() => {
        getList(1);
    }, [folderId]);

    useEffect(() => {
        if (!uploadFile) {
            getListDebounce(1);
        }
    }, [uploadFile]);

    useEffect(() => {
        if (Object.keys(uploadContext?.fileUploadSuccess)?.length) getListDebounce(1);
    }, [uploadContext?.fileUploadSuccess]);

    const columns: Column<any>[] = useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('NAME')} />,
                accessor: 'name',
                Cell: (data: any) => {
                    let type = data?.row?.original?.object_type;
                    return (
                        <div onClick={(e) => handleClick(data?.row?.original, e)} className="flex items-center px-2">
                            <div className="flex-none w-6">
                                <img
                                    alt="libary-icon"
                                    className="h-6 w-6"
                                    src={
                                        data?.row?.original?.object_type
                                            ? LIBRARY_ICONS(translator)?.find((item) => item?.value === type)?.icon
                                            : LIBRARY_ICONS(translator)[0].icon
                                    }
                                />
                            </div>
                            <div className="w-full">
                                <RenderTableCell
                                    className="p-3 pl-1 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                    value={data?.value?.replace(data?.row?.original?.sub_object_type, '')}
                                />
                            </div>
                        </div>
                    );
                },
                width: 320,
            },
            {
                Header: () => <RenderTableHeader value={translator('OWNER')} />,
                accessor: 'created_by',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div onClick={(e) => handleClick(row?.original, e)} className="flex items-center px-2">
                            <div className="flex-none w-6">
                                <img alt="avatar-icon" className="h-6 w-6 rounded-full" src={row?.original?.created_by?.avatar_url} />
                            </div>
                            <div className="w-full">
                                <RenderTableCell
                                    className="p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                    onClick={() => { }}
                                    value={row?.original?.created_by?.display_name}
                                />
                            </div>
                        </div>
                    );
                },
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('CONTENT_TYPE')} />,
                accessor: 'object_type',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div onClick={(e) => handleClick(row?.original, e)}>
                            <RenderTableCell
                                className="p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                value={
                                    row?.original?.object_type
                                        ? LIBRARY_ICONS(translator)?.find((item) => item?.value === row?.original?.object_type)?.label
                                        : LIBRARY_ICONS(translator)[0].label
                                }
                            />
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS', true)} />,
                accessor: 'status',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div
                            onClick={(e) => handleClick(row?.row?.original, e)}
                            className="py-3 ml-2">
                            {row?.original.object_type == 'lesson' || row?.original.object_type == 'assignment' ? (
                                <span
                                    className={`text-sm font-normal border rounded px-3 py-[1px] inline-block
                            ${row?.original?.status == LESSON_STATUS_CODE.published
                                            ? 'text-green-500 border-green-300 bg-green-50'
                                            : 'text-gray-500 border-gray-300 bg-gray-50'
                                        }`}
                                >
                                    {t(LESSON_STATUS[row?.original?.status])}
                                </span>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => (
                    <div
                        className="flex items-center cursor-pointer text-primary-500"
                        onClick={() => {
                            getListDebounce(pagination?.page, pagination?.order === 'desc' ? 'asc' : 'desc');
                        }}
                    >
                        <RenderTableHeader className="!text-primary-500" value={translator('LAST_MODIFIED')} />
                        {pagination?.order === 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1 " />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1 " />
                        )}
                    </div>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <div onClick={(e) => handleClick(d?.row?.original, e)}>
                            <RenderTableCell
                                className=" p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                value={dayjs.utc(d?.value).tz(result?.time_zone).locale(language).fromNow()}
                            />
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => <></>,
                accessor: 'actions',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="mt-1.5">
                            <ActionMenu
                                item={row?.original}
                                previewLibrary={previewLibrary}
                                setOpenModalRename={setOpenModalRename}
                                onMoveFile={() => setOpenModalMoveFile(true)}
                                setSelectedFile={setSelectedFile}
                                setOpenModalDelete={setOpenModalDelete}
                                onDetails={(value) => onSingleClick(value)}
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                setOpenModalDuplicate={setOpenModalDuplicate}
                                setModalShareLink={(isOpen: boolean) => handleShareLink(isOpen, row?.original)}
                                setLoadingDownload={setLoadingDownload}
                                setOpenModalStatus={setOpenModalStatus}
                                positon={`${row.index > dataTable?.length / 2 && dataTable?.length > 7 ? 'bottom-0' : ''}`}
                                setModalGetLinkVideo={setOpenModalGetLinkVideo}
                            />
                        </div>
                    );
                },
                width: 40,
            },
        ],
        [dataTable, pagination?.order, selectedFiles?.length]
    );
    const initialState = {
        hiddenColumns: ['id'],
    };

    let timer
    const handleClick = (item: any, event: any) => {
        event.stopPropagation();
        clearTimeout(timer)
        if (event?.detail === 1) {
            timer = setTimeout(() => {
                const isShiftClick = event?.shiftKey;
                if (isShiftClick) {
                    const handleCheckItem = selectedFiles?.find((el) => el?.id === item?.id);
                    if (handleCheckItem && selectedFiles?.length > 0) {
                        const newArr = selectedFiles?.filter((el) => el?.id !== item?.id) || [];
                        setSelectedFiles(newArr);
                    } else setSelectedFiles([...selectedFiles, item]);
                    setPopupDetails(false);
                } else {
                    setSelectedFiles([item]);
                    onSingleClick(item);
                }
            }, 250)
        }
        if (event.detail === 2) onDoubleClickRow(item);
    };

    const onDoubleClickRow = (value) => {
        if (value?.object_type === LibraryTypeEnum.folder) {
            setSelectedFiles([]);
            setPopupDetails(false);
            history.push(`/workspace/${params?.id}/library/${value?.id}`);
        } else previewLibrary(value);
    };

    const onChangeFilter = (filterValues) => {
        filter = filterValues;
        getListDebounce(1);
        localStorage.setItem('libraryFilter', JSON.stringify(filterValues));
    };

    const onCloseSelectFiles = () => {
        setSelectedFiles([]);
    };

    const previewLibrary = (value) => {
        switch (value?.object_type) {
            case LibraryTypeEnum.image:
            case LibraryTypeEnum.video:
            case LibraryTypeEnum.document:
            case LibraryTypeEnum.audio:
            case LibraryTypeEnum.pdf:
            case LibraryTypeEnum.presentation:
            case LibraryTypeEnum.spreadsheet:
                setSelectDocument(value);
                break;
            case LibraryTypeEnum.h5p:
                history.push(`/workspace/${params?.id}/h5p-content/${value.object_id}/preview`);
                break;
            case LibraryTypeEnum.lesson:
                history.push(`/workspace/${params?.id}/lesson/${value.object_id}/preview`);
                break;
            case LibraryTypeEnum.assignment:
                history.push(`/workspace/${params?.id}/assignment/${value.object_id}/preview`);
                break;
            case LibraryTypeEnum.scorm:
                history.push(`/workspace/${params?.id}/scorm/${value.object_id}/preview`);
                break;

            default:
                break;
        }
        setPopupDetails(false);
    };
    const onSingleClick = (item) => {
        setLoadingViewDetails(true);
        switch (item?.object_type) {
            case LibraryTypeEnum.lesson:
                workspaceService
                    ?.getLessonDetail(params?.id, item?.object_id)
                    .then((res) => {
                        const format = {
                            name: res?.title,
                            created_at: res?.created_at,
                            updated_at: res?.updated_at,
                            object_type: LibraryTypeEnum.lesson,
                            created_by: res?.created_by,
                            uid: res?.uid,
                            id: res?.id,
                            object_id: item?.object_id,
                            path: res?.path,
                            sub_object_type: item?.sub_object_type,
                            status: res?.status
                        };
                        setItemSelected(format);
                        setSelectedFile(format);
                    })
                    .finally(() => setLoadingViewDetails(false));
                break;
            case LibraryTypeEnum.scorm:
                lessonService?.getSCORM(params?.id, item?.object_id)
                    .then((res) => {
                        const format = {
                            name: res?.data?.title,
                            created_at: item?.created_at,
                            updated_at: item?.updated_at,
                            object_type: item?.object_type,
                            created_by: item?.created_by,
                            uid: res?.data?.uuid,
                            id: res?.data?.id,
                            object_id: item?.object_id,
                            path: res?.data?.path || null,
                            sub_object_type: item?.sub_object_type,
                            status: res?.data?.status
                        };
                        setItemSelected(format);
                        setSelectedFile(format);
                    })
                    .finally(() => setLoadingViewDetails(false));
                break;
            case LibraryTypeEnum.h5p:
                h5pService
                    .h5pEditPromise({
                        workspaceId: params.id,
                        contentId: item?.object_id,
                    })
                    .then((res: any) => {
                        const format = {
                            name: res?.metadata?.title,
                            created_at: res?.created_at,
                            updated_at: res?.updated_at,
                            object_type: LibraryTypeEnum.h5p,
                            created_by: item?.created_by,
                            uid: res?.uid,
                            object_id: item?.object_id,
                            file_type: res?.metadata?.mainLibrary,
                            path: res?.path,
                            status: item?.status
                        };

                        setItemSelected(format);
                        setSelectedFile(format);
                    })
                    .finally(() => setLoadingViewDetails(false));

                break;
            case 'course': break;
            case LibraryTypeEnum.assignment:
                libraryService
                    .getDetailsAssignment(params?.id, item?.object_id)
                    .then((res) => {
                        const format = {
                            ...res.data,
                            name: res?.data?.title,
                            object_type: item?.object_type,
                            object_id: item?.object_id,
                        };
                        setItemSelected(format);
                        setSelectedFile(format);
                    })
                    .finally(() => setLoadingViewDetails(false));
                break;
            case LibraryTypeEnum.folder:
                libraryService
                    .getDetailsFolder(params?.id, item?.id)
                    .then((res) => {
                        const format = {
                            ...res.data,
                            object_type: item?.object_type,
                            object_id: item?.object_id,
                        };
                        setItemSelected(format);
                        setSelectedFile(format);
                    })
                    .finally(() => setLoadingViewDetails(false));
                break;

            default:
                libraryService
                    .getDetailsFile(params?.id, item?.object_id)
                    .then((res) => {
                        const format = {
                            ...res.data,
                            object_type: item?.object_type,
                            object_id: item?.object_id
                        };
                        setItemSelected(format);
                        setSelectedFile(format);
                    })
                    .finally(() => setLoadingViewDetails(false));
                break;
        }
        setPopupDetails(true);
    };

    const handleShareLink = async (isOpen, value) => {
        if (isOpen) {
            setOpenModalShareLink(isOpen);
            setLinkShare({ workspaceId: params.id, ...value });
        }
    };

    const handleChangeStatus = async (value: boolean) => {
        if (value) {
            let response = await lessonService.updateLesson(params.id, selectedFile.object_id, {
                status: selectedFile?.status == 'draft' ? 'published' : 'draft',
            });
            if (response) {
                getList(1);
                Toastify.success(`The lesson was ${selectedFile?.status == 'draft' ? 'published' : 'unpublished'} successfully!`);
            }
        }
        setOpenModalStatus(false);
    }

    return (
        <>
            <div className="border-b py-4 px-5 flex justify-between outline-none">
                {checkPermission('libraryContent', 'R') ? <FilterSearch
                    onChangeFilter={onChangeFilter}
                    data={dataTable}
                    listSelected={selectedFiles}
                    onClose={onCloseSelectFiles}
                    // isFirstLoad={isFirstLoad}
                    setOpenModalDelete={setOpenModalDelete}
                    setIsMulti={setIsMulti}
                    setOpenModalDuplicate={setOpenModalDuplicate}
                    setLoadingDownload={setLoadingDownload}
                    onMoveFile={() => setOpenModalMoveFile(true)}
                /> : ''}

                {(checkPermission('libraryContentLessonsOrScorm', 'C') || checkPermission('libraryContentAssignment', 'C') || checkPermission('libraryContentFolder', 'C') || checkPermission('libraryContentFile', 'C')) ? <PopoverNewContent setPopup={setOpenModalNewFolder} titleBtn={translator('NEW')} setUpload={setUploadFile} setOpenSCORM={setOpenSCORM} /> : ''}

            </div>
            <div className='p-5'>
                {loadingTable ? (
                    <div className="h-[calc(100vh-287px)] flex items-center justify-center mt-4">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <>
                        <div className="h-[calc(100vh-287px)] custom-scrollbar overflow-y-scroll">
                            <Table
                                data={dataTable || []}
                                columns={columns}
                                initialState={initialState}
                                emptyContent={translator('NO_CONTENT')}
                                emptySubContent={translator('EMPTY_SUB_CONTENT')}
                                emptyIcon={NO_CONTENT_IMG}
                                isMultiSelected
                                listSelected={selectedFiles}
                                actionNoContent={
                                    (checkPermission('libraryContentLessonsOrScorm', 'C') || checkPermission('libraryContentAssignment', 'C') || checkPermission('libraryContentFolder', 'C') || checkPermission('libraryContentFile', 'C')) ?
                                        <PopoverNewContent
                                            setPopup={setOpenModalNewFolder}
                                            titleBtn={translator('NEW_CONTENT')}
                                            stylesPopup="bottom-10"
                                            stylesBtn="w-[140px]"
                                            setUpload={setUploadFile}
                                            setOpenSCORM={setOpenSCORM}
                                        /> : ''
                                }
                            />
                        </div>
                        <div className="flex items-center justify-between border-t border-ooolab_bar_color ">
                            <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block pl-5">
                                {translator('SHOWING_1_50_OF_TOTAL_PAGE', true, {
                                    fromTo: indexPage,
                                    totalPage: pagination?.total,
                                })}
                            </p>
                            <div className="mt-[-1px]">
                                <TablePagination
                                    onClickPagination={(page) => getListDebounce(page)}
                                    perPage={pagination.per_page}
                                    total={pagination.total}
                                    forcePage={pagination.page - 1}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            <ModalCreateFolder
                isOpen={isOpenModalNewFolder}
                onClose={() => setOpenModalNewFolder(false)}
                refreshData={() => getList(1)}
            />
            {isOpenModalRename && (
                <ModalRename
                    isOpen={isOpenModalRename}
                    onClose={() => {
                        setOpenModalRename(false);
                    }}
                    refreshData={() => {
                        getList(1);
                        setItemSelected(null);
                        setPopupDetails(false);
                    }}
                    item={selectedFile}
                />
            )}

            {isOpenModalMoveFile && (
                <ModalMoveFile
                    isOpen={isOpenModalMoveFile}
                    onClose={() => setOpenModalMoveFile(false)}
                    refreshData={() => {
                        getList(1);
                        setItemSelected(null);
                        setSelectedFiles([]);
                        setPopupDetails(false);
                    }}
                    arrItem={selectedFiles}
                    setLoadingMoveFile={setLoadingMoveFile}
                />
            )}
            {isOpenModalDelete ? (
                <ModalDelete
                    isOpen={isOpenModalDelete}
                    onClose={() => setOpenModalDelete(false)}
                    refreshData={() => {
                        getList(1);
                        setItemSelected(null);
                        setPopupDetails(false);
                        setIsMulti(false);
                        setSelectedFiles([]);
                    }}
                    item={selectedFile}
                    isDeleteMulti={isMulti}
                    listItems={selectedFiles}
                />
            ) : null}
            {isOpenModalDuplicate && (
                <DuplicateAction
                    onClose={() => setOpenModalDuplicate(false)}
                    refreshData={() => {
                        getList(1);
                        setItemSelected(null);
                        setPopupDetails(false);
                        setIsMulti(false);
                    }}
                    item={selectedFile}
                    isMulti={isMulti}
                    listItems={selectedFiles}
                />
            )}

            {loadingDownload ? <LoadingPopup message={t('LIBRARY.DOWNLOADING')} loading={loadingDownload} /> : ''}
            {loadingMoveFile ? <LoadingPopup message={t('LIBRARY.MOVING')} loading={loadingMoveFile} /> : ''}
            {processListUpload?.length ? (
                <ProgressUploadFile fileList={processListUpload} onClose={() => setProcessListUpload([])} />
            ) : (
                ''
            )}

            <DocumentPreview
                isOpen={!!selectDocument}
                data={selectDocument}
                closeModal={() => setSelectDocument(null)}
                onDelete={() => {
                    setOpenModalDelete(true);
                    setSelectedFile(selectDocument);
                }}
            />
            {itemSelected && (
                <Popup
                    isOpen={popupDetails}
                    maskClosable={true}
                    onCloseModal={() => {
                        setPopupDetails(false);
                    }}
                    styles="!px-0 top-[53px]"
                    child={
                        <>
                            <DetailsFilePopup item={itemSelected} />
                        </>
                    }
                    data={itemSelected}
                    loading={loadingViewDetails}
                    previewLibrary={previewLibrary}
                    setOpenModalRename={setOpenModalRename}
                    setSelectedFile={setSelectedFile}
                    setOpenModalDelete={setOpenModalDelete}
                    isShowClose={true}
                    setOpenModalDuplicate={setOpenModalDuplicate}
                    setModalShareLink={() => handleShareLink(true, itemSelected)}
                    setLoadingDownload={setLoadingDownload}
                    onMoveFile={() => setOpenModalMoveFile(true)}
                    translator={translator}
                    setModalGetLinkVideo={setOpenModalGetLinkVideo}
                />
            )}
            {openModalShareLink ? (
                <ModalShareLink isOpen={openModalShareLink} onClose={(id: string) => {
                    setOpenModalShareLink(false);
                    if (id) {
                        const newData = dataTable.map((item) => {
                            if (item.id == id) {
                                return {
                                    ...item,
                                    is_shared: !item.is_shared
                                }
                            }
                            return item;
                        })
                        setDateTable(newData);
                    }

                }} value={linkShare} />
            ) : (
                ''
            )}

            {openModalGetLinkVideo && (
                <ModalGetLink
                    isOpen={openModalGetLinkVideo}
                    onClose={() => {
                        setOpenModalGetLinkVideo(false);
                        setSelectedFile(null);
                    }}
                    data={{ ...selectedFile, workspaceId: params?.id }}
                />
            )}

            {!!openSCORM ? <DialogComponent
                isOpen={!!openSCORM}
                onCloseModal={() => setOpenSCORM(false)}
                title={translation("Upload SCORM 1.2 Package")}
                styles="max-w-[700px] h-auto px-6 !py-4 rounded-lg"
                classNameHeader="!py-0"
                child={
                    <UploadScorm
                        onClose={(value) => {
                            if (value) {
                                getList(1);
                            }
                            setOpenSCORM(false);
                        }}
                    />
                }
            /> : null}

            {openModalStatus ? <ConfirmModal
                title={translation(selectedFile?.status == LESSON_STATUS_CODE.draft ? 'LIBRARY.PUBLISH_LESSON' : 'LIBRARY.CONFIRM_UNPUBLISH')}
                isOpen={openModalStatus}
                onClose={(value) => handleChangeStatus(value)}
                textConfirm={translation(selectedFile?.status == LESSON_STATUS_CODE.draft ? 'PUBLISH' : 'UNPUBLISH')}
                textCancel={translation(selectedFile?.status == LESSON_STATUS_CODE.draft ? 'CANCEL' : 'DISCARD_CHANGES')}
            >
                <p className="text-sm">
                    {selectedFile?.status == LESSON_STATUS_CODE.draft ?
                        translation('LIBRARY.MESSAGE_PUBLISH_LESSON') :
                        translation('LIBRARY.MESSAGE_REVERT_LESSON')
                    }</p>
            </ConfirmModal> : null}
        </>
    );
};

export default TabLibrary;
