import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import './Table.scss'
import { UserContext } from 'contexts/User/UserContext';
import { useParams } from 'react-router-dom';
import NoAssignment from 'assets/img/empty-state/noAssignment.png'
import ActionMenu from './ActionMenu';
import { useAppSelector } from 'hooks/hooks';
import { convertSecondToHourMins } from 'utils/commonFun';


interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    assignmentSelected: any[];
    setAssignmentSelected: Function;
    setOrder: Function;
    order: 'asc' | 'desc';
    isAllowAssign: boolean;
    onAssignAssignment: Function;
}

const ViewTable: FC<IViewTable> = ({
    data,
    refreshData,
    assignmentSelected,
    setAssignmentSelected,
    setOrder,
    order,
    isAllowAssign,
    onAssignAssignment,
}) => {
    const { t: translator } = useTranslation();    
    const {
        userState: { result },
    } = useContext(UserContext);
    const params: {id} = useParams();
    const workspaceId = params?.id;
    const { data: {status}} = useAppSelector((state) => state.course);   

    const onSelectAssignment = (assignment) => {
        const hasCheck = assignmentSelected?.find((item) => item?.id === assignment?.id);
        if (hasCheck) {
            setAssignmentSelected(assignmentSelected.filter((item) => item?.id !== assignment.id));
        } else {
            setAssignmentSelected([...assignmentSelected, assignment]);
        }
    };

    const onSelectAllAssignment = () => {
        if (!!assignmentSelected?.length) {
            setAssignmentSelected([]);
        } else setAssignmentSelected(data);
    };

    const columns = useMemo(
        () => [
            isAllowAssign
                ? {
                      Header: () => (
                          <div className="w-full px-2 flex ">
                              <input
                                  type="checkbox"
                                  checked={!!assignmentSelected?.length}
                                  onChange={onSelectAllAssignment}
                              />
                          </div>
                      ),
                      Cell: ({ row }: any) => {
                          const hasCheck = assignmentSelected?.find((item) => item?.id === row?.original?.id);
                          return (
                              <div className="px-2 flex w-full">
                                  <input type="checkbox" checked={hasCheck} onChange={() => onSelectAssignment(row?.original)} />
                              </div>
                          );
                      },
                      accessor: 'assignmentSelected',
                      width: '20',
                  }
                : { accessor: 'assignmentSelected', width: '0' },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ID')} />,
                Cell: ({row}: any) => {
                    return (
                        <RenderTableCell value={row.values?.id} />
                    )
                },
                accessor: 'id',
                 width: "30"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ASSIGNMENT')} />,
                accessor: 'title',
                Cell: ({row}: any) => {
                    return (
                        <RenderTableCell 
                            onClick={() => window.open(`/workspace/${workspaceId}/assignment/${row.original?.id}/preview`)}
                            className='text-primary-500 cursor-pointer font-semibold text-ellipsis truncate bg-transparent' 
                            value={row.values?.title} 
                        />
                    )
                },
                width: "120"
            },
            {
                Header: () => <RenderTableHeader value={translator('TIME_LIMIT')} />,
                accessor: 'time_limit',
                Cell: ({row}: any) => <RenderTableCell value={convertSecondToHourMins(row.values?.time_limit) || '-'} />,
                width: "60"
            },
            {
                Header: () => <RenderTableHeader order={order} isOrder={true} onClick={() => setOrder(order === 'desc' ? 'asc' : 'desc')} className='text-primary-500' value={translator('COURSES.DATE_ADDED')} />,
                accessor: 'added_at',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.values?.added_at).tz(result?.time_zone).format("DD/MM/YYYY")} />,
                 width: "60"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LAST_UPDATED')} />,
                accessor: 'last_updated',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.values?.last_updated).tz(result?.time_zone).format("DD/MM/YYYY")} />,
                 width: "60"
            },
            {
                Header: () => <></>,
                accessor: 'action',
                Cell: ({row}: any) => (
                    <ActionMenu
                        data={row?.original}
                        refreshData={refreshData}
                        isAllowAssign={isAllowAssign && assignmentSelected?.length < 2}
                        assignmentselected={assignmentSelected}
                        onAssignAssignment={() => {
                            onAssignAssignment();
                            setAssignmentSelected([row?.original]);
                        }}
                        position={row?.index > data?.length/2 ? 'bottom-0' : ''}
                    />
                ),
                width: "20"
            },
        ],
        [data, assignmentSelected, isAllowAssign]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={translator('COURSES.NO_ASSIGNMENTS')}
            emptyIcon={NoAssignment}
        />
    );
};

export default ViewTable;
