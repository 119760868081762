import { FC, useMemo } from 'react';
import { CourseProgressHistorySubtab, CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';
import { CircleInformationIcon } from 'assets/icon';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { useAppSelector } from 'hooks/hooks';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    tab: CourseProgressHistorySubtab;
}

const GradebookListTable: FC<IViewTable> = ({ data, refreshData, tab = 'learner' }) => {
    const { t } = useTranslation();
    const { redirectToUrlCourse } = useUrlCourseNavigation();

    const { setting } = useAppSelector((state) => state.course);

    const getValue = (value) => {
        if (value == null) {
            return '-';
        }
        return value + '%';
    };

    const initialState = useMemo(() => {
        const hiddenColumns = [];
        if (setting?.assignment_grade_weight === null) hiddenColumns.push('assignment_score');
        if (setting?.lesson_goal_weight === null) hiddenColumns.push('lesson_goal');
        if (setting?.course_completion_weight === null) hiddenColumns.push('course_completion');
        return { hiddenColumns };
    }, [setting]);

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t(tab === 'learner' ? 'LEARNER' : 'Learning Group')} />,
                accessor: 'Learner/Learning Group',
                Cell: ({ row }: any) => {
                    const learner = row?.original?.learning_group || row?.original?.learner;
                    const enrollmentId = row?.original?.id;
                    const id = row?.original?.learning_group
                        ? `learnerGroupeId=${learner?.id}&subtab=overview&enrollment_id=${enrollmentId}`
                        : `learnerId=${learner?.id}&subtab=assignment&enrollment_id=${enrollmentId}`;
                    return (
                        <div
                            className="flex gap-1 text-sm text-gray-800 ml-2 cursor-pointer"
                            onClick={() =>
                                // history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=gradebook&${id}`)
                                redirectToUrlCourse({
                                    tab: 'gradebook',
                                    subtab: row?.original?.learning_group ? 'learning-group' : 'learner',
                                    ...(row?.original?.learning_group
                                        ? { learningGroupId: row?.original?.learning_group?.id }
                                        : { enrollmentId: row?.original?.id }),
                                    viewGradebook: row?.original?.learning_group ? 'overview' : 'assignment',
                                    ...(row?.original?.learner ? { learnerId: row?.original?.learner?.id } : {}),
                                })
                            }
                        >
                            <div
                                className="flex gap-2 items-center"
                                data-tip={learner?.name || learner?.display_name}
                                data-for={row?.original?.id + '_show-more-people'}
                            >
                                <div
                                    className={`flex-none w-7 h-7 rounded-full overflow-hidden bg-cover`}
                                    style={{
                                        backgroundImage: `url('${
                                            row?.original?.learning_group
                                                ? `https://ui-avatars.com/api?name=${learner?.name}&size=128&format=png`
                                                : learner?.avatar_url
                                        }')`,
                                    }}
                                ></div>
                                <div className="grid">
                                    <div className="truncate text-primary-500 font-semibold text-sm">
                                        {learner?.name || learner?.display_name}
                                    </div>
                                </div>
                                {
                                    //@ts-ignore
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id={row?.original?.id + '_show-more-people'}
                                        getContent={(content) => <div>{content}</div>}
                                    />
                                }
                            </div>
                        </div>
                    );
                },
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.OVERALL_GRADE')} className="!justify-center" />,
                accessor: 'overall',
                Cell: ({ row }: any) => <RenderTableCell className="text-center bg-gray-100" value={getValue(row?.values?.overall)} />,
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.ATTENDANCE')} className="!justify-center" />,
                accessor: 'attendance',
                Cell: ({ row }: any) => <RenderTableCell className="text-center" value={getValue(row?.values?.attendance)} />,
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.COURSE_COMPLETION')} className="!justify-center" />,
                accessor: 'course_completion',
                Cell: ({ row }: any) => <RenderTableCell className="text-center" value={getValue(row?.values?.course_completion)} />,
                width: '140',
            },
            {
                Header: () => (
                    <div className="flex items-center gap-1 max-w-sm ">
                        <RenderTableHeader value={t('COURSES.ASSIGNMENT_GRADE')} className="!justify-center" />
                        <div
                            className="w-4 h-4 cursor-pointer flex items-center"
                            data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                            data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                        >
                            <CircleInformationIcon width="14" height="14" fill="#1F2937" />
                            <TooltipCustom
                                classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                                id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                                place="top"
                                type="light"
                            />
                        </div>
                    </div>
                ),
                accessor: 'assignment_score',
                Cell: ({ row }: any) => <RenderTableCell className="text-center" value={getValue(row?.values?.assignment_score)} />,
                width: '150',
            },
            {
                Header: () => (
                    <RenderTableHeader
                        value={t('ASSIGNMENT_COMPLETION')}
                        className="!justify-center"
                        valueTooltips={t('TOOLTIPS_ASSIGNMENT_COMPLETION')}
                    />
                ),
                accessor: 'assignment_completion',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        className="text-center"
                        value={row?.values?.assignment_completion ? row?.values?.assignment_completion + '%' : '-'}
                    />
                ),
                width: '180',
            },
            {
                Header: () => (
                    <RenderTableHeader
                        value={t('ASSIGNMENT_ON_TIME')}
                        className="!justify-center"
                        valueTooltips={t('TOOLTIPS_ASSIGNMENT_ON_TIME')}
                    />
                ),
                accessor: 'assignment_ontime',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        className="text-center"
                        value={row?.values?.assignment_ontime ? row?.values?.assignment_ontime + '%' : '-'}
                    />
                ),
                width: '170',
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.LESSON_GOAL_COMPLETION')} className="!justify-center" />,
                accessor: 'lesson_goal',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        className="text-center"
                        value={row?.values?.lesson_goal ? getValue(row?.values?.lesson_goal + '%') : '-'}
                    />
                ),
                width: '160',
            },
        ],
        [data, tab]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={t('COURSES.NO_GRADEBOOKS')}
            emptyIcon={NO_CONTENT}
            initialState={initialState}
        />
    );
};

export default GradebookListTable;
