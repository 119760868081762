import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
    AssigmentBuilderHeader,
    AssigmentBuilderSidebarContent,
    AssigmentBuilderSidebarHeader,
    AssignmentInformation,
} from './components';
import { DraftFill, InformationCircleIcon } from 'assets/icon';
import { TYPE_NAVS } from './assignmentBuilder.constants';
import { AssignmentList } from './components/AssignmentList/AssignmentList';
import { AssigmentBuilderContent } from './components/AssignmentBuilderContent/AssigmentBuilderContent';

import './AssignmentBuilder.scss';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { assignmentCreate, assignmentFetch, changeSelectContent, clearError, clearStatusNewContent, assignmentFetchAssociatedLessons, resetAssociatedLessons } from 'store/features/assignmentSlice';
import { Toastify } from '_shared';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

export const AssigmentBuilderPage = () => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const { location }: any = history;
    const dispatch = useAppDispatch();
    const { data, error }: any = useAppSelector((state) => state.assignment);
    const params: { id: string; assignmentId: string } = useParams();
    const [typeSelect, setTypeSelect] = useState(TYPE_NAVS.information);
    const [missingInformation, setMissingInformation] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const { state }: { state: any } = location;


    const createAssignment = async () => {
        if (params?.assignmentId == 'new') {
            const response = await dispatch(
                assignmentCreate({
                    workspaceId: params.id,
                    title: 'Untitled Assignment',
                    parent_id: location?.state?.folderId,
                })
            );
            if (response) {
                if (response?.payload?.data?.id) {
                    history.push(`/workspace/${params.id}/assignment/${response?.payload.data?.id}`, {
                        newContent: true,
                    });
                }
            }
        } else {
            if (!state?.newContent) {
                dispatch(clearStatusNewContent());
            }
            dispatch(
                assignmentFetch({
                    workspaceId: params.id,
                    assignmentId: params.assignmentId,
                })
            );
            dispatch(assignmentFetchAssociatedLessons({
                workspaceId: params.id,
                assignmentId: params.assignmentId,
            }));
            setLoadingPage(false);
        }
    };

    const handleChangeType = (value) => {
        setTypeSelect(value);
        if (data?.sections?.length > 0) {
            dispatch(changeSelectContent(data?.sections[0]));
        }
    };

    useEffect(() => {
        createAssignment();
        return () => {
            dispatch(changeSelectContent(null));
            dispatch(resetAssociatedLessons());
        };
    }, [params]);

    useEffect(() => {
        if (error) {
            Toastify.error('This Assignment has unsaved changes');
            dispatch(clearError(null));
        }
    }, [error]);

    useEffect(() => {
        if (data?.title && data?.sections?.length > 0) {
            setMissingInformation(false);
        }
    }, [data]);

    if (loadingPage) {
        return (
            <div className="w-[100vw] h-[100vh] flex justify-center items-center">
                <CircleSpin className="!text-primary-500" />
            </div>
        );
    }

    return (
        <>
            <div className="assigmentBuilder">
                <div className="assigmentBuilder-header">
                    <AssigmentBuilderHeader
                        setMissingInformation={() => setMissingInformation(!data?.title || !data?.sections?.length)}
                        folderId={data?.path?.slice(-1)?.[0]?.id}
                        isMissingInformation={!data?.title || !data?.sections?.length}
                    />
                </div>
                <div className="assigmentBuilder-main">
                    <div className="assigmentBuilder-sidebar">
                        <AssigmentBuilderSidebarHeader
                            isOpen={typeSelect == TYPE_NAVS.information}
                            onClick={() => {
                                handleChangeType(TYPE_NAVS.information);
                            }}
                        >
                            <span className="assigmentBuilderSidebar-icon text-blue-400">
                                <InformationCircleIcon />
                            </span>
                            {translator('Information')}
                        </AssigmentBuilderSidebarHeader>
                        {typeSelect == TYPE_NAVS.information ? (
                            <AssigmentBuilderSidebarContent>
                                <AssignmentInformation />
                            </AssigmentBuilderSidebarContent>
                        ) : (
                            ''
                        )}
                        <div className={`${missingInformation ? 'missing-content-border' : ''}`}>
                            <AssigmentBuilderSidebarHeader
                                isOpen={typeSelect == TYPE_NAVS.assignment}
                                onClick={() => {
                                    handleChangeType(TYPE_NAVS.assignment);
                                }}
                            >
                                <span className="assigmentBuilderSidebar-icon text-pink-500">
                                    <DraftFill />
                                </span>
                                {translator('COURSES.ASSIGNMENT_MATERIALS')}
                                <span className="assigmentBuilderSidebar-bagde">{data?.sections?.length || 0}</span>
                            </AssigmentBuilderSidebarHeader>
                        </div>
                        {missingInformation && (
                            <div className="missing-content">
                                {translator('FORM_CONST.THIS_IS_REQUIRED_FIELDS_FOR_PUBLISHING_A_ASSIGNMENT')}
                            </div>
                        )}
                        {typeSelect == TYPE_NAVS.assignment ? (
                            <AssigmentBuilderSidebarContent>
                                <AssignmentList />
                            </AssigmentBuilderSidebarContent>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="assigmentBuilder-content">
                        <AssigmentBuilderContent />
                    </div>
                </div>
            </div>
        </>
    );
};
