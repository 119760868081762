import { useContext, useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { CaretIcon, EditLine } from 'assets/icon';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { LEARNER_TYPE, LEARNER_TYPE_ENUM } from 'constant/course.const';
import { BookingLearner } from './BookingLearner';
import { BookingLearnerGroup } from './BookingLearnerGroup';
import { getAvatarUserByName } from 'utils/commonFun';
import { Prompt, useParams } from 'react-router-dom';
import moment from 'moment';
import dayjs from 'dayjs';
import selfBookingService from 'services/selfBooking.service';
import './BookingDetails.scss';
import DialogComponent from 'components/Dialog/DialogComponent';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { clearSlots, removeSlotFromSession, updateSlotToSession } from 'store/features/bookingSlice';
import { BookingForm } from '../BookingForm/BookingForm';
import { UserContext } from 'contexts/User/UserContext';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export const BookingDetails = ({
    enrollmentSelected,
    refreshData,
    updateCredit,
}: {
    enrollmentSelected: any;
    refreshData?: () => void;
    updateCredit?: (value) => void;
}) => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const dispatch = useAppDispatch();
    const { sessions, changed } = useAppSelector((state) => state.booking);
    const params: { id: string; courseId } = useParams();
    const { t } = useTranslation();

    const [total, setTotal] = useState(0);
    const [isOpenModalConfirm, setOpenModalConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasBookingForm, setHasBookingForm] = useState(false);
    const [bookSlot, setBookSlot] = useState();

    useEffect(() => {
        setTotal(0);
        if (sessions?.length > 0) {
            const total = sessions.reduce((total, item) => item.slots?.length + total, 0);
            setTotal(total);
        }
    }, [sessions]);

    const handleBooking = async () => {
        try {
            setLoading(true);
            const bookedSession = sessions.map((item) => item?.slots).flat();
            await selfBookingService.bookavAvailableSlotService(params.id, {
                booking_requests: bookedSession.map((item) => mapingData(item)),
            });
            setLoading(false);
            setOpenModalConfirm(false);
            Toastify.success('Booking successful!');
            dispatch(clearSlots());
            if (updateCredit) {
                updateCredit(total);
            }
            refreshData?.()
        } catch {
            setLoading(false);
            Toastify.error();
        }
    };

    const mapingData = (item) => {
        let value: any = {
            instructor_event_id: item?.instructor?.user_id,
            schedule_time_range_id: item?.session_time?.schedule_time_range_id,
            calendar_time_range_id: item?.session_time?.calendar_event_id,
            course_id: params?.courseId,
            lesson_id: item?.lesson?.id,
            start_time: item?.session_time?.start_time,
            student_id: item?.learners?.[0]?.id,
            note: item?.note,
            end_time: moment(item?.session_time?.start_time).add(item?.session_time?.duration, 'minutes').format(),
        };
        if (enrollmentSelected?.type?.value === LEARNER_TYPE_ENUM.learning_group) {
            delete value.student_id;
            (value.group_id = item?.group_id), (value.group_learner_ids = item?.learners?.map((learner) => learner?.id));
        }
        return value;
    };

    const handleCloseBooking = (value) => {
        if (value) {
            dispatch(updateSlotToSession(value));
        }
        setHasBookingForm(false);
    };
    useEffect(() => {
        return () => {
            dispatch(clearSlots());
        };
    }, []);

    const learnerInsufficient = enrollmentSelected?.learners?.filter((item) => item?.credit_balance?.available_credit < total)

    return (
        <>
            <div className="bookingDetails-header">
                <div className="bookingDetails-title">Booking Details</div>
                {enrollmentSelected ? (
                    <>
                        <div className="bookingDetails-label">{enrollmentSelected?.type?.display_name}:</div>
                        <div className="bookingDetails-learner">
                            {enrollmentSelected?.type?.value === LEARNER_TYPE[0].value ? (
                                <BookingLearner data={enrollmentSelected?.learners?.[0]} />
                            ) : (
                                <BookingLearnerGroup
                                    data={{ ...enrollmentSelected?.learning_group, learners: enrollmentSelected.learners }}
                                    total={total}
                                />
                            )}
                        </div>
                    </>
                ) : (
                    <div className="bookingDetails-label">Fill out detail booking information</div>
                )}
            </div>
            {sessions ? (
                <div className="bookingDetails-sessions">
                    <div className="bookingDetails-title">Sessions List</div>
                    {sessions?.map((item) => (
                        <div key={item.date}>
                            {item?.slots?.length > 0 ? (
                                <div className="sessionsList-date">
                                    {dayjs(item?.date).format('dddd, MMMM DD, YYYY')}
                                </div>
                            ) : null}
                            <div className="sessionsList">
                                {item?.slots.map((slot) => (
                                    <div className="sessionsList-list" key={slot?.session_time?.schedule_time_range_id}>
                                        <Disclosure defaultOpen={true}>
                                            {({ open }) => (
                                                <>
                                                    <Disclosure.Button className="sessionsList-header">
                                                        <span className={open ? 'rotate-90' : ''}>
                                                            <CaretIcon size={24} />
                                                        </span>
                                                        {`${dayjs
                                                            .utc(slot?.session_time?.start_time)
                                                            .tz(result?.time_zone)
                                                            .format('HH:mm')} - ${dayjs
                                                            .utc(
                                                                dayjs(slot?.session_time?.start_time).add(
                                                                    slot?.session_time?.duration,
                                                                    'minutes'
                                                                )
                                                            )
                                                            .tz(result?.time_zone)
                                                            .format('HH:mm')}`}
                                                        <span className="sessionsList-credit">1 Credit</span>
                                                        <button
                                                            className="sessionsList-btn"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                setHasBookingForm(true);
                                                                setBookSlot({ ...slot, slotAvaiable: [slot?.session_time], date: item?.date });
                                                            }}
                                                        >
                                                            <EditLine /> <span>Edit</span>
                                                        </button>
                                                        <button
                                                            className="sessionsList-remove"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                dispatch(
                                                                    removeSlotFromSession(slot?.session_time?.schedule_time_range_id)
                                                                );
                                                            }}
                                                        >
                                                            Remove
                                                        </button>
                                                    </Disclosure.Button>
                                                    <Disclosure.Panel>
                                                        <div className="sessionsList-content">
                                                            <div className="sessionsList-item">
                                                                <div className="sessionsList-label">Instructor:</div>
                                                                <div className="sessionsList-value">
                                                                    <div className="sessionsList-user">
                                                                        <div
                                                                            className="sessionsList-user-avatar"
                                                                            style={{
                                                                                backgroundImage: `url(${slot?.instructor?.avatar_url})`,
                                                                            }}
                                                                        >
                                                                            {!slot?.instructor?.avatar_url
                                                                                ? getAvatarUserByName(slot?.instructor?.display_name)
                                                                                : null}
                                                                        </div>
                                                                        <div className="sessionsList-user-name">
                                                                            {slot?.instructor?.display_name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="sessionsList-item">
                                                                <div className="sessionsList-label">Lesson:</div>
                                                                <div className="sessionsList-value font-semibold">
                                                                    {slot?.lesson?.title}
                                                                </div>
                                                            </div>
                                                            <div className="sessionsList-item">
                                                                <div className="sessionsList-label">{t('DESCRIPTION')}:</div>
                                                                <div className="sessionsList-value">{slot?.note}</div>
                                                            </div>
                                                        </div>
                                                    </Disclosure.Panel>
                                                </>
                                            )}
                                        </Disclosure>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            ) : null}
            <div className="bookingDetails-footer">
                <div className="bookingDetails-footer-item">
                    <div className="bookingDetails-footer-label">Total Sessions</div>
                    <div className="bookingDetails-footer-value">{total}</div>
                </div>
                <div className="bookingDetails-footer-item">
                    <div className="bookingDetails-footer-label">Total Costs</div>
                    <div
                        className={`bookingDetails-footer-value !text-right ${
                            learnerInsufficient?.length > 0
                                ? '!text-red-500'
                                : null
                        }`}
                    >
                        {total} {total > 1 ? 'Credits' : 'Credit'}
                        {enrollmentSelected?.learners?.find((item) => item?.credit_balance?.available_credit < total) && (
                            <>
                                <div 
                                    className="flex text-[10px] text-red-500 items-center"
                                    data-for={`tooltip-insufficient-credit`} 
                                    data-tip={`Learner Insufficient Credit: ${learnerInsufficient?.map(item => item?.name)?.join(', ')}`}
                                >
                                    <ExclamationCircleIcon className="w-4 h-4 mr-1" />
                                    Insufficient Credit
                                </div>
                                {/* @ts-ignore */}
                                <ReactTooltip 
                                    place="right"
                                    type="light"              
                                    id={`tooltip-insufficient-credit`}
                                    getContent={(content) => <div className="max-w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                                    className='shadow-lg opacity-100'
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className="bookingDetails-footer-action">
                    <ButtonPrimary
                        type="button"
                        onClick={() => setOpenModalConfirm(true)}
                        size="L"
                        className="w-full"
                        disabled={total === 0 || learnerInsufficient?.length}
                    >
                        Book {total > 0 && `(${total} ${total > 1 ? 'sessions' : 'session'})`}
                    </ButtonPrimary>
                </div>
            </div>
            <DialogComponent
                title={'Complete Booking'}
                isOpen={isOpenModalConfirm}
                onCloseModal={() => setOpenModalConfirm(false)}
                isShowClose={true}
                styles="overflow-hidden max-w-[480px] !py-4 !px-6"
                child={
                    <div className="text-sm text-gray-800 flex flex-col gap-5 mt-2">
                        <div>
                            <div>
                                <span className="font-bold">{total} sessions</span> will be booked and{' '}
                                <span className="font-bold mt-2">{total} credits</span> will charged.
                            </div>
                            <div>Are you sure you want to complete the booking?</div>
                        </div>
                        <div className="flex justify-center gap-4">
                            <ButtonOutline type="button" size="M" onClick={() => setOpenModalConfirm(false)}>
                                Cancel
                            </ButtonOutline>
                            <ButtonPrimary type="button" size="M" onClick={handleBooking} disabled={loading}>
                                {loading ? <CircleSpin color="text-primary-500" /> : null}
                                Complete Booking
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            <DialogComponent
                isOpen={hasBookingForm}
                onCloseModal={handleCloseBooking}
                title={'Add Booking'}
                styles="max-w-[500px] min-h-[276px] my-0 rounded-lg !py-2 !px-6"
                isShowClose={true}
                child={
                    <BookingForm
                        onClose={handleCloseBooking}
                        bookSlot={bookSlot}
                        data={bookSlot}
                        enrollmentSelected={enrollmentSelected}
                    />
                }
            />
            <Prompt
                when={enrollmentSelected && sessions.length > 0}
                message={() => {
                    return JSON.stringify({
                        message: `Are you sure you want to leave? Any sessions you've added will be lost.`,
                        isBooking: true,
                    });
                }}
            ></Prompt>
        </>
    );
};
