import { useTranslation } from "react-i18next";
import { AddSkillsModal } from "./AddSkillsModal";
import CourseSkillsList from "./CourseSkillsList";
import { useEffect, useState } from "react";
import { SkillType } from "types/Skill.type";
import skillService from "services/skill.service";
import { useParams } from "react-router-dom";
import Pagination from "components/V2/Pagination";
import { getIndexPage } from "utils/commonFun";
import "./index.scss";

export const CourseSkills = ({ isTemplate = false }) => {
    const params: { id: string, courseId: string } = useParams();
    const { t: translate } = useTranslation();
    const [skills, setSkills] = useState<SkillType[]>([]);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        total: 0,
    });
    const [indexPage, setIndexPage] = useState('0-0');

    const getCourseSkills = async (page: number) => {
        try {
            const payload = {
                ...pagination,
                page
            }
            delete payload?.total;
            const res = isTemplate ? await skillService.getCourseTemplateSkills(params?.id, params?.courseId, payload) : await skillService.getCourseSkills(params?.id, params?.courseId, payload);
            setSkills(res?.data?.items || []);
            setPagination({ ...pagination, ...payload, total: res?.data?.total, page: res?.data?.page });
            setIndexPage(getIndexPage(payload?.page, pagination?.per_page, res?.data?.items?.length));
        } catch (error) {

        }
    }

    const handlePagination = (page: number) => {
        getCourseSkills(page);
    };

    useEffect(() => {
        getCourseSkills(1);
    }, []);

    return <div className="courseSkills">
        <div className="courseSkills-header">
            <h3>{translate('SKILLS')}</h3>
            <AddSkillsModal onRefresh={() => getCourseSkills(1)} isTemplate={isTemplate} />
        </div>
        {skills?.length > 0 ? <CourseSkillsList
            data={skills}
            refreshData={() => getCourseSkills(pagination?.page)}
            isTemplate={isTemplate}
        /> :
            <div className="text-sm text-gray-500">{translate('NO_SKILL')}</div>}
        {skills?.length > 0 && <Pagination
            total={pagination.total}
            per_page={pagination.per_page}
            callback={(e) => handlePagination(e)}
            indexPage={indexPage}
            page={pagination?.page}
            className="mt-4"
        />}
    </div>
}