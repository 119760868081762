import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import LogoLight from "assets/images/ooolab/logo.svg";
import { useForm } from "react-hook-form";
import { FORM_CONST } from "constant/form.const";
import { useHistory, useLocation } from "react-router-dom";
import { AppleIcon, GoogleIcon } from "assets/icon/ThirdPartyIcon";
import { CircleTime } from "assets/icon/CircleTime";
import { CircleWarningIcon } from "assets/icon/CircleWarningIcon";
import HeaderSignForm from "components/User/HeaderSignForm/HeaderSignForm";
import authService from "services/auth.service";
const Logo = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo.svg`);



type LoginFormSSO = {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
    whiteLabel?: any;
};

export const LoginFormSSO: FC<LoginFormSSO> = ({ setAuthStorage, whiteLabel }) => {
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false)
    const [errorMsg, setErrorMsg] = useState<string>();
    const location: any = useLocation();
    const history: any = useHistory();
    const {
        register,
        handleSubmit,
        trigger,
        getFieldState,
        getValues,
        formState: { errors, isValid, isDirty },
    } = useForm<any>({
        defaultValues: {
            email: "",
        },
    });

    const onSubmitForm = (values: any) => {
        authService.requestOTP({
            email: values?.email?.trim(),
        }).then((response) => {
            history.push(`/login/otp?email=${encodeURIComponent(values?.email)}`);
        })
            .catch((error) => {
                setErrorMsg(error?.response?.data);
            })
            .finally(() => setLoading(false));

    };

    const handleLoginGoogle = async () => {
        try {
            const res = await authService.loginGoogle();
            if (res?.data?.url) {
                window.location.href = res?.data?.url;
            }
        } catch (error) {

        }
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmitForm)}
            className={`mt-10 flex flex-col space-y-4 animate__animated ${location?.state?.state?.from === "/login?auth=basic"
                ? "animate__fadeInLeft"
                : ""
                } animate__faster`}
        >
            <div className="flex items-center space-x-4 ">
                <button
                    type="button"
                    className="flex-1 w-full flex items-center justify-center space-x-2 rounded-[10px] py-4 border border-gray-300 hover:border-primary-100 max-h-14"
                    onClick={handleLoginGoogle}
                >
                    <GoogleIcon />
                    <span className="text-sm lg:text-base">
                        {translator("SIGN_IN_WITH_GOOGLE")}
                    </span>
                </button>
                <button
                    type="button"
                    className="hidden flex-1 w-full flex items-center justify-center space-x-2 rounded-[10px] py-4 border border-gray-300 hover:border-primary-100 max-h-14"
                    onClick={() => { }}
                >
                    <AppleIcon />
                    <span className="text-sm lg:text-base">
                        {translator("SIGN_IN_WITH_APPLE")}
                    </span>
                </button>
            </div>

            <div className="flex items-center">
                <div className="h-[1px] flex-1 bg-gray-300 mr-2"></div>
                <p className="text-gray-500 text-xs">{translator("OR")}</p>
                <div className="h-[1px] flex-1 bg-gray-300 ml-2"></div>
            </div>

            <div className="">
                <input
                    type="text"
                    className={`p-[18px] max-h-14 block w-full rounded border outline-none border-gray-300 hover:border-primary-100 focus:border-primary-300 ${errors?.email ? "!border-red-500" : ""
                        }`}
                    placeholder="name@domain.com"
                    {...register("email", {
                        required: true,
                        pattern: FORM_CONST.EMAIL_REGEX,
                        setValueAs: (value: string) => value?.trim(),
                    })}
                />
                {errors?.email?.type === "required" && (
                    <p className="mt-1 text-red-500 flex items-center space-x-1">
                        <CircleWarningIcon />
                        <span>{translator("FORM_CONST.REQUIRED_FIELD")}</span>
                    </p>
                )}
                {errors?.email?.type === "pattern" && (
                    <p className="mt-1 text-red-500 flex items-center space-x-1">
                        <CircleWarningIcon />
                        <span>{translator("EMAIL_IS_INVALID")}</span>
                    </p>
                )}
            </div>

            <button
                type="submit"
                className="w-full flex items-center justify-center space-x-2 rounded-[10px] py-3.5 max-h-12 text-white bg-enterprise hover:bg-primary-300 disabled:text-gray-500 disabled:bg-gray-100"
                disabled={loading || !isDirty || !isValid}
            >
                {loading && <CircleTime />}
                <span>{translator("SIGN_IN_WITH_EMAIL")}</span>
            </button>

            <p className="px-3 p-2 bg-gray-50 rounded-xl text-sm font-normal">
                <Trans i18nKey="WE_WILL_EMAIL_YOU_A_CODE_FOR_A_PASSWORD_FREE_SIGN_IN_OR_YOU_CAN_SIGN_IN_MANUALLY_INSTEAD">
                    <span
                        className="text-enterprise hover:text-primary-300 underline cursor-pointer"
                        onClick={() => history.push("/login?auth=basic")}
                    >
                        Sign in manually instead
                    </span>
                </Trans>
            </p>
        </form>
    );
};
