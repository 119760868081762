import React from 'react';
import { Row } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';

import { LESSON_STATUS, LESSON_STATUS_CODE } from 'components/Library/LessonBuilder/constants';
import { User } from '_shared/components/User/User';

interface TableCertificatesProps {
    dataTable: Row[];
}

const TableCertificates = ({ dataTable }: TableCertificatesProps) => {
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const history = useHistory();
    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('SKILL')} />,
                accessor: 'name',
                Cell: ({ row }) => {
                    return (
                        <div
                            className="w-full cursor-pointer"
                            onClick={() => history.push(`/workspace/${params?.id}/management/skills/${row?.original?.id}`)}
                        >
                            <RenderTableCell value={row.values?.name} className="!text-enterprise font-semibold" />
                        </div>
                    );
                },
                width: 120,
            },
            {
                Header: () => <RenderTableHeader value={translator('DESCRIPTION')} />,
                accessor: 'description',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderTableCell value={row.values?.description} />
                    </div>
                ),
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="px-2 h-full items-center">
                            <span
                                className={`text-xs font-normal border rounded px-3 py-[1px] inline-block
                                        ${
                                            row?.original?.status == LESSON_STATUS_CODE.published
                                                ? 'text-green-500 border-green-300 bg-green-50'
                                                : 'text-primary-500 border-primary-300 bg-primary-50'
                                        }
                                    `}
                            >
                                {translator(LESSON_STATUS[row?.original?.status])}
                            </span>
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('LEVELS')} />,
                accessor: 'levels',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderTableCell value={row.values?.levels?.length} />
                    </div>
                ),
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={translator('ASSOCIATED_COURSE_S')} />,
                accessor: 'associated_courses',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <RenderTableCell value={row.values?.associated_courses} />
                    </div>
                ),
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={translator('LEARNERS')} />,
                accessor: 'associated_learners',
                Cell: ({ row }) => (
                    <div
                        onClick={() =>
                            history.push(`/workspace/${params?.id}/management/users`, {
                                skill: { ...row?.original, name: row?.original?.name, value: row?.original?.id },
                                location: "learner_portal",
                            })
                        }
                        className="h-full flex items-center cursor-default cursor-pointer"
                    >
                        <RenderTableCell className="!text-primary-500 font-semibold" value={row.values?.associated_learners} />
                    </div>
                ),
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={translator('CREATED_BY')} />,
                accessor: 'created_by',
                Cell: ({ row }) => (
                    <div className="h-full flex items-center cursor-default">
                        <User
                            avatar={row?.values?.created_by?.avatar_url}
                            name={row?.values?.created_by?.display_name}
                            isOpenNewTab={false}
                            link={`/workspace/${params?.id}/management/users/${row?.values?.created_by?.id}?tab=information`}
                        />
                    </div>
                ),
                width: 80,
            },
        ],
        [dataTable]
    );

    return <Table data={dataTable} columns={columns} noBorder emptyContent={translator('NO_SKILLS')} />;
};

export default TableCertificates;
