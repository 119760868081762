import React from "react";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "assets/icon/FileUploadIcon";

import NEW_H5P from 'assets/img/h5p.png';
import H5P_LIBRARY from 'assets/img/h5pLibrary.png';
import LIBRARY from 'assets/img/library.png';
import "./AddNewLibrary.scss";
import { TYPE_CREATE_CONTENT } from "components/Library/LessonBuilder/constants";
import NEW_SUBMISSION from 'assets/img/submission.png';

export const AddNewLibrary = ({onAddLibrary}) => {
    const { t: translator } = useTranslation();
    
    return (
        <div className="addNewLibrary">
            <div onClick={() => onAddLibrary(TYPE_CREATE_CONTENT.UPLOAD)} className=" btn-action">
                <div>
                    <div className="flex flex-col justify-center items-center w-full gap-2">
                        <FileUploadIcon />
                        <p className="text-sm font-semibold group-hover:text-primary-500">{translator('LIBRARY.UPLOAD_FILE')}</p>
                    </div>
                </div>
            </div>
            <div
                onClick={() => onAddLibrary(TYPE_CREATE_CONTENT.NEW_H5P)}
                className="btn-action"
            >
                <div className="btn-content">
                    <img src={NEW_H5P} />
                    <div>{translator('LIBRARY.NEW_H5P')}</div>
                </div>
            </div>
            <div
                onClick={() => onAddLibrary(TYPE_CREATE_CONTENT.NEW_SUBMISSION)}
                className="btn-action"
            >
                <div className="btn-content">
                    <img src={NEW_SUBMISSION} />
                    <div>{translator("NEW_SUBMISSION")}</div>
                </div>
            </div>
            <div className="line"></div>
            <div
                onClick={() => onAddLibrary(TYPE_CREATE_CONTENT.LIBRARY)}
                className="btn-action"
            >
                <div className="btn-content">
                    <img src={LIBRARY} />
                    {translator('LIBRARY.LIBRARY')}
                </div>
            </div>
            <div
                onClick={() => onAddLibrary(TYPE_CREATE_CONTENT.H5P_LIBRARY)}
                className="btn-action"
            >
                <div className="btn-content">
                    <img src={H5P_LIBRARY} />
                    {translator('LIBRARY.H5P_LIBRARY')}
                </div>
            </div>
        </div>
    )
}