import React from 'react';
import './checkboxStyle.css';

interface CheckboxComponentProps {
    id?: string;
    label?: string;
    propsCheckbox?: any;
    disabled?: boolean;
    checked?: boolean
    type?: string
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({
    id,
    label,
    propsCheckbox,
    disabled= false,
    checked = false,
    type= 'radio'
}) => {
    return (
        <div className="checkbox-container">
            <input {...propsCheckbox} type={type} id={id}  disabled={disabled} checked={checked} />
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
};

export default CheckboxComponent;

type CheckboxTableProps = {
    id?: string;
    label?: string;
    disabled?: boolean;
    checked?: boolean;
    indeterminate?: boolean;
    type?: string;
    className?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckboxTable: React.FC<CheckboxTableProps> = ({
    id,
    label,
    disabled= false,
    checked = false,
    indeterminate = false,
    type= 'checkbox',
    className = '',
    onChange,
}) => {
    const checkboxRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (checkboxRef.current) {
            checkboxRef.current.indeterminate = indeterminate;
        }
    }, [indeterminate]);

    return (
        <div className={`checkbox-container checkbox-table ${indeterminate ? 'indeterminate' : ''} ${className}`}>
            <input ref={checkboxRef} type={type} id={id} disabled={disabled} checked={checked} onChange={onChange} />
            {label && <label htmlFor={id}>{label}</label>}
        </div>
    );
};