import React from 'react';
// PACKAGE
import { Redirect, Switch } from 'react-router-dom';
// COMPONENT
import UserAccountSettingPage from 'pages/UserAccountSettingPage';
import LoginPage from 'pages/LoginPage';
// import Page404 from 'pages/Page404'
import CreatePasswordPage from 'pages/CreatePasswordPage';
import UpdatePasswordPage from 'pages/UpdatePasswordPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import CreateWorkspacePage from 'pages/CreateWorkspacePage';
import WorkspaceHomepage from 'pages/WorkspaceHomePage';
import H5PPage from 'pages/H5PPage';
import H5PPlayerPage from 'pages/H5PPlayerPage';
import EmbededPage from 'pages/EmbededPage';
import H5PBuilderPage from 'pages/H5PBuilderPage';
// TYPES
import { AuthLocalStorageType } from 'types/Auth.type';
// ROUTES
import PrivateRoute from './PrivateRoute';
import CommonRoute from './CommonRoute';
import NormalRoute from './NormalRoute';
import AuthRoute from './AuthRoute';
import GoogleResponsePage from 'pages/GoogleResponsePage';
import AuthTemporaryRoute from './AuthTemporaryRoute';
import WorkspaceTrashBin from 'pages/WorkspaceTrashBin';
import WorkspaceFolderViewPage from 'pages/WorkspaceFolderView';
// CONSTANTS
import { BarType, NewBarType } from 'constant/setupBars.const';
import LessonPage from 'pages/LessonPage';
import AdminPage from 'pages/AdminPage';
import H5PPreviewPage from 'pages/H5PPreviewPage';
import AssignmentPreviewPage from 'pages/AssignmentPreviewPage';
import UpdateInformationPage from 'pages/UpdateInformationPage';
import WorkspaceSettingPage from 'pages/WorkspaceSetting';
import WorkspaceConnectDrive from 'pages/WorkspaceConnectGoogle';
import ContentPlayerPage from 'pages/ContentPlayerPage';
import CoursesPage from 'pages/Courses';
import MyWorkspacePage from 'pages/MyWorkspacePage';
import WorkspaceDrivePage from 'pages/WorkspaceDrive';
import GroupListManagementPage from 'pages/Management/Group/GroupList';
import GroupDetailPage from 'pages/Management/Group/GroupDetail';
import ClassroomListManagementPage from 'pages/Management/Classroom/ClassroomList';
import ClassroomDetailPage from 'pages/Management/Classroom/ClassroomDetail';
import ClassListPage from 'pages/Management/Class/ClassList';
import ClassDetailPage from 'pages/Management/Class/ClassDetail';
import BannerListPage from 'pages/Management/Banner/BannerList';
import BookingClass from 'pages/Management/BookingClass';

import TrashBinMyDrivePage from 'pages/TrashBinMyDrive';
import NewAssignment from 'pages/Management/Assignment/NewAssignment';
import FullLayoutRouter from './FullLayoutRouter';
import AssignmentViewPage from 'pages/AssignmentViewPage';
import AuthWithFullLayoutRoute from './AuthWithFullLayoutRouter';
import Grade from 'pages/Management/GradeSubmission/Grade';
import TeachDashboardPage from 'pages/TeachDashboardPage';
import GradePage from 'pages/GradePage';
import ResourcesPage from 'pages/ResourcesPage';
import RewardPage from 'pages/Management/Reward';
import GroupPage from 'pages/Management/Group/GroupList';
import LearningGroupPage from 'pages/Management/LearningGroup/GroupList';
import LearningGroupDetailPage from 'pages/Management/LearningGroup/GroupDetail';
import RewardDetailsPage from 'pages/Management/RewardDetails';
import BannerDetailPage from 'pages/Management/Banner/BannerDetail';
import NotificationListPage from 'pages/Management/Notification/NotificationList/NotificationList';
import NotificationDetailsPage from 'pages/Management/Notification/NotificationDetails/NotificationDetails';
import Page404 from 'pages/Page404';
import Page403 from 'pages/Page403';
import ClassDashboardPage from 'pages/Management/Report/ClassDashboard';
import TeacherDashboardPage from 'pages/Management/Report/TeacherDashboard';
import ClassDashboardDetailsPage from 'pages/Management/Report/ClassDashboardDetails';
import StudentDashboardPage from 'pages/Management/Report/StudentDashboard';
import StudentDashboardDetailsPage from 'pages/Management/Report/StudentDashboarDetails';
import WorkspaceDashboardPage from 'pages/Management/Report/WorkspaceDashboard';
import TeacherDashboardDetailsPage from 'pages/Management/Report/TeacherDashboarDetails';
import NewBookingPage from 'pages/Management/BookingClass/NewBooking';
import LearningPathListPage from 'pages/Academics/LearningPath/LearningPathListPage';
import CompanyListPage from 'pages/Management/Company/CompanyListPage';
import CompanyDetailPage from 'pages/Management/Company/CompanyDetailPage';
import InstructorAvailabilityPage from 'pages/Dashboard/InstructorAvailabilityPage';
import SalesforceIntegrationPage from 'pages/Management/SalesforceIntegration/SalesforceIntegrationList';
import AvailabilityDashboardPage from 'pages/Management/AvailabilityDashboard';
import H5PAssigmentsPreview from 'components/H5PPreview/H5PAssigmentsPreview';
import { LibraryPage, LibraryPublicPage, H5PPublicPage, LessonPublicPage } from 'pages/LibraryPage';
import { LessonBuilderPage, LessonBuilderOldPage } from 'pages/LessonBuilder';
import { AssigmentBuilderPage } from 'components/Library/AssignmentBuilder/AssignmentBuilder';
import CourseBuilderPage from 'pages/Courses/CourseBuilderPage';
import LearningPathBuilderPage from 'pages/Academics/LearningPath/LearningPathBuilderPage';
import BookingPage from 'pages/BookingPage/BookingPage';
import AssignmentGradePage from 'pages/Management/GradeSubmission/AssignmentGradePage/AssignmentGradePage';
import GradeBook from 'pages/Courses/Grade';
import GradeBookLearner from 'pages/Management/User/UserDetail/Grade';

import UsertListPage from 'pages/Management/User/UserList';
import UserDetailPage from 'pages/Management/User/UserDetail';
import RolesPermissionsListPage from 'pages/Management/RolesPermissions/RolesPermissionsList/RolesPermissionsList';
import UserImportHistoryPage from 'pages/Management/User/UserImportHistory';
import AssignmentCenterPage from 'pages/AssignmentCenterPage/AssignmentCenterPage';
import RolesPermissionDetails from 'components/Management/RolesPermissions/RolesPermissionDetails/RolesPermissionDetails';
import InvoiceListPage from 'pages/EcommercePage/Billing/Student/InvoiceList';
import InvoiceDetail from 'components/Ecommerce/Billing/InvoiceDetail/InvoiceDetail';
import { PaymentGateWaysUpdateState } from 'components/WorkspaceSetting/ECommerce/PaymentGateways/PaymentGateWaysUpdateState';
import TemplateBuilderPage from 'pages/Courses/Template/TemplateBuilderPage';
import UserActivityDashboardPage from 'pages/Management/Report/UserActivityDashboard';
import TeachingHistoryPage from 'pages/TeachingHistoryPage';
import CertificatesPage from 'pages/Management/CertificateManagement/CertificateList';
import CertificateDetailPage from 'pages/Management/CertificateManagement/CertificateDetail';
import SkillPage from 'pages/Management/Skill/SkillList';
import SkillDetailPage from 'pages/Management/Skill/SkillDetail';
import InstructorPortalPage from 'pages/InstructorPortal/InstructorPortal';
import NotificationDetailPage from 'pages/NotificationDetailPage';
import RouteChangeHandler from './RouteChangeHandler';
import DownloadDataPage from 'pages/Management/Report/DownloadData';
import SessionsPage from 'pages/Management/Sessions/page';
import { RecipientAbsenceRequestPage } from 'pages/Management/RecipientAbsenceRequest';
import { LoginOtpPage } from 'pages/LoginPage/LoginOtpPage';
import { ScormPreviewPage } from 'pages/SCORMPreviewPage';

type RoutesProps = {
    isAuthStorage: boolean;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
    storageUserInfo?: AuthLocalStorageType;
    storageUserInfoSession?: AuthLocalStorageType;
};

const routes = (props: RoutesProps) => {
    const { isAuthStorage, setAuthStorage, storageUserInfo, storageUserInfoSession } = props;

    return (<>
        <RouteChangeHandler />
        <Switch>
            {/* GOOGLE DRIVE */}
            <AuthRoute
                path="/google/login"
                component={GoogleResponsePage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <NormalRoute path="/oauth2/:platform/callback" component={LoginPage} setAuthStorage={setAuthStorage} />
            <CommonRoute path="/login/otp" component={LoginOtpPage} isAuthStorage={isAuthStorage} setAuthStorage={setAuthStorage} />
            <CommonRoute path="/login?auth=otp" component={LoginPage} isAuthStorage={isAuthStorage} setAuthStorage={setAuthStorage} />
            <CommonRoute path="/login" component={LoginPage} isAuthStorage={isAuthStorage} setAuthStorage={setAuthStorage} />
            <CommonRoute path="/sso" component={LoginPage} isAuthStorage={isAuthStorage} setAuthStorage={setAuthStorage} />
            <FullLayoutRouter
                path="/contents/:uid/embed"
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                component={EmbededPage}
            />

            <NormalRoute path="/preview-library" component={LibraryPublicPage} setAuthStorage={setAuthStorage} />
            <NormalRoute path="/preview-library-h5p" component={H5PPublicPage} setAuthStorage={setAuthStorage} />
            <NormalRoute path="/preview-library-lesson" component={LessonPublicPage} setAuthStorage={setAuthStorage} />

            <NormalRoute
                path="/password/forgot"
                component={ForgotPasswordPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <NormalRoute path="/forbidden" component={Page403} setAuthStorage={setAuthStorage} />
            <AuthTemporaryRoute
                path="/password/create"
                component={CreatePasswordPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                storageUserInfoSession={storageUserInfoSession}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/create"
                component={CreateWorkspacePage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                documentTitle='Create Workspace'
            />
            <AuthRoute
                path="/password/update"
                component={UpdatePasswordPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <PrivateRoute
                path="/workspace/:id/setting"
                component={WorkspaceSettingPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                documentTitle='Workspace Setting'
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/payment-gateways"
                component={PaymentGateWaysUpdateState}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/gradebook/:classId/:sessionId"
                component={GradePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Dashboard}
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/notifications/:notificationId"
                component={NotificationDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Dashboard}
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/notifications"
                component={InstructorPortalPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Dashboard}
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/instructor-schedule"
                component={TeachDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Dashboard}
                documentTitle='Instructor Schedule'
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/teaching-history"
                component={TeachingHistoryPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Dashboard}
                documentTitle='Teaching History'
            />
            <FullLayoutRouter
                path="/workspace/:id/instructor-dashboard/assignment-center/:assignmentId/grade"
                component={AssignmentGradePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Dashboard}
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/assignment-center"
                component={AssignmentCenterPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Dashboard}
                documentTitle='Assignment Center'
            />
            <PrivateRoute
                path="/workspace/:id/instructor-dashboard/instructor-availability"
                component={InstructorAvailabilityPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Dashboard}
            />

            <AuthWithFullLayoutRoute
                path="/workspace/:id/learning-path/:learningPathId"
                component={LearningPathBuilderPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.ContentManagement}
            />

            <PrivateRoute
                path="/workspace/:id/learning-path"
                component={LearningPathListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.ContentManagement}
                documentTitle='Learning Path'
            />

            <PrivateRoute
                path="/workspace/:id/management/availability-dashboard"
                component={AvailabilityDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/companies/:companyId"
                component={CompanyDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/companies"
                component={CompanyListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Management}
                documentTitle='Companies'
            />
            <PrivateRoute
                path="/workspace/:id/management/salesforce"
                component={SalesforceIntegrationPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                // barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/custom-groups/:groupId"
                component={GroupDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
            />
            <PrivateRoute
                path="/workspace/:id/management/custom-groups"
                component={GroupListManagementPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
                documentTitle='Custom Groups'
            />

            <AuthWithFullLayoutRoute
                path="/workspace/:id/management/courses/:courseId/session/:sessionId/grade"
                component={GradeBook}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />

            {/* <PrivateRoute
                path="/workspace/:id/management/student/:studentId"
                component={StudentDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                path="/workspace/:id/management/instructor/:teacherId"
                component={TeacherDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            /> */}

            <PrivateRoute
                path="/workspace/:id/management/classroom/:classroomId"
                component={ClassroomDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/classroom"
                component={ClassroomListManagementPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/class/:classId"
                component={ClassDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/class"
                component={ClassListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />
            <PrivateRoute
                path="/workspace/:id/management/recipient-absence-request"
                component={RecipientAbsenceRequestPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
                documentTitle='Custom Groups'
            />
            {/* <PrivateRoute
                path="/workspace/:id/management/instructor"
                component={TeacherListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                path="/workspace/:id/management/student"
                component={StudentListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            /> */}

            <PrivateRoute
                path="/workspace/:id/e-commerce/billing/:invoiceId"
                component={InvoiceDetail}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.ECommerce}
            />

            <PrivateRoute
                path="/workspace/:id/e-commerce/billing"
                component={InvoiceListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.ECommerce}
                documentTitle='Billing'
            />

            <AuthWithFullLayoutRoute
                path="/workspace/:id/management/users/:userId/session/:sessionId/grade"
                component={GradeBookLearner}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/management/users/:userId/course/:courseId/assignment/:assignmentId/grade"
                component={AssignmentGradePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />

            <PrivateRoute
                path="/workspace/:id/management/users/:userId"
                component={UserDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.LearningManagement}
            />

            <PrivateRoute
                path="/workspace/:id/management/users"
                component={UsertListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.LearningManagement}
                documentTitle='Users'
            />
            <PrivateRoute
                path="/workspace/:id/management/users-history"
                component={UserImportHistoryPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />
            <PrivateRoute
                path="/workspace/:id/management/roles-permissions/:roleId"
                component={RolesPermissionDetails}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                path="/workspace/:id/management/roles-permissions"
                component={RolesPermissionsListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                component={BannerDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
                path="/workspace/:id/management/banners/:bannerId"
            />

            <PrivateRoute
                path="/workspace/:id/management/banners"
                component={BannerListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
                documentTitle='Banners'
            />
            <PrivateRoute
                path="/workspace/:id/management/booking-class/add-booking"
                component={NewBookingPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                path="/workspace/:id/management/booking-class"
                component={BookingClass}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                path="/workspace/:id/management/banners"
                component={BannerListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
                documentTitle='Banners'
            />
            <PrivateRoute
                path="/workspace/:id/management/resources"
                component={ResourcesPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.ContentManagement}
                documentTitle='Resources'
            />
            <PrivateRoute
                path="/workspace/:id/management/reward/:rewardId"
                component={RewardDetailsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Engagement}
            />
            <PrivateRoute
                path="/workspace/:id/management/reward"
                component={RewardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Engagement}
                documentTitle='Rewards'
            />
            <PrivateRoute
                path="/workspace/:id/management/notification/:notificationId"
                component={NotificationDetailsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
            />
            <PrivateRoute
                path="/workspace/:id/management/notification/new"
                component={NotificationDetailsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
            />
            <PrivateRoute
                path="/workspace/:id/management/notification"
                component={NotificationListPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Communications}
                documentTitle='Notifications'
            />
            <PrivateRoute
                path="/workspace/:id/report/dashboard-course/:classId"
                component={ClassDashboardDetailsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
            />
            <PrivateRoute
                path="/workspace/:id/report/dashboard-instructor/:teacherId"
                component={TeacherDashboardDetailsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
            />
            <PrivateRoute
                path="/workspace/:id/report/dashboard-workspace"
                component={WorkspaceDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
                documentTitle='Workspace Dashboard'
            />

            <PrivateRoute
                path="/workspace/:id/report/dashboard-course"
                component={ClassDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
                documentTitle='Course Dashboard'
            />
            <PrivateRoute
                path="/workspace/:id/report/dashboard-learner/:studentId"
                component={StudentDashboardDetailsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
            />
            <PrivateRoute
                path="/workspace/:id/report/dashboard-learner"
                component={StudentDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
                documentTitle='Learner Dashboard'
            />

            <PrivateRoute
                path="/workspace/:id/report/dashboard-instructor"
                component={TeacherDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
            />

            <PrivateRoute
                path="/workspace/:id/report/dashboard-activity"
                component={UserActivityDashboardPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
            />

            <PrivateRoute
                path="/workspace/:id/report/dashboard-export"
                component={DownloadDataPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Report}
            />

            <PrivateRoute
                path="/workspace/:id/management/group"
                component={GroupPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
                newBar={NewBarType.Report}
            />

            <PrivateRoute
                path="/workspace/:id/management/learning-groups/:groupId"
                component={LearningGroupDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />

            <PrivateRoute
                path="/workspace/:id/management/learning-groups"
                component={LearningGroupPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
                documentTitle='Learning Groups'
            />

            <PrivateRoute
                path="/workspace/:id/management/certificate/:certificateId"
                component={CertificateDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />

            <PrivateRoute
                path="/workspace/:id/management/certificate"
                component={CertificatesPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
                documentTitle='Certificate Management'
            />

            <PrivateRoute
                path="/workspace/:id/management/skills/:skillId"
                component={SkillDetailPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
                documentTitle='Skill Management'
            />

            <PrivateRoute
                path="/workspace/:id/management/skills"
                component={SkillPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
                documentTitle='Skill Management'
            />

            <FullLayoutRouter
                path="/workspace/:id/assignment/class/:classId/new"
                component={NewAssignment}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Management}
            />

            <FullLayoutRouter
                path="/workspace/:id/assignment/:assignmentId/class/:classId/submission"
                component={Grade}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Management}
            />

            <FullLayoutRouter
                path="/workspace/:id/management/courses/:courseId/assignment/:assignmentId/grade"
                component={AssignmentGradePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.LearningManagement}
            />

            <FullLayoutRouter
                path="/workspace/:id/assignment/:assignmentId/class/:classId"
                component={NewAssignment}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Management}
            />

            <PrivateRoute
                path="/workspace/:id/connect-drive"
                component={WorkspaceConnectDrive}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Uploads}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/h5p-content/:contentId/preview"
                component={ContentPlayerPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <NormalRoute
                path="/assignment/:contentId/view"
                component={AssignmentViewPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <PrivateRoute
                path="/workspace/:id/h5p-content/new"
                component={H5PBuilderPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            />
            <PrivateRoute
                path="/workspace/:id/h5p-content/:contentId"
                component={H5PPlayerPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            />

            <PrivateRoute
                path="/workspace/:id/h5p-content"
                component={H5PPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/lesson/:lessonId/preview"
                component={H5PPreviewPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/assignments/preview"
                component={H5PAssigmentsPreview}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <PrivateRoute
                path="/workspace/:id/lesson-old/:lessonId"
                component={LessonBuilderOldPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/lesson/:lessonId"
                component={LessonBuilderPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <PrivateRoute
                path="/workspace/:id/lesson"
                component={LessonPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/assignment/:assignmentId/preview"
                component={AssignmentPreviewPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/scorm/:scormId/preview"
                component={ScormPreviewPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <AuthWithFullLayoutRoute
                path="/workspace/:id/assignment/:assignmentId"
                component={AssigmentBuilderPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <PrivateRoute
                path="/workspace/:id/management/courses/:courseId/booking"
                component={BookingPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.LearningManagement}
            />
            <PrivateRoute
                path="/workspace/:id/management/courses/:courseId/template"
                component={TemplateBuilderPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.LearningManagement
                }
            />
            <PrivateRoute
                path="/workspace/:id/management/courses/:courseId"
                component={CourseBuilderPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.LearningManagement}
            />
            <PrivateRoute
                path="/workspace/:id/management/courses"
                component={CoursesPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.LearningManagement}
                documentTitle='Courses'
            />
            <PrivateRoute
                path="/workspace/:id/management/sessions"
                component={SessionsPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.LearningManagement}
                documentTitle='Sessions'
            />
            {/* <AdminRouter
                path="/workspace/:id/admin"
                component={AdminPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            /> */}
            <PrivateRoute
                path="/workspace/:id/admin"
                component={AdminPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
                newBar={NewBarType.Academics}
            />
            <PrivateRoute
                path="/workspace/:id/user-setting"
                component={UserAccountSettingPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Manage}
                documentTitle='Account Settings'
            />
            <PrivateRoute
                path="/workspace/:id/my-drive/trash"
                component={TrashBinMyDrivePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Library}
            />
            <PrivateRoute
                path="/workspace/:id/trash"
                component={WorkspaceTrashBin}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.Academics}
            />
            <PrivateRoute
                path="/workspace/:id/library/:folderId"
                component={LibraryPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.ContentManagement}
                documentTitle='Library'
            />
            <PrivateRoute
                path="/workspace/:id/library"
                component={LibraryPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                newBar={NewBarType.ContentManagement}
                documentTitle='Library'
            />
            <PrivateRoute
                path="/workspace/:id/folders/:folderId"
                component={WorkspaceFolderViewPage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Uploads}
            />
            <PrivateRoute
                path="/workspace/:id/workspace-drive"
                component={WorkspaceDrivePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Uploads}
            />
            <PrivateRoute
                path="/workspace/:id/:type/"
                component={WorkspaceHomepage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Uploads}
            />
            <PrivateRoute
                path="/workspace/:id/share"
                component={WorkspaceTrashBin}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Uploads}
            />
            <PrivateRoute
                path="/workspace/:id"
                component={WorkspaceHomepage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                barType={BarType.Dashboard}
            />
            <AuthWithFullLayoutRoute
                path="/information/update"
                component={UpdateInformationPage}
                isAuthStorage={isAuthStorage}
                setAuthStorage={setAuthStorage}
                storageUserInfo={storageUserInfo}
            />
            <AuthWithFullLayoutRoute
                path="/workspace"
                component={MyWorkspacePage}
                setAuthStorage={setAuthStorage}
                isAuthStorage={isAuthStorage}
                storageUserInfo={storageUserInfo}
                documentTitle="Workspace"
            />
            <CommonRoute exact path="/">
                <Redirect to="/workspace" />
            </CommonRoute>

            <CommonRoute path="*" component={Page404} setAuthStorage={setAuthStorage} />
        </Switch>
    </>
    );
};

export default routes;
