import { roundNumberWithMins } from 'constant/util.const';
import {
    OverViewItemType,
    ClassOverviewItemType,
    OverViewItemDefineType,
    ActiveReviewItemType,
    VerticalProgressBarType,
    SpecificStudentAssignmentItemType,
    HomeLearningChartType,
    // HomeLearningType,
    // SpecificStudentOverviewType,
    SpecificStudentAttendanceItemType,
    SpecificStudentWorkbookItemType,
    SpecificStudentActiveReviewItemType,
} from 'types/Report.type';

export const convertOverviewData = (_data: OverViewItemType[] | ClassOverviewItemType[], type = 'class') => {
    let arrayTemp: OverViewItemDefineType[] = [];
    if (_data?.length && type === 'class') {
        _data.forEach((el: any) => {
            arrayTemp.push({
                id: el.id,
                name: el.name,
                general: {
                    avg_class_grade: el.avg_grade,
                    summative_assessment: el.avg_overall,
                    attendance: el.avg_attendance,
                    total_apos: el.total_apo,
                },
                online_class: {
                    total_talk_time: '',
                    avg_talk_time: '',
                    avg_percent_talk_time: '',
                    engagement_index: '',
                },
                formative_assessment: {
                    avg_grade: '',
                    avg_time: '',
                    correct_responses: '',
                },
                home_learning: {
                    workbook_completion: el.avg_workbook_completion_rate,
                    avg_active_review_score: el.avg_assignment_score,
                    active_review_completion: el.avg_assignment_completion_rate,
                    avg_active_review_time: el.avg_assignment_total_time_spent,
                },
                gradebook: {
                    overall: el.avg_overall,
                    listening: el.avg_assessment_listening,
                    reading: el.avg_assessment_reading,
                    speaking: el.avg_assessment_speaking,
                    writing: el.avg_assessment_writing,
                },
            });
        });
    } else if (_data?.length && type === 'student') {
        _data.forEach((el: any) => {
            arrayTemp.push({
                id: el.id,
                name: `${el.display_name} ${el?.nick_name ? ` / ${el?.nick_name}` : ''}`,
                general: {
                    avg_class_grade: el.grade,
                    summative_assessment: el.overall,
                    attendance: el.avg_attendance,
                    total_apos: el.total_apo,
                },
                online_class: {
                    total_talk_time: '',
                    avg_talk_time: '',
                    avg_percent_talk_time: '',
                    engagement_index: '',
                },
                formative_assessment: {
                    avg_grade: '',
                    avg_time: '',
                    correct_responses: '',
                },
                home_learning: {
                    workbook_completion: el.avg_workbook_completion,
                    avg_active_review_score: el.avg_assignment_score,
                    active_review_completion: el.avg_assignment_completion_rate,
                    avg_active_review_time: el.avg_assignment_total_time_spent,
                },
                gradebook: {
                    overall: el.overall,
                    listening: el.assessment_listening,
                    reading: el.assessment_reading,
                    speaking: el.assessment_speaking,
                    writing: el.assessment_writing,
                },
            });
        });
    }
    return arrayTemp;
};

export const convertVerticalProgressBarData = (
    _data: ActiveReviewItemType[] | SpecificStudentAssignmentItemType[],
    type = 'class'
) => {
    let arrayTemp: VerticalProgressBarType[] = [];
    if (_data?.length && type === 'class') {
        _data.forEach((el: any) => {
            arrayTemp.push({
                label: el.name,
                percent: el.avg_assignment_score ? Math.round(el.avg_assignment_score * 100) / 100 : 0,
                progress: el.avg_assignment_completion_rate ? Math.round(el.avg_assignment_completion_rate * 100) / 100 : 0,
                mins: el.sum_assignment_total_time_spent ? roundNumberWithMins(el.sum_assignment_total_time_spent) : 0,
            });
        });
    } else if (_data?.length && type === 'student') {
        _data.forEach((el: any) => {
            arrayTemp.push({
                label: el.name,
                percent: el.final_score ? Math.round(el.final_score * 100) / 100 : 0,
                progress: el.completion_percentage ? Math.round(el.completion_percentage * 100) / 100 : 0,
                mins: el.total_time_spent ? roundNumberWithMins(el.total_time_spent) : 0,
            });
        });
    }
    return arrayTemp;
};

// HomeLearningType | SpecificStudentOverviewType
export const convertHomeLearningChartData = (_data: any, type = 'class') => {
    let objTemp: HomeLearningChartType = {
        active_review_grade: '0',
        active_review: 0,
        workbook: 0,
    };
    if (_data && type === 'class') {
        objTemp = {
            active_review_grade: _data.avg_assignment_score,
            active_review: _data.avg_assignment_completion_rate,
            workbook: _data.avg_workbook_completion_rate,
        };
    } else if (_data && type === 'student') {
        objTemp = {
            active_review_grade: _data.avg_assignment_score,
            active_review: _data.avg_assignment_completion_rate,
            workbook: _data.avg_workbook_completion,
        };
    }
    return objTemp;
};

export const convertAttendanceDataSpecificStudent = (data: SpecificStudentAttendanceItemType[]) => {
    if (data?.length) {
        return data.map((el: SpecificStudentAttendanceItemType) => ({
            id: el.id,
            email: el.email,
            first_name: el.first_name,
            last_name: el.last_name,
            display_name: `${el.display_name} ${el.nick_name ? ` / ${el.nick_name}`: ''}`,
            avatar_url: el.avatar_url,
            avg_attendance: el.avg_attendance,
            avg_punctuality: el.avg_punctuality,
            avg_workbook_completion_rate: undefined,
            sessions: el.attendances.map((elm) => ({
                session_id: elm.session_id,
                start_time: elm.start_time,
                end_time: elm.end_time,
                lesson_id: undefined,
                lesson_title: elm.lesson_title,
                attendance_type: elm.attendance_type,
                workbook_review: undefined,
            })),
        }));
    }
    return [];
};

export const convertActiveReviewDataSpecificStudent = (data: SpecificStudentActiveReviewItemType[]) => {
    if (data?.length) {
        return data.map((el: SpecificStudentActiveReviewItemType) => ({
            id: el.id,
            email: el.email,
            first_name: el.first_name,
            last_name: el.last_name,
            display_name: `${el.display_name} ${el.nick_name ? ` / ${el.nick_name}`: ''}`,
            avatar_url: el.avatar_url,
            avg_assignment_score: el.avg_assignment_score,
            avg_assignment_completion_rate: el.avg_assignment_completion,
            avg_assignment_total_time_spent: el.avg_assignment_completion_time,
            assignments: el.assignments.map((elm) => ({
                id: elm.id,
                name: elm.name,
                start_time: elm.session_end_time,
                end_time: elm.end_time,
                final_score: elm.final_score,
                completion_percentage: elm.completion_percentage,
                completion_date: undefined,
                total_time_spent: elm.total_time_spent,
            })),
        }));
    }
    return [];
};

export const convertWorkbookDataSpecificStudent = (data: SpecificStudentWorkbookItemType[]) => {
    if (data?.length) {
        return data.map((el: SpecificStudentWorkbookItemType) => ({
            id: el.id,
            email: el.email,
            first_name: el.first_name,
            last_name: el.last_name,
            display_name: `${el.display_name} ${el.nick_name ? ` / ${el.nick_name}`: ''}`,
            avatar_url: el.avatar_url,
            avg_attendance: undefined,
            avg_punctuality: undefined,
            avg_workbook_completion_rate: el.avg_workbook_completion,
            sessions: el.workbooks.map((elm) => ({
                session_id: elm.session_id,
                start_time: elm.start_time,
                end_time: elm.end_time,
                lesson_id: undefined,
                lesson_title: elm.lesson_title,
                attendance_type: undefined,
                workbook_review: elm.workbook_review,
            })),
        }));
    }
    return [];
};

const escapeSpecialCharsCsv = (value) => {
    if (!!value) {
        value = value.toString();
        value = value.replace(/"/g, '""');

        // handle phone number, ex: +84123456789
        // handle full date-time, ex: 14/08/2024 17:00:00
        if (value.startsWith('+') || /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2}/.test(value)) {
            value = `="${value}"`;
        } else if (
            value.includes(',') ||
            value.includes('\n') ||
            value.includes('"') ||
            value.includes(';') ||
            value.includes('\t') ||
            value.includes('\r') ||
            value.includes('\0') ||
            value.includes('\v') ||
            value.includes('\b') ||
            value.includes('#') ||
            value.includes('\\') ||
            value.startsWith(' ') ||
            value.endsWith(' ') ||
            value.includes("'")
        ) {
            value = `"${value}"`;
        }
    }
    return value;
};

export const downloadCSV = (rows: string[][], file_name: string) => {
    const csvContent =
        'data:text/csv;charset=utf-8,' +
        rows
            .map((row) => {
                const newRow = row.map((item) => item); //escapeSpecialCharsCsv(item)
                return newRow.join(',');
            })
            .join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', file_name + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const downloadCSVWithWorker = (csvContent, fileName) => {
    const BOM = '\uFEFF'; // helps the file reading software recognize the correct encoding
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};