import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, XIcon } from '@heroicons/react/solid';
import { ButtonIcon, ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useForm, Controller } from 'react-hook-form';
import { InputField } from 'components/InputForm/InputForm';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { UploadAdapter } from 'utils/commonFun';
import assignmentService from 'services/assignment.service';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { assignmentCreateContent, changeLibraryTypeAdd, changeSelectContent, updateSection } from 'store/features/assignmentSlice';

const configCkEditor = {
    toolbar: [
        "undo", "redo",
        "heading", "styles",
        "|", "bold", "italic", "underline", "strikethrough",
        "|", "fontSize", "fontColor", "fontBackgroundColor",
        "|", "alignment", "outdent", "indent",
        "|", "bulletedList", "numberedList", "todoList",
        "|", "link", "blockQuote", "imageUpload", "mediaEmbed", "insertAudio",
        "|", "insertTable",
    ],
    link: {
        addTargetToExternalLinks: true,
    },
    placeholder: "Enter Description here",
    mediaEmbed: {
        previewsInData: true,
        providers: [
            {
                name: 'custom-audio',
                url: /^https?:\/\/.*\.(mp3|wav|ogg)$/,
                html: match => `<audio controls src="${match.input}" style="width:100%"></audio>`
            },
            {
                name: 'youtube',
                url: /^https?:\/\/(www\.)?(youtube\.com|youtu\.be)\/.+$/,
            },
            {
                name: 'vimeo',
                url: /^https?:\/\/(www\.)?vimeo\.com\/.+$/,
            },
            {
                name: 'dailymotion',
                url: /^https?:\/\/(www\.)?dailymotion\.com\/.+$/,
            },
        ]
    },

};

const RecordingTask = ({ onCloseModal, title, dataEdit
}) => {
    const {
        control,
        formState: { errors, dirtyFields },
        watch,
        register,
        reset,
        setValue,
        handleSubmit
    } = useForm({ mode: 'onChange' });
    const params: any = useParams();
    const { t } = useTranslation();
    const [isPannel, setIsPannel] = useState<boolean>(true);
    const [descLength, setDescLength] = useState(0);
    const { data: assignment, assignmentSelect } = useAppSelector((state) => state.assignment);
    const dispatch = useAppDispatch();


    const removeTags = (str) => {
        if ((str === null) || (str === ''))
            return;
        else
            str = str?.toString();
        return str?.replace(/(<([^>]+)>)/ig, '');
    }
    useEffect(() => {
        const desc = removeTags(watch('description') || '')?.length;
        setDescLength(desc)
    }, [watch('description')]);


    useEffect(() => {
        if (dataEdit?.library_content?.object_id) {
            assignmentService.getRecordingTask(params?.id, dataEdit?.library_content?.object_id).then((res) => {
                reset({
                    title: res?.data?.title,
                    description: res?.data?.description
                })
            })
        }
    }, [dataEdit?.library_content?.object_id])

    const createRecordingTask = (data) => {
        const payload = {
            title: data?.title,
            instruction: data?.description
        };
        assignmentService
            .createRecordingTask(params?.id, payload)
            .then((res) => {
                dispatch(
                    assignmentCreateContent({
                        name: res?.data?.title,
                        workspaceId: params?.id,
                        assignmentId: params?.assignmentId,
                        object_id: res?.data?.id,
                        object_type: 'recording_task',
                        instruction: res?.data?.description,
                        order_number: assignment?.sections?.length > 0 ? assignment?.sections?.length + 1 : 0,
                    })
                ).then(res => {
                    dispatch(changeSelectContent(res?.payload));
                    dispatch(changeLibraryTypeAdd(null))
                })
                Toastify.success();
            })
            .catch(() => {
                Toastify.error();
            });
    };

    const updateRecordingTask = (data) => {
        const payload = {
            title: data?.title,
            instruction: data?.description
        };
        assignmentService
            .updateRecordingTask(params?.id, dataEdit?.library_content?.object_id, payload)
            .then((res) => {
                let recording = {
                    id: assignmentSelect?.id,
                    order_number: assignment?.sections?.length > 0 ? assignment?.sections?.length + 1 : 0,
                    library_content: {
                        ...assignmentSelect?.library_content,
                        name: data.title,
                        instruction: data?.description,
                    },
                };
                const newSections = (assignment?.sections || [])?.map((section) => {
                    if (section?.library_content?.object_id === recording?.library_content?.object_id) return recording;
                    return section;
                });
                dispatch(updateSection(newSections));
                Toastify.success();
                onCloseModal();
            })
            .catch(() => {
                Toastify.error();
            });
    };
    const onSubmit = (values) => {
        if (dataEdit?.library_content?.object_id) {
            updateRecordingTask(values)
        } else {
            createRecordingTask(values)

        }

    };

    return (
        <div className='from-library'>
            <div className="header">
                <div className="header-title">{title}</div>
                <ButtonIcon onClick={onCloseModal} >
                    <XIcon className="header-icon" />
                </ButtonIcon>
            </div>

            <div className='pt-6 px-8'>
                {isPannel ?
                    <div className="border border-blue-300 bg-blue-50 rounded-xl p-3 flex gap-3 mb-5">
                        <InformationCircleIcon className="flex-none w-6 h-6 text-primary-500" />
                        <p className="text-gray-800 text-sm">{t('ASSIGNMENT.SUB_NEW_RECORDING_TASK')}</p>
                        <div className=" ml-auto flex  items-center gap-2.5">
                            <ButtonIcon onClick={() => setIsPannel(false)}>
                                <XIcon className="w-5 h-5" />
                            </ButtonIcon>
                        </div>
                    </div> : ''}

            </div>
            <form className=" mt-4 " onSubmit={handleSubmit(onSubmit)}>
                <div className='mx-8'>
                    <InputField
                        label={t('ASSIGNMENT.TITLE')}
                        placeholder={t('ASSIGNMENT.ENTER_TITLE_HERE')}
                        name="title"
                        errors={errors}
                        isRequired
                        register={register('title', {
                            required: 'This field is required!',
                            maxLength: {
                                value: 255,
                                message: t('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 }),
                            },
                        })}
                    />
                    <div className="mt-4">
                        <label className="text-gray-800 text-xs font-semibold flex items-center gap-0.5 mb-1">
                            <span>{t('ASSIGNMENT.DESCIPTION')}</span>
                        </label>
                        {/*@ts-ignore*/}
                        <Controller
                            name="description"
                            control={control}
                            {...register('description')}
                            render={() => (
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={watch('description')}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue('description', data);
                                    }}
                                    config={configCkEditor}
                                    onReady={(eventData) => {
                                        eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
                                            return new UploadAdapter(loader);
                                        };
                                    }}
                                />
                            )}
                        />
                        <div className='flex justify-between mt-1'>
                            <div>
                                {descLength >= 1000 && <div className='text-xs text-red-500'>{t('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 1000 })}</div>}
                            </div>
                            <div className='text-xs text-right text-gray-500'>{descLength || 0}/1000</div>
                        </div>
                    </div>
                </div>

                <div className=' mt-5 flex justify-center gap-2 px-8  border-t border-gray-300 py-5'>
                    <ButtonOutline type="button" onClick={onCloseModal} >{t('CANCEL')}</ButtonOutline>
                    <ButtonPrimary disabled={!dirtyFields} type="submit" onClick={() => { }}>{dataEdit ? t('UPDATE') : t('ADD')}</ButtonPrimary>
                </div>
            </form>
        </div>
    );
};

export default RecordingTask;
