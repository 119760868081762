import React, { useEffect, useState } from 'react';
import SearchBox from 'components/V2/SearchBox';
import Pagination from 'components/V2/Pagination';
import { TableLearningMaterials } from './TableLearningMaterials';
import courseService from 'services/course.service';
import { useParams } from 'react-router-dom';
import { getIndexPage } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useDebouncedState } from 'hooks/useDebounce';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import './learning-materials.scss';

export const LearningMaterials = () => {
    const params: { id: string; courseId: string } = useParams();
    const query = useQueryParamsURL();

    const [keyword, setKeyword] = useDebouncedState('');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'title',
        total: 0,
    });

    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const enrollmentId = query.get('enrollmentId') || query.get('enrollment_id');
    const learnerId = query.get('learnerId');

    const fetchCourseGradebookLearningMaterials = (page = 1) => {
        setLoading(true);

        const payload = {
            ...pagination,
            page: page || pagination.page,
            title: keyword,
        };

        if (!payload?.title) delete payload?.title;
        delete payload?.total;

        const getLearningMaterials = !!learnerId
            ? courseService.getLearnerLearningMaterials(workspaceId, courseId, enrollmentId, learnerId, payload)
            : courseService.getCourseLearningMaterials(workspaceId, courseId, enrollmentId, payload);

        getLearningMaterials
            .then((response) => {
                const { items, total, page, order } = response.data;
                setData(items);
                setPagination({ ...pagination, total, page, order });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!enrollmentId) return;
        fetchCourseGradebookLearningMaterials();
    }, [keyword]);

    return (
        <>
            <div className="learning_materials">
                <div className="mb-4">
                    <SearchBox className="max-w-[240px]" onSubmit={(value) => setKeyword(value)} />
                </div>
                <div className={`h-[500px] overflow-auto`}>
                    {loading ? (
                        <div className="h-full flex items-center justify-center pt-10">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <TableLearningMaterials data={data} />
                    )}
                </div>
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(page) => fetchCourseGradebookLearningMaterials(page)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
        </>
    );
};
