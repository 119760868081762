import { useContext, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { STATUS_COLOR } from 'constant/util.const';
import { UserContext } from 'contexts/User/UserContext';
import { ImageAvatar } from 'components/Image';
import { EditIcon } from 'assets/icon';
import { InputField } from 'components/InputForm/InputForm';
import courseService from 'services/course.service';
import { Toastify } from '_shared';
import { checkPermission } from 'utils/commonFun';

export const ModalTransactionDetail = ({ translator, workspaceId, creditDetail, transactionDetail }) => {
    const [isEditNotes, setIsEditNotes] = useState(false);
    const [data, setData] = useState(null);

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        reset,
        watch,
        setFocus,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            note: null,
        },
    });

    const {
        userState: { result },
    } = useContext(UserContext);

    const fetchTransactionDetail = () => {
        courseService
            .getTransactionByIdCreditBalance(workspaceId, creditDetail?.credit_balance?.id, transactionDetail?.id)
            .then((res) => {
                const newData = { detail: creditDetail, transaction: res?.data };
                setData(newData);
                reset({ note: newData?.transaction?.note || null });
            });
    };

    const handleUpdateNote = () => {
        const payload = {
            note: getValues()?.note || null,
        };

        courseService
            .updateNoteTransactionByIdCreditBalance(workspaceId, creditDetail?.credit_balance?.id, transactionDetail?.id, payload)
            .then((res) => Toastify.success())
            .catch((res) => Toastify.error())
            .finally(() => setIsEditNotes(false));
    };

    const creditObject = useMemo(() => {
        let amount = data?.transaction?.amount;
        let color = '';
        if (data?.transaction?.txn_type === 'increase') {
            amount = `+${amount}`;
            color = STATUS_COLOR.increase;
        } else if (data?.transaction?.txn_type === 'decrease') {
            amount = `-${amount}`;
            color = STATUS_COLOR.decrease;
        }

        return { amount, color };
    }, [data]);

    useEffect(() => {
        if (creditDetail?.credit_balance?.id && transactionDetail?.id) fetchTransactionDetail();
    }, [creditDetail, transactionDetail]);

    useEffect(() => {
        if (isEditNotes) setFocus('note', { shouldSelect: true });
    }, [isEditNotes]);

    return (
        <div className="-mx-5 px-6 pt-4 border-t border-gray-300 grid grid-cols-6 gap-4 text-sm">
            <>
                <p className="col-span-2 font-semibold">{translator('LEARNER')}</p>
                <p className="col-span-4 truncate flex items-center space-x-2">
                    <ImageAvatar imageSrc={creditDetail?.user?.avatar_url} />
                    <span className="truncate text-xs font-semibold text-enterprise">{creditDetail?.user?.display_name}</span>
                </p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('ENROLLMENT')}</p>
                <p className="col-span-4 break-words">
                    {creditDetail?.course_enrollment?.id || '-'}
                </p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('DESCRIPTION')}</p>
                <p className="col-span-4 break-words">
                    {/* {data?.transaction?.description && data?.transaction?.session?.start_time
                        ? `${data?.transaction?.description} [${dayjs(data?.transaction?.session?.start_time)
                              .tz(result?.time_zone || 'UTC')
                              .format('DD/MM/YYYY HH:mm')}]`
                        : data?.transaction?.description} */}
                    {data?.transaction?.description + (data?.transaction?.session ? ` (ID ${data?.transaction?.session?.id})` : '')}
                </p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('CREDIT_S')}</p>
                <p className="col-span-4" style={{ color: creditObject?.color }}>
                    {creditObject?.amount}
                </p>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('NOTES')}</p>
                <div className="col-span-4">
                    {!isEditNotes ? (
                        <div className="flex items-center">
                            <p className="break-all">{getValues('note')}</p>
                            {!isEditNotes && checkPermission('courseCreditBalance', 'U') && (
                                <p
                                    className="ml-2 flex-none cursor-pointer text-enterprise flex items-center space-x-1"
                                    onClick={() => setIsEditNotes(true)}
                                >
                                    <EditIcon fill="#0071CE" />
                                    <span>{translator(!watch('note') ? 'ADD_NOTE' : 'EDIT_NOTE')}</span>
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className="flex space-x-2 w-full">
                            <InputField
                                placeholder={translator('ENTER_NOTE_HERE')}
                                name="note"
                                errors={errors}
                                register={register('note')}
                                classNameInput="max-h-[22px] text-xs"
                                className="w-[85%]"
                                maxLength={1000}
                            />
                            <span
                                className={`mt-0.5 ${!!errors['note'] ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                onClick={handleUpdateNote}
                            >
                                <CheckIcon className="w-4 h-4 text-enterprise" />
                            </span>
                            <span
                                className="mt-0.5 cursor-pointer"
                                onClick={() => {
                                    setValue('note', data?.transaction?.note);
                                    setIsEditNotes(false);
                                }}
                            >
                                <XIcon className="w-4 h-4 text-gray-500" />
                            </span>
                        </div>
                    )}
                </div>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('UPDATED_BY')}</p>
                <div className="col-span-4 truncate flex items-center space-x-2">
                    {!!data?.transaction?.last_editor && (
                        <>
                            <ImageAvatar imageSrc={data?.transaction?.last_editor?.avatar_url} />
                            <span className="truncate text-xs font-semibold text-enterprise">
                                {data?.transaction?.last_editor?.display_name}
                            </span>
                        </>
                    )}
                </div>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('TRANSACTED_BY')}</p>
                <div className="col-span-4 truncate flex items-center space-x-2">
                    {!!data?.transaction?.creator && (
                        <>
                            <ImageAvatar imageSrc={data?.transaction?.creator?.avatar_url} />
                            <span className="truncate text-xs font-semibold text-enterprise">
                                {data?.transaction?.creator?.display_name}
                            </span>
                        </>
                    )}
                </div>
            </>
            <>
                <p className="col-span-2 font-semibold">{translator('TRANSACTION_DATE')}</p>
                <p className="col-span-4">
                    {data?.transaction?.created_at
                        ? dayjs(data?.transaction?.created_at)
                              .tz(result?.time_zone || 'UTC')
                              .format('DD/MM/YYYY HH:mm:ss')
                        : ''}
                </p>
            </>
        </div>
    );
};
