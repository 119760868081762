import * as React from 'react';
// PACKAGE
import { BrowserRouter, matchPath } from 'react-router-dom';
// COMPONENT
import Routes from './routes';
// import SCORM 1.2 packages
import 'packages/SCORM/init';
import 'packages/SCORM/scormAPI';
// UTILS
import {
    getLocalStorageAuthData,
    getAttrLocalStorage,
    getCurrentEmail,
    getSessionStorageAuthData,
    setDeviceID,
} from './utils/handleLocalStorage';
import { handleLogout } from './utils/handleLogout';
// CONTEXT
import { UserContext } from './contexts/User/UserContext';
import { AuthContext } from './contexts/Auth/AuthContext';

import CancelChanges from 'assets/img/cancel.png';
import axiosInstance from 'services/restful.service';
import { AuthAction } from 'types/Auth.type';
import { SET_AUTH } from 'actions/auth.action';
import { getLocalCookie } from 'utils/handleAuthorized';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { AUTH_CONST } from 'constant/auth.const';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import UploadProvider, { UploadContext } from 'contexts/Upload';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary } from '_shared';
import { initializeApp } from "firebase/app";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import { GET_WORKSPACE_DETAIL } from 'actions/workspace.action';
import { AlertIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import absenceRequestService from 'services/absenceRequest.service';
import { checkPermission } from 'utils/commonFun';
import { setInitialized } from 'store/features/scormSlice';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: "clms-web-learningos.firebaseapp.com",
    projectId: "clms-web-learningos",
    storageBucket: "clms-web-learningos.appspot.com",
    messagingSenderId: "830328277222",
    appId: "1:830328277222:web:d58e9f47aae8d4907e5327",
    measurementId: "G-Y6K6J2S2SG"
};

let app, analytics;

if (process.env.REACT_APP_S3_PATH == 'production') {
    app = initializeApp(firebaseConfig);
    analytics = getAnalytics(app);
}

export interface AppContainerProps {
    isAuthStorage: boolean;
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

const AppContainer: React.FC<AppContainerProps> = ({
    isAuthStorage,
    setAuthStorage,
}) => {
    const dispatch = useAppDispatch();
    const storageUserInfo = getLocalStorageAuthData();
    const storageUserInfoSession = getSessionStorageAuthData();
    const [modalState, setModalState] = React.useState<{
        title: string,
        message: string;
        func: ((e: boolean) => void) | undefined;
        page?: string;
        isImg?: boolean;
        textCancel?: string;
        textConfirm?: string;
    }>({
        title: '',
        message: '',
        func: undefined,
        page: '',
        isImg: true,
        textCancel: 'Cancel',
        textConfirm: 'Confirm',
    });

    const handleClosePromptModal = () =>
        setModalState({ ...modalState, message: '', func: undefined, page: '' });

    // HANDLE EXPIRED
    const userCtx = React.useContext(UserContext);
    const {
        dispatch: userDispatch,
        userState: { err, result },
    } = userCtx;
    const authCtx = React.useContext(AuthContext);
    const authDispatch = authCtx.dispatch;
    const currentEmail = getCurrentEmail();
    const { getWorkspaceDetailState, dispatch: dispatchWorkspace } = React.useContext(GetWorkspaceContext);
    const {
        result: { membership },
    } = getWorkspaceDetailState;
    const token: string | undefined = getAttrLocalStorage(
        'access_token',
        `user_info_${currentEmail}`
    );

    const currentPath: any = matchPath(window.location.pathname, {
        path: '/workspace/:id',
        strict: false,
    });
    const {t: translator} = useTranslation();
    const [hasAbsenceEmpty, setHasAbsenceEmpty] = React.useState(false);

    const getCountAbsenceRequestRecipients = async () => {
        try {
            const res: any = await absenceRequestService.countAbsenceRequestRecipients(membership?.workspace_id);
            setHasAbsenceEmpty(res?.data?.need_add_recipient);
        } catch (error) {
            
        }
    }
    
    React.useEffect(() => {
        const object = {
            id: result?.id || null,
            email: result?.email || null,
            workspace_id: membership?.workspace_id || null,
        };
        if (process.env.REACT_APP_S3_PATH == 'production') {
            setUserProperties(analytics, object);
        }
        if (membership?.workspace_id && result?.id && checkPermission('course', 'R')) {
            getCountAbsenceRequestRecipients();
        }
    }, [membership, result]);

    React.useLayoutEffect(() => {
        if (currentPath?.params?.id) {
            axiosInstance.defaults.headers["x-workspace-id"] = currentPath?.params?.id
        }
    }, [])

    React.useEffect(() => {
        const fpPromise = FingerprintJS.load();
        fpPromise
            .then(fp => fp.get())
            .then(result => {
                axiosInstance.defaults.headers["X-Device-ID"] = result.visitorId;
                setDeviceID(result.visitorId);
            });

        dispatchWorkspace({
            type: GET_WORKSPACE_DETAIL.REQ_SET_WORKSPACE_FEATURE_FLAGS,
            value: JSON.parse(process.env.REACT_APP_FEATURE_FLAGS),
        });
    }, []);

    React.useEffect(() => {
        if (err?.error?.name === AUTH_CONST.UNAUTHORIZED) {
            handleLogout(authDispatch, setAuthStorage);
        }
        if (err?.error?.name === AUTH_CONST.TOKEN_EXPIRED) {
            localStorage.removeItem('user-data');
            localStorage.removeItem('userRole');
            setAuthStorage(false);
        }
    }, [err]);

    React.useEffect(() => {
        if (hasAbsenceEmpty) {
            document.body.classList.add("bodyTop");
        } else {
            document.body.classList.remove("bodyTop");
        }
    }, [hasAbsenceEmpty]);

    // }, [err]);

    const axiosInstanceIntceptorWithCookie = (config: AxiosRequestConfig) => {
        const cookieToken = getLocalCookie();
        // Do something before request is sent
        const { method } = config;
        const newHeaders = { ...config };
        if (method !== 'get' && cookieToken) {
            newHeaders.headers['X-CSRF-TOKEN'] = cookieToken;
        }
        newHeaders.headers['X-Request-Origin'] = 'cms';
        return newHeaders;
    };
    axiosInstance.interceptors.response.use(
        function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        },
        function (error: AxiosError) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            const { data, status } = error?.response || { data: null, status: null };
            if (
                status === 401 &&
                data?.error?.name === AUTH_CONST.UNAUTHORIZED
            ) {
                authDispatch({
                    type: SET_AUTH.LOGIN_FAIL,
                    err: {
                        ...error.response.data,
                    },
                } as AuthAction);
                localStorage.removeItem('user-data');
                localStorage.removeItem('userRole');
                setAuthStorage(false);
            }
            if (
                status === 401 &&
                data?.error?.name === AUTH_CONST.TOKEN_EXPIRED
            ) {
                localStorage.removeItem('user-data');
                localStorage.removeItem('userRole');
                setAuthStorage(false);
            }
            return Promise.reject(error);
        }
    );

    React.useEffect(() => {
        let idInteceptor;
        if (isAuthStorage) {
            idInteceptor = axiosInstance.interceptors.request.use(
                axiosInstanceIntceptorWithCookie,
                function (error) {
                    // Do something with request error
                    return Promise.reject(error);
                }
            );

        } else {
            if (idInteceptor) {
                axiosInstance.interceptors.request.eject(idInteceptor);
            }
        }
    }, [isAuthStorage]);

    React.useEffect(() => {
        if (membership?.role) {
            document.body.classList.add('admin');
        } else {
            document.body.classList.remove('admin');
        }
    }, [membership]);

    React.useEffect(() => {
        if (dispatch) {
            window.API.LMSInitialize();
            dispatch(setInitialized(true));
    
            // debug SCORM 1.2
            console.log('scorm: ', !!window?.scorm ? 'injected' : 'not inject');
            console.log('scorm API', window.API);
        }
    }, [dispatch]);

    return (
        <BrowserRouter
            getUserConfirmation={(m, cb) => {
                let tempObj: any = {};

                try {
                    let messageState = JSON.parse(m);

                    tempObj = {
                        ...modalState,
                        ...messageState,
                        func: cb,
                        message: messageState?.message || m,
                        page: messageState?.isBooking ? 'Booking' : '',
                    }

                } catch {

                }
                setModalState(tempObj);
            }}
        >
            {hasAbsenceEmpty && <div className="recipientAbsenceRequestPage-notification">
                <div className="flex items-center gap-2">
                    <AlertIcon />
                    <p>{translator("The Recipient List for Absence Request is currently empty. Please add recipient(s) to receive notifications.")}</p>
                </div>
                <button
                    type="button"
                    className="flex-none text-sm border border-white rounded font-semibold px-3 py-1.5 !w-[90px]"
                    onClick={() => window.open(`/workspace/${membership?.workspace_id}/management/recipient-absence-request`)}
                >
                    {translator('Add Now')}
                </button>
            </div>}
            <UploadProvider>
                {modalState?.page == 'Booking' ? (
                    <DialogComponent
                        title={'Leave without Booking'}
                        isOpen={!!modalState.message}
                        onCloseModal={() => handleClosePromptModal()}
                        isShowClose={true}
                        styles="overflow-hidden max-w-[480px] !py-4 !px-6"
                        child={
                            <div className="text-sm text-gray-800 flex flex-col gap-8 mt-4">
                                <div>Are you sure you want to leave? Any sessions you've added will be lost.</div>
                                <div className="flex justify-center gap-4">
                                    <ButtonOutline type="button" size="M" onClick={() => {
                                        if (modalState.func) {
                                            modalState.func(true);
                                            handleClosePromptModal();
                                        }
                                    }}>
                                        Leave
                                    </ButtonOutline>
                                    <ButtonPrimary type="button" size="M" onClick={() => handleClosePromptModal()}>Continue Booking</ButtonPrimary>
                                </div>
                            </div>
                        }
                    />
                ) : (
                    <DialogComponent
                        title={modalState?.title}
                        isOpen={!!modalState?.message}
                        onCloseModal={() => handleClosePromptModal()}
                        child={
                            <div className="text-sm text-gray-800 flex flex-col gap-8 mt-4">
                                {modalState?.isImg && (
                                    <div className='flex items-center justify-center'>
                                        <img
                                            className="w-40 h-40 mb-3"
                                            src={CancelChanges}
                                            alt="img"
                                        />
                                    </div>
                                )}
                                <div>{modalState?.message}</div>
                                <div className="flex justify-center gap-4">
                                    <ButtonOutline type="button" size="M" onClick={() => {
                                        if (modalState.func) {
                                            modalState.func(true);
                                            handleClosePromptModal();
                                        }
                                    }}>
                                        {modalState?.textConfirm}
                                    </ButtonOutline>
                                    <ButtonPrimary type="button" size="M" onClick={() => handleClosePromptModal()}>{modalState?.textCancel}</ButtonPrimary>
                                </div>
                            </div>
                        }
                    />
                )}

                {/* <MasterPage setAuthStorage={setAuthStorage}> */}
                <Routes
                    isAuthStorage={isAuthStorage}
                    setAuthStorage={setAuthStorage}
                    storageUserInfo={storageUserInfo}
                    storageUserInfoSession={storageUserInfoSession}
                ></Routes>
                {/* </MasterPage> */}
                {/* <Routes
                    isAuthStorage={isAuthStorage}
                    setAuthStorage={setAuthStorage}
                    storageUserInfo={storageUserInfo}
                    storageUserInfoSession={storageUserInfoSession}
                /> */}
            </UploadProvider>
        </BrowserRouter>
    );
};

export default AppContainer;
