import { RestfulService } from './restful.service';
// CONSTANTS
import { HOST_URL, RESOURCES } from 'constant/api.const';
import { UploadResourceArgsType } from 'types/User.type';
import { NormalResponseError, UploadFormDataResultType } from 'types/Common.type';
import { ConvertMineTypeResource } from 'utils/validate';
// TYPES

const getListResources = async (workspaceId: string, params?: any) => {
    const res = await RestfulService.getApi(HOST_URL + RESOURCES.getListResources(workspaceId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const createResources = async (workspaceId: string, params?: any) => {
    const res = await RestfulService.postApi(HOST_URL + RESOURCES.getListResources(workspaceId), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const importResources = async (workspaceId: string, params?: any) => {
    const res = await RestfulService.postApi(HOST_URL + RESOURCES.getListResources(workspaceId) + '/import', params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const updateResources = async (workspaceId: string, params: any) => {
    const res = await RestfulService.patchApi(HOST_URL + RESOURCES.updateResources(workspaceId, params.id), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const deleteResources = async (workspaceId: string, params: any) => {
    const res = await RestfulService.deleteApi(HOST_URL + RESOURCES.resourcesById(workspaceId, params.id), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getResourceById = async (workspaceId: string, params: any) => {
    const res = await RestfulService.getApi(HOST_URL + RESOURCES.resourcesById(workspaceId, params.id), params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const uploadResourceToAWS = async (body: FormData, url: string) => {
    if (body) {
        const res = await RestfulService.postApiFormData(url, body);
        return res.status;
    }
};

const uploadResource = async (body: UploadResourceArgsType) => {
    if (body) {
        const { mime_type, file_extension } = body;
        const filePublic = body.public;
        const res = await RestfulService.postApi(HOST_URL + RESOURCES.resourcesUpload(), {
            resources: [{ mime_type, file_extension, public: filePublic }],
        });
        return res.data;
    }
};

/**
 * Upload image to CDN
 * @param file
 * @param access_token token of current user
 * @param canvas is optional
 * @param onError
 * @param onSuccess callback to getting image path after uploading to CDN
 * */
const uploadFile = async (
    file: File,
    onSuccess: (path: string) => void,
    onError: (error: NormalResponseError | undefined) => void,
    canvas?: HTMLCanvasElement | undefined,
    isPublic=false
) => {
    const re = /(?:\.([^.]+))?$/;
    const fileExtension = `.${re.exec(file.name)![1]}`;
    const fileType = file.type;
    const extension = re.exec(file.name)![1];
    //post resource metadata then get information for uploading CDN
    const resource = await uploadResource({
        public: isPublic,
        mime_type: fileType ? fileType : ConvertMineTypeResource(extension),
        file_extension: fileExtension,
    });
    if (resource.error) {
        return onError(resource.error);
    }
    const uploadAvatarStateResult = resource?.urls[0] as UploadFormDataResultType;

    //prepare form for uploading to CDN
    const form: FormData = new FormData();
    form.append('Content-Type', uploadAvatarStateResult.fields['Content-Type']);
    form.append('acl', uploadAvatarStateResult.fields.acl);
    form.append('key', uploadAvatarStateResult.fields.key);
    form.append('policy', uploadAvatarStateResult.fields.policy);
    form.append('tagging', uploadAvatarStateResult.fields.tagging);
    form.append('x-amz-algorithm', uploadAvatarStateResult.fields['x-amz-algorithm']);
    form.append('x-amz-credential', uploadAvatarStateResult.fields['x-amz-credential']);
    form.append('x-amz-date', uploadAvatarStateResult.fields['x-amz-date']);
    form.append('x-amz-security-token', uploadAvatarStateResult.fields['x-amz-security-token']);
    form.append('x-amz-signature', uploadAvatarStateResult.fields['x-amz-signature']);
    let file_canvas: Blob | undefined;
    if (canvas) {
        const blobBin = atob(canvas.toDataURL(fileType).split(',')[1]);
        const array = [];
        for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        file_canvas = new Blob([new Uint8Array(array)], { type: fileType });
    }

    form.append('file', file_canvas ?? file);

    await uploadResourceToAWS(form, uploadAvatarStateResult.url)
        .then((result) => onSuccess(uploadAvatarStateResult.fields.key))
        .catch(onError);
};

const getDownloadUrl = async (workspaceId: string, file_id?: string) => {
    const res = await RestfulService.getApi(HOST_URL + `/workspaces/${workspaceId}/files/${file_id}/download-url`);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getDownloadVideo = (workspaceId: string,params?: any) => {
    return RestfulService.getApi(`${HOST_URL}/workspaces/${workspaceId}/files/download-url`, params);
};


export default {
    createResources,
    getListResources,
    getResourceById,
    updateResources,
    deleteResources,
    uploadFile,
    importResources,
    getDownloadUrl,
    getDownloadVideo
};
