import { forwardRef, useEffect, useState } from 'react';
import { TYPE_CREATE_CONTENT } from '../../constants';
import { useTranslation } from 'react-i18next';
import { AddIcon } from 'assets/icon';
import lessonService from 'services/lesson.service';
import { useParams } from 'react-router-dom';
import { UUID, checkPermission } from 'utils/commonFun';
import './Assignments.scss';
import ListContent from './ListContent';

function Assignment({
    sections = [],
    setSections,
    setSectionSelected,
    handleSelectContentToPreview,
    contentSelected,
    handleDeleteFileOfSection,
    typeSelect,
    setOnSaving,
    setCreateContentType,
    setOpenModalCreateH5p,
    setAssignmentSelected,
    assignmentSelected,
    handleAddContentToSection,
    setOpenModalAddAsignment
}, ref) {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const [draggedContent, setDraggedContent] = useState<any>();
    const [isDragging, setDragging] = useState(false)

    const onDragContentStart = (e, index) => {
        setOnSaving(true);
        setDragging(true)
        setDraggedContent(sections[0]?.section_contents?.[index]);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.parentNode);
        e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
    };

    const onDragOverContent = (index) => {
        const draggedOverContent = sections[0]?.section_contents?.[index];
        if (draggedContent?.id === draggedOverContent?.id) {
            return;
        }
        let section_contents = sections[0]?.section_contents?.filter((item) => item?.id !== draggedContent?.id);
        section_contents?.splice(index, 0, draggedContent);
        const newSection = { ...sections[0], section_contents };
        const newSections = sections.map((section) => {
            if (section?.id === newSection?.id) return newSection;
            return section;
        });
        setSections(newSections);
    };

    const onDragContentEnd = () => {
        setOnSaving(true);
        lessonService
            .sortContent(params?.id, params?.lessonId, sections[0]?.id, {
                ids: sections[0]?.section_contents?.map((content) => content?.id),
            })
            .then((res) => {
                setDraggedContent(undefined);
                setOnSaving(false);
            });
        setDragging(false)
    };

    const newSection = () => {
        setOnSaving(true);
        const newSection = {
            title: translator('LESSON.NEW_SESSION') + " " + ((sections?.length || 0) + 1),
            order_number: (sections?.length || 0) + 1,
            type: typeSelect,
        };
        lessonService.createSection(params?.id, params?.lessonId, newSection).then((res) => {
            setSections([...sections, { ...newSection, id: res?.id, uuid: UUID() }]);
            setSectionSelected({ ...newSection, id: res?.id, uuid: UUID() });
            setOnSaving(false);
        });
    };

    useEffect(() => {
        if (sections?.[0]) setSectionSelected(sections[0]);
        else {
            if (checkPermission('libraryContent', 'U')) newSection();
        }
    }, []);

    return (
        <div>
            <div className="listAssignemnt custom-scrollbar">
                <ListContent
                    sectionItem={sections[0]}
                    contentSelected={contentSelected}
                    handleSelectContentToPreview={handleSelectContentToPreview}
                    translator={translator}
                    handleDeleteFileOfSection={handleDeleteFileOfSection}
                    onDragContentStart={onDragContentStart}
                    onDragContentEnd={onDragContentEnd}
                    onDragOverContent={onDragOverContent}
                    sections={sections}
                    setSections={setSections}
                    setOnSaving={setOnSaving}
                    setAssignmentSelected={setAssignmentSelected}
                    assignmentSelected={assignmentSelected}
                    isDragging={isDragging}
                />
            </div>
            {checkPermission('libraryContentLessonsOrScorm', 'U') ? <button
                onClick={() => {
                    setOpenModalAddAsignment(true);
                    setCreateContentType(TYPE_CREATE_CONTENT.LIBRARY);
                }}
                className="btnSelectAssignment"
            >
                <AddIcon />
                <span>{translator('LESSON.SELECT_ASSIGNMENT')}</span>
            </button> : ''}

        </div>
    );
}

export default forwardRef(Assignment)
