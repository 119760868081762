import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import {SkillList} from 'components/Management/SkillManagement/SkillList';

const CertificatesPage = () => {
    return (
        // <ManagePermissionWrapper>
            <SkillList>
                <ManagementMasterPage  />
            </SkillList>
        // </ManagePermissionWrapper>
    );
};

export default CertificatesPage;
