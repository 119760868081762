import { FC } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { classNames } from 'utils/commonFun';

type SearchInputProps = {
    className?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
};

const index: FC<SearchInputProps> = ({ className, placeholder, value, onChange, disabled }) => {
    const { t: translator } = useTranslation();

    const onChangeInput = (event) => {
        onChange(event.target.value)
    }
    return (
        <div
            className={classNames(
                'w-full border border-ooolab_gray_6 rounded p-2 flex items-center space-x-2 text-xs focus-within:border-[#2684FF]',
                className
            )}
        >
            <SearchIcon className="h-5 w-5 text-dark-100" />
            <input
                type="search"
                placeholder={placeholder || translator('SEARCH')}
                className="w-full leading-5 bg-white placeholder-gray-500 disabled:bg-gray-50"
                onChange={onChangeInput}
                value={value}
                disabled={disabled}
            />
        </div>
    );
};

export default index;
