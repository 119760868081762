import { CheckIcon, ChevronDownIcon, ChevronUpIcon, XIcon } from '@heroicons/react/outline';
import './ModalSessionDetail.scss';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { ButtonPrimary, CopyClipboard, Toastify } from '_shared';
import { useParams } from 'react-router-dom';
import Disclosure from '_shared/components/Disclosure/Disclosure';
import { ListUser, User } from '_shared/components/User/User';
import ActionMenu from '../../../SessionList/components/SessionTable/ActionMenu';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from 'contexts/User/UserContext';
import { useTranslation } from 'react-i18next';
import { InputField } from 'components/InputForm/InputForm';
import { FORM_CONST } from 'constant/form.const';
import courseService from 'services/course.service';
import { EditIcon } from 'assets/icon';
import { openUrl } from 'utils/commonFun';
import { useAppSelector } from 'hooks/hooks';
import { SESSION_STATUS_ENUM } from 'constant/sessions.const';
import ReactTooltip from 'react-tooltip';
import workspaceService from 'services/workspace.service';

const SESSION_STATUS = {
    completed: SESSION_STATUS_ENUM.COMPLETED,
    not_started: SESSION_STATUS_ENUM.NOT_STARTED,
    in_progress: SESSION_STATUS_ENUM.IN_PROGRESS,
    cancelled: SESSION_STATUS_ENUM.CANCELLED,
};

const isJoinVcLink = (vc_link, startTime, status) => {
    const distanceTime = dayjs(startTime).diff(dayjs(), 'minute')
    if (!vc_link) return false;
    if ((distanceTime > 0 && distanceTime < 15) || status === SESSION_STATUS_ENUM.IN_PROGRESS) return true
}

export const SessionTitle = ({ session, isMultiSession = false, refreshData }) => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const { setting } = useAppSelector((state) => state.course);
    const { t } = useTranslation();
    const params: any = useParams();
    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])
    return (
        <div className="SessionTitle">
            <div className={`flex gap-2 items-center ${isMultiSession ? 'text-xs py-0.5' : 'text-sm'}`}>
                {!isMultiSession && <div className="w-4 h-4 rounded bg-primary-500"></div>} <div className="font-normal">ID</div>{' '}
                <div className=" font-semibold">{session?.id}</div> •{' '}
                <div className=" font-semibold !text-primary-500">{`${dayjs(session?.start_time)
                    ?.tz(result.time_zone)
                    ?.format('HH:mm')} - ${dayjs(session?.end_time)?.tz(result.time_zone)?.format('HH:mm')}`}</div>
                •
                {session?.display_status ? <div
                    className={`px-1 rounded font-normal  sessionStatus ${SESSION_STATUS[session?.display_status]} ${isMultiSession ? 'text-xs py-0.5' : 'text-sm'
                        }`}
                >
                    {t(session?.display_status)}
                </div> : ''}

            </div>
            <div className={`flex items-center ${isMultiSession ? 'mr-0' : 'mr-3.5'}`}>
                {isMultiSession && (
                    <div data-tip="You can only evaluate a completed session." data-for={`tooltip-evaluate-${session?.id}`}>
                        <ButtonPrimary
                            className="!text-xs !h-6 !px-2 disabled:!bg-white disabled:!text-gray-400 disabled:!border-gray-300"
                            disabled={session?.display_status !== SESSION_STATUS.completed}
                            type="button"
                            onClick={(e) => {
                                e.stopPropagation();
                                let link = `/workspace/${params?.id}/management/courses/${session?.course_id || session?.course?.id}}/session/${session?.id}/grade`;
                                window?.open(link, '_blank');
                            }}
                        >
                            {t("EVALUATE.EVALUATE_SESSION")}
                        </ButtonPrimary>
                    </div>
                )}
                {session?.display_status !== SESSION_STATUS.completed && (
                    <>
                        {/* @ts-ignore */}
                        <ReactTooltip
                            place="right"
                            type="light"
                            id={`tooltip-evaluate-${session?.id}`}
                            getContent={(content) => <div className="w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                            className="shadow-lg opacity-100"
                        />
                    </>
                )}
                {isMultiSession &&
                    isJoinVcLink(session?.vc_link, session?.start_time, session?.display_status) && (
                        <ButtonPrimary
                            className="!text-xs !h-6 !px-2 !ml-3"
                            type="button"
                            onClick={() => window.open(session?.vc_link, '_blank')}
                        >
                            {t('JOIN')}
                        </ButtonPrimary>
                    )
                }

                <div data-tip={t("SESSION.CANNOT_EDIT_SESSION_TOOLTIP")} data-for={`tooltip-action-${session?.id}`}>
                    <ActionMenu
                        disabledAction={
                            session?.display_status === SESSION_STATUS.completed ||
                            session?.display_status === SESSION_STATUS.cancelled
                        }
                        hideAction="view"
                        data={{ ...session, id: session?.id, time_zone: result?.time_zone }}
                        refreshData={refreshData}
                        settings={setting}
                    />
                </div>

                {(session?.display_status === SESSION_STATUS.completed || session?.display_status === SESSION_STATUS.cancelled) && (
                    <>
                        {/* @ts-ignore */}
                        <ReactTooltip
                            place="top"
                            type="light"
                            id={`tooltip-action-${session?.id}`}
                            getContent={(content) => <div className="w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                            className="shadow-lg opacity-100"
                        />
                    </>
                )}
                {!isMultiSession && <div className="h-5 w-[1px] bg-gray-200"></div>}
            </div>
        </div>
    );
};

export const SessionDetail = ({ session, isOpen = false, close = () => { }, isMultiSession = false }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const workspaceId = params?.id;
    const [isEditRecordLink, setIsEditRecordLink] = useState(false);
    const [sessionDetail, setSessionDetail] = useState(null);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, []);

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
        mode: 'onChange',
    });

    const fetchSessionDetail = () => {
        workspaceService.getSessionDetail(workspaceId, session?.id).then((res) => setSessionDetail(res?.data));
    };

    useEffect(() => {
        !!session && reset(session);
    }, [session]);

    useEffect(() => {
        if (session?.id) {
            fetchSessionDetail();
        }
    }, [session]);

    const handleUpdateRecordLink = async () => {
        if (!!errors['record_link']) return;
        const response = await courseService.updateSessionPast(params?.id, session?.course_id || session?.course?.id, {
            id: session?.id,
            record_link: getValues('record_link'),
        });
        if (response?.status) Toastify.success(t('SESSION.UPDATE_RECORDING_LINK_SUCCESS'));
        else Toastify.error(t('SESSION.UPDATE_RECORDING_LINK_FAILED'));
        setIsEditRecordLink(false);
    };

    const onSubmitForm = async (values) => {
        console.log(values);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="SessionDetail relative">
            {isOpen && (
                <div className="wrapper">
                    <div className="label">{t("SESSION.COURSE")}</div>
                    <div
                        className="value text-primary-500 font-semibold cursor-pointer"
                        onClick={() => window.open(`/workspace/${params?.id}/management/courses/${session?.course_id || session?.course?.id}`, '_blank')}
                    >
                        {session?.course?.title}
                    </div>
                </div>
            )}

            {!session?.learners?.length && (
                <div className="wrapper">
                    <div className="label">{t("SESSION.LEARNERS")}</div>
                    <div>-</div>
                </div>
            )}

            {session?.learners?.length > 0 && (
                <div className="wrapper">
                    <div className="label">{t("SESSION.LEARNERS")}</div>
                    {session?.learners?.length > 1 ? (
                        <div className="w-full">
                            {session?.learners?.length > 0 ? (
                                <Disclosure
                                    button={(open) => {
                                        return (
                                            <div className="w-full">
                                                <div className="text-xs text-primary-500 flex gap-1 items-center">
                                                    {open ? t("SESSION.HIDE") : t("SESSION.SHOW_ALL")} {!open && session?.learners?.length} {t("SESSION.LEARNERS")}{' '}
                                                    <ChevronDownIcon className={`w-3 h-3 ${open ? 'rotate-180' : 'rotate-0'}`} />{' '}
                                                </div>
                                            </div>
                                        );
                                    }}
                                    panel={() => {
                                        return (
                                            <div className="flex flex-wrap mt-2 gap-2">
                                                {session?.learners?.map((learner) => {
                                                    return (
                                                        <User
                                                            key={learner?.id}
                                                            name={learner?.display_name}
                                                            link={`/workspace/${params?.id}/management/users/${learner?.id}?tab=information`}
                                                            avatar={learner?.avatar_url}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        );
                                    }}
                                    index={undefined}
                                />
                            ) : (
                                <div className="flex flex-wrap gap-2">
                                    {session?.learners?.map((learner) => {
                                        return (
                                            <User
                                                key={learner?.id}
                                                name={learner?.display_name}
                                                link={`/workspace/${params?.id}/management/users/${learner?.id}?tab=information`}
                                                avatar={learner?.avatar_url}
                                            />
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    ) : (
                        <User
                            name={session?.learners?.[0]?.display_name}
                            link={`/workspace/${params?.id}/management/users/${session?.learners?.[0]?.id}?tab=information`}
                            avatar={session?.learners?.[0]?.avatar_url}
                        />
                    )}
                </div>
            )}


            {session?.instructors?.length > 0 && (
                <div className="wrapper">
                    <div className="label">{t("SESSION.INSTRUCTORS")}</div>
                    {session?.instructors?.length > 1 && (
                        <div className="flex flex-wrap gap-2">
                            {session?.instructors?.map((instructor, index) => {
                                return (
                                    <User
                                        key={instructor?.id}
                                        name={instructor?.display_name}
                                        link={`/workspace/${params?.id}/management/users/${instructor?.id}?tab=information`}
                                        avatar={instructor?.avatar_url}
                                    />
                                );
                            })}
                        </div>
                    )
                    }
                    {session?.instructors?.length === 1 && (
                        <User
                            name={session?.instructors?.[0]?.display_name}
                            link={`/workspace/${params?.id}/management/users/${session?.instructors?.[0]?.id}?tab=information`}
                            avatar={session?.instructors?.[0]?.avatar_url}
                        />
                    )}
                    {session?.instructors?.length === 0 && '-'}
                </div>
            )
            }

            {!session?.instructors?.length && (
                <div className="wrapper">
                    <div className="label">{t("SESSION.INSTRUCTORS")}</div>
                    <div>-</div>
                </div>
            )}

            <div className="wrapper">
                <div className="label">{t("SESSION.LESSON")}</div>
                {session?.lesson?.title ? (
                    <div
                        className="value cursor-pointer"
                        onClick={() => window.open(`/workspace/${params?.id}/lesson/${session?.lesson?.id}`, '_blank')}
                    >
                        <div className="link">{session?.lesson?.title}</div>
                    </div>
                ) : (
                    '-'
                )}
            </div>

            <div className="wrapper">
                <div className="label">{t("SESSION.VIRTUAL_CLASSROOM")}</div>
                {sessionDetail?.vc_link ? (
                    <div 
                        onClick={() => openUrl(sessionDetail?.vc_link)}
                        className="max-w-[480px] cursor-pointer text-ellipsis truncate !font-normal text-primary-500 text-left"
                    >
                        {sessionDetail?.vc_link}
                    </div>
                ) : (
                    '-'
                )}
            </div>

            <div className="wrapper">
                <div className="label">{t("SESSION.EXTRA_LINK")}</div>
                {session?.extra_link ? (
                    <div className="value cursor-pointer">
                        <div className="max-w-[480px] link text-left !font-normal text-ellipsis truncate" onClick={() => openUrl(session?.extra_link)}>
                            {session?.extra_link}
                        </div>
                    </div>
                ) : (
                    '-'
                )}
            </div>

            {session?.display_status === 'completed' ? (
                <div className="wrapper">
                    <div className="label flex items-center">{t('SESSION.SESSION_RECORDING')}</div>
                    {!isEditRecordLink ? (
                        <div className="value cursor-pointer !gap-2">
                            {!!getValues('record_link') && (
                                <>
                                    <div className="max-w-[420px] link text-left !font-normal text-ellipsis truncate" onClick={() => openUrl(getValues('record_link'))}>
                                        {getValues('record_link')}
                                    </div>
                                    <CopyClipboard
                                        text={getValues('record_link')}
                                        color="text-primary-500"
                                        message="Recording link copied!"
                                    />
                                </>
                            )}
                            {!isEditRecordLink && (
                                <span className="cursor-pointer" onClick={() => setIsEditRecordLink(true)}>
                                    <EditIcon fill="#0071CE" />
                                </span>
                            )}
                        </div>
                    ) : (
                        <div className="flex space-x-2 w-full">
                            <InputField
                                placeholder={t('SESSION.ENTER_RECORDING_LINK')}
                                name="record_link"
                                errors={errors}
                                register={register('record_link', {
                                    pattern: {
                                        value: FORM_CONST.URL_VALIDATE,
                                        message: t('SESSION.VALIDATE_LINK'),
                                    },
                                })}
                                classNameInput="max-h-[22px] text-xs"
                                className="w-2/3"
                            />
                            <span
                                className="mt-0.5 cursor-pointer"
                                onClick={() => {
                                    setValue('record_link', session?.record_link);
                                    setIsEditRecordLink(false);
                                }}
                            >
                                <XIcon className="w-4 h-4 text-red-500" />
                            </span>
                            <span
                                className={`mt-0.5 ${!!errors['record_link'] ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                                onClick={handleUpdateRecordLink}
                            >
                                <CheckIcon className="w-4 h-4 text-green-500" />
                            </span>
                        </div>
                    )}
                </div>
            ) : null}

            <div className="wrapper">
                <div className="label">{t("SESSION.LOCATION")}</div>
                <div className="value">
                    <div className="text break-all">{session?.location || '-'}</div>
                </div>
            </div>

            <div className="wrapper">
                <div className="label">{t("DESCRIPTION")}</div>
                <div className="value">
                    <div className="text">{session?.instructor_note || '-'}</div>
                </div>
            </div>
            {isOpen && isMultiSession && (
                <div className="text-xs text-primary-500 absolute right-5 bottom-2 flex items-center cursor-pointer" onClick={close}>
                    {t("SHOW_LESS")} <ChevronUpIcon className="w-3 h-3" />{' '}
                </div>
            )}
            {!isMultiSession && (
                <div className="mt-4 pt-3 border-t border-gray-300 -mx-4 flex justify-end gap-3 px-5">
                    <div data-tip={t("SESSION.EVALUATE_SESSION_TOOLTIP")} data-for={`tooltip-evaluate`}>
                        <ButtonPrimary
                            disabled={session?.display_status !== SESSION_STATUS.completed}
                            type="button"
                            className="disabled:!bg-white disabled:!text-gray-400 disabled:!border-gray-300"
                            onClick={(e) => {
                                e.stopPropagation();
                                let link = `/workspace/${params?.id}/management/courses/${session?.course_id}/session/${session?.id}/grade`;
                                if (location.pathname.includes('instructor-dashboard')) {
                                    link = `/workspace/${params?.id}/instructor-dashboard/gradebook/${sessionDetail?.course?.id}/${sessionDetail?.id}`
                                }
                                window?.open(link, '_blank');
                            }}
                        >
                            {t("EVALUATE.EVALUATE_SESSION")}
                        </ButtonPrimary>
                    </div>
                    {session?.display_status !== SESSION_STATUS.completed && (
                        <>
                            {/* @ts-ignore */}
                            <ReactTooltip
                                place="right"
                                type="light"
                                id={`tooltip-evaluate`}
                                getContent={(content) => <div className="w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                                className="shadow-lg opacity-100"
                            />
                        </>
                    )}
                    {isJoinVcLink(sessionDetail?.vc_link, session.start_time, session?.display_status) && (
                            <ButtonPrimary 
                                type="button" 
                                onClick={() => window.open(sessionDetail?.vc_link, '_blank')}
                            >
                                {t("JOIN")}
                            </ButtonPrimary>
                        )}
                </div>
            )}
        </form>
    );
};

export const Member = ({ session }) => {
    const params: any = useParams();
    const {t} = useTranslation();
    return (
        <div className="Member">
            {(
                <div className="wrapper">
                    <div className="label">{t("SESSION.LEARNERS")}</div>
                    <ListUser
                        id="learner"
                        users={session?.learners?.map((item) => ({
                            name: item?.display_name,
                            avatar: item?.avatar_url,
                            id: item?.id,
                            link: `/workspace/${params?.id}/management/users/${item?.id}`,
                        }))}
                    />
                </div>
            )}
            •
            <div className="wrapper">
                <div className="label">{t("SESSION.INSTRUCTORS")}</div>
                <ListUser
                    id="instructor"
                    users={session?.instructors?.map((item) => ({
                        name: item?.display_name,
                        avatar: item?.avatar_url,
                        id: item?.id,
                        link: `/workspace/${params?.id}/management/users/${item?.id}`,
                    }))}
                />
            </div>
        </div>
    );
};
