import React from 'react';
// PACKAGES
import { IPlayerModel } from 'packages/h5p-server';
// TYPES
import { IH5PPlayerArgs } from 'types/H5P.type';
// COMPONENTS
import { H5PPlayerUI } from 'packages/h5p-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { H5PContext } from 'contexts/H5P/H5PContext';
import { useEffect } from 'react';
import { result } from 'lodash';

export interface H5PViewerContentListComponent {
    argsId: IH5PPlayerArgs;
    h5pPlayerPromise: (args: IH5PPlayerArgs) => Promise<IPlayerModel>;
    entryState: {
        editing: boolean;
        playing: boolean;
        loading: boolean;
        saved: boolean;
        saving: boolean;
        saveError: boolean;
        saveErrorMessage: string;
    };
    setEntryState: React.Dispatch<
        React.SetStateAction<{
            editing: boolean;
            playing: boolean;
            loading: boolean;
            saved: boolean;
            saving: boolean;
            saveError: boolean;
            saveErrorMessage: string;
        }>
    >;
    setFinishContent?: Function
}

const H5PViewerContentListEntryComponent: React.FC<H5PViewerContentListComponent> = ({
    argsId,
    h5pPlayerPromise,
    entryState,
    setEntryState,
    setFinishContent
}) => {
    const h5pPlayer = React.useRef(null);

    const [count, setCount] = React.useState<number>(0);

    const [obj, setObj] = React.useState<any>();
    const h5PCtx = React.useContext(H5PContext);
    const {
        dispatch,
        H5PState: { h5PApproveContentResult },
    } = h5PCtx;

    useEffect(() => {
        if (h5PApproveContentResult === 204) {
            setCount(count + 1);
        }
    }, [h5PApproveContentResult]);

    function onPlayerInitialized() {
        setEntryState({ ...entryState, loading: false });
    }

    function play() {
        setEntryState({ ...entryState, editing: false, playing: true });
    }
    function close() {
        setEntryState({ ...entryState, editing: false, playing: false });
    }

    return (
        <div className='w-full mx-auto'>
            {/*@ts-ignore*/
                <H5PPlayerUI
                    ref={h5pPlayer}
                    argsId={argsId}
                    loadContentCallback={h5pPlayerPromise}
                    onInitialized={onPlayerInitialized}
                    onxAPIStatement={(statement: any, context: any, event) => {
                        console.log("onxAPIStatement");
                        console.log(JSON.stringify(statement));
                        if (statement.object && statement.object.id && !(statement.object.id + "").includes("subContentId")) {
                            if (setObj) {
                                setObj(statement.object);
                            }
                        }
                        if (statement.result && (!statement.object || !(statement.object.id + "").includes("subContentId"))) {
                            if (setFinishContent) {
                                setFinishContent(argsId, { object: obj, result: statement.result });
                            }
                        }
                    }
                    }
                    key={`ui_${count}`}
                />}
        </div>
    )

};

export default H5PViewerContentListEntryComponent;
