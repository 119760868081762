import React from "react";

import "./ButtonBack.scss";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon } from "@heroicons/react/outline";

export const ButtonBack = ({onClick, hideLabel = false}) => {
    const {t: translator} = useTranslation();

    return <button className="buttonBack" onClick={onClick}>
        <span className="buttonBack-icon">
            <ChevronLeftIcon />
        </span>
        {!hideLabel && <span className="buttonBack-text">{translator('BACK')}</span>}
    </button>
}