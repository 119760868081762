import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';
import { DownloadIcon } from 'assets/icon';
import { checkPermission, dowloadFile } from 'utils/commonFun';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import ModalDeleteReport from 'components/Workspace/Course/CourseBuilder/components/CourseReport/Components/ModalDeleteRport/ModalDeleteReport';
import ModalAddReport from 'components/Workspace/Course/CourseBuilder/components/CourseReport/Components/ModalAddReport';

interface ActionMenuProp {
    refreshData: any;
    data: any;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ refreshData, data, position }) => {
    const { t: translator } = useTranslation();

    const params: { id: string; courseId: string } = useParams();

    const [isOpenModalEditReport, setOpenModalEditReport] = useState(false);
    const [isOpenModalDeleteReport, setOpenModalDeleteReport] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [isOpenViewFile, setOpenViewFile] = useState(false)

    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        dowloadFile(data?.file_url, 'Report', () => {
            setDownloading(false);
        });
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? 'bottom-0' : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('VIEW'),
                        icon: <EyeIcon className='w-4 h-4' />,
                        action: () => setOpenViewFile(true),
                        hide: !checkPermission('course', 'R') && !checkPermission('user', 'R'),
                    },
                    {
                        label: translator('EDIT'),
                        icon: <EditIcon />,
                        isDisabled: data?.status === 'released',
                        action: () => setOpenModalEditReport(true),
                        class: '',
                        hide: (!checkPermission('course', 'R') || !checkPermission('course', 'U') || !checkPermission('user', 'U')),
                    },
                    {
                        label: translator('DOWNLOAD'),
                        icon: <DownloadIcon />,
                        isDisabled: false,
                        action: download,
                        class: '',
                        hide: !checkPermission('course', 'R') && !checkPermission('user', 'R'),
                    },
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon width="18" height="16" />,
                        action: () => setOpenModalDeleteReport(true),
                        class: 'border-t border-gray-300',
                        hide: !checkPermission('course', 'R') || !checkPermission('course', 'U') || !checkPermission('user', 'U'),
                    },
                ]}
            />
            {isOpenModalEditReport &&
                <ModalAddReport
                    onClose={() => setOpenModalEditReport(false)}
                    isOpen={isOpenModalEditReport}
                    dataValues={data}
                    refreshData={refreshData}
                />
            }
            {isOpenModalDeleteReport &&
                <ModalDeleteReport
                    isOpen={isOpenModalDeleteReport}
                    onClose={() => setOpenModalDeleteReport(false)}
                    reportId={data?.id}
                    course_id={data?.course_id}
                    refreshData={refreshData}
                />
            }
            {isOpenViewFile &&
                <DocumentPreview
                    closeModal={() => setOpenViewFile(false)}
                    isOpen={isOpenViewFile}
                    data={{
                        type: data?.file_mime_type,
                        name: data?.file_url.substring(data?.file_url?.lastIndexOf('/') + 1),
                        url: data?.file_url
                    }}
                />

            }
        </>
    );
};

export default ActionMenu;
