import './AssignmentRecording.scss';
import { useEffect, useRef, useState } from 'react';
import { MicrophoneIcon } from '@heroicons/react/solid';
import assignmentService from 'services/assignment.service';
import { useParams } from 'react-router-dom';



export default function AssignmentSubmission({data}) {
    const [isRecording, setIsRecording] = useState(false);
    const [audioURL, setAudioURL] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
    const [timmer, setTimmer] = useState('00:00');
    const [recordingData, setRecordingData] = useState<any>();
    const params: any = useParams();
    const startRecording = async () => {
        
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;
        audioChunksRef.current = [];
    
        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };
    
        mediaRecorder.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
          const url = URL.createObjectURL(audioBlob);
          setAudioURL(url);
        };
    
        mediaRecorder.start();
        setIsRecording(true);
      };
    
      const stopRecording = () => {
        mediaRecorderRef.current?.stop();
        setIsRecording(false);
      };

      useEffect(() => {
        if (isRecording) {
            clearInterval(timer)
            const date = new Date(0);
            var timer = setInterval(() => {
                date.setSeconds(date.getSeconds() + 1);
                setTimmer(date.toISOString().substr(14, 5));
            }, 1000);
            return () => clearInterval(timer);
        }
      }
      , [isRecording]);

      useEffect(() => {
        if (!params?.id) {
            setRecordingData(data?.library_content);
            return;
        }
        assignmentService?.getRecordingTask(params?.id, data?.library_content?.object_id).then((res) => {
            const data = {
                title: res?.data?.title,
                instruction: res?.data?.instruction,
            };
            setRecordingData(data);
        });
      }, [data, params?.id]);


    return (
        <div className="AssignmentRecording absolute inset-0">
            <div className="AssignmentRecording-information">
                <div className="preview">
                    <div className=" w-full h-full">
                        <div className="wrapper-preview h-full">
                            {(recordingData?.title || data?.library_content?.name )&&
                                <div className="flex justify-between">
                                    <div className="title">{recordingData?.title || data?.library_content?.name}</div>
                                </div>
                            }
                            <div className="mt-4 ">
                                {recordingData?.instruction && (
                                    <p className="note" dangerouslySetInnerHTML={{ __html: recordingData?.instruction || data?.library_content?.instruction}} />
                                )}
                            </div>
                            
                            <div className='w-full rounded-lg bg-white p-4 mt-4 flex justify-center items-center flex-col gap-2'>
                                <p className='text-sm text-gray-500'>Press the button below record your answer</p>
                                {timmer}
                                <button
                                    disabled={true}
                                    onClick={isRecording ? stopRecording : startRecording}
                                >
                                    {isRecording ? (
                                        <div className='p-3 bg-red-400 rounded-full'><MicrophoneIcon className='w-6 h-6 text-white' /></div>
                                    ) : 
                                    (
                                        <div className='p-3 bg-gray-400 rounded-full'><MicrophoneIcon className='w-6 h-6 text-white' /></div>
                                    )
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
