import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { CircleInformationIcon } from 'assets/icon';
import { ENROLLMENT_STATUS } from '../../constants';
import { checkPermission } from 'utils/commonFun';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import { useAppSelector } from 'hooks/hooks';
import DeactivateIcon from 'assets/icon/DeactivateIcon';
import { ActiveIcon } from 'assets/icon/ActiveIcon';
import { toast } from 'react-toastify';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

interface ActionMenuProp {
    data: any;
    position?: string;
    onDeposit?: (values) => void;
    refreshData?: () => void;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, position, onDeposit, refreshData }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const { openInNewTabCourse, redirectToUrlCourse } = useUrlCourseNavigation();
    const { setting } = useAppSelector((state) => state.course);

    const [isOpenModalDeactivate, setOpenModalDeactivate] = useState(false);
    const [isOpenModalActivate, setOpenModalActivate] = useState(false);
    const [numbersCredit, setNumbersCredit] = useState(0);
    const [loading, setLoading] = useState(false);

    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const onDeactivateEnrollment = () => {
        const loadingToastId = Toastify.loading(translator('COURSES.DEACTIVATING_ENROLLMENT'));
        courseService
            .updateEnrollmentDetail(workspaceId, courseId, data?.id, { status: ENROLLMENT_STATUS.DEACTIVATED })
            .then(() => {
                toast.dismiss(loadingToastId);
                Toastify.success(translator('YOUR_WORK_HAS_BEEN_SAVED'));
                setOpenModalDeactivate(false);
                refreshData();
            })

            .catch(() => {
                toast.dismiss(loadingToastId);
                Toastify.error();
            });
    };

    const onActiveEnrollment = () => {
        courseService
            .updateEnrollmentDetail(workspaceId, courseId, data?.id, { status: ENROLLMENT_STATUS.ENROLLED })
            .then(() => {
                Toastify.success();
                setOpenModalActivate(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    const fetchRefundCredits = async (enrollmentId, learnerId) => {
        try {
            setLoading(true);
            const response: any = await courseService.getRefundCredits(workspaceId, courseId, enrollmentId, learnerId);
            console.log(response);
            setNumbersCredit(response?.data?.refund_credits || 0);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const infoModal = useMemo(() => {
        let message =
            setting?.enrollment_type === 'credit'
                ? translator('COURSES.DEACTIVATE_ENROLLMENT_CREDIT_MSG')
                : translator('COURSES.DEACTIVATE_ENROLLMENT_MESSAGE');
        let labelBtn = setting?.enrollment_type === 'credit' ? translator('YES') : translator('DEACTIVATE');

        if (setting?.enrollment_type === 'credit') {
            if (data?.type === 'individual') {
                if (numbersCredit > 0) {
                    message = translator('DEACTIVATE_ENROLLMENT_CREDIT_INDIVIDUAL_MSG', { num_credits: numbersCredit });
                    labelBtn = translator('YES');
                } else {
                    message = translator('COURSES.DEACTIVATE_ENROLLMENT_MESSAGE');
                    labelBtn = translator('DEACTIVATE');
                }
            }
        }

        return {
            message,
            labelBtn,
        };
    }, [data, setting, translator, numbersCredit]);

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('View Details'),
                        icon: <InformationCircleIcon className="h-5 w-5" />,
                        action: () =>
                            redirectToUrlCourse({
                                tab: 'people',
                                subtab: 'learner',
                                enrollmentId: data?.id,
                                view: 'information',
                            }),
                        class: 'border-b border-gray-300',
                        hide: false,
                    },
                    {
                        label: translator('COURSES.VIEW_CREDIT_BALANCE'),
                        icon: (
                            <div className="text-ooolab_dark_1">
                                <CircleInformationIcon width="16px" height="16px" fill="currentColor" />
                            </div>
                        ),
                        action: () =>
                            openInNewTabCourse({
                                tab: 'credit-balance',
                                creditId: data?.credit_balance?.id,
                            }),
                        class: 'border-b border-gray-300',
                        hide: !checkPermission('coursePeopleEnrollment', 'R') || setting?.enrollment_type !== 'credit',
                        isDisabled: !data?.credit_balance?.id,
                    },
                    {
                        label: translator('COURSES.DEPOSIT'),
                        icon: <PlusIcon className="w-4 h-4" />,
                        action: onDeposit,
                        class: 'border-b border-gray-300',
                        hide:
                            !checkPermission('coursePeopleEnrollment', 'DA') ||
                            data?.display_status === ENROLLMENT_STATUS.DEACTIVATED ||
                            setting?.enrollment_type !== 'credit',
                    },
                    {
                        label: translator('DEACTIVATE'),
                        icon: <DeactivateIcon />,
                        isDisabled: data?.display_status === ENROLLMENT_STATUS.DEACTIVATED,
                        action: () => {
                            if (setting?.enrollment_type === 'credit' && !!data?.learner?.id && !!data?.id)
                                fetchRefundCredits(data?.id, data?.learner?.id);
                            setOpenModalDeactivate(true);
                        },
                        class: 'border-b border-gray-300',
                        hide:
                            !checkPermission('coursePeopleEnrollment', 'DA') ||
                            data?.display_status === ENROLLMENT_STATUS.DEACTIVATED ||
                            data?.display_status === ENROLLMENT_STATUS.EXPIRED,
                    },
                    {
                        label: translator('ACTIVATE'),
                        icon: <ActiveIcon />,
                        action: () => setOpenModalActivate(true),
                        class: 'border-b border-gray-300',
                        isDisabled: data?.display_status === ENROLLMENT_STATUS.ENROLLED,
                        hide:
                            !checkPermission('coursePeopleEnrollment', 'DA') ||
                            data?.display_status === ENROLLMENT_STATUS.ACTIVE ||
                            data?.display_status === ENROLLMENT_STATUS.EXPIRED,
                    },
                ]}
            />

            {isOpenModalDeactivate && (
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenModalDeactivate}
                    onCloseModal={() => setOpenModalDeactivate(false)}
                    child={
                        <div className="flex flex-col">
                            {loading ? (
                                <div className="w-full">
                                    <div className="flex animate-pulse space-x-4">
                                        <div className="flex-1 space-y-4 py-1">
                                            <div className="h-2 rounded bg-gray-200"></div>
                                            <div className="grid grid-cols-3 gap-4">
                                                <div className="col-span-2 h-2 rounded bg-gray-200"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="mt-2 text-sm text-gray-800">{infoModal.message}</div>
                            )}
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDeactivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    className="buttonPrimary-danger"
                                    disabled={loading}
                                    onClick={onDeactivateEnrollment}
                                >
                                    {infoModal.labelBtn}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}

            {isOpenModalActivate && (
                <DialogComponent
                    title={translator('COURSES.ACTIVE_CONFIRMATION')}
                    isOpen={isOpenModalActivate}
                    onCloseModal={() => setOpenModalActivate(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">{translator('COURSES.ACTIVATE_ENROLLMENT_MESSAGE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalActivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" onClick={onActiveEnrollment}>
                                    {translator('ACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
