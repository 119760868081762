import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { WorkspaceService } from 'services';
import { ContentTypes, H5PTypes } from './uitils';
import workspaceService from 'services/workspace.service';
import { debounce } from 'lodash';
import ButtonComponent from 'components/Button/ButtonComponent';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import SelectedFiles from '../SelectedFiles/SelectedFiles';
import { CONTENT_TYPE, FILE_TYPE, H5P_LIBRARY } from 'constant/h5p.const';
import './FilterSearch.scss';
import { DropdownAsync } from '_shared/components/Form/DropdownAsync/DropdownAsync';
import SearchInput from '_shared/components/SearchInput/SearchInput';
import Select from 'components/Select/Select';

interface IFilter {
    onChangeFilter: (value: any) => void;
    data: any;
    listSelected: any[];
    onClose: () => void;
    // isFirstLoad: boolean;
    setOpenModalDelete: Dispatch<SetStateAction<boolean>>;
    setIsMulti: Dispatch<SetStateAction<boolean>>;
    setOpenModalDuplicate: Dispatch<SetStateAction<boolean>>;
    setLoadingDownload: Dispatch<SetStateAction<boolean>>;
    onMoveFile: () => void;
}

const optionsFolder = [
    { label:  ('This Folder Only'), value: 'this_folder' },
    { label: ('Entire Internal Library'), value: 'entire_folder' },
];

const FilterSearch = ({
    onChangeFilter,
    data,
    listSelected,
    onClose,
    // isFirstLoad,
    setOpenModalDelete,
    setIsMulti,
    setOpenModalDuplicate,
    setLoadingDownload,
    onMoveFile
}: IFilter) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchOwner, setInputSearchOwner] = useDebouncedState('');
    const [inputSearchContentType, setInputSearchContentType] = useDebouncedState('');
    const [inputSearchH5PType, setInputSearchH5PType] = useDebouncedState('');
    const [inputSearchCustomField, setInputSearchCustomField] = useDebouncedState('');

    const [listOwners, setListOwner] = useState([]);
    const [selectedOwners, setSelectedOwners] = useState([]);
    const [selectedContectType, setSelectedContentType] = useState<any>([]);
    const [selectedH5PType, setSelectedH5PType] = useState([]);

    const [loadingOwner, setloadingOwner] = useState(false);
    const [listContentType, setlistContentType] = useState({ data: ContentTypes('', false, t)?.data?.[0], total: ContentTypes('', false, t)?.total });
    const [listH5PType, setlistH5PType] = useState({ data: H5PTypes()?.data?.[0], total: H5PTypes('')?.total });
    const [listCustomField, setListCustomField] = useState([]);
    const [filterByTags, setFilterByTags] = useState<any>();
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [hasAddFilterFirst, setHasAddFilterFirst] = useState<boolean>(false);
    const [payloadAddFilter, setPayloadAddFilter] = useState<{ types: string }>(null);
    const [hasReset, setHasReset] = useState(false);
    const [filterLocalJson, setFilterLocalJson] = useState<any>();
    const filterLocal = localStorage.getItem('libraryFilter');
    const [searchFolder, setSearchFolder] = useState<any>(optionsFolder[0]);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const [pagination, setPagination] = useState<any>({
        page: 1,
    });
    const [paginationContentType, setPaginationContentType] = useState<any>({
        page: 0,
    });
    const [paginationH5PType, setPaginationH5PType] = useState<any>({
        page: 0,
    });
    const [paginationCustomField, setPaginationCustomField] = useState<any>({
        page: 0,
    });

    const folderId = params?.folderId || null;

    const getListCustomFields = (page: number = 1) => {
        const body = {
            order: 'asc',
            page: page || paginationCustomField?.page,
            per_page: 10,
            q: inputSearchCustomField,
        };
        if (inputSearchCustomField === '') {
            delete body.q;
        };
        workspaceService.getCustomFieldsService(params.id, body).then((res: any) => {
            const formatedData = res?.data?.items?.map((item) => {
                return {
                    name: item?.name,
                    id: item?.id,
                };
            });
            setPaginationCustomField({ ...pagination, total: res?.data?.total, page });
            if (page === 1) setListCustomField(formatedData);
            else setListCustomField([...listCustomField, ...formatedData]);
        });
    };

    const getListOwner = (page: number = 1) => {
        setloadingOwner(true);
        WorkspaceService.getWorkspaceMembers(
            { id: params.id },
            {
                page: page ? page : pagination.page,
                per_page: 10,
                q: inputSearchOwner,
                order: 'asc',
                sort_by: 'display_name',
            }
        )
            .then((res) => {
                const formatedData = res?.items?.map((item) => {
                    return {
                        name: item?.display_name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPagination({ ...pagination, total: res?.total, page });
                if (page === 1) setListOwner(formatedData);
                else setListOwner([...listOwners, ...formatedData]);
            })
            .finally(() => setloadingOwner(false));
    };

    const onChangeAddFilter = (values) => {
        setHasReset(false);
        setSelectedTags(values);
        const tags = filterByTags?.tags || {};
        if (Object.keys(tags)?.length < values?.length) {
            const newItem = values[values?.length - 1];
            if (newItem) {
                tags[newItem.id] = [];
            }
        } else {
            Object.keys(tags).map(key => {
                if (values.filter(item => item.id == key).length == 0) {
                    delete tags[key];
                }
            });
        }
        setFilterByTags(prev => ({
            ...prev,
            tags
        }));

        if (values?.length > 0 && selectedContectType?.length == 0) {
            setHasAddFilterFirst(true);
        }
    }

    const onDelete = () => {
        setOpenModalDelete(true);
        setIsMulti(true);
    };

    const onDuplicate = () => {
        setOpenModalDuplicate(true);
        setIsMulti(true);
    };

    const handleSelectedContectType = (values) => {
        setSelectedContentType(values);
        setHasReset(false);
        if (!hasAddFilterFirst) {
            setTimeout(() => {
                setHasReset(true);
            })
            setSelectedTags([]);
            setFilterByTags(null);
            setPayloadAddFilter({
                types: values?.map(item => item.value).join(',')
            })
        } else {
            setPayloadAddFilter(null);
        }
    }

    useEffect(() => {
        getListOwner(1);
    }, [inputSearchOwner]);

    useEffect(() => {
        getListCustomFields(1);
    }, [inputSearchCustomField]);

    useEffect(() => {
        const h5pTypes = H5PTypes(inputSearchH5PType);
        if (paginationH5PType?.page > 0 && paginationH5PType?.page < h5pTypes?.total) {
            return setlistH5PType({ ...listH5PType, data: [...listH5PType?.data, ...h5pTypes?.data?.[paginationH5PType?.page]] });
        }
    }, [paginationH5PType?.page]);

    useEffect(() => {
        setPaginationH5PType({ page: 0 });
        const listH5PType = H5PTypes(inputSearchH5PType);
        setlistH5PType({ total: listH5PType?.total, data: listH5PType?.data?.[0] });
    }, [inputSearchH5PType]);

    useEffect(() => {
        const contentTypes = ContentTypes(inputSearchContentType, false, t);
        if (paginationContentType?.page > 0 && paginationContentType?.page < contentTypes?.total) {
            setlistContentType({
                ...listContentType,
                data: [...listContentType?.data, ...contentTypes?.data?.[paginationContentType?.page]],
            });
        }
    }, [paginationContentType?.page]);

    useEffect(() => {
        setPaginationContentType({ page: 0 });
        const listContentType = ContentTypes(inputSearchContentType, false, t);
        setlistContentType({ total: listContentType?.total, data: listContentType?.data?.[0] });
    }, [inputSearchContentType]);

    useEffect(() => {
        const typeH5p = selectedContectType?.find((type) => type?.value === 'h5p');
        if (!typeH5p) setSelectedH5PType([]);
    }, [selectedContectType]);

    useEffect(() => {
        const listCheckedTags = [];
        let listFilter: any = {
            created_by: selectedOwners?.map((owner) => owner?.id)?.join(','),
            h5p_types: selectedH5PType?.map((type) => type?.value)?.join(','),
            object_types: selectedContectType?.map((type) => type?.value)?.join(','),
            q: keyword,
            searchFolder: searchFolder?.value || null,
        };
        if (filterByTags?.tags) {
            Object.keys(filterByTags.tags).forEach((key) => {
                let item: any = {
                    custom_field_id: key
                };
                if (filterByTags?.tags[key]?.length > 0) {
                    item = {
                        ...item,
                        data: filterByTags?.tags[key].map(tag => tag.name)
                    }


                }
                listCheckedTags.push(item);
            });
            listFilter = {
                ...listFilter,
                custom_fields: JSON.stringify(listCheckedTags)
            }
        }
        onChangeFilter(listFilter);
    }, [selectedOwners, selectedH5PType, selectedContectType, keyword, filterByTags, searchFolder]);

    useEffect(() => {
        if (hasAddFilterFirst) {
            setlistContentType((prev) => {
                const newData = prev.data.map(item => ({
                    ...item,
                    disabled: hasTypeSelect(item)
                }));
                const slectContentType = newData?.filter(item => !item.disabled);
                if (selectedTags?.length > 0) {
                    handleSelectedContectType(slectContentType);
                } else {
                    handleSelectedContectType([]);
                }
                
                return {
                    ...prev,
                    data: newData
                }
            })
        }
    }, [selectedTags?.length, hasAddFilterFirst]);

    const hasTypeSelect = (data) => {
        let hasExist = true; 
        let selectType = data.value;
        if (selectType == 'h5p') {
            selectType = 'h5p_content';
        }
        if (FILE_TYPE.includes(selectType)) {
            selectType = 'file';
        }
        if (selectedTags?.length == 0) {
            hasExist = false;
        }
        selectedTags?.forEach(item => {
            const contentType = item.type.find(type => type === selectType);
            if (contentType) {
                hasExist = false
                return;
            }
        });
        return hasExist;
    }

    useEffect(() => {
        if (filterLocal) {
            const filterLocaltoJson = JSON.parse(filterLocal);
            setFilterLocalJson(filterLocaltoJson);
        }
    }, [filterLocal])

    useEffect(() => {
        if (!folderId) {
            setSearchFolder(optionsFolder[0]);
        }
    }, [folderId])

    return (
        <>
            <div className={`${listSelected?.length > 0 ? 'flex' : 'hidden'}`}>
                <SelectedFiles list={listSelected} onClose={onClose} onDelete={onDelete} onDuplicate={onDuplicate} setLoadingDownload={setLoadingDownload} onMoveFile={onMoveFile} />
            </div>
            <div className={`w-full flex flex-wrap gap-2 items-center ${listSelected?.length == 0 ? '' : 'hidden'}`}>
                {!!folderId ? (
                    <div className='flex items-center'>
                        <SearchInput
                            className="w-full max-w-[300px] !text-sm max-h-9 border-r-0 focus-within:border rounded-tr-none rounded-br-none"
                            onChange={(value) => setKeyword(value)}
                            value={keyword}
                        />
                        <Select
                            className="w-[275px]"
                            options={optionsFolder}
                            value={searchFolder}
                            onChange={(value) => setSearchFolder(value)}
                            customStyles={{
                                control: (base) => {
                                    return {
                                        ...base,
                                        minHeight: '0px',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        height: '36px',
                                        borderColor: '#ebebf0',
                                        maxHeight: '36px',
                                    }
                                },
                                valueContainer: (base) => {
                                    return { ...base, fontSize: '14px' }
                                },
                                indicatorsContainer: (base) => {
                                    return { ...base, padding: '0px', height: '36px' }
                                },
                            }}
                        />
                    </div>
                ) : (
                    <SearchInput
                        className="w-full max-w-[320px] !text-sm max-h-9"
                        onChange={(value) => setKeyword(value)}
                        value={keyword}
                    />
                )}
                <SelectBox
                    label={t('OWNER')}
                    onSearch={setInputSearchOwner}
                    onSelect={setSelectedOwners}
                    data={listOwners}
                    loading={loadingOwner}
                    selectedItems={selectedOwners}
                    total={pagination?.total}
                    showmore={() => getListOwner(pagination?.page + 1)}
                />
                <SelectBox
                    label={t('CONTENT_TYPE')}
                    onSearch={setInputSearchContentType}
                    onSelect={handleSelectedContectType}
                    data={listContentType?.data}
                    selectedItems={selectedContectType}
                    total={
                        Object.keys(selectedContectType)?.length > 0 ? Object.keys(CONTENT_TYPE)?.length : listContentType?.total
                    }
                    showmore={() => setPaginationContentType({ page: paginationContentType?.page + 1 })}
                />
                <SelectBox
                    label={t('H5P_TYPE')}
                    onSearch={setInputSearchH5PType}
                    onSelect={setSelectedH5PType}
                    selectedItems={selectedH5PType}
                    data={listH5PType?.data}
                    total={selectedH5PType?.length ? Object.keys(H5P_LIBRARY)?.length : listH5PType?.total}
                    showmore={() => setPaginationH5PType({ page: paginationH5PType?.page + 1 })}
                    isDisabled={!selectedContectType?.find((item) => item?.value === 'h5p')}
                />
                {selectedTags?.map((item) => (
                    <div key={item.id}>
                        <DropdownAsync
                            placeholder={item.name}
                            loadOptions={(payload) => workspaceService.getCustomFieldsByNameMapDataService(params.id,
                                {
                                    ...payload,
                                    custom_field_id: item.id,
                                    order: 'asc'
                                }
                            )}
                            onChange={(value) => {
                                setFilterByTags(prev => {
                                    const tags = prev?.tags ?? {};
                                    tags[item.id] = value;
                                    return {
                                        ...prev,
                                        tags
                                    }
                                })
                            }}
                            onRemove={() => {
                                setSelectedTags(prev => {
                                    return prev.filter(tag => tag.id != item.id);
                                });
                                setFilterByTags(prev => {
                                    const tags = prev?.tags ?? {};
                                    delete tags[item.id];
                                    return {
                                        ...prev,
                                        tags
                                    }
                                });
                            }}
                            isRemoved={true}
                            isAll={true}
                        />
                    </div>
                ))}
                {selectedContectType?.length == 1 && selectedContectType[0]?.value === 'folder' ? null :
                    <DropdownAsync
                        className="dropdownAsync--actived"
                        placeholder={translator("ADD_FILTER")}
                        loadOptions={(payload) => workspaceService.getCustomFieldsService(params.id, payload)}
                        onChange={onChangeAddFilter}
                        paramsApi={{
                            ...payloadAddFilter,
                            order: "asc",
                            sort_by: "name"
                        }}
                        isReset={hasReset}
                        hasArrow={false}
                        selectedValue={selectedTags}
                    >
                        <PlusIcon className="h-4 w-4 mr-2 text-primary-500" aria-hidden="true" />
                        <span className='text-primary-500'>{translator("ADD_FILTER")}</span>

                    </DropdownAsync>
                }
                <div className="h-[27px] w-[1px] bg-gray-200"></div>
                <ButtonComponent
                    title={translator('RESET')}
                    onClickButton={() => {
                        setSelectedOwners([]);
                        setSelectedContentType([]);
                        setSelectedH5PType([]);
                        setFilterByTags(null);
                        setSelectedTags(null);
                        setKeyword('');
                        setHasAddFilterFirst(false);
                        setlistContentType((prev) => {
                            const newData = prev.data.map(item => ({ ...item, disabled: false }));
                            return {
                                ...prev,
                                data: newData
                            }
                        });
                        setPayloadAddFilter(null);
                        setHasReset(prev => !prev);
                    }}
                    classStyle="text-primary-500 !px-0 !border-none !text-sm !font-normal disabled:!opacity-50 disabled:!bg-white disabled:!text-gray-800"
                    disable={!selectedContectType?.length && !selectedOwners?.length && !selectedContectType?.length && !selectedH5PType?.length && !selectedTags?.length &&!keyword}
                />
            </div>
        </>
    );
};

export default FilterSearch;
