import { ButtonBack } from '_shared';
import EnrollmentInformation from './EnrollmentInfomation';
import { setEnrollmentSelected } from 'store/features/courseSlice';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import Members from './Members/Member';
import './Detail.scss';
import { ENROLLMENT_TYPE } from '../../constants';
import { useEffect, useState } from 'react';
import { SessionList } from '../../../CourseScheduling/SessionList/SessionList';
import { GradebookDetails } from '../../../CourseGradebook/GradebookDetails/GradebookDetails';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Calendar from '../../../CourseScheduling/Calendar/Calendar';
import courseService from 'services/course.service';
import Credits from './Credits/Credits';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { CourseSubtab, CourseTab, CourseViewType } from 'types/Courses.type';

const LIST_TAB = (type, setting) => {
    return [
        {
            label: 'Enrollment Information',
            value: 'information',
            isHidden: type === ENROLLMENT_TYPE.LEARNING_GROUP,
        },
        {
            label: 'Members',
            value: 'members',
            isHidden: type === ENROLLMENT_TYPE.INDIVIDUAL,
        },
        {
            label: 'Calendar',
            value: 'calendar',
            isHidden: false,
        },
        {
            label: 'Session List',
            value: 'session-list',
            isHidden: false,
        },
        {
            label: 'Progress & History',
            value: 'gradebook',
            isHidden: false,
        },
        // {
        //     label: "Credits",
        //     value: "credits",
        //     isHidden: type === ENROLLMENT_TYPE.LEARNING_GROUP || setting?.enrollment_type !== 'credit'
        // }
    ];
};

export default function EnrollmentDetail() {
    const query = useQueryParamsURL();
    const params: { id: string; courseId: string } = useParams();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { enrollmentSelected, setting } = useAppSelector((state) => state.course);
    const { redirectToUrlCourse } = useUrlCourseNavigation();

    // const [tab, setTab] = useState('information');

    const tab = query.get('tab') as CourseTab;
    const subtab = query.get('subtab') as CourseSubtab;
    const enrollmentId = query.get('enrollmentId');
    const learnerId = query.get('learnerId');
    const learningGroupId = query.get('learningGroupId');
    const view = query.get('view') as CourseViewType;

    useEffect(() => {
        if (enrollmentId && view === 'information') {
            courseService.getEnrollmentDetail(params?.id, params?.courseId, enrollmentId).then((res) => {
                dispatch(setEnrollmentSelected({ ...res?.data }));
            });
        }
    }, [view, enrollmentId]);

    // useEffect(() => {
    //     setTab(view);
    // }, [view]);

    // const getUrl = (tab) => {
    //     if (tab == 'gradebook') {
    //         if (enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP) {
    //             return `&learnerGroupeId=${enrollmentSelected?.learning_group?.id}&view=gradebook&subtab=overview`;
    //         }
    //         return `&learnerId=${enrollmentSelected?.learner?.id}&view=gradebook&subtab=assignment`;
    //     }
    //     return `view=${tab}`;
    // };

    const handleSwitchView = (_view: CourseViewType) => {
        let queryParams = {};
        const viewObject = _view === 'gradebook' ? { viewGradebook: subtab === 'learner' ? 'assignment' : 'overview' } : {};

        if (subtab === 'learner') {
            queryParams = {
                enrollmentId,
                view: _view,
                ...viewObject,
                learnerId: enrollmentSelected?.learner?.id || learnerId,
            };
        } else if (subtab === 'learning-group') {
            queryParams = {
                learningGroupId,
                view: _view,
                ...viewObject,
                learnerId: enrollmentSelected?.learner?.id || learnerId,
            };
        }
        redirectToUrlCourse({
            tab,
            subtab,
            ...queryParams,
        });
    };

    return (
        <div className="EnrollmentDetail">
            <div className="BackBtn px-4">
                <ButtonBack
                    hideLabel={true}
                    onClick={() =>
                        redirectToUrlCourse({
                            tab,
                            subtab,
                        })
                    }
                />
                <div className="text-ellipsis truncate">
                    {enrollmentSelected?.type === ENROLLMENT_TYPE.INDIVIDUAL ? 'Learners: ' : 'Learning Group: '}
                    {enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP
                        ? enrollmentSelected?.learning_group?.name
                        : enrollmentSelected?.learner?.display_name}
                </div>
            </div>

            <div className="EnrollmentDetail-Tablist px-4">
                {LIST_TAB(enrollmentSelected?.type, setting)
                    ?.filter((tabItem) => !tabItem?.isHidden)
                    .map((tabItem) => (
                        <div
                            className={`tab-default cursor-pointer ${view === tabItem?.value ? 'active' : ''}`}
                            key={tabItem?.value}
                            onClick={() => {
                                // setTab(tabItem?.value);
                                // history.push(
                                //     `/workspace/${params.id}/management/courses/${
                                //         params.courseId
                                //     }?tab=people&enrollmentId=${enrollmentId}&${getUrl(tabItem?.value)}`
                                // );
                                handleSwitchView(tabItem?.value as CourseViewType);
                            }}
                        >
                            {tabItem?.label}
                        </div>
                    ))}
            </div>
            <div className="px-4">
                {view === 'information' && <EnrollmentInformation />}
                {view === 'members' && <Members />}
                {view === 'calendar' && (
                    <Calendar
                        hasFilterLearner={false}
                        learnerId={enrollmentSelected?.learning_group?.id || enrollmentSelected?.learner?.id}
                        hasGroupLearner={!!enrollmentSelected?.learning_group?.id}
                    />
                )}
                {view === 'session-list' && (
                    <SessionList
                        hasFilterLearner={false}
                        learner_group_ids={enrollmentSelected?.learning_group?.id}
                        learnerId={enrollmentSelected?.learner?.id}
                    />
                )}
                {view === 'gradebook' && <GradebookDetails hasBackButton={false} />}
                {/* {view === 'credits' && <Credits enrollmentSelected={enrollmentSelected} />} */}
            </div>
        </div>
    );
}
