import React, { useContext } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import NO_CONTENT_IMG from 'assets/img/empty-state/learning-path.png';
import ActionMenu from './ActionMenu';
import { useTranslation } from 'react-i18next';

export enum LEARNING_PATH_STATUS {
    draft = 'draft',
    published = "published",
}

interface TableGroupsProps {
    workspaceId: string;
    dataTable: any;
    translator: (key: string) => string;
    setSelected: Function;
    pagination: any;
    onChangeOrder: any;
    onDelete: Function;
    onRename: Function;
    onViewDetail: Function;
    onDuplicateLearningPath: (learningPathId: string) => void;
    onRevertToDraft: Function;
}

const TableLearningPath: React.FC<TableGroupsProps> = ({
    workspaceId,
    dataTable,
    translator,
    setSelected,
    pagination,
    onChangeOrder,
    onDelete,
    onRename,
    onDuplicateLearningPath,
    onViewDetail,
    onRevertToDraft
}) => {
    const {
        userState: { result },
    } = useContext(UserContext);

    const {i18n: {language}} = useTranslation();

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('NAME')} />,
                Cell: ({ row, column }: { row: Row; column: Column }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/learning-path/${row.values.id}`}
                            className="text-ooolab_sm text-primary-500 font-semibold cursor-pointer truncate"
                            isTooltip
                            value={row.values?.title}
                            disable={row.values.title?.length < 35}
                        />
                    );
                },
                accessor: 'title',
                width: 180,
            },
            {
                Header: () => <RenderTableHeader value={translator('OWNER')} />,
                accessor: 'created_by',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="flex items-center px-2">
                            <div className="flex-none w-6">
                                <img alt="avatar-icon" className="h-6 w-6 rounded-full" src={row?.original?.created_by?.avatar_url} />
                            </div>
                            <div className='text-sm font-normal ml-2 w-full truncate text-ellipsis'>{row?.original?.created_by?.display_name}</div>
                        </div>
                    );
                },
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="py-3 ml-2 ">
                            <span
                                className={`text-sm font-normal border rounded px-3 py-[1px] capitalize inline-block
                        ${
                            row?.original?.status == LEARNING_PATH_STATUS.published
                                ? 'text-green-500 border-green-300 bg-green-50'
                                : 'text-gray-500 border-gray-300 bg-gray-50'
                        }`}
                            >
                                {translator(LEARNING_PATH_STATUS[row?.original?.status]?.toUpperCase())}
                            </span>
                        </div>
                    );
                },
                width: 60,
            },
            {
                Header: () => (
                    <div
                        className="flex items-center cursor-pointer"
                        onClick={() => onChangeOrder(pagination?.order === 'desc' ? 'asc' : 'desc')}
                    >
                        <RenderTableHeader value={translator('LAST_MODIFIED')} />
                        {pagination?.order === 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1" />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1" />
                        )}
                    </div>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell
                            className=" p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                            onClick={() => {}}
                            value={dayjs.utc(d?.value).tz(result?.time_zone)?.locale(language)?.fromNow()}
                        />
                    );
                },
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={row?.original}
                                onViewDetail={() => {
                                    onViewDetail();
                                    setSelected(row?.original);
                                }}
                                onRename={() => {
                                    onRename();
                                    setSelected(row?.original);
                                }}
                                onDelete={() => {
                                    onDelete();
                                    setSelected(row?.original);
                                }}
                                onRevertToDraft={() => {
                                    setSelected(row?.original);
                                    onRevertToDraft();
                                }}
                                onDuplicate={() => onDuplicateLearningPath(row?.original?.id)}   
                                position={row?.index > dataTable?.length/2 ? 'bottom-0': ''}        
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
            },
        ],
        [dataTable, pagination]
    );

    return (
        <Table
            data={dataTable}
            columns={columns}
            initialState={initialState}
            emptyContent={translator('NO_LEARNING_PATH')}
            emptyIcon={NO_CONTENT_IMG}
        />
    );
};

export default TableLearningPath;
