import { ChevronRightIcon } from '@heroicons/react/outline';
import { NotificationIcon } from 'assets/icon/NotificationIcon';
import { FC, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { TeacherDashboardService } from 'services';
import { classNames } from 'utils/commonFun';

type PagesProps = {
    name: string;
    href: string;
    className?: string;
    onClick?: any;
};

type PageBreadcrumbProps = {
    containerClassName?: string;
    pageClassName?: string;
    pageNameDefault?: string;
    pages: PagesProps[];
    onClickPageNameDefault?: any;
    isShowIconNotification?: boolean
};

const PageBreadcrumb: FC<PageBreadcrumbProps> = ({
    containerClassName,
    pageClassName,
    pageNameDefault,
    pages,
    onClickPageNameDefault,
    isShowIconNotification = false
}) => {
    const params: {id} = useParams();
    const history = useHistory();
    const [totalUnread, setTotalUnread] = useState(0)

    const readAllNotification = () => {
        history.push(`/workspace/${params?.id}/instructor-dashboard/notifications`)
        TeacherDashboardService.readAllNotification(params?.id)
        .then(() => {
            if (history?.location?.pathname !== `/workspace/${params?.id}/instructor-dashboard/notifications`) {
                setTotalUnread(0)
            }
            else setTotalUnread(0)
        })
    };

    const getTotalUnread = () => {
        TeacherDashboardService.getStisticNotification(params?.id).then((res) => {
            setTotalUnread(res?.data?.total_unread);
        });
    };

    useEffect(() => {
        if (isShowIconNotification) {
            getTotalUnread();
        }
    }, [])

    return (
        <div
            className={classNames('bg-white max-h-[59px] p-5 border-b flex justify-between items-center', containerClassName)}
        >
            <div className={classNames('w-full flex items-center text-sm font-normal text-gray-800')}>
                {pageNameDefault && (
                    <h1
                        onClick={() => onClickPageNameDefault?.()}
                        className={classNames('text-gray-500 hover:text-gray-500 min-w-fit mr-1 cursor-pointer', pageClassName)}
                    >
                        {pageNameDefault}
                    </h1>
                )}
                {pages?.length > 3 ? (
                    <>
                        <ChevronRightIcon width={16} height={16} />{' '}
                        {!pages[0]?.href ? (
                            <div onClick={() => pages[0]?.onClick?.()}>{pages[0]?.name}</div>
                        ) : (
                            <Link to={pages[0]?.href} className={''} key={0}>
                                {pages[0]?.name}
                            </Link>
                        )}
                        <ChevronRightIcon width={16} height={16} />
                        ...
                        {pages?.slice(pages?.length - 2)?.map((item, index) => {
                            return (
                                <div className='flex items-center' key={index}>
                                    <ChevronRightIcon width={16} height={16} />{' '}
                                    {!item?.href ? (
                                        <div onClick={() => item?.onClick?.()} className={`ml-1`}>{item?.name}</div>
                                    ) : (
                                        <Link to={item?.href} className={''} key={index}>
                                            {item?.name}
                                        </Link>
                                    )}
                                </div>
                            );
                        })}
                    </>
                ) : (
                    pages?.map((page, index) => {
                        return (
                            <div className='flex items-center' key={index}>
                                {page?.name && <ChevronRightIcon width={16} height={16} />}
                                {page?.href ? (
                                    <Link
                                        to={page?.href}
                                        className={classNames(
                                            index === pages?.length - 1 ? 'font-semibold min-w-fit' : 'min-w-fit ml-1.5 text-gray-500',
                                            page?.className,
                                        )}
                                    >
                                        {page?.name}
                                    </Link>
                                ) : (
                                    <div
                                        className={classNames(
                                            index === pages?.length - 1
                                                ? 'font-semibold text-ellipsis truncate ml-1.5 max-w-xl'
                                                : 'text-ellipsis truncate cursor-pointer',
                                            page?.className,
                                        )}
                                        onClick={() => page?.onClick?.()}
                                    >
                                        {page?.name}
                                    </div>
                                )}
                            </div>
                        );
                    })
                )}
            </div>

            {
                isShowIconNotification && (
                    <button className='notificationcount' onClick={(e) => {
                        e.stopPropagation();
                        readAllNotification();
                    }}>
                        {totalUnread > 0 && <span className='notificationcount-number'>{totalUnread}</span>}
                        <NotificationIcon />
                    </button>
                )
            }
        </div>
    );
};

export default PageBreadcrumb;
