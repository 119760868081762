import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import PageBreadcrumb from "components/Breadcrumb/PageBreadcrumb";
import "./AssignmentCenter.scss";
import AssignmentFilter from "./AssignmentFIlter/AssignmentFilter";
import assignmentService from "services/assignment.service";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import TablePagination from "components/Pagination";
import ViewTable from "./ViewTable/ViewTable";
import { getIndexPage } from "utils/commonFun";
import { ButtonOutline, Toastify } from "_shared";
import { EditLine } from "assets/icon";
import DialogComponent from "components/Dialog/DialogComponent";
import { ChangeDueDate } from './ViewTable/ChangeDueDate';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import { useForm } from "react-hook-form";
import { XIcon } from "@heroicons/react/outline";

export const enum assignmentFromEnum {
    from_session = 'from_session',
    from_e_learning = 'from_e_learning',
    from_assigned = 'from_assigned'
};

type AssignmentCenterProps = {
    children: React.ReactNode
}


export const AssignmentCenter = ({ children }: AssignmentCenterProps) => {
    const history = useHistory();
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [assignments, setAssignments] = useState([]);
    const [filter, setFilter] = useState<any>();
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        total: 0,
    });
    const [indexPage, setIndexPage] = useState('0-0');
    const [sort, setSort] = useState('desc');
    const [assignmentSelected, setAssignmentSelected] = useState([]);
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);

    const { control, setValue, getValues, watch } = useForm({ defaultValues: { assignment_from: true } });

    const workspaceId = params.id;

    const getAssignments = (page: number | string = 1, order = 'desc', _filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            ..._filter,
            assignment_from: getValues('assignment_from') ? 'from_assigned' : undefined,
            order: order || sort,
            page,
        }
        delete payload?.total;
        if (!payload?.assignment_from) delete payload?.assignment_from;
        
        assignmentService.getAssignmentList(workspaceId, payload)
            .then((res) => {
                setFirstLoad(false);
                const { items, page, per_page, total } = res;
                setAssignments(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(page, per_page, items.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const onEditDueDateAssignment = (date) => {
        const payload = {
            assignments: assignmentSelected.map(el => el?.id),
            end_time: date,
        }
        assignmentService.updateDueDateAssignmentService(workspaceId, payload)
            .then((res) => {
                setAssignmentSelected([]);
                getAssignments();
                Toastify.success('Update Due Date Successful!');
            })
            .catch(() => Toastify.error());
    }

    const handlePagination = (page: number) => {
        getAssignments(page, sort);
    };

    useEffect(() => {
        getAssignments();
    }, [watch('assignment_from')]);


    const onChangeFilter = (filtervalue) => {
        setFilter(filtervalue);
        getAssignments(1, pagination?.order, filtervalue)
    }

    const onChangeOrder = (value) => {
        setSort(value);
        getAssignments(pagination.page, value, filter)
    }

    return (
        <div className="instructorDashboard">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.DASHBOARD')}
                pages={[
                    { name: translator('ASSIGNMENT_CENTER.ASSIGNMENT_CENTER'), href: ``, className: '' },
                ]}
                containerClassName="z-10 sticky top-0"
            />
            <div className='px-5 pt-4 text-sm text-gray-800 flex items-center justify-between'>
                <div>
                    <h1 className="text-lg font-semibold">{translator('ASSIGNMENT_CENTER.ASSIGNMENT_CENTER')}</h1>
                    <p className="text-xs text-gray-500">{translator('This page displays the latest attempt result by default')}</p>
                </div>
                <p>
                    <SwitchToggle
                        name='assignment_from'
                        control={control}
                        enableColor
                    />
                    <span className="ml-2">{translator('ASSIGNMENT_CENTER.My_Assigned')}</span>
                </p>
            </div>
            <div className="instructorDashboard-filter">
                <AssignmentFilter onChangeFilter={onChangeFilter} isFirstLoad={isFirstLoad} />
            </div>
            <div className={`${!!assignmentSelected?.length ? 'mt-3' : ''} px-5 pb-4 flex items-center space-x-3`}>
                {!!assignmentSelected?.length && (
                    <>
                        <span className="cursor-pointer" onClick={() => setAssignmentSelected([])}>
                            <XIcon className="w-6 h-6 text-gray-500 hover:text-red-500" />
                        </span>
                        <p className="pr-2">{assignmentSelected?.length + ' assignment(s) selected'}</p>
                        <ButtonOutline
                            disabled={!assignmentSelected?.length}
                            type="button"
                            onClick={() => { setOpenModalEdit(true) }}
                            className="buttonOutline--isPrimary"
                        >
                            <EditLine />
                            {translator('EDIT')}
                        </ButtonOutline>
                    </>
                )}
            </div>
            <div className="instructorDashboard-main">
                <div className={`instructorDashboard-tableBody ${!!assignmentSelected?.length ? 'h-[calc(100vh-360px)]' : 'h-[calc(100vh-316px)]'}`}> 
                    {loading ?
                        <div className='loading'>
                            <CircleSpin color=' text-primary-500' />
                        </div> : <ViewTable
                            data={assignments}
                            refreshData={getAssignments}
                            onChangeOrder={onChangeOrder}
                            order={sort}
                            selected={assignmentSelected}
                            setSelected={setAssignmentSelected}
                        />
                    }

                </div>
                <div className="instructorDashboard-pagination ">
                    <p className="instructorDashboard-paginationCount">
                        {translator('SHOWING_1_50_OF_TOTAL_PAGE', {
                            fromTo: indexPage,
                            totalPage: pagination?.total,
                        })}
                    </p>
                    <div className="mt-[-1px]">
                        <TablePagination
                            onClickPagination={(e) =>
                                handlePagination(e)
                            }
                            perPage={pagination.per_page}
                            total={pagination.total}
                        />
                    </div>
                </div>
            </div>
            <DialogComponent
                title={translator('COURSES.EDIT_DUE_DATE')}
                isOpen={isOpenModalEdit}
                onCloseModal={() => setOpenModalEdit(false)}
                isShowClose={true}
                styles="max-w-[400px] !py-1.5 !px-6"
                child={
                    <ChangeDueDate 
                        data={{ end_time: new Date() }} 
                        onClose={(value) => {
                            if (value) {
                                onEditDueDateAssignment(value);
                            }
                            setOpenModalEdit(false);
                        }} 
                        loading={false} 
                    />
                }
            />
        </div>
    )
}