import { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { debounce } from 'lodash';
import './AssignmentFilter.scss';
import { assignmentFromEnum } from '../AssignmentCenter';
import instructorsService from 'services/instructors.service';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceService from 'services/workspace.service';
import DatePickerComponent from 'components/Reports/components/DatePicker';
import moment from 'moment';

const ASSIGNMENT_FROM = (translator?: any) => {
    return [
        { name: translator('SESSIONS'), value: assignmentFromEnum.from_session },
        { name: translator('ASSIGNED'), value: assignmentFromEnum.from_assigned },
        { name: translator('E_LEARNING'), value: assignmentFromEnum.from_e_learning },
    ]
}

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

const AssignmentFilter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const params: any = useParams();
    const { t } = useTranslation();

    const translator = (str: string, newKey?: boolean, opt?: any) =>
        newKey ? t(str, opt) : t(`ASSIGNMENT_CENTER.${str}`, opt);

    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('');
    const [inputSearchLearner, setInputSearchLearner] = useDebouncedState('');
    const [inputSearchLearnerGroup, setInputSearchLearnerGroup] = useDebouncedState('');
    const [inputSearchGradeBy, setInputSearchGradeBy] = useDebouncedState('');
    const [statusSelected, setStatusSelected] = useState<any[]>([])
    const [overdueSelected, setOverdueSelected] = useState<any[]>([])
    const [assignmentFrom, setAssignmentFrom] = useState<any>(ASSIGNMENT_FROM(translator)[0])
    const [courseList, setCourseList] = useState<any[]>([]);
    const [learnerList, setLearnerList] = useState<any[]>([]);
    const [learnerGroupList, setLearnerGroupList] = useState<any[]>([]);
    const [courseSelected, setCourseSelected] = useState([]);
    const [learnerSelected, setLearnerSelected] = useState([]);
    const [learnerGroupSelected, setLearnerGroupSelected] = useState([]);
    const [loadingCourse, setLoadingCourse] = useState(false);
    const [loadingLearner, setLoadingLearner] = useState(false);
    const [loadingLearnerGroup, setLoadingLearnerGroup] = useState(false);
    const [loadingGradedBy, setLoadingGradedBy] = useState(false);
    const [gradedBySelected, setGradedBySelected] = useState([]);
    const [gradedByList, setGradedByList] = useState<any[]>([]);
    const [dueDateFrom, setDueDateFrom] = useState();
    const [dueDateTo, setDueDateTo] = useState();
    const [completionDateFrom, setCompletionDateFrom] = useState();
    const [completionDateTo, setCompletionDateTo] = useState();
    
    const [paginationCourse, setPaginationCourse] = useState<any>({
        page: 1,
        total: 0,
        per_page: 20,
        sort_by: 'display_name',
    });
    const [paginationLearner, setPaginationLearner] = useState<any>({
        page: 1,
        total: 0,
        per_page: 20,
        sort_by: 'display_name',
    });
    const [paginationLearnerGroup, setPaginationLearnerGroup] = useState<any>({
        page: 1,
        total: 0,
        per_page: 20,
        sort_by: 'name',
    });
    const [paginationGradedBy, setPaginationGradedBy] = useState<any>({
        page: 1,
        total: 0,
        per_page: 20,
        sort_by: 'display_name',
    });
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const {
        result: { membership },
    } = getWorkspaceDetailState;

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 700),
        []
    );

    const getLearnerInstructorsRelations = (page: number = 1) => {
        setLoadingLearner(true);
        instructorsService.getRelationDataOfInstructor(
            params.id,
            {
                page: page || paginationLearner.page,
                per_page: 10,
                q: inputSearchLearner,
                order: 'desc',
                sort_by: 'created_at',
                relation_type: 'learner',
                instructor_user_id: membership?.user_id,
            }
        )
            .then((res) => {
                const formattedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.display_name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPaginationLearner({ ...paginationLearner, total: res?.data?.total, page });
                if (page === 1) setLearnerList(formattedData);
                else setLearnerList([...learnerList, ...formattedData]);
            })
            .finally(() => setLoadingLearner(false));
    };

    const getLearnerGroupInstructorsRelations = (page: number = 1) => {
        setLoadingLearnerGroup(true);
        instructorsService.getRelationDataOfInstructor(
            params.id,
            {
                page: page || paginationLearnerGroup.page,
                per_page: 10,
                q: inputSearchLearnerGroup,
                order: 'desc',
                sort_by: 'created_at',
                relation_type: 'learning_group',
                instructor_user_id: membership?.user_id,
            }
        )
            .then((res) => {
                const formattedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPaginationLearnerGroup({ ...paginationLearnerGroup, total: res?.data?.total, page });
                if (page === 1) setLearnerGroupList(formattedData);
                else setLearnerGroupList([...learnerGroupList, ...formattedData]);
            })
            .finally(() => setLoadingLearnerGroup(false));
    };

    const getGradedByRelations = (page: number = 1) => {
        setLoadingGradedBy(true);
        workspaceService.getWorkspaceMembersService(
            params.id,
            {
                page: page || paginationGradedBy.page,
                per_page: 10,
                q: inputSearchGradeBy,
                order: 'desc',
                sort_by: 'created_at',
                location: 'clms'
            }
        )
            .then((res) => {
                const formattedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.display_name,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPaginationGradedBy({ ...paginationGradedBy, total: res?.data?.total, page });
                if (page === 1) setGradedByList(formattedData);
                else setGradedByList([...gradedByList, ...formattedData]);
            })
            .finally(() => setLoadingGradedBy(false));
    };

    const getCourseInstructorsRelations = (page: number = 1) => {
        setLoadingCourse(true);
        instructorsService.getRelationDataOfInstructor(
            params.id,
            {
                page: page || paginationCourse.page,
                per_page: 10,
                q: inputSearchCourse,
                order: 'desc',
                sort_by: 'created_at',
                relation_type: 'course',
                instructor_user_id: membership.user_id,
            }
        ).then((res) => {
            const formattedData = res?.data?.items?.map((item) => {
                return {
                    name: item?.title,
                    id: item?.id,
                    value: item?.id,
                };
            });
            setPaginationCourse({ ...paginationCourse, total: res?.data?.total, page });
            if (page === 1) setCourseList(formattedData);
            else setCourseList([...courseList, ...formattedData]);
        })
            .finally(() => setLoadingCourse(false));
    };

    const onChangeDueDate = (dates) => {
        const [start, end] = dates;
        setDueDateFrom(start);
        setDueDateTo(end);
    }

    const onChangeCompletionDateTo = (dates) => {
        const [start, end] = dates;
        setCompletionDateFrom(start);
        setCompletionDateTo(end);
    }

    useEffect(() => {
        if (membership?.user_id != -1) {
            getLearnerInstructorsRelations(1);
        }
    }, [inputSearchLearner, membership]);

    useEffect(() => {
        if (membership?.user_id != -1) {
            getLearnerGroupInstructorsRelations(1);
        }
    }, [inputSearchLearnerGroup, membership]);

    useEffect(() => {
        if (membership?.user_id != -1) {
            getGradedByRelations(1);
        }
    }, [inputSearchGradeBy, membership]);

    useEffect(() => {
        if (membership?.user_id != -1) {
            getCourseInstructorsRelations(1);
        }
    }, [inputSearchCourse, membership]);

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                q: keyword,
                state: statusSelected?.[0]?.value,
                overdue_tag: overdueSelected?.[0]?.value,
                learner_ids: learnerSelected?.map((item) => item?.id)?.join(',') || undefined,
                course_ids: courseSelected?.map((item) => item?.id)?.join(',') || undefined,
                learning_group_ids: learnerGroupSelected?.map((item) => item?.id)?.join(',') || undefined,
                graded_user_ids: gradedBySelected?.map((item) => item?.id)?.join(',') || undefined,

            };
            if (!listFilter?.q) delete listFilter?.q
            if (statusSelected.length === 4) delete listFilter.status
            if (dueDateTo && dueDateFrom) {
                listFilter = {
                    ...listFilter,
                    due_date_from: moment(dueDateFrom).format('YYYY-MM-DD 00:00:00'),
                    due_date_to: moment(dueDateTo).format('YYYY-MM-DD 00:00:00')
                }
            }
            if (completionDateFrom && completionDateTo) {
                listFilter = {
                    ...listFilter,
                    completion_date_from: moment(completionDateFrom).format('YYYY-MM-DD 00:00:00'),
                    completion_date_to: moment(completionDateTo).format('YYYY-MM-DD 00:00:00'),
                }
            }
            updateFilter(listFilter);
        }
    }, [keyword, 
        assignmentFrom, 
        statusSelected,
        overdueSelected, 
        learnerSelected, 
        courseSelected,
        learnerGroupSelected,
        gradedBySelected,
        dueDateTo,
        completionDateTo,
    ]);

    return (
        <>
            <div className="filterAssignment">
                <SearchBox className="filterAssignment-searchInput" onSubmit={(value) => setKeyword(value)} />

                <div className='filterAssignment-assignmentFrom !hidden'>
                    {ASSIGNMENT_FROM(translator)?.map((i, idx) => <div className={`items ${assignmentFrom?.value === i?.value ? 'selectedFrom' : ''}`} key={idx} onClick={(() => {
                        setAssignmentFrom(i)
                    })}>{i?.name} </div>)}
                </div>

                <SelectBox
                    label={'Learners'}
                    onSearch={setInputSearchLearner}
                    onSelect={setLearnerSelected}
                    data={learnerList}
                    loading={loadingLearner}
                    selectedItems={learnerSelected}
                    total={paginationLearner?.total}
                    showmore={() => getLearnerInstructorsRelations(paginationLearner?.page + 1)}
                />

                <SelectBox
                    label={'Learning Group'}
                    onSearch={setInputSearchLearnerGroup}
                    onSelect={setLearnerGroupSelected}
                    data={learnerGroupList}
                    loading={loadingLearnerGroup}
                    selectedItems={learnerGroupSelected}
                    total={paginationLearnerGroup?.total}
                    showmore={() => getLearnerGroupInstructorsRelations(paginationLearnerGroup?.page + 1)}
                />

                <SelectBox
                    label={'Courses'}
                    onSearch={setInputSearchCourse}
                    onSelect={setCourseSelected}
                    data={courseList}
                    loading={loadingCourse}
                    selectedItems={courseSelected}
                    total={paginationCourse?.total}
                    showmore={() => getCourseInstructorsRelations(paginationCourse?.page + 1)}
                />

                <SelectBox
                    label={'Status'}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    isMulti={false}
                    data={[
                        { name: translator('ASSIGNED'), value: 'assigned' },
                        { name: translator('IN_PROGRESS'), value: 'in_progress' },
                        { name: translator('WAITING_FOR_GRADE'), value: 'waiting_for_grade' },
                        // { name: translator('OVERDUE'), value: 'overdue' },
                        { name: translator('COMPLETED'), value: 'completed' },
                    ]}
                    total={4}
                />
                <SelectBox
                    label={'Overdue'}
                    onSelect={setOverdueSelected}
                    selectedItems={overdueSelected}
                    isMulti={false}
                    data={[
                        { name: t('YES'), value: true },
                        { name: t('NO'), value: false },
                    ]}
                    total={2}
                />
                <SelectBox
                    label={'Graded by'}
                    onSearch={setInputSearchGradeBy}
                    onSelect={setGradedBySelected}
                    data={gradedByList}
                    loading={loadingGradedBy}
                    selectedItems={gradedBySelected}
                    total={paginationGradedBy?.total}
                    showmore={() => getGradedByRelations(paginationGradedBy?.page + 1)}
                />
                <div className='relative min-w-[108px]'>
                    <DatePickerComponent placeHolder="Due Date" onChange={onChangeDueDate} startDate={dueDateFrom} endDate={dueDateTo} />
                </div>
                <div className='relative min-w-[108px]'>
                    <DatePickerComponent placeHolder="Completed Date" onChange={onChangeCompletionDateTo} startDate={completionDateFrom} endDate={completionDateTo}  />
                </div>
                <div className="line"></div>
                <button
                    onClick={() => {
                        onChangeFilter({});
                        setStatusSelected([]);
                        setOverdueSelected([]);
                        setLearnerSelected([]);
                        setCourseSelected([]);
                        setLearnerGroupSelected([]);
                        setGradedBySelected([]);
                        setDueDateTo(null);
                        setDueDateFrom(null);
                        setCompletionDateTo(null);
                        setCompletionDateFrom(null);

                    }}
                    className="btn-reset mr-4"
                    disabled={!statusSelected?.length &&
                        !overdueSelected?.length && 
                        !learnerSelected?.length && 
                        !courseSelected?.length && 
                        !gradedBySelected?.length && 
                        !learnerGroupSelected?.length && 
                        !dueDateTo && 
                        !completionDateTo
                    }
                >
                    {translator('RESET')}
                </button>
            </div>
        </>
    );
};

export default AssignmentFilter;
