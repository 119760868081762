import { PlusIcon } from '@heroicons/react/outline';
import { ButtonPrimary } from '_shared'
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getIndexPage } from 'utils/commonFun';
import Table from './Table/Table';
import Pagination from 'components/V2/Pagination';
import skillService from 'services/skill.service';
import ModalAddSkill from './ModalAddSkill';

const defaulPagination = {
  page: 1,
  per_page: 20,
  order: 'desc',
  sort_by: 'created_at',
  total: 0,
};

export default function Skills({userData}) {
  const { t } = useTranslation();
  const params: { id: string; userId: string } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [indexPage, setIndexPage] = useState('0-0');
  const [pagination, setPagination] = useState<any>(defaulPagination);
  const [isOpenModalAddSkill, setOpenModalAddSkill] = useState(false);

  const workspaceId = params.id;
  const userId = params.userId;

  const getData = (page: number = 1) => {
      setLoading(true);
      const payload = {
          ...pagination,
          userId,
          page,
      };
      delete payload?.total;
      skillService
          .getLearnerSkill(workspaceId, userId, payload)
          .then((res) => {
              const { items, total, page } = res.data;
              setData(items);
              setPagination({ ...pagination, total, page });
              setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
          })
          .catch()
          .finally(() => setLoading(false));
  };

  const handlePagination = (page: number) => {
      getData(page);
  };

  useEffect(() => {
    getData(1);
  }, []);

  return (
    <div className=''>
      <div className="w-full flex justify-between mt-3 border-b border-gray-300">
          <h1 className='text-lg font-semibold'>{t("DASHBOARD.SIDEBAR.SKILLS")}</h1>
          <div className='flex gap-3 items-center pb-5'>
            <ButtonPrimary onClick={() => setOpenModalAddSkill(true)} type="submit"><PlusIcon className='w-5 h-5 text-white' /> {t("ADD")}</ButtonPrimary>
          </div>
      </div>
      <div className="CourseReport-main mt-5">
          <div className="wrapper-content">
              <div className="scrollbar">
                  {loading && (
                      <div className="loading">
                          <CircleSpin color="text-primary-500" />
                      </div>
                  )}
                  {!loading && (
                    <Table data={data} refreshData={getData} />
                  )}
              </div>
              <Pagination
                  total={pagination.total}
                  per_page={pagination.per_page}
                  callback={handlePagination}
                  indexPage={indexPage}
                  page={pagination?.page}
              />
          </div>
      </div>
      {
        isOpenModalAddSkill && (
          <ModalAddSkill
            isOpen={isOpenModalAddSkill}
            onClose={() => setOpenModalAddSkill(false)}
            refreshData={getData}
            userData={userData}
          />
        )
      }
    </div>
  )
}
