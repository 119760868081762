import { Popover } from "@headlessui/react";
import { PauseIcon } from "@heroicons/react/outline";
import { PlayIcon } from "assets/icon/PlayIcon copy";
import { SpeedIcon } from "assets/icon/SpeedIcon";
import { VolumeIcon } from "assets/icon/VolumnIcon";


import { useRef, useState, useEffect } from "react";

export default function AudioPlayer({ src }) {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [speed, setSpeed] = useState(1);
    const onChangeSpeed = (e, newSpeed) => {
        e.preventDefault();
        setSpeed(newSpeed);
        audioRef.current.playbackRate = newSpeed;
    };
    // const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1); // Thêm state volume
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);


    useEffect(() => {
        const audio = audioRef.current;
        audio.pause();
        audio.currentTime = 0;
        setProgress(0);
        audio.addEventListener("ended", () => {
            setIsPlaying(false);
            setProgress(0);
        });
    }, []);

    const togglePlay = async (e) => {
        e.preventDefault();
        if (!audioRef.current) return;
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const seekAudio = (e) => {
        const newTime = (e.nativeEvent.offsetX / e.target.clientWidth) * duration;
        audioRef.current.currentTime = newTime;
        setProgress((newTime / duration) * 100);
    };

    const formatTime = (seconds) => {
        if (isNaN(seconds)) return "00:00";
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    useEffect(() => {
        const getMetaAudio = async () => {
            const response = await fetch(`${src}?t=${Date.now()}`);
            const arrayBuffer = await response.arrayBuffer();
            // Sử dụng Web Audio API để phân tích file
            //@ts-ignore
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
            if (audioBuffer) {
                setDuration(audioBuffer?.duration);
            }
        }
        try {
            getMetaAudio();
            
        } catch (error) {
            console.error("Lỗi khi lấy duration:", error);
            setDuration(null);
        }
    }, [src]);

    useEffect(() => {
        if (!audioRef.current) return;
        const updateTimer = () => {
            const currentTime = audioRef.current.currentTime;
            if (currentTime >= duration) {
                clearInterval(timer);
            }
            setCurrentTime(currentTime);
            setProgress((currentTime / duration) * 100);
        };

        const timer = setInterval(updateTimer, 1000);

        return () => clearInterval(timer);

    }, [duration]);
    

    const handleVolumeChange = (e) => {
        const value = e.target.value;
        if (audioRef.current) {
            audioRef.current.volume = value; // Thay đổi volume của audio
            setVolume(value); // Cập nhật state volume
        }
    };

    return (
        <div className="w-full">
            <audio ref={audioRef} src={src} preload="metadata" crossOrigin="anonymous" />
            <div className="flex items-center gap-4">
                <button
                    onClick={togglePlay}
                    className="flex gap-2 text-xs items-center text-gray-800"
                >
                    {
                        isPlaying ? <PauseIcon className="text-gray-800 w-6 h-6" /> : <PlayIcon />
                    }
                    {/* { isPlaying &&  */}
                        <span className="text-xs text-gray-800 flex items-center gap-1 whitespace-nowrap">
                            {formatTime(currentTime)} / {duration ? formatTime(duration) : "00:00"}
                        </span>
                    {/* } */}
                </button>
                <div
                    className="w-[calc(100%-50px)] h-2 bg-gray-200 rounded-full cursor-pointer"
                    onClick={seekAudio}
                >
                    <div
                        className="h-2 bg-gray-700 rounded-full"
                        style={{ width: `${progress}%` }}
                    ></div>
                </div>
                <div className="flex relative items-center group">
                    <label htmlFor="volume-slider" className="w-6 h-6 mb-2"><VolumeIcon /></label>
                    <div className=" hidden group-hover:block p-2 px-4 bg-white rounded-full shadow  cursor-pointer -rotate-90 absolute bottom-16 -right-11 z-10">
                        <input
                            id="volume-slider"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={volume}
                            onChange={handleVolumeChange}
                            className=" w-20 h-2"
                        />
                    </div>
            </div>
                <Popover className={`relative`}>
                    <Popover.Button className={'mt-1 outline-none'} >
                        <SpeedIcon />
                    </Popover.Button>
                    <Popover.Panel className='!w-20 text-center text-xs !bg-white !shadow-md !rounded-lg !p-4 !flex !flex-col !gap-2 absolute top-10 -left-5'>
                        <button className={`${speed === 0.25 ? 'text-primary-500' : ''}`} onClick={(e) => onChangeSpeed(e,0.25)} >0.25</button>
                        <button className={`${speed === 0.5 ? 'text-primary-500' : ''}`} onClick={(e) => onChangeSpeed(e,0.5)} >0.5</button>
                        <button className={`${speed === 1 ? 'text-primary-500' : ''}`} onClick={(e) => onChangeSpeed(e,1)} >Normal</button>
                        <button className={`${speed === 0.75 ? 'text-primary-500' : ''}`} onClick={(e) => onChangeSpeed(e,0.75)} >0.75</button>
                        <button className={`${speed === 1.25 ? 'text-primary-500' : ''}`} onClick={(e) => onChangeSpeed(e,1.25)} >1.25</button>
                    </Popover.Panel>
                </Popover>
            </div>
        </div>
    );
}
