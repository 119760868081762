import { FC, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { checkPermission } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import { useBoolean } from 'hooks/custom';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import workspaceService from 'services/workspace.service';
import DeactivateIcon from 'assets/icon/DeactivateIcon';
import { ActiveIcon } from 'assets/icon/ActiveIcon';
import { EyeIcon } from '@heroicons/react/outline';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { USER_STATUS } from 'components/Management/User/constant';

interface ActionMenuProp {
    data: any;
    refreshData: Function
    isDisableAction: boolean
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, isDisableAction }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; groupId: string } = useParams();
    const workspaceId = params?.id;
    const learningGroupId = params?.groupId;
    const history = useHistory();

    const { booleanValue: modalConfirm, toggleBooleanValue: toggleModalConfirm } = useBoolean();
    const { booleanValue: modalNotAlowChangeStatus, toggleBooleanValue: toggleModalNotAlowChangeStatus } = useBoolean();
    const workspaceState = useContext(GetWorkspaceContext);
    const learningGroupName = workspaceState?.getWorkspaceDetailState?.currentRouteDetail?.[0]?.name || '';


    const preCheckAllChangeStatus = async () => {
      const {data: {allow_change_status = false}} = await workspaceService.preCheckBeforeChangeStatusMemberOfLearningGroup(workspaceId, learningGroupId, {
        lg_member_id: data?.id,
        status: data?.lg_member_display_status === 'active' ? 'deactivated' : 'active'
      })
      if (allow_change_status) {
        toggleModalConfirm()
      }
      else {
        toggleModalNotAlowChangeStatus();
      }
    }

    const onChangeStatusMember = async () => {
      workspaceService.changeStatusMemberOfLearningGroup(workspaceId, learningGroupId, {
        lg_member_id: data?.id,
        status: data?.lg_member_display_status === 'active' ? 'deactivated' : 'active'
      })
          .then((res => {
              refreshData()
              Toastify.success()
          }))
          .catch(err => {
            Toastify.error()
          })
          .finally(() => {
              toggleModalConfirm();
          })
    }

    const onViewLearnerTab = () => {
      window.open(`/workspace/${workspaceId}/management/users/${data?.id}?tab=enrollment&groupId=${params?.groupId}&groupName=${learningGroupName}&statuses=active,enrolled`, '_blank')
    }
  
    return (
      <>
        <ActionsButton
            menus={[
                {
                  label: translator('USER.CREDIT_ACCOUNT.VIEW_CREDIT_ACCOUNT'),
                  icon: <EyeIcon className='w-5 h-5' />,
                  action: () => window?.open(`/workspace/${workspaceId}/management/users/${data?.id}?tab=credit-accounts&creditId=${data?.credit_account_id}`, '_blank'),
                  hide: !checkPermission('learningGroupDetailsMembers', 'R'),
                },
                {
                    label: data?.lg_member_display_status === 'active' ? <div className='text-red-500'>{translator('DEACTIVATE')}</div> : translator('ACTIVATE'),
                    icon: data?.lg_member_display_status === 'active' ? <div className='text-red-500'><DeactivateIcon /></div> : <ActiveIcon />,
                    action: preCheckAllChangeStatus,
                    hide: !checkPermission('learningGroupDetailsMembers', 'D') || isDisableAction || data?.membership_status === USER_STATUS.DEACTIVATE,
                },
            ]}
        />
          <DialogComponent
              isOpen={modalNotAlowChangeStatus}
              title={translator('DEACTIVATE')}
              onCloseModal={toggleModalNotAlowChangeStatus}
              maskClosable={false}
              child={
                  <div className='mt-2'>
                      <div className="text-sm">
                        This user is active in one or more enrollments of this learning group. Deactivate them from the enrollments before deactivating their account in this group.
                      </div>
                      <div className="text-sm mt-2">
                        Click <span onClick={onViewLearnerTab} className='cursor-pointer opacity-100 hover:opacity-75 text-primary-500'>here</span> to view the enrollment list
                      </div>
                  </div>
              }
            />

            <DialogComponent
              isOpen={modalConfirm}
              title={ data?.lg_member_display_status === 'active' ? translator('DEACTIVATE') : translator('ACTIVATE')}
              onCloseModal={toggleModalConfirm}
              maskClosable={false}
              child={
                  <div className='mt-2'>
                      <div className="text-ooolab_dark_1 text-sm">
                        Are you sure you want to {data?.lg_member_display_status === 'active'? 'deactivate' : 'activate'} this member?
                      </div>
                      <div className="flex items-center justify-center space-x-4 mt-8">
                          <ButtonOutline
                              type="button"
                              onClick={toggleModalConfirm}
                          >
                            {translator('LEARNING_GROUP.CANCEL')}
                          </ButtonOutline>
                          <ButtonPrimary
                              type="button"
                              onClick={onChangeStatusMember}
                              className={data?.lg_member_display_status === 'active' ? 'buttonPrimary-danger' : ''}
                          >
                            {translator(data?.lg_member_display_status === 'active'? 'DEACTIVATE' : 'ACTIVATE')}
                          </ButtonPrimary>
                      </div>
                  </div>
              }
            />
      </>
    );
};

export default ActionMenu;
