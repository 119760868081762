import { FC, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import DefaultWorkspaceAva from 'assets/SVG/workspace_avatar.svg';
import './WorkspaceSettingsGeneral.scss'
import { ButtonPrimary, Dropdown } from '_shared';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import UpdateAvatarModal from 'components/Workspace/WorkspaceSetting/UpdateAvatarModal';
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { InputField, PhoneInputField } from 'components/InputForm/InputForm';
import { FORM_CONST } from 'constant/form.const';
import { workspaceLanguageOptions } from 'constant/workspace.const';
import { identity, pickBy } from 'lodash';
import ApiKeyIntegration from 'components/WorkspaceSetting/WorkspaceSettingsGeneral/ApiKeyIntegration';

interface WorkspaceSettingsGeneralProps { }

const WorkspaceSettingsGeneral: FC<WorkspaceSettingsGeneralProps> = ({ }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,
        formState: { errors, isValid, isDirty },
        reset,
        setValue,
        watch,
        control
    } = useForm({ mode: 'onChange' });
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`DASHBOARD.WORKSPACE_SETTING.${str}`, opt));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uploadAvatarModal, setUploadAvatarModal] = useState(false);
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;
    const onSubmit = (values) => {
        setIsLoading(true);
        const payload = {
            name: values?.workspaceName,
            address: values?.address,
            email: values?.emailAddress,
            phone: values?.phone,
        }  
        workspaceMiddleware.updateDetailWorkspace(dispatch, params.id, pickBy(payload, identity));
        setIsLoading(false);
    }
    const localInfor = getLocalStorageAuthData();

    useEffect(() => {
        const language = workspaceLanguageOptions.find(item => item.value == (workspaceDetailInformation.default_language || 'en'));
        const defaultValues = {
            ...workspaceDetailInformation,
            workspaceName: workspaceDetailInformation?.name,
            address: workspaceDetailInformation?.address,
            emailAddress: workspaceDetailInformation?.email,
            phone: workspaceDetailInformation?.phone,
            default_language: {
                ...language,
                name: t(language.name),
            },
        }
        reset(defaultValues);
    }, [workspaceDetailInformation])

    const onChangeLanguage = async () => {
        setIsLoading(true);
        const payload = {
            default_language: watch('default_language')?.value || 'en',
        }  
        await workspaceMiddleware.updateDetailWorkspace(dispatch, params.id, payload);
        setIsLoading(false);
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="general">
                <div className='bg-white p-6 rounded-2xl'>
                    <div className='general-header'>
                        <h1>{translator('GENERAL')}</h1>
                        <ButtonPrimary disabled={!isValid || !isDirty || isLoading} type="submit" className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`} >{t('SAVE')}</ButtonPrimary>
                    </div>
                    <div className="workspacePhoto">
                        <p className="workspacePhoto-title">
                            {translator('WORKSPACE_PHOTO')}
                        </p>
                        <div className="workspacePhoto-upload">
                            <img
                                src={workspaceDetailInformation.avatar_url || DefaultWorkspaceAva}
                                alt={'_avatar'}
                                className={'block object-cover rounded-ooolab_circle h-20 w-20'}
                            />
                            <div className="flex flex-col ml-6 items-start">
                                <button
                                    className="rounded bg-primary-500 px-3 py-1 text-sm leading-6 text-white font-medium focus:outline-none"
                                    onClick={() => setUploadAvatarModal(true)}
                                >
                                    {translator('CHANGE_PHOTO')}
                                </button>
                                <label className={'text-gray-500 text-sm leading-6 mt-2'}>
                                    {translator('SUB_TITLE_CHANGE_PHOTO')}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='general-form'>
                        <InputField
                            name="workspaceName"
                            maxLength={255}
                            label={translator('WORKSPACE')}
                            errors={errors}
                            className="col-span-1 !w-full"
                            isRequired
                            placeholder={translator('ENTER_WORKSPACE')}
                            register={register('workspaceName', {
                                required: t('FORM_CONST.REQUIRED_FIELD'),
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                        />
                        <InputField
                            name="address"
                            maxLength={255}
                            label={translator('ADDRESS')}
                            errors={errors}
                            className="col-span-1 !w-full"
                            placeholder={translator('ENTER_ADDRESS')}
                            register={register('address', {
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                        />
                        <InputField
                            name="emailAddress"
                            maxLength={255}
                            label={translator('EMAIL_ADDRESS')}
                            errors={errors}
                            className="col-span-1 !w-full"
                            placeholder={translator('ENTER_EMAIL_ADDRESS')}
                            register={register('emailAddress', {
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                                pattern: {
                                    value: FORM_CONST.EMAIL_REGEX,
                                    message: FORM_CONST.EMAIL_VALIDATE,
                                },
                            })}
                        />
                        <PhoneInputField
                            name="phone"
                            control={control}
                            label={translator('PHONE')}
                            placeholder={translator('ENTER_PHONE')}
                            errors={errors}
                            rules={{
                                pattern: {
                                    value: FORM_CONST.PHONE_NUMBER,
                                    message: t('FORM_CONST.PHONE_NUMBER'),
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='mt-8 bg-white p-6 rounded-2xl'>
                    <div className='flex justify-between items-center'>
                        <h1 className='text-xl font-semibold'>{translator('LEARNER_S_PORTAL_DEFAULT_LANGUAGE')}</h1>
                        <ButtonPrimary
                            type="button"
                            disabled={workspaceDetailInformation?.default_language == (watch('default_language')?.value || 'en')}
                            className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`}
                            onClick={onChangeLanguage}
                        >
                            {t('SAVE')}
                        </ButtonPrimary>
                    </div>
                    <div className='border-t border-gray-300 my-4' />
                    <div className='grid grid-cols-2 gap-5'>
                        <div className='col-span-1'>
                            <label className="mb-1 text-sm font-semibold block">
                                {translator('LANGUAGE')}
                            </label>
                            <Dropdown
                                items={workspaceLanguageOptions.map(item => ({ ...item, name: t(item.name)}))}
                                onChange={(value: any) => setValue('default_language', value)}
                                placeholder={''}
                                className="w-full dropdown-language"
                                selectedValue={watch('default_language')}
                            />
                        </div>
                    </div>
                </div>
                <ApiKeyIntegration />
            </form>
            {(uploadAvatarModal && (
                <UpdateAvatarModal
                    access_token={localInfor.access_token}
                    titleText={translator('UPLOAD_WORKSPACE_AVATAR')}
                    avatar_url={workspaceDetailInformation.avatar_url}
                    onCancel={() => setUploadAvatarModal(false)}
                    onUpdatedAvatar={(e) => {
                        const payload = {
                            avatar: e,
                        }
                        workspaceMiddleware.updateDetailWorkspace(dispatch, params.id, payload);
                        setUploadAvatarModal(false);
                    }}
                />
            )) ||
                null}
        </>
    );
};

export default WorkspaceSettingsGeneral;
