import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import skillService from 'services/skill.service';
import DialogComponent from 'components/Dialog/DialogComponent';

export function ModalDeleteSkill({ isOpen, onClose, data = null, setLoading }) {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: { id: string; skillId: string } = useParams();

    const workspaceId = params?.id;
    const skillId = params?.skillId;

    const onDeleteSkill = () => {
        setLoading(true);
        skillService
            .deleteSkillByIdService(workspaceId, skillId)
            .then((res) => {
                Toastify.success();
                history.goBack();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    return (
        <DialogComponent
            title={translator('Delete Skill')}
            isOpen={isOpen}
            onCloseModal={onClose}
            child={
                <div className="flex flex-col">
                    <div className="mt-2 text-sm text-gray-800">{translator('Are you sure you want to delete this skill?')}</div>
                    <div className="flex justify-center space-x-4 mt-6">
                        <ButtonOutline type="button" className='!px-7' onClick={onClose}>
                            {translator('NO')}
                        </ButtonOutline>
                        <ButtonPrimary
                            type="button"
                            className="buttonPrimary-danger"
                            onClick={onDeleteSkill}
                        >
                            {translator('DELETE')}
                        </ButtonPrimary>
                    </div>
                </div>
            }
        />
    );
}
