import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import '_shared/components/Calendar/Calendar.scss';
import { SettingsIcon } from 'assets/icon/SettingIcon';
import TeachService from 'services/teach.services';
import { useAppSelector } from 'hooks/hooks';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import ModalSessionDetail from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/ModalSessionDetail';
import AloneSession from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/AloneSession';
import MultipleSession from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/MultipleSession';

export const SessionServiceFormat = 'YYYY-MM-DDTHH:mm:ss';

export default function Calendar() {
    const { expandedMenu } = useAppSelector(state => state.configPage);
    const [calendarApi, setCalendarApi] = useState<any>();
    const {i18n:{language}} = useTranslation();

    const [eventsCalendar, setEventsCalendar] = useState<any[]>();
    const {
        userState: { result },
    } = useContext(UserContext);
    const calendarRef: any = useRef<FullCalendar>(null!);
    const [paramsCalendar, setParamsCalendar] = useState<any>({
        skip_pagination: true,
    });
    const [viewType, setViewType] = useState('');
    const [loading, setLoading] = useState(false);
    const params = useParams<{ id: string; courseId: string }>();
    const workspaceId = params?.id;

    const [sessionDetail, setSesstionDetail] = useState<any>(undefined);
    const [isOpenModalViewSessionDetail, setOpenModalViewSessionDetail] = useState(false);

    useEffect(() => {
        if (calendarApi) {
            calendarApi?.changeView(viewType);
        }
    }, [expandedMenu]);

    useEffect(() => {
        if (calendarRef) {
            setCalendarApi(calendarRef.current.getApi());
        }
    }, [calendarRef]);

    const getSessions = async (params?: any) => {
        setLoading(true);
        const paramsRequest = {
            ...params,
        };
        const res = await TeachService.getIntructorSessions(workspaceId, paramsRequest);
        setLoading(false);
        return res?.data;
    };

    const fetchEvents = async (params) => {
        const resCalendar: any = await getSessions(params);
        setEventsCalendar(
            resCalendar?.items?.map((item) => {
                const parseStartDate = dayjs(item?.start_time).tz(result.time_zone);
                const parseEndDate = dayjs(item?.end_time).tz(result.time_zone);
                return {
                    ...item,
                    start: parseStartDate.format(SessionServiceFormat),
                    end: parseEndDate.format(SessionServiceFormat),
                };
            }) || []
        );
    };

    useEffect(() => {
        if (paramsCalendar?.end_before && paramsCalendar?.start_after) fetchEvents({ ...paramsCalendar });
    }, [paramsCalendar]);

    return (
        <div>
            <div className="Calendar relative px-5">
                {viewType !== 'dayGridMonth' && (
                    <div className="absolute w-[96px] text-center top-[87px] left-[7px] text-xs">
                        <button
                            type="button"
                            className="text-primary-500 hover:opacity-80 block text-center m-auto"
                            onClick={() => window.open(`/workspace/${workspaceId}/user-setting`, '_blank')}
                        >
                            <SettingsIcon />
                        </button>
                        <span className="text-[10px]">UTC{dayjs().tz(result?.time_zone).format('Z')}</span>
                    </div>
                )}
                {
                    /*@ts-ignore*/
                    <FullCalendar
                        ref={calendarRef}
                        allDaySlot={false}
                        /*@ts-ignore*/
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{
                            left: 'today prev,next title',
                            right: 'timeGridDay,timeGridWeek,dayGridMonth',
                        }}
                        eventClick={(data) => {
                            setSesstionDetail([data?.event]);
                            setOpenModalViewSessionDetail(true);
                        }}
                        events={eventsCalendar}
                        eventContent={(eventInfo) => {
                            return (
                                <AloneSession
                                    id={eventInfo?.event?.id}
                                    startTime={eventInfo?.event?.extendedProps?.start_time}
                                    endTime={eventInfo?.event?.extendedProps?.end_time}
                                />
                            );
                        }}
                        locale={language}
                        dayMaxEvents={1}
                        forceEventDuration={true}
                        moreLinkContent={(content: any) => {
                            if (content?.num === 0) return;
                            return <MultipleSession numberOfSession={content?.num}/>;
                        }}
                        slotLabelFormat={{
                            hour: '2-digit',
                            minute: '2-digit',
                        }}
                        dayHeaderFormat={{ weekday: 'short' }}
                        dayHeaderContent={(args) => viewType === 'timeGridDay' ?  dayjs(args.date).locale(language).format('ddd DD') : dayjs(args.date).locale(language).format('ddd')}
                        moreLinkClick={(value: any) => {
                            setSesstionDetail(value?.allSegs?.map((item) => ({...item?.event?.extendedProps, id: item?.event?.id})));
                            setOpenModalViewSessionDetail(true);
                        }}
                        timeZoneParam={result?.time_zone}
                        timeZone={result?.time_zone}
                        datesSet={(arg) => {
                            const { startStr, endStr, view } = arg;
                            setParamsCalendar({
                                start_after: dayjs(startStr).format(),
                                end_before: dayjs(endStr).format(),
                                skip_pagination: true,
                            });
                            setViewType(view?.type);
                        }}
                    />
                }
            </div>

            {isOpenModalViewSessionDetail && (
                <ModalSessionDetail
                    sessions={sessionDetail}
                    isOpen={isOpenModalViewSessionDetail}
                    refreshData={() => {
                        setOpenModalViewSessionDetail(false);
                        fetchEvents(paramsCalendar);
                    }}
                    onClose={() => {
                        setOpenModalViewSessionDetail(false);
                        setSesstionDetail(undefined);
                    }}
                />
            )}
        </div>
    );
}
