import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { ButtonIcon } from '_shared';
import { DotsVerticalIcon, PencilAltIcon} from '@heroicons/react/outline';
import DialogComponent from "components/Dialog/DialogComponent";
import H5PPreview from 'components/H5PPreview';
import "./UnitActions.scss";
import { PresentationIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import { checkPermission } from 'utils/commonFun';
import { useParams } from 'react-router-dom';

export const UnitActions = ({ values }) => {
    const params: { id: string; courseId: string } = useParams();

    const [hasPreviewLesson, setHasPreviewLesson] = useState(false);
    const {t} = useTranslation();
    return (
        <>
            <div className="unitActions">
                <Popover>
                    <Popover.Button className="focus:outline-0" onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation();
                    }}>
                        <ButtonIcon onClick={(event: React.MouseEvent<HTMLElement>) => {
                            //event.stopPropagation();
                        }}>
                            <DotsVerticalIcon className="w-5 h-5" />
                        </ButtonIcon>
                    </Popover.Button>
                    <Popover.Panel className="unitActions-panel">
                        <button 
                            className="lessonActions-item disabled:cursor-not-allowed" 
                            disabled={!checkPermission('libraryContentLessonsOrScorm', 'U')} 
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.preventDefault();
                                window.open(`/workspace/${params?.id}/lesson/${values?.library_content?.object_id}`, '_blank');
                            }}
                        >
                            <PencilAltIcon className="w-5 mr-2" />
                            {t('EDIT')}
                        </button>
                        <button className="unitActions-item" onClick={(event: React.MouseEvent<HTMLElement>) => {
                            event.preventDefault();
                            setHasPreviewLesson(true);
                        }}>
                            <span className="w-5 mr-1">
                                <PresentationIcon />
                            </span>
                            {t("COURSES.PRESENTATION_MODE")}
                        </button>
                    </Popover.Panel>
                </Popover>
            </div>
            {hasPreviewLesson && <DialogComponent
                isOpen={true}
                onCloseModal={() => setHasPreviewLesson(false)}
                hasNonTitle
                styles="max-w-[1200px] w-full min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                isShowClose={false}
                child={
                    <H5PPreview values={{
                        ...values.library_content
                    }}
                        onClose={() => setHasPreviewLesson(false)}
                    />
                }
            />}
        </>
    )
}