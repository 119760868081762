import React, { useState, useEffect } from "react";

export const AspectRatioBox = ({ children, heightPadding = 110, widthPadding = 0 }: { widthPadding?: number, heightPadding?: number, children: React.ReactNode }) => {
  const [height, setHeight] = useState(window.innerHeight - heightPadding);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const calculateDimensions = () => {
      // Update height and calculate width using 16:9 aspect ratio
      const screenHeight = window.innerHeight - heightPadding;
      let newWidth = screenHeight * (16 / 9);
      const $elementBox = document.getElementsByClassName('h5p-container-view') as any;
      const $content = document.getElementsByClassName('file-container1') as any;

      if ($elementBox?.length > 0 || $content?.length > 0) {
        const elemtWidth = $elementBox[0]?.offsetWidth;
        const contentWidth = $content[0]?.offsetWidth;
        if (newWidth > elemtWidth) {
          newWidth = elemtWidth;
        }
        if (newWidth < contentWidth) {
          newWidth = contentWidth + widthPadding;
        }
      }
      setHeight(screenHeight);
      setWidth(newWidth - widthPadding);
    };

    // Add event listener to handle window resize
    window.addEventListener("resize", calculateDimensions);

    // Initial calculation
    calculateDimensions();

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", calculateDimensions);
    };
  }, []);

  return (
    <div
      style={{
        width: `${width}px`,
        height: `${height}px`,
        overflowY: 'auto',
        minWidth: '460px',
        minHeight: '320px'
      }}
      className="flex items-center flex-wrap mx-auto p-4 justify-center"
    >
      {children}
    </div>
  );
};