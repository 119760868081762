import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { checkPermission } from 'utils/commonFun';
import { INVOICE_STATUS, SINGLE_INVOICE_STATUS_ENUM } from '../../constants';

export default function ModalAddDesciption({ data, onClose, refreshData = null, type = 'note' }) {
  const params: { id } = useParams();
  const [desciption, setDescription] = useState('');
  const { t: translator } = useTranslation()

  useEffect(() => {
    if (data?.note) setDescription(data?.note);
  }, [data?.note]);

  const handleAddNotes = () => {
    const payload = {
      note: desciption || null, //description
    }
    workspaceService.addNoteToInvoice(params?.id, data?.id, payload)
      .then(() => {
        if (data?.note) {
          Toastify.success(translator('EDIT_DESCRIPTION_SUCCESS'));
        }
        else {
          Toastify.success(translator('ADD_DESCRIPTION_SUCCESS'));
        }
        refreshData?.();
        onClose();
      })
      .catch(() => Toastify.error());
  }

  return (
    <div className='w-full mt-4'>
      <div className="text-sm font-semibold">{translator("DESCRIPTION")}</div>
      <div className="relative">
        <textarea
          className="mt-1 h-32 border border-gray-300 !rounded-sm w-full p-2 text-sm focus:outline-none"
          placeholder={translator("ENTER_DESCRIPTION")}
          value={desciption}
          onChange={(e) => setDescription(e.target.value)}
          maxLength={100}
          disabled={!checkPermission('invoice', 'U')}
        />
        <div className="absolute bottom-2 right-2 text-xs text-gray-500 bg-white rounded-lg p-1">({desciption?.length || 0}/100)</div>
      </div>
      <div className='w-full mt-5 flex justify-center gap-5'>
        <ButtonOutline type="button" onClick={onClose} >{translator('CANCEL')}</ButtonOutline>
        <ButtonPrimary disabled={!desciption || !checkPermission('invoice', 'U') || data?.status !== SINGLE_INVOICE_STATUS_ENUM.scheduled} type="button" onClick={handleAddNotes}>{!data?.note ? translator('ADD') : translator('SAVE')}</ButtonPrimary>
      </div>
    </div>
  );
}
