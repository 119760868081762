import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import ActionsButton from 'components/ActionsButton';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';
import { DownloadIcon } from 'assets/icon';
import { checkPermission, dowloadFile, getFileExtensionFromUrl } from 'utils/commonFun';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import ModalNewCertificate from 'components/Workspace/Course/CourseBuilder/components/CourseCertificate/Components/ModalNewCertificate';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';

interface ActionMenuProp {
    refreshData: any;
    data: any;
    position?: string;
    userData?: any;
}

const ActionMenu: FC<ActionMenuProp> = ({ refreshData, data, position, userData }) => {
    const { t: translator } = useTranslation();

    const [isOpenModalEditCertificate, setOpenModalEditCetificate] = useState(false);
    const [isOpenModalDeleteCertificate, setOpenModalDeleteCertificate] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [isOpenViewFile, setOpenViewFile] = useState(false)
    const params: { id: string, userId:string } = useParams();

    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        dowloadFile(data?.file, data?.name, () => {
            setDownloading(false);
        });
    }
    const onDelete = () => {
        courseService.deleteCertificateCourse(params?.id, data?.course?.id, params?.userId)
            .then(() => {
                Toastify.success()
                refreshData();
                setOpenModalDeleteCertificate(false);
            })
            .catch(() => {
                Toastify.error()
            })
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? 'bottom-0' : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('VIEW'),
                        icon: <EyeIcon className='w-4 h-4' />,
                        action: () => setOpenViewFile(true),
                        hide: !checkPermission('course', 'R') && !checkPermission('user', 'R'),
                    },
                    {
                        label: translator('EDIT'),
                        icon: <EditIcon />,
                        isDisabled: data?.status === 'released',
                        action: () => setOpenModalEditCetificate(true),
                        class: '',
                        hide: (!checkPermission('course', 'R') || !checkPermission('course', 'U') || !checkPermission('user', 'U')),
                    },
                    {
                        label: translator('DOWNLOAD'),
                        icon: <DownloadIcon />,
                        isDisabled: false,
                        action: download,
                        class: '',
                        hide: !checkPermission('course', 'R') && !checkPermission('user', 'R'),
                    },
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon width="18" height="16" />,
                        action: () => setOpenModalDeleteCertificate(true),
                        class: 'border-t border-gray-300',
                        hide: !checkPermission('course', 'R') || !checkPermission('course', 'U') || !checkPermission('user', 'U'),
                    },
                ]}
            />
            {isOpenModalEditCertificate &&
                <ModalNewCertificate
                    isOpen={isOpenModalEditCertificate}
                    onClose={() => setOpenModalEditCetificate(false)}
                    refreshData={refreshData}
                    certiData={{
                        learner: {
                            id: userData?.id,
                            display_name: userData?.display_name,
                            avatar_url: userData?.avatar_url,
                        },
                        ...data
                    }}
                    isLearner
                />
            }
            {isOpenModalDeleteCertificate &&
                <DialogComponent
                    isOpen={isOpenModalDeleteCertificate}
                    onCloseModal={() => setOpenModalDeleteCertificate(false)}
                    title={translator('COURSES.DELETE_CERTIFICATE')}
                    child={
                        <div className='mt-4'>
                            <div>{translator('COURSES.DELETE_CERTIFICATE_CONTENT')}</div>
                            <div className='mt-5 flex justify-center gap-5'>
                                <ButtonOutline type="button" onClick={() => setOpenModalDeleteCertificate(false)}>{translator('CANCEL')}</ButtonOutline>
                                <ButtonPrimary type="button" className='!border-red-500 !bg-red-500' onClick={onDelete}>{translator('DELETE')}</ButtonPrimary>
                            </div>
                        </div>
                    }
                />

            }
            {isOpenViewFile &&
                <DocumentPreview
                    closeModal={() => setOpenViewFile(false)}
                    isOpen={isOpenViewFile}
                    data={{
                        type: getFileExtensionFromUrl(data?.file) === 'pdf' ? 'pdf' : 'image',
                        name: data?.name,
                        url: data?.file

                    }}
                />

            }
        </>
    );
};

export default ActionMenu;
