import React, { useEffect, useState } from "react";
import { CloudCheckIcon, DesktopIcon, MobileIcon } from "assets/icon";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ButtonBack, ButtonOutline, ButtonPrimary, ConfirmModal } from "_shared";
import { PresentationIcon } from "assets/icon/PresentationIcon";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { LIBRARY_TAB } from "components/Library/LibraryContants";
import { LESSON_STATUS, LESSON_STATUS_CODE, VIEW_TYPE } from "../../constants";

import "./LessonBuilderHeader.scss";
import libraryService from "services/library.service";
import { checkPermission } from "utils/commonFun";

export const LessonBuilderHeader = ({ details, setViewType, viewType, isSaving = false, onPublic, folderId, setMissingInformation, isMissingInformation, isNew }) => {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const history = useHistory();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openWarning, setOpenWarning] = useState(false);
    const [openWarningCreateEmpty, setOpenModalWarningCreateEmpty] = useState(false)

    const [saved, setSaved] = useState(false);
    const onClose = (value) => {
        if (value) {
            onPublic();
        }
        setOpenConfirm(false);
    }
    const onPresentation = () => {
        history.push(`/workspace/${params?.id}/lesson/${params?.lessonId}/preview`);
    }
    useEffect(() => {
        if (isSaving) {
            setSaved(isSaving);
        }
    }, [isSaving]);

    const onCloseModalWarning = () => {
        setOpenWarning(false)
        setMissingInformation(true)
    }

    const onPublicLesson = () => {
        if (isMissingInformation && details?.status == LESSON_STATUS_CODE.draft) {
            setOpenWarning(true);
        }
        else setOpenConfirm(true);
    }

    const onBack = () => {
        let url = ''
        if (isMissingInformation && !details?.lesson_goal && !details?.tags?.length && details?.status == LESSON_STATUS_CODE.draft && isNew) return setOpenModalWarningCreateEmpty(true)
        if (folderId) url = `/workspace/${params?.id}/library/${folderId}?tab=${LIBRARY_TAB[0].value}`
        else url = `/workspace/${params?.id}/library?tab=${LIBRARY_TAB[0].value}`
        history.push(url)
    }

    const onCloseModalWarningCreateEmpty = (value) => {
        let url = '';
        setOpenModalWarningCreateEmpty(false);
        if (folderId) url = `/workspace/${params?.id}/library/${folderId}?tab=${LIBRARY_TAB[0].value}`;
        else url = `/workspace/${params?.id}/library?tab=${LIBRARY_TAB[0].value}`;
        if (!value) {
            libraryService.moveToTrashLesson(params?.id, params?.lessonId)
                .then((res) => {
                    libraryService.deleteForeverLesson(params?.id, params?.lessonId);
                })
        }
        history.push(url);
    }

    return (<>
        <div className="lessonBuilderHeader">
            <div className="flex-none w-[349px]">
                <ButtonBack
                    onClick={onBack}
                />
            </div>
            <div className="lessonBuilderStatus">
                <span className={`lessonBuilderStatus-label ${details?.status == LESSON_STATUS_CODE.published ? 'lessonBuilderStatus-label--active' : ''}`}>
                    {translator(LESSON_STATUS[details?.status])}
                </span>
                <span className="lessonBuilderStatus-text">{details?.title}</span>
            </div>
            <div className="lessonBuilderHeader-action">
                <span className="text-gray-500">
                    {isSaving ? <CircleSpin className="text-primary-500" /> : <span className={`${saved ? 'text-green-500' : ''}`}><CloudCheckIcon /></span>}
                </span>
                <div className="lessonBuilderHeader-line"></div>
                <button className={`lessonBuilderHeader-btn ${viewType === VIEW_TYPE.WEB ? 'lessonBuilderHeader-btn--active' : ''} mr-1`} onClick={() => setViewType(VIEW_TYPE.WEB)}>
                    <DesktopIcon />
                </button>
                <button className={`lessonBuilderHeader-btn ${viewType === VIEW_TYPE.MOBILE ? 'lessonBuilderHeader-btn--active' : ''}`} onClick={() => setViewType(VIEW_TYPE.MOBILE)}>
                    <MobileIcon />
                </button>
                <div className="lessonBuilderHeader-line"></div>
                {checkPermission('libraryContentLessonsOrScorm', 'R') ? <ButtonOutline type="button" onClick={onPresentation} className="buttonOutline--isPrimary">
                    <PresentationIcon />
                    <span>{translator('PRESENT')}</span>
                </ButtonOutline> : ''}

                {checkPermission('libraryContentLessonsOrScorm', 'PL') || checkPermission('libraryContentLessonsOrScorm', 'RD') ? <ButtonPrimary type="button" className="whitespace-nowrap" onClick={onPublicLesson}
                    disabled={isSaving}>
                    {details?.status == LESSON_STATUS_CODE.draft ? translator('PUBLISH') : translator('Revert to Draft')}
                </ButtonPrimary> : ''}

            </div>
        </div>
        <ConfirmModal
            title={`${details?.status == LESSON_STATUS_CODE.draft ? translator("LIBRARY.PUBLISH_LESSON") : translator("LIBRARY.CONFIRM_UNPUBLISH")}`}
            isOpen={openConfirm}
            onClose={onClose}
            textConfirm={`${details?.status == LESSON_STATUS_CODE.draft ? translator('PUBLISH') : translator("UNPUBLISH")}`}
            textCancel={`${details?.status == LESSON_STATUS_CODE.draft ? translator('CANCEL') : translator("DISSCARD_CHANGES")}`}
        >
            <p className="text-sm">
                {details?.status == LESSON_STATUS_CODE.draft ?
                    translator('LIBRARY.MESSAGE_PUBLISH_LESSON') :
                    translator('LIBRARY.MESSAGE_REVERT_LESSON')
                }</p>
        </ConfirmModal>
        <ConfirmModal
            title={translator("INFORMATION")}
            isOpen={openWarning}
            onClose={onCloseModalWarning}
            textConfirm={`Ok`}
            isHideBtnSecondary={true}
        >
            <p className="text-sm">
                {translator('LIBRARY.WARNING_PUBLISH_LESSON')}
            </p>
        </ConfirmModal>
        <ConfirmModal
            title={translator("INFORMATION")}
            isOpen={openWarningCreateEmpty}
            onClose={onCloseModalWarningCreateEmpty}
            onCloseX={() => {
                setOpenModalWarningCreateEmpty(false);
            }}
            textConfirm={translator('SAVE')}
            textCancel={translator('DISCARD')}

        >
            <p className="text-sm">
                {translator('LIBRARY.WARNING_CREATE_EMPTY_LESSON')}
            </p>
        </ConfirmModal>
    </>
    )
}