import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { checkPermission } from 'utils/commonFun';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import workspaceService from 'services/workspace.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import { TrashIcon } from '@heroicons/react/outline';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalActive, setOpenModalChangeStatus] = useState(false);
    const params: { id: string} = useParams();

    const onDeleteLearningGroup = () => {
      workspaceService.deleteLearningGroupService(params?.id, data?.id)
          .then((res) => {
            Toastify.success()
            refreshData()
          })
          .catch(() => {
              Toastify.error()
          })
          .finally(() => setOpenModalChangeStatus(false))
    }

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position: 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon className='w-4 h-4' />,
                        action: () => setOpenModalChangeStatus(true),
                        class: '',
                        hide: !checkPermission('learningGroup', 'D'),
                        isDisabled: data?.has_credit_account_txn,
                        contentTip: 'This learning group cannot be deleted since there are credit transactions linked to it'
                    },
                ]}
            />

            <DialogComponent
                isOpen={isOpenModalActive}
                title={translator('DELETE')}
                onCloseModal={() => setOpenModalChangeStatus(false)}
                maskClosable={false}
                stylesTitle="text-base"
                styles="max-w-md"
                child={
                    <div className='mt-4'>
                        <div className="text-gray-800 text-sm text-left ">
                          {translator('Are you sure you want to delete this learning group?')} 
                        </div>
                        <div className="flex items-center justify-center space-x-4 mt-8">
                            <ButtonOutline
                                onClick={() => setOpenModalChangeStatus(false)}
                                type="button"
                            >
                              {translator('CANCEL')}
                            </ButtonOutline>

                            <ButtonPrimary
                                onClick={onDeleteLearningGroup}
                                type="button"
                                className={'buttonPrimary-danger'}
                            >
                                {translator('DELETE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
              />
        </>
    );
};

export default ActionMenu;
