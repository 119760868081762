
import DialogComponent from "components/Dialog/DialogComponent";
import { PlusIcon, XIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { SelectField } from "components/Select";
import { ButtonOutline, ButtonPrimary, Toastify } from "_shared";
import skillService from "services/skill.service";
import { useParams } from "react-router-dom";
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import "./index.scss";

export const AddSkillsModal = ({ onRefresh, isTemplate = false }: { onRefresh: () => void, isTemplate }) => {
    const params: { id: string, courseId: string } = useParams();
    const { t: translate } = useTranslation();
    const [loading, setLoading] = useState(false);
    const {
        control,
        handleSubmit,
        formState: { errors, isValid },
        watch,
        reset,
        setError,
        clearErrors,
        setValue,
        trigger
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            skills: [{ skill: null, level: null }],
        }
    })
    const [hasOpenAdd, setHasOpenAdd] = useState(false);
    const [skillsList, setSkillsList] = useState([]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "skills",
    });

    const watchSkills = watch("skills");

    const fetchSkill = async (payload?: any) => {
        try {
            const res = await skillService.getSkillsService(params?.id, payload);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            Toastify.error();
        }
    };

    const getListSkills = async () => {
        let payload: any = {
            page: 1,
            per_page: 20,
            order: 'asc',
            status: 'published',
            skip_pagination: true,
            sort_by: 'name'
        };
        if (!payload?.q) delete payload?.q
        try {
            const { items } = await fetchSkill(payload);
            const newOptions = items?.map((el: any) => {
                return {
                    id: el?.id,
                    label: el?.name,
                    value: el?.id,
                    levels: el?.levels
                };
            });
            setSkillsList(newOptions);
        } catch (error) {
            Toastify.error();
        }
    };

    const handleMapLevel = (data, leveChange: string) => {
        const levelData = data?.levels?.map(item => ({ id: item?.id, value: item.id, label: item?.name }));
        levelData?.sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }
            return -1;
        });
        return levelData;
    }

    const onSubmit = async (values) => {
        try {
            setLoading(true);
            const payload = {
                skill_level_ids: values?.skills?.map(item => item.level?.id)
            }
            if (params?.id && params?.courseId) {
                const create = isTemplate ? skillService.createCourseTemplateSkills(params.id, params.courseId, payload) : skillService.createCourseSkills(params.id, params.courseId, payload);
                await create;
                setHasOpenAdd(false);
                onRefresh?.();
            }
        } catch (error) {
            Toastify.error(error?.response?.data?.error?.body_params?.[0]?.msg);
        } finally {
            setLoading(false);
        }
    }

    const skills = watch('skills');
    const checkDuplicate = () => {
        clearErrors();
        if (skills?.length > 1) {
            const duplicateIndex = skills?.map((item, index) => {
                if (!item?.skill?.id || !item?.level?.id) return -1;
                return skills.findIndex((skill, i) => {
                    return (
                        skill?.skill?.id === item?.skill?.id && skill?.level?.id === item?.level?.id && i !== index
                    );
                });
            });
            for (let i = 0; i < duplicateIndex.length; i++) {
                if (duplicateIndex[i] !== -1) {
                    setError(`skills.${i}.skill`, { type: 'manual', message: translate('DUPLICATE_SKILL') });
                } else {
                    clearErrors(`skills.${i}.skill`);
                }
            }
        }
    };

    useEffect(() => {
        if (!hasOpenAdd) {
            reset({ skills: [{ skill: null, level: null }] });
        }
    }, [hasOpenAdd]);

    useEffect(() => {
        getListSkills();
    }, []);

    return <>
        <button type="button" className="addSkillsModal-button"
            onClick={() => setHasOpenAdd(true)}
        >
            <PlusIcon className="w-5 h-5 mr-1" />
            {translate('ADD_SKILLS')}
        </button>
        <DialogComponent
            title={t('ADD_SKILLS')}
            isOpen={hasOpenAdd}
            onCloseModal={() => setHasOpenAdd(false)}
            isShowClose={true}
            styles="max-w-[900px] !py-1.5 !px-6"
            child={<div className='mb-4'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <p className="text-sm mb-2 text-gray-800">{translate('YOU_CAN_SELECT_ONE_OR_MORE_SKILL_HERE')}:</p>
                    <div className="flex gap-2 items-center mb-2">
                        <div className="flex-none w-4"></div>
                        <div className="w-1/2 text-sm text-gray-800">{translate('SKILL')}</div>
                        <div className="w-1/2 text-sm text-gray-800">{translate('LEVEL')}</div>
                        <div className="w-6"></div>
                    </div>
                    <div className="min-h-[380px] overflow-y-auto">
                        {fields.map((field, index) => (
                            <div key={field.id} className="flex gap-2 mb-2">
                                <div className="flex-none w-4 mt-1">{index + 1}.</div>
                                <div className="w-1/2">
                                    <SelectField
                                        control={control}
                                        label={''}
                                        name={`skills.${index}.skill`}
                                        placeholder={t('SELECT_A_SKILL')}
                                        isSearchable={false}
                                        isMulti={false}
                                        options={skillsList}
                                        customStyles={{
                                            control: (base) => {
                                                return { ...base, minHeight: '0px', height: 'auto' };
                                            },
                                            valueContainer: (base) => {
                                                return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 };
                                            },
                                            indicatorsContainer: (base) => {
                                                return { ...base, padding: '0px 8px', height: '30px' };
                                            },
                                        }}
                                        errors={errors}
                                        className={`w-full ${errors.skills?.[index]?.skill || errors.skills?.[index]?.level ? 'border-error' : ''}`}
                                        rules={{
                                            required: "This field is required",
                                            validate: (value) => {
                                                const allPairs = watchSkills.map((item) => `${item.skill?.id}-${item.level?.id}`);
                                                const currentPair = `${value?.id}-${watchSkills[index]?.level?.id}`;
                                                const isDuplicate = allPairs.filter((pair) => pair === currentPair).length > 1;
                                                checkDuplicate();
                                                return isDuplicate ? 'Skill & Level must be unique' : true;
                                            },
                                        }}
                                        onChange={() => {
                                            setValue(`skills.${index}.level`, null)
                                        }}
                                    />
                                </div>
                                <SelectField
                                    control={control}
                                    isRequired
                                    label={''}
                                    name={`skills.${index}.level`}
                                    placeholder={t('SELECT_A_LEVEL')}
                                    isSearchable={false}
                                    isMulti={false}
                                    options={handleMapLevel(watch(`skills.${index}.skill`), `skills.${index}.level`)}
                                    customStyles={{
                                        control: (base) => {
                                            return { ...base, minHeight: '0px', height: 'auto' };
                                        },
                                        valueContainer: (base) => {
                                            return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 };
                                        },
                                        indicatorsContainer: (base) => {
                                            return { ...base, padding: '0px 8px', height: '30px' };
                                        },
                                    }}
                                    errors={errors}
                                    className={`w-1/2 ${errors.skills?.[index]?.level || errors.skills?.[index]?.skill ? 'border-error' : ''}`}
                                    rules={{
                                        required: "This field is required",
                                        validate: (value) => {
                                            const allPairs = watchSkills.map((item) => `${item.skill?.id}-${item.level?.id}`);
                                            const currentPair = `${watchSkills[index]?.skill?.id}-${value?.id}`;
                                            const isDuplicate = allPairs.filter((pair) => pair === currentPair).length > 1;
                                            checkDuplicate();
                                            return isDuplicate ? false : true;
                                        },
                                    }}
                                    isDisabled={!watch(`skills.${index}.skill`)?.id}


                                />
                                <button type="button"
                                    className="flex items-center justify-center flex-none text-red-500 hover:bg-gray-300 rounded-full w-8 h-8 transition"
                                    onClick={() => {
                                        remove(index);
                                        checkDuplicate();
                                    }}
                                >
                                    <XIcon className="w-5 h-5" />
                                </button>
                            </div>))}
                        <div className="flex gap-2 mt-2 items-center">
                            <div className="flex-none w-4 text-gray-400">{fields?.length + 1}.</div>
                            <button type="button" className="addSkillsModal-button"
                                onClick={() => append({ skill: null, level: null })}
                            >
                                <PlusIcon className="w-5 h-5 mr-1" />
                                {translate('ADD_A_SKILL')}
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-center gap-4">
                        <ButtonOutline size="M" type="button" onClick={() => setHasOpenAdd(false)}>{translate('CANCEL')}</ButtonOutline>
                        <ButtonPrimary size="M" type="submit"
                            disabled={!isValid || fields?.length === 0}
                            onClick={() => { }}>
                            {
                                loading ? <CircleSpin color="text-primary-500" /> :
                                    translate('CONFIRM')}
                        </ButtonPrimary>
                    </div>
                </form>
            </div>}
        />
    </>
}