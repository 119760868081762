import httpClient from './restful.service';

const createSkillService = (workspaceId: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/skills`;
    return httpClient.post(url, bodyParams);
};

const getSkillsService = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/skills`;
    return httpClient.get(url, { params });
};

const getSkillByIdService = (workspaceId: string, skillId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/skills/${skillId}`;
    return httpClient.get(url, { params });
};

const updateSkillByIdService = (workspaceId: string, skillId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/skills/${skillId}`;
    return httpClient.patch(url, params);
};

const deleteSkillByIdService = (workspaceId: string, skillId: string) => {
    const url = `/workspaces/${workspaceId}/skills/${skillId}`;
    return httpClient.delete(url);
};

const deleteLeanerSkillByIdService = (workspaceId: string, learnerId:string, skillId: string) => {
    const url = `/workspaces/${workspaceId}/students/${learnerId}/skills/${skillId}`;
    return httpClient.delete(url);
};

const getCategories = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/categories`;
    return httpClient.get(url, { params });
};

const getCourseSkills = (workspaceId: string, skillId: string | number, params?: any) => {
    const url = `/workspaces/${workspaceId}/courses/${skillId}/skills`;
    return httpClient.get(url, { params });
};

const createCourseSkills = (workspaceId: string, course_id: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/courses/${course_id}/skills`;
    return httpClient.post(url, bodyParams);
};

const getAssociatedCoursesById = (workspaceId: string, skillId: string | number, params?: any) => {
    const url = `/workspaces/${workspaceId}/skills/${skillId}/courses`;
    return httpClient.get(url, { params });
}

const deleteCourseSkill = (workspaceId: string, course_id: string, course_skill_id: string) => {
    const url = `/workspaces/${workspaceId}/courses/${course_id}/skills/${course_skill_id}`;
    return httpClient.delete(url);
};

const getLearnerSkill = (workspaceId: string, learnerId:string, params?: any) => {
    const url = `/workspaces/${workspaceId}/students/${learnerId}/skills`;
    return httpClient.get(url, { params });
};

const createLearnerSkill = (workspaceId: string, learnerId:string, params?: any) => {
    const url = `/workspaces/${workspaceId}/students/${learnerId}/skills`;
    return httpClient.post(url, params);
};

const createMultiLearnerSkill = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/students/skills`;
    return httpClient.post(url, params);
};

const getCourseTemplateSkills = (workspaceId: string, skillId: string | number, params?: any) => {
    const url = `/workspaces/${workspaceId}/course-templates/${skillId}/skills`;
    return httpClient.get(url, { params });
};

const createCourseTemplateSkills = (workspaceId: string, course_id: string, bodyParams: any) => {
    const url = `/workspaces/${workspaceId}/course-templates/${course_id}/skills`;
    return httpClient.post(url, bodyParams);
};

const deleteCourseTemplateSkill = (workspaceId: string, course_id: string, course_skill_id: string) => {
    const url = `/workspaces/${workspaceId}/course-templates/${course_id}/skills/${course_skill_id}`;
    return httpClient.delete(url);
};
export default {
    createSkillService,
    getSkillsService,
    getSkillByIdService,
    updateSkillByIdService,
    deleteSkillByIdService,
    getCategories,
    getAssociatedCoursesById,
    getCourseSkills,
    createCourseSkills,
    deleteCourseSkill,
    deleteLeanerSkillByIdService,
    getLearnerSkill,
    createLearnerSkill,
    getCourseTemplateSkills,
    createCourseTemplateSkills,
    deleteCourseTemplateSkill,
    createMultiLearnerSkill
};
