import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

interface UrlUserNavigationProps {
  user_id?: string | number;
}

/**
 * Custom hook: redirect or new tab
 * @returns {Object}
 */
export const useUrlUserNavigation = (props?: UrlUserNavigationProps) => {
  const history = useHistory();
  const params: any = useParams();
  const location = useLocation();

  const { workspaceId, userId } = useMemo(() => {
    let extractedWorkspaceId = params?.workspaceId;
    let extractedCourseId = props?.user_id || params?.userId;

    if (!extractedWorkspaceId || !extractedCourseId) {
      const pathParts = location.pathname.split('/');

      const workspaceIndex = pathParts.findIndex(part => part === 'workspace');
      if (workspaceIndex !== -1 && workspaceIndex + 1 < pathParts.length) {
        extractedWorkspaceId = pathParts[workspaceIndex + 1];
      }

      const coursesIndex = pathParts.findIndex(part => part === 'users');
      if (coursesIndex !== -1 && coursesIndex + 1 < pathParts.length) {
        extractedCourseId = pathParts[coursesIndex + 1];
      }
    }

    return {
      workspaceId: extractedWorkspaceId,
      userId: extractedCourseId
    };
  }, [params, location.pathname, props?.user_id]);

  /**
   * @param {Object} params - enrollmentId, view, etc.
   * @returns {string} URL
   */
  const buildUrl = useCallback((additionalParams = {}) => {
    if (!workspaceId || !userId) {
      console.warn('WorkspaceId or userId not found in the current URL');
      return '';
    }

    const baseUrl = `/workspace/${workspaceId}/management/users/${userId}`;

    if (Object.keys(additionalParams).length === 0) {
      return baseUrl;
    }
    
    const queryString = Object.entries(additionalParams)
      .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    
    return `${baseUrl}?${queryString}`;
  }, [workspaceId, userId]);

  /**
   * @param {Object} params - enrollmentId, view, etc.
   * @returns {string} URL
   */
  const buildFullUrl = useCallback((additionalParams = {}) => {
    const domain = window.location.origin;
    const relativeUrl = buildUrl(additionalParams);
    
    if (!relativeUrl) {
      return '';
    }
    
    return `${domain}${relativeUrl}`;
  }, [buildUrl]);

  /**
   * @param {Object} params - enrollmentId, view, etc.
   */
  const redirectToUrl = useCallback((additionalParams = {}) => {
    const url = buildUrl(additionalParams);
    
    if (!url) {
      return false;
    }
    
    history.push(url);
    return true;
  }, [history, buildUrl]);

  /**
   * new tab
   * @param {Object} params - enrollmentId, view, etc.
   */
  const openInNewTab = useCallback((additionalParams = {}) => {
    const url = buildFullUrl(additionalParams);
    
    if (!url) {
      return false;
    }
    
    window.open(url, '_blank', 'noopener,noreferrer');
    return true;
  }, [buildFullUrl]);

  return {
    buildUrlUser: buildUrl,
    buildFullUrlUser: buildFullUrl,
    redirectToUrlUser: redirectToUrl,
    openInNewTabUser: openInNewTab,
  };
};
