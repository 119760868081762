import { defaultPagination } from 'constant/util.const';
import { FC, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import libraryService from 'services/library.service';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import Table, { RenderTableCell, RenderTableHeader } from 'components/V2/Table/TableCustomMultipleSelect';
import { LIBRARY_ICONS, LibraryTypeEnum } from '../LibraryContants';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import NO_CONTENT_IMG from 'assets/img/empty-state/trash.png';
import TablePagination from 'components/V2/Pagination/Pagination';

import { CircleSpin } from 'components/UiComponents/CircleSpin';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import DialogComponent from 'components/Dialog/DialogComponent';
import { DeleteIcon, RestoreIcon } from 'assets/icon';
import ActionsButton from 'components/ActionsButton';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import './TabTrash.scss';

interface TabTrashProps { }

const TabTrash: FC<TabTrashProps> = ({ }) => {
    const params: any = useParams();
    const history = useHistory();
    const { t, i18n: {language} } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 1000);

    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

    const [pagination, setPagination] = useState(defaultPagination);
    const [dataTable, setDateTable] = useState<any>(null);
    const [indexPage, setIndexPage] = useState('0-0');
    const [isModalEmptyTrash, setIsModalEmptyTrash] = useState<boolean>(false);
    const [isModalRestore, setIsModalRestore] = useState<boolean>(false);
    const [isDelete, setIsDelete] = useState<boolean>(false);

    const [selectedFile, setSelectedFile] = useState<any>({});
    const [isDoubleClick, setIsDoubleClick] = useState<boolean>(false);
    const [isEmptyTrash, setIsEmptyTrash] = useState<boolean>(false);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const {
        userState: { result },
    } = useContext(UserContext);

    const getList = (page: number = 1, order?: string) => {
        setLoadingTable(true);
        const payload = {
            ...pagination,
            page: page || pagination.page,
            order: order || pagination?.order,
            status: 'trash',
            q: keyword,
        };
        if (!payload?.q) {
            delete payload?.q;
        }
        delete payload?.total;
        libraryService
            .getListLibrary(params.id, payload)
            .then((res) => {
                setDateTable(res?.data?.items);
                setPagination({
                    ...payload,
                    page: page || res?.data?.page,
                    per_page: res.data?.per_page,
                    order: order || res?.data?.order,
                    total: res?.data?.total,
                    sort_by: res?.data?.sort_by,
                });
                setIndexPage(getIndexPage(page, res.data?.per_page, res.data?.items?.length));
            })
            .finally(() => setLoadingTable(false));
        if (result?.id) {
            libraryService
                .getListLibrary(params.id, {
                    status: 'trash',
                    created_by: result?.id,
                })
                .then((res) => {
                    if (res?.data?.items?.length > 0) {
                        setIsEmptyTrash(false);
                    } else {
                        setIsEmptyTrash(true);
                    }
                });
        }
    };

    useEffect(() => {
        getList(1);
    }, [keyword]);

    const handleSort = () => {
        getList(pagination?.page, pagination?.order === 'desc' ? 'asc' : 'desc');
    };

    const columns: Column<any>[] = useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={translator('NAME')} />,
                accessor: 'name',
                Cell: (data: any) => {
                    let type = data?.row?.original?.object_type;
                    const subType = data?.row?.original?.sub_object_type?.split('.')[1];
                    if (type === 'image' && subType) {
                        type = subType;
                    }
                    return (
                        <div className="flex items-center pl-ooolab_p_1_e">
                            <div className="flex-none w-6">
                                <img
                                    alt="libary-icon"
                                    className="h-6 w-6"
                                    src={
                                        data?.row?.original?.object_type
                                            ? LIBRARY_ICONS(translator)?.find((item) => item?.value === type)?.icon
                                            : LIBRARY_ICONS(translator)[0].icon
                                    }
                                />
                            </div>
                            <div className="w-full">
                                <RenderTableCell
                                    className=" p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                    onClick={() => { }}
                                    value={data?.value}
                                />
                            </div>
                        </div>
                    );
                },
                width: 320,
            },
            {
                Header: () => <RenderTableHeader value={translator('OWNER')} />,
                accessor: 'created_by',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="flex items-center">
                            <div className="flex-none w-6">
                                <img alt="avatar-icon" className="h-6 w-6 rounded-full" src={row?.original?.created_by?.avatar_url} />
                            </div>
                            <div className="w-full">
                                <RenderTableCell
                                    className=" p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                                    onClick={() => { }}
                                    value={row?.original?.created_by?.display_name}
                                />
                            </div>
                        </div>
                    );
                },
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('CONTENT_TYPE')} />,
                accessor: 'object_type',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <RenderTableCell
                            className=" p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                            onClick={() => { }}
                            value={
                                row?.original?.object_type
                                    ? LIBRARY_ICONS(translator)?.find((item) => item?.value === row?.original?.object_type)?.label
                                    : LIBRARY_ICONS(translator)[0].label
                            }
                        />
                    );
                },
                width: 100,
            },
            {
                Header: () => (
                    <div className="flex items-center cursor-pointer text-primary-500" onClick={handleSort}>
                        <RenderTableHeader className="!text-primary-500" value={translator('DELETE_DATE')} />
                        {pagination?.order === 'desc' ? (
                            <ArrowDownIcon className="w-4 h-4 ml-1 " />
                        ) : (
                            <ArrowUpIcon className="w-4 h-4 ml-1 " />
                        )}
                    </div>
                ),
                accessor: 'updated_at',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell
                            className=" p-3 font-normal text-sm !text-gray-800 cursor-pointer truncate"
                            onClick={() => { }}
                            value={dayjs.utc(d?.value).tz(result?.time_zone)?.locale(language).fromNow()}
                        />
                    );
                },
                width: 100,
            },
            {
                Header: () => <></>,
                accessor: 'actions',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="mt-1">
                            <ActionsButton
                                styleOptions="top-0 right-[35px]"
                                menus={[
                                    {
                                        label: translator('RESTORE'),
                                        icon: <RestoreIcon />,
                                        isDisabled: !checkPermission('libraryTrashContent', 'RT'),
                                        action: async () => {
                                            setSelectedFile(row?.original);
                                            setIsModalRestore(true);
                                        },
                                        class: 'border-b border-gray-300',
                                        hide: !checkPermission('libraryTrashContent', 'RT'),
                                    },
                                    {
                                        label: translator('DELETE_FOREVER'),
                                        icon: <DeleteIcon width="18" height="18" />,
                                        isDisabled: (row?.original?.created_by?.id !== result?.id || !checkPermission('libraryTrashContent', 'D')),
                                        hide: (row?.original?.created_by?.id !== result?.id || !checkPermission('libraryTrashContent', 'D')),
                                        action: async () => {
                                            setIsDelete(true);
                                            setIsModalEmptyTrash(true);
                                            setSelectedFile(row?.original);
                                        },
                                        class: '!text-red-500',
                                    },
                                ]}
                            />
                        </div>
                    );
                },
                width: 40,
            },
        ],
        [dataTable, pagination?.order]
    );
    const initialState = {
        hiddenColumns: ['id'],
    };

    const handleClick = (item: any, event: any) => { };
    const handleDoubleClick = (item: any) => {
        if (item?.object_type === LibraryTypeEnum.folder) {
            setSelectedFile(item);
            setIsDoubleClick(true);
            setIsModalRestore(true);
        }
    };

    const handleChangePagination = (page: number) => {
        getList(page);
    };

    const onRestore = () => {
        if (selectedFile) {
            setLoadingBtn(true);
            const id = selectedFile?.object_type === LibraryTypeEnum.folder ? selectedFile?.id : selectedFile?.object_id;
            let action = 'recoverFile';
            switch (selectedFile?.object_type) {
                case LibraryTypeEnum.folder:
                    action = 'recoverFolder';
                    break;
                case LibraryTypeEnum.lesson:
                    action = 'recoverLesson';
                    break;
                case LibraryTypeEnum.h5p:
                    action = 'recoverH5P';
                    break;
                case LibraryTypeEnum.assignment:
                    action = 'recoverAssignment';
                    break;
                default:
                    action = 'recoverFile';
                    break;
            }
            libraryService?.[action](params?.id, id)
                .then(() => {
                    getList(1);
                    setSelectedFile({});
                })
                .catch(() => Toastify.error())
                .finally(() => {
                    setLoadingBtn(false);
                    setIsModalRestore(false);
                });
        }
    };

    const onDeleteForever = () => {
        if (selectedFile) {
            setLoadingBtn(true);
            const id = selectedFile?.object_type === LibraryTypeEnum.folder ? selectedFile?.id : selectedFile?.object_id;
            let action = 'deleteForeverFile';
            switch (selectedFile?.object_type) {
                case LibraryTypeEnum.folder:
                    action = 'deleteForeverFolder';
                    break;
                case LibraryTypeEnum.lesson:
                    action = 'deleteForeverLesson';
                    break;
                case LibraryTypeEnum.h5p:
                    action = 'deleteForeverH5P';
                    break;
                case LibraryTypeEnum.assignment:
                    action = 'deleteForeverAssignment';
                    break;
                default:
                    action = 'deleteForeverFile';
                    break;
            }
            libraryService?.[action](params?.id, id)
                .then(() => {
                    getList(1);
                    setSelectedFile({});
                })
                .catch(() => Toastify.error())
                .finally(() => {
                    setLoadingBtn(false);
                    setIsModalEmptyTrash(false);
                });
        }
    };

    const onEmptyTrash = () => {
        setLoadingBtn(true);
        libraryService
            .emptyTrash(params?.id)
            .then((res) => {
                getList(1);
                Toastify.success();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setLoadingBtn(false);
                setIsModalEmptyTrash(false);
            });
    };

    return (
        <div className="tabTrash">
            <div className="tabTrash-header">
                <div className="tabTrash-headerItems">
                    <SearchBox
                        className="tabTrash-headerSearch"
                        onSubmit={(value) => {
                            setKeyword(value);
                        }}
                    />

                    {checkPermission('libraryTrashContent', 'D') ? <ButtonOutline type="button" onClick={() => setIsModalEmptyTrash(true)} className="" disabled={isEmptyTrash || !checkPermission('libraryTrashContent', 'D')}>
                        {translator('EMPTY_TRASH')}
                    </ButtonOutline> : ''}

                </div>
            </div>
            <div className='p-5 pb-0'>
                {loadingTable ? (
                    <div className="tabTrash-tableLoading">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <>
                        <div className="tabTrash-tableBody">
                            <Table
                                data={dataTable || []}
                                columns={columns}
                                initialState={initialState}
                                styleNoContent={'!font-normal !text-base !text-gray-500'}
                                emptyContent={translator('NO_CONTENT_TRASH')}
                                emptyIcon={NO_CONTENT_IMG}
                                onClickRow={handleClick}
                                onDoubleClickRow={handleDoubleClick}
                                actionNoContent={<></>}
                            />
                        </div>
                        <div className="tabTrash-pagination ">
                            <p className="tabTrash-paginationCount">
                                {translator('SHOWING_1_50_OF_TOTAL_PAGE', true, {
                                    fromTo: indexPage,
                                    totalPage: pagination?.total,
                                })}
                            </p>
                            <div className="mt-[-1px]">
                                <TablePagination
                                    onClickPagination={handleChangePagination}
                                    perPage={pagination.per_page}
                                    total={pagination.total}
                                    forcePage={pagination.page - 1}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
            {isModalEmptyTrash ? (
                <DialogComponent
                    isOpen={isModalEmptyTrash}
                    title={isDelete ? translator('DELETE_FOREVER_LOWER') : translator('EMPTY_TRASH_LOWER')}
                    stylesTitle="tabTrash-modalTitleStyle"
                    onCloseModal={() => {
                        setIsModalEmptyTrash(false);
                        setIsDelete(false);
                    }}
                    maskClosable={true}
                    styles="tabTrash-modalStyles"
                    child={
                        <>
                            <div className="tabTrash-modalStyles--bodyTitle">
                                {isDelete ? translator('TITLE_DELETE_FOREVER') : translator('TITLE_EMPTY_TRASH')}
                            </div>
                            <div className="tabTrash-modalStyles--bodyButton ">
                                <ButtonOutline
                                    type="button"
                                    onClick={() => {
                                        setIsDelete(false);
                                        setIsModalEmptyTrash(false);
                                    }}
                                >
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    disabled={loadingBtn}
                                    onClick={() => {
                                        isDelete ? onDeleteForever() : onEmptyTrash();
                                    }}
                                    className="buttonPrimary-danger"
                                >
                                    {loadingBtn ? (
                                        <span className="confirmDeleteModal-loading">
                                            <CircleSpin />
                                            {translator('DELETE')}
                                        </span>
                                    ) : (
                                        translator('DELETE')
                                    )}
                                </ButtonPrimary>
                            </div>
                        </>
                    }
                />
            ) : (
                ''
            )}

            {selectedFile && isModalRestore ? (
                <DialogComponent
                    isOpen={isModalRestore}
                    title={isDoubleClick ? translator('FOLDER_IN_TRASH') : translator('RESTORE')}
                    stylesTitle="tabTrash-modalTitleStyle"
                    onCloseModal={() => {
                        setIsModalRestore(false);
                        setIsDoubleClick(false);
                    }}
                    maskClosable={true}
                    styles="tabTrash-modalStyles"
                    child={
                        <>
                            <div className="tabTrash-modalStyles--bodyTitle">
                                {isDoubleClick ? translator('TITLE_FOLDER_IN_TRASH') : translator('TITLE_RESTORE')}
                            </div>
                            <div className="tabTrash-modalStyles--bodyButton ">
                                <ButtonOutline
                                    type="button"
                                    onClick={() => {
                                        setIsModalRestore(false);
                                        setIsDoubleClick(false);
                                    }}
                                >
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary
                                    type="button"
                                    onClick={() => {
                                        onRestore();
                                    }}
                                >
                                    {translator('RESTORE')}
                                </ButtonPrimary>
                            </div>
                        </>
                    }
                />
            ) : (
                ''
            )}
        </div>
    );
};

export default TabTrash;
