import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import dayjs from 'dayjs';
import './Table.scss'
import { UserContext } from 'contexts/User/UserContext';
import { User } from '_shared/components/User/User';
import { useHistory, useParams } from 'react-router-dom';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';
import NO_LEARNER from 'assets/img/empty-state/student.png'
import { useAppSelector } from 'hooks/hooks';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ModalDepositRefund } from 'components/Workspace/Course/CourseBuilder/components/CourseCreditBalance/ModalDepositRefund';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

interface IViewTable {
    data: CourseType[];
    refreshData?: () => void;
}

const ViewTable: FC<IViewTable> = ({
    data,
    refreshData,
}) => {
    const { t: translator } = useTranslation();
    const {setting} = useAppSelector((state) => state.course);
    const { redirectToUrlCourse } = useUrlCourseNavigation();
    
    const [rowData, setRowData] = useState<any>(null);   

    const params: any = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const history = useHistory();

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('Enrollment ID')} />,
                Cell: ({row}: any) => {
                    return (
                        <RenderTableCell 
                            onClick={() => redirectToUrlCourse({
                                tab: 'people',
                                subtab: 'learner',
                                enrollmentId: row?.original?.id,
                                view: row?.original?.type === 'individual' ? 'information' : 'members',
                            })} 
                            className='text-primary-500 font-semibold cursor-pointer' value={row?.original?.id} 
                        />
                    )
                },
                accessor: 'id',
                width: 80
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LEARNER')} />,
                accessor: 'learner',
                Cell: ({row}: any) => {
                    if (!row?.original?.learner) {
                        return <RenderTableCell value="-" />
                    }
                    return (
                        <User
                            userId={row?.original?.learner?.id}
                            avatar={row.original?.learner?.avatar_url}
                            name={row.original?.learner?.display_name}
                        />
                    )
                },
                width: 100
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.START_DATE')} />,
                accessor: 'start_date',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.original?.start_date).tz(result?.time_zone).format("DD/MM/YYYY")} />,
                width: 70
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.END_DATE')} />,
                accessor: 'end_date',
                Cell: ({row}: any) => <RenderTableCell value={dayjs.utc(row.original?.end_date).tz(result?.time_zone).format("DD/MM/YYYY")} />,
                width: 70
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.STATUS')} />,
                accessor: 'display_status',
                Cell: ({row}: any) => {
                    if (!row?.values?.display_status) return <RenderTableCell value='-'/>
                    return (
                        <EnrollmentStatus status={row?.values?.display_status} />
                    );
                },
                width: 100
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.CREDITS')} />,
                accessor: 'credit_balance',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={
                            <div>
                                <span className="font-semibold">
                                    {row?.original?.learner?.credit_balance?.total_credit - row?.original?.learner?.credit_balance?.available_credit || 0}
                                </span>
                                /{row?.original?.learner?.credit_balance?.total_credit || 0}
                            </div>
                        }
                    />
                ),
                width: 70,
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ADDED_BY')} />,
                accessor: 'created_by',
                Cell: ({row}: any) => {
                    if (!row?.original?.created_by) {
                        return <RenderTableCell value="-" />
                    }
                    return (
                        <User 
                            name={row?.original?.created_by?.display_name}
                            avatar={row?.original?.created_by?.avatar_url} 
                            userId={row?.original?.created_by?.id}
                        /> 
                    )
                },
                width: 80
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={{
                                    ...row?.original,
                                    credit_balance: row?.original?.learner?.credit_balance || null,
                                }}
                                position={row?.index > data?.length/2 ? 'bottom-0' : ''}
                                onDeposit={() => setRowData(row?.original)}
                                refreshData={refreshData}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40,
            }
        ],
        [data]
    );

    return (
        <>
            <Table
                data={data || []}
                columns={columns}
                emptyIcon={NO_LEARNER}
                initialState={{ hiddenColumns: setting?.enrollment_type === 'credit' ? [] : ['credit_balance'] }}
                emptyContent={translator("COURSES.NO_LEARNERS")}
            />
            <DialogComponent
                isOpen={!!rowData}
                onCloseModal={() => setRowData(null)}
                title={translator('COURSES.CREDIT_BALANCE.DEPOSIT_CREDITS')}
                styles="max-w-md"
                child={
                    <ModalDepositRefund
                        translator={(key, isNew) => isNew ? translator(key) : translator(`COURSES.CREDIT_BALANCE.${key}`)}
                        type='deposit'
                        data={rowData}
                        onClose={() => setRowData(null)}
                        onConfirm={() => {
                            setRowData(null);
                            refreshData?.();
                        }}
                    />
                }
            />
        </>
    );
};

export default ViewTable;
