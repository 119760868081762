import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';
import { useLocation, useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { getIndexPage } from 'utils/commonFun';
import SessionTable from './components/SessionTable/SessionTable';
import './SessionList.scss';
import { SessionFilter } from './components/SessionFilter/SessionFilter';
import DatePickerComponent from 'components/Reports/components/DatePicker';
import { PlusSmIcon, XCircleIcon, XIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ModalAddLearner } from './components/ModalSession/ModalAddLearner';
import dayjs from 'dayjs';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import { useAppSelector } from 'hooks/hooks';
import { UserContext } from 'contexts/User/UserContext';

let startDate = null;
let endDate = null;
let saveKeyword = '';

/**
 * hasFilterLearner = true
 *      Course Detail Sessions -> Calendar
 * hasFilterLearner = false
 *      Course Detail People -> Enrollments -> Enrollments detail -> Calendar
 */
export const SessionList = ({ hasFilterLearner = true, learnerId = null, learner_group_ids = null, showMarkedAttendance = false }) => {
    const params: { id: string; courseId: string } = useParams();
    const { t } = useTranslation();
    const location: any = useLocation();

    const [keyword, setKeyword] = useDebouncedState('', 0);
    const { setting } = useAppSelector((state) => state.course);

    const [isFirstLoad, setFirstLoad] = useState(true);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [dateRange, setDateRange] = useState([]);
    const [sessionsSelected, setSessionsSelected] = useState([]);
    const [isOpenModalCancel, setOpenModalCancel] = useState(false);
    const [isOpenModalAddLearner, setOpenModalAddLearner] = useState(false);
    const [loadingCancelSession, setLoadingCancelSession] = useState(false);

    const {
        userState: { result },
    } = useContext(UserContext);

    startDate = useMemo(() => dateRange?.[0] || null, [dateRange]);
    endDate = useMemo(() => dateRange?.[1] || null, [dateRange]);
    saveKeyword = useMemo(() => keyword, [keyword]);

    const translator = (str: string, opt?: any) => t(`SESSION.${str}`, opt);

    const fetchData = (page: number | string = 1, filter = undefined) => {
        if (loading) return;

        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            q: saveKeyword || undefined,
            page,
            learner_ids: learnerId || filter?.learner_ids,
            learning_group_ids: learner_group_ids || filter?.learning_group_ids,
            from_date: startDate && endDate ? dayjs(startDate).startOf('D').format('YYYY-MM-DDTHH:mm:ss') : undefined,
            to_date: startDate && endDate ? dayjs(endDate).endOf('D').format('YYYY-MM-DDTHH:mm:ss') : undefined,
            query_for: learner_group_ids ? 'for_a_specific_learning_group' : 'for_a_specific_learner',
        };
        delete payload?.total;
        if (!payload?.q) delete payload?.q;
        if (!payload?.learner_ids) delete payload?.learner_ids;
        if (!payload?.learning_group_ids) delete payload?.learning_group_ids;
        if (!payload?.from_date) delete payload?.from_date;
        if (!payload?.to_date) delete payload?.to_date;
        if (hasFilterLearner) delete payload?.query_for;

        courseService
            .getSessionsList(params?.id, params?.courseId, payload)
            .then((res) => {
                setFirstLoad(false);
                const { items, total } = res.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const onCancelSession = () => {
        setLoadingCancelSession(true);
        courseService
            .cancelMultiSessions(params?.id, params?.courseId, { session_ids: sessionsSelected?.map((item) => item?.id) })
            .then(() => {
                Toastify.success(sessionsSelected?.length > 1 ? 'Sessions successfully canceled' : 'Session successfully canceled');
                fetchData();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setLoadingCancelSession(false);
                setOpenModalCancel(false);
                setSessionsSelected([]);
            });
    };

    const handlePagination = (page: number) => {
        fetchData(page);
    };

    const handleFilter = (value) => {
        fetchData(1, value);
    };

    const learningGroup = useMemo(() => {
        return (
            sessionsSelected
                ?.map((item) => {
                    if (!!item?.learning_groups) return item?.learning_groups?.[0];
                    return null;
                })
                ?.filter(Boolean)?.[0] || null
        );
    }, [sessionsSelected]);

    // for check Add Learner
    const checkSessionOngoingOrUpcoming = useMemo(() => {
        const currentTime = dayjs();
        let typeSession = 'individual';
        let isOnceLearningGroup = false;
        const listLearningGroupSessionsSelected = sessionsSelected?.map((item) => {
            if (!!item?.learning_groups) return item?.learning_groups?.[0];
            return null;
        });
        const listLearningGroupSessionsSelectFilter = listLearningGroupSessionsSelected.filter(Boolean);
        const learningGroupSessionsSelected = listLearningGroupSessionsSelectFilter?.[0] || null;

        if (!!listLearningGroupSessionsSelectFilter?.length) {
            typeSession = 'learning_group';
            isOnceLearningGroup =
                listLearningGroupSessionsSelectFilter.every((item) => item?.id === learningGroupSessionsSelected?.id) &&
                sessionsSelected?.length === listLearningGroupSessionsSelectFilter?.length;
        }

        const flgSession = sessionsSelected.every((ss) => {
            const startTime = dayjs(ss?.start_time).tz(result.time_zone); // for check Upcoming session
            const endTime = dayjs(ss?.end_time).tz(result.time_zone); // for check Ongoing session
            if (ss?.display_status !== 'cancelled' && ss?.display_status !== 'completed')
                return currentTime.isBefore(endTime) || startTime.isAfter(currentTime);
            return false;
        });

        if (typeSession === 'learning_group') {
            return flgSession && isOnceLearningGroup;
        }

        return flgSession;
    }, [sessionsSelected]);

    // for check Cancel sessions
    const checkSessionUpcoming = useMemo(() => {
        const currentTime = dayjs();

        return sessionsSelected.every((ss) => {
            // allow Upcoming or Ongoing session (new requirement)
            const startTime = dayjs(ss?.start_time).tz(result.time_zone);
            if (ss?.display_status !== 'cancelled' && ss?.display_status !== 'completed') return true;
            return false;
        });
    }, [sessionsSelected]);

    useEffect(() => {
        return () => {
            startDate = null;
            endDate = null;
        };
    }, []);

    useEffect(() => {
        if (!!endDate || (!startDate && !endDate)) if (isFirstLoad && location?.state) return;
        fetchData();
    }, [endDate, keyword]);

    useEffect(() => {
        if (sessionsSelected?.length && data?.length) {
            const updatedSelected = data?.filter((item) => sessionsSelected?.some((selected) => selected?.id === item?.id));
            setSessionsSelected(updatedSelected);
        }
    }, [data]);

    return (
        <>
            <div className="CourseSessionList sessionList-main">
                <div className="mt-4 mb-3 flex justify-between items-center">
                    <div className="max-w-[234px]">
                        <SearchBox
                            placeholder={translator('SEARCH_SESSION_ID')}
                            className="sessionFilter-searchInput"
                            onSubmit={(value) => setKeyword(value)}
                        />
                    </div>
                    <div className="relative min-w-[248px]">
                        <DatePickerComponent
                            onChange={setDateRange}
                            startDate={dateRange?.[0]}
                            endDate={dateRange?.[1]}
                            className="!rounded !px-2 min-h-[36px]"
                            placeHolder="DD/MM/YYYY - DD/MM/YYYY"
                            isClearable
                        />
                    </div>
                </div>
                <div className="CourseSessionList-filter">
                    <SessionFilter
                        onChangeFilter={handleFilter}
                        hasFilterLearner={hasFilterLearner}
                        learner_group_ids={learner_group_ids}
                        isFirstLoad={isFirstLoad}
                        showMarkedAttendance={showMarkedAttendance}
                    />
                    <div className={`flex items-center space-x-2 mt-3 ${!!sessionsSelected?.length ? '' : '!hidden'}`}>
                        <span onClick={() => setSessionsSelected([])}>
                            <XIcon className="w-6 h-6 hover:text-red-500 cursor-pointer" />
                        </span>
                        <span>{sessionsSelected?.length + ' sessions selected'}</span>
                        <>
                            <ButtonOutline
                                type="button"
                                disabled={!checkSessionUpcoming}
                                onClick={() => setOpenModalCancel(true)}
                                className={`${!checkSessionUpcoming ? 'buttonOutline--isDisable' : '!text-red-500'}`}
                                data-for="CANCEL_SESSIONS_TOOLTIPS"
                                data-tip={translator('CANCEL_SESSIONS_TOOLTIPS')}
                            >
                                <XCircleIcon className={`w-6 h-6 mr-1 ${!checkSessionUpcoming ? 'text-gray-400' : 'text-red-500'}`} />
                                {translator('CANCEL_SESSIONS')}
                            </ButtonOutline>
                            {!checkSessionUpcoming && (
                                <TooltipCustom
                                    classNameContainer="shadow-medium"
                                    id="CANCEL_SESSIONS_TOOLTIPS"
                                    content={translator('CANCEL_SESSIONS_TOOLTIPS')}
                                    place="bottom"
                                    type="light"
                                />
                            )}
                        </>
                        {(hasFilterLearner || (!hasFilterLearner && learner_group_ids)) && (
                            <>
                                <ButtonOutline
                                    type="button"
                                    disabled={!checkSessionOngoingOrUpcoming}
                                    onClick={() => setOpenModalAddLearner(true)}
                                    className={!checkSessionOngoingOrUpcoming ? 'buttonOutline--isDisable' : '!text-enterprise'}
                                    data-for="ADD_LEARNER_TOOLTIPS"
                                    data-tip={translator('ADD_LEARNER_TOOLTIPS')}
                                >
                                    <PlusSmIcon
                                        className={`w-6 h-6 mr-1 ${
                                            !checkSessionOngoingOrUpcoming ? 'text-gray-400' : 'text-enterprise'
                                        }`}
                                    />
                                    {translator('ADD_ATTENDEE')}
                                </ButtonOutline>
                                {!checkSessionOngoingOrUpcoming && (
                                    <TooltipCustom
                                        classNameContainer="shadow-medium !opacity-100"
                                        id="ADD_LEARNER_TOOLTIPS"
                                        className=""
                                        content={
                                            <>
                                                <p>You can only add learners to sessions that are:</p>
                                                <div className="mt-1 pl-2">
                                                    <p>
                                                        <strong>● Ongoing</strong> or <strong>Upcoming</strong>
                                                    </p>
                                                    <p>
                                                        <strong>● For the same learning group</strong> or{' '}
                                                        <strong>For individual learners</strong>
                                                    </p>
                                                </div>
                                                <p className="mt-1">Please remove any ineligible sessions.</p>
                                            </>
                                        }
                                        place="bottom"
                                        type="light"
                                    />
                                )}
                            </>
                        )}
                    </div>
                </div>
                <div className="wrapper-content">
                    <div className="wrapper-content--main">
                        {loading ? (
                            <div className="loading">
                                <CircleSpin color="text-primary-500" />
                            </div>
                        ) : (
                            <SessionTable
                                data={data}
                                refreshData={fetchData}
                                hasFilterLearner={hasFilterLearner}
                                learnerId={learnerId}
                                learner_group_ids={learner_group_ids}
                                selected={sessionsSelected}
                                setSelected={setSessionsSelected}
                            />
                        )}
                    </div>
                    <Pagination
                        total={pagination.total}
                        per_page={pagination.per_page}
                        callback={(e) => handlePagination(e)}
                        indexPage={indexPage}
                        page={pagination?.page}
                    />
                </div>
                <DialogComponent
                    title={translator(sessionsSelected?.length > 1 ? 'CANCEL_SESSIONS' : 'CANCEL_SESSION')}
                    isOpen={isOpenModalCancel}
                    onCloseModal={() => setOpenModalCancel(false)}
                    isShowClose={true}
                    styles="max-w-[530px]"
                    child={
                        <>
                            <div className="flex flex-col mt-1">
                                <div className="mt-2 text-sm text-gray-800">
                                    {translator(
                                        setting?.enrollment_type === 'credit'
                                            ? 'CANCEL_SESSIONS_HAS_CREDIT_MESSAGE'
                                            : sessionsSelected?.length > 1
                                            ? 'CANCEL_SESSIONS_NONE_CREDIT_MESSAGE'
                                            : 'CANCEL_A_SESSION_NONE_CREDIT_MESSAGE'
                                    )}
                                </div>
                                <div className="flex justify-center gap-4 mt-6 mb-2 w-full">
                                    <ButtonOutline type="button" onClick={() => setOpenModalCancel(false)}>
                                        {t('NO')}
                                    </ButtonOutline>
                                    <ButtonPrimary
                                        type="button"
                                        disabled={loadingCancelSession}
                                        className="buttonPrimary-danger"
                                        onClick={onCancelSession}
                                    >
                                        {t('Yes')}
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </>
                    }
                />
                {sessionsSelected && (
                    <DialogComponent
                        title={translator('ADD_ATTENDEE_TO_SESSION')}
                        isOpen={isOpenModalAddLearner}
                        onCloseModal={() => setOpenModalAddLearner(false)}
                        isShowClose={true}
                        styles="max-w-[600px]"
                        child={
                            <ModalAddLearner
                                type={learningGroup ? 'learning_group' : 'individual'}
                                learning_group={learningGroup ? { label: learningGroup?.name, value: learningGroup?.id } : null}
                                sessions={sessionsSelected}
                                onClose={() => setOpenModalAddLearner(false)}
                                onSubmit={() => {
                                    setOpenModalAddLearner(false);
                                    setSessionsSelected([]);
                                    fetchData();
                                }}
                            />
                        }
                    />
                )}
            </div>
        </>
    );
};
