import { InputField } from 'components/InputForm/InputForm';
import { useTranslation } from 'react-i18next';
import { VALIDATE_FIELD } from 'utils/validate';
import { useFieldArray } from 'react-hook-form';
import { ButtonOutline } from '_shared';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import './Reminder.scss'

export enum REMINDER_TYPE {
  pending_session_attendance = "pending_session_attendance",
  upcoming_session = "upcoming_session",
  upcoming_assignment= "upcoming_assignment"
}

export default function Reminder({ notAllowEdit = false, formProps }) {
    const {
        control,
        register,
        formState: { errors, isSubmitted },
        watch

    } = formProps;
    const { t: translator } = useTranslation();

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'reminders',
    });

    const removeRemider = (id) => {
      if (fields?.length) {
          remove(id);
      }
    };

    return (
        <div className="">
            <div className="courseSettings-title Reminder">
                {translator('COURSES.SETTING.REMINERS')}
                <p className="reminder-description">{translator('COURSES.SETTING.SET_UP_REMINDERS_FOR_YOUR_COURSE')}</p>
            </div>
            <div className={`main-setting  ${notAllowEdit ? 'opacity-container' : ''} `}>
                <div className="reminder-title">{translator('COURSES.SETTING.UPCOMING_SESSION')}</div>
                <div className="reminder-description">
                    {translator('COURSES.SETTING.REMINDER_FOR_UPCOMING_SESSION')}
                </div>
                {fields?.filter((item: any) => item?.event === REMINDER_TYPE.upcoming_session)?.map((item) => {
                    const reminderIndex: any = fields?.findIndex((reminder: any) => reminder?.id === item?.id)
                    return (
                      <div>
                        <div className='reminder'>
                          <div className='sub-title'>{translator('COURSES.SETTING.BEFORE_SESSION_START')}: </div>
                          <div className='input'>
                              <InputField
                                name={`reminders.${reminderIndex}.hours`}
                                maxLength={255}
                                classNameInput="h-8 !w-16"
                                labelHorizontal={translator('HOUR_S')}
                                type='number'
                                register={register(`reminders.${reminderIndex}.hours`, {
                                  validate: {
                                    shouldNotContainSpace: (value) => {
                                        return VALIDATE_FIELD.NUMBER_.exec(value) ? true : translator('FORM_CONST.NUMBER_ONLY');
                                    },
                                  },
                                })}
                                errors={errors}
                                isRequired
                                disabled={notAllowEdit}
                              />
                              <InputField
                                name={`reminders.${reminderIndex}.minutes`}
                                maxLength={255}
                                classNameInput="h-8 !w-16"
                                className='max-w-[130px]'
                                labelHorizontal={translator('MINUTES')}
                                type='number'
                                register={register(`reminders.${reminderIndex}.minutes`, {
                                  validate: {
                                    shouldNotContainSpace: (value) => {
                                        return VALIDATE_FIELD.MUNUTES.exec(value) ? true : translator('FORM_CONST.MIMUTES');
                                    },
                                  },
                                })}
                                isRequired
                                errors={errors}
                                disabled={notAllowEdit}
                              />
                              <div onClick={() => !notAllowEdit && removeRemider(reminderIndex)} >
                                <TrashIcon className='w-5 h-5 text-red-500 cursor-pointer mt-1'/>
                              </div>
                          </div>
                        </div>
                      </div>
                      
                    )
                })}

                <ButtonOutline
                    className="button-add-reminder !border-primary-500 !text-primary-500 ml-4"
                    type="button"
                    onClick={() => append({ hours: null, minutes: null, event: REMINDER_TYPE.upcoming_session })}
                    disabled={fields?.filter((item: any) => item?.event === REMINDER_TYPE.upcoming_session)?.length === 5 || notAllowEdit}
                >
                    <PlusIcon className="w-5 h-5 text-primary-500 " />
                    {translator('COURSES.SETTING.ADD_REMINDER')}
                </ButtonOutline>

                <div className="reminder-title mt-5 pt-5 border-t border-gray-300">{translator('COURSES.SETTING.ASSIGNMENT_DUE_SOON')}</div>
                <div className="reminder-description">
                    {translator('COURSES.SETTING.SETUP_FOR_ASSIGNMENT_DUE_SOON')}
                </div>

                {fields?.filter((item: any) => item?.event === REMINDER_TYPE.upcoming_assignment)?.map((item) => {
                    const reminderIndex: any = fields?.findIndex((reminder: any) => reminder?.id === item?.id)
                    return (
                      <div className='reminder'>
                        <div className='sub-title '>{translator('COURSES.SETTING.BEFORE_ASSIGNMENT_START')}: </div>
                        <div className='input'>
                          <InputField
                              name={`reminders.${reminderIndex}.hours`}
                              maxLength={255}
                              classNameInput="h-8 !w-16"
                              labelHorizontal={translator('HOUR_S')}
                              type='number'
                              register={register(`reminders.${reminderIndex}.hours`, {
                                validate: {
                                  shouldNotContainSpace: (value) => {
                                      return VALIDATE_FIELD.NUMBER_.exec(value) ? true : `${translator('FORM_CONST.NUMBER_ONLY')}`;
                                  },
                                },
                              })}
                              errors={errors}
                              isRequired
                              disabled={notAllowEdit}
                            />
                            <InputField
                              name={`reminders.${reminderIndex}.minutes`}
                              maxLength={255}
                              classNameInput="h-8 !w-16"
                              labelHorizontal={translator('MINUTES')}
                              className='max-w-[130px]'
                              isRequired
                              type='number'
                              register={register(`reminders.${reminderIndex}.minutes`, {
                                validate: {
                                  shouldNotContainSpace: (value) => {
                                      return VALIDATE_FIELD.MUNUTES.exec(value) ? true : translator('FORM_CONST.MIMUTES');
                                  }}}
                              )}
                              errors={errors}
                              disabled={notAllowEdit}
                            />
                            <div onClick={() => !notAllowEdit && removeRemider(reminderIndex)} >
                              <TrashIcon className='w-5 h-5 text-red-500 cursor-pointer mt-1'/>
                            </div>
                          </div>
                        </div>
                    )
                })}
                <ButtonOutline
                    className="button-add-reminder !border-primary-500 !text-primary-500 ml-4"
                    type="button"
                    onClick={() => append({ hours: null, minutes: null, event: REMINDER_TYPE.upcoming_assignment })}
                    disabled={fields?.filter((item: any) => item?.event === REMINDER_TYPE.upcoming_assignment)?.length === 5 || notAllowEdit}
                >
                    <PlusIcon className="w-5 h-5 text-primary-500 " />
                    {translator('COURSES.SETTING.ADD_REMINDER')}
                </ButtonOutline>
            </div>
        </div>
    );
}
