import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

type TableLearningGroupsPeopleProps = {
    data: any[];
};

const TableLearningGroupsPeople: FC<TableLearningGroupsPeopleProps> = ({ data }) => {
    const { t } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const { redirectToUrlCourse } = useUrlCourseNavigation();

    const workspaceId = params.id;
    const courseId = params.courseId;

    const columns = useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={t('LEARNING_GROUP_S')} />,
                Cell: ({ row }: any) => {
                    return (
                        <RenderTableCell
                            onClick={() => redirectToUrlCourse({
                                tab: 'people',
                                subtab: 'learning-group',
                                // learningGroupId: row?.original?.id, // COMING_SOON
                                enrollmentId: row?.original?.id,
                                view: 'members',
                            })
                            }
                            className="text-primary-500 font-semibold cursor-pointer"
                            value={row?.original?.name}
                        />
                    );
                },
                accessor: 'learning_groups',
                width: 200,
            },
            {
                Header: () => <RenderTableHeader value={t('Number 0f leaner(s)')} />,
                accessor: 'learners',
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row?.original?.number_of_learners} />;
                },
                width: 100,
            },
        ],
        [data]
    );

    return <Table initialState={{ hiddenColumns: ['id'] }} data={data || []} columns={columns} emptyContent={t('NO_LEARNING_GROUPS')} />;
};

export default TableLearningGroupsPeople;
