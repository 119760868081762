import { useEffect, useMemo, useState } from 'react';
import SearchInput from '../components/SearchInput';
import { useParams } from 'react-router';
import { IClassResponse } from 'types/Class.type';
import ButtonDownload from 'components/Button/ButtonDownload';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useTranslation } from 'react-i18next';
import { CardContainer } from '../components/CardContainer';
import { Title } from '../components/Title';
import { timeList } from 'constant/workspace.const';
import { ColumnWithLooseAccessor } from 'react-table';
import Table from 'components/Table';
import TablePagination from 'components/V2/Pagination/Pagination';
import { ClassOverview } from './components/ClassOverview';
import classesService from "services/reports/classes-report.service";
import { LinkCustom } from 'components/V2/Table/TableCustom';
import { checkPermission, getIndexPage, getValueOrSign } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { downloadCSV } from '../components/common';
import { InformationCircleIcon } from '@heroicons/react/outline';
import ReactTooltip from 'react-tooltip';
import { CircleInformationIcon } from 'assets/icon';
import { TooltipCustom } from 'components/Tooltip/Tooltip';

const ClassDashboard = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string } = useParams();
    const [classes, setClasses] = useState<IClassResponse>();
    const [selectedDate, setSelectDate] = useState(timeList[1]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState<string>(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const columns: ColumnWithLooseAccessor[] = useMemo(
        () => [
            {

                Header: () => (
                    <p className="text-left pl-2">
                        {translator('Course Name')}
                    </p>
                ),
                Cell: ({ row }: { row: any }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${params.id}/report/dashboard-course/${row?.values?.course?.id}`}
                            className="!text-sm text-primary-500 font-semibold cursor-pointer truncate max-w-[420px]"
                            isTooltip
                            value={row?.values?.course?.title}
                            disable={row?.values?.course?.title?.length < 35}
                        />
                    );
                },
                accessor: 'course'
            },
            {
                Header: () => <p className="">{translator('Avg Grade')}</p>,
                accessor: 'overall',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
            },
            {
                Header: () => <p className="">{translator('Avg Attendance')}</p>,
                accessor: 'attendance',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
            },
            {
                Header: () => <div className='flex items-center gap-1 max-w-sm '>
                    <p className="">{t('AVG_ASSIGNMENT_GRADE')}</p>
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                        data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                            id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                            content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                            place="top"
                            type="light"
                        />
                    </div>
                </div>,
                accessor: 'assignment_score',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
            },
            {
                Header: () => <div className='flex items-center gap-1 max-w-sm '>
                    <p className="">{t('AVG_ASSIGNMENT_COMPLETION')}</p>
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for="TOOLTIPS_ASSIGNMENT_COMPLETION"
                        data-tip={t('TOOLTIPS_ASSIGNMENT_COMPLETION')}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                            id="TOOLTIPS_ASSIGNMENT_COMPLETION"
                            content={t('TOOLTIPS_ASSIGNMENT_COMPLETION')}
                            place="top"
                            type="light"
                        />
                    </div>
                </div>,
                accessor: 'assignment_completion',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value || 0)}
                        </p>
                    );
                },
            },
            {
                Header: () => <div className='flex items-center gap-1 max-w-sm '>
                    <p className="">{t('AVG_ASSIGNMENT_ONE_TIME')}</p>
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for="TOOLTIPS_ASSIGNMENT_ON_TIME"
                        data-tip={t('TOOLTIPS_ASSIGNMENT_ON_TIME')}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                            id="TOOLTIPS_ASSIGNMENT_ON_TIME"
                            content={t('TOOLTIPS_ASSIGNMENT_ON_TIME')}
                            place="top"
                            type="light"
                        />
                    </div>
                </div>,
                accessor: 'assignment_ontime',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value || 0)}
                        </p>
                    );
                },
            },
            // {
            //     Header: () => <p className="">{t('AVG_ASSIGNMENT_OVERDUE')}</p>,
            //     accessor: 'assignment_overdue',
            //     Cell: (d: any) => {
            //         return (
            //             <p className="text-gray-800">
            //                 {getValueOrSign(d.value || 0)}
            //             </p>
            //         );
            //     },
            // },
            {
                Header: () => <p className="">{translator('Avg Learning Path Completion')}</p>,
                accessor: 'course_completion',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
                width: 128
            },
            {
                Header: () => <p className="">{translator('Avg Goal Achievement')}</p>,
                accessor: 'lesson_goal',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
            },
        ],
        []
    );

    const getData = async (page = 1) => {
        try {
            setLoading(true);
            const payload: any = {
                page,
                q: searchText
            }
            if (!payload.q) {
                delete payload.q;
            }
            const response = await classesService.getClassStat(params.id, payload);
            setClasses(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const handlePagination = (page: number) => {
        getData(page);
    }

    const handleDownloadCSV = async () => {
        try {
            setIsDownloading(true);
            const response = await classesService.getClassStat(params.id,
                {
                    page: classes?.page,
                    skip_pagination: true
                }
            );
            let headers: string[], rows: string[][];
            let file_name = `Courses report`;
            headers = [
                'No.',
                'Course Name',
                'Avg Overall Grade',
                'Avg Attendance',
                'Avg Assignment Grade',
                'Avg Assignment Completion',
                'Avg Assignment On-time',
                'Avg Course Completion',
                'Avg Goals Achieved'
            ];
            rows = response?.data?.items?.map((el: any, index) => [
                index + 1,
                el?.course?.title || 'N/A',
                el?.overall != null ? el?.overall + '%' : '-',
                el?.attendance != null ? el?.attendance + '%' : '-',
                el?.assignment_score != null ? el?.assignment_score + '%' : '-',
                el?.assignment_completion != null ? el?.assignment_completion + '%' : '-',
                el?.assignment_ontime != null ? el?.assignment_ontime + '%' : '-',
                el?.course_completion != null ? el?.course_completion + '%' : '-',
                el?.lesson_goal != null ? el?.lesson_goal + '%' : '-',

            ]);
            downloadCSV([headers, ...rows], file_name);
            setIsDownloading(false);
        } catch {
            setIsDownloading(false);
        }
    };

    useEffect(() => {
        getData(1);
    }, [selectedDate?.id]);

    useEffect(() => {
        getData(1);
    }, [searchText]);

    return (
        <div className="ClassDashboard w-full">
            <PageBreadcrumb
                pageNameDefault={translator('ANALYTICS')}
                pages={[{ name: translator("Course Dashboard"), href: '', className: '' }]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div className=" mb-5">
                <div className="flex items-center justify-between p-5 border-b boder-gray-50">
                    <h1 className="text-xl text-gray-800 font-semibold">{translator('Course Dashboard')}</h1>
                </div>
                <div className='p-5 bg-gray-50 min-h-[calc(100vh-126px)]'>
                    <ClassOverview />
                    <div className='mt-5'>
                        <CardContainer>
                            <div className='flex mb-4 items-center'>
                                <Title>{translator('Course List')}</Title>
                                {checkPermission('courseDashboard', 'DL') && <div className='ml-auto flex space-x-4'>
                                    <ButtonDownload title={translator("DOWNLOAD_CSV")} disabled={classes?.total == 0} isDownload={isDownloading} onClick={handleDownloadCSV} />
                                </div>}

                            </div>
                            <div className=''>
                                <SearchInput onSubmit={setSearchText} />
                            </div>
                            <div className='mt-4 overflow-x-auto'>
                                {
                                    loading ?
                                        <div className="flex justify-center items-center w-full min-h-[366px]">
                                            <CircleSpin color="text-primary-500" />
                                        </div> :
                                        classes?.items?.length > 0 ?
                                            <Table
                                                columns={columns}
                                                data={classes?.items}
                                                rowProps={{
                                                    className:
                                                        'bg-white hover:bg-gray-50 cursor-pointer',
                                                }}
                                                rowClass={(row) => row?.id === ''
                                                    ? 'bg-white hover:bg-gray-50 cursor-pointer bg-gray-50'
                                                    : 'bg-white hover:bg-gray-50 cursor-pointer'
                                                }
                                                headerProps={{
                                                    className: 'bg-gray-50 text-gray-900 text-sm py-5',
                                                }}
                                                headerRowClass="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                                className="min-w-full divide-y divide-gray-200"
                                                onClickRow={() => { }}
                                                onDoubleClickRow={() => { }}
                                                selectedRemove={() => { }}
                                            /> :
                                            <div className="flex justify-center items-center w-full min-h-[366px]">
                                                <NoContentWithImage />
                                            </div>
                                }
                            </div>
                            {classes?.total > 0 && <div className="flex items-center justify-between border-t border-ooolab_bar_color mt-4">
                                <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                                    {t('SHOWING_1_50_OF_TOTAL_PAGE', {
                                        fromTo: getIndexPage(classes.page, classes.per_page, classes.items.length),
                                        totalPage: classes?.total,
                                    })}
                                </p>
                                <div className="mt-4">
                                    <TablePagination
                                        onClickPagination={handlePagination}
                                        perPage={classes?.per_page}
                                        total={classes?.total}
                                        forcePage={classes?.page - 1}
                                    />
                                </div>
                            </div>}
                        </CardContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClassDashboard;
