import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { timeList } from "constant/workspace.const";
import { CardContainer } from "components/Reports/components/CardContainer";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { ColumnWithLooseAccessor } from "react-table";
import SearchInput from "components/Reports/components/SearchInput";
import Table from "components/Table";
import TablePagination from "components/V2/Pagination/Pagination";
import ButtonDownload from 'components/Button/ButtonDownload';
import { NoContentWithImage } from "components/NoContent/NoContent";
import { checkPermission, formatThousand, getIndexPage, getValueOrSign, timeConvert } from "utils/commonFun";
import { downloadCSV } from "components/Reports/components/common";
import { LinkCustom } from "components/V2/Table/TableCustom";
import learnerReportService from "services/reports/learner-report.service";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import { CircleInformationIcon } from "assets/icon";
import { TooltipCustom } from "components/Tooltip/Tooltip";

export const StudentAssignmentsList = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, studentId: string } = useParams();
    const [selectedDate, setSelectDate] = useState(timeList[1]);
    const [loading, setLoading] = useState(true);
    const [assignment, setAssignment] = useState<any>();
    const [searchText, setSearchText] = useState<string>(null);

    const search = useLocation()?.search;
    const type = new URLSearchParams(search).get('type');

    const columns: ColumnWithLooseAccessor[] = useMemo(
        () => [
            {

                Header: () => (
                    <p className="text-left pl-2">
                        {translator('Assignment Name')}
                    </p>
                ),
                Cell: ({ row }: { row: any }) => {
                    return (
                        <div className="grid">
                            <div
                                className="text-sm text-primary-500 font-semibold cursor-pointer truncate max-w-[220px]"
                            >{row.values?.name}</div>
                        </div>
                    );
                },
                accessor: 'name',
                width: '120'
            },
            {
                Header: () => <p className="">{translator('Course')}</p>,
                accessor: 'course',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800 truncate max-w-[220px]">
                            {d.value?.title}
                        </p>
                    );
                },
                width: '120'
            },
            {
                Header: () => <p className="">{translator('Grade')}</p>,
                accessor: 'avg_grade',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
            },
            {
                Header: () => <p className="">{translator('Completion')}</p>,
                accessor: 'avg_completion',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {getValueOrSign(d.value)}
                        </p>
                    );
                },
            },
            {
                Header: () => <p className="">{translator('Attempts')}</p>,
                accessor: 'avg_attempt',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {formatThousand(d.value)}
                        </p>
                    );
                },
            },
            {
                Header: () => <>
                    <div className='flex items-center gap-1 max-w-sm '>
                        <p className="">{t('Time Spent')}</p>
                        <div className="w-4 h-4 cursor-pointer flex items-center"
                            data-for="TOOLTIPS_total_time_spent"
                            data-tip={t('Time Spent currently records all time spent specifically doing H5P content')}>
                            <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                            <TooltipCustom
                                classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                                id="TOOLTIPS_total_time_spent"
                                content={t('Time Spent currently records all time spent specifically doing H5P content')}
                                place="top"
                                type="light"
                            />
                        </div>
                    </div>
                </>,
                accessor: 'total_time_spent',
                Cell: (d: any) => {
                    return (
                        <p className="text-gray-800">
                            {timeConvert((d?.value || 0) / 60)}
                        </p>
                    );
                },
            }
        ],
        []
    );

    const getData = async (page = 1) => {
        try {
            setLoading(true);
            const payload: any = {
                page,
                studentId: params?.studentId,
                q: searchText
            }
            if (!payload.q) {
                delete payload.q;
            }
            let response;
            if (type === 'learning-group') {
                response = await learnerReportService.assignmentsGroup(params.id, payload);
            } else {
                response = await learnerReportService.assignments(params.id, payload);
            }
            setAssignment(response?.data);
            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const handlePagination = (page: number) => {
        getData(page);
    }

    const handleDownloadCSV = async () => {
        let response;
        const payload = {
            page: assignment?.page,
            studentId: params?.studentId,
        }
        if (type === 'learning-group') {
            response = await learnerReportService.assignmentsGroup(params.id, payload);
        } else {
            response = await learnerReportService.assignments(params.id, payload);
        }
        let headers: string[], rows: string[][];
        let file_name = `Assignments report`;
        headers = [
            'No.',
            'Assignment Name',
            'Course',
            'Grade',
            'Completion',
            'Attempts',
            'Time Spent'
        ];
        rows = response?.data?.items?.map((el: any, index) => [
            index + 1,
            el?.name || 'N/A',
            el?.course?.title || 'N/A',
            el?.avg_grade != null ? el?.avg_grade + '%' : '-',
            el?.avg_completion != null ? el?.avg_completion + '%' : '-',
            el?.avg_attempt != null ? el?.avg_attempt : '-',
            el?.avg_time_spent != null ? timeConvert(el?.avg_time_spent / 60) : '-',
        ]);
        downloadCSV([headers, ...rows], file_name);
    };


    useEffect(() => {
        getData(1);
    }, [selectedDate?.id, searchText]);

    return <>
        <div className='my-5'>
            <CardContainer>
                <div className='flex mb-4 items-center'>
                    <Title>{translator('Assignment List')}</Title>
                    {checkPermission('courseDashboard', 'DL') && <div className='ml-auto flex space-x-4'>
                        <ButtonDownload title="Download CSV" isDownload={false} disabled={assignment?.items?.length == 0 || assignment == null} onClick={handleDownloadCSV} />
                    </div>}

                </div>
                <div className=''>
                    <SearchInput onSubmit={setSearchText} />
                </div>
                <div className='mt-4 overflow-auto'>
                    {
                        loading ?
                            <div className="flex justify-center items-center w-full min-h-[566px]">
                                <CircleSpin color="text-primary-500" />
                            </div> :
                            assignment?.items?.length > 0 ?
                                <Table
                                    columns={columns}
                                    data={assignment?.items}
                                    rowProps={{
                                        className:
                                            'bg-white hover:bg-gray-50 cursor-pointer',
                                    }}
                                    rowClass={(row) => row?.id === ''
                                        ? 'bg-white hover:bg-gray-50 cursor-pointer bg-gray-50'
                                        : 'bg-white hover:bg-gray-50 cursor-pointer'
                                    }
                                    headerProps={{
                                        className: 'bg-gray-50 text-gray-900 text-sm py-5',
                                    }}
                                    headerRowClass="px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                    className="min-w-full divide-y divide-gray-200"
                                    onClickRow={() => { }}
                                    onDoubleClickRow={() => { }}
                                    selectedRemove={() => { }}
                                /> :
                                <div className="flex justify-center items-center w-full max-h-[220px]">
                                    <NoContentWithImage />
                                </div>
                    }
                </div>
                {assignment?.total > 0 && <div className="flex items-center justify-between border-t border-ooolab_bar_color mt-4">
                    <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                        {t('SHOWING_1_50_OF_TOTAL_PAGE', {
                            fromTo: getIndexPage(assignment.page, assignment.per_page, assignment.items.length),
                            totalPage: assignment?.total,
                        })}
                    </p>
                    <div className="mt-4">
                        <TablePagination
                            onClickPagination={handlePagination}
                            perPage={assignment?.per_page}
                            total={assignment?.total}
                            forcePage={assignment?.page - 1}
                        />
                    </div>
                </div>}
            </CardContainer>
        </div>
    </>
}