import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import EnrollmentTable from './EnrollmentTable';
import { useLocation, useParams } from 'react-router-dom';
import Pagination from 'components/V2/Pagination';
import { getIndexPage } from 'utils/commonFun';
import workspaceService from 'services/workspace.service';
import EnrollmentFilter from './EnrollmentFilter';
import debounce from 'lodash/debounce';

export default function UserList() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [indexPage, setIndexPage] = useState('1-50');
  const [enrollmentList, setEnrollmentList] = useState([]);
  const [filter, setFilter] = useState(undefined)

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 20,
    total: 0,
  });
  const params: { id, userId } = useParams();
  const workspaceId = params?.id;
  const userId = params?.userId

  const getEnrollmentList = (page: number = 1, filter = undefined) => {
    const bodyRequest = {
      ...pagination,
      page: page,
      ...filter,
    };
    delete bodyRequest.total;
    setLoading(true);
    workspaceService
      .getStudentCourseEnrollmets(workspaceId, userId, bodyRequest)
      .then((res: any) => {
        if (res) {
          const { items, page, total, per_page } = res.data;
          setEnrollmentList(items);
          setPagination({
            page: page,
            per_page: per_page,
            total: total
          });
          setIndexPage(getIndexPage(page, 20, items.length));
        }
      })
      .finally(() => setLoading(false))
  };

  const handlePagination = (page: number) => {
    setLoading(true);
    getDebounceEnrollmentList(page, filter)
  };


  const getDebounceEnrollmentList = useCallback(
    debounce((page, filter) => getEnrollmentList(page, filter), 500),
    []
  );

  useEffect(() => {
    getDebounceEnrollmentList(1, filter);
  }, [filter]);

  const onChangeFilter = (filter) => {
    setFilter(filter)
  };

  return (
    <div className="w-full h-full">
      <div className='pb-5 border-b border-gray-300 -mx-5 px-5'>
        <div className='text-lg font-semibold'>{t("ENROLLMENT.ENROLLMENT")}</div>
        <EnrollmentFilter onChangeFilter={onChangeFilter} />
      </div>
      <div className="h-[calc(100vh-360px)] custom-scrollbar overflow-y-auto relative mt-5">
        {loading ? (
          <div className="h-[calc(100vh-360px)] flex items-center justify-center mt-4">
            <CircleSpin color="text-primary-500" />
          </div>
        ) : (
          <EnrollmentTable workspaceId={workspaceId} dataTable={enrollmentList} />
        )}
      </div>
      <Pagination
        indexPage={indexPage}
        page={pagination?.page}
        per_page={pagination?.per_page}
        total={pagination.total}
        callback={handlePagination}
      />
    </div>
  );
}
