import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SelectBox from 'components/V2/SelectBox';
import { useDebouncedState } from 'hooks/useDebounce';
import { WorkspaceService } from 'services';

type SelectAssociatedCoursesProps = {
    selectedItems?: any[];
    onSelect: Dispatch<SetStateAction<any[]>>;
};

export const SelectAssociatedCourses: FC<SelectAssociatedCoursesProps> = ({ selectedItems, onSelect }) => {
    const { t } = useTranslation();
    const params: { id: string; } = useParams();

    const workspaceId: string = params?.id;

    const [list, setList] = useState<any>([]);
    const [selected, setSelected] = useState<any>([]);
    const [inputSearch, setInputSearch] = useDebouncedState('');
    const [pagination, setPagination] = useState<any>({ page: 1 });

    const fetchList = async (page) => {
        let payload: any = {
            title: inputSearch,
            page: page ? page : pagination.page,
            per_page: 20,
            order: 'desc',
            sort_by: 'updated_at',
        };

        if (!payload?.title) delete payload.title;

        const response = await WorkspaceService.getCoursesList(workspaceId, payload);

        if (response) {
            const formattedData = response?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.title,
                    value: item?.id,
                };
            });
            if (page === 1) setList(formattedData);
            else setList([...list, ...formattedData]);
            setPagination({ ...pagination, total: response?.total, page });
        }

        return [];
    };

    useEffect(() => {
        fetchList(1);
    }, [inputSearch]);

    return (
        <SelectBox
            label={t('ASSOCIATED_COURSES')}
            isMulti
            onSearch={setInputSearch}
            onSelect={(options) => {
                setSelected(options);
                onSelect?.(options);
            }}
            data={list}
            selectedItems={selected}
            total={pagination?.total}
            showmore={() => fetchList(pagination?.page + 1)}
        />
    );
};
