import React, { useEffect, useContext } from 'react';
import { Tab } from '@headlessui/react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import DefaultThumb from 'assets/img/defaultThumb.png'
import avatarImg from 'assets/avatar_option.png';
import calendarSVG from 'assets/SVG/calendar.svg';
import clockSVG from 'assets/SVG/clock.svg';
import TeachService from 'services/teach.services';
import { classNames, getPeriodTime, getFullDateDisplay, openUrl } from 'utils/commonFun';
import Timer from './Timer';
import ButtonComponent from 'components/Button/ButtonComponent';
import { UserContext } from 'contexts/User/UserContext';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { CheckCircleIcon, ClipboardCheckIcon } from '@heroicons/react/outline';
import { Toast } from 'utils/sweetAlert';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import workspaceService from 'services/workspace.service';
import moment from 'moment';
import { checkPermission } from 'utils/commonFun';
import courseService from 'services/course.service';
import { COURSE_TYPE } from 'components/Workspace/Course/CoursesList/constants';
import ModalSessionDetail from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/ModalSessionDetail';


const tabList = ['TODAY', 'UPCOMING', 'PAST'];

export const status = {
    offline: 0,
    online: 1,
    assessment: 2,
};

const SessionList: React.FC = () => {
    const history = useHistory();
    const params: any  = useParams();
    const { state }: any = useLocation();
    const [loading, setLoading] = React.useState(true);
    const [tzNumber, setTzNumber] = React.useState(null);
    const [isOpenModal, setOpenModal] = React.useState(false);
    const [valChild, setValChild] = React.useState<any>({});
    const [tabSelect, setTabSelect] = React.useState(1);
    const [dataToday, setDataToday] = React.useState([]);
    const [dataUpcoming, setDataUpcoming] = React.useState([]);
    const [dataPast, setDataPast] = React.useState([]);
    const [paginationToday, setPaginationToday] = React.useState({
        page: 1,
        per_page: 10,
        sort_by: 'start_time',
        order: 'asc',
        total: 0,
        has_student: true,
        has_teacher: true,
    });
    const [paginationUpcoming, setPaginationUpcoming] = React.useState({
        page: 1,
        per_page: 10,
        sort_by: 'start_time',
        order: 'asc',
        total: 0,
        has_student: true,
        has_teacher: true,
    });
    const [paginationPast, setPaginationPast] = React.useState({
        page: 1,
        per_page: 10,
        sort_by: 'start_time',
        order: 'desc',
        total: 0,
        has_student: true,
        has_teacher: true,
    });
    const { t: translator } = useTranslation();
    const workspace: any = useParams();
    const [viewSyllabus, setViewSyllabus] = React.useState<boolean>(false);
    const [lessonDetails, setLessonDetails] = React.useState<any>();

    const {
        userState: { result },
    } = useContext(UserContext);

    const calculatorTime = (time: string) => {
        const date: any = dayjs.utc().tz(result?.time_zone);
        const dateInput: any = dayjs.utc(time).tz(result?.time_zone);
        const millis: number = dateInput.valueOf() - date.valueOf();
        return Math.floor(millis / 1000);
    };

    const durationTime = (startTime, endTime) => {
        const start: any = new Date(startTime).getTime();
        const end: any = new Date(endTime).getTime();
        const millis: number = end - start;
        const minutes = Math.floor((millis / 1000 / 60) % 60);
        let dayCount = Math.floor(millis / (1000 * 3600 * 24));
        const hours = Math.floor((millis / 1000 / 60 / 60) % 24);
        if (hours > 0) {
            return ` (${(dayCount > 0 && dayCount * 24 + hours) || hours}hrs ${minutes}mins)`;
        }
        return ` (${minutes}mins)`;
    };

    const checkJoinClass = (el: any) => {
        let flg: boolean = true;
        // within 15 minus, lesser end_time
        if (dayjs().valueOf() >= dayjs?.utc(el.start_time).tz(result?.time_zone).valueOf() - 900000 && dayjs().valueOf() < dayjs?.utc(el.end_time)?.tz(result?.time_zone).valueOf())
            flg = false;
        return flg;
    };

    const handleChangeTabs = (index: number) => {
        setTabSelect(index);
    };

    const handleOpenModal = (val) => {
        setViewSyllabus(false);
        setOpenModal(true);
        setValChild(val);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleJoinClass = async (val, event?) => {
        event.stopPropagation();
        const data: any = await fetchVcLink(val.my_class.id, val.id);
        if (data?.vc_link) {
            window.open(data.vc_link, '_blank')?.focus();
        } else {
            Toast.fire({
                icon: 'error',
                title: data.description,
            });
        }
    };

    const checkStatusClass = (startDate, endDate) => {
        let label = '';
        if (moment().isBefore(moment(startDate))) {
            label = translator('DASHBOARD.TEACHER.START_IN');
        }
        if (moment().isAfter(moment(endDate))) {
            label = translator('DASHBOARD.TEACHER.ENDED');
        }
        if (moment(new Date()).isBetween(moment(startDate), moment(moment(endDate)))) {
            label = 'In-progress';
        }
        return label;
    };

    const handleJoinSession = (session) => {
        courseService.getSessionDetail(params?.id, session?.course?.id, session?.id).then(res => {
            let vcLink = res?.data?.vc_link;
            if (vcLink) {
                openUrl(vcLink)
            }
            else handleOpenModal(session)
        });
    }

    const renderTabToday = (data: any, type: string) => {
        if (data?.length == 0 && !loading) return <NoContentWithImage content={translator('DASHBOARD.TEACHER.NO_SESSIONS')} />;
        return data?.map((el) => (
            <div
                key={el.id}
                onClick={() => checkPermission('instructorDashboard', 'R') ? handleOpenModal(el) : {}}
                className="grid grid-cols-4 lg:grid-cols-10 gap-4 py-3 px-4 xs:p-2 mt-5 rounded-xl bg-white shadow-ooolab_box_shadow_container cursor-pointer"
            >
                <div className="col-span-2 lg:col-span-3 flex items-center">
                    <img className="rounded-lg xs:mr-2 mr-4 lg:mr-3 w-14 h-14 object-cover" src={el?.course?.thumbnail || DefaultThumb} alt="img" />
                    <div className="w-2/3">
                        <p className="text-ooolab_sm text-ooolab_dark_1 font-bold leading-5">{el?.course?.title}</p>
                        <div className="flex items-center space-x-2">
                            <div className="text-xs text-ooolab_dark_2 leading-6 truncate w-2/3 capitalize">
                                {el.course?.type?.map(label=> COURSE_TYPE?.[label])?.join(', ')}
                            </div>
                            {el.is_graded && (
                                <div className="flex items-center space-x-1 w-1/3">
                                    <CheckCircleIcon className="w-4 h-4 text-ooolab_green_2" />
                                    <span className="text-xs text-ooolab_green_2">{translator('DASHBOARD.TEACHER.GRADED')}</span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-span-2 lg:col-span-2 lg:col-start-4 flex items-center">
                    <img className="rounded-full mr-2 w-7 h-7" src={result.avatar_url || avatarImg} alt="img" />
                    <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5">{result.display_name}</p>
                </div>
                <div className="col-span-2 lg:col-span-2 lg:col-start-6 ml-28 xs:ml-0 lg:ml-0 flex flex-col justify-center">
                    <div className="flex items-center">
                        <img className="mr-2 inline-block" src={calendarSVG} alt="calendarSVG" />
                        <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5">
                            {getFullDateDisplay(el?.start_time, result?.time_zone)}
                        </p>
                    </div>
                    <div className="flex items-center">
                        <img className="mr-2 inline-block" src={clockSVG} alt="clockSVG" />
                        <div className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5">
                            <p className="lg:inline block">{getPeriodTime(el.start_time, el.end_time, result?.time_zone)}</p>
                            <p className="lg:inline block">{durationTime(el.start_time, el.end_time)}</p>
                        </div>
                    </div>
                </div>
                <div className="col-span-2 lg:col-span-3 lg:col-start-8 border-0 lg:border-l border-ooolab_blue_4 flex justify-between lg:justify-end items-center">
                    <div className="md:mr-4 lg:mr-8">
                        <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5 text-center">
                            {checkStatusClass(el.start_time, el.end_time)}
                        </p>
                        <div className="text-ooolab_dark_1 text-center">
                            <Timer seconds={calculatorTime(el.start_time)} translator={translator} />
                        </div>
                    </div>
                    <div>
                        <ButtonComponent
                            title={translator('Join')}
                            classStyle={`xs:p-1 xs:text-sm primary-button
                                ${checkJoinClass(el) ? 'disable' : ''}
                            `}
                            onClickButton={() => handleJoinSession(el)}
                            disable={checkJoinClass(el)}
                        />
                    </div>
                </div>
            </div>
        ));
    };

    const renderTab = (data: any, type: string) => {
        return data?.length > 0 ? data?.map((elm: any, indexx: number) => (
            <div key={indexx} className="mb-8">
                <h1 className="mt-4 text-xl text-ooolab_dark_1 leading-7 font-semibold">{elm.date == dayjs().format('DD/MM/YYYY') && type == 'upcoming' ? translator('DASHBOARD.TEACHER.TODAY') : elm.date}</h1>
                {elm.data.map((el: any) => (
                    <div
                        key={el.id}
                        onClick={() => checkPermission('instructorDashboard', 'R') && elm.date == dayjs().format('DD/MM/YYYY') && type == 'upcoming' ? handleOpenModal(el) : {}}
                        className="grid grid-cols-4 lg:grid-cols-10 gap-0 py-3 px-4 xs:p-2 mt-5 rounded-xl bg-white shadow-ooolab_box_shadow_container"
                    >
                        <div className="col-span-2 lg:col-span-3 flex items-center">
                            <img
                                className="rounded-lg xs:mr-2 mr-4 lg:mr-3 w-14 h-14 object-cover"
                                src={el?.course?.thumbnail || DefaultThumb}
                                alt="img"
                            />
                            <div>
                                <p className="text-ooolab_sm text-ooolab_dark_1 font-bold leading-5">{el?.course?.title}</p>
                                <div className="flex items-center justify-between space-x-2">
                                    <p className="text-xs text-ooolab_dark_2 leading-6 capitalize">
                                        {el.course?.type.map(label=> COURSE_TYPE?.[label])?.join(', ')}
                                    </p>
                                    {el?.is_graded && (
                                        <div className="flex items-center space-x-1">
                                            <CheckCircleIcon className="w-4 h-4 text-ooolab_green_2" />
                                            <span className="text-xs text-ooolab_green_2">
                                                {translator('DASHBOARD.TEACHER.GRADED')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 lg:col-span-2 lg:col-start-4 flex items-center justify-center w-full">
                            <img className="rounded-full mr-2 w-7 h-7" src={result.avatar_url || avatarImg} alt="img" />
                            <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5">{result.display_name}</p>
                        </div>
                        <div className="col-span-2 lg:col-span-2 lg:col-start-6 ml-28 xs:ml-0 lg:ml-0 flex flex-col justify-center">
                            <div className="flex items-center">
                                <img className="mr-2 inline-block" src={calendarSVG} alt="calendarSVG" />
                                <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5">
                                    {getFullDateDisplay(el?.start_time, result?.time_zone)}
                                </p>
                            </div>
                            <div className="flex items-center">
                                <img className="mr-2 inline-block" src={clockSVG} alt="clockSVG" />
                                <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5">
                                    <p className="lg:inline block">{getPeriodTime(el?.start_time, el?.end_time, result?.time_zone)}</p>
                                    <p className="lg:inline block">{durationTime(el.start_time, el.end_time)}</p>
                                </p>
                            </div>
                        </div>
                        {elm.date == dayjs().format('DD/MM/YYYY') && type == 'upcoming' ? (
                            <div className="col-span-2 lg:col-span-3 lg:col-start-8 border-0 lg:border-l border-ooolab_blue_4 flex justify-between lg:justify-end items-center">
                                <div className="md:mr-4 lg:mr-8">
                                    <p className="text-ooolab_sm text-ooolab_dark_1 font-normal leading-5 text-center">
                                        {checkStatusClass(el.start_time, el.end_time)}
                                    </p>
                                    <div className="text-ooolab_dark_1 text-center">
                                        <Timer seconds={calculatorTime(el.start_time)} translator={translator} />
                                    </div>
                                </div>
                                <div>
                                    <ButtonComponent
                                        title={translator('Join')}
                                        classStyle={`xs:p-1 xs:text-sm primary-button
                                    ${checkJoinClass(el) ? 'disable' : ''}
                                `}
                                        onClickButton={() => handleJoinSession(el)}
                                        disable={checkJoinClass(el)}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="max-w-[240px] ml-auto pl-[12px] col-span-2 lg:col-span-3 lg:col-start-8 border-0 lg:border-l border-ooolab_blue_4 flex gap-4 items-center lg:justify-end">
                                {(checkPermission('instructorDashboard', 'R') ||
                                    checkPermission('instructorDashboard', 'R') ||
                                    checkPermission('instructorDashboard', 'R')) && (
                                    <ButtonComponent
                                        title={translator('View Details')}
                                        classStyle="xs:p-1 xs:text-sm secondary-button"
                                        onClickButton={() => handleOpenModal(el)}
                                    />
                                )}
                                {checkPermission('instructorDashboard', 'U') && type == 'past' ? (
                                    <ButtonComponent
                                        title={translator('EVALUATE.EVALUATE')}
                                        classStyle="xs:p-1 xs:text-sm secondary-button !bg-primary-500 !text-white"
                                        onClickButton={() => handleGrade(el)}
                                        icon={<ClipboardCheckIcon className="w-4 h-4 mr-1" />}
                                    />
                                ) : null}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        )) : !loading && <NoContentWithImage content={translator('DASHBOARD.TEACHER.NO_SESSIONS')} />;
    };

    const fetchVcLink = async (classId, sessionId) => {
        try {
            const res = await TeachService.getVcLinkService({
                workspaceId: workspace.id,
                sessionId,
                classId,
            });
            return res.data || null;
        } catch (error) {
            return error.response.data.error;
        }
    };

    const handleGrade = (val) => {
        history.push(`/workspace/${workspace.id}/instructor-dashboard/gradebook/${val.course?.id}/${val.id || val?.session_id}`, {
            nameclass: val.course?.title,
            tabSelect: tabSelect,
        });
    };

    const fetchDataToday = (_page?: number) => {
        setLoading(true);
        const params = {
            order: 'asc',
            start_date: `${dayjs().tz(result?.time_zone).format('YYYY-MM-DD')}`,
            end_after: new Date(),
            page: _page || paginationToday.page,
        };
        TeachService.getIntructorSessions(workspace.id, params)
            .then((res) => {
                const { items, page, total } = res.data;
                const tempItems = items.map((el: any) => ({
                    ...el,
                    session_type: status[el.type],
                }));
                setPaginationToday({ ...paginationToday, page, total });
                setDataToday([...dataToday, ...tempItems]);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const fetchDataUpcoming = (_page?: number) => {
        setLoading(true);
        // beginning_of_tomorrow (2021-04-26T00:00:00Z)
        const tomorrow = dayjs()
            .add(1, 'day')
            .tz(result?.time_zone || 'UTC')
            .format('YYYY-MM-DD');
        const today = dayjs()
            .tz(result?.time_zone || 'UTC')
            .format('YYYY-MM-DDTHH:mm:ss');
        const params = {
            order: 'asc',
            end_after: today + tzNumber,
            page: _page || paginationUpcoming.page,
        };
        TeachService.getListUpcomingService(workspace.id, params)
            .then((res) => {
                const { items, page, total } = res.data;
                setPaginationUpcoming({ ...paginationUpcoming, page, total });
                if (total == 0) {
                    setLoading(false);
                }
                Promise.all(
                    items.map((el) =>
                        TeachService.getListTodayService(workspace.id, {
                            start_date: el,
                            end_after: dayjs().tz(result?.time_zone || 'UTC').format('YYYY-MM-DD') === el 
                                ? dayjs()
                                    .tz(result?.time_zone || 'UTC')
                                    .format('YYYY-MM-DDTHH:mm:ss.SSS') 
                                : undefined,
                            order: 'asc',
                        })
                    )
                ).then(([...res]) => {
                    let arr = res.map((item: any, i: number) => ({
                        date: getFullDateDisplay(items[i] + 'T00:00:00' + tzNumber, result?.time_zone),
                        data: item.data.items.map((el: any) => ({
                            ...el,
                            session_type: status[el.type],
                        })),
                    }));
                    setDataUpcoming([...dataUpcoming, ...arr]);
                    setLoading(false);
                });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const fetchDataPast = (_page?: number) => {
        setLoading(true);
        // beginning_of_today (2021-04-25T00:00:00Z)
        const yesterday = dayjs()
            .tz(result?.time_zone || 'UTC')
            .format('YYYY-MM-DD');
        const params = {
            order: 'desc',
            end_before: new Date(),
            page: _page || paginationPast.page,
        };
        TeachService.getListPastService(workspace.id, params)
            .then((res) => {
                const { items, page, total } = res.data;
                setPaginationPast({ ...paginationPast, page, total });
                if (total == 0) {
                    setLoading(false);
                }
                Promise.all(
                    items.map((el) => {
                        let payload: any = {
                            start_date: el
                        }
                        if (moment().format('YYYY-MM-DD') == el) {
                            payload = {
                                ...payload,
                                end_before: new Date()
                            }
                        }
                        return TeachService.getIntructorSessions(workspace.id, payload);
                    }

                    )
                ).then(([...res]) => {
                    let arr = res.map((item: any, i: number) => ({
                        date: getFullDateDisplay(items[i] + 'T00:00:00' + tzNumber, result?.time_zone),
                        data: item.data.items.map((el: any) => ({
                            ...el,
                            session_type: status[el.type],
                        })),
                    }));
                    setDataPast([...dataPast, ...arr]);
                    setLoading(false);
                });
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (state?.tabSelected) setTabSelect(state?.tabSelected);
        return () => {
            setDataToday([]);
            setDataPast([]);
            setDataUpcoming([]);
            setLessonDetails({});
        };
    }, []);

    useEffect(() => {
        if (!tzNumber) return;
        setDataToday([]);
        setDataPast([]);
        setDataUpcoming([]);
        setLessonDetails({});
        switch (tabSelect) {
            case 0: {
                setDataToday([]);
                fetchDataToday();
                break;
            }
            case 1: {
                setDataUpcoming([]);
                fetchDataUpcoming();
                break;
            }
            case 2: {
                setDataPast([]);
                fetchDataPast();
                break;
            }
            default:
                break;
        }
    }, [tabSelect, tzNumber, result?.time_zone]);

    useEffect(() => {
        if (result?.time_zone) {
            setTzNumber(dayjs().tz(result?.time_zone).format('Z'));
        } else {
            setTzNumber(dayjs().tz('UTC').format('Z'));
        }
    }, [result?.time_zone]);

    const handleViewSyllabus = () => {
        setViewSyllabus(true);
        if (valChild?.lesson_id) {
            workspaceService.getLessonDetail(workspace.id, valChild.lesson_id).then((res) => {
                if (res) setLessonDetails(res);
            });
        }
    };

    return (
        <div className="TeachDashboard px-5 py-3">
            <div className="py-4 bg-gray-50 rounded-xl min-h-[300px]">
                <Tab.Group onChange={handleChangeTabs} defaultIndex={state?.tabSelected || 1}>
                    <Tab.List className="mb-4 px-5">
                        {tabList.map((el, index) => (
                            <Tab
                                key={index}
                                className={({ selected }) =>
                                    classNames(
                                        'text-ooolab_base font-bold leading-6 mr-5',
                                        selected ? 'text-primary-500' : 'text-ooolab_dark_2',
                                        el == 'TODAY' ? 'hidden' : ''
                                    )
                                }
                            >
                                {translator('DASHBOARD.TEACHER.' + el)}
                                {el === 'TODAY' ? ` (${paginationToday.total})` : null}
                            </Tab>
                        ))}
                    </Tab.List>
                    <hr className="mt-4 divide-y-2 divide-ooolab_blue_4" />
                    <Tab.Panels className="px-5">
                        <Tab.Panel>
                            {/* @ts-ignore */}
                            {checkPermission('instructorDashboard', 'R') && <InfiniteScroll
                                dataLength={dataToday.length}
                                next={() => dataToday.length < paginationToday.total && fetchDataToday(paginationToday.page + 1)}
                                hasMore={dataToday.length !== paginationToday.total}
                                loader={
                                    loading && (
                                        <div className="min-h-[20vh] flex items-center justify-center mt-4">
                                            <CircleSpin color="text-primary-500" />
                                        </div>
                                    )
                                }
                                height={dataToday.length > 6 ? '70vh' : '100%'}
                                className="custom-scrollbar"
                            >
                                {renderTabToday(dataToday, 'today')}
                            </InfiniteScroll>}
                        </Tab.Panel>
                        <Tab.Panel>
                            {/* @ts-ignore */}
                            {checkPermission('instructorDashboard', 'R') && <InfiniteScroll
                                dataLength={dataUpcoming.length}
                                next={() =>
                                    dataUpcoming.length < paginationUpcoming.total && fetchDataUpcoming(paginationUpcoming.page + 1)
                                }
                                hasMore={dataUpcoming.length !== paginationUpcoming.total}
                                loader={
                                    loading && (
                                        <div className="min-h-[20vh] flex items-center justify-center mt-4">
                                            <CircleSpin color="text-primary-500" />
                                        </div>
                                    )
                                }
                                height={dataUpcoming.length > 6 ? '70vh' : '100%'}
                                className="custom-scrollbar"
                            >
                                {renderTab(dataUpcoming, 'upcoming')}
                            </InfiniteScroll>}
                        </Tab.Panel>
                        <Tab.Panel>
                            {/* @ts-ignore */}
                            {checkPermission('instructorDashboard', 'R') && <InfiniteScroll
                                dataLength={dataPast.length}
                                next={() => dataPast.length < paginationPast.total && fetchDataPast(paginationPast.page + 1)}
                                hasMore={dataPast.length !== paginationPast.total}
                                loader={
                                    loading && (
                                        <div className="min-h-[20vh] flex items-center justify-center mt-4">
                                            <CircleSpin color="text-primary-500" />
                                        </div>
                                    )
                                }
                                height={dataPast.length > 6 ? '70vh' : '100%'}
                                className="custom-scrollbar"
                            >
                                {renderTab(dataPast, 'past')}
                            </InfiniteScroll>}
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div>
            {isOpenModal && (
                <ModalSessionDetail
                    sessions={[valChild]}
                    isOpen={isOpenModal}
                    refreshData={() => {
                        handleCloseModal();
                    }}
                    onClose={() => handleCloseModal()}
                />
            )}
        </div>
    );
};

export default SessionList;
