import { FC, useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import './DetailsFilePopup.scss';
import Details from './Details';
import Materials from './Materials';
import learningPathService from 'services/learningPath.service';
interface LearningPathDetailProps {
    item: any;
}

const LearningPathDetail: FC<LearningPathDetailProps> = ({ item }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [customFields, setCustomFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const TAB = {
        DETAILS: 'details',
        LESSONS: 'lessons',
    };

    const [tab, setTab] = useState(TAB.DETAILS);

    const {
        userState: { result },
    } = useContext(UserContext);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));

    const getCustomFields = async (page: number) => {
        setIsLoading(true);
        try {
            const body = {
                order: 'desc',
                page: page,
                sort_by: 'created_at',
                skip_pagination: true,
            };
            const response = await learningPathService.getLearningPathCustomField(params.id, item.id, body );
            if (response) {
                setCustomFields(response?.data);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const handleUpdateTag = async (value) => {
        setCustomFields(value);
        await learningPathService.updateCustomFieldLearningPath(params.id, item?.id, {
            custom_fields: value.map((item) => ({ custom_field_id: item.id, ...item })),
            id: item.id,
        });
    };
    
    useEffect(() => {
        getCustomFields(1)
    }, [item]);

    return (
        <>
            <div className="lessonTab">
                <div
                    onClick={() => setTab(TAB.DETAILS)}
                    className={`lessonTab-item ${tab === TAB.DETAILS ? 'active' : 'default'}`}
                >
                    {translator('DETAILS')}
                </div>
                <div
                    onClick={() => setTab(TAB.LESSONS)}
                    className={`lessonTab-item ${tab === TAB.LESSONS ? 'active' : ' default'}`}
                >
                    {translator('LESSONS')}
                </div>
            </div>
            {tab === TAB.LESSONS ? (
                <Materials units={item?.sections}  isLoading={isLoading}/>
            ) : (
                <Details
                    translator={translator}
                    item={item}
                    result={result}
                    customFields={customFields}
                    handleUpdateTag={handleUpdateTag}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default LearningPathDetail;
