import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Tab } from '@headlessui/react';
import { classNames } from 'utils/commonFun';
import WorkspaceSettingsGeneral from './WorkspaceSettingsGeneral/WorkspaceSettingsGeneral';
import RolePermissionsSetting from './RolePermissionsSetting/RolePermissionsSetting';
import { ECommerce } from './ECommerce/ECommerce';
import { CustomProperty } from 'components/WorkspaceSetting/Property';


export const SETTINGS_TAB = (translator) => [
    {
        name: translator('GENERAL'),
        value: 'general',
        index: 0,
    },
    {
        name: translator('E-Commerce'),
        value: 'e-commerce',
        index: 1,
    },
    {
        name: translator('ROLES_PERMISSIONS'),
        value: 'role',
        index: 2,
    },
    {
        name: translator('CUSTOM_PROPERTY.PROPERTIES'),
        value: 'property',
        index: 3,
    },
];

interface WorkspaceSettingProp { }

const WorkspaceSetting: FC<WorkspaceSettingProp> = ({ }) => {
    const params: { id; courseId } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab');
    const [tabSelect, setTabSelect] = useState<any>(null);
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`DASHBOARD.WORKSPACE_SETTING.${str}`, opt));

    useEffect(() => {
        const typeSettings: any = (tab && SETTINGS_TAB(translator)?.find((item) => item?.value === tab)) || SETTINGS_TAB(translator)[0];
        setTabSelect(typeSettings?.index);
    }, [tab]);


    return (
        <>
            <div className="relative text-xs font-semibold">
                <h1 className='text-xl font-semibold text-gray-800 px-5 pt-6'>
                    {translator('WORKSPACE_SETTINGS')}
                </h1>
                <Tab.Group
                    onChange={(tab: number) => {
                        history.push(`/workspace/${params?.id}/setting?tab=${SETTINGS_TAB(translator)[tab].value}`);
                    }}
                    defaultIndex={tabSelect}
                    selectedIndex={tabSelect}
                >
                    <Tab.List className="border-b flex items-end space-x-3 pt-5 px-5">
                        {SETTINGS_TAB(translator)?.map((tab, index) => (
                            <Tab
                                key={index}
                                className={({ selected }) =>
                                    classNames(
                                        'mr-3 px-3 py-3 outline-none font-semibold',
                                        selected ? 'text-blue-500  border-b border-blue-500' : ' text-gray-500'
                                    )
                                }
                            >
                                {tab.name}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="bg-gray-50 min-h-[calc(90vh-35px)]">
                        <Tab.Panel className={'outline-none p-5'}>
                            <WorkspaceSettingsGeneral />
                        </Tab.Panel>
                        <Tab.Panel className={'outline-none p-5'}>
                            <ECommerce />
                        </Tab.Panel>
                        <Tab.Panel className={'outline-none p-5'}>
                            <RolePermissionsSetting />
                        </Tab.Panel>
                        <Tab.Panel className={'outline-none p-5'}>
                            <CustomProperty tab={SETTINGS_TAB(translator)[tabSelect]?.value} />
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </div >
        </>
    );
};

export default WorkspaceSetting;
