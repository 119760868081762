export const ACCEPT_FILE_TYPE_UPLOAD_SCORM = ['.zip', 'application/zip', 'application/x-zip-compressed', 'application/octet-stream'];

export const SCORM_TYPE_STATUS = {
    'not attempted': 'notattempted',
    browsed: 'browsed',
    completed: 'completed',
    failed: 'failed',
    incomplete: 'incomplete',
    passed: 'passed',
    wait: 'wait',
};

export const SCORM_STATUS_ICONS = {
    book: 'book',
    'not attempted': 'square_icon',
    browsed: 'book',
    completed: 'check_square',
    failed: 'timer',
    incomplete: 'pencil_square',
    passed: 'checked',
    wait: 'wait',
};
