import {  useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PreviewContentFullScreen } from '_shared/PreviewContent/PreviewContentFullScreen';
import { MicrophoneIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import { SpeedIcon } from 'assets/icon/SpeedIcon';
import AudioPlayer from '_shared/AudioPlayer';

export const RecordingTask = ({ task }: { task: any }) => {
    const { t: translator } = useTranslation();
    const [previewFile, setPreviewFile] = useState(null);
    const refAudio = useRef(null);
    const [speed, setSpeed] = useState(1);

    const onChangeSpeed = (e, speed) => {
        e.preventDefault();
        e.stopPropagation();
        setSpeed(speed);
        if (refAudio.current) refAudio.current.playbackRate = speed;
    }

    return (
        <div className="text-base">
            <div
                className="submission-description"
                dangerouslySetInnerHTML={{
                    __html: task?.instruction?.replaceAll(/\n/g, "<br>"),
                }}
            ></div>
            <div className='mt-4 bg-gray-50 rounded-2xl p-4'>
                <div className='flex gap-2 '>
                    <MicrophoneIcon className="w-6 h-6 text-gray-800" />
                    <span className=" text-base  font-semibold text-gray-800 ml-2">
                        {translator('Recording')}
                    </span>
                </div>
                <div className=" flex flex-col space-y-2 px-2 rounded-2xl">
                    {!!task?.task_result?.files?.length ? task?.task_result?.files?.map((f, i) => {
                        const file = {
                            ...f,
                            name: f?.file_name,
                            file: f?.file_url,
                            object_type: f?.file_name?.split('.').reverse()?.[0] || null,
                        };
                        return (
                            <AudioPlayer
                                key={file?.file}
                                src={file?.file}
                            />
                        );
                    }) : <p className='text-gray-500 mt-2'>No Recording.</p>}

                   
                </div>
            </div>
            <PreviewContentFullScreen isOpen={!!previewFile} data={previewFile} closeModal={() => setPreviewFile(null)} />
        </div>
    );
};
