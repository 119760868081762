import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import { checkPermission } from 'utils/commonFun';
import { DotsCircleHorizontalIcon } from '@heroicons/react/solid';
import skillService from 'services/skill.service';

interface ActionMenuProp {
    data: any;
    refreshData: Function;
    position?: string;
    isTemplate?: boolean;
}

const ActionMenu: FC<ActionMenuProp> = ({ data, refreshData, position, isTemplate = false }) => {
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const { t: translator } = useTranslation();
    const [isOpenModalDelete, setOpenModalDelete] = useState(false);
    const [hasActiveLearner, setHasActiveLearner] = useState(false);

    const checkHasLearner = () => {
        courseService
            .checkHasActiveLearner({ workspaceId, id: params?.courseId })
            .then((res) => {
                if (res?.data?.has_active_learner) {
                    return setHasActiveLearner(true);
                } else {
                    setHasActiveLearner(false);
                }
            })
            .catch((error) => console.error(error));
    }

    const onDeleteCourse = () => {
        skillService
            .deleteCourseSkill(params?.id, params?.courseId, data?.id)
            .then(() => {
                Toastify.success();
                setOpenModalDelete(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };


    const onDeleteSkillCourseTemplate = () => {
        skillService
            .deleteCourseTemplateSkill(params?.id, params?.courseId, data?.id)
            .then(() => {
                Toastify.success();
                setOpenModalDelete(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };

    useEffect(() => {
        if (isTemplate) return;
        checkHasLearner();
    }, [])

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        action: () => {
                            setOpenModalDelete(true);
                        },
                        isDisabled: !checkPermission('course', 'D'),
                        hide: !checkPermission('course', 'D'),
                    },
                ]}
            />

            {isOpenModalDelete && (
                <DialogComponent
                    title={translator('DELETE_SKILL')}
                    isOpen={isOpenModalDelete}
                    onCloseModal={() => setOpenModalDelete(false)}
                    child={
                        <div className="flex flex-col">
                            {hasActiveLearner && <div className="text-sm text-gray-800 mt-4">
                                {translator('THERE_ARE_LEARNERS_ENROLLED_IN_THIS_COURSE_BASED_ON_THIS_SETTING')}<br />
                                <div className='flex items-center gap-2 ml-1'><span className='text-gray-500 flex items-center gap-2'><DotsCircleHorizontalIcon className='w-1 h-1' />{translator('SKILL')}:</span> {data?.skill_level?.skill?.name}</div>
                                <div className='flex items-center gap-2 ml-1'><span className='text-gray-500 flex items-center gap-2'><DotsCircleHorizontalIcon className='w-1 h-1' />{translator('LEVEL')}:</span> {data?.skill_level?.name}</div>
                                <p className='mt-4' dangerouslySetInnerHTML={{ __html: translator('PLEASE_REVIEW_THE_ENROLLMENT_LIST', { link: `/workspace/${params?.id}/management/courses/${params?.courseId}?tab=people&subtab=learner` }) }}></p>
                            </div>}
                            <div className="mt-2 text-sm text-gray-800">{translator('ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_SKILL')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">

                                <ButtonOutline type="button" className="border !border-primary-500 !text-primary-500" onClick={() => isTemplate ? onDeleteSkillCourseTemplate() : onDeleteCourse()}>
                                    {translator('DELETE')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className='' onClick={() => setOpenModalDelete(false)}>
                                    {translator('CANCEL')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
