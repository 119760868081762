import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserTable from './UserTable';
import { useParams } from 'react-router-dom';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import workspaceMiddleware from 'middleware/workspace.middleware';
import Filter from './FilterUser/FilterUser';
import { ButtonOutline, ButtonPrimary } from '_shared';
import { PlusIcon, XIcon } from '@heroicons/react/outline';
import { Popover } from '@headlessui/react';
import UserIcon from 'assets/icon/UserIcon';
import LearnerPortalIcon from 'assets/icon/LearnerPortalIcon';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateLearnerPortal from '../CreateUser/LearnerPortal';
import CreateUser from '../CreateUser/User';
import { ImportUser } from '../ImportUser/ImportUser';
import { debounce } from 'lodash';
import { LOCATION } from '../constant';
import ModalAddSkill from '../UserDetail/Tab/Skills/ModalAddSkill';
import { TooltipCustom } from 'components/Tooltip/Tooltip';

export default function UserList({ children }) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [studentList, setStudentList] = useState([]);
    const [location, setLocation] = useState(LOCATION.clms)
    const [openModalCreateLearnerPortal, setOpenModalCreateLearnerPortal] = useState(false)
    const [openModalCreateUser, setOpenModalCreateUser] = useState(false);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        status: ["invite", "deactivate", "active"],
        total: 0,
    });
    const params: { id } = useParams();
    const workspaceId = params?.id;
    const [filter, setFilter] = useState(undefined)
    const [userSelected, setUserSelected] = useState<any>([]);
    const [isOpenModalAddSkill, setOpenModalAddSkill] = useState(false);

    const getUserlistDebounce = useCallback(
        debounce((page: number = 1, filter = undefined) => getUserList(page, filter), 500),
        []
    );

    const getUserList = (page: number = 1, filter = undefined) => {
        const bodyRequest = {
            ...pagination,
            page: page || pagination?.page,
            ...filter,
            status: filter?.status || ["invite", "deactivate", "active"],
        };
        delete bodyRequest.total;
        if (!bodyRequest.q) delete bodyRequest.q;
        setLoading(true);
        workspaceMiddleware
            .getWorkspaceMembersMiddleware(workspaceId, bodyRequest)
            .then((res: any) => {
                if (res) {
                    const { items, page, total } = res.data;
                    setStudentList(items);
                    setPagination({ ...pagination, page, total });
                    setIndexPage(getIndexPage(page, 20, items.length));
                }
            })
            .finally(() => setLoading(false))
    };

    const handlePagination = (page: number) => {
        setLoading(true);
        setUserSelected([])
        getUserList(page, filter);
    };

    useEffect(() => {
        getUserlistDebounce(1, filter)
    }, [filter]);

    const onChangeFilter = (filter) => {
        setFilter(filter)
    }

    const isHasParent = useMemo(() => {
        if (!userSelected?.length) return;
        return userSelected?.some((user) => user?.membership?.type === 'parent'
        )

    }, [userSelected])

    return (
        <div className="w-full h-screen">
            <PageBreadcrumb
                pageNameDefault={t('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[{ name: t('DASHBOARD.SIDEBAR.USERS'), href: '', className: '' }]}
            />
            <div className='px-5 py-4 flex justify-between'>
                <Filter onChangeFilter={onChangeFilter} setLocation={setLocation} location={location} />
                {checkPermission('user', 'C') && <div className='mr-2'>
                    <ImportUser />
                </div>}
                {checkPermission('user', 'C') && <Popover className="relative">
                    <Popover.Button>
                        <ButtonPrimary className='h-fit !py-1.5 w-36' type="button" onClick={() => { }}> <PlusIcon className='w-5 h-5  text-white' />{t("USER.NEW_USER")}</ButtonPrimary>
                    </Popover.Button>
                    <Popover.Panel className="absolute z-10 w-52 right-0 bg-white rounded-lg shadow-sm mt-3">
                        <div className='px-4 text-sm w-full cursor-pointer'>
                            <div onClick={() => setOpenModalCreateUser(true)} className="flex gap-1 py-2 items-center"><UserIcon className={''} /> CLMS</div>
                            <div onClick={() => setOpenModalCreateLearnerPortal(true)} className="flex gap-1 py-2 items-center"><LearnerPortalIcon /> {t("USER.LEARNER_PORTAL")}</div>
                        </div>
                    </Popover.Panel>
                </Popover>}

            </div>
            {userSelected?.length ? <div className='px-5 py-4 flex items-center'>
                <div className=' flex-none'> <XIcon className='w-4 h-4 text-gray-500 mr-3 cursor-pointer' onClick={() => setUserSelected([])} /> </div>
                <div className=' text-sm text-dark-800 font-normal mr-4'>{userSelected?.length} {t('USER.USERS_SELECTED')}</div>
                <div>
                    <ButtonOutline data-for="add_skills"
                        data-tip={t('Only learners can be assigned skills')}
                        id="add_skills" type="button" size="M"
                        onClick={() => { setOpenModalAddSkill(true) }}
                        className="buttonOutline--isPrimary"
                        disabled={isHasParent}

                    >
                        <PlusIcon className=' w-4 h-4 flex-none mr-1' />
                        {t('USER.ADD_SKILLS')}</ButtonOutline>
                </div>
                {isHasParent && <TooltipCustom
                    classNameContainer="shadow-medium"
                    id="add_skills"
                    content={t('Only learners can be assigned skills')}
                    place="top"
                    type="light"
                />}

            </div> : ''}

            <div className={`px-5`}>
                <div className="h-[calc(100vh-190px)] custom-scrollbar overflow-y-auto relative">
                    {loading ? (
                        <div className="h-[calc(100vh-190px)] flex items-center justify-center mt-4">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <UserTable location={location} loading={loading} workspaceId={workspaceId} dataTable={studentList} t={t} refreshData={() => getUserList(1, filter)}
                            userSelected={userSelected}
                            setUserSelected={setUserSelected}
                        />
                    )}
                </div>
                <Pagination
                    indexPage={indexPage}
                    page={pagination?.page}
                    per_page={pagination?.per_page}
                    total={pagination.total}
                    callback={handlePagination}
                />
            </div>

            <DialogComponent
                isOpen={openModalCreateLearnerPortal}
                title={t("USER.NEW_LEARNER_PORTAL")}
                onCloseModal={() => setOpenModalCreateLearnerPortal(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <CreateLearnerPortal
                        submitted={() => {
                            getUserList(1, filter);
                            setOpenModalCreateLearnerPortal(false);
                        }}
                        onCloseModal={() => setOpenModalCreateLearnerPortal(false)}
                    />
                }
            />

            <DialogComponent
                isOpen={openModalCreateUser}
                title={t("USER.NEW_CLMS_USER")}
                onCloseModal={() => setOpenModalCreateUser(false)}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <CreateUser
                        submitted={() => {
                            getUserList(1, filter);
                            setOpenModalCreateUser(false);
                        }}
                        onCloseModal={() => setOpenModalCreateUser(false)}
                    />
                }
            />
            {
                isOpenModalAddSkill && (
                    <ModalAddSkill
                        isOpen={isOpenModalAddSkill}
                        onClose={() => setOpenModalAddSkill(false)}
                        refreshData={() => { }}
                        // userData={userData}
                        userList={userSelected}
                    />
                )
            }
        </div>
    );
}
