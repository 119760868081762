import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import ActionsButton from 'components/ActionsButton';
import NO_CONTENT from 'assets/img/empty-state/resource.png';
import { DownloadIcon, EyeIcon, TrashIcon } from '@heroicons/react/outline';
import { LIBRARY_ICONS, LibraryTypeEnum } from 'components/Library/LibraryContants';
import { useHistory, useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

type TableResourcesProps = {
    workspaceId: string;
    data: any[];
    onDeleteResource: (rowValue: any) => void;
    onPreviewResource: (rowValue: any) => void;
    onDownloadResource: (rowValue: any) => void;
};

export const TableResources: FC<TableResourcesProps> = ({ workspaceId, data, onDeleteResource, onPreviewResource, onDownloadResource }) => {
    const { t, i18n } = useTranslation();
    const language = i18n.language;
    const history = useHistory();
    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));
    const params: {courseId: string} = useParams()
    const {
        userState: { result },
    } = useContext(UserContext);
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const columns = useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => <RenderTableHeader value={t('COURSES.ACADEMICS.NAME')} />,
                accessor: 'name',
                Cell: ({ row }: any) => {
                    return (
                        <div className="flex items-center">
                            <img
                                alt="icon"
                                className="h-6 w-6 flex-none"
                                src={
                                    row?.original?.object_type !== 'image'
                                      ? LIBRARY_ICONS(translator)?.find((item) => item?.value === row?.original?.object_type)?.icon
                                      :  LIBRARY_ICONS(translator)?.find((item) => row?.original?.sub_object_type?.includes(item?.value))?.icon
                                }
                            />
                            <RenderTableCell value={row?.values?.name} />
                        </div>
                    );
                },
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.ACADEMICS.ADDED_DATE')} />,
                accessor: 'created_at',
                Cell: ({ row }: any) => (
                    <RenderTableCell value={dayjs(row.values?.added_date).tz(result?.time_zone).format('DD/MM/YYYY')} />
                ),
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.ACADEMICS.CONTENT_TYPE')} />,
                accessor: 'object_type',
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            value={LIBRARY_ICONS(translator)?.find((item) => item?.value === row?.values?.object_type)?.label || ''}
                        />
                    );
                },
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.ACADEMICS.LAST_MODIFIED')} />,
                accessor: 'last_modified',
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs(row?.original?.updated_at).tz(result?.time_zone)?.locale(language).fromNow()} />;
                },
                width: 60,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionsButton
                                styleOptions="top-0 right-[35px]"
                                menus={[
                                    {
                                        label: t('VIEW'),
                                        icon: <EyeIcon className="w-4 h-4" />,
                                        action: () => {
                                            if (LibraryTypeEnum.h5p === row?.original?.object_type) history.push(`/workspace/${workspaceId}/h5p-content/${row?.original?.object_id}/preview?from=course-resourse&courseId=${params?.courseId}`)
                                            else onPreviewResource(row?.original);
                                        },
                                        hide: !checkPermission('libraryContent', 'R'),
                                    },
                                    {
                                        label: t('DOWNLOAD'),
                                        icon: <DownloadIcon className="w-4 h-4" />,
                                        action: () => onDownloadResource(row?.original),
                                        hide: !checkPermission('libraryContent', 'DL'),// || !getWorkspaceDetailState?.featureFlags?.DRM,
                                    },
                                    {
                                        label: t('DELETE'),
                                        icon: <TrashIcon className="w-4 h-4" />,
                                        action: () => onDeleteResource(row?.original),
                                        hide: !checkPermission('courseResource', 'D'),
                                    },
                                ]}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 10,
            },
        ],
        [data]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            initialState={{ hiddenColumns: ['id'] }}
            emptyContent={t('COURSES.ACADEMICS.NO_RESOURCES')}
            emptyIcon={NO_CONTENT}
        />
    );
};
