import {  useEffect, useState } from "react";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { useParams } from "react-router-dom";
import { checkPermission, getIndexPage } from "utils/commonFun";
import GradebookListTable from "./GradebookListTable";
import "./GradebookList.scss";
import Pagination from "components/V2/Pagination";
import learnerReportService from "services/reports/learner-report.service";
import { useTranslation } from "react-i18next";
import { ValueFilterSkillType } from "components/Skills/SkillFilter";

export const GradebookList = ({ filterSkill }: { filterSkill: ValueFilterSkillType }) => {
    const params: { id: string, userId: string } = useParams();
    const { t } = useTranslation(); t("All Courses")
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });

    const getdata = (page: number | string = 1, filter = undefined) => {
        setLoading(true);
        let payload: any = {
            ...pagination,
            studentId: params?.userId,
            page,
            ...filterSkill
        }
        delete payload?.total;
        learnerReportService.getLearnerAcademics(params?.id, payload)
            .then((res) => {
                const { items, total } = res.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getdata(page);
    };

    useEffect(() => {
        if (checkPermission('learnerDashboard', 'R')) {
            getdata()
        }
    }, [filterSkill])

    return (<div className="gradebookList">
        <h2 className="gradebookList-title">{ }</h2>
        <div className="gradebookList-table">
            {loading ? (
                <div className="gradebookList-table--loading">
                    <CircleSpin color="text-primary-500" />
                </div>
            ) : <div className="w-full h-[calc(100vh-430px)] custom-scrollbar overflow-auto ">
                <GradebookListTable data={data} />
            </div>
            }
        </div>
        <div className="gradebookList-pagination">
            <Pagination
                total={pagination.total}
                per_page={pagination.per_page}
                callback={(e) => handlePagination(e)}
                indexPage={indexPage}
                page={pagination?.page}
            />
        </div>
    </div>)
}