import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePickerField } from 'components/DatePicker';
import { WorkspaceService } from 'services';
import { useParams } from 'react-router-dom';
import { useFieldArray } from 'react-hook-form';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

interface IStep2 {
    setIsCompletedStep2: Dispatch<SetStateAction<boolean>>;
    control: any;
    errors: any;
    watch: any;
    isValid?: boolean;
    isMulti?: boolean;
}

export default function Step2({ setIsCompletedStep2, control, errors, watch, isValid, isMulti = false }: IStep2) {
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();

    const [loading, setLoading] = useState(true);

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'enroll_courses',
    });

    const workspaceId: string = params?.id;
    const fetchList = async () => {
        let payload: any = {
            page: 1,
            per_page: 20,
            skip_pagination: true,
            order: 'desc',
            sort_by: 'updated_at',
            skill_level_ids: !!watch('skill_level_ids')?.length ? JSON.stringify(watch('skill_level_ids')) : '[]',
            enrollment_type: ["catalog", "open"],
            status: "active"
        };

        if (!payload?.title) delete payload.title;

        const response = await WorkspaceService.getCoursesList(workspaceId, payload);

        if (response) {
            const formattedData = response?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.title,
                    value: item?.id,
                    start_date: null,
                    end_date: null,
                };
            });
            replace(formattedData);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        if (!loading) {
            if (!!watch('enroll_courses')?.length) setIsCompletedStep2(isValid);
            else setIsCompletedStep2(true);
        }
    }, [watch('enroll_courses'), isValid, loading]);

    return (
        <div className="text-gray-800 text-sm">
            <h1>
                {!!fields?.length
                    ? (isMulti ? 'The learner will be automatically enrolled to the following non-credit course(s). Please fill out enrollment start & end dates for each course:' : 'This Learner will be automatically enrolled to the following non-credit course(s):')
                    : 'No courses are currently associated with this Skill. When this Skill is added to a learner, they will not be automatically enrolled in any courses.'}
            </h1>
            <div className="mt-2 space-y-2">
                {loading ? (
                    <div className="flex justify-center pt-10">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    fields.map((item: any, index) => {
                        return (
                            <div key={index} className="bg-gray-50 flex items-center space-x-4 rounded p-3">
                                <p
                                    className="flex-1 text-enterprise font-semibold cursor-pointer"
                                    onClick={() => {
                                        window.open(
                                            `/workspace/${workspaceId}/management/courses/${item?.value}?tab=information`
                                        );
                                    }}
                                >
                                    {`${index + 1}.`} {watch(`enroll_courses.${index}.name`)}
                                </p>
                                <p className="flex space-x-2">
                                    <DatePickerField
                                        control={control}
                                        label={translator('START_DATE')}
                                        placeholder={translator('START_DATE')}
                                        name={`enroll_courses.${index}.start_date`}
                                        errors={errors}
                                        isRequired
                                        maxDate={watch(`enroll_courses.${index}.end_date`)}
                                        minDate={new Date()}
                                    />
                                    <DatePickerField
                                        control={control}
                                        label={translator('END_DATE')}
                                        placeholder={translator('END_DATE')}
                                        name={`enroll_courses.${index}.end_date`}
                                        errors={errors}
                                        isRequired
                                        minDate={watch(`enroll_courses.${index}.start_date`)}
                                        className='skill-learner-end_date'
                                    />
                                </p>
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}
