import { useForm } from 'react-hook-form';
import { FORM_CONST } from 'constant/form.const';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { InputField } from 'components/InputForm/InputForm';
import { AsyncSelectField } from 'components/Select';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import workspaceService from 'services/workspace.service';
import { USER_ROLE } from '../../constant';

const PER_PAGE = 20;
const MAX_LENGTH = 255;

const CreateUser = ({ submitted, onCloseModal }) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        control,
        setError,
    } = useForm({ mode: "onChange" });
    const { t: translator } = useTranslation();
    const params: any = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const submitForm = async (val) => {
        setIsLoading(true);
        await workspaceService
            .inviteMembers({workspaceId: params?.id, members: {
                first_name: val?.first_name || null,
                last_name: val?.last_name || null,
                email: val?.email || null,
                user_role_name: val?.user_role_name?.value || null,
            }})
            .then((res) => {
                Toastify.success(translator("USER.CREATE_USER_SUCCESSFULLY"))
                submitted();
            })
            .catch((error) => {
                if (error?.error?.body_params?.[0]?.msg === "ALREADY_INVITED") {
                    setError(
                        'email', 
                        {
                            message: translator("USER.EMAIL_ALREADY_IN_USE")
                        },
                        {
                            shouldFocus: true
                        }
                    )
                    Toastify.error(translator('USER.DUPLICATE_EMAIL'))
                }
                else Toastify.error(translator("USER.CREATE_USER_FAIL"))
            });
        setIsLoading(false);
    };

    const fetchRoles = async (bodyParams?: any) => {
        const res = await workspaceService.getRoles(params?.id, bodyParams);
        return {
            items: res?.data?.items,
            total: res?.data?.total,
        };
    };

    const handleLoadRoles = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional?.page,
            per_page: PER_PAGE,
            name: inputValue || null,
        };
        if (!payload?.name) delete payload?.name;

        const { items, total } = await fetchRoles(payload);
        const newOptions = items?.map((el: any) => {
            return {
                id: el?.id,
                label: el?.name,
                value: USER_ROLE[el?.name] || el?.name,
            }});
        return {
            options: newOptions,
            hasMore: additional?.page < total / PER_PAGE,
            additional: {
                page: additional?.page + 1,
            },
        };
    };

    return (
        <form onSubmit={handleSubmit(submitForm)} className="grid grid-cols-4 px-ooolab_p_2 gap-x-8 gap-y-5 mt-3">
            <InputField
                label={translator('USER.FIRST_NAME')}
                name="first_name"
                className="col-span-2"
                isRequired
                placeholder={translator('USER.ENTER_FIRST_NAME')}
                register={register('first_name', {
                    required: translator('FORM_CONST.REQUIRED_FIELD'),
                    validate: {
                        shouldNotContainSpace: (value) =>
                            !!value.trim()
                                ? true
                                : translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    maxLength: {
                        value: 255,
                        message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                    },
                })}
                errors={errors}
            />
            <InputField
                label={translator('USER.LAST_NAME')}
                name="last_name"
                className="col-span-2"
                isRequired
                placeholder={translator('USER.ENTER_LAST_NAME')}
                register={register('last_name', {
                    required: translator('FORM_CONST.REQUIRED_FIELD'),
                    validate: {
                        shouldNotContainSpace: (value) => {
                            if (!!value.trim()) return true;
                            return translator(
                                'FORM_CONST.REQUIRED_FIELD'
                            )
                        }
                    },
                    maxLength: {
                        value: MAX_LENGTH,
                        message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                    },
                })}
                errors={errors}
            />
            <InputField
                label={translator('USER.EMAIL')}
                name="email"
                className="col-span-4"
                placeholder={translator('USER.ENTER_EMAIL')}
                isRequired
                register={register('email', {
                    required: {
                        value: true,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    pattern: {
                        value: FORM_CONST.EMAIL_REGEX,
                        message: translator('FORM_CONST.EMAIL_VALIDATE'),
                    },
                    maxLength: {
                        value: MAX_LENGTH,
                        message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                    },
                })}
                errors={errors}
            />
            <AsyncSelectField
                control={control}
                label={translator('USER.ROLE')}
                name="user_role_name"
                className="col-span-4 relative"
                placeholder={translator('USER.SELECT_ROLE')}
                closeMenuOnSelect={true}
                errors={errors}
                loadOptions={handleLoadRoles}
                isRequired
            />

            <div className="col-span-4 flex justify-center gap-4 items-center pt-3">
                <ButtonOutline
                    onClick={onCloseModal}
                    type={'button'}
                >
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    disabled={isLoading || !isValid}
                >
                    {translator('CREATE')}
                </ButtonPrimary>
            </div>
        </form>
    );
};

export default CreateUser;
