import { FC, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/course.png';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';
import { COURSE_TYPE } from 'components/Workspace/Course/CoursesList/constants';
import { TooltipCustom } from 'components/Tooltip/Tooltip';

type TableProps = {
    data: CourseType[];
};

export const TableSkillAssociatedCourse: FC<TableProps> = ({ data }) => {
    const { t: translator } = useTranslation();
    const history = useHistory();
    const params: any = useParams();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('COURSES.NAME')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            onClick={() => history.push(`/workspace/${params?.id}/management/courses/${row?.original?.id}`)}
                            className="cursor-pointer text-primary-500 font-semibold"
                            value={row.values?.title}
                        />
                    );
                },
                accessor: 'title',
                width: 200,
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COURSE_TYPE')} />,
                Cell: ({ row }: any) => {
                    const length = row?.values?.type?.length;
                    const types = row?.values?.type;
                    const typesSlice = types
                        ?.slice(3)
                        ?.map((item) => COURSE_TYPE?.[item])
                        ?.join(', ');
                    if (!length) return <RenderTableCell value={''} />;
                    return (
                        <div className="CourseType">
                            {types?.slice(0, 3)?.map((item) => (
                                <div key={item} className="CourseType-item">
                                    {COURSE_TYPE?.[item]}
                                </div>
                            ))}
                            {length > 3 && (
                                <div className="CourseType-viewmore">
                                    <div data-tip={typesSlice} data-for={`${row?.original?.id}-type-tooltip`}>
                                        +{length - 3}
                                    </div>
                                    <TooltipCustom
                                        classNameContainer="shadow-medium"
                                        id={`${row?.original?.id}-type-tooltip`}
                                        content={typesSlice}
                                        place="bottom"
                                        type="dark"
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
                accessor: 'type',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('Course Level')} />,
                Cell: ({ row }: any) => {
                    const length = row?.values?.level?.length;
                    const levels = row?.values?.level;
                    const levelsSlice = levels
                        ?.slice(1)
                        ?.map((item) => item)
                        ?.join(', ');
                    if (!length) return <RenderTableCell value={''} />;
                    return (
                        <div className="CourseType">
                            {levels?.slice(0, 1)?.map((item) => (
                                <div key={item} className="CourseType-item">
                                    {item}
                                </div>
                            ))}
                            {length > 1 && (
                                <div className="CourseType-viewmore">
                                    <div data-tip={levelsSlice} data-for={`${row?.original?.id}-level-tooltip`}>
                                        +{length - 1}
                                    </div>
                                    <TooltipCustom
                                        classNameContainer="shadow-medium"
                                        id={`${row?.original?.id}-level-tooltip`}
                                        content={levelsSlice}
                                        place="bottom"
                                        type="dark"
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
                accessor: 'level',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('Course Status')} />,
                Cell: ({ row }: any) => {
                    if (!row?.values?.status) return <RenderTableCell value="" />;
                    return <EnrollmentStatus status={row?.values?.status} />;
                },
                accessor: 'status',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={translator('Skill Level')} />,
                Cell: ({ row }: any) => {
                    const length = row?.values?.skill_levels?.length;
                    const levels = row?.values?.skill_levels;
                    const levelsSlice = levels
                        ?.slice(1)
                        ?.map((item) => item?.name)
                        ?.join(', ');
                    if (!length) return <RenderTableCell value={''} />;
                    return (
                        <div className="">
                            {levels?.slice(0, 1)?.map((item) => (
                                <div key={item?.id} className="text-xs text-gray-800 ml-2">
                                    {item?.name}
                                </div>
                            ))}
                            {length > 1 && (
                                <div className="text-xs">
                                    <div data-tip={levelsSlice} data-for={`${row?.original?.id}-skill-level-tooltip`}>
                                        +{length - 1}
                                    </div>
                                    <TooltipCustom
                                        classNameContainer="shadow-medium"
                                        id={`${row?.original?.id}-skill-level-tooltip`}
                                        content={levelsSlice}
                                        place="bottom"
                                        type="dark"
                                    />
                                </div>
                            )}
                        </div>
                    );
                },
                accessor: 'skill_levels',
                width: 100,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <>
            <Table
                data={data}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('No Associated Course(s)')}
                emptyIcon={NO_CONTENT}
            />
        </>
    );
};
