import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionsButton from 'components/ActionsButton';
import { TrashIcon } from '@heroicons/react/outline';
import { checkPermission } from 'utils/commonFun';
import ModalDeleteSkill from '../ModalDeleteSkill';

interface ActionMenuProp {
    refreshData: any;
    data: any;
    position?: string
}

const ActionMenu: FC<ActionMenuProp> = ({ refreshData, data, position }) => {
    const { t: translator } = useTranslation();
    const [isOpenModalDeleteSkill, setOpenModalDeleteSkill] = useState<boolean>(false);

    return (
        <>
            <ActionsButton
                styleOptions={`${position ? 'bottom-0' : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('DELETE'),
                        icon: <TrashIcon width="18" height="16" />,
                        action: () => setOpenModalDeleteSkill(true),
                        hide: !checkPermission('user', 'U'),
                        isDisabled: data?.learning_group,
                        contentTip: translator('This Skill belongs to a learning group and can only be deleted from within that group.'),
                    },
                ]}
            />
            
            {isOpenModalDeleteSkill &&
                <ModalDeleteSkill
                    isOpen={isOpenModalDeleteSkill}
                    onClose={() => setOpenModalDeleteSkill(false)}
                    data={data}
                    refreshData={refreshData}
                />
            }
        </>
    );
};

export default ActionMenu;
