import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { InformationCircleIcon } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { InputField } from 'components/InputForm/InputForm';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useContext  } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';

export default function ModalMoveFileToNewFolder({ isOpen, onClose, data, refreshData, isLastFile }) {
    const params: { id } = useParams();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {t: translator} = useTranslation();
    const {
        handleSubmit,
        formState: { errors, isValid },
        watch,
        register
    } = useForm({ mode: 'onChange' });

    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const onSubmit = (value) => {
        const payload = {
            custom_fields: [
                {
                    ...workspaceDetailInformation?.custom_field_ws_resource_folder,
                    custom_field_id: workspaceDetailInformation?.custom_field_ws_resource_folder?.id,
                    data: [value?.folderName],
                },
            ],
            id: data?.object_id,
            type: 'files',
        };
        if (data?.object_type === 'h5p') {
            delete payload.type;
            delete payload.id;
            workspaceService.upsertCustomfiledH5p(params.id, data?.object_id, payload)
            .then((res) => {
              Toastify.success();
              onClose();
              refreshData();
            })
            .catch(() => Toastify.error())
          }
          else {
            workspaceService.updateCustomFieldByTypeService(params.id, payload)
            .then((res) => {
              Toastify.success();
              onClose();
              refreshData();
            })
            .catch(() => Toastify.error())
          }
    };

    return (
        <DialogComponent
            title={translator("RESOURCES.CREATE_AND_MOVE_TO_NEW_FOLDER")}
            isOpen={isOpen}
            onCloseModal={onClose}
            child={
                <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    <InputField 
                        name='folderName'
                        register={{ ...register('folderName', {
                            validate: {
                                shouldNotContainSpace: (value) => {
                                    return value.trim()?.length ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`;
                                },
                            },
                            maxLength: {
                                value: 255,
                                message: translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 }),
                            },
                            }),
                         }}
                        errors={errors} 
                        label={translator('RESOURCES.NEW_FOLDER_NAME')}
                        placeholder={translator('ENTER') + translator('RESOURCES.NEW_FOLDER_NAME')}
                    />
                    {isLastFile && (
                        <div className='text-xs mt-2 text-primary-500 rounded-md border border-blue-300 bg-blue-50 p-2 flex gap-2'> 
                            <div className='w-5'><InformationCircleIcon /> </div>
                            <div>
                                {translator('RESOURCES.MESSAGE_LAST_FILE')}
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center gap-4 mt-4">
                      <ButtonOutline type="Button" onClick={onClose}>
                          {translator('CANCEL')}
                      </ButtonOutline>
                      <ButtonPrimary type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                          {translator('CREATE')}
                      </ButtonPrimary>
                  </div>
                </form>
            }
        />
    );
}
