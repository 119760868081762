import { CalendarIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import './Notification.scss';
import UpcomingAssignmentIcon from 'assets/icon/UpcomingAssignmentIcon';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Logo from 'assets/img/learning-logo.png';
import { TeacherDashboardService } from 'services';
import { openUrl, timeSince } from 'utils/commonFun';
import { CheckboxReminder } from 'assets/icon';
import ModalSessionDetail from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/ModalSessionDetail';

export type NOTIFICATION_EVENT_TYPE =
    | 'learner_absence_request'
    | 'news_custom'
    | 'upcoming_assignment'
    | 'upcoming_class'
    | 'apollo_custom'
    | 'pending_session_attendance';

interface NotificationProps {
    type: NOTIFICATION_EVENT_TYPE;
    subTitle: string;
    title: string;
    courseName?: string;
    startDate: string;
    tz: string;
    vcLink?: string;
    id: string;
    link?: string;
    image?: string;
    isClicked: boolean;
    session?: any;
    createdAt: string
}

export default function Notification({
    type,
    subTitle,
    courseName,
    startDate,
    tz,
    vcLink,
    id,
    link,
    image,
    title,
    isClicked,
    session,
    createdAt
}: NotificationProps) {
    const { t: translator } = useTranslation();
    const [openModalViewSessionDetail, setOpenModalViewSessionDetail] = useState(false);
    const params: { id } = useParams();
    const history = useHistory();
    const [isClickednotification, setReadNotification] = useState(isClicked);

    useEffect(() => {
        setReadNotification(isClicked);
    }, [isClicked]);

    const clickNotificationAction = () => {
        TeacherDashboardService.clickNotification(params?.id, id).then(() => {
            setReadNotification(true);
        });
    };

    const handleJoinSession = () => {
        if (vcLink) {
            openUrl(vcLink)
            clickNotificationAction();
        } else goToSessionDetail();
    };

    const goToSessionDetail = () => {
        setOpenModalViewSessionDetail(true);
        clickNotificationAction();
    };

    const goToCustomNotification = () => {
        clickNotificationAction();
        if (link) return openUrl(link);
        history.push(`/workspace/${params?.id}/instructor-dashboard/notifications/${id}`);
    };

    const onClickNotification = () => {
        if (type === 'upcoming_class') {
            goToSessionDetail();
        }
        if (type === 'apollo_custom') {
            goToCustomNotification();
        }
        if (type === 'learner_absence_request') {
            clickNotificationAction();
        }
        if (type === 'pending_session_attendance') {
            clickNotificationAction();
            history.push(`/workspace/${params?.id}/instructor-dashboard/gradebook/${session?.course?.id}/${session?.id}`);
        }
    };

    return (
        <>
            <div onClick={onClickNotification} className="flex justify-between items-center hover:bg-gray-50 rounded px-8 py-2 cursor-pointer">
                <div className="Notification">
                    <div className={`title ${type} flex-none`}>
                        {type === 'upcoming_assignment' && <UpcomingAssignmentIcon className="icon" />}
                        {type === 'upcoming_class' && <CalendarIcon className="icon" />}
                        {type === 'learner_absence_request' && <CalendarIcon className="icon" />}
                        {type === 'pending_session_attendance' &&  <CheckboxReminder className="icon" />}
                        {(type === 'apollo_custom' || type === 'news_custom') && (
                            <div style={{ backgroundImage: `url(${image || Logo})` }} className='bg-contain object-cover w-12 h-12 rounded-full'></div>
                        )}
                    </div>
                    <div className="content">
                        {type === 'upcoming_class' && (
                            <>
                                <p className="content-type">{translator('INSTRUCTOR_PORTAL.UPCOMING_SESSION')}</p>
                                <p className="text-sm">
                                    <span className="font-semibold">{courseName}</span> {translator('INSTRUCTOR_PORTAL.START_AT')}{' '}
                                    <span className="font-semibold">{dayjs.utc(startDate).tz(tz).format('DD/MM/YYYY HH:mm')}</span> .{' '}
                                    <span className='cursor-pointer' onClick={handleJoinSession}>
                                        {translator('INSTRUCTOR_PORTAL.CLICK_HERE_TO_JOIN_THE_SESSION')}
                                    </span>
                                </p>
                            </>
                        )}
                        {type === 'learner_absence_request' && (
                            <>
                                <p className="content-type">{translator('Your session has been cancelled')}</p>
                                <p className="content-subtitle">
                                    <span>{translator(`The ${courseName} session scheduled for ${dayjs.utc(startDate).tz(tz).format('DD/MM/YYYY HH:mm')} has been cancelled.`)}</span>
                                </p>
                            </>
                        )}
                        {(type === 'apollo_custom' || type === 'news_custom') && (
                            <>
                                <p className="content-type">{title}</p>
                                <p className="content-subtitle">{subTitle}</p>
                                <span onClick={goToCustomNotification} className="text-sm cursor-pointer">
                                    {link
                                        ? translator('INSTRUCTOR_PORTAL.CLICK_HERE_TO_OPEN_LINK')
                                        : translator('INSTRUCTOR_PORTAL.CLICK_HERE_TO_VIEW_DETAILS')}
                                </span>
                            </>
                        )}
                        {type === 'pending_session_attendance' && (
                            <>
                                <p className="content-type">{translator('Missing Session Evaluation')}</p>
                                <p className="content-subtitle">
                                    Please complete the session evaluation for <strong>{courseName}</strong> scheduled for <strong>{dayjs.utc(session?.start_time).tz(tz).format('DD/MM/YYYY HH:mm')}</strong>.
                                </p>
                            </>
                        )}
                        <p className="text-sm text-gray-500">{timeSince(new Date(createdAt))} ago</p>
                    </div>
                </div>
                {!isClickednotification && <div className="flex-none w-2 h-2 rounded-full bg-primary-500"></div>}
            </div>
            <ModalSessionDetail
                isOpen={openModalViewSessionDetail}
                onClose={() => setOpenModalViewSessionDetail(false)}
                sessions={[session]}
                refreshData={() => { }}
            />
        </>
    );
}
