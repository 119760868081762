import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import ButtonComponent from 'components/Button/ButtonComponent';
import Pagination from 'components/V2/Pagination';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import TableSkills from './TableSkill';
import skillService from 'services/skill.service';
import Filter from './Filter';
import { debounce } from 'lodash';

export const SkillList = ({ children }) => {
    const params: any = useParams();
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [skillList, setSkillList] = useState([]);
    const [filter, setFilter] = useState({});

    const history = useHistory();

    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });

    const workspaceId = params.id;

    const getSkillList = ({ page = 1, filter }: { page?: number | string; filter?: any}) => {
        setLoading(true);
        const payload = {
            page: page || pagination.page,
            per_page: pagination.per_page,
            order: pagination.order,
            sort_by: pagination.sort_by,
            ...filter
        };

        skillService
            .getSkillsService(workspaceId, payload)
            .then((res) => {
                const { items, page, per_page, order, total } = res.data;
                setSkillList(items);
                setPagination({ ...pagination, page, per_page, order, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const getSkillListDebounce = useCallback(
        debounce((page, filter) => {
            getSkillList({ page, filter });
        }, 500),
        []
    )

    useEffect(() => {
        getSkillListDebounce(1, filter);
    }, [filter]);

    const onChangeFilter = (filter) => {
        setFilter(filter);
    };

    return (
        <div>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[{ name: translator('DASHBOARD.SIDEBAR.SKILLS'), href: '', className: '' }]}
            />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 py-4 px-5 text-enterprise">
                <div className='text-xl text-gray-800 font-semibold' >{translator("DASHBOARD.SIDEBAR.SKILLS")}</div>
                <div className="lg:flex lg:justify-end space-x-2">
                    {/* {checkPermission('skill', 'C') && ( */}
                        <ButtonComponent
                            title={translator('NEW')}
                            classStyle="text-sm font-semibold !rounded bg-primary-500 text-white flex gap-1 justify-center items-center px-3 py-1"
                            onClickButton={() => history.push(`/workspace/${workspaceId}/management/skills/new`)}
                            icon={<PlusIcon className="w-4 h-4" />}
                        />
                    {/* )} */}
                </div>
            </div>
            <div className="border-b border-gray-300 pb-4">
                <Filter onChangeFilter={onChangeFilter} />
            </div>
            <div className="p-4 pb-0">
                <div className="h-[calc(100vh-245px)] custom-scrollbar overflow-y-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-195px)] flex items-center justify-center mt-4">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    ) : (
                        <TableSkills
                            dataTable={skillList}
                        />
                    )}
                </div>
                {!!skillList?.length && (
                    <Pagination
                        callback={(page) => getSkillList({ page })}
                        total={pagination?.total}
                        page={pagination?.page}
                        per_page={pagination?.per_page}
                        indexPage={indexPage}
                    />
                )}
            </div>
        </div>
    );
};
