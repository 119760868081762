import Table from 'components/Management/components/table';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Column } from 'react-table';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ReactTooltip from 'react-tooltip';

const RenderGroupsHeader = ({ value }: { value: string }) => (
    <div className="h-full pl-1 flex items-center">
        <p className=" text-sm text-ooolab_dark_1 font-semibold">{value}</p>
    </div>
);

const FormatColumnValue = ({ children }) => (
    <p className="overflow-ellipsis whitespace-nowrap overflow-hidden pl-1 py-1 font-normal text-ooolab_dark_1 text-sm my-3">
        {children}
    </p>
);

const renderColorStatus = (status: string) => {
    let color = 'bg-[#FFCECC]';
    switch (status) {
        case 'active':
            color = 'bg-[#CCFFDA]';
            break;
        case 'pending':
            color = 'bg-[#FA9847]';
            break;
        default:
            break;
    }
    return color;
};
interface TableClassProps {
    data?: any[];
}

const TableReward: React.FC<TableClassProps> = ({ data }) => {
    const { t: translator } = useTranslation();

    const param: { id: string } = useParams();
    const columns: Column<any>[] = React.useMemo(
        () => [
            {
                Header: () => (
                    <div className="flex items-center justify-between border-ooolab_bar_color">
                        <RenderGroupsHeader value={'Reward ID'} />
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),
                accessor: 'id',
                Cell: ({ row }: { row: Row }) => (
                    <div className="border-ooolab_bar_color flex items-center text-xs justify-between h-full py-3 font-semibold">
                        <Link
                            to={`/workspace/${param.id}/management/reward/${row?.values?.id}`}
                            className="text-primary-500 font-semibold cursor-pointer"
                        >
                            {row?.values?.id}
                        </Link>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                ),

                width: 120,
            },
            {
                Header: () => (
                    <>
                        <div className="h-full grid grid-cols-4 ">
                            <div className="col-span-1"></div>
                            <p className="col-span-3 text-sm text-ooolab_dark_1 font-semibold text-left">
                                {translator("Reward Name")}
                            </p>
                        </div>
                    </>
                ),
                accessor: 'name',
                Cell: ({ row }: { row: any }) => {
                    return (
                        <>
                            <div className="h-full grid grid-cols-4 ">
                                <div className="col-span-1 flex justify-center items-center">
                                    <img
                                        src={row?.original?.thumbnail}
                                        className="w-6 h-6 rounded"
                                        alt="thumbnail"
                                    />
                                </div>
                                <div className="flex items-center col-span-3 ">
                                    <p
                                        data-tip={row?.values?.name}
                                        data-for={
                                            row?.values?.id + 'reward_name'
                                        }
                                        className="overflow-ellipsis whitespace-nowrap overflow-hidden font-normal text-ooolab_dark_1 text-sm  "
                                    >
                                        {row?.values?.name}
                                    </p>
                                </div>
                            </div>
                            {/*@ts-ignore*/
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row?.values?.id + 'reward_name'}
                                getContent={(content) => (
                                    <div className=" h-auto">{content}</div>
                                )}
                            />}
                        </>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Description')} />,
                accessor: 'description',
                Cell: (d: any) => {
                    return <FormatColumnValue>{d?.value}</FormatColumnValue>;
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Start Date')} />,
                accessor: 'start_date',
                Cell: (d: any) => {
                    return (
                        <FormatColumnValue>
                            {dayjs(d?.value).format('MM/DD/YYYY')}
                        </FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('End Date')} />,
                accessor: 'end_date',
                Cell: (d: any) => {
                    return (
                        <FormatColumnValue>
                            {dayjs(d?.value).format('MM/DD/YYYY')}
                        </FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Availability')} />,
                accessor: 'available_quantity',
                Cell: (d: any) => {
                    return <FormatColumnValue>{d?.value}</FormatColumnValue>;
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Coin Price')} />,
                accessor: 'price',
                Cell: (d: any) => {
                    return (
                        <FormatColumnValue>
                            <span className="font-semibold">{d?.value}</span>{' '}
                            {translator("Coins")}
                        </FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Level')} />,
                accessor: 'reward_level',
                Cell: (d: any) => {
                    return (
                        <FormatColumnValue>{d?.value?.name}</FormatColumnValue>
                    );
                },
            },
            {
                Header: () => <RenderGroupsHeader value={translator('Status')} />,
                accessor: 'status',
                Cell: (d: any) => {
                    return (
                        <div className="h-full flex items-center w-3/4">
                            <div
                                className={`${renderColorStatus(
                                    d?.value
                                )}  overflow-ellipsis whitespace-nowrap overflow-hidden p-1 font-semibold text-ooolab_dark_1 text-sm text-center  rounded w-full capitalize `}
                            >
                                {d?.value === 'deactivate'
                                    ? translator('Deactivated')
                                    : translator(d?.value?.toUpperCase())}
                            </div>
                        </div>
                    );
                },
            },
        ],
        [data]
    );
    return <Table data={data} columns={columns} />;
};

export default TableReward;
