import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedState } from 'hooks/useDebounce';
import { debounce } from 'lodash';
import SearchInput from '_shared/components/SearchInput/SearchInput';
import { SelectAssociatedCourses } from 'components/Management/SkillManagement/components/SelectAssociatedCourses';

interface IFilter {
    onChangeFilter: (value: any) => void;
}

const Filter = ({ onChangeFilter }: IFilter) => {
    const { t: translator } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [associatedCourses, setAssociatedCourses] = useState([]);

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter)),
        []
    );

    useEffect(() => {
        let listFilter: any = {
            course_ids: JSON.stringify(associatedCourses?.map((item) => item?.value)),
            q: keyword?.trim(),
        };

        if (!associatedCourses?.length) delete listFilter.course_ids;
        if (!listFilter?.q) delete listFilter.q;
        updateFilter(listFilter);
    }, [ keyword, associatedCourses]);

    return (
        <div className="flex gap-3 px-5">
            <SearchInput
                placeholder={translator('SEARCH_A_SKILL')}
                className={"!w-[240px]"}
                onChange={(value) => setKeyword(value)}
                value={keyword}
            />
            <SelectAssociatedCourses onSelect={setAssociatedCourses} />
        </div>
    );
};

export default Filter;
