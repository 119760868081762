import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardContainer } from "../CardContainer";
import { Title } from "../Title";
import ButtonDownload from 'components/Button/ButtonDownload';
import SearchInput from "../SearchInput";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { NoContentWithImage } from "components/NoContent/NoContent";
import { checkPermission, getIndexPage, getValueOrSign } from "utils/commonFun";
import TablePagination from "components/V2/Pagination/Pagination";
import Table, { LinkCustom, RenderTableCell } from "components/V2/Table/TableCustom";
import { useParams } from "react-router-dom";
import { RenderTableHeader } from "components/V2/Table/TableCustomMultipleSelect";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import { CircleInformationIcon } from "assets/icon";
import { TooltipCustom } from "components/Tooltip/Tooltip";

export const StudentList = ({
    students,
    onSearchStudent,
    loadingStudents,
    handlePaginationStudent,
    handleDownloadCSV,
    isDownloading,
    setType,
    type = 'individual',
    className = '',
    hasDashboard = false
}) => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, classId: string } = useParams();
    const getUrl = (row) => {
        if (hasDashboard) {
            return `${type == 'individual' ? `/workspace/${params.id}/report/dashboard-learner/${row?.original?.learner?.id}` : `/workspace/${params.id}/report/dashboard-learner/${row?.original?.learning_group?.id}?type=learning-group&tab=overview`}`;
        }
        return `${type == 'individual' ? `/workspace/${params.id}/management/users/${row?.original?.learner?.id}` : `/workspace/${params.id}/management/learning-groups/${row?.original?.learning_group?.id}`}`;
    }
    const columns: any[] = useMemo(
        () => [
            {

                Header: () => (
                    <RenderTableHeader className="text-left pl-2" value={type == 'individual' ? translator('Learner Name') : translator('Learning Group Name')} />
                ),
                Cell: ({ row }: { row: any }) => {
                    const value = type == 'individual' ? row?.original?.learner : row?.original?.learning_group;
                    return (
                        <LinkCustom
                            to={getUrl(row)}
                            className="!text-xs text-primary-500 font-semibold cursor-pointer truncate"
                            isTooltip
                            value={value?.display_name || value?.name}
                            disable={value?.display_name?.length < 35 || value?.name?.length < 35}
                            target={hasDashboard ? '' : `_blank`}
                        />
                    );
                },
                accessor: 'learner',
            },
            {
                Header: () => <RenderTableHeader value={translator(type == 'individual' ? 'Overall Grade' : 'Avg Overall Grade')} />,
                accessor: 'overall',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={getValueOrSign(d.value)} />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator(type == 'individual' ? 'Attendance' : 'Avg Attendance')} />,
                accessor: 'attendance',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={getValueOrSign(d.value)} />
                    );
                },
            },
            {
                Header: () => <>
                    <div className='flex items-center gap-1 max-w-sm '>
                        <RenderTableHeader value={type == 'individual' ? t('COURSES.ASSIGNMENT_GRADE') : t('Avg Assignment Grade')} className="!justify-center" />
                        <div className="w-4 h-4 cursor-pointer flex items-center"
                            data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                            data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}>
                            <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                            <TooltipCustom
                                classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                                id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                                place="top"
                                type="light"
                            />
                        </div>
                    </div>
                </>,
                accessor: 'assignment_score',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={getValueOrSign(d.value)} />
                    );
                },
                width: type == 'individual' ? 150 : 180,
            },
            {
                Header: () => <>
                    <div className='flex items-center gap-1 max-w-sm '>
                        <RenderTableHeader value={type == 'individual' ? t('ASSIGNMENT_COMPLETION') : t('AVG_ASSIGNMENT_COMPLETION')} className="!justify-center" />
                        <div className="w-4 h-4 cursor-pointer flex items-center"
                            data-for="TOOLTIPS_ASSIGNMENT_COMPLETION"
                            data-tip={t('TOOLTIPS_ASSIGNMENT_COMPLETION')}>
                            <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                            <TooltipCustom
                                classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                                id="TOOLTIPS_ASSIGNMENT_COMPLETION"
                                content={t('TOOLTIPS_ASSIGNMENT_COMPLETION')}
                                place="top"
                                type="light"
                            />
                        </div>
                    </div>
                </>,
                accessor: 'assignment_completion',
                Cell: (d: any) => {
                    console.log(d.value);
                    return (
                        <RenderTableCell value={getValueOrSign(d.value || 0)} />
                    );
                },
                width: type == 'individual' ? 200 : 220,
            },
            {
                Header: () => <>
                    <div className='flex items-center gap-1 max-w-sm '>
                        <RenderTableHeader value={type == 'individual' ? t('ASSIGNMENT_ONE_TIME') : t('AVG_ASSIGNMENT_ONE_TIME')} className="!justify-center" />
                        <div className="w-4 h-4 cursor-pointer flex items-center"
                            data-for="TOOLTIPS_ASSIGNMENT_ON_TIME"
                            data-tip={t('TOOLTIPS_ASSIGNMENT_ON_TIME')}>
                            <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                            <TooltipCustom
                                classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                                id="TOOLTIPS_ASSIGNMENT_ON_TIME"
                                content={t('TOOLTIPS_ASSIGNMENT_ON_TIME')}
                                place="top"
                                type="light"
                            />
                        </div>
                    </div>
                </>,
                accessor: 'assignment_ontime',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={getValueOrSign(d.value || 0)} />
                    );
                },
                width: type == 'individual' ? 170 : 200,
            },
            // {
            //     Header: () => <RenderTableHeader value={t(type == 'individual' ? 'ASSIGNMENT_OVERDUE' : 'AVG_ASSIGNMENT_OVERDUE')} />,
            //     accessor: 'assignment_overdue',
            //     Cell: (d: any) => {
            //         return (
            //             <RenderTableCell value={getValueOrSign(d.value || 0)} />
            //         );
            //     },
            //     width: type == 'individual' ? 150 : 200,
            // },
            {
                Header: () => <RenderTableHeader value={translator(type == 'individual' ? 'Course Completion' : 'Avg Course Completion')} />,
                accessor: 'course_completion',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={getValueOrSign(d.value)} />
                    )
                },
                width: type == 'individual' ? 150 : 160,
            },
            {
                Header: () => <RenderTableHeader value={translator(type == 'individual' ? 'Goals Achieved' : 'Avg Goals Achieved')} />,
                accessor: 'lesson_goal',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={getValueOrSign(d.value)} />
                    );
                },
            },
            {
                Header: () => <RenderTableHeader value={translator('Total Coins Earned')} />,
                accessor: 'coin_earned',
                Cell: (d: any) => {
                    return (
                        <RenderTableCell value={d.value ? d.value : '-'} />
                    );
                },
            },
        ],
        [type]
    );

    return <>
        <CardContainer className="pb-1">
            <div className='flex mb-4 items-center'>
                <Title>{translator('Learner List')}</Title>
                {checkPermission('learnerDashboard', 'DL') && <div className='ml-auto flex space-x-4'>
                    <ButtonDownload title="Download CSV" isDownload={isDownloading} disabled={students?.total == 0 || students == null} onClick={handleDownloadCSV} />
                </div>}

            </div>
            <div className='flex gap-5'>
                <SearchInput onSubmit={onSearchStudent} />
                <div className="flex items-center rounded border border-gray-300 p-1">
                    <button className={`min-w-[126px] h-6 text-xs font-semibold text-center ${type == 'individual' ? 'text-primary-500 bg-blue-50 rounded' : null}`} onClick={() => setType('individual')}>Learners</button>
                    <button className={`min-w-[126px] h-6 text-xs font-semibold text-center ${type == 'learning_group' ? 'text-primary-500 bg-blue-50 rounded' : null}`} onClick={() => setType('learning_group')}>Learning Groups</button>
                </div>
            </div>
            <div className={`mt-4 w-full overflow-x-auto ${className ? className : ''}`}>
                {
                    loadingStudents ?
                        <div className="flex justify-center items-center w-full min-h-[360px]">
                            <CircleSpin color="!text-primary-500" />
                        </div> :
                        students?.items?.length > 0 ?
                            <Table
                                columns={columns}
                                data={students?.items}
                            /> :
                            <div className="flex justify-center items-center w-full min-h-[360px] py-8">
                                <NoContentWithImage />
                            </div>
                }
            </div>
            {students?.total > 0 && !loadingStudents && <div className="flex items-center justify-between border-t border-ooolab_bar_color mt-4">
                <p className="text-ooolab_dark_2 text-ooolab_xs font-semibold leading-ooolab_24px hidden lg:block">
                    {t('SHOWING_1_50_OF_TOTAL_PAGE', {
                        fromTo: getIndexPage(students?.page, students?.per_page, students?.items?.length),
                        totalPage: students?.total,
                    })}
                </p>
                <div className="mt-4">
                    <TablePagination
                        onClickPagination={handlePaginationStudent}
                        perPage={students?.per_page}
                        total={students?.total}
                        forcePage={students?.page - 1}
                    />
                </div>
            </div>}
        </CardContainer>
    </>
}