import React, { useState } from 'react';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { hasCheckedStream } from 'constant/file.constant';
import VideoPlayer from '_shared/components/VideoPlayer/VideoPlayer';
import { IframePdfViewer } from '_shared/PreviewContent/IframePDFViewer';

export const NormalTask = ({ task }) => {
    return (
        <div className="">
            {LibraryTypeEnum.image === task?.object_type && (
                <div className="previewContent-image">
                    <img className="object-contain" src={task?.file_url} alt="" />
                </div>
            )}
            {LibraryTypeEnum.video == task?.object_type &&
                (hasCheckedStream(task?.file_url) ? (
                    <VideoPlayer data={task} color={'text-gray-800'} />
                ) : (
                    <iframe title="iframe-video" className="document w-full min-h-[80vh]" src={task?.file_url}></iframe>
                ))}
            {(LibraryTypeEnum.document == task?.object_type ||
                LibraryTypeEnum.presentation == task?.object_type ||
                LibraryTypeEnum.spreadsheet == task?.object_type) && (
                <iframe
                    title="iframe-document"
                    className="document w-full min-h-[80vh]"
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${task?.file_url}`}
                />
            )}
            {LibraryTypeEnum.audio == task?.object_type && (
                <div className="audio">
                    <audio controls src={task?.file_url} className="w-full max-w-[450px]"></audio>
                </div>
            )}
            {LibraryTypeEnum.pdf == task?.object_type && <IframePdfViewer url={task?.file_url} />}
        </div>
    );
};
