import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InputField, LabelForm, TextareaField } from 'components/InputForm/InputForm';
import { ArrowNarrowRightIcon } from '@heroicons/react/outline';
import { ImageAvatar } from 'components/Image';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import courseService from 'services/course.service';
import { useUrlUserNavigation } from 'hooks/useUrlUserNavigation';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

type ModalDepositRefundProps = {
    data: any;
    translator: (key: string, isNew?: boolean) => string;
    type?: 'deposit' | 'refund';
    onClose: () => void;
    onConfirm: (values?: any) => void;
};

export const ModalDepositRefund: FC<ModalDepositRefundProps> = ({ data, translator, type = 'deposit', onClose, onConfirm }) => {
    const params: any = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [creditAccountDetail, setCreditAccountDetail] = useState(null);

    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const learner = data?.user || data?.learner; // from Course Credit Balance or Course People (Learners Tab)
    const enrollment = data?.course_enrollment || data; // from Course Credit Balance or Course People (Learners Tab)

    const { openInNewTabUser } = useUrlUserNavigation({ user_id: learner?.id });
    const { openInNewTabCourse } = useUrlCourseNavigation();

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
    } = useForm();

    const fetchCreditAccountDetail = () => {
        courseService
            .getCreditAccounts(workspaceId, {
                account_type: enrollment?.type === 'individual' ? 'personal' : 'learning_group',
                learning_group_id: enrollment?.type === 'learning_group' ? data?.learning_group?.id : null,
                membership_id: learner?.membership_id || null,
            })
            .then((res) => {
                if (!!res?.data?.items?.length) {
                    const dataCredit = res?.data?.items?.find(item => item?.user?.membership_id === learner?.membership_id);
                    !!dataCredit && setCreditAccountDetail(dataCredit || null);
                }
            });
    };

    const onDepositCredit = (bodyParams: any) => {
        setIsLoading(true);

        courseService
            .depositCreditBalance(workspaceId, bodyParams)
            .then((res) => {
                Toastify.success(translator("COURSES.DEPOSIT_CREDIT_SUCCESSFULLY", true));
                onConfirm?.();
            })
            .catch((err) => Toastify.error())
            .finally(() => setIsLoading(false));
    };

    const onRefundCredit = (bodyParams: any) => {
        setIsLoading(true);

        courseService
            .refundCreditBalance(workspaceId, bodyParams)
            .then((res) => {
                Toastify.success(translator("COURSES.REFUND_CREDIT_SUCCESSFULLY", true));
                onConfirm?.();
            })
            .catch((err) => Toastify.error())
            .finally(() => setIsLoading(false));
    };

    const handleOpenNewTab = (to) => {
        if (to === 'enrollment') {
            openInNewTabCourse({ 
                tab: 'people',
                subtab: 'learner',
                enrollmentId: enrollment?.id,
                view: 'information'
            });
        } else if (to === 'credit_account') {
            openInNewTabUser({ 
                tab: 'credit-accounts',
                creditId: creditAccountDetail?.id,
            });
        }
    };

    const credit_balance = useMemo(() => {
        if (type === 'deposit') {
            return creditAccountDetail?.credit_balance || 0;
        }
        return data?.credit_balance?.available_credit;
    }, [data, creditAccountDetail]);

    const final_available_credits = useMemo(() => {
        const amount = watch('amount') || 0;
        const credit = data?.credit_balance?.available_credit || 0;
        let final_credit = 0;

        if (type === 'deposit') {
            final_credit = Number(credit) + Number(amount);
        } else final_credit = Number(credit) - Number(amount);

        return final_credit;
    }, [watch('amount')]);

    const conditionCredit = useMemo(() => {
        if (type === 'deposit') {
            return watch('amount') > 0;
        }

        if (watch('amount') > 0 && watch('amount') == data?.credit_balance?.available_credit) return true;
        return watch('amount') > 0 && final_available_credits > 0;
    }, [final_available_credits, watch('amount')]);

    useEffect(() => {
        if (type === 'deposit' && !!data) fetchCreditAccountDetail();
    }, [data]);

    const onSubmitForm = (values) => {
        const payload = {
            enrollment_credit_balance_id: data?.credit_balance?.id || null,
            amount: values?.amount >= 0 ? Number(values?.amount) : null,
            note: values?.note || null,
        };
        if (type === 'deposit')
            onDepositCredit({
                credit_account_id: creditAccountDetail?.id,
                ...payload,
            });
        else onRefundCredit(payload);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="text-sm text-gray-800">
                <div className="bg-blue-50 grid grid-cols-2 gap-3 -mx-5 px-5 py-4 border-t border-gray-300">
                    <div>
                        <LabelForm title={translator('ACCOUNT_OWNER')} />
                        <div className="flex items-center space-x-1">
                            <ImageAvatar imageSrc={learner?.avatar_url} />
                            <span className="font-semibold text-enterprise cursor-pointer" onClick={() => handleOpenNewTab('credit_account')}>{learner?.display_name}</span>
                        </div>
                    </div>
                    <div>
                        <LabelForm title={translator('ENROLLMENT')} />
                        <p className="font-semibold text-enterprise cursor-pointer" onClick={() => handleOpenNewTab('enrollment')}>
                            {enrollment?.id}
                        </p>
                    </div>
                    <div className="relative">
                        <InputField
                            name="amount"
                            type="number"
                            register={register('amount', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD', true),
                                },
                                max: {
                                    value: credit_balance,
                                    message: translator('INSUFFICIENT_BALANCE'),
                                },
                                validate: {
                                    insufficientBalance: (value) => {
                                        const amount = value || 0;
                                        const credit = data?.credit_balance?.available_credit || 0;
                                        let final_credit = 0;

                                        if (type === 'deposit') {
                                            final_credit = Number(credit) + Number(amount);
                                        } else final_credit = Number(credit) - Number(amount);

                                        if (type === 'refund' && final_credit < 0) {
                                            return translator('INSUFFICIENT_BALANCE');
                                        }

                                        return true;
                                    },
                                },
                            })}
                            label={translator('NUMBER_OF_CREDIT_S')}
                            placeholder={translator('ENTER_HERE')}
                            isRequired
                            errors={errors}
                            className="w-2/3"
                        />
                        <p className="absolute top-7 right-2 w-14">/{credit_balance}</p>
                    </div>
                    <div>
                        <LabelForm title={translator('FINAL_AVAILABLE_CREDITS')} />
                        <div className="flex items-center space-x-1 truncate mt-[13px]">
                            <span>{data?.credit_balance?.available_credit}</span>
                            <ArrowNarrowRightIcon className="w-4 h-4" />
                            {conditionCredit ? (
                                <span className="font-semibold truncate" style={{ color: type === 'deposit' ? '#16A34A' : '#EF4444' }}>
                                    {final_available_credits}
                                </span>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    </div>
                </div>
                <TextareaField
                    name="note"
                    register={register('note')}
                    label={translator('NOTES')}
                    placeholder={translator('ADD_YOUR_NOTES_HERE')}
                    maxLength={1000}
                    isCountter
                    textLength={watch('note')?.length}
                    className="mt-2 mb-4"
                />
            </div>
            <div className="pt-4 border-t border-gray-300 -mx-5 flex justify-center space-x-4">
                <ButtonOutline onClick={onClose} type={'button'}>
                    {translator('CANCEL', true)}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    disabled={isLoading || !(isDirty && watch('amount') > 0)}
                    className={`${isLoading ? 'bg-ooola_EFF0FA text-ooolab_8083A3 cursor-not-allowed' : ''}`}
                >
                    {translator('CONFIRM', true)}
                </ButtonPrimary>
            </div>
        </form>
    );
};
