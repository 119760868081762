import { useTranslation } from "react-i18next"

const STATUS_LABEL = {
    active: "ACTIVE",
    deactivated: "DEACTIVATED",
    enrolled: "ENROLLED",
    expired: "EXPIRED",
    review: 'REVIEW',
    inactive: 'INACTIVE',
    invite: 'INVITE'
}

export default function EnrollmentStatus({ status, customExpired = false, customDeactived = false }) {
    const { t } = useTranslation()
    return (
        <div className="flex gap-1 items-center">
            <div
                className={`capitalize text-xs text-gray-500 bg-gray-50 border rounded px-2 py-[3px] w-fit
            ${status === 'active' ? '!text-green-500 !bg-green-50 !border-green-300' : ''}
            ${status === 'expired' ? (customExpired ? '!text-orange-500 !bg-orange-50 !border-orange-300' : '!text-red-500 !bg-red-50 !border-red-300') : ''}
            ${status === 'enrolled' ? '!text-blue-500 !bg-blue-50 !border-blue-300' : ''}
            ${status === 'review' ? '!text-orange-500 !bg-orange-50 !border-orange-300' : ''}
            ${status === 'invite' ? '!text-orange-500 !bg-orange-50 !border-orange-300' : ''}
            ${(status === 'deactivated' && customDeactived) ? '!text-red-500 !bg-red-50 !border-red-300' : ''}

        `}
            >
                {t(STATUS_LABEL[status]?.toUpperCase())}
            </div>

        </div>
    )
}
