import {
  configureStore,
  ThunkAction,
  Action
} from '@reduxjs/toolkit';
import logger from 'redux-logger';

import assignmentReducer from './features/assignmentSlice';
import courseReducer from './features/courseSlice';
import learningPathReducer from './features/learningPathSlice';
import librarySlice from './features/librarySlice';
import configPageSlice from './features/configPageSlice';
import bookingSlice from './features/bookingSlice';
import courseTemplateSlice from './features/courseTemplateSlice';
import scormReducer from './features/scormSlice';

export const store = configureStore({
  reducer: {
    assignment: assignmentReducer,
    course: courseReducer,
    learningPath: learningPathReducer,
    library: librarySlice,
    configPage: configPageSlice,
    booking: bookingSlice,
    courseTemplate: courseTemplateSlice,
    scorm: scormReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: [''],
      // Ignore these field paths in all actions
      ignoredActionPaths: ['payload.request', 'payload.config'],
      // Ignore these paths in the state
      ignoredPaths: [''],
      serializableCheck: false,
    },
  }).concat(logger),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
