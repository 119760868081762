// src/TreeNode.js
import { CheckCircleIcon } from '@heroicons/react/solid';
import React, { useState } from 'react';

const ScormMenu = ({ node, currentScorm, onClick }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggle = () => {
        setIsOpen(!isOpen);
        onClick(node);
    };
    if (node?.children?.length === 0 && !node?.is_launchable) {
        return <span className="text-gray-800 my-1 inline-block">{node.title}</span>;
    }
    return (
        <div className={`text-xs ${currentScorm?.id == node.id && node.children?.length === 0 ? 'text-primary-500 bg-blue-50 border-l-2 !border-primary-500' : 'border-transparent'}`}>
            <div className={`h-10  flex items-center gap-2 ${node.children?.length > 0 ? '' : 'pl-5'}`} onClick={toggle} style={{ cursor: 'pointer' }}>
                {node?.user_track?.lesson_status == 'completed' || node?.user_track?.lesson_status == 'passed' ? <><CheckCircleIcon className='w-5 h-5 text-green-500 ml-2' /></> :
                    <span className={`flex flex-none ml-2 w-4 h-4 border border-gray-200 rounded-full`}></span>
                }
                <span>{node.title}</span>
            </div>
            {isOpen && node.children && (
                <>
                    {node.children.map((childNode) => (
                        <div key={childNode.id}>
                            <ScormMenu node={childNode} currentScorm={currentScorm} onClick={onClick} />
                        </div>
                    ))}
                </>
            )}
        </div>
    );
};

export default ScormMenu;
