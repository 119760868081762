import { useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import uploadService from "services/upload.service";
import { changeShowCourseSetting, courseUpdate, updatePeopleNumber } from "store/features/courseSlice";
import { Toastify } from "_shared";
import { InputFile } from "_shared/components/Form/InputFile/InputFile";
import "./CourseSideLeft.scss";
import { CalendarIcon, ClipboardCheckIcon, CogIcon, InformationCircleIcon } from "@heroicons/react/outline";
import { DraftLine, ListOrder, ReportIcon } from "assets/icon";
import PeopleIcon from "assets/icon/PeopleIcon";
import courseService from "services/course.service";
import { checkPermission } from "utils/commonFun";
import { changeShowCourseTemplateSetting, courseTemplateUpdate } from "store/features/courseTemplateSlice";
import { CertificateIcon } from "assets/icon/CertificateIcon";
import { GetWorkspaceContext } from "contexts/Workspace/WorkspaceContext";
import { defaultRole, roleType } from "components/Management/RolesPermissions/RolesPermissionsContants";

export const CourseSideLeft = ({ hasCourse = true }: { hasCourse?: boolean }) => {
    const params: { id: string, courseId: string } = useParams();
    const { data, peopleNumber, hasShowCourseSetting } = useAppSelector((state) => state.course);
    const { data: templateData } = useAppSelector((state) => state.courseTemplate);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { getWorkspaceDetailState } = useContext(
        GetWorkspaceContext
    );
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;

    const checkImageUpload = (file: any) => {
        const mimeType = ['image/jpeg', 'image/png'];
        const limitFileSize = 5 * 1024 * 1024;

        const fileSize = file?.size || 0;
        const fileExtenion = file?.type || '';

        if (!mimeType.includes(fileExtenion) || fileSize > limitFileSize) {
            Toastify.error(t('LEARNING_PATH.LEARNING_PATH_DETAIL.YOUR_IMAGE_SHOULD_BE_UPLOADED_AS_JPG_PNG_JPED_FILES_AND_MUST_BE_LESS_THAN_5MB_IN_SIZE'))
        } else {
            uploadService.uploadFile(
                file,
                async (path: string) => {
                    if (hasCourse) {
                        dispatch(courseUpdate({
                            workspaceId: params?.id,
                            id: params?.courseId,
                            thumbnail: path,
                            title: data?.title,
                        }));
                    } else {
                        dispatch(courseTemplateUpdate({
                            workspaceId: params?.id,
                            id: params?.courseId,
                            thumbnail: path,
                            title: templateData?.title,
                            template_name: templateData?.template_name
                        }));
                    }
                },
                () => { }
            );
        }
    };

    useEffect(() => {
        if (!params?.id || params?.courseId === 'new') {
            return;
        }
        const getCountPeople = () => {
            courseService.getCountPeople(params.id, params.courseId).then((response: any) => {
                dispatch(updatePeopleNumber(response?.data));
            })
        }
        if (hasCourse) {
            getCountPeople();
        }
    }, [params?.courseId, hasShowCourseSetting]);

    const handlePerrmisonRedirect = () => {
        let url = `/workspace/${params.id}/management/courses/${params.courseId}${!hasCourse ? '/template' : ''}?tab=academics&subtab=syllabus`;
        if (checkPermission('courseResource', 'R') && !checkPermission('syllabus', 'R')) {
            url = `/workspace/${params.id}/management/courses/${params.courseId}${!hasCourse ? '/template' : ''}?tab=academics&subtab=resources`;
        }
        return url
    }
    return (
        <div className="courseSideLeft">
            <label className="courseSideLeft-label">{t('COURSES.COURSE_IMAGE')}</label>
            <InputFile onChange={checkImageUpload} imgDefault={data?.thumbnail || templateData?.thumbnail} className="h-[180px] overflow-hidden bg-contain" isDisable={!checkPermission('courseInformation', 'U')} />
            <div className={`courseSideLeft-nav`} id="courseSideLeft-nav" >
                <div className="courseSideLeft-item">
                    <InformationCircleIcon className="w-6 h-6 text-gray-500" />
                    <div className="courseSideLeft-item-content">
                        <div className="courseSideLeft-item-name">{t('COURSES.COURSE_INFORMATION')}</div>
                        < Link to={`/workspace/${params.id}/management/courses/${params.courseId}${!hasCourse ? '/template' : ''}?tab=information`} className="courseSideLeft-item-link">{t('COURSES.UPDATE_INFORMATION')}</Link>
                    </div>
                </div>
                {(checkPermission('courseResource', 'R') || checkPermission('syllabus', 'R')) && <div className="courseSideLeft-item">
                    <ListOrder className="w-6 h-6 text-gray-500" />
                    <div className="courseSideLeft-item-content">
                        <div className="courseSideLeft-item-name">{t('COURSES.ACADEMIC')}</div>
                        <Link to={handlePerrmisonRedirect} className="courseSideLeft-item-link">
                            {t('COURSES.MANAGE_SYLLABUS_AND_RESOURCES')}
                        </Link>
                    </div>
                </div>
                }
                {hasCourse && ((WorkspaceDetailInformation?.membership?.user_role?.name != defaultRole.instructor && WorkspaceDetailInformation?.membership?.user_role?.role_type != roleType.default) || (WorkspaceDetailInformation?.membership?.user_role?.name != defaultRole.instructor && WorkspaceDetailInformation?.membership?.user_role?.role_type === roleType.default)) ? <>
                    <div className="courseSideLeft-item">
                        <PeopleIcon />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('COURSES.PEOPLE')}</div>
                            <div>{peopleNumber?.number_learner} {t('COURSES.LEARNERS')}</div>
                            <div>{peopleNumber?.number_instructor} {t('COURSES.INSTRUCTORS')}</div>
                            <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=people&subtab=learner`} className="courseSideLeft-item-link">{t('COURSES.MANAGE_PEOPLE_AND_ENROLLMENT')}</Link>
                        </div>
                    </div>
                    <div className="courseSideLeft-item">
                        <CalendarIcon className="w-6 h-6 text-gray-500" />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('SESSION.SESSIONS')}</div>
                            <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=scheduling`} className="courseSideLeft-item-link">{t('COURSES.CREATE_AND_MANAGE_SESSION')}</Link>
                        </div>
                    </div>
                    <div className="courseSideLeft-item">
                        <DraftLine size={24} className="text-gray-500" />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('COURSES.ASSIGNMENTS')}</div>
                            <div className="flex flex-col">
                                <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=assignment-management&&subtab=assignments`} className="courseSideLeft-item-link">
                                    {t('COURSES.VIEW_ASSIGMENT')}
                                </Link>
                                <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=assignment-management&&subtab=grades`} className="courseSideLeft-item-link">
                                    {t('COURSES.VIEW_GRADES')}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="courseSideLeft-item">
                        <ClipboardCheckIcon className="w-6 h-6 text-gray-500" />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('COURSES.PROGGESS_HISTORY')}</div>
                            <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=gradebook`} className="courseSideLeft-item-link">
                                {t('COURSES.VIEW_PROGRESS_AND_HISTORY')}
                            </Link>
                        </div>
                    </div>
                    <div className="courseSideLeft-item">
                        <ReportIcon className="w-6 h-6 text-gray-500" />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('COURSES.REPORTS')}</div>
                            <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=reports`} className="courseSideLeft-item-link">
                                {t('COURSES.CREATE_AND_MANAGE_REPORTS')}
                            </Link>
                        </div>
                    </div>
                    <div className="courseSideLeft-item">
                        <CertificateIcon />
                        <div className="courseSideLeft-item-content">
                            <div className="courseSideLeft-item-name">{t('COURSES.CERTIFICATES')}</div>
                            <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=certificate`} className="courseSideLeft-item-link">
                                {t('COURSES.MANAGE_CETIFICATES')}
                            </Link>
                        </div>
                    </div>
                </> : null}
                {(hasCourse && WorkspaceDetailInformation?.membership?.user_role?.name == defaultRole.instructor && WorkspaceDetailInformation?.membership?.user_role?.role_type == roleType.default) &&
                    <>
                        <div className="courseSideLeft-item">
                            <DraftLine size={24} className="text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('COURSES.ASSIGNMENTS')}</div>
                                <div className="flex flex-col">
                                    <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=assignment-management&&subtab=assignments`} className="courseSideLeft-item-link">
                                        {t('COURSES.VIEW_ASSIGMENT')}
                                    </Link>
                                    <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=assignment-management&&subtab=grades`} className="courseSideLeft-item-link">
                                        {t('COURSES.VIEW_GRADES')}
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="courseSideLeft-item">
                            <ReportIcon className="w-6 h-6 text-gray-500" />
                            <div className="courseSideLeft-item-content">
                                <div className="courseSideLeft-item-name">{t('COURSES.REPORTS')}</div>
                                <Link to={`/workspace/${params.id}/management/courses/${params.courseId}?tab=reports`} className="courseSideLeft-item-link">
                                    {t('COURSES.CREATE_AND_MANAGE_REPORTS')}
                                </Link>
                            </div>
                        </div>
                    </>
                }
                {WorkspaceDetailInformation?.membership?.user_role?.name != defaultRole.instructor && WorkspaceDetailInformation?.membership?.user_role?.name != roleType.default && <div className="courseSideLeft-item">
                    <CogIcon className="w-6 h-6 text-gray-500" />
                    <div className="courseSideLeft-item-content">
                        <div className="courseSideLeft-item-name">{t('COURSES.SETTINGS')}</div>
                        <div className="courseSideLeft-item-link" onClick={() => {
                            if (hasCourse) {
                                dispatch(changeShowCourseSetting(true));
                            } else {
                                dispatch(changeShowCourseTemplateSetting(true));
                            }
                        }}>
                            {t('COURSES.CONFIGURE_COURSE')}
                        </div>
                    </div>
                </div>}
            </div>
        </div >
    )
}