import { RestfulService } from './restful.service';
// CONSTANTS
import { HOST_URL } from 'constant/api.const';

const createScormContent = async (workspace_id: string, params?: any) => {
    const res = await RestfulService.postApi(HOST_URL + `/workspaces/${workspace_id}/scorm-contents`, params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getScormContent = async (workspace_id: string, params?: { scorm_content_id: string }) => {
    const res = await RestfulService.getApi(
        HOST_URL + `/workspaces/${workspace_id}/scorm-contents/${params?.scorm_content_id}`,
        params
    );
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getScormContentSCOs = async (workspace_id: string, params?: any) => {
    const res = await RestfulService.getApi(
        HOST_URL + `/workspaces/${workspace_id}/scorm-contents/${params?.scorm_content_id}/scos`,
        params
    );
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const launchScormSCO = async (workspace_id: string, params?: { scorm_content_id: string; scorm_sco_id: string }) => {
    const res = await RestfulService.getApi(
        HOST_URL + `/workspaces/${workspace_id}/scorm-contents/${params.scorm_content_id}/scos/${params.scorm_sco_id}/launch`,
        params
    );
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const trackScormSco = async (workspace_id: string, params?: any) => {
    const res = await RestfulService.postApi(
        HOST_URL + `/workspaces/${workspace_id}/scorm-contents/${params?.scorm_content_id}/scos/${params?.scorm_sco_id}/track`,
        params
    );
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

export default {
    createScormContent,
    getScormContent,
    getScormContentSCOs,
    launchScormSCO,
    trackScormSco,
};
