import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/outline';
import { ButtonBack, ButtonOutline, ButtonPrimary } from '_shared';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import Spinner from 'components/Spinner';
import FooterPage from 'components/Footer/FooterPage';
import { ModalDepositRefund } from './ModalDepositRefund';
import { ModalTransactionDetail } from './ModalTransactionDetail';
import { TableTransaction } from './TableTransaction';
import { checkPermission, classNames, getIndexPage, isEmptyNullUndefine } from 'utils/commonFun';
import DialogComponent from 'components/Dialog/DialogComponent';
import courseService from 'services/course.service';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { useUrlUserNavigation } from 'hooks/useUrlUserNavigation';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

const transactionTab = {
    all: undefined,
    incoming: 'increase',
    outgoing: 'decrease',
};

const CourseCreditBalance = () => {
    const params: any = useParams();
    const history = useHistory();
    const { t } = useTranslation();
    const query = useQueryParamsURL();

    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState(null);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });
    const [selectedRow, setSelectedRow] = useState(null);
    const [transactionType, setTransactionType] = useState<'all' | 'incoming' | 'outgoing'>('all');
    const [selectedDepositRefund, setSelectedDepositRefund] = useState<'deposit' | 'refund'>(null);
    const [creditAccountDetail, setCreditAccountDetail] = useState(null);

    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const creditId = query.get('creditId') || null;
    const enrollment_id = query.get('enrollment_id') || null;
    const membership_id = query.get('membership_id') || null;

    const { openInNewTabUser } = useUrlUserNavigation({ user_id: creditAccountDetail?.user?.id });
    const { openInNewTabCourse } = useUrlCourseNavigation();

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`COURSES.CREDIT_BALANCE.${str}`, opt));

    const fetchCreditDetail = () => {
        if (!!creditId) {
            courseService.getCreditBalanceIndividual(workspaceId, creditId).then((res) => {
                setDetail(res?.data || null);
                if (res?.data) fetchCreditAccountDetail(res?.data);
            });
        } else if (enrollment_id && membership_id) {
            courseService
                .getCreditBalanceLearningGroup(workspaceId, { enrollment_id: enrollment_id, membership_id: membership_id })
                .then((res) => {
                    setDetail(res?.data || null);
                    if (res?.data) fetchCreditAccountDetail(res?.data);
                });
        }
    };
    console.log(creditAccountDetail)
    const fetchTransactions = (page: number | string = 1) => {
        setLoading(true);

        const payload = {
            ...pagination,
            txn_type: transactionTab[transactionType],
            page,
        };
        delete payload.total;

        courseService
            .getTransactionCreditBalance(workspaceId, detail?.credit_balance?.id, payload)
            .then((res) => {
                if (res?.data) {
                    const { items, page, per_page, order, total, sort_by } = res?.data;
                    setData(items);
                    setPagination({ page, per_page, order, total, sort_by });
                    setIndexPage(getIndexPage(page, per_page, items?.length));
                }
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const fetchCreditAccountDetail = (creditDetail) => {
        courseService
            .getCreditAccounts(workspaceId, {
                account_type: creditDetail?.course_enrollment?.type === 'individual' ? 'personal' : 'learning_group',
                learning_group_id: creditDetail?.course_enrollment?.type === 'learning_group' ? creditDetail?.learning_group?.id : null,
                membership_id: creditDetail?.user?.membership_id || null,
            })
            .then((res) => {
                if (!!res?.data?.items?.length) {
                    const dataCreditAccountDetail = res?.data?.items?.find(item => item?.user?.membership_id === creditDetail?.user?.membership_id);
                    !!dataCreditAccountDetail && setCreditAccountDetail(dataCreditAccountDetail || null);
                }
            });
    };

    const handleOpenNewTab = (to) => {
        if (to === 'learner') {
            openInNewTabUser({ tab: 'information' });
        } else if (to === 'enrollment') {
            openInNewTabCourse({ 
                tab: 'people',
                subtab: 'learner',
                enrollmentId: detail?.course_enrollment?.id,
                view: 'information'
            });
        } else if (to === 'credit_account') {
            openInNewTabUser({ 
                tab: 'credit-accounts',
                creditId: creditAccountDetail?.id,
            });
        }
    };

    useEffect(() => {
        fetchCreditDetail();
    }, []);

    useEffect(() => {
        if (!!detail) fetchTransactions();
    }, [detail, transactionType]);

    return (
        <div className="text-gray-800 text-sm overflow-auto">
            <div className="pt-4 px-5 flex items-center space-x-2.5">
                {/* <ButtonBack hideLabel={true} onClick={() => history.goBack()} /> */}
                <h1 className="text-base font-semibold">{translator('CREDIT_BALANCE')}</h1>
            </div>
            <div className="px-5 py-4">
                <p className="truncate">
                    <span className="text-gray-500 mr-1">{translator('LEARNER')}:</span>
                    {!!detail && !!detail?.user ? (
                        <span
                            className="font-semibold text-enterprise truncate cursor-pointer"
                            onClick={() => handleOpenNewTab('learner')}
                        >
                            {detail?.user?.display_name}
                        </span>
                    ) : (
                        '-'
                    )}
                </p>
                <p className="truncate">
                    <span className="text-gray-500 mr-1">{translator('ENROLLMENT')}:</span>
                    {!!detail && !!detail?.course_enrollment ? (
                        <span
                            className="font-semibold text-enterprise truncate cursor-pointer"
                            onClick={() => handleOpenNewTab('enrollment')}
                        >
                            {detail?.course_enrollment?.id}
                        </span>
                    ) : (
                        '-'
                    )}
                </p>
                <p className="truncate">
                    <span className="text-gray-500 mr-1">{t('Associated with Credit Account')}:</span>
                    {!!detail && !!detail?.course_enrollment && (!!detail?.learning_group || !!detail?.user) ? (
                        <span
                            className={!!creditAccountDetail ? "font-semibold text-enterprise truncate cursor-pointer" : "font-semibold"}
                            onClick={() => !!creditAccountDetail && handleOpenNewTab('credit_account')}
                        >
                            {detail?.course_enrollment?.type === 'individual' ? detail?.user?.display_name : detail?.learning_group?.name}
                        </span>
                    ) : (
                        '-'
                    )}
                </p>
            </div>
            <div className="border-y border-gray-300 px-5 py-4 flex flex-col xl:flex-row xl:items-center xl:justify-between">
                <div className="flex items-center space-x-2 pr-4">
                    <div className="bg-gray-50 w-48 rounded-lg px-4 py-2.5 flex justify-between items-center">
                        <p className="flex items-center space-x-1">
                            <span className="text-gray-500 font-semibold">{translator('ENROLLED_CREDITS')}</span>
                            <InformationCircleIcon
                                className="w-5 h-5 cursor-pointer"
                                data-for="TOOLTIP_ENROLLED_CREDITS"
                                data-tip={translator('TOOLTIP_ENROLLED_CREDITS')}
                            />
                            <TooltipCustom
                                classNameContainer="shadow-medium"
                                id="TOOLTIP_ENROLLED_CREDITS"
                                content={translator('TOOLTIP_ENROLLED_CREDITS')}
                                place="top"
                                type="light"
                            />
                        </p>
                        <p className="text-base">
                            {!isEmptyNullUndefine(detail?.credit_balance?.total_credit) ? detail?.credit_balance?.total_credit : '-'}
                        </p>
                    </div>
                    <div className="bg-gray-50 w-48 rounded-lg px-4 py-2.5 flex justify-between items-center">
                        <p className="flex items-center space-x-1">
                            <span className="text-gray-500 font-semibold">{translator('USED_CREDITS')}</span>
                            <InformationCircleIcon
                                className="w-5 h-5 cursor-pointer"
                                data-for="TOOLTIP_USED_CREDITS"
                                data-tip={translator('TOOLTIP_USED_CREDITS')}
                            />
                            <TooltipCustom
                                classNameContainer="shadow-medium"
                                id="TOOLTIP_USED_CREDITS"
                                content={translator('TOOLTIP_USED_CREDITS')}
                                place="top"
                                type="light"
                            />
                        </p>
                        <p className="text-base">
                            {!isEmptyNullUndefine(detail?.credit_balance?.total_credit) && !isEmptyNullUndefine(detail?.credit_balance?.available_credit)
                                ? detail?.credit_balance?.total_credit - detail?.credit_balance?.available_credit
                                : '-'}
                        </p>
                    </div>
                    <div className="bg-gray-50 w-48 rounded-lg px-4 py-2.5 flex justify-between items-center">
                        <p className="flex items-center space-x-1">
                            <span className="text-gray-500 font-semibold">{translator('AVAILABLE_CREDITS')}</span>
                        </p>
                        <p className="text-base">
                            {!isEmptyNullUndefine(detail?.credit_balance?.available_credit)
                                ? detail?.credit_balance?.available_credit
                                : '-'}
                        </p>
                    </div>
                </div>
                <div className="pt-4 xl:pt-0 flex items-center space-x-2 xl:pl-4 xl:border-l border-gray-300">
                    {checkPermission('courseCreditBalance', 'U') && (
                        <ButtonOutline
                            onClick={() => setSelectedDepositRefund('refund')}
                            type="button"
                            disabled={detail?.credit_balance?.available_credit <= 0}
                            className="disabled:opacity-50"
                        >
                            {translator('REFUND')}
                        </ButtonOutline>
                    )}
                    {checkPermission('courseCreditBalance', 'U') && (
                        <ButtonPrimary type="button" onClick={() => setSelectedDepositRefund('deposit')}>
                            <PlusIcon className="w-4 h-4 text-white" />
                            {translator('DEPOSIT')}
                        </ButtonPrimary>
                    )}
                </div>
            </div>
            <div className="border-b border-gray-300 px-5 flex items-center">
                <p
                    className={classNames(
                        'px-4 py-3 cursor-pointer text-gray-500 font-semibold border-b',
                        transactionType === 'all' ? 'border-enterprise !text-enterprise' : ''
                    )}
                    onClick={() => setTransactionType('all')}
                >
                    {translator('ALL')}
                </p>
                <p
                    className={classNames(
                        'px-4 py-3 cursor-pointer text-gray-500 font-semibold border-b',
                        transactionType === 'incoming' ? 'border-enterprise !text-enterprise' : ''
                    )}
                    onClick={() => setTransactionType('incoming')}
                >
                    {translator('INCOMING')}
                </p>
                <p
                    className={classNames(
                        'px-4 py-3 cursor-pointer text-gray-500 font-semibold border-b',
                        transactionType === 'outgoing' ? 'border-enterprise !text-enterprise' : ''
                    )}
                    onClick={() => setTransactionType('outgoing')}
                >
                    {translator('OUTGOING')}
                </p>
            </div>
            <div className="px-5 pt-4">
                <div className="h-[calc(100vh-496px)] custom-scrollbar overflow-auto">
                    {loading ? (
                        <div className="h-[calc(100vh-496px)] flex justify-center items-center">
                            <Spinner />
                        </div>
                    ) : (
                        <TableTransaction
                            workspaceId={workspaceId}
                            courseId={courseId}
                            dataTable={data}
                            translator={translator}
                            onTransactionDetail={(values) => setSelectedRow(values)}
                        />
                    )}
                </div>
                <FooterPage
                    indexPage={indexPage}
                    total={pagination.total}
                    per_page={pagination.per_page}
                    page={pagination.page}
                    callback={(page) => fetchTransactions(page)}
                />
            </div>
            <DialogComponent
                isOpen={!!selectedDepositRefund}
                onCloseModal={() => setSelectedDepositRefund(null)}
                title={translator(selectedDepositRefund === 'deposit' ? 'DEPOSIT_CREDITS' : 'REFUND_CREDITS')}
                styles="max-w-md"
                child={
                    <ModalDepositRefund
                        translator={translator}
                        type={selectedDepositRefund}
                        data={detail}
                        onClose={() => setSelectedDepositRefund(null)}
                        onConfirm={() => {
                            setSelectedDepositRefund(null);
                            fetchCreditDetail();
                        }}
                    />
                }
            />
            <DialogComponent
                isOpen={!!selectedRow}
                onCloseModal={() => setSelectedRow(null)}
                title={translator('TRANSACTION_DETAILS')}
                styles="max-w-lg"
                child={
                    <ModalTransactionDetail                       
                        translator={translator}
                        workspaceId={workspaceId}
                        creditDetail={detail}
                        transactionDetail={selectedRow} 
                    />
                }
            />
        </div>
    );
};

export default CourseCreditBalance;
