import React, { FC, useState } from 'react';
import { Row, Column } from 'react-table';
import Table, { RenderTableHeader, RenderTableCell, LinkCustom } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ListUser } from '_shared/components/User/User';
import { useParams } from 'react-router-dom';
import ActionMenu from './ActionMenu';

type TableCompanyProps = {
    workspaceId: string;
    dataTable: any;
    translator: any;
    setAscName?: Function;
    isAscName?: boolean;
    refreshData?: Function
};
const StatusComponent = ({status, translator}) => {
    let classStatus = '';
    if (status == 'enrolled') {
        classStatus = 'text-primary-500 border-primary-300 bg-primary-50';
    }
    if (status == 'active') {
        classStatus = 'text-green-500 border-green-300 bg-green-50';
    }
    if (status == 'expired') {
        classStatus = 'text-red-500 border-red-300 bg-red-50';
    }
    if (status == 'deactivated') {
        classStatus = 'text-gray-500 border-gray-300 bg-gray-50';
    }
    return <div className={`capitalize rounded border inline-block text-sm py-[1px] px-2 ${classStatus}`}>
        {translator(status?.toUpperCase(), true)}
    </div>
}
const TableCompany: FC<TableCompanyProps> = ({ workspaceId, dataTable, translator, setAscName, isAscName, refreshData}) => {
    const initialState = {
        hiddenColumns: ['id'],
    };

    const params: {id} = useParams();

    const columns = React.useMemo(
        () => [
            { accessor: 'id' },
            {
                Header: () => (
                    <div className='flex gap-1 items-center h-full'>
                        <RenderTableHeader isOrder onClick={() => setAscName(!isAscName)} value={translator('Course_Enrollment')} />
                    </div>
                ),
                Cell: ({ row }: { row: Row; column: Column }) => {
                    return (
                        <LinkCustom
                            to={`/workspace/${workspaceId}/management/courses/${row.values?.course?.id}??tab=people&enrollmentId=${row.values.id}&view=members`}
                            isTooltip
                            value={row.values?.course?.title}
                            disable={row.values?.course?.title?.length < 35}
                            className=" pr-2"
                            target='_blank'
                        />
                    );
                },
                accessor: 'course',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('MEMBERS')} />,
                Cell: ({ row }: { row: Row; column: Column }) => {
                    return (
                        <ListUser 
                            id={"user-enrollment-group"} 
                            users={row?.values?.members?.map((member) => {
                                return {
                                    ...member,
                                    link: `/workspace/${params?.id}/management/users/${member?.id}`,
                                }
                            })}
                            onViewUser={() => handleShowPopup(row?.values?.members, "Members")}
                        />
                    )
                },
                accessor: 'members',
                width: 150,
            },
            {
                Header: () => <RenderTableHeader value={translator('ENROLLMENT_START_DATE')} />,
                Cell: ({ row }: { row: Row}) => {
                    return (
                            <RenderTableCell value={row.values?.start_date && dayjs(row.values?.start_date).format("DD/MM/YYYY")} />
                    )
                },
                accessor: 'start_date',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('ENROLLMENT_END_DATE')} />,
                Cell: ({ row }: { row: Row; }) => {
                    return (
                            <RenderTableCell value={row.values?.end_date && dayjs(row.values?.end_date).format("DD/MM/YYYY")} />
                    )
                    
                },
                accessor: 'end_date',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('ENROLLED_DATE')} />,
                Cell: ({ row }: { row: Row }) => {
                    return (
                            <RenderTableCell value={dayjs(row.values?.enrolled_date).format("DD/MM/YYYY")} />
                    );
                },
                accessor: 'enrolled_date',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={translator('STATUS', true)} />,
                Cell: ({ row }: { row: Row}) => {
                    return (
                            <StatusComponent status={row.values?.display_status} translator={translator} />
                    );
                },
                accessor: 'display_status',
                width: 140,
            },

            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={row?.original}
                                refreshData={refreshData}
                                position={row?.index > dataTable?.length/2 ? 'bottom-0' : ''}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 40, 
            },
        ],
        [dataTable]
    );

    const [dataPopup, setDataPopup] = useState({
        list: [],
        title: ""
    })

    const [isOpenPopup, setOpenPopup] = useState<boolean>(false)

    const handleShowPopup = (list, title: string) => {
        setOpenPopup(true)
        setDataPopup({ list, title })
    }

    return (
        <>
            <Table
                isBorderFirstColum={false}
                data={dataTable}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('NO_DATA')}
            />
            <DialogComponent
                isOpen={isOpenPopup}
                onCloseModal={() => setOpenPopup(false)}
                title={dataPopup?.title}
                styles="!max-w-lg"
                child={(
                    <div className=' px-4 max-h-ooolab_h_100 overflow-auto'>
                        <div className=' flex gap-2 flex-col'>
                            {dataPopup?.list?.map((el) => {
                                return (
                                    <div
                                        key={el?.id} className="flex items-center cursor-pointer"
                                        onClick={() => window.open(`/workspace/${params?.id}/management/users/${el?.id}`, '_blank')}
                                    >
                                        <img className=' bg-dark-100 rounded-full border-2 border-white w-5 h-5 object-cover object-center -ml-3' src={el?.avatar_url} alt="" />
                                        <span className='ml-1 text-xs font-normal text-truncate'>{el?.display_name}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default TableCompany;
