import React from 'react';
import './invoice-receipt.scss';

export const PreviewInvoice = ({ company_name, company_logo, company_address, company_tax_code, image, support_phone, support_email }) => {
    return (
        <div className="pdf-format invoice">
            <div className="header-pdf">
                <div className="company">
                    <div>
                        {image || company_logo ? (
                            <img src={(image && URL.createObjectURL(image)) || company_logo} alt="logo" className="logo" />
                        ) : (
                            <span className="logo inline-block"></span>
                        )}
                        <h1 className="text-enterprise text-[28px] font-semibold leading-7">INVOICE</h1>
                    </div>
                    <div className="company-info">
                        <h1 className="company-info-name">&nbsp;{company_name}</h1>
                        <p className="company-info-address" dangerouslySetInnerHTML={{__html: company_address?.replaceAll('\n', '<br />')}}></p>
                        <p className="company-info-tax">&nbsp;{company_tax_code}</p>
                    </div>
                </div>
                <div className="bill">
                    <div className="learner">
                        <p className="billed-to">Billed to</p>
                        <p className="learner-name">Learner Name</p>
                    </div>
                    <div className="bill-info">
                        <p className="bill-info-item">
                            <span>Invoice Date</span>
                            <span>31/12/2023</span>
                        </p>
                        <p className="bill-info-item">
                            <span>Invoice ID</span>
                            <span>123456789</span>
                        </p>
                        <p className="bill-info-item">
                            <span>Due Date</span>
                            <span>10/01/2024</span>
                        </p>
                    </div>
                </div>
                <div className="table">
                    <div className="table-header">
                        <div className="col col-1">Description</div>
                        <div className="col col-2">Quantity</div>
                        <div className="col col-3">Unit Price</div>
                        <div className="col col-4">Total</div>
                    </div>
                    <div className="table-body">
                        <div className="row">
                            <div className="col col-1 product-name">EVL23 12001_Codmos Full Course </div>
                            <div className="col col-2">1</div>
                            <div className="col col-3">$400.00 USD</div>
                            <div className="col col-4">$400.00 USD</div>
                        </div>
                        <div className="row">
                            <div className="col col-1 product-name">EVL23 12001_Codmos Full Course </div>
                            <div className="col col-2">1</div>
                            <div className="col col-3">$400.00 USD</div>
                            <div className="col col-4">$400.00 USD</div>
                        </div>
                    </div>
                    <div className="table-footer">
                        <div className="row">
                            <div className="col col-1"></div>
                            <div className="col col-2"></div>
                            <div className="col col-3">Sub Total</div>
                            <div className="col col-4">$800.00 USD</div>
                        </div>
                        <div className="row">
                            <div className="col col-1"></div>
                            <div className="col col-2"></div>
                            <div className="col col-3">Total VAT</div>
                            <div className="col col-4">-</div>
                        </div>
                        <div className="row">
                            <div className="col col-1"></div>
                            <div className="col col-2"></div>
                            <div className="col col-3">Total Amount</div>
                            <div className="col col-4">$800.00 USD</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-pdf">
                <div className="thank">
                    <p className="text-gray-800 font-semibold">Thank you for the business!</p>
                    <p className="mt-1 text-gray-500">Please pay within 15 days of receiving this invoice.</p>
                </div>
                <div className="payment-detail text-gray-500">
                    <p className="font-semibold text-gray-800">Payment details</p>
                    <p>MICROSOFT CORPORATION</p>
                    <p>5035 2608 9301 - USD</p>
                    <p>0CBCSGSGXXX</p>
                    <p>0CBCSGSGXXX</p>
                    <p className="mt-4">
                        <span>{support_phone || '+91 00000 00000'}</span>
                        <span className="text-gray-200 mx-2">|</span>
                        <span>{support_email || 'hello@email.com'}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};
