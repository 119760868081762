export const GoogleIcon = ({ className = "" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      className={className}
    >
      <g clipPath="url(#clip0_2052_1993)">
        <path
          fill="#4285F4"
          d="M20.103 10.23c0-.68-.055-1.364-.173-2.032h-9.432v3.85H15.9a4.63 4.63 0 0 1-2 3.04v2.498h3.223c1.892-1.742 2.98-4.314 2.98-7.356"
        ></path>
        <path
          fill="#34A853"
          d="M10.498 20c2.697 0 4.972-.886 6.629-2.414l-3.222-2.499c-.897.61-2.054.955-3.403.955-2.609 0-4.82-1.76-5.615-4.126H1.562v2.576A10 10 0 0 0 10.498 20"
        ></path>
        <path
          fill="#FBBC04"
          d="M4.883 11.916a6 6 0 0 1 0-3.828V5.512H1.562a10.01 10.01 0 0 0 0 8.98z"
        ></path>
        <path
          fill="#EA4335"
          d="M10.498 3.958a5.43 5.43 0 0 1 3.836 1.499l2.855-2.855A9.6 9.6 0 0 0 10.5 0a10 10 0 0 0-8.937 5.512l3.322 2.576c.79-2.37 3.005-4.13 5.614-4.13"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2052_1993">
          <path fill="#fff" d="M.5 0h19.603v20H.5z"></path>
        </clipPath>
      </defs>
    </svg>
  );
  
  export const AppleIcon = ({ className = "" }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="20"
      fill="none"
      viewBox="0 0 17 20"
      className={className}
    >
      <g clipPath="url(#clip0_2052_3223)">
        <path
          fill="#000"
          d="M15.99 6.818c-.113.09-2.126 1.244-2.126 3.81 0 2.968 2.561 4.018 2.638 4.044-.012.064-.407 1.438-1.35 2.838-.842 1.232-1.72 2.462-3.057 2.462s-1.68-.79-3.224-.79c-1.503 0-2.038.816-3.26.816-1.223 0-2.076-1.14-3.057-2.54C1.418 15.814.5 13.26.5 10.836c0-3.888 2.485-5.95 4.93-5.95 1.299 0 2.382.868 3.198.868.776 0 1.987-.92 3.465-.92.56 0 2.573.052 3.898 1.984m-4.599-3.63c.612-.738 1.044-1.762 1.044-2.786 0-.142-.012-.286-.037-.402-.995.038-2.178.674-2.892 1.516-.56.648-1.083 1.672-1.083 2.71 0 .156.026.312.038.362.063.012.165.026.267.026.892 0 2.015-.608 2.663-1.426"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_2052_3223">
          <path fill="#fff" d="M.5 0h16v20H.5z"></path>
        </clipPath>
      </defs>
    </svg>
  );
  