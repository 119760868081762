
import React, { useEffect, useRef, useState } from "react";
import { DeleteIcon, ReorderAlt } from "assets/icon";
import { LIBRARY_ICONS } from "components/Library/LibraryContants";
import { useTranslation } from "react-i18next";
import { checkPermission } from "utils/commonFun";

export const AssignmentItem = ({
    values,
    hasCurrentSelect,
    idx,
    onDragOverContent,
    onDragContentStart,
    onDragContentEnd,
    onDelete,
    onUpdateName,
    onSelectContent
}) => {
    const { t: translator } = useTranslation();
    const refInput = useRef<any>();
    const [hasEdit, setHasEdit] = useState(false);
    const [value, setValue] = useState<any>(values?.library_content?.name);

    const onEditName = (e) => {
        e.stopPropagation();
        setHasEdit(true);
    };
    const handleUpdate = (value) => {
        onUpdateName({
            title: value,
            name: value,
            sectionId: values?.id,
            id: values?.library_content?.object_id,
            type: values?.library_content?.object_type
        })
    }

    useEffect(() => {
        if (hasEdit && refInput?.current) {
            refInput.current.focus();
        }
    }, [hasEdit]);

    return (
        <div key={values?.id}>
            <div
                className={`assignmentItem ${hasCurrentSelect ? 'assignmentItem--actived' : ''}`}
                draggable={checkPermission('libraryContentAssignment', 'U')}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSelectContent(values);
                }}
                onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onDragOverContent(idx);
                }}
                onDragStart={(e) => {
                    e.stopPropagation();
                    onDragContentStart(e, idx);
                }}
                onDragEnd={(e) => {
                    e.stopPropagation();
                    onDragContentEnd();
                }}
            >
                <div className="assignmentItem-content">
                    <button className="assignmentItem-drag cursor-move">
                        <ReorderAlt />
                    </button>
                    <img
                        alt="assignmentItem-icon"
                        className="assignmentItem-icon"
                        src={
                            values?.library_content?.object_type
                                ? LIBRARY_ICONS(translator)?.find((item) => item?.value == values?.library_content?.object_type)?.icon
                                : LIBRARY_ICONS(translator)[0].icon
                        }
                    />
                    <div className="assignmentItem-name">
                        {!hasEdit ? (
                            <button className="truncate cursor-text" onClick={(e) => onEditName(e)} disabled={!checkPermission('libraryContentAssignment', 'U')}>
                                {values?.library_content?.name}
                            </button>
                        ) : (
                            <input
                                ref={refInput}
                                value={value}
                                className="assignmentItem-input"
                                onFocus={(e) => e.stopPropagation()}
                                onBlur={(e) => {
                                    handleUpdate(e.target.value);
                                    setHasEdit(false);
                                }}
                                onKeyDown={(e: any) => {
                                    e.code === 'Space' && e.stopPropagation();
                                    if (e.code === 'Enter') {
                                        refInput.current.blur();
                                        e.stopPropagation()
                                    }
                                }}
                                onChange={(event) => {
                                    setValue(event.target.value);
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                style={{ width: value?.length + 'ch' }}
                                disabled={!checkPermission('libraryContentAssignment', 'U')}
                            />
                        )}
                    </div>
                </div>
                {checkPermission('libraryContentAssignment', 'U') ? <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onDelete()
                    }}
                    className="assignmentItem-delete !text-red-500"
                >
                    <DeleteIcon width="16px" height="16px" />
                </button> : ''}

            </div>
            {
                value?.length > 255 && (
                    <div className="text-red-500 text-xs font-normal ml-14">
                        {translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 255 })}
                    </div>
                )
            }
        </div >
    )
}