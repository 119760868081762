import { propertyType } from 'components/WorkspaceSetting/Property/components/propertyCommon';
import { FORM_CONST } from 'constant/form.const';
import { useTranslation } from 'react-i18next';
import propertyService from 'services/property.service';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import isArray from 'lodash/isArray';
import Spinner from 'components/Spinner';
import { isEmpty } from 'lodash';

interface Props {
    formState: any;
    watch: any;
    control: any;
    register: any;
    setValue: any;
    isViewAllProperties: boolean;
    properties: any;
    isInitiated: boolean;
    defaultProperties: any;
}

const PER_PAGE = 10;

export default function Properties({ formState, watch, control, register, properties, setValue, isViewAllProperties, isInitiated, defaultProperties }: Props) {
    return (
        <div>
            {
                !isInitiated ? (
                    <Spinner />
                )
                    :
                    properties?.map((item: any) => {
                        return (
                            <div key={"property" + item?.name}>
                                <PropertyComponent
                                    property={item}
                                    register={register}
                                    control={control}
                                    formState={formState}
                                    watch={watch}
                                    isViewAllProperties={isViewAllProperties}
                                    setValue={setValue}
                                    defaultProperties={defaultProperties}
                                />
                            </div>
                        );
                    })
            }
        </div>
    );
}

const formatOption = (data, parentOptionId = undefined) => {
    return data?.map((item) => ({
        name: item?.label,
        value: item?.id,
        parentOptionId
    }));
}

const PropertyComponent = ({ property, register, control, formState, watch, isViewAllProperties, setValue, defaultProperties }) => {
    const { t } = useTranslation();
    const [isFirstCallApi, setFirstCallApi] = useState(true);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState<any>([]);
    const params: { id: string } = useParams();
    const [pagination, setPagination] = useState<any>({
        page: 1,
        total: 0,
    });

    const name = `properties[${property?.name}].value`;
    const parentValue = watch(`properties.${property?.parent?.name}.value`);

    const getChildOptions = (data) => {
        const childOptions = data?.map((item) => {
            const children = item?.children.find((child) => child?.id === property?.property_id);
            if (!children) return;
            return formatOption(children?.options, item?.id);
        });
        const childOptionsFlat = [];
        childOptions?.forEach((item) => {
            item?.forEach((child) => {
                if (childOptionsFlat.find((item) => item?.value === child?.value)) return;
                childOptionsFlat.push(child);
            });
        });
        return childOptionsFlat;
    }

    const getPropertyOptions = (page: number = 1) => {
        setLoading(true);
        const param = {
            page: page,
            per_page: PER_PAGE,
        };
        let propertyId = property?.property_id;
        if (property?.parent?.property_type === propertyType?.single_selection?.value || property?.parent?.property_type === propertyType?.multiple_selection?.value) {
            if (parentValue?.length > 0) {
                param['option_ids'] = parentValue?.map((item) => item?.value);
                propertyId = property?.parent?.property_id
            }
        }
        propertyService
            .getOptions(params?.id, propertyId, param)
            .then((res) => {
                let optionFormated = [];
                if (property?.parent?.property_id &&
                    (property?.parent?.property_type === propertyType?.single_selection?.value ||
                        property?.parent?.property_type === propertyType?.multiple_selection?.value)
                ) {
                    optionFormated = getChildOptions(res?.data?.items);
                } else {
                    optionFormated = formatOption(res?.data?.items)
                }
                setFirstCallApi(false);
                if (page === 1) setOptions(optionFormated);
                if (page > 1) {
                    setOptions([...options, ...optionFormated]);
                }
                setPagination({ total: res?.data?.total, page: res?.data?.page });
            })
            .finally(() => setLoading(false));
    };

    const Component = propertyType?.[property?.property_type]?.component;
    let validate = undefined;
    let maxLength = 300;

    if (property?.property_type === propertyType.single_line_text.value) {
        maxLength = 300;
        validate = {
            maxLength: {
                value: 300,
                message: t('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 300 }),
            },
        };
    }

    if (property?.property_type === propertyType.multi_line_text.value) {
        maxLength = 1000;
        validate = {
            maxLength: {
                value: 1000,
                message: t('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 1000 }),
            },
        };
    }

    if (property?.property_type === propertyType.email.value) {
        maxLength = 255
        validate = {
            pattern: {
                value: FORM_CONST.EMAIL_REGEX,
                message: t('FORM_CONST.EMAIL_VALIDATE'),
            },
            maxLength: {
                value: 255,
                message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
            },
        };
    }

    useEffect(() => {
        if (property.parent?.name) {
            if (isEmpty(watch(`properties[${property?.parent?.name}].value`))) {
                setValue(`properties[${property?.name}].value`, null);
            }
            if (property?.parent?.property_type === propertyType.multiple_selection.value) {
                if (defaultProperties?.[property?.parent?.name]?.value?.length !== watch(`properties[${property?.parent?.name}].value`)?.length) {
                    setValue(`properties[${property?.name}].value`, null);
                }
            }
            if (property?.parent?.property_type === propertyType.single_selection.value) {
                if (defaultProperties?.[property?.parent?.name]?.value?.[0]?.value !== watch(`properties[${property?.parent?.name}].value`)?.[0]?.value) {
                    setValue(`properties[${property?.name}].value`, null);
                }
            }

        }
    }, [watch(`properties[${property?.parent?.name}].value`)]);

    //------condition to show property--------

    if (!isViewAllProperties) {
        if (!property?.is_visible) return null;
        if (property?.parent?.name && !property?.parent?.is_visible) {
            return null;
        }
    }
    if (property?.parent?.name) {
        if (isArray(parentValue) && !parentValue?.length) {
            return null;
        }
        if (!parentValue) {
            return null;
        }
    }

    return (
        <div className="pb-5">
            <div>
                {property?.property_type === propertyType.date_picker.value && (
                    <label htmlFor="dob" className="text-gray-800 text-xs font-semibold">
                        {property?.label}
                    </label>
                )}
                <div>
                    <Component
                        //common props
                        name={name}
                        label={property?.label}
                        control={control}
                        register={register(name, { ...validate })}
                        errors={formState?.errors}
                        isRequired={property?.is_required}
                        classNameInput="!h-8"
                        maxLength={maxLength}
                        textLength={watch(name)?.length}
                        isCountter={property.property_type === propertyType.multi_line_text.value}
                        //for DatePickerInput
                        labelCls="text-ooolab_text_username"
                        paddingCls="py-2 px-4"
                        iconCls="top-[10px]"
                        positionIcon="right"
                        placeholderText={"Select Date"}
                        //for Select
                        onClick={() => getPropertyOptions()}
                        className={
                            propertyType?.single_selection?.value === property?.property_type ||
                                propertyType?.multiple_selection?.value === property?.property_type
                                ? '!h-8'
                                : ''
                        }
                        pagination={pagination}
                        loading={loading}
                        getData={getPropertyOptions}
                        value={watch(name)}
                        data={options}
                        numberOfItemDisplayed={5}
                        isMulti={propertyType?.multiple_selection?.value === property?.property_type}
                        placeholder={
                            property?.property_type === propertyType.multi_line_text?.value ||
                                property?.property_type === propertyType.single_line_text?.value ||
                                propertyType.phone_number
                                ? 'Enter ' + property?.label
                                : 'Select ' + property?.label
                        }
                        formatOptionLabel={(data) => <div className="text-xs">{data?.name}</div>}
                        formatoptionValue={(data) => <div className="text-xs">{data?.value}</div>}
                    />
                </div>
            </div>
        </div>
    );
};
