import { useState } from 'react';
import DialogComponent from 'components/Dialog/DialogComponent';
import libraryService from 'services/library.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Toastify } from '_shared';
import { ButtonOutline, ButtonPrimary } from '_shared';

export default function ModalCreateFolder({ onClose, isOpen, refreshData }) {
    const [folderName, setFolderName] = useState('');
    const params: any = useParams();
    const { t } = useTranslation();

    const handleCreateFolder = () => {
        const payload = {
            name: folderName,
            object_type: 'folder',
            parent_id: params?.folderId ? parseInt(params?.folderId)  : null,
        };
        !params?.folderId && delete payload?.parent_id;

        libraryService
            .createFolder(params?.id, payload)
            .then(() => {
                setFolderName('');
                onClose(false);
                Toastify.success();
                refreshData(1);
            })
            .catch(() => Toastify.error());
    };

    return (
        <DialogComponent
            title={t('LIBRARY.NEW_FOLDER')}
            isOpen={isOpen}
            onCloseModal={() => {
                onClose(false);
                setFolderName('');
            }}
            child={
                <div className="mt-4">
                    <input
                        value={folderName}
                        onChange={(e) => setFolderName(e.target.value)}
                        className="w-full py-2 px-3 border  text-sm font-normal border-gray-300 rounded outline-none"
                        placeholder={t('RESOURCES.ENTER_FOLDER_NAME')}
                        maxLength={255}
                    />
                    <div className="flex justify-center gap-4 mt-5">
                        <ButtonOutline
                            type='button'
                            onClick={() => {
                                onClose(false);
                                setFolderName('');
                            }}
                        >
                            {t('LIBRARY.CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary
                            type='button'
                            disabled={!folderName?.trim()}
                            onClick={handleCreateFolder}
                        >
                            {t('LIBRARY.CREATE')}
                        </ButtonPrimary>
                    </div>
                </div>
            }
            stylesTitle="text-base"
        />
    );
}
