import { ChevronDoubleLeftIcon, MenuIcon } from '@heroicons/react/outline';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import scormService from 'services/scorm.service';
import "./index.scss";
import ScormLoader from './ScormLoader';
import ScormMenu from './ScormMenu';

function getAllChildNodes(parentNode) {
    const childNodes = parentNode?.children;
    let allNodes = [];
    childNodes.forEach((node: any) => {
        allNodes.push(node);
        if (node?.children?.length > 0) {
            const childChildren = getAllChildNodes(node);
            allNodes = allNodes.concat(childChildren);
        }
    });
    return allNodes;
}

const SCORMPreviewComponent = ({ values }: { values?: any }) => {
    const paramsURL: { id: string, scormId: string } = useParams();
    const { t: translate } = useTranslation();
    const [scos, setScos] = useState<any>();
    const [scosPlay, setScosPlay] = useState(null);
    const [launchScorm, setLaunchScorm] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const [messageErr, setMessageErr] = useState(null);
    const [hasExpandMenu, setHasExpandMenu] = useState(false);

    const getScormContent = async () => {
        try {
            const res = await scormService.getScormContent(paramsURL?.id || values?.wsid, { scorm_content_id: paramsURL?.scormId || values?.object_id });
            setScos(res);
            if (res?.scos?.length > 0) {
                if (res?.scos[0]?.is_launchable) {
                    setScosPlay(res?.scos[0]);
                } else {
                    let allChildren = res?.scos?.map(item => getAllChildNodes(item))?.flat();
                    allChildren = allChildren?.filter(item => item?.is_launchable);
                    const notDolist = allChildren?.filter(item => item?.user_track?.lesson_status != 'completed');
                    if (notDolist?.length > 0) {
                        setScosPlay(notDolist[0]);
                    } else if (allChildren?.length > 0) {
                        setScosPlay(allChildren[allChildren?.length - 1]);
                    }
                }

            } else {
                setLoading(false);
            }
        } catch (error) {

        }
    }

    const launchScormSCO = async () => {
        try {
            const res = await scormService.launchScormSCO(paramsURL?.id || values?.wsid,
                {
                    scorm_content_id: paramsURL?.scormId || values?.object_id,
                    scorm_sco_id: scosPlay?.id

                });
            if (res) {
                setLaunchScorm(res);
            }
        } catch (error) {
            setLoading(false);
            setMessageErr(error?.error?.description);
        }
    }

    const handleSelect = (value) => {
        setMessageErr(null);
        if (value?.is_launchable && value?.id != scosPlay?.id) {
            setLoading(true);
            setScosPlay(value);
        }
    }
    const handleGetScorm = () => {
        setMessageErr(null);
        getScormContent();
    }

    useEffect(() => {
        setScos(values);
        if (values?.scos?.length > 0) {
            if (values?.scos[0]?.is_launchable) {
                setScosPlay(values?.scos[0]);
            } else {
                let allChildren = values?.scos?.map(item => getAllChildNodes(item))?.flat();
                allChildren = allChildren?.filter(item => item?.is_launchable);
                const notDolist = allChildren?.filter(item => item?.user_track?.lesson_status != 'completed');
                if (notDolist?.length > 0) {
                    setScosPlay(notDolist[0]);
                } else if (allChildren?.length > 0) {
                    setScosPlay(allChildren[allChildren?.length - 1]);
                }
            }

        }
    }, [values]);

    useEffect(() => {
        if (launchScorm?.integration) {
            setLoading(false);
        }
    }, [launchScorm?.integration]);

    useEffect(() => {
        if (scosPlay) {
            launchScormSCO();
        }
    }, [scosPlay]);

    return <>
        {
            <div className='w-full'>
                <div className='relative flex gap-4 w-full'>
                    {hasExpandMenu && <button
                        onClick={() => setHasExpandMenu(false)}
                        className='border boder-gray-200 rounded-full w-10 h-10 flex flex-none items-center justify-center absolute top-4 left-4 hover:text-primary-500 hover:bg-blue-50 transition'
                    >
                        <MenuIcon className='w-4 h-4' />
                    </button>}
                    {(scos?.scos?.length > 1 || scos?.scos?.[0]?.children?.length > 0) && !hasExpandMenu ?
                        <div className='scorm-nav'>
                            <div className='flex justify-around items-center font-semibold text-sm text-gray-700 p-4 border-b border-gray-100'>
                                <div>{translate('Table of Contents')}</div>
                                <button onClick={() => setHasExpandMenu(true)} className='hover:text-primary-500'>
                                    <ChevronDoubleLeftIcon className='w-5 h-5' />
                                </button>
                            </div>
                            <div className='scorm-nav-content'>
                                {scos?.scos?.map(item => <div key={item?.id}>
                                    <ScormMenu node={item} currentScorm={scosPlay} onClick={(value) => handleSelect(value)} />
                                </div>)}
                            </div>
                        </div> : null
                    }
                    {isLoading ?
                        <div className='scorm-loading'>
                            <CircleSpin color='text-primary-500' />
                        </div> :
                        <>

                            {messageErr ?
                                <div className='scorm-nocontent'>{messageErr}</div> :
                                <div className='w-full p-14'>
                                    {scosPlay ? <ScormLoader launchScorm={launchScorm} onCallBack={handleGetScorm} />
                                        :
                                        <div className='scorm-nocontent'>{translate('Please select content to play')}</div>
                                    }
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        }
    </>

};

export default SCORMPreviewComponent;
