import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import { INVOICE_TYPE, PAYMENT_OPTION, SINGLE_INVOICE_STATUS_ENUM } from '../constants';
import { useTranslation } from 'react-i18next';
import './style.scss';
import { useLocation, useParams } from 'react-router-dom';
import { ListUser } from '_shared/components/User/User';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { PriceFormat } from '_shared/components/Price/PriceFormat';
import DialogComponent from 'components/Dialog/DialogComponent';
import ModalAddDesciption from '../InvoiceList/components/ModalAddDesciption';
import ModalAddNotes from '../InvoiceList/components/ModalAddNotes';
import AddNoteIcon from 'assets/icon/AddNoteIcon';

export default function Information({ invoice, refreshData }) {
    const {
        userState: { result },
    } = useContext(UserContext);
    const [isOpenModalDesciption, setOpenModalDesciption] = useState(false);
    const [isOpenModalNote, setOpenModalNote] = useState(false);
    const { t: translator } = useTranslation();
    const params: { id } = useParams();
    const search = useLocation()?.search;
    const invoiceType = new URLSearchParams(search).get('type');
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;
    return (
        <div className="InvoiceInformation">
            <div className="InvoiceInformation-title">{translator('INFORMATION')}</div>
            {invoice?.name && (
                <>
                    <div className="InvoiceInformation--invoice-field-label">{translator('RECURRING_INVOICE_NAME')}</div>
                    <div className="InvoiceInformation--invoice-field-value">{invoice?.name}</div>
                </>
            )}

            {invoice?.amount && invoiceType === INVOICE_TYPE.single && (
                <>
                    <div className="InvoiceInformation--invoice-field-label">{translator('AMOUNT')}</div>
                    <div className="InvoiceInformation--invoice-field-value uppercase">
                        <span className="font-semibold "><PriceFormat value={invoice?.amount} currency={workspaceDetailInformation.default_currency} /></span>
                    </div>
                </>
            )}

            {invoiceType === INVOICE_TYPE.single && (
                <div className="InvoiceInformation--transaction-table">
                    <div className="transaction-item border-style">
                        <div className="transaction-item--label">{translator('TRANSACTION_ID')}</div>
                        <div className="transaction-item--value">{invoice?.id}</div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('TRANSACTION_DATE')}</div>
                        <div className="transaction-item--value">
                            {dayjs.utc(invoice?.schedule_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('PAYMENT_OPTION')}</div>
                        <div className="transaction-item--value">{PAYMENT_OPTION(translator)[invoice?.payment_option]}</div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('DUE_DATE')}</div>
                        <div className="transaction-item--value">
                            {invoice?.due_at ? dayjs.utc(invoice?.due_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY・HH:mm') : '-'}
                        </div>
                    </div>
                    <div className="transaction-item padding-left">
                        <div className="transaction-item--label">{translator('PAYMENT_DATE')}</div>
                        <div className="transaction-item--value">{invoice?.paid_at ? dayjs.utc(invoice?.paid_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY・HH:mm') : '-'}</div>
                    </div>
                </div>
            )}

            {invoiceType === INVOICE_TYPE.recurring && (
                <div className="InvoiceInformation--transaction-table !grid-cols-4">
                    <div className="transaction-item border-style">
                        <div className="transaction-item--label">{translator('START_DATE')}</div>
                        <div className="transaction-item--value">
                            {dayjs.utc(invoice?.start_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('END_DATE')}</div>
                        <div className="transaction-item--value">
                            {dayjs.utc(invoice?.end_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY')}
                        </div>
                    </div>
                    <div className="transaction-item border-style padding-left">
                        <div className="transaction-item--label">{translator('FREQUENCY')}</div>
                        <div className="transaction-item--value capitalize">{invoice?.frequency_type}</div>
                    </div>
                    <div className="transaction-item padding-left">
                        <div className="transaction-item--label">{translator('PAYMENT_OPTION')}</div>
                        <div className="transaction-item--value">{PAYMENT_OPTION(translator)[invoice?.payment_option]}</div>
                    </div>
                </div>
            )}

            <div className="InvoiceInformation-information">
                <div className="InvoiceInformation-information--label ">{translator('COURSE')}</div>
                <div className="InvoiceInformation-information--value">
                    {invoice?.courses?.map((course) => course?.title)?.join(', ')}
                </div>
            </div>
            <div className="InvoiceInformation-information">
                <div className="InvoiceInformation-information--label">{translator('LEARNER')}</div>
                <ListUser
                    users={[...(invoice?.learners || []), ...(invoice?.learning_groups || [])]?.map((item) => {
                        let link = ''
                        if (!item?.display_name) link = `/workspace/${params?.id}/management/learning-groups/${item?.id}`
                        else link = `/workspace/${params?.id}/management/users/${item?.id}?tab=information`
                        return {
                            ...item,
                            link
                        };
                    })}
                    id={'user'}
                    fontsize="!text-sm"
                    avatarStyle="w-6 h-6"

                />
            </div>

            {invoiceType === INVOICE_TYPE.single && invoice?.status === SINGLE_INVOICE_STATUS_ENUM.scheduled &&
                (
                    <div className="InvoiceInformation-information">
                        <div className="InvoiceInformation-information--label">{translator('DESCRIPTION')}</div>
                        <div className='flex justify-between w-full gap-2 items-start'>
                            {invoice?.note &&
                                <div
                                    className="text-gray-800 text-sm !font-normal max-w-[calc(100%-140px)]"
                                    dangerouslySetInnerHTML={
                                        { __html: invoice?.note?.replace(/\n/g, '<br />') }
                                    }
                                />
                            }
                            <div className='cursor-pointer flex items-center text-sm text-primary-500 gap-1' onClick={() => setOpenModalDesciption(true)}>
                                <AddNoteIcon />
                                {invoice?.note ? translator("EDIT_DESCIPTION") : translator("ADD_DESCRIPTION")}
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="InvoiceInformation-information">
                <div className="InvoiceInformation-information--label">{translator('NOTES')}</div>
                <div className='flex justify-between w-full gap-2 items-start'>
                    {invoice?.notes &&
                        <div
                            className="text-gray-800 text-sm !font-normal max-w-[calc(100%-140px)]"
                            dangerouslySetInnerHTML={
                                { __html: invoice?.notes?.replace(/\n/g, '<br />') }
                            }
                        />
                    }
                    <div className='cursor-pointer flex items-center text-sm text-primary-500 gap-1' onClick={() => setOpenModalNote(true)}>
                        <AddNoteIcon />
                        {invoice?.notes ? translator("EDIT_NOTES") : translator("ADD_NOTES")}
                    </div>
                </div>
            </div>

            <DialogComponent
                title={!invoice?.note ? translator("ADD_DESCRIPTION") : translator("EDIT_DESCIPTION")}
                onCloseModal={() => setOpenModalDesciption(false)}
                isOpen={isOpenModalDesciption}
                child={
                    <ModalAddDesciption refreshData={refreshData} data={invoice} onClose={() => setOpenModalDesciption(false)} />
                }
            />
            <DialogComponent
                title={!invoice?.notes ? translator("ADD_NOTES") : translator("EDIT_NOTES")}
                onCloseModal={() => setOpenModalNote(false)}
                isOpen={isOpenModalNote}
                child={
                    <ModalAddNotes isSingleInvoice={invoiceType === INVOICE_TYPE.single} refreshData={refreshData} data={invoice} onClose={() => setOpenModalNote(false)} />
                }
            />
        </div>
    );
}
