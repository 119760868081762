import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Book3FillIcon, BookMarkFillIcon, DraftFillIcon, InformationCircleIcon } from 'assets/icon';
import { LessonBuilderHeader } from './components';
import { EmptyStage } from '../components/EmptyStage/EmptyStage';
import { Information } from './components/Information/Information';
import { LearningMaterials } from './components/LearningMaterials/LearningMaterials';
import { debounce, isArray } from 'lodash';
import { LessonBuilderSideHeader, LessonBuilderSideContent } from './components/LessonBuilderSidebar/LessonBuilderSidebar';
import Content from './components/Content/Content';
import { TYPE_CREATE_CONTENT, TYPE_LESSON_BUILDER, VIEW_TYPE } from './constants';
import { getDetail } from './helpers';
import { EmptyStageSection } from '../components/EmptyStage/EmptyStateSection';
import { LessonInterface } from 'types/GetListOfWorkspace.type';
import workspaceService from 'services/workspace.service';
import './LessonBuilder.scss';
import lessonService from 'services/lesson.service';
import SupplementatyMaterials from './components/SupplementatyMaterials/SupplementaryMaterial';
import { Toastify } from '_shared';
import Assignments from './components/Assignments/Assignments';
import assignmentService from 'services/assignment.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import FromLibrary from './components/Content/FromLibrary/FromLibrary';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { checkPermission } from 'utils/commonFun';

const NAV_ITEMS = (translator) =>  [
    {
        key: TYPE_LESSON_BUILDER.information,
        header: {
            color: 'text-blue-400',
            name: translator("INFORMATION"),
            icon: <InformationCircleIcon />,
        },
        content: Information,
    },
    {
        key: TYPE_LESSON_BUILDER.learningMaterials,
        header: {
            color: 'text-primary-500',
            name: translator("COURSES.LEARNING_MATERIAL"),
            icon: <BookMarkFillIcon />,
        },
        content: LearningMaterials,
    },
    {
        key: TYPE_LESSON_BUILDER.assignments,
        header: {
            color: 'text-pink-500',
            name: translator("COURSES.ASSIGNMENTS"),
            icon: <DraftFillIcon width={20} height={20} />,
        },
        content: Assignments,
    },
    {
        key: TYPE_LESSON_BUILDER.supplementaryMaterials,
        header: {
            color: 'text-cyan-500',
            name: translator("COURSES.SUPPLEMENTARY_MATERIALS"),
            icon: <Book3FillIcon width={20} height={20} />,
        },
        content: SupplementatyMaterials,
    },
];

export const LessonBuilder = () => {
    const history = useHistory();
    const { location }: any = history;
    const params: { id: string; lessonId: string } = useParams();
    const { t: translator } = useTranslation();
    const [typeSelect, setTypeSelect] = useState<string>(NAV_ITEMS(translator)[0].key);
    const [contentSelected, setContentSelected] = useState<any>();
    const [viewType, setViewType] = useState(VIEW_TYPE.WEB);
    const [createContentType, setCreateContentType] = useState(TYPE_CREATE_CONTENT.UPLOAD);
    const [lessonDetail, setLessonDetail] = useState<LessonInterface>();
    const [associatedLearningPath, setAssociatedLearningPath] = useState<any[]>([]);
    const [onSaving, setOnSaving] = useState(false);
    const [isOpenModalCreateH5p, setOpenModalCreateH5p] = useState(false);
    const [isOpenModalLibrary, setOpenModalLibrary] = useState(false);
    const [loadingPreviewContent, setLoadingPreviewContent] = useState(false);
    const [assignmentSelected, setAssignmentSelected] = useState<any>();
    const refUpload: any = useRef();
    const [sectionUploading, setSectionUploading] = useState<any>();
    const [missingInformation, setMissingInformation] = useState(false);
    const [isOpenModalAddAsignment, setOpenModalAddAsignment] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);
    const [isNew, setIsNew] = useState<boolean>(false);
    const { state }: { state: any } = location;

    const [materials, setMaterials] = useState({
        learning_material: [],
        supplementary_material: [],
        assignment: [],
    });

    const [sectionSelected, setSectionSelected] = useState<any>();

    const createLesson = () => {
        workspaceService
            .createLesson(params.id, {
                title: 'Untitled Lesson',
                parent_id: location?.state?.folderId,
            })
            .then((res: any) => {
                if (res?.data?.id) {
                    history.push(`/workspace/${params.id}/lesson/${res?.data?.id}`, {
                        newContent: true,
                    });
                }
            })
            .catch((err) => { });
    };

    const getLesson = async () => {
        const response = await workspaceService.getLessonDetail(params.id, params.lessonId);
        if (response) {
            setLoadingPage(false);
            setLessonDetail(response);
            setMaterials({
                learning_material: response?.sections?.learning_material,
                supplementary_material: response?.sections?.supplementary_material,
                assignment: response?.sections?.assignment,
            });
        }
    };

    const fetchAssociatedLearningPath = () => {
        workspaceService.getAssociatedLearningPath(params.id, params.lessonId, { skip_pagination: true })
            .then(res => {
                setAssociatedLearningPath(res?.data?.items || []);
            });
    }

    const debounceSortContent = debounce((newSectionData, file) => {
        getDetail(params?.id, file, setContentSelected, setLoadingPreviewContent);
        lessonService
            .sortContent(params?.id, params?.lessonId, sectionSelected?.id, {
                ids: newSectionData?.map((content) => content?.id),
            })
            .then(() => setCreateContentType(undefined));
    }, 800);
    //trigger update data after upload file
    useEffect(() => {
        if (sectionUploading) {
            let index = materials[typeSelect]?.findIndex((item) => item?.id === sectionUploading?.id);
            materials[typeSelect][index] = sectionUploading;
            setMaterials(() => ({ ...materials }));
            setSectionUploading(undefined);
        }
    }, [sectionUploading]);

    const updateContentUpload = (file) => {
        let index = materials[typeSelect]?.findIndex((item) => item?.id === sectionSelected?.id);
        const currentSectionData = materials?.[typeSelect]?.[index]?.section_contents || [];
        const newSectionData = [...currentSectionData, file];
        const newSection = { ...sectionSelected, section_contents: newSectionData };
        materials[typeSelect][index] = newSection;
        setSectionUploading(newSection);
        setSectionSelected(newSection);
        debounceSortContent(newSectionData, file);
    };

    const getAssignemt = async (assignmentId) => {
        const res = await assignmentService.getAssignment({ workspaceId: params?.id, assignmentId });
        return setContentSelected(res?.data?.sections?.[0]);
    };

    const handleAddContentToSection = async (files, section = undefined) => {
        setOnSaving(true);
        const currentSection = section || sectionSelected;
        const newData = isArray(files) ? files : [files];
        let index = materials[typeSelect]?.findIndex((item) => item?.id === currentSection?.id);
        const currentSectionData = materials?.[typeSelect]?.[index]?.section_contents || [];
        let section_contents = [...currentSectionData];
        for await (const file of newData) {
            const res = await lessonService.addContentToSection(params?.id, params?.lessonId, currentSection?.id, {
                object_id: file?.object_id,
                object_type: file?.object_type,
                order_number: section_contents?.length + 1,
            });
            if (res?.id) {
                section_contents.push({ id: res?.id, library_content: file, order_number: section_contents?.length + 1 });
            }
        }
        const newSection = { ...currentSection, section_contents: section_contents };
        if (section_contents?.length > currentSectionData?.length) {
            if (typeSelect === TYPE_LESSON_BUILDER.assignments) {
                getAssignemt(section_contents?.slice(-1)?.[0]?.library_content?.object_id);
                setAssignmentSelected(section_contents?.slice(-1)?.[0]);
            } else {
                getDetail(params?.id, section_contents?.slice(-1)?.[0], setContentSelected, setLoadingPreviewContent);
            }
            setSectionSelected(newSection);
            materials[typeSelect][index] = newSection;
            setMaterials(() => ({ ...materials }));
            setOnSaving(false);
        }
    };

    const setSections = (sections) => {
        setMaterials({ ...materials, [typeSelect]: sections });
    };

    const handleDeleteFileOfSection = (section, fileSeleced) => {
        setOnSaving(true);
        lessonService.deleteContent(params?.id, params?.lessonId, section?.id, fileSeleced).then(() => {
            const newSection = { ...section };
            const newData = newSection?.section_contents?.filter((file) => file?.id !== fileSeleced);
            newSection.section_contents = newData;
            setSectionSelected(newSection);
            let index = materials[typeSelect]?.findIndex((item) => item?.id === section?.id);
            materials[typeSelect][index] = newSection;
            setMaterials(() => ({ ...materials }));
            if (!newData?.length) {
                setContentSelected(undefined);
                setCreateContentType(TYPE_CREATE_CONTENT.UPLOAD);
            }
            //for type is assignment
            if (typeSelect === TYPE_LESSON_BUILDER.assignments) {
                if (assignmentSelected?.id === fileSeleced && newData?.length) {
                    setAssignmentSelected(newData?.[0]);
                    getAssignemt(newData?.[0]?.library_content?.object_id);
                }
            }
            if (contentSelected?.id === fileSeleced && newData?.length) {
                getDetail(params?.id, newData?.[0], setContentSelected, setLoadingPreviewContent);
            }
            setOnSaving(false);
        });
    };

    const handleDeleteSection = (sectionId) => {
        setOnSaving(true);
        lessonService.deleteSection(params?.id, params?.lessonId, sectionId).then((res) => {
            const newMaterials = { ...materials };
            const listSection = newMaterials[typeSelect]?.filter((item) => item?.id !== sectionId);
            newMaterials[typeSelect] = listSection;
            setMaterials(newMaterials);
            if (sectionSelected?.id === sectionId) {
                const firstSection = listSection?.[0];
                setSectionSelected(firstSection || undefined);
                const firstContent = firstSection?.section_contents?.[0];
                if (!firstContent) {
                    setContentSelected(undefined);
                    setCreateContentType(TYPE_CREATE_CONTENT.UPLOAD);
                } else getDetail(params?.id, firstContent, setContentSelected, setLoadingPreviewContent);
            }
            setOnSaving(false);
        });
    };

    const handleSelectContentToPreview = (content) => {
        if (!content) {
            setContentSelected(undefined);
            setCreateContentType(TYPE_CREATE_CONTENT.UPLOAD);
            return;
        }
        if (typeSelect === TYPE_LESSON_BUILDER.assignments) {
            setContentSelected(content);
        } else getDetail(params?.id, content, setContentSelected, setLoadingPreviewContent);
        setCreateContentType(undefined);
    };

    const updateNote = async (data) => {
        if (data?.refresh) {
            setContentSelected((prev) => ({
                ...prev,
                note: data?.note || '',
            }));
            setOnSaving(false);
        } else if (data?.note != undefined) {
            setOnSaving(true);
            await lessonService.UpdateNote({
                workspaceId: params?.id,
                lessonId: params?.lessonId,
                sectionId: sectionSelected?.id,
                contentId: contentSelected?.id,
                note: data?.note,
            });
            setOnSaving(false);
            getLesson();
        }
    };

    useEffect(() => {
        if (checkPermission('libraryContent', 'R')) {
            if (state?.newContent) {
                setIsNew(true);
            }
            if (params?.lessonId == 'new') {
                if (checkPermission('libraryContent', 'U')) {
                    createLesson();
                } else history.push('/forbidden')
            } else if (!onSaving) {
                getLesson();
                fetchAssociatedLearningPath();
            }
        } else {
            history.push('/forbidden')
        }

    }, [params?.lessonId]);

    const builderContent = () => {
        switch (typeSelect) {
            case TYPE_LESSON_BUILDER.information:
                return <EmptyStage onTypeSelect={setTypeSelect} />;
            case TYPE_LESSON_BUILDER.learningMaterials:
            case TYPE_LESSON_BUILDER.assignments:
                if (
                    (!materials?.learning_material?.length && typeSelect === TYPE_LESSON_BUILDER.learningMaterials) ||
                    (!materials?.assignment?.[0]?.section_contents?.length && typeSelect === TYPE_LESSON_BUILDER.assignments)
                ) {
                    return (
                        <EmptyStageSection
                            setSections={setSections}
                            setSectionSelected={setSectionSelected}
                            sections={materials[typeSelect]}
                            typeSelect={typeSelect}
                            setTypeSelect={setTypeSelect}
                            setOpenModalAddAsignment={setOpenModalAddAsignment}
                        />
                    );
                }
                return (
                    /*@ts-ignore*/
                    <Content
                        createContentType={createContentType}
                        setCreateContentType={setCreateContentType}
                        viewType={viewType}
                        handleAddContentToSection={handleAddContentToSection}
                        contentSelected={contentSelected}
                        sectionSelected={sectionSelected}
                        handleDeleteSection={handleDeleteSection}
                        handleDeleteFileOfSection={handleDeleteFileOfSection}
                        setOpenModalCreateH5p={setOpenModalCreateH5p}
                        setOpenModalLibrary={setOpenModalLibrary}
                        isOpenModalCreateH5p={isOpenModalCreateH5p}
                        isOpenModalLibrary={isOpenModalLibrary}
                        type={typeSelect}
                        loadingPreviewContent={loadingPreviewContent}
                        updateContentUpload={updateContentUpload}
                        updateNote={updateNote}
                        ref={refUpload}
                        assignmentSelected={assignmentSelected}
                    />
                );
            case TYPE_LESSON_BUILDER.supplementaryMaterials:
                return (
                    /*@ts-ignore*/
                    <Content
                        createContentType={createContentType}
                        setCreateContentType={setCreateContentType}
                        viewType={viewType}
                        handleAddContentToSection={handleAddContentToSection}
                        contentSelected={contentSelected}
                        sectionSelected={sectionSelected}
                        handleDeleteSection={handleDeleteSection}
                        handleDeleteFileOfSection={handleDeleteFileOfSection}
                        setOpenModalCreateH5p={setOpenModalCreateH5p}
                        setOpenModalLibrary={setOpenModalLibrary}
                        isOpenModalCreateH5p={isOpenModalCreateH5p}
                        isOpenModalLibrary={isOpenModalLibrary}
                        type={typeSelect}
                        loadingPreviewContent={loadingPreviewContent}
                        updateContentUpload={updateContentUpload}
                        updateNote={updateNote}
                        ref={refUpload}
                        assignmentSelected={assignmentSelected}
                    />
                );
            default:
                break;
        }
    };

    const handlePublic = async () => {
        setOnSaving(true);
        let response = await lessonService.updateLesson(params.id, params.lessonId, {
            status: lessonDetail?.status == 'draft' ? 'published' : 'draft',
        });
        if (response) {
            setLessonDetail((prev) => ({
                ...prev,
                status: response?.status,
            }));
            Toastify.success(`The lesson was ${lessonDetail?.status == 'draft' ? 'published' : 'unpublished'} successfully!`);
        }
        setOnSaving(false);
    };

    const handleSaveInfo = async (data) => {
        setOnSaving(true);
        if (data?.title != lessonDetail?.title || data?.lesson_goal != lessonDetail.lesson_goal) {
            const response = await lessonService.updateLesson(params.id, params.lessonId, data);
            setLessonDetail((prev) => ({
                ...prev,
                ...response,
            }));
        }
        setOnSaving(false);
    };

    const handleSelectType = (type: string) => {
        setTypeSelect(type);
    };

    useEffect(() => {
        setContentSelected(undefined);
        if (materials[typeSelect]?.length) {
            setSectionSelected(() => materials?.[typeSelect]?.[0]);
            const firstContent = materials?.[typeSelect]?.[0]?.section_contents?.[0];
            if (firstContent) {
                if (typeSelect === TYPE_LESSON_BUILDER.assignments) {
                    setAssignmentSelected(firstContent);
                    getAssignemt(firstContent?.library_content?.object_id);
                    return;
                }
                getDetail(params?.id, firstContent, setContentSelected, setLoadingPreviewContent);
                setCreateContentType(undefined);
            }
        }
    }, [typeSelect]);

    useEffect(() => {
        if (lessonDetail?.title && (materials?.assignment?.[0]?.section_contents?.length || materials?.learning_material?.length)) {
            setMissingInformation(false);
        }
    }, [lessonDetail, materials]);

    if (loadingPage) {
        return (
            <div className="w-[100vw] h-[100vh] flex justify-center items-center">
                <CircleSpin className="!text-primary-500" />
            </div>
        );
    }

    const checkeckMissingContent = () => {
        if (!lessonDetail?.title) return true;
        if (materials?.learning_material?.length) {
            return !materials?.learning_material?.find((section) => (section?.section_contents || [])?.length !== 0);
        }
        return !materials?.assignment?.[0]?.section_contents?.length && !materials?.learning_material?.length;
    };

    return (
        <div className="lessonBuilder">
            <div className="lessonBuilder-header">
                <LessonBuilderHeader
                    details={lessonDetail}
                    setMissingInformation={() => setMissingInformation(checkeckMissingContent())}
                    isMissingInformation={checkeckMissingContent()}
                    isSaving={onSaving}
                    setViewType={setViewType}
                    viewType={viewType}
                    onPublic={handlePublic}
                    folderId={lessonDetail?.path?.slice(-1)?.[0]?.id}
                    isNew={isNew}
                />
            </div>
            <div className="lessonBuilder-main">
                <div className="lessonBuilder-sidebar">
                    {NAV_ITEMS(translator).map((item, index) => {
                        const NavComponent = item.content;
                        const isShowError =
                            missingInformation &&
                            (item.key === TYPE_LESSON_BUILDER.learningMaterials || item.key === TYPE_LESSON_BUILDER.assignments);
                        return (
                            <div key={item.key}>
                                <LessonBuilderSideHeader
                                    translator={translator}
                                    isShowError={isShowError}
                                    isOpen={typeSelect == item.key}
                                    onClick={() => handleSelectType(item.key)}
                                >
                                    <span className={`lessonBuilderSidebar-icon ${item?.header?.color || ''}`}>
                                        {item?.header?.icon}
                                    </span>
                                    {translator(item?.header?.name)}
                                    {(TYPE_LESSON_BUILDER.assignments == item.key ||
                                        TYPE_LESSON_BUILDER.supplementaryMaterials == item.key) && (
                                            <div className="number">{materials?.[item.key]?.[0]?.section_contents?.length || 0}</div>
                                        )}
                                </LessonBuilderSideHeader>
                                {typeSelect == item.key && (
                                    <LessonBuilderSideContent>
                                        {/* @ts-ignore*/}
                                        <NavComponent
                                            setSectionSelected={setSectionSelected}
                                            contentSelected={contentSelected}
                                            sections={materials?.[item.key]}
                                            setSections={setSections}
                                            handleSelectContentToPreview={handleSelectContentToPreview}
                                            sectionSelected={sectionSelected}
                                            handleDeleteFileOfSection={handleDeleteFileOfSection}
                                            handleDeleteSection={handleDeleteSection}
                                            typeSelect={typeSelect}
                                            details={{ ...lessonDetail, associatedLearningPath }}
                                            onSaving={setOnSaving}
                                            setOnSaving={setOnSaving}
                                            onSave={handleSaveInfo}
                                            setCreateContentType={setCreateContentType}
                                            setOpenModalCreateH5p={setOpenModalCreateH5p}
                                            setOpenModalLibrary={setOpenModalLibrary}
                                            ref={refUpload}
                                            setAssignmentSelected={setAssignmentSelected}
                                            assignmentSelected={assignmentSelected}
                                            handleAddContentToSection={handleAddContentToSection}
                                            setOpenModalAddAsignment={setOpenModalAddAsignment}
                                            missingInformation={missingInformation}
                                        />
                                    </LessonBuilderSideContent>
                                )}
                            </div>
                        );
                    })}
                </div>
                <div className="lessonBuilder-content">{builderContent()}</div>
                {isOpenModalAddAsignment && (
                    <DialogComponent
                        isOpen={isOpenModalAddAsignment}
                        onCloseModal={() => setOpenModalAddAsignment(false)}
                        hasNonTitle
                        styles="max-w-[70vw] min-h-[480px] my-0 rounded-lg !p-0 pb-4"
                        isShowClose={false}
                        child={
                            <FromLibrary
                                createContentType={TYPE_CREATE_CONTENT.LIBRARY}
                                type={TYPE_LESSON_BUILDER.assignments}
                                title={translator('LIBRARY.ADD_FROM_LIBRARY')}
                                onCloseModal={() => setOpenModalAddAsignment(false)}
                                handleAddContentToSection={handleAddContentToSection}
                            />
                        }
                    />
                )}
            </div>
        </div>
    );
};
