export const VolumeIcon = () => {
    return (
        <svg className="!block" width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3294_16551)">
            <path d="M9.889 20H6C5.73478 20 5.48043 19.8947 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V13C5 12.7348 5.10536 12.4805 5.29289 12.2929C5.48043 12.1054 5.73478 12 6 12H9.889L15.183 7.66802C15.2563 7.60797 15.3451 7.56996 15.4391 7.55841C15.5331 7.54687 15.6284 7.56227 15.714 7.60282C15.7996 7.64337 15.872 7.7074 15.9226 7.78745C15.9732 7.86751 16.0001 7.9603 16 8.05502V23.945C16.0001 24.0397 15.9732 24.1325 15.9226 24.2126C15.872 24.2926 15.7996 24.3567 15.714 24.3972C15.6284 24.4378 15.5331 24.4532 15.4391 24.4416C15.3451 24.4301 15.2563 24.3921 15.183 24.332L9.89 20H9.889ZM23.406 24.134L21.99 22.718C22.938 21.8745 23.6964 20.8397 24.2152 19.6817C24.734 18.5237 25.0015 17.2689 25 16C25.0012 14.6661 24.7054 13.3487 24.1339 12.1434C23.5624 10.9381 22.7296 9.87526 21.696 9.03202L23.116 7.61202C24.3345 8.64357 25.3132 9.9285 25.9841 11.3772C26.6549 12.8259 27.0016 14.4035 27 16C27 19.223 25.614 22.122 23.406 24.134ZM19.863 20.591L18.441 19.169C18.9265 18.7957 19.3196 18.3158 19.5899 17.7663C19.8602 17.2167 20.0006 16.6124 20 16C20 14.57 19.25 13.315 18.12 12.608L19.559 11.169C20.3165 11.7262 20.9321 12.4539 21.3562 13.2931C21.7802 14.1324 22.0008 15.0597 22 16C22 17.842 21.17 19.49 19.863 20.591Z" fill="#374151"/>
            </g>
            <defs>
            <clipPath id="clip0_3294_16551">
            <rect width="24" height="24" fill="white" transform="translate(4 4)"/>
            </clipPath>
            </defs>
        </svg>
    )
}