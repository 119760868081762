import DialogComponent from 'components/Dialog/DialogComponent';
import Step1 from './Step1';
import Step2 from './Step2';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CheckCircleIcon } from '@heroicons/react/solid';
import skillService from 'services/skill.service';
import './new-skill-learner.scss';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';

interface IModalAddSkill {
    isOpen: boolean;
    onClose: () => void;
    userData?: any;
    refreshData: () => void;
    userList?: any[];
}

export default function ModalAddSkill({ isOpen, onClose, userData, refreshData, userList }: IModalAddSkill) {
    const { t: translator } = useTranslation();
    const params: { id: string; userId } = useParams();

    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);

    const workspaceId: string = params?.id;
    const userId: string = params?.userId;
    const {userState: {result}} = useContext(UserContext)

    const {
        control,
        watch,
        handleSubmit,
        formState: { errors, isValid },
        setError,
        setValue,
        getValues,
        clearErrors,
    } = useForm({
        defaultValues: {
            skills: [{ skill: null, level: null }],
            skill_level_ids: [],
            enroll_courses: [],
        },
        mode: 'onChange',
    });

    const [isCompletedStep1, setIsCompletedStep1] = useState(false);

    const [isCompletedStep2, setIsCompletedStep2] = useState(false);

    const onCreateLearnerSkill = (bodyParams) => {
        setLoading(true);
        skillService
            .createLearnerSkill(workspaceId, userId, bodyParams)
            .then(() => {
                Toastify.success();
                refreshData?.();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };

    const onCreateMultiLearnerSkill = (bodyParams) => {
        setLoading(true);
        skillService
            .createMultiLearnerSkill(workspaceId, bodyParams)
            .then(() => {
                Toastify.success();
                refreshData?.();
            })
            .catch(() => Toastify.error())
            .finally(() => {
                setLoading(false);
                onClose();
            });
    };
    
    const onSubmitForm = (values) => {
        const bodyParams = {
            student_membership_ids: userList?.map(user => user?.membership?.id),
            skill_level_ids: values?.skill_level_ids,
            enroll_courses: values?.enroll_courses?.map((el) => ({
                course_id: el?.id,
                start_date:  dayjs.utc(el?.start_date).tz(result?.time_zone).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                end_date: dayjs.utc(el?.end_date).tz(result?.time_zone).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            })),
        };
        if (userList?.length) {
            onCreateMultiLearnerSkill(bodyParams);
        } else {
            delete bodyParams?.student_membership_ids;
            onCreateLearnerSkill(bodyParams);

        }
    };

    return (
        <DialogComponent
            isOpen={isOpen}
            onCloseModal={onClose}
            title={translator('ADD_SKILLS')}
            styles="max-w-6xl"
            styleDialog='z-70'
            child={
                <form
                    className="create-skill-learner mt-4 flex flex-col gap-5 border-t border-gray-300 pt-5 -mx-5 p-5"
                    onSubmit={handleSubmit(onSubmitForm)}
                >
                    <div className="flex gap-2 w-full">
                        <div
                            className={`w-1/2 border-t-4 py-1.5
                                ${step === 1 ? 'border-primary-500' : 'border-gray-300'}
                                ${step === 2 && isCompletedStep1 ? 'border-green-600' : 'border-gray-300'}
                            `}
                        >
                            <div className="flex  items-center gap-1">
                                {step === 2 && isCompletedStep1 && <CheckCircleIcon className="w-5 h-5 text-green-600" />}
                                {step === 1 && (
                                    <div
                                        className={`w-4 h-4  rounded-full ${step === 1 ? 'border-6 border-primary-500' : 'border-2 border-gray-300'
                                            }`}
                                    />
                                )}
                                <div className="text-sm font-semibold">{translator('SKILL_SELECTION')}</div>
                            </div>
                        </div>

                        <div
                            className={`w-1/2 border-t-4 py-1.5
                                ${step === 2 ? 'border-primary-500' : 'border-gray-300'}
                                ${step === 2 && isCompletedStep2 ? '!border-green-600' : 'border-gray-300'}
                            `}
                        >
                            <div className="flex  items-center gap-1">
                                {step === 2 && isCompletedStep2 && <CheckCircleIcon className="w-5 h-5 text-green-600" />}
                                {!isCompletedStep2 && (
                                    <div
                                        className={`w-4 h-4  rounded-full 
                                            ${step === 2 ? 'border-6 border-primary-500' : 'border-2 border-gray-300'}
                                            ${isCompletedStep2 ? 'border-6 border-green-600' : 'border-2 border-gray-300'}
                                            
                                        `}
                                    />
                                )}
                                <div className="text-sm font-semibold">{translator('ENROLLMENT_CONFIRMATION')}</div>
                            </div>
                        </div>
                    </div>

                    <div className="max-h-[40vh] min-h-[50vh] overflow-y-auto">
                        {step === 1 && (
                            <Step1
                                userData={userData}
                                setIsCompletedStep1={setIsCompletedStep1}
                                control={control}
                                errors={errors}
                                watch={watch}
                                setError={setError}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                userList={userList}
                            />
                        )}
                        {step === 2 && (
                            <Step2 isValid={isValid} setIsCompletedStep2={setIsCompletedStep2} control={control} errors={errors} watch={watch} isMulti={userList?.length ? true : false} />
                        )}
                    </div>
                    <div className="pt-5 px-5 flex justify-between border-t border-gray-300 -mx-5 sticky bottom-0 bg-white">
                        <ButtonOutline type={'button'} onClick={() => onClose()}>
                            {translator('CANCEL')}
                        </ButtonOutline>
                        <div className="flex space-x-2">
                            {step === 1 && (
                                <ButtonPrimary
                                    type={'button'}
                                    disabled={step === 1 && (errors.skills?.length > 0 || !isCompletedStep1)}
                                    className="disabled:!bg-gray-100 !border-none disabled:!text-gray-500"
                                    onClick={() => {
                                        const skillLevels = watch('skills')
                                            ?.map((sk) => sk?.level?.map((item) => item?.id))
                                            ?.flat(Infinity);
                                        setValue('skill_level_ids', skillLevels);
                                        setStep(2);
                                    }}
                                >
                                    {translator('NEXT')} <ChevronRightIcon className="w-4 h-4" />
                                </ButtonPrimary>
                            )}
                            {step === 2 && (
                                <ButtonOutline
                                    className="!text-primary-500 !border-primary-500 !gap-2"
                                    type={'button'}
                                    onClick={() => setStep(1)}
                                >
                                    <ChevronLeftIcon className="w-4 h-4" /> {translator('Back')}
                                </ButtonOutline>
                            )}
                            {step === 2 && (
                                <ButtonPrimary
                                    type={'submit'}
                                    disabled={loading || !isCompletedStep2}
                                    className="disabled:!bg-gray-100 !border-none disabled:!text-gray-500"
                                >
                                    {translator('Confirm')}
                                </ButtonPrimary>
                            )}
                        </div>
                    </div>
                </form>
            }
        />
    );
}
