import { useParams } from 'react-router-dom';
import SelectBox from "_shared/components/Select";
import { useEffect, useState } from 'react';
import propertyService from 'services/property.service';

export const SelectPropertyOptions = ({ optionId, values, control = null, name, onChangeValue, label, isDisabled = false, keyValue, placeholder, errors }:
    {
        optionId: string;
        values?: any
        control?: any,
        name?: string,
        onChangeValue: (field: string, value: any) => void,
        label: string,
        isDisabled?: boolean,
        keyValue,
        placeholder: string;
        errors: any
    }) => {
    const params: { id: string, courseId: string } = useParams();
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState<any>();

    const getOptions = async (page: number = 1) => {
        try {
            const param = {
                page: page,
                per_page: 20,
                workspace_id: params?.id,
            };
            const res: any = await propertyService.getPropertyOptions(params?.id, optionId, param);
            const listFormated = res?.data?.items?.map((item) => {
                return {
                    ...item,
                    value: item?.id,
                    name: item?.label
                }
            });
            if (page === 1) setData(listFormated);
            if (page > 1) {
                setData([...data, ...listFormated]);
            }
            setPagination({ total: res?.data?.total, page: res?.data?.page });
        } catch (error) {

        }
    }
    useEffect(() => {
        getOptions(1);
    }, [optionId]);

    return (
        <>
            <SelectBox
                label=""
                control={control}
                name={name}
                data={data}
                pagination={pagination}
                placeholder={placeholder}
                getData={getOptions}
                numberOfItemDisplayed={2}
                isMulti={true}
                errors={errors}
                isShowSearchIcon={false}
                value={values}
                className="!h-[40px]"
            />
        </>
    )
}