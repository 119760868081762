import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import dayjs from 'dayjs';
import './Table.scss';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import ActionMenu from './ActionMenu';
import { SESSION_STATUS } from 'constant/course.const';
import { CheckboxTable } from 'components/Checkbox/CheckboxComponent';
import DialogComponent from 'components/Dialog/DialogComponent';
import { SessionDetail, SessionTitle } from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/SessionDetail';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    selected: any[];
    setSelected: React.Dispatch<React.SetStateAction<any[]>>;
}

const SessionTable: FC<IViewTable> = ({ data, refreshData, selected, setSelected }) => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();
    const [isOpenModalDetails, setOpenModalDetails] = useState(false);
    const [itemSelected, setItemSelected] = useState<any>();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const {
        userState: { result },
    } = useContext(UserContext);

    const [isAll, setIsAll] = useState<boolean>(false);

    useEffect(() => {
        if (!selected?.length) setIsAll(false);
    }, [selected]);

    const columns = useMemo(
        () => [
            {
                Header: () => (
                    <CheckboxTable
                        checked={isAll}
                        indeterminate={!isAll && selected?.length > 0}
                        onChange={() => {
                            setIsAll(!isAll);
                            setSelected(!isAll ? data : []);
                        }}
                        id="all"
                        label=" "
                    />
                ),
                Cell: ({ row }: any) => {
                    const session = row?.original;
                    const hasCheck = !!selected?.find((item) => item?.id === session?.id);
                    return (
                        <CheckboxTable
                            id={row?.original?.id}
                            label=" "
                            checked={hasCheck}
                            onChange={() => {
                                let listSelected = [];
                                if (hasCheck) listSelected = selected.filter((item) => item?.id !== session.id);
                                else listSelected = [...selected, session];
                                setSelected(listSelected);
                                setIsAll(listSelected?.length === data?.length);
                            }}
                        />
                    );
                },
                accessor: 'session_selected',
                width: '30',
            },
            {
                Header: () => <RenderTableHeader value={t("SESSION.SESSION_ID")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell onClick={() => { setItemSelected(row?.original); setOpenModalDetails(true) }} className="text-primary-500 font-semibold" value={row.values?.id} />;
                },
                accessor: 'id',
                width: '80',
            },
            {
                Header: () => <RenderTableHeader value={t('COURSE')} />,
                accessor: 'course',
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            onClick={() => window.open(`/workspace/${params?.id}/management/courses/${row.values?.course?.id}`, '_blank')}
                            className="text-primary-500 font-semibold"
                            value={row.values?.course?.title}
                        />
                    );
                },
                width: '150',
            },
            {
                Header: () => <RenderTableHeader value={t('DATE')} />,
                accessor: 'start_time',
                Cell: ({ row }: any) => (
                    <RenderTableCell value={dayjs.utc(row.values?.start_time).tz(result?.time_zone).format('DD/MM/YYYY')} />
                ),
                width: '90',
            },
            {
                Header: () => <RenderTableHeader value={t('TIME')} />,
                accessor: 'end_time',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={`${dayjs.utc(row.values?.start_time).tz(result?.time_zone).format('HH:mm')} - ${dayjs
                            .utc(row.values?.end_time)
                            .tz(result?.time_zone)
                            .format('HH:mm')}`}
                    />
                ),
                width: '90',
            },
            {
                Header: () => <RenderTableHeader value={t('STATUS')} />,
                accessor: 'display_status',
                Cell: ({ row }: any) => {
                    if (!row?.original?.display_status) return <RenderTableCell value="" />;
                    return (
                        <div className="py-3 ml-2">
                            <span className={`sessionList-status sessionList-status--${row?.original?.display_status}`}>
                                {SESSION_STATUS(t)?.find((el) => el?.value === row?.original?.display_status)?.name}
                            </span>
                        </div>
                    );
                },
                width: '110',
            },
            {
                Header: () => <RenderTableHeader value={t('INSTRUCTOR')} />,
                accessor: 'instructors',
                Cell: ({ row }: any) => {
                    const peopleEnrolled = [...(row?.original?.instructors || [])];
                    return (
                        <div className="flex gap-1 text-sm text-gray-800 ml-2">
                            {peopleEnrolled?.slice(0, 2)?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            data-tip={item?.name || item?.display_name}
                                            data-for={row?.original?.id + '_show-more-instructors_' + index}
                                            className={`w-7 h-7 rounded-full overflow-hidden bg-cover ${peopleEnrolled?.length > 2 && index > 0
                                                ? '-ml-3.5 border-2 border-white'
                                                : 'border-2 border-white'
                                                }`}
                                            style={{ backgroundImage: `url('${item?.avatar_url}')` }}
                                        ></div>
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                id={row?.original?.id + '_show-more-instructors_' + index}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </div>
                                );
                            })}
                            {peopleEnrolled?.length > 2 && (
                                <div
                                    className="w-7 h-7 border-2 bg-gray-300 border-white text-gray-900 text-[10px] -ml-3.5 p-0.5 flex items-center justify-center rounded-full hover:text-primary-500 hover:border-primary-500"
                                    data-tip={peopleEnrolled?.slice(2)?.map((item) => item?.display_name)}
                                    data-for={row?.original?.id + '_show-more-instructors'}
                                >
                                    +{peopleEnrolled?.length - 2}
                                </div>
                            )}
                            {
                                //@ts-ignore
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="max-w-xs"
                                    id={row?.original?.id + '_show-more-instructors'}
                                    getContent={(content) => <div>{content}</div>}
                                />
                            }
                        </div>
                    );
                },
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={t('LEARNER')} />,
                accessor: 'learners',
                Cell: ({ row }: any) => {
                    const peopleEnrolled = [...(row?.original?.learners || [])];
                    return (
                        <div className="flex gap-1 text-sm text-gray-800 ml-2">
                            {peopleEnrolled?.slice(0, 2)?.map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div
                                            data-tip={item?.name || item?.display_name}
                                            data-for={row?.original?.id + '_show-more-learners_' + index}
                                            className={`w-7 h-7 rounded-full overflow-hidden bg-cover ${peopleEnrolled?.length > 2 && index > 0
                                                ? '-ml-3.5 border-2 border-white'
                                                : 'border-2 border-white'
                                                }`}
                                            style={{ backgroundImage: `url('${item?.avatar_url}')` }}
                                        ></div>
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                id={row?.original?.id + '_show-more-learners_' + index}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </div>
                                );
                            })}
                            {peopleEnrolled?.length > 2 && (
                                <div
                                    className="w-7 h-7 -ml-3.5 border-2 bg-gray-300 border-white text-gray-900 text-[10px] flex items-center justify-center rounded-full hover:text-primary-500 hover:border-primary-500"
                                    data-tip={peopleEnrolled?.slice(2)?.map((item) => item?.display_name)}
                                    data-for={row?.original?.id + '_show-more-learners'}
                                >
                                    +{peopleEnrolled?.length - 2}
                                </div>
                            )}
                            {
                                //@ts-ignore
                                <ReactTooltip
                                    place="top"
                                    type="dark"
                                    effect="float"
                                    className="max-w-xs"
                                    id={row?.original?.id + '_show-more-learners'}
                                    getContent={(content) => <div>{content}</div>}
                                />
                            }
                        </div>
                    );
                },
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={t('COURSES.ATTENDANCE')} />,
                accessor: 'session_attendances',
                Cell: ({ row }: any) => {
                    let text = ''
                    if (row?.original?.display_status !== 'completed' ) text = '-'
                    else if (row?.original?.session_attendances?.length > 0) text = 'Marked';
                    else text = 'Unmarked';
                    return <RenderTableCell value={text} />;
                },
                width: '130',
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu
                                data={{ ...row?.original, time_zone: result?.time_zone }}
                                refreshData={refreshData}
                                position={row?.index > data?.length / 2 ? 'bottom-0' : ''}
                                disabledAction={!!selected?.length}
                                status={row?.original?.display_status}
                            />
                        </div>
                    );
                },
                accessor: 'action',
                width: 80,
            },
        ],
        [data, WorkspaceDetailInformation, selected, isAll]
    );

    return <>
        <Table data={data || []} columns={columns} emptyContent={t('SESSION.NO_SESSIONS')} emptyIcon={NO_CONTENT} />
        {isOpenModalDetails && (
            <DialogComponent
                title={<SessionTitle refreshData={refreshData} session={itemSelected} />}
                isOpen={isOpenModalDetails}
                onCloseModal={() => setOpenModalDetails(false)}
                styles="max-w-2xl !p-3"
                stylesTitle="!h-5"
                child={
                    <div className="-mx-2 border-t border-gray-300">
                        <SessionDetail session={itemSelected} isOpen={true} close={() => setOpenModalDetails(false)} />
                    </div>
                }
            />
        )}
    </>;
};

export default SessionTable;
