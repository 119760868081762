interface IconProps {
    className?: string;
    fill?: string;
}

const CopyIcon = () => (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 group">
        <path
            className="group-hover:fill-ooolab_blue_7 group-focus:fill-ooolab_white"
            d="M0.5 2C0.5 1.17157 1.17157 0.5 2 0.5H9.5C10.3284 0.5 11 1.17157 11 2V5H14C14.8284 5 15.5 5.67157 15.5 6.5V14C15.5 14.8284 14.8284 15.5 14 15.5H6.5C5.67157 15.5 5 14.8284 5 14V11H2C1.17157 11 0.5 10.3284 0.5 9.5V2ZM6.5 11V14H14V6.5H11V9.5C11 10.3284 10.3284 11 9.5 11H6.5ZM9.5 9.5V2L2 2V9.5H9.5Z"
            fill="#8F90A6"
        />
    </svg>
);

const InviteIcon = () => (
    <svg viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 group">
        <path
            className="group-hover:fill-ooolab_blue_7 group-focus:fill-ooolab_white"
            d="M5.25 1.5C3.86929 1.5 2.75 2.61929 2.75 4C2.75 5.38071 3.86929 6.5 5.25 6.5C6.63071 6.5 7.75 5.38071 7.75 4C7.75 2.61929 6.63071 1.5 5.25 1.5ZM1.5 4C1.5 1.92893 3.17893 0.25 5.25 0.25C7.32107 0.25 9 1.92893 9 4C9 6.07107 7.32107 7.75 5.25 7.75C3.17893 7.75 1.5 6.07107 1.5 4ZM10.875 5.875C11.2202 5.875 11.5 6.15482 11.5 6.5V7.125H12.125C12.4702 7.125 12.75 7.40482 12.75 7.75C12.75 8.09518 12.4702 8.375 12.125 8.375H11.5V9C11.5 9.34518 11.2202 9.625 10.875 9.625C10.5298 9.625 10.25 9.34518 10.25 9V8.375H9.625C9.27982 8.375 9 8.09518 9 7.75C9 7.40482 9.27982 7.125 9.625 7.125H10.25V6.5C10.25 6.15482 10.5298 5.875 10.875 5.875ZM3.0625 10.25C2.27534 10.25 1.5 11.0085 1.5 12.125C1.5 12.4702 1.22018 12.75 0.875 12.75C0.529822 12.75 0.25 12.4702 0.25 12.125C0.25 10.4801 1.43342 9 3.0625 9H7.4375C9.06658 9 10.25 10.4801 10.25 12.125C10.25 12.4702 9.97018 12.75 9.625 12.75C9.27982 12.75 9 12.4702 9 12.125C9 11.0085 8.22466 10.25 7.4375 10.25H3.0625Z"
            fill="#8F90A6"
        />
    </svg>
);

const ShareIcon = () => (
    <svg viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-ooolab_w_4 h-ooolab_w_3">
        <path
            className="group-hover:fill-ooolab_blue_7 group-focus:fill-ooolab_white"
            d="M4.5 2C2.81421 2 1.5 3.31421 1.5 5C1.5 6.68579 2.81421 8 4.5 8H6C6.41421 8 6.75 8.33579 6.75 8.75C6.75 9.16421 6.41421 9.5 6 9.5H4.5C1.98579 9.5 0 7.51421 0 5C0 2.48579 1.98579 0.5 4.5 0.5H6C6.41421 0.5 6.75 0.835786 6.75 1.25C6.75 1.66421 6.41421 2 6 2H4.5ZM8.25 1.25C8.25 0.835786 8.58579 0.5 9 0.5H10.5C13.0142 0.5 15 2.48579 15 5C15 7.51421 13.0142 9.5 10.5 9.5H9C8.58579 9.5 8.25 9.16421 8.25 8.75C8.25 8.33579 8.58579 8 9 8H10.5C12.1858 8 13.5 6.68579 13.5 5C13.5 3.31421 12.1858 2 10.5 2H9C8.58579 2 8.25 1.66421 8.25 1.25ZM3.75 5C3.75 4.58579 4.08579 4.25 4.5 4.25H10.5C10.9142 4.25 11.25 4.58579 11.25 5C11.25 5.41421 10.9142 5.75 10.5 5.75H4.5C4.08579 5.75 3.75 5.41421 3.75 5Z"
            fill="#8F90A6"
        />
    </svg>
);

const LaptopIcon: React.FC<IconProps> = (props) => {
    return (
        <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.3335 3.33333C1.3335 2.59695 1.93045 2 2.66683 2H13.3335C14.0699 2 14.6668 2.59695 14.6668 3.33333V10.6667C14.6668 11.403 14.0699 12 13.3335 12H2.66683C1.93045 12 1.3335 11.403 1.3335 10.6667V3.33333ZM13.3335 3.33333H2.66683V10.6667H13.3335V3.33333Z" />
            <path d="M14.6668 13.3333C14.6668 13.7015 14.3684 14 14.0002 14H2.00016C1.63197 14 1.3335 13.7015 1.3335 13.3333C1.3335 12.9651 1.63197 12.6667 2.00016 12.6667L14.0002 12.6667C14.3684 12.6667 14.6668 12.9651 14.6668 13.3333Z" />
        </svg>
    );
};
const UsersIcon: React.FC<IconProps> = (props) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.66683 2.66683C5.19407 2.66683 4.00016 3.86074 4.00016 5.3335C4.00016 6.80626 5.19407 8.00016 6.66683 8.00016C8.13959 8.00016 9.3335 6.80626 9.3335 5.3335C9.3335 3.86074 8.13959 2.66683 6.66683 2.66683ZM2.66683 5.3335C2.66683 3.12436 4.45769 1.3335 6.66683 1.3335C8.87597 1.3335 10.6668 3.12436 10.6668 5.3335C10.6668 7.54263 8.87597 9.3335 6.66683 9.3335C4.45769 9.3335 2.66683 7.54263 2.66683 5.3335ZM11.2191 2.50507C11.4795 2.24472 11.9016 2.24472 12.1619 2.50507C13.724 4.06717 13.724 6.59983 12.1619 8.16192C11.9016 8.42227 11.4795 8.42227 11.2191 8.16192C10.9588 7.90157 10.9588 7.47946 11.2191 7.21911C12.2605 6.17772 12.2605 4.48928 11.2191 3.44788C10.9588 3.18753 10.9588 2.76542 11.2191 2.50507ZM11.6867 11.1718C11.776 10.8146 12.138 10.5974 12.4952 10.6867C13.3805 10.9081 13.9408 11.4741 14.2631 12.1187C14.575 12.7424 14.6668 13.4382 14.6668 14.0002C14.6668 14.3684 14.3684 14.6668 14.0002 14.6668C13.632 14.6668 13.3335 14.3684 13.3335 14.0002C13.3335 13.5621 13.2587 13.0913 13.0705 12.715C12.8928 12.3595 12.6199 12.0923 12.1718 11.9803C11.8146 11.891 11.5974 11.529 11.6867 11.1718ZM4.3335 12.0002C3.49386 12.0002 2.66683 12.8092 2.66683 14.0002C2.66683 14.3684 2.36835 14.6668 2.00016 14.6668C1.63197 14.6668 1.3335 14.3684 1.3335 14.0002C1.3335 12.2456 2.59581 10.6668 4.3335 10.6668H9.00016C10.7379 10.6668 12.0002 12.2456 12.0002 14.0002C12.0002 14.3684 11.7017 14.6668 11.3335 14.6668C10.9653 14.6668 10.6668 14.3684 10.6668 14.0002C10.6668 12.8092 9.8398 12.0002 9.00016 12.0002H4.3335Z"
                fill="#8F90A6"
            />
        </svg>
    );
};

const loadSVG = () => (
    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
    </svg>
);

const EyeSVG: React.FC<IconProps> = (props) => (
    <svg {...props} className="w-5 h-4" viewBox="0 0 20 14" fill="none">
        <path
            className="group-hover:fill-button_bar_hover"
            d="M13 7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7C7 5.34315 8.34315 4 10 4C11.6569 4 13 5.34315 13 7Z"
            fill="#404475"
        />
        <path
            className="group-hover:fill-button_bar_hover"
            d="M19.8944 6.55279C17.7362 2.23635 13.9031 0 10 0C6.09687 0 2.26379 2.23635 0.105573 6.55279C-0.0351909 6.83431 -0.0351909 7.16569 0.105573 7.44721C2.26379 11.7637 6.09687 14 10 14C13.9031 14 17.7362 11.7637 19.8944 7.44721C20.0352 7.16569 20.0352 6.83431 19.8944 6.55279ZM10 12C7.03121 12 3.99806 10.3792 2.12966 7C3.99806 3.62078 7.03121 2 10 2C12.9688 2 16.0019 3.62078 17.8703 7C16.0019 10.3792 12.9688 12 10 12Z"
            fill="#404475"
        />
    </svg>
);

const EyeOffSVG: React.FC<IconProps> = (props) => (
    <svg {...props} className="w-5 h-5" viewBox="0 0 20 18" fill="none">
        <path
            className="group-hover:fill-button_bar_hover"
            d="M2.70711 0.292893C2.31658 -0.0976311 1.68342 -0.0976311 1.29289 0.292893C0.902369 0.683417 0.902369 1.31658 1.29289 1.70711L3.71706 4.13127C2.28639 5.20737 1.03925 6.68543 0.105573 8.55279C-0.0351909 8.83432 -0.0351909 9.16569 0.105573 9.44722C2.26379 13.7637 6.09687 16 10 16C11.5552 16 13.0992 15.645 14.5306 14.9448L17.2929 17.7071C17.6834 18.0976 18.3166 18.0976 18.7071 17.7071C19.0976 17.3166 19.0976 16.6834 18.7071 16.2929L2.70711 0.292893ZM13.0138 13.428C12.0343 13.8112 11.0134 14 10 14C7.03121 14 3.99806 12.3792 2.12966 9C2.94721 7.52136 3.98778 6.3794 5.14838 5.56259L7.29237 7.70659C7.10495 8.09822 7 8.53686 7 9.00001C7 10.6569 8.34315 12 10 12C10.4631 12 10.9018 11.8951 11.2934 11.7076L13.0138 13.428Z"
            fill="#404475"
        />
        <path
            className="group-hover:fill-button_bar_hover"
            d="M16.5523 10.8955C17.0353 10.3402 17.4784 9.70876 17.8703 9C16.0019 5.62078 12.9687 4 9.99996 4C9.88796 4 9.77586 4.00231 9.66374 4.00693L7.87939 2.22258C8.57741 2.07451 9.28752 2 9.99996 2C13.9031 2 17.7362 4.23635 19.8944 8.55279C20.0352 8.83431 20.0352 9.16569 19.8944 9.44721C19.3504 10.5352 18.7 11.491 17.9689 12.3121L16.5523 10.8955Z"
            fill="#404475"
        />
    </svg>
);

const DotSVG: React.FC<IconProps> = ({ fill = '#8083A3', className }) => (
    <svg viewBox="0 0 6 6" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" fill={fill} />
    </svg>
);

const EditSVG = ({ fill = '#8F90A6', className }: IconProps) => {
    return (
        <svg className={className} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.03583 13.9999H0.5V10.4641L10.0292 0.934907C10.1854 0.778681 10.3974 0.690918 10.6183 0.690918C10.8393 0.690918 11.0512 0.778681 11.2075 0.934907L13.565 3.29241C13.7212 3.44868 13.809 3.6606 13.809 3.88157C13.809 4.10254 13.7212 4.31447 13.565 4.47074L4.03583 13.9999ZM0.5 15.6666H15.5V17.3332H0.5V15.6666Z"
                fill={fill}
            />
        </svg>
    );
};

const LoadingSVG = ({ className = 'text-white' }: IconProps) => {
    return (
        <svg className={`animate-spin h-5 w-5 ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
        </svg>
    );
};

const PlaySVG = ({ className = '' }) => (
    <svg className={`h-3 w-3 text-white ${className}`} viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.5 2.61742C0.5 1.3311 1.89536 0.529649 3.00645 1.17779L10.5193 5.56026C11.6218 6.20339 11.6218 7.79639 10.5193 8.43952L3.00645 12.822C1.89536 13.4701 0.5 12.6687 0.5 11.3824V2.61742ZM9.67947 6.99989L2.16667 2.61742V11.3824L9.67947 6.99989Z"
            fill="white"
        />
    </svg>
);

const PowerSVG = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="11.5" fill="white" stroke="#EBEBF0" />
        <path
            d="M12.0001 6.75C11.6793 6.75 11.4168 7.0125 11.4168 7.33333V12C11.4168 12.3208 11.6793 12.5833 12.0001 12.5833C12.321 12.5833 12.5835 12.3208 12.5835 12V7.33333C12.5835 7.0125 12.321 6.75 12.0001 6.75ZM14.9985 8.41833C14.771 8.64583 14.7768 9.00167 14.9926 9.22917C15.6518 9.92917 16.0601 10.8625 16.0835 11.895C16.136 14.1292 14.2868 16.0542 12.0526 16.0775C9.77181 16.1125 7.91681 14.275 7.91681 12C7.91681 10.9267 8.33098 9.9525 9.00764 9.22333C9.22348 8.99583 9.22348 8.64 9.00181 8.41833C8.76848 8.185 8.38931 8.19083 8.16764 8.43C7.32181 9.32833 6.79098 10.5242 6.75014 11.8483C6.66848 14.695 8.98431 17.1567 11.831 17.2442C14.806 17.3375 17.2501 14.9517 17.2501 11.9942C17.2501 10.6117 16.7135 9.36333 15.8385 8.43C15.6168 8.19083 15.2318 8.185 14.9985 8.41833Z"
            fill="#2E3A59"
        />
    </svg>
);

const MapSVG = ({ className = '' }) => (
    <svg className={`h-4 w-4 text-white ${className}`} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.73656 0.542764C5.90761 0.485745 6.09255 0.485745 6.2636 0.542764L11.0001 2.12159L14.6397 0.908383C15.7189 0.548643 16.8334 1.35193 16.8334 2.48952V12.3994C16.8334 13.1168 16.3744 13.7537 15.6938 13.9805L11.2636 15.4572C11.0925 15.5143 10.9076 15.5143 10.7366 15.4572L6.00008 13.8784L2.36046 15.0916C1.28124 15.4514 0.166748 14.6481 0.166748 13.5105V3.60063C0.166748 2.88325 0.625798 2.24635 1.30637 2.01949L5.73656 0.542764ZM6.83342 12.3994L10.1667 13.5105V3.60063L6.83342 2.48952V12.3994ZM5.16675 2.48952L1.83341 3.60063V13.5105L5.16675 12.3994V2.48952ZM11.8334 3.60063V13.5105L15.1667 12.3994V2.48952L11.8334 3.60063Z"
            fill="white"
        />
    </svg>
);

const RefreshSVG = ({ className = '' }) => (
    <svg
        className={`h-4 w-4 text-gray-300 ${className}`}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10.6609 1.91076C10.9863 1.58533 11.514 1.58533 11.8394 1.91076L14.3394 4.41076C14.6649 4.7362 14.6649 5.26384 14.3394 5.58928L11.8394 8.08928C11.514 8.41471 10.9863 8.41471 10.6609 8.08928C10.3355 7.76384 10.3355 7.2362 10.6609 6.91077L11.7383 5.83335H10.4168C7.4604 5.83335 5.00016 8.29359 5.00016 11.25C5.00016 14.2065 7.4604 16.6667 10.4168 16.6667C13.3733 16.6667 15.8335 14.2065 15.8335 11.25C15.8335 10.7898 16.2066 10.4167 16.6668 10.4167C17.1271 10.4167 17.5002 10.7898 17.5002 11.25C17.5002 15.1269 14.2937 18.3334 10.4168 18.3334C6.53993 18.3334 3.3335 15.1269 3.3335 11.25C3.3335 7.37312 6.53993 4.16669 10.4168 4.16669H11.7383L10.6609 3.08928C10.3355 2.76384 10.3355 2.2362 10.6609 1.91076Z"
            fill="#111827"
        />
    </svg>
);

const WarningSVG = ({ className = '' }) => (
    <svg className={`h-4 w-4 text-orange-500 ${className}`} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.5002 18.6667C15.7638 18.6667 15.1669 18.0697 15.1669 17.3333V13.3333C15.1669 12.597 15.7638 12 16.5002 12C17.2366 12 17.8336 12.597 17.8336 13.3333V17.3333C17.8336 18.0697 17.2366 18.6667 16.5002 18.6667Z"
            fill="#F97316"
        />
        <path
            d="M14.5002 22C14.5002 20.8954 15.3957 20 16.5002 20C17.6048 20 18.5002 20.8954 18.5002 22C18.5002 23.1046 17.6048 24 16.5002 24C15.3957 24 14.5002 23.1046 14.5002 22Z"
            fill="#F97316"
        />
        <path
            d="M14.1404 4.28747C15.1402 2.38791 17.8602 2.38789 18.86 4.28747L29.9849 25.4247C30.9195 27.2004 29.6318 29.3333 27.6251 29.3333H5.37538C3.36869 29.3333 2.08099 27.2004 3.0156 25.4247L14.1404 4.28747ZM27.6251 26.6667L16.5002 5.52946L5.37538 26.6667L27.6251 26.6667Z"
            fill="#F97316"
        />
    </svg>
);

const CollapseIcon = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        className={className}
    >
        <g clipPath="url(#clip0_2676_51561)">
            <rect width="20" height="20" fill="#fff" rx="4"></rect>
            <path fill="#0071CE" d="M5 9h10v2H5z" opacity="0.8"></path>
        </g>
        <rect
            width="19"
            height="19"
            x="0.5"
            y="0.5"
            stroke="#D1D5DB"
            rx="3.5"
        ></rect>
        <defs>
            <clipPath id="clip0_2676_51561">
                <rect width="20" height="20" fill="#fff" rx="4"></rect>
            </clipPath>
        </defs>
    </svg>
);

const ExpandIcon = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
        className={className}
    >
        <rect width="19" height="19" x="0.5" y="0.5" fill="#fff" rx="3.5"></rect>
        <rect
            width="19"
            height="19"
            x="0.5"
            y="0.5"
            stroke="#D1D5DB"
            rx="3.5"
        ></rect>
        <path
            fill="#0071CE"
            fillRule="evenodd"
            d="M11 5H9v4H5v2h4v4h2v-4h4V9h-4z"
            clipRule="evenodd"
        ></path>
    </svg>
);

const DotIcon = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="6"
        fill="none"
        viewBox="0 0 6 6"
        className={className}
    >
        <circle cx="3" cy="3" r="3" fill="#374151"></circle>
    </svg>
);

const CheckboxReminder = ({ className = '' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        className={className}
        >
        <g clipPath="url(#clip0_2413_30175)">
            <path
            fill="#06B6D4"
            d="M7 7V3a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-4v3.993c0 .556-.449 1.007-1.007 1.007H3.007A1.006 1.006 0 0 1 2 20.993l.003-12.986C2.003 7.451 2.452 7 3.01 7zm2 0h6.993C16.549 7 17 7.449 17 8.007V15h3V4H9zm-.497 11 5.656-5.657-1.414-1.414-4.242 4.243L6.38 13.05l-1.414 1.414z"
            ></path>
        </g>
        <defs>
            <clipPath id="clip0_2413_30175">
            <path fill="#fff" d="M0 0h24v24H0z"></path>
            </clipPath>
        </defs>
        </svg>
    );

import { ClockIcon } from './clock';
import { ArrowDownRight } from './ArrowDownRight';
import { ArrorBottom } from './ArrorBottom';
import { ArrowDownSLine } from './ArrowDownSLine';
import { DeleteIcon } from './DeleteIcon';
import { FolderOpen } from './FolderOpen';
import { CheckIcon } from './CheckIcon';
import { RestoreIcon } from './RestoreIcon';
import { SubTagIcon } from './SubTagIcon';
import { MoveIcon } from './MoveIcon';
import { CopyFileIcon } from './CopyFileIcon';
import { FolderIcon } from './FolderIcon';
import { EditIcon } from './EditIcon';
import { PlayIcon } from './PlayIcon';
import { DownloadIcon } from './DownloadIcon';
import { NextIcon } from './NextIcon';
import { PreviousIcon } from './PreviousIcon';
import ReportIcon from './ReportIcon'
import WarningCircleIcon from './WarningCircleIcon'
import StatusIcon from './StatusIcon';
import CloudUploadIcon from './CloudUploadIcon'
export * from './CloudCheckIcon';
export * from './DesktopIcon';
export * from './MobileIcon';
export * from './ArrowRightSIcon';
export * from './InformationCircleIcon';
export * from './BookMarkFillIcon';
export * from './DraftFillIcon';
export * from './Book3FillIcon';
import { PresentationIcon } from './PresentationIcon';
import { TextIcon } from './TextIcon';
export * from "./CheckboxFill";
export * from "./ZoomInIcon";
export * from "./ZoomOutIcon";
export * from "./ListIcon";
export * from './CircleInfomationIcon';
export * from './AddIcon';
export * from './ReorderAlt';
export * from './CommentIcon';
export * from './DraftFill';
export * from './VideoUpload';
export * from './ImageUpload';
export * from './BookMarkLine';
export * from './DraftLine';
export * from './Book3Outline';
export * from './OpenBox';
export * from './ListOrder';
export * from './CaretIcon';
export * from './ListSetting';
export * from './Dashboard';
export * from './Librarys';
export * from './EditLine';
export * from './ImportIcon';
export * from './HistoryIcon';
export * from './ListViewIcon';
export * from './LoadingIcon';
export * from './ImageFill';
export * from './AlertIcon';
export * from './GetLinkIcon';
export * from './ShowHideIcon';
export * from './EyesIcon';

export {
    CopyIcon,
    InviteIcon,
    ShareIcon,
    LaptopIcon,
    UsersIcon,
    loadSVG,
    EyeSVG,
    EyeOffSVG,
    DotSVG,
    EditSVG,
    LoadingSVG,
    PlaySVG,
    PowerSVG,
    MapSVG,
    RefreshSVG,
    WarningSVG,
    ClockIcon,
    ArrowDownRight,
    ArrorBottom,
    ArrowDownSLine,
    DeleteIcon,
    FolderOpen,
    CheckIcon,
    RestoreIcon,
    SubTagIcon,
    MoveIcon,
    CopyFileIcon,
    FolderIcon,
    DownloadIcon,
    PlayIcon,
    EditIcon,
    NextIcon,
    PreviousIcon,
    PresentationIcon,
    TextIcon,
    StatusIcon,
    WarningCircleIcon,
    ReportIcon,
    CloudUploadIcon,
    CollapseIcon,
    ExpandIcon,
    DotIcon,
    CheckboxReminder,
};
