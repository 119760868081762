import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditIcon } from 'assets/icon/EditIcon';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { EyeIcon, TrashIcon } from '@heroicons/react/outline';
import { SessionDetail, SessionTitle } from '../../../Calendar/components/ModalSessionDetail/SessionDetail';
import { SessionEdit } from '../SessionEdit/SessionEdit';
import ActionsButton from 'components/ActionsButton';
import courseService from 'services/course.service';
import DialogComponent from 'components/Dialog/DialogComponent';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { checkPermission } from 'utils/commonFun';
import { USER_STATUS } from 'components/Management/User/constant';

interface ActionMenuProp {
    refreshData: Function,
    data: any;
    hideAction?: string;
    editLearner?: boolean;
    userData?: any;
    position?: string;
    settings?: any;
    disabledAction?: boolean;
}

const ActionMenu: FC<ActionMenuProp> = ({
    refreshData,
    data,
    hideAction,
    editLearner,
    userData,
    position,
    settings,
    disabledAction = false,
}) => {
    const { t: translator } = useTranslation();
    const [isOpenModalDeactivate, setOpenModalDeactivate] = useState(false);
    const [isOpenModalDetails, setOpenModalDetails] = useState(false);
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);

    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const handleCancelSession = () => {
        const payload = {
            learner_id: data?.learners[0]?.id
        }
        courseService
            .cancelSession(workspaceId, courseId || data?.course_id, data?.id, payload)
            .then(() => {
                Toastify.success('Session successfully canceled');
                setOpenModalDeactivate(false);
                refreshData();
            })
            .catch(() => Toastify.error());
    };
    const {
        userState: { result },
    } = useContext(UserContext);
    
    return (
        <>
            <ActionsButton
                disable={disabledAction}
                styleOptions={`${position ? position : 'top-0'} right-[35px]`}
                menus={[
                    {
                        label: translator('VIEW'),
                        icon: <EyeIcon className="w-4 h-4" />,
                        action: () => setOpenModalDetails(true),
                        hide: hideAction?.includes('view') || (!checkPermission('course', 'R') && !checkPermission('session', 'R')),
                    },
                    {
                        label: translator('EDIT'),
                        icon: <EditIcon />,
                        action: () => setOpenModalEdit(true),
                        isDisabled:
                            dayjs.utc().tz(result.time_zone).isAfter(dayjs.utc(data?.end_time).tz(result?.time_zone)) ||
                            data?.status == 'cancelled' ||
                            userData?.membership?.status === USER_STATUS.DEACTIVATE,
                        hide: ((!checkPermission('course', 'U') && !checkPermission('session', 'U')) || data?.display_status === 'completed' || data?.display_status === 'cancelled'),
                    },
                    {
                        label: translator('CANCEL'),
                        icon: <TrashIcon className="w-4 h-4" />,
                        action: () => setOpenModalDeactivate(true),
                        isDisabled: data?.status == 'completed' || data?.status == 'cancelled' || userData?.membership?.status === USER_STATUS.DEACTIVATE,
                        hide:
                            userData?.membership?.status === USER_STATUS.DEACTIVATE ||
                            (!checkPermission('course', 'U') && !checkPermission('session', 'U')) ||
                            (data?.display_status === 'completed' || data?.display_status === 'cancelled'),
                    },
                ]}
            />
            {isOpenModalDeactivate && (
                <DialogComponent
                    title={translator('SESSION.CANCEL_SESSION')}
                    isOpen={isOpenModalDeactivate}
                    onCloseModal={() => setOpenModalDeactivate(false)}
                    styles="overflow-hidden max-w-lg px-6 !py-4"
                    child={
                        <div className="flex flex-col mt-1">
                            <div className="mt-2 text-sm text-gray-800">
                                {settings?.enrollment_type === 'credit' || data?.course?.enrollment_type === 'credit'
                                    ? translator('SESSION.CANCEL_SESSIONS_HAS_CREDIT_MESSAGE')
                                    : translator('SESSION.CANCEL_A_SESSION_NONE_CREDIT_MESSAGE')
                                }
                            </div>
                            <div className="flex justify-center gap-4 mt-6 mb-2 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDeactivate(false)}>
                                    {translator('NO')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className='buttonPrimary-danger' onClick={handleCancelSession}>
                                    {translator('Yes')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
            {isOpenModalDetails && (
                <DialogComponent
                    title={<SessionTitle refreshData={refreshData} session={data} />}
                    isOpen={isOpenModalDetails}
                    onCloseModal={() => setOpenModalDetails(false)}
                    styles="max-w-2xl !p-3"
                    stylesTitle="!h-5"
                    child={
                        <div className="-mx-2 border-t border-gray-300">
                            <SessionDetail session={data} isOpen={true} close={() => setOpenModalDetails(false)} />
                        </div>
                    }
                />
            )}
            {isOpenModalEdit && (
                <DialogComponent
                    title={''}
                    isOpen={isOpenModalEdit}
                    onCloseModal={() => setOpenModalEdit(false)}
                    isShowClose={false}
                    styles="!max-w-[600px] !p-0"
                    child={
                        <SessionEdit
                            data={data}
                            refreshData={refreshData}
                            onDelete={() => setOpenModalDeactivate(true)}
                            onClose={() => setOpenModalEdit(false)}
                            editLearner={editLearner}
                        />
                    }
                />
            )}
        </>
    );
};

export default ActionMenu;
