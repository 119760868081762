import { useEffect, useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import './GradebookDetails.scss';
import { useParams } from 'react-router-dom';
import { GradebookAssignment } from './GradebookAssignment/GradebookAssignment';
import { StudyHistory } from './StudyHistory/StudyHistory';
import courseService from 'services/course.service';
import { useTranslation } from 'react-i18next';
import { GradebookOverview } from './GradebookOverview/GradebookOverview';
import { LearningMaterials } from './LearningMaterials';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { CircleInformationIcon } from 'assets/icon';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { CourseSubtab, CourseTab, CourseViewType } from 'types/Courses.type';
import { classNames } from 'utils/commonFun';

const TAB = {
    overview: 'overview',
    assignment: 'assignment',
    learningMaterials: 'learning-materials',
    studyHistory: 'study-history',
};

export const GradebookDetails = ({ hasBackButton = true }) => {
    const { t } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const query = useQueryParamsURL();
    const { redirectToUrlCourse } = useUrlCourseNavigation();

    const tab = query.get('tab') as CourseTab;
    const subtab = query.get('subtab') as CourseSubtab;
    const enrollmentId = query.get('enrollmentId');
    const learnerId = query.get('learnerId');
    const learningGroupId = query.get('learningGroupId');
    const view = query.get('view') as CourseViewType;
    const viewGradebook = query.get('viewGradebook') as CourseViewType;

    const [overview, setOverview] = useState<any>();

    // const isGroup: any = query?.get('isGroup') ?? false;
    // const learnerGroupeId = query.get('learnerGroupeId');
    // const _enrollmentId = enrollmentId || query.get('enrollment_id') || query.get('enrollmentId');
    // const fromGroup = query.get('fromGroup');

    // const isLearningGroup = JSON.parse(query.get('isLearningGroup') || null);
    // const leaderTab = learnerGroupeId ? `learnerGroupeId=${learnerGroupeId}` : `learnerId=${learnerId}`;
    // const [storageEnrollmentId, setStorageEnrollmentId] = useState<string | number>(null);

    useEffect(() => {
        let payload;
        if (subtab === 'learner' ) {
            payload = {
                learners: learnerId ? `[${learnerId}]` : `[]`,
            };
        } else {
            payload = {
                learning_groups: learningGroupId ? `[${learningGroupId}]` : `[]`,
            };
        }
        const getOverview = async () => {
            const response = await courseService.getGradebooks(params?.id, params?.courseId, payload);
            if (response?.data?.items?.length > 0) {
                setOverview(response?.data?.items[0]);
            }
        };
        const getOverviewByGroup = async () => {
            const response = await courseService.getGradebooksByGroup(params?.id, params?.courseId, learnerId);
            if (response?.data) {
                setOverview({
                    ...response?.data,
                    learner: response?.data?.user,
                });
            }
        };
        if (subtab === 'learning-group') {
            getOverviewByGroup();
        } else {
            getOverview();
        }
    }, [learnerId, learningGroupId, subtab]);

    // useEffect(() => {
    //     if (!!_enrollmentId) setStorageEnrollmentId(_enrollmentId);
    // }, [_enrollmentId]);

    const handleSwitchView = (_view: CourseViewType) => {
        let queryParams = {};
        const viewPeople = view ? { view } : {};
        
        if (subtab === 'learner') {
            queryParams = {
                enrollmentId,
                ...viewPeople,
                viewGradebook: _view,
                learnerId,
            };
        } else if (subtab === 'learning-group') {
            queryParams = {
                learningGroupId,
                ...viewPeople,
                viewGradebook: _view,
                learnerId,
            };
        }
        redirectToUrlCourse({
            tab,
            subtab,
            ...queryParams,
        });
    };

    // const onGoBack = () => {
    //     if (isLearningGroup) {
    //         history.push(
    //             `/workspace/${params?.id}/management/courses/${
    //                 params?.courseId
    //             }?tab=${tab}&learnerGroupeId=${isLearningGroup}&subtab=overview${
    //                 storageEnrollmentId ? `&enrollmentId${storageEnrollmentId}` : ''
    //             }`
    //         );
    //     } else {
    //         history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}`);
    //     }
    // };

    return (
        <>
            <div className="gradebookDetailsCourse">
                <div className="gradebookDetailsCourse-header">
                    {hasBackButton ? (
                        <h1 className="gradebookDetailsCourse-title">
                            <button
                                title={''}
                                onClick={() =>
                                    redirectToUrlCourse({
                                        tab,
                                        subtab,
                                    })
                                }
                            >
                                <ChevronLeftIcon className="w-5 h-5" />
                            </button>
                            {overview?.learner?.display_name || overview?.learning_group?.name}
                        </h1>
                    ) : null}
                    <div className="gradebookDetailsCourse-info">
                        <div className="gradebookDetailsCourse-list">
                            <div className="gradebookDetailsCourse-item">
                                <div className="gradebookDetailsCourse-label">{t('COURSES.COURSE_COMPLETION')}</div>
                                <div className="gradebookDetailsCourse-value">
                                    {overview?.course_completion == null ? '-' : `${overview?.course_completion}%`}
                                </div>
                            </div>
                            <div className="gradebookDetailsCourse-line"></div>
                            <div className="gradebookDetailsCourse-item">
                                <div className="flex items-center gap-1 max-w-sm">
                                    <div className="gradebookDetailsCourse-label">{t('COURSES.ASSIGNMENT_GRADE')} </div>
                                    <div
                                        className="w-3 h-3 cursor-pointer flex items-center mt-[1px]"
                                        data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                        data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                                    >
                                        <CircleInformationIcon width="12" height="12" />
                                        <TooltipCustom
                                            classNameContainer="shadow-medium max-w-sm  text-center"
                                            id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                            content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                                            place="top"
                                            type="light"
                                        />
                                    </div>
                                </div>
                                <div className="gradebookDetailsCourse-value">
                                    {overview?.assignment_score == null ? '-' : `${overview?.assignment_score}%`}
                                </div>
                            </div>
                            <div className="gradebookDetailsCourse-line"></div>
                            <div className="gradebookDetailsCourse-item">
                                <div className="gradebookDetailsCourse-label">{t('COURSES.ATTENDANCE')}</div>
                                <div className="gradebookDetailsCourse-value">
                                    {overview?.attendance == null ? '-' : `${overview?.attendance}%`}
                                </div>
                            </div>
                            <div className="gradebookDetailsCourse-line"></div>
                            <div className="gradebookDetailsCourse-item">
                                <div className="gradebookDetailsCourse-label">{t('COURSES.LESSON_GOAL_COMPLETION')}</div>
                                <div className="gradebookDetailsCourse-value">
                                    {overview?.lesson_goal == null ? '-' : `${overview?.lesson_goal}%`}
                                </div>
                            </div>
                        </div>
                        <div className="gradebookDetailsCourse-overall">
                            {t('COURSES.OVERALL_GRADE')}: <span>{overview?.overall == null ? '-' : `${overview?.overall}%`}</span>
                        </div>
                    </div>
                </div>
                <div className="gradebookDetailsCourse-content">
                    <div className="gradebookDetailsTab">
                        {subtab === 'learning-group' ? (
                            <div
                                onClick={() => {
                                    // const queryEnrollmentId = storageEnrollmentId ? `&enrollmentId=${storageEnrollmentId}` : '';
                                    // history.push(
                                    //     `/workspace/${params?.id}/management/courses/${params?.courseId}?tab=${tab}&${leaderTab}&subtab=overview${queryEnrollmentId}&view=gradebook`
                                    // );
                                    handleSwitchView('overview');
                                }}
                                className={classNames(
                                    'gradebookDetailsTab-item',
                                    viewGradebook === TAB.overview ? 'gradebookDetailsTab-item--actived' : ''
                                )}
                            >
                                {t('COURSES.COURSE_OVERVIEW')}
                            </div>
                        ) : null}

                        <div
                            onClick={() => {
                                // let url = '';
                                // if (tab === 'people') {
                                //     url = `/workspace/${params?.id}/management/courses/${
                                //         params?.courseId
                                //     }?tab=${tab}&${leaderTab}&subtab=assignment&isGroup=${
                                //         isGroup ?? false
                                //     }${`&enrollmentId=${storageEnrollmentId}&view=gradebook`}`;
                                // } else {
                                //     url = `/workspace/${params?.id}/management/courses/${
                                //         params?.courseId
                                //     }?tab=${tab}&${leaderTab}&subtab=assignment&isGroup=${isGroup ?? false}${
                                //         storageEnrollmentId ? `&enrollment_id=${storageEnrollmentId}` : ''
                                //     }`;
                                // }
                                // history.push(url + `&isLearningGroup=${isLearningGroup}`);
                                handleSwitchView('assignment');
                            }}
                            className={classNames(
                                'gradebookDetailsTab-item',
                                viewGradebook === TAB.assignment ? 'gradebookDetailsTab-item--actived' : ''
                            )}
                        >
                            {t('COURSES.ASSIGNMENT')}
                        </div>

                        <div
                            onClick={() => {
                                // let temp = '&enrollment_id=';
                                // if (tab === 'people') temp = '&enrollmentId=';
                                // const queryEnrollmentId = storageEnrollmentId ? `${temp}${storageEnrollmentId}` : '';
                                // history.push(
                                //     `/workspace/${params?.id}/management/courses/${
                                //         params?.courseId
                                //     }?tab=${tab}&${leaderTab}&subtab=learning-materials&isGroup=${
                                //         isGroup ?? false
                                //     }${queryEnrollmentId}&view=gradebook`
                                // );
                                handleSwitchView('learning-materials');
                            }}
                            className={classNames(
                                'gradebookDetailsTab-item',
                                viewGradebook === TAB.learningMaterials ? 'gradebookDetailsTab-item--actived' : ''
                            )}
                        >
                            {t('LEARNING_MATERIALS')}
                        </div>

                        <div
                            onClick={() => {
                                // let url = '';
                                // if (tab === 'people') {
                                //     url = `/workspace/${params?.id}/management/courses/${
                                //         params?.courseId
                                //     }?tab=${tab}&${leaderTab}&subtab=study-history&isGroup=${
                                //         isGroup ?? false
                                //     }${`&enrollmentId=${storageEnrollmentId}&view=gradebook`}`;
                                // } else {
                                //     url = `/workspace/${params?.id}/management/courses/${
                                //         params?.courseId
                                //     }?tab=${tab}&${leaderTab}&subtab=study-history&isGroup=${isGroup ?? false}${
                                //         storageEnrollmentId ? `&enrollment_id=${storageEnrollmentId}` : ''
                                //     }`;
                                // }
                                // history.push(url + `&isLearningGroup=${isLearningGroup}`);
                                handleSwitchView('study-history');
                            }}
                            className={classNames(
                                'gradebookDetailsTab-item',
                                viewGradebook === TAB.studyHistory ? 'gradebookDetailsTab-item--actived' : ''
                            )}
                        >
                            {t('COURSES.SESSION')}
                        </div>
                    </div>
                    <div className="gradebookDetailsCourse-container">
                        {viewGradebook === TAB.overview && subtab === 'learning-group' ? (
                            <GradebookOverview learningGroupId={learningGroupId} />
                        ) : null}
                        {viewGradebook === TAB.assignment ? <GradebookAssignment /> : null}
                        {viewGradebook === TAB.learningMaterials ? <LearningMaterials /> : null}
                        {viewGradebook === TAB.studyHistory ? <StudyHistory /> : null}
                    </div>
                </div>
            </div>
        </>
    );
};
