import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { checkPermission } from 'utils/commonFun';

export default function ModalAddNotes({data, onClose, refreshData = null, isSingleInvoice = false}) {
    const params: {id} = useParams();
    const [notes, setNotes] = useState('');
    const {t: translator} = useTranslation();

    useEffect(() => {
        if (data?.notes) setNotes(data?.notes);
    }, [data?.notes]);

    const handleAddNotes = () => {
      const payload = {
        notes: notes || null
      }
      if (isSingleInvoice) {
        workspaceService.addNoteToInvoice(params?.id, data?.id, payload)
        .then(() => {
          if (data?.notes) {
            Toastify.success(translator('EDIT_NOTES_SUCCESS'));
          }
          else {
            Toastify.success(translator('ADD_NOTES_SUCCESS'));
          }
          refreshData?.();
          onClose();
        })
        .catch(() => Toastify.error());
      }
      else {
        workspaceService.addNoteRecurringToInvoice(params?.id, data?.id, payload)
        .then(() => {
          if (data?.notes) {
            Toastify.success(translator('EDIT_NOTES_SUCCESS'));
          }
          else {
            Toastify.success(translator('ADD_NOTES_SUCCESS'));
          }
          refreshData?.();
          onClose();
        })
        .catch(() => Toastify.error());
      }
    }

    return (
        <div className='w-full mt-4'>
            {isSingleInvoice && <div>
                <span className='text-gray-500 text-sm font-semibold'>
                  {translator("INVOICE_ID")}
                </span>
                : <span className='text-gray-900 font-semibold text-sm'>{data?.id}</span>
              </div>
            }
            <div className="text-sm font-semibold mt-3">{translator("NOTES")}</div>
            <div className="relative">
                <textarea 
                  className="mt-1 h-32 border border-gray-300 !rounded-sm w-full p-2 text-sm focus:outline-none" 
                  placeholder={translator("ENTER_NOTES")}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  maxLength={1000}
                  disabled={!checkPermission('invoice','U')}
                />
                <div className="absolute bottom-2 right-2 text-xs text-gray-500  bg-white rounded-lg p-1">({notes?.length || 0}/1000)</div>
            </div>
            
            <div className='w-full mt-5 flex justify-center gap-5'>
              <ButtonOutline type="button" onClick={onClose} >{translator('CANCEL')}</ButtonOutline>
              <ButtonPrimary type="button" onClick={handleAddNotes} disabled={!checkPermission('invoice','U') || !notes} >{!data?.notes? translator('ADD'): translator('SAVE')}</ButtonPrimary>
            </div>
        </div>
    );
}
