import React, { useEffect, useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import './AssignmentGrade.scss';
import { LearnerInfo } from '../components/LearnerInfo/LearnerInfo';
import { Tasks } from '../components/Tasks/Tasks';
import courseService from 'services/course.service';
import { useHistory, useParams } from 'react-router-dom';
import { SideLeft } from '../components/SideLeft/SideLeft';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useForm } from 'react-hook-form';
import NoAssignment from 'assets/img/empty-state/emptyAssignmentGrade.png';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { classNames, isEmptyNullUndefine } from 'utils/commonFun';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { orderBy } from 'lodash';

export const TASKS_STATUS = {
    completed: 'Completed',
    waiting_for_grade: 'Waiting for Grade',
    in_progress: 'In Progress',
};
export const AssignmentGrade = () => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string; assignmentId: string } = useParams();
    const history = useHistory();

    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const assignmentId = params?.assignmentId;

    const [assignmentDetail, setAssignmentDetail] = useState<any>(null);
    const [statusGrades, setStatusGrades] = useState<{ in_progress: any[]; waiting_for_grade: any[]; completed: any[] }>({
        in_progress: [],
        waiting_for_grade: [],
        completed: [],
    });
    const [assignmentAttemptDetail, setAssignmentAttemptDetail] = useState<any>(null);
    const [tasksWaitingForGrade, setTasksWaitingForGrade] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [tabContent, setTabContent] = useState<'all_task' | 'waiting_for_grade'>('all_task');
    const [isGrade, setIsGrade] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
        getValues,
        register,
        watch,
        resetField,
        reset,
        setValue,
    } = useForm({
        defaultValues: {
            tasks: [],
        },
    });

    const { fields, replace } = useFieldArray({
        control,
        name: 'tasks',
        keyName: 'uid',
    });

    const fetchAssignmentDetail = (assId = assignmentId) => {
        courseService.getAssignmentDetail(workspaceId, courseId, assId).then((res) => {
            setAssignmentDetail(res?.data);
        });
    };

    const fetchGrades = () => {
        setLoading(true);
        courseService
            .getAssignmentsGrade(workspaceId, courseId, assignmentId)
            .then((response) => {
                const waitingForGrade = response?.data
                    ?.filter((item) => item?.status == 'waiting_for_grade')
                    ?.map((item) => item?.assignment_attempts)
                    ?.flat(Infinity);
                const inProgress = response?.data
                    ?.filter((item) => item?.status == 'in_progress')
                    ?.map((item) => item?.assignment_attempts)
                    ?.flat(Infinity);
                const completed = response?.data
                    ?.filter((item) => item?.status == 'completed')
                    ?.map((item) => item?.assignment_attempts)
                    ?.flat(Infinity);
                setStatusGrades({
                    in_progress: inProgress || [],
                    waiting_for_grade: waitingForGrade || [],
                    completed: completed || [],
                });
                // default select a lasted attempt (first load)
                // if has attempt selected then chose attempt selected that
                let attemptSelect = assignmentAttemptDetail;
                if (!attemptSelect) {
                    const attemptsList = orderBy([...completed, ...waitingForGrade, ...inProgress], 'completion_date', 'desc');
                    attemptSelect = attemptsList?.[0] || null;
                }
                if (attemptSelect) {
                    if (attemptSelect?.assignment_id != assignmentDetail?.id) {
                        fetchAssignmentDetail(attemptSelect?.assignment_id);
                    }
                    fetchAssignmentAttemptDetail(attemptSelect?.assignment_id, attemptSelect?.id);
                }
            })
            .finally(() => setLoading(false));
    };

    const fetchAssignmentAttemptDetail = (assId = assignmentId, id) => {
        courseService.getAssignmentsAttempt(workspaceId, courseId, assId, id).then((res) => {
            setAssignmentAttemptDetail(res?.data);
            setTasksWaitingForGrade(res?.data?.tasks?.filter((item) => item?.task_result?.is_finished && !item?.task_result?.is_graded));
        });
    };

    const onSubmitForm = (values) => {
        const bodyParams = values?.tasks?.map((el) => ({
            assignment_task_result_id: el?.task_result?.id,
            feedback: el?.task_result?.instructor_feedback || null,
            score: isEmptyNullUndefine(el?.task_result?.grade) ? null : Number(el?.task_result?.grade),
        }));
        setLoadingSubmit(true);
        courseService
            .addFeedbackScore(workspaceId, courseId, assignmentAttemptDetail?.assignment_id, assignmentAttemptDetail?.id, bodyParams)
            .then(() => {
                Toastify.success('Changed successfully!');
                fetchGrades();
                setIsGrade(false);
            })
            .catch(() => Toastify.error())
            .finally(() => setLoadingSubmit(false));
    };

    useEffect(() => {
        if (assignmentId) {
            fetchGrades();
        }
    }, [assignmentId]);

    useEffect(() => {
        let tasksList = tabContent === 'all_task' ? assignmentAttemptDetail?.tasks || [] : tasksWaitingForGrade;
        tasksList = tasksList?.map((el) => {
            const is_finished_task = el?.task_result?.is_finished;
            const is_submit_attempt = !!assignmentAttemptDetail?.completion_date;
            if (!is_finished_task) {
                return {
                    ...el,
                    task_result: {
                        ...el?.task_result,
                        grade: is_submit_attempt ? 0 : null,
                    }
                }
            }
            return el;
        })
        reset({ tasks: tasksList });
    }, [tabContent, assignmentAttemptDetail, tasksWaitingForGrade]);

    useEffect(() => {
        const allTasksElement = document.querySelector('.assignmentGrade-tasks');
        const waitTasksElement = document.querySelector('.assignmentGrade-waiting_for_grade');
        if (tabContent === 'all_task' && allTasksElement) {
            allTasksElement.scrollTo({ top: 0, behavior: "smooth" });
        } else if (tabContent === 'waiting_for_grade' && waitTasksElement) {
            waitTasksElement.scrollTo({ top: 0, behavior: "smooth" });
        }
    }, [assignmentAttemptDetail]);

    return (
        <>
            <div className="assignmentGradeHeader assignmentGrade-header">
                <button className="assignmentGradeHeader-back !hidden" onClick={() => history.goBack()}>
                    <span>
                        <ChevronLeftIcon className="w-5 h-5 " />
                    </span>{' '}
                    {translator('BACK')}
                </button>
                <h1 className="assignmentGradeHeader-name w-full flex justify-center items-center">
                    {assignmentAttemptDetail?.assignment?.learner?.display_name + ' / ' + assignmentAttemptDetail?.assignment?.name}
                </h1>
            </div>
            <div className="assignmentGrade-container">
                {loading ? (
                    <div className="flex items-center justify-center w-full h-[calc(100vh-48px)]">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <>
                        <div className="assignmentGrade-sideLeft">
                            <SideLeft
                                statusGrades={statusGrades}
                                assignmentDetail={assignmentDetail}
                                attemptSelected={assignmentAttemptDetail}
                                onSelectAttempt={(assId, id) => {
                                    fetchAssignmentAttemptDetail(assId, id);
                                    setIsGrade(false);
                                }}
                            />
                        </div>
                        <form onSubmit={handleSubmit(onSubmitForm)} className="assignmentGrade-content">
                            <div className="assignmentGrade-learner border-b border-gray-300">
                                <LearnerInfo attemptDetail={assignmentAttemptDetail} />
                            </div>
                            {!isGrade && (
                                <div className="assignmentGrade-tab_content border-b border-gray-300">
                                    <button
                                        type="button"
                                        className={tabContent === 'all_task' ? 'active' : ''}
                                        onClick={() => setTabContent('all_task')}
                                    >
                                        <span>All Tasks</span>
                                        <span className="min-w-[20px] bg-gray-100 rounded-3xl px-1.5 text-gray-800">
                                            {assignmentAttemptDetail?.tasks?.length || 0}
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        className={tabContent === 'waiting_for_grade' ? 'active' : ''}
                                        onClick={() => setTabContent('waiting_for_grade')}
                                    >
                                        <span>Waiting for Grade</span>
                                        <span
                                            className={classNames(
                                                'min-w-[20px] rounded-3xl px-1.5 ',
                                                tasksWaitingForGrade?.length > 0
                                                    ? 'bg-orange-500 text-white'
                                                    : 'text-gray-800 bg-gray-100'
                                            )}
                                        >
                                            {tasksWaitingForGrade?.length || 0}
                                        </span>
                                    </button>
                                </div>
                            )}
                            <div className="bg-white flex items-center justify-between px-5 py-4 shadow-light_1 z-1">
                                <p className="text-gray-800 text-lg font-semibold">
                                    {tabContent === 'all_task' ? 'All Tasks' : 'Waiting for Grade'}
                                </p>
                                {assignmentAttemptDetail !== 'in_progress' && (
                                    <div className="flex justify-center space-x-2">
                                        {!isGrade ? (
                                            <>
                                                <ButtonPrimary
                                                    type="button"
                                                    disabled={
                                                        tabContent === 'all_task'
                                                            ? !(assignmentAttemptDetail?.tasks || [])?.length
                                                            : !tasksWaitingForGrade?.length
                                                    }
                                                    onClick={() => setIsGrade(true)}
                                                    data-for="GRADING_TOOLTIP"
                                                    data-tip={translator('No tasks available for grading')}
                                                >
                                                    Grade
                                                </ButtonPrimary>
                                                {(tabContent === 'all_task'
                                                    ? !(assignmentAttemptDetail?.tasks || [])?.length
                                                    : !tasksWaitingForGrade?.length) && (
                                                        <TooltipCustom
                                                            classNameContainer="shadow-medium"
                                                            id="GRADING_TOOLTIP"
                                                            content={translator('No tasks available for grading')}
                                                            place="left"
                                                            type="light"
                                                        />
                                                    )}
                                            </>
                                        ) : (
                                            <>
                                                <ButtonOutline type="button" disabled={loadingSubmit} onClick={() => setIsGrade(false)} size="M">
                                                    Cancel
                                                </ButtonOutline>
                                                <ButtonPrimary type="submit" disabled={loadingSubmit || !isDirty} size="M">
                                                    {loadingSubmit ? <CircleSpin color="text-primary-500" /> : null}
                                                    Save
                                                </ButtonPrimary>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                            {tabContent === 'all_task' && (
                                <div className={`assignmentGrade-tasks ${isGrade ? 'assignmentGrade-tasks--isGrade' : ''}`}>
                                    {!!assignmentAttemptDetail?.tasks?.length ? (
                                        <Tasks
                                            attemptDetail={assignmentAttemptDetail}
                                            tasks={fields}
                                            isGrade={isGrade}
                                            register={register}
                                            getValues={watch}
                                            setValue={setValue}
                                            readOnly={true}
                                        />
                                    ) : (
                                        <NoContentWithImage content={'No Data'} img={NoAssignment} />
                                    )}
                                </div>
                            )}
                            {tabContent === 'waiting_for_grade' && (
                                <div
                                    className={`assignmentGrade-waiting_for_grade ${isGrade ? 'assignmentGrade-waiting_for_grade--isGrade' : ''
                                        }`}
                                >
                                    {!!tasksWaitingForGrade?.length ? (
                                        <Tasks
                                            attemptDetail={assignmentAttemptDetail}
                                            tasks={fields}
                                            isGrade={isGrade}
                                            register={register}
                                            getValues={watch}
                                            setValue={setValue}
                                        />
                                    ) : (
                                        <NoContentWithImage content={'No Data'} img={NoAssignment} />
                                    )}
                                </div>
                            )}
                        </form>
                    </>
                )}
            </div>
        </>
    );
};
