import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ProgressOverview.scss";
import learnerReportService from "services/reports/learner-report.service";
import { checkPermission } from "utils/commonFun";
import { useTranslation } from "react-i18next";
import { CircleInformationIcon } from "assets/icon";
import { TooltipCustom } from "components/Tooltip/Tooltip";
import { ValueFilterSkillType } from "components/Skills/SkillFilter";

export const ProgressOverview = ({ filterSkill }: { filterSkill: ValueFilterSkillType }) => {
    const params: { id: string, userId: string } = useParams();
    const [overview, setOverview] = useState<any>();
    const { t } = useTranslation();

    useEffect(() => {
        const payload = {
            studentId: params?.userId,
            ...filterSkill
        }
        const getOverview = async () => {
            const response = await learnerReportService.getLearnerOverviews(params?.id, payload);
            setOverview(response?.data);
        }
        if (params?.userId && checkPermission('learnerDashboard', 'R')) {
            getOverview();
        }
    }, [params?.userId, filterSkill]);

    return <div className="courseOverview">
        <h2 className="courseOverview-title"></h2>
        <div className="courseOverview-list">
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.OVERALL_GRADE")}</div>
                <div className="courseOverview-value">{overview?.avg_overall == null ? '-' : `${overview?.avg_overall}%`}</div>
            </div>
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.COURSES_COMPLETETION")}</div>
                <div className="courseOverview-value">{overview?.avg_course_completion == null ? '-' : `${overview?.avg_course_completion}%`}</div>
            </div>
            <div className="courseOverview-item">
                <div className='flex items-center gap-1 max-w-sm'>
                    <div className="courseOverview-label">{t('COURSES.ASSIGNMENT_GRADE')} </div>
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                        data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center"
                            id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                            content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                            place="top"
                            type="light"
                        />
                    </div>
                </div>
                <div className="courseOverview-value">{overview?.avg_assignment_score == null ? '-' : `${overview?.avg_assignment_score}%`}</div>
            </div>
            <div className="courseOverview-item">
                <div className="courseOverview-label">{t("COURSES.LESSON_GOAL_COMPLETION")}</div>
                <div className="courseOverview-value">{overview?.avg_lesson_goal == null ? '-' : `${overview?.avg_lesson_goal}%`}</div>
            </div>
        </div>
    </div>
}