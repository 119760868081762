import React, { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { AsyncSelectField } from 'components/Select';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import ReactTooltip from 'react-tooltip';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { formatAvatarLearnerNameEmail } from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/options';

type ModalAddLearnerProps = {
    sessions: any[];
    type: 'individual' | 'learning_group';
    learning_group?: {
        label: string;
        value: number;
    } | null;
    onClose: () => void;
    onSubmit: () => void;
};

export const ModalAddLearner: FC<ModalAddLearnerProps> = ({ sessions, type = 'individual', learning_group = null, onClose, onSubmit }) => {
    const params: { id: string; courseId: string } = useParams();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty },
        setValue,
        getValues,
        reset
    } = useForm({
        mode: 'onChange',
    });

    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const translator = (str: string, opt?: any) => t(`SESSION.${str}`, opt);

    const fetchLearnersSessions = async (payload?: any) => {
        try {
            const res = await courseService.getLearnerFromSessions(workspaceId, courseId, payload);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            console.error('Error:', error);
            return {
                items: [],
                total: 0,
            };
        }
    };
    const fetchInstructorsSessions = async (payload?: any) => {
        try {
            const res = await courseService.getMoreInstructorFromSessions(workspaceId, courseId, payload);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            console.error('Error:', error);
            return {
                items: [],
                total: 0,
            };
        }
    };

    const fetchLearningGroupOfCourse = async (bodyParams?: any) => {
        try {
            const res = await courseService.getPeopleOfCourse(workspaceId, courseId, bodyParams);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            console.error('Error:', error);
            return {
                items: [],
                total: 0,
            };
        }
    };

    const onAddAttendees = (bodyData: any) => {
        setLoading(true);
        courseService
            .addMoreAttendeesToSessions(workspaceId, courseId, bodyData)
            .then(() => {
                Toastify.success();
                onSubmit?.();
            })
            .catch(() => Toastify.error())
            .finally(() => setLoading(false));
    };

    const handleLearnerLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue,
            exclude_session_ids: (sessions?.map((el) => el?.id) || [])?.join(','),
        };
        if (type === 'learning_group' && !!learning_group) {
            payload.learning_group_id = learning_group?.value || null;
        }
        if (!payload.q) delete payload.q;

        try {
            const { items, total } = await fetchLearnersSessions(payload);
            const newOptions = items?.map((el: any) => ({
                ...el,
                label: el.display_name,
                value: el.id,
            }));
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };


    const handleInstructorLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue,
            exclude_session_ids: (sessions?.map((el) => el?.id) || [])?.join(','),
        };
        if (!payload.q) delete payload.q;

        try {
            const { items, total } = await fetchInstructorsSessions(payload);
            const newOptions = items?.map((el: any) => ({
                ...el,
                label: el.display_name,
                value: el.id,
            }));
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLoadGroupOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            order: 'desc',
            q: inputValue,
            enrollment_as: 'learning_group',
            statuses: 'active,enrolled',
        };
        if (payload?.q) delete payload.q;
        try {
            const { items, total } = await fetchLearningGroupOfCourse(payload);
            const newOptions = items?.map((el: any) => ({
                ...el,
                label: el.name,
                value: el.id,
            }));
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        ReactTooltip.rebuild();
        reset({
            learning_group: type === 'learning_group' ? learning_group : null,
            learners: [],
            instructors: [],
        })
    }, []);

    const onSubmitForm = (values) => {
        const payload: any = {
            session_ids: sessions?.map((item) => item?.id) || null,
            membership_ids: values?.learners?.map((item) => item?.membership_id) || null,
            instructor_membership_ids: values?.instructors?.map((item) => item?.membership_id) || null,
        };
        if (type === 'learning_group') payload.learning_group_id = values?.learning_group?.value || null;
        onAddAttendees(payload);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitForm)} className="mt-2 text-gray-800">
            <div className="flex flex-col space-y-5">
                <div>
                    <h1 className="mb-1 text-xs font-semibold">{translator('YOU_ARE_SELECTING_THE_FOLLOWING_SESSION_S')}</h1>
                    <div className="bg-gray-50 py-3 px-4 rounded-lg overflow-auto max-h-[240px] text-xs">
                        <div className="grid grid-cols-7 font-semibold mb-1">
                            <div className="col-span-3">{translator('DATE')}</div>
                            <div className="col-span-2">{translator('LEARNER_S')}</div>
                            <div className="col-span-2">{translator('INSTRUCTOR_S')}</div>
                        </div>
                        {sessions?.map((item, index) => {
                            const instructors = item?.instructors || [];
                            const learners = item?.learners || [];
                            return (
                                <div key={index} className="grid grid-cols-7 py-0.5">
                                    <div className="col-span-3 flex items-center">
                                        {dayjs.utc(item?.start_time).tz(result?.time_zone).format('DD/MM/YYYY')}
                                        {` (${dayjs.utc(item?.start_time).tz(result?.time_zone).format('HH:mm')} - ${dayjs
                                            .utc(item?.end_time)
                                            .tz(result?.time_zone)
                                            .format('HH:mm')})`}
                                    </div>
                                    <div className="col-span-2 flex gap-1">
                                        {learners?.slice(0, 2)?.map((el, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        data-tip={el?.name || el?.display_name}
                                                        data-for={el?.id + '_show-more-learners-modal_' + index}
                                                        className={`w-7 h-7 rounded-full overflow-hidden bg-cover ${learners?.length > 2 && index > 0
                                                                ? '-ml-3.5 border-2 border-white'
                                                                : 'border-2 border-white'
                                                            }`}
                                                        style={{ backgroundImage: `url('${el?.avatar_url}')` }}
                                                    ></div>
                                                    {
                                                        //@ts-ignore
                                                        <ReactTooltip
                                                            place="top"
                                                            type="dark"
                                                            effect="float"
                                                            id={el?.id + '_show-more-learners-modal_' + index}
                                                            getContent={(content) => <div>{content}</div>}
                                                        />
                                                    }
                                                </div>
                                            );
                                        })}
                                        {learners?.length > 2 && (
                                            <div
                                                className="w-7 h-7 -ml-3.5 border-2 bg-gray-300 border-white text-gray-900 text-[10px] flex items-center justify-center rounded-full hover:text-primary-500 hover:border-primary-500"
                                                data-tip={learners?.slice(2)?.map((item) => item?.display_name)}
                                                data-for={item?.id + '_show-more-learners-modal'}
                                            >
                                                +{learners?.length - 2}
                                            </div>
                                        )}
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className="max-w-xs"
                                                id={item?.id + '_show-more-learners-modal'}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </div>
                                    <div className="col-span-2 flex gap-1">
                                        {instructors?.slice(0, 2)?.map((el, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        data-tip={el?.name || el?.display_name}
                                                        data-for={item?.id + '_show-more-instructors-modal_' + index}
                                                        className={`w-7 h-7 rounded-full overflow-hidden bg-cover ${instructors?.length > 2 && index > 0
                                                                ? '-ml-3.5 border-2 border-white'
                                                                : 'border-2 border-white'
                                                            }`}
                                                        style={{ backgroundImage: `url('${el?.avatar_url}')` }}
                                                    ></div>
                                                    {
                                                        //@ts-ignore
                                                        <ReactTooltip
                                                            place="top"
                                                            type="dark"
                                                            effect="float"
                                                            id={item?.id + '_show-more-instructors-modal_' + index}
                                                            getContent={(content) => <div>{content}</div>}
                                                        />
                                                    }
                                                </div>
                                            );
                                        })}
                                        {instructors?.length > 2 && (
                                            <div
                                                className="w-7 h-7 border-2 bg-gray-300 border-white text-gray-900 text-[10px] -ml-3.5 p-0.5 flex items-center justify-center rounded-full hover:text-primary-500 hover:border-primary-500"
                                                data-tip={instructors?.slice(2)?.map((item) => item?.display_name)}
                                                data-for={item?.id + '_show-more-instructors-modal'}
                                            >
                                                +{instructors?.length - 2}
                                            </div>
                                        )}
                                        {
                                            //@ts-ignore
                                            <ReactTooltip
                                                place="top"
                                                type="dark"
                                                effect="float"
                                                className="max-w-xs"
                                                id={item?.id + '_show-more-instructors-modal'}
                                                getContent={(content) => <div>{content}</div>}
                                            />
                                        }
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {type === 'learning_group' && (
                    <AsyncSelectField
                        key={'learning-group-field'}
                        control={control}
                        label={translator('LEARNING_GROUP', true)}
                        name="learning_group"
                        placeholder={translator('LEARNING_GROUP.SELECT_A_LEARNING_GROUP', true)}
                        isRequired
                        hideSelectedOptions
                        closeMenuOnSelect={true}
                        errors={errors}
                        isDisabled
                        loadOptions={handleLoadGroupOptions}
                    />
                )}
                <AsyncSelectField
                    key='learner-field'
                    control={control}
                    label={translator('LEARNER_S')}
                    name="learners"
                    placeholder={translator('SELECT') + ' ' + translator('LEARNER_S')}
                    hideSelectedOptions
                    closeMenuOnSelect={true}
                    errors={errors}
                    isMulti
                    loadOptions={handleLearnerLoadOptions}
                    formatOptionLabelCustom={formatAvatarLearnerNameEmail}
                />
                <AsyncSelectField
                    key='instructor-field'
                    control={control}
                    label={translator('INSTRUCTOR_S')}
                    name="instructors"
                    placeholder={translator('SELECT') + ' ' + translator('INSTRUCTOR_S')}
                    hideSelectedOptions
                    closeMenuOnSelect={true}
                    errors={errors}
                    isMulti
                    loadOptions={handleInstructorLoadOptions}
                    formatOptionLabelCustom={formatAvatarLearnerNameEmail}
                />
            </div>
            <div className="mt-6 flex justify-center space-x-2">
                <ButtonOutline type="button" onClick={onClose}>
                    {t('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary type="submit" disabled={loading || !isDirty || !isValid}>
                    {t('ADD')}
                </ButtonPrimary>
            </div>
        </form>
    );
};
