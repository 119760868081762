import httpClient, { RestfulService } from './restful.service';
// CONSTANTS
import { AUTH, HOST_URL } from 'constant/api.const';
// TYPES
import { AuthLoginBodyType } from 'types/Auth.type';
import { ForgotPasswordArgsType } from 'types/ForgotPassword.type';
import { ResetPasswordArgsType, VerifyTokenResetPasswordArgsType } from 'types/ResetPassword.type';
import { getLocalCookie } from 'utils/handleAuthorized';

const login = async (body: AuthLoginBodyType) => {
    if (body) {
        const res = await RestfulService.postApi(HOST_URL + AUTH.LOGIN, body);

        if (!!res.data.error) {
            throw res.data;
        }
        return res.data;
    }
};

const sso = async (token, usercode) => {
    const res = await RestfulService.postApi(HOST_URL + '/auth/sso/login', { token, usercode });

    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const authCallBackGg = async (params: any) => {
    if (params) {
        const res = await RestfulService.getApi(HOST_URL + AUTH.OAUTHGG, params);

        if (!!res.data.error) {
            throw res.data;
        }
        return res.data;
    }
};

const authCallBackfb = async (params: any) => {
    if (params) {
        const res = await RestfulService.getApi(HOST_URL + AUTH.OAUTHFB, params);

        if (!!res.data.error) {
            throw res.data;
        }
        return res.data;
    }
};

const logout = async () => {
    const hasCookie = !!getLocalCookie();
    if (!hasCookie) {
        return false;
    }
    const res = await RestfulService.deleteApi(AUTH.LOGOUT);
    if (res.status === 204) {
        return true;
    }
};

const forgotPassword = async (body: ForgotPasswordArgsType) => {
    if (body) {
        const { email } = body;
        const res = await RestfulService.postApi(HOST_URL + AUTH.FORGOT_PASSWORD, { email });
        return res;
    }
};

const resetPassword = async (body: ResetPasswordArgsType) => {
    if (body) {
        const { code, password, email } = body;
        const res = await RestfulService.postApi(HOST_URL + AUTH.RESET_PASSWORD, { email, code, password });
        return res;
    }
};

const verifyTokenResetPassordService = async (body: VerifyTokenResetPasswordArgsType) => {
    if (body) {
        const { email, code } = body;
        const res = await httpClient.post(HOST_URL + AUTH.VERIFY_TOKEN_RESET_PASSWORD, { email, code });
        return res;
    }
};

const requestOTP = async (body: any) => {
    if (body) {
        const { email } = body;
        const res = await RestfulService.postApi(HOST_URL + AUTH.REQUEST_OTP, { email });
        return res;
    }
};
const loginOTP = async (body: any) => {
    if (body) {
        const { email, otp_code, response_type } = body;
        const res = await RestfulService.postApi(HOST_URL + AUTH.LOGIN_OTP, { email, otp_code, response_type });
        return res;
    }
};

const loginGoogle = () => {
    return RestfulService.getApi('/oauth2/google/login');
};

const callbackGoogle = (params) => {
    return RestfulService.postApi('/oauth2/google/callback', params);
};

export default {
    login,
    forgotPassword,
    resetPassword,
    verifyTokenResetPassordService,
    logout,
    authCallBackGg,
    authCallBackfb,
    sso,
    requestOTP,
    loginOTP,
    loginGoogle,
    callbackGoogle,
};
