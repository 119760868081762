import React, { useEffect } from 'react';
import { H5PTask } from './H5PTask';
import { NormalTask } from './NormalTask';
import { SubmissionTask } from './SubmissionTask';
import './Tasks.scss';
import { LIBRARY_ICONS, LibraryTypeEnum } from 'components/Library/LibraryContants';
import { useTranslation } from 'react-i18next';
import { TextareaField } from 'components/InputForm/InputForm';
import { CheckCircleIcon } from '@heroicons/react/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { classNames, isEmptyNullUndefine } from 'utils/commonFun';
import { RecordingTask } from './RecordingTask';

export const Tasks = ({ attemptDetail, tasks, isGrade, register, getValues, setValue, readOnly = false }) => {
    const { t: translator } = useTranslation();

    const renderUI = (task) => {
        const is_graded_attempt = attemptDetail?.status === 'completed';
        const is_graded_task = task?.task_result?.is_graded;
        const is_finished_task = task?.task_result?.is_finished;
        const is_submit_attempt = !!attemptDetail?.completion_date;
        const grade_task = getValues(`tasks.${task?.index}.task_result.grade`);

        if (grade_task != null) {
            return (
                <span className="tasks-grade">
                    Graded: <span>{grade_task + '%'}</span>
                </span>
            );
        }
        if (!is_finished_task) {
            if (is_submit_attempt) {
                return (
                    <span className="tasks-grade">
                        Graded: <span>0%</span>
                    </span>
                );
            }
            return (
                <span className="tasks-grade">
                    Graded: <span>-</span>
                </span>
            );
        }
        return <span className={`tasks-status waiting`}>Waiting for Grade</span>;
    };

    useEffect(() => {
        if (window?.H5PIntegration?.contents) {
            window.H5PIntegration.contents = null;
        }
    }, [attemptDetail?.id]);

    return (
        <>
            {tasks?.map((task, index) => (
                <div key={task?.id} className="tasks">
                    <div className="tasks-header-name justify-between py-3 px-4 border-b border-gray-300">
                        <div className="flex items-center space-x-2">
                            <span className="tasks-icon">
                                <img
                                    alt="libary-icon"
                                    className="assigmentBuilderPreview-icon"
                                    src={
                                        task?.object_type
                                            ? LIBRARY_ICONS(translator)?.find((item) => item?.value === task?.object_type)?.icon
                                            : LIBRARY_ICONS(translator)[0].icon
                                    }
                                />
                            </span>
                            <h3 className="tasks-name">{index + 1 + '. ' + task.name}</h3>
                        </div>
                        <div className="flex items-center">{renderUI({ ...task, index })}</div>
                    </div>
                    <div className="p-4">
                        {task?.object_type === LibraryTypeEnum.h5p ? (
                            <H5PTask
                                task={{ ...task, assignmentId: attemptDetail?.assignment_id }}
                                attemptId={attemptDetail?.id}
                                key={attemptDetail?.id}
                                readOnly={readOnly}
                                countTasks={tasks?.filter(item => item?.object_type === LibraryTypeEnum.h5p)?.length}
                            />
                        ) : task?.object_type === LibraryTypeEnum.submission ? (
                            <SubmissionTask task={task} />
                        ) : task?.object_type === LibraryTypeEnum.recordingTask? (
                            <RecordingTask task={task} />
                        ) : (
                            <NormalTask task={task} />
                        )}
                    </div>
                    {isGrade ? (
                        <div className="flex space-x-2">
                            <div
                                className={classNames(
                                    'flex items-center space-x-2 rounded-tr-lg px-8',
                                    isEmptyNullUndefine(getValues(`tasks.${index}.task_result.grade`)) ? 'bg-yellow-50' : 'bg-green-50'
                                )}
                            >
                                <label htmlFor={`tasks.${index}.task_result.grade`} className="font-semibold text-base">
                                    Grade
                                </label>
                                <div className="w-20 rounded border border-gray-300 bg-white relative overflow-hidden focus-within:border-[#2684FF]">
                                    <input
                                        id={`tasks-${index}-task_result-grade`}
                                        type="number"
                                        max={100}
                                        min={0}
                                        step={1}
                                        pattern="\d{1,3}"
                                        name={`tasks.${index}.task_result.grade`}
                                        className="px-2.5 py-1 w-[59px] border-r border-gray-300"
                                        {...register(`tasks.${index}.task_result.grade`)}
                                        onInvalid={(e: any) => {
                                            if (e?.target?.validity?.rangeUnderflow || e?.target?.validity?.rangeOverflow) {
                                                e.target.setCustomValidity('Value must be between 0 and 100');
                                            } else {
                                                e.target.setCustomValidity('');
                                            }
                                        }}
                                        onInput={(e: any) => e?.target?.setCustomValidity('')}
                                    />
                                    <button
                                        title={''}
                                        type="button"
                                        className="absolute top-0 right-0 w-5 flex items-center justify-center border-b border-gray-300"
                                        onClick={() => {
                                            let input: any = document.getElementById(`tasks-${index}-task_result-grade`);
                                            input?.stepUp();
                                            setValue(`tasks.${index}.task_result.grade`, input?.value, { shouldDirty: true });
                                        }}
                                    >
                                        <ChevronUpIcon className="h-4 w-4 text-gray-500 hover:text-enterprise" />
                                    </button>

                                    <button
                                        title={''}
                                        type="button"
                                        className="absolute bottom-0 right-0 w-5 flex items-center justify-center"
                                        onClick={() => {
                                            let input: any = document.getElementById(`tasks-${index}-task_result-grade`);
                                            input?.stepDown();
                                            setValue(`tasks.${index}.task_result.grade`, input?.value, { shouldDirty: true });
                                        }}
                                    >
                                        <ChevronDownIcon className="h-4 w-4 text-gray-500 hover:text-enterprise" />
                                    </button>
                                </div>
                                <span>/100%</span>
                                <CheckCircleIcon
                                    className={classNames(
                                        'h-6 w-6 ',
                                        isEmptyNullUndefine(getValues(`tasks.${index}.task_result.grade`))
                                            ? 'text-white'
                                            : 'text-green-500'
                                    )}
                                />
                            </div>
                            <div className="p-4 flex-1">
                                <TextareaField
                                    label={'Feedback'}
                                    name={`tasks.${index}.task_result.instructor_feedback`}
                                    className="w-full"
                                    placeholder={`Enter Feedback here`}
                                    register={register(`tasks.${index}.task_result.instructor_feedback`)}
                                    textLength={getValues(`tasks.${index}.task_result.instructor_feedback`)?.toString()?.length}
                                    maxLength={1000}
                                    isCountter
                                    rows={2}
                                    cols={4}
                                />
                            </div>
                        </div>
                    ) : (
                        task?.task_result?.instructor_feedback && (
                            <div className="p-4 pt-0">
                                <div className="bg-purple-50 rounded-lg p-4 text-base">
                                    <p className="font-semibold mb-1">Feedback</p>
                                    <div
                                        className="submission-description"
                                        dangerouslySetInnerHTML={{
                                            __html: task?.task_result?.instructor_feedback?.replaceAll(/\n/g, '<br>'),
                                        }}
                                    ></div>
                                </div>
                            </div>
                        )
                    )}
                </div>
            ))}
        </>
    );
};
