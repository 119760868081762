import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonOutline, Toastify } from '_shared';
import './Grades.scss';
import courseService from 'services/course.service';
import Pagination from 'components/V2/Pagination';
import { getIndexPage } from 'utils/commonFun';
import Table from './Components/Table/Table';
import Filter from './Components/Filters/Filter';
import { debounce } from 'lodash';
import { EditLine } from 'assets/icon';
import DialogComponent from 'components/Dialog/DialogComponent';
import { EditDueDate } from './Components/Table/EditDueDate';
import { ExportGradeCSV } from './Components/GradeDownloadCSV';
import ReactTooltip from 'react-tooltip';
import { useAppSelector } from 'hooks/hooks';
import { XIcon } from '@heroicons/react/outline';

const Grades: React.FC = ({ children }) => {
    const { data: courseDetails} = useAppSelector((state) => state.course);
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'status',
        total: 0,
    });

    const [loadingEdit, setLoadingEdit] = useState(false);
    const [isOpenModalEdit, setOpenModalEdit] = useState(false);

    const workspaceId = params.id;
    const courseId = params.courseId;
    const [assignmentSelected, setAssignmentSelected] = useState([]);
    const [filter, setFilter] = useState(undefined);
    const [downloading, setDownloading] = useState(false);

    const getData = (page: number | string = 1, order = 'desc', filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            order,
            page,
        };
        if (!payload?.q) delete payload?.q;
        delete payload?.total;
        courseService
            .getCourseGrades(workspaceId, courseId, payload)
            .then((res: any) => {
                setData(res?.data?.items);
                setPagination({ ...pagination, ...payload, total: res?.data?.total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, res?.data?.items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getData(page, pagination?.order, filter);
    };

    const getAssignmentDebounce = useCallback(
        debounce((page, order?: string, filter?: any) => getData(page, order, filter), 500),
        []
    );

    useEffect(() => {
        getAssignmentDebounce(1, pagination?.order, filter);
    }, [filter]);


    const onChangeFilter = (filter) => {
        setFilter(filter);
    }

    const handleUpdateDueDate = async (value) => {
        if (value) {
            try {
                setLoadingEdit(true);
                let arrAssignment = [];
                assignmentSelected?.map((item) => arrAssignment.push(item?.id))
                const payload = {
                    assignments: arrAssignment,
                    end_time: value,
                }
                const response = await courseService.updateAssignmentBulk(params?.id, params?.courseId, payload
                );
                if (response?.data?.error) {
                    setLoadingEdit(false);
                    return Toastify.error();
                }
                setOpenModalEdit(false);
                setLoadingEdit(false);
                getData();
                Toastify.success('Update Due Date Successful!');
                setAssignmentSelected([])
            } catch {
                Toastify.error();
            }
        } else {
            setOpenModalEdit(false);
        }
    }
    const downloadCSV = async () => {
        try {
            setDownloading(true);
            const res = await courseService.getCourseGrades(workspaceId, courseId, { skip_pagination: true });
            ExportGradeCSV(res?.data?.items, `${courseId} - ${courseDetails?.title} - Grades`, translator);
        } catch (error) {

        } finally {
            setDownloading(false);
        }
    }
    return (
        <div className='pt-4 px-6 overflow-auto'>
            <div>
                <h2 className='text-lg font-semibold text-gray-800'>{translator('COURSES.GRADES')}</h2>
                <p className='mt-1 text-xs text-gray-500'>This page displays the latest attempt result by default</p>
            </div>
            <div className="gradeAssignment">
                <div className="gradeAssignment-filter">
                    <div className='flex gap-2 w-full'>
                        <Filter onChangeFilter={onChangeFilter} />
                    </div>
                    <ButtonOutline
                        type="button"
                        onClick={downloadCSV}
                        className={`p-2 buttonOutline--isPrimary whitespace-nowrap w-fill max-w-[140px] justify-center`}
                        disabled={downloading}
                    >   <div
                            data-for={`downloadCSV`}
                            data-tip={translator("COURSES.DOWNLOAD_TOOLTIP")}
                        >
                            {downloading ? <CircleSpin color='text-primary-500' /> : translator('COURSES.DOWNLOAD_ALL')}
                            {/*@ts-ignore*/
                            <ReactTooltip
                                place="top"
                                type="dark"
                                effect="float"
                                className="max-w-xs"
                                id={`downloadCSV`}
                                getContent={(content) => <div className='text-xs font-normal whitespace-normal'>{content}</div>}
                            />}
                        </div>
                    </ButtonOutline>
                </div>
                <div className={`${!!assignmentSelected?.length ? 'mt-3 pb-4' : 'mt-4'} flex items-center space-x-3`}>
                    {!!assignmentSelected?.length && (
                        <>
                            <span className='cursor-pointer' onClick={() => setAssignmentSelected([])}><XIcon className='w-6 h-6 text-gray-500 hover:text-red-500' /></span>
                            <p className='text-sm pr-2'>{assignmentSelected?.length} assignment(s) selected</p>
                            <ButtonOutline
                                disabled={!assignmentSelected?.length}
                                type="button"
                                onClick={() => { setOpenModalEdit(true) }}
                                className={` py-2 px-4 w-fill max-w-[140px]  ${!assignmentSelected?.length ? 'buttonOutline--isDisable' : 'buttonOutline--isPrimary'}`}
                            >
                                <EditLine />
                                {translator('EDIT')}
                            </ButtonOutline>
                        </>
                    )}
                </div>

                <div className="gradeAssignment-main">
                    <div className="wrapper-content">
                        <div className={`scrollbar ${!!assignmentSelected?.length ? 'h-[calc(100vh-454px)]' : 'h-[calc(100vh-410px)]'}`}>
                            {loading && (
                                <div className="loading">
                                    <CircleSpin color="text-primary-500" />
                                </div>
                            )}
                            {!loading &&
                                <Table
                                    data={data}
                                    refreshData={getData}
                                    assignmentSelected={assignmentSelected}
                                    setAssignmentSelected={setAssignmentSelected}
                                    pagination={pagination}
                                />
                            }
                        </div>
                        <Pagination
                            total={pagination.total}
                            per_page={pagination.per_page}
                            callback={(e) => handlePagination(e)}
                            indexPage={indexPage}
                            page={pagination?.page}
                        />
                    </div>
                </div>
            </div>
            <DialogComponent
                title={translator('COURSES.EDIT_DUE_DATE')}
                isOpen={isOpenModalEdit}
                onCloseModal={() => setOpenModalEdit(false)}
                isShowClose={true}
                styles="max-w-[400px] !py-1.5 !px-6"
                child={<EditDueDate data={data} onClose={handleUpdateDueDate} loading={loadingEdit} />}
            />
        </div>
    );
};

export default Grades;
