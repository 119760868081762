import { useTranslation } from 'react-i18next';
import { HeaderSkill } from './HeaderSkill';
import { Information } from './Information';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useHistory, useParams } from 'react-router-dom';
import { Levels } from './Levels';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import skillService from 'services/skill.service';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { Toastify } from '_shared';
import { useBoolean } from 'hooks/custom';
import { SkillAssociatedCourse } from 'components/Management/SkillManagement/SkillDetail/AssociatedCourse';
import { ModalDeleteSkill } from 'components/Management/SkillManagement/components/ModalDeleteSkill';
import { useDebouncedState } from 'hooks/useDebounce';
import { getIndexPage } from 'utils/commonFun';
import { CircleSpin } from 'components/UiComponents/CircleSpin';

export const ConnectForm = ({ children }) => {
    const methods = useFormContext();
    return children({ ...methods });
};

export default function index({ children }) {
    const { t: translator } = useTranslation();
    const params: { id: string; skillId: string } = useParams();
    const { booleanValue: modalDeleteSkill, toggleBooleanValue: toggleModalDeleteSkill } = useBoolean();
    const [keyword, setKeyword] = useDebouncedState('');
    const [loadingDetail, setLoadingDetail] = useState(false);
    const [loadingAssociatedCourses, setLoadingAssociatedCourses] = useState(false);
    const [loadingCreateUpdate, setLoadingCreateUpdate] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 10,
        total: 0,
        sort_by: 'title',
        order: 'desc',
    });

    const [originalSkill, setOriginalSkill] = useState(null);

    const workspaceId = params?.id;
    const skillId = params?.skillId;

    const history = useHistory();
    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            description: '',
            levels: [{ name: '', description: '', level: 1 }],
            associatedCourses: [],
            list_remove_levels: [],
        },
    });
    const { handleSubmit } = methods;

    const checkDuplicate = () => {
        const levels = methods.watch('levels');
        if (levels?.length > 1) {
            const duplicateIndex = levels?.map((item, index) => {
                if (!item?.name?.trim()) return -1;
                return levels.findIndex((level, idx) => {
                    return (
                        level.name?.trim() == item.name?.trim() && index !== idx
                    );
                });
            });
            for (let i = 0; i < duplicateIndex.length; i++) {
                if (duplicateIndex[i] !== -1) {
                    methods.setError(`levels.${i}.name`, { type: 'duplicate', message: translator('This level name already exists.') });
                }
                else if (duplicateIndex[i] === -1 && !levels[i]?.name) {
                    methods.setError(`levels.${i}.name`, { type: 'required', message: translator('FORM_CONST.REQUIRED_FIELD') });
                }
                else methods.clearErrors(`levels.${i}.name`);
            }
        }
    };

    const onCreateSkill = () => {
        checkDuplicate();
        const data = methods.getValues();
        const levels = get(data, 'levels', []);
        data.levels = levels.map((level, index) => {
            return {
                level: index + 1,
                name: level.name?.trim(),
                description: level.description?.trim(),
            };
        });
        setLoadingCreateUpdate(true);
        skillService
            .createSkillService(workspaceId, data)
            .then((res) => {
                Toastify.success(translator('CREATE_SKILL_SUCCESS'));
                history.replace(`/workspace/${params?.id}/management/skills/${res?.data?.id}`);
            })
            .catch((error) => {
                const fieldErrors: any[] = error.response?.data?.error?.body_params?.map(el => ({
                    field: el?.loc?.[0],
                    msg: el?.ctx?.error,
                    type: 'DUPLICATE',
                    index: Array.isArray(el?.input) ? el?.input?.map((el, i) => i) : null
                }));
                fieldErrors?.forEach(item => {
                    if (item?.field === 'name' && item?.type === 'DUPLICATE') {
                        methods.setError('name', {
                            type: 'duplicate',
                            message: 'This skill name already exists.',
                        }, { shouldFocus: true });
                    }
                    if (item?.field === 'levels' && item?.type === 'DUPLICATE') {
                        item?.index?.map(i => {
                            methods.setError(`levels.${i}.name`, {
                                type: 'duplicate',
                                message: 'This level name already exists.',
                            }), { shouldFocus: true };
                        })
                    }
                });
                Toastify.error(translator('CREATE_SKILL_FAILED'));
            })
            .finally(() => setLoadingCreateUpdate(false));
    };

    const onUpdateSkill = () => {
        checkDuplicate();
        const data = methods.getValues();
        const levels = get(data, 'levels', []);
        
        data.list_remove_levels = originalSkill?.levels?.filter((level) => {
            // same id but different name with old name in originalSkill
            return levels.find((newLevel) => newLevel?.id !== level?.id && newLevel?.name === level?.name);
        }
        ).map((level) => level?.id);
        data.levels = levels.map((level, index) => {
            if (data.list_remove_levels?.includes(level?.id)) {
                return {
                    name: level?.name?.trim(),
                    description: level?.description,
                    level: index + 1,
            }}
            return {
                ...level,
                name: level?.name?.trim(),
                level: index + 1,
            };
        });

        setLoadingCreateUpdate(true);
        skillService
            .updateSkillByIdService(workspaceId, skillId, data)
            .then((res) => {
                Toastify.success();
                fetchSkillDetail();
                fetchSkillAssociatedCourses();
            })
            .catch((error) => {
                const fieldErrors: any[] = error.response?.data?.error?.body_params?.map(el => ({
                    field: el?.loc?.[0],
                    msg: el?.ctx?.error,
                    type: 'DUPLICATE',
                    index: Array.isArray(el?.input) ? el?.input?.map((el, i) => i) : null
                }));
                fieldErrors?.forEach(item => {
                    if (item?.field === 'name' && item?.type === 'DUPLICATE') {
                        methods.setError('name', {
                            type: 'duplicate',
                            message: 'This skill name already exists.',
                        }, { shouldFocus: true });
                    }
                    if (item?.field === 'levels' && item?.type === 'DUPLICATE') {
                        item?.index?.map(i => {
                            methods.setError(`levels.${i}.name`, {
                                type: 'duplicate',
                                message: 'This level name already exists.',
                            }), { shouldFocus: true };
                        })
                    }
                });
                Toastify.error();
            })
            .finally(() => setLoadingCreateUpdate(false));
    };

    const fetchSkillDetail = async () => {
        if (skillId !== 'new') {
            setLoadingDetail(true);
            const response: any = await skillService.getSkillByIdService(workspaceId, skillId);
            if (response) {
                setLoadingDetail(false);
                methods.reset(response?.data);
                setOriginalSkill(response?.data);
            }
        }
    };

    const fetchSkillAssociatedCourses = async (page = 1) => {
        const queryParams = {
            title: keyword,
            sort_by: pagination.sort_by,
            order: pagination.order,
            per_page: pagination.per_page,
            page: page ?? pagination?.page,
        };
        if (!queryParams?.title) delete queryParams?.title;
        setLoadingAssociatedCourses(true);
        const response: any = await skillService.getAssociatedCoursesById(workspaceId, skillId, queryParams);
        if (response) {
            const { items, page, per_page, order, total } = response.data;
            setPagination({ ...pagination, page, per_page, order, total });
            setIndexPage(getIndexPage(page, per_page, items?.length));
            setLoadingAssociatedCourses(false);
            methods.reset({
                ...methods?.watch(),
                associatedCourses: items,
            });
        }

        return response?.data?.items || [];
    };

    useEffect(() => {
        fetchSkillDetail();
    }, [skillId]);

    useEffect(() => {
        skillId != 'new' && fetchSkillAssociatedCourses();
    }, [keyword]);

    if (loadingDetail) {
        return (
            <div className="h-screen flex justify-center pt-10 items-center">
                <CircleSpin color="text-primary-500" />
            </div>
        );
    }

    return (
        <div className="bg-gray-50 h-full min-h-screen">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    {
                        name: translator('DASHBOARD.SIDEBAR.SKILLS'),
                        href: `/workspace/${workspaceId}/management/skills`,
                        className: '',
                    },
                    { name: skillId === 'new' ? translator('Create a New Skill') : methods.getValues("name"), href: '', className: '' },
                ]}
            />
            <FormProvider {...methods}>
                <form className="m-5 bg-white rounded-lg" onSubmit={skillId != 'new' ? handleSubmit(onUpdateSkill) : handleSubmit(onCreateSkill)}>
                    <div className="p-5">
                        <HeaderSkill
                            skillDetail={methods.watch()}
                            loading={loadingCreateUpdate}
                            isUpdate={methods.formState.isDirty}
                            isDisableCreate={!methods.formState.isValid}
                            isDisableUpdate={!methods.formState.isValid}
                            loadingDelete={loadingDelete}
                            onCancel={() => history.goBack()}
                            onDelete={toggleModalDeleteSkill}
                            onRefresh={() => {
                                fetchSkillDetail();
                                fetchSkillAssociatedCourses();
                            }}
                        />
                    </div>
                    <div className=" grid grid-cols-2 border-t border-gray-300 py-5 h-full">
                        <div className="col-span-1 px-6 border-r border-gray-300">
                            <Information />
                        </div>
                        <div className="col-span-1 px-6">
                            <Levels skillId={skillId} checkDuplicate={checkDuplicate} />
                        </div>
                    </div>
                    {skillId !== 'new' && (
                        <div className="px-6">
                            <SkillAssociatedCourse
                                data={methods.watch()?.associatedCourses}
                                setKeyword={setKeyword}
                                loading={loadingAssociatedCourses}
                                indexPage={indexPage}
                                pagination={pagination}
                                fetchList={fetchSkillAssociatedCourses}
                            />
                        </div>
                    )}
                </form>
            </FormProvider>
            <ModalDeleteSkill isOpen={modalDeleteSkill} onClose={toggleModalDeleteSkill} setLoading={setLoadingDelete} />
        </div>
    );
}
