import moment from 'moment';
import React, { useMemo } from 'react';
import './LearnerInfo.scss';
import { useTranslation } from 'react-i18next';

export const LearnerInfo = ({ attemptDetail }) => {
    const { t } = useTranslation();

    const totalGrade = useMemo(() => {
        const isAllTasksGraded = !!attemptDetail?.tasks?.filter((el) => !el?.task_result?.is_graded)?.length;
        const isAttemptSubmitted = !!attemptDetail?.completion_date;

        if (attemptDetail?.status === 'completed') {
            return attemptDetail?.final_score;
        }
        return null;
    }, [attemptDetail]);

    return (
        <div className="learnerInfo">
            <div className="learnerInfo-header">
                <div className="learnerInfo-learner truncate">
                    <div
                        className="learnerInfo-avatar"
                        style={{ backgroundImage: `url(${attemptDetail?.assignment?.learner?.avatar_url})` }}
                    ></div>
                    <div className="learnerInfo-name truncate">{attemptDetail?.assignment?.learner?.display_name}</div>
                </div>
                <div className="learnerInfo-grade">
                    Total Grade <span>{totalGrade != null ? Math.round(totalGrade) + '%' : '-'}</span>
                </div>
            </div>
            <div className="learnerInfo-info">
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Submission Date:</div>
                    <div className="learnerInfo-value">
                        {!!attemptDetail?.completion_date ? moment(attemptDetail?.completion_date).format('DD/MM/YYYY') : '-'}
                    </div>
                </div>
                <div className="learnerInfo-line"></div>
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">{t('DUE_DATE')}:</div>
                    <div className="learnerInfo-value">
                        {!!attemptDetail?.assignment?.end_time
                            ? moment(attemptDetail?.assignment?.end_time).format('DD/MM/YYYY')
                            : '-'}
                    </div>
                </div>
                <div className="learnerInfo-line"></div>
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Time limit:</div>
                    <div className="learnerInfo-value">
                        {attemptDetail?.assignment?.time_limit != null ? attemptDetail?.assignment?.time_limit / 60 + 'm' : '∞'}
                    </div>
                </div>
                <div className="learnerInfo-line"></div>
                <div className="learnerInfo-item">
                    <div className="learnerInfo-label">Completion Rate:</div>
                    <div className="learnerInfo-value">
                        {attemptDetail?.completion_percentage != null
                            ? Math.round(attemptDetail?.completion_percentage || 0) + '%'
                            : '-'}
                    </div>
                </div>
            </div>
        </div>
    );
};
