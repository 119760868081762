import { downloadCSV } from '../common';

export const DownloadLearnerGroup = (students) => {
    let headers: string[], rows: string[][];
    let file_name = `Learner List`;
    headers = [
        'No.',
        'Learning Group Name',
        'Avg Overall Grade',
        'Avg Attendance',
        'Avg Assignment Grade',
        'Avg Assignment Completion',
        'Avg Assignment On-time',
        //'Avg Assignment Overdue',
        'Avg Course Completion',
        'Avg Goals Achieved',
        'Total Coins Earned',
    ];
    rows = students?.items?.map((el: any, index) => [
        index + 1,
        el?.learning_group?.name || 'N/A',
        el?.overall != null ? el?.overall : '-',
        el?.attendance != null ? el?.attendance : '-',
        el?.assignment_score != null ? el?.assignment_score : '-',
        el?.assignment_completion != null ? el?.assignment_completion : '-',
        el?.assignment_ontime != null ? el?.assignment_ontime : '-',
        //'-',
        el?.course_completion != null ? el?.course_completion : '-',
        el?.lesson_goal != null ? el?.lesson_goal : '-',
        el?.coin_earned != null ? el?.coin_earned : '-',
    ]);
    downloadCSV([headers, ...rows], file_name);
};
