import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import { STATUS_LEARNER_ENROLL_OPTION } from 'constant/util.const';
import { checkPermission } from 'utils/commonFun';
interface IFilter {
    onChangeFilter: (value: any) => void;
}
const capitalized = (word = '') => {
    return word.charAt(0).toUpperCase()
        + word.slice(1)
}

const EnrollmentFilter = ({ onChangeFilter }: IFilter) => {
    const [keyword, setKeyword] = useDebouncedState('');
    const search = useLocation()?.search;
    const defaultStatus = new URLSearchParams(search).get('statuses');
    const {t} = useTranslation();

    const [statusSelected, setStatusSelected] = useState<any>(defaultStatus ? defaultStatus?.split(',')?.map(item => ({ name: capitalized(item), value: item })) : []);

    const params: { id, userId } = useParams();
    const workspaceId = params?.id;
    const userId = params?.userId

    useEffect(() => {
        let listFilter: any = {
            title: keyword,
            statuses: statusSelected?.map(status => status?.value)?.join(','),
        };
        if (!statusSelected?.length || statusSelected.length === 4) delete listFilter.statuses;
        if (!listFilter?.title) delete listFilter?.title
        if (!listFilter?.learning_group_ids?.length) delete listFilter?.learning_group_ids;

        onChangeFilter(listFilter);
    }, [keyword, statusSelected]);


    return (
        <div className=' w-full flex flex-wrap gap-2 items-center mt-3'>
            <SearchBox
                onSubmit={(e: any) => setKeyword(e)}
                className="w-full max-w-xs text-sm max-h-9"
                placeholder={t("SEARCH")}
            />
            <SelectBox
                label={t("USER.ENROLLMENT.LEARNER_STATUS")}
                onSelect={setStatusSelected}
                selectedItems={statusSelected}
                data={STATUS_LEARNER_ENROLL_OPTION(t)}
                total={4}
            />

        </div>
    );
}
export default EnrollmentFilter;