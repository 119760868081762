import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './Grade.scss';
import { ButtonPrimary } from '_shared';
import React, { useMemo, useState } from 'react';
import Select, { StylesConfig } from 'react-select';
import { useForm, useFieldArray } from 'react-hook-form';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import Spinner from 'components/Spinner';
import Swal from 'sweetalert2';
import confirm_resetPNG from 'assets/confirm_reset.png';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { get } from 'lodash';
import { Toastify } from '_shared';
import { TextareaField } from 'components/InputForm/InputForm';
import courseService from 'services/course.service';
import { attendanceOption } from './constant'
import { VALIDATE_FIELD } from 'utils/validate';
import { checkPermission } from 'utils/commonFun';
import { User } from '_shared/components/User/User';

const customSelectStyle: StylesConfig = {
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    control: (styles: any) => {
        return {
            ...styles,
            minHeight: 34,
            maxHeight: 34,
            height: 34,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #9ca3af33',
            borderRadius: 4,
            outline: 'none',
            ':hover': 'none',
            boxShadow: 'none',
        };
    },
    valueContainer: (styles) => {
        return {
            ...styles,
            paddingBottom: 6,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
        };
    },
};

export const optionGoldAchieved = [
    {
        label: "Yes",
        value: 2,
    },
    {
        label: "No",
        value: 1,
    },
    {
        label: "N/A",
        value: null,
    }
]

export default function Grade({ children }) {
    const params: { id: string; courseId: string, sessionId: string } = useParams();
    const { t } = useTranslation();
    const [isPresent, setIsPresent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [sessionDetail, setSessionDetail] = useState(undefined)
    const dataAttendance = useMemo(() => attendanceOption(t), [t]);
    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isDirty, isValid },
        setValue,
        getValues,
        watch,
        clearErrors,
    } = useForm({
        defaultValues: {
            student_attendances: [],
            lesson_notes: '',
            instructor_notes: ''
        },
        mode: "onChange"
    });
    const { fields, replace } = useFieldArray({
        control,
        name: 'student_attendances',
    });

    React.useEffect(() => {
        getListAttendance();
    }, []);


    const getListAttendance = () => {
        setLoading(true);
        courseService.getListAttendanceOfSession(
            params?.id,
            params?.courseId,
            params?.sessionId,
            { skip_pagination: true }
        )
            .then((res) => {
                const { items } = res.data;
                replace(items);
                courseService.getDetailSession(params?.id, params?.courseId, params?.sessionId)
                    .then((res) => {
                        setValue('lesson_notes', res?.data?.lesson_notes);
                        setValue('instructor_notes', res?.data?.instructor_notes)
                        setSessionDetail(res?.data)
                    })
            })
            .finally(() => setLoading(false));
    };

    const updateListAttendance = (data: any) => {
        setLoading(true);
        courseService.updateAttendanceOfSession(
            params?.id,
            params?.courseId,
            params?.sessionId,
            { ...data }
        )
            .then((res) => {
                getListAttendance();
                Toastify.success();
            })
            .catch((err: any) => {
                if (get(err, 'response.data.error.name') === 'nothing_changed') return;
                const error =
                    t('EVALUATE.UPDATE_GRADE_ERROR') + get(err, 'response.data.error.body_params[0].msg');
                Toastify.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const checkAttendance = (index: number) => {
        return watch(`student_attendances.${index}.attendance_type`) === 'absent';
    };

    const handleAllPresent = () => {
        setValue(
            'student_attendances',
            getValues('student_attendances').map((item) => ({
                ...item,
                attendance_type: 'present',
                rewards_coin: item?.rewards_coin || undefined,
            }))
        );
        setIsPresent(true);
    };

    const handleMaxGoalAchieved = () => {
        setValue(
            'student_attendances',
            getValues('student_attendances').map((item) => ({
                ...item,
                goal_achieved: 2,
                rewards_coin: item?.rewards_coin || undefined,
            }))
        );
    };


    const getDefaultAttendance = (value: string) => {
        if (value) {
            return dataAttendance.find((i) => i.value === value);
        } else if (isPresent) {
            return {
                id: 1,
                value: 'present',
                label: t('EVALUATE.PRESENT'),
            };
        }
        return null;
    };

    const onReset = () => {
        Swal.fire({
            title: '<p>Confirm Reset</p>',
            iconHtml: `<img src="${confirm_resetPNG}" />`,
            html: '<p>Are you sure you want to reset this action?</p>',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            cancelButtonText: 'Yes, do it!',
            confirmButtonText: 'No, cancel',
            customClass: {
                title: 'swale2-title-popupconfirm',
                popup: 'z-9999 swale2-popup-popupconfirm',
                icon: 'swale2-icon-popupconfirm',
                confirmButton: 'swale2-cancelButton-popupconfirm',
                cancelButton: 'swale2-confirmButton-popupconfirm',
                container: 'swale2-container-popupconfirm',
                htmlContainer: 'swale2-htmlContainer-popupconfirm',
            },
        }).then((result) => {
            if (result.isDismissed) {
                setValue('lesson_notes', '');
                setValue('instructor_notes', '')
                getListAttendance();
            }
        });
    };

    const onSubmitForm = (values: any) => {
        updateListAttendance({
            student_attendances: values.student_attendances?.map((el) => ({
                attendance_type: el.attendance_type || null,
                instructor_comment: el.instructor_comment || '',
                goal_achieved: el.goal_achieved || null,
                rewards_coin: el?.rewards_coin || null,
                student_id: el?.user?.id
            })),
        });
        courseService.updateSessionPast(params?.id, params?.courseId, {
            id: params?.sessionId,
            instructor_notes: values?.instructor_notes,
            lesson_notes: values?.lesson_notes
        })
    };

    return (
        <form className="CourseGradebook" onSubmit={handleSubmit(onSubmitForm)}>
            <div className="CourseGradebook-Header">
                <div></div>
                <div className="CourseGradebook-Header--title">{t("EVALUATE.EVALUATE_SESSION")}</div>
                {checkPermission('course', 'U') && <div className="CourseGradebook-Header--button">
                    <button  disabled={!isDirty} onClick={onReset} className='text-primary-500 font-semibold text-sm'>
                        {t("RESET_ALL")}
                    </button>
                    <div className='w-[1px] h-7 bg-gray-300'></div>
                    <ButtonPrimary type="submit" >
                        {t("CONFIRM")}
                    </ButtonPrimary>
                </div>}

            </div>

            <div className="CourseGradebook-Form" >
                <div className="CourseGradebook-Form--section flex ">
                    <div className='w-1/2'>
                        <div className="!text-lg font-semibold mb-2">{t('EVALUATE.OVERVIEW')}</div>
                        <div>
                            <label className='text-xs font-semibold'>{t('EVALUATE.COURSE')}</label>
                            <div onClick={() => window.open(`/workspace/${params?.id}/management/courses/${params?.courseId}`, '_blank')} className='text-sm font-semibold cursor-pointer text-primary-500'>{sessionDetail?.course?.title}</div>
                        </div>
                        <div>
                            <label className='text-xs font-semibold'>{t('EVALUATE.LESSON')}</label>
                            <div onClick={() => window.open(`/workspace/${params?.id}/lesson/${sessionDetail?.lesson?.id}`)} className='text-sm font-semibold cursor-pointer text-primary-500'>{sessionDetail?.lesson?.title || '-'}</div>
                        </div>
                        <div>
                            <label className='text-xs font-semibold'>{t('EVALUATE.INSTRUCTORS')}</label>
                            <div className='flex flex-wrap gap-2'>
                                {
                                    sessionDetail?.instructors?.map(item => {
                                        return (
                                            <User
                                                key={item?.id}
                                                name={item?.display_name}
                                                link={`/workspace/${params?.id}/management/users/${item?.id}?tab=information`}
                                                avatar={item?.avatar_url}
                                                className='!max-w-none'
                                            />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="Overview w-1/2">
                        <TextareaField
                            label={t('EVALUATE.LESSON_NOTES')}
                            name="lesson_notes"
                            placeholder={t('EVALUATE.ENTER_LESSON_NOTES')}
                            register={register('lesson_notes')}
                            textLength={getValues('lesson_notes')?.length?.toString()}
                            maxLength={1000}
                            isCountter
                            disabled={!checkPermission('course', 'U')}
                        />
                        <TextareaField
                            label={t('EVALUATE.INSTRUCTOR_NOTES')}
                            name="instructor_notes"
                            className='mt-2'
                            placeholder={t('EVALUATE.ENTER_INSTRUCTOR_NOTES')}
                            register={register('instructor_notes')}
                            textLength={getValues('instructor_notes')?.length?.toString()}
                            maxLength={1000}
                            isCountter
                            disabled={!checkPermission('course', 'U')}
                        />
                    </div>
                </div>
                <div className="CourseGradebook-Form--section mt-5">
                    <div className="label">{t("EVALUATE.EVALUATE")}</div>
                    <div className="Grade pl-4">
                        <div className={'grid grid-cols-12 gap-5'}>
                            <div className="col-span-2"></div>
                            <div className="col-span-2 text-gray-500">
                                <p className="text-sm font-semibold mb-3">{t('EVALUATE.ATTENDANCE')}</p>
                                <ButtonPrimary onClick={handleAllPresent} type="button" disabled={!checkPermission('course', 'U')}>
                                    {t('EVALUATE.ALL_PRESENT')}
                                </ButtonPrimary>
                            </div>
                            <div className="col-span-2 text-gray-500">
                                <p className="text-sm font-semibold mb-3">{t('EVALUATE.LESSON_GOAL')}</p>
                                <ButtonPrimary onClick={handleMaxGoalAchieved} type="button" disabled={!checkPermission('course', 'U')}>
                                    {t('EVALUATE.ALL_YES')}
                                </ButtonPrimary>
                            </div>
                            <div className="col-span-2 text-gray-500">
                                <p className="text-sm font-semibold mb-3">{t('EVALUATE.REWARD_COINS')}</p>
                            </div>
                            <div className="col-span-4 text-gray-500">
                                <p className="text-sm font-semibold">{t('EVALUATE.INSTRUCTOR_COMMENTS')}</p>
                            </div>
                        </div>
                        {!loading && (
                            <div className="space-y-3 mt-4 pb-6">
                                {fields?.map((item, index) => (
                                    <div key={item.id} className={'grid grid-cols-12 gap-5'}>
                                        <div className="col-span-2 flex items-center space-x-2">
                                            <img src={item?.user?.avatar_url} alt="img" className="w-6 h-6 rounded-full" />
                                            <p className="font-semibold text-sm">
                                                {item?.user?.display_name || '-'}
                                            </p>
                                        </div>

                                        <div className="col-span-2">
                                            {/* @ts-ignore */}
                                            <Select
                                                isSearchable={false}
                                                styles={customSelectStyle}
                                                placeholder={t('EVALUATE.SELECT')}
                                                options={dataAttendance}
                                                menuPlacement="auto"
                                                defaultValue={getDefaultAttendance(item.attendance_type)}
                                                name={`student_attendances.${index}.attendance_type`}
                                                {...register(`student_attendances.${index}.attendance_type`, {
                                                    required: {
                                                        value: true,
                                                        message: t('FORM_CONST.REQUIRED_FIELD'),
                                                    },
                                                })}
                                                className="!border-ooolab_gray_6"
                                                onChange={(item: any) => {
                                                    setValue(`student_attendances.${index}.attendance_type`, item.value);
                                                    clearErrors(`student_attendances.${index}.attendance_type`);
                                                }}
                                                isDisabled={!checkPermission('course', 'U')}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.attendance_type`} />
                                        </div>
                                        <div className="col-span-2">
                                            {/* @ts-ignore */}
                                            <Select
                                                isSearchable={false}
                                                styles={customSelectStyle}
                                                placeholder={t('EVALUATE.SELECT')}
                                                options={optionGoldAchieved}
                                                menuPlacement="auto"
                                                defaultValue={optionGoldAchieved.find((el) => el.value === item.goal_achieved)}
                                                name={`student_attendances.${index}.goal_achieved`}
                                                {...register(`student_attendances.${index}.goal_achieved`)}
                                                className="!border-ooolab_gray_6"
                                                formatOptionLabel={(item: any) => t(item.label?.toUpperCase())}
                                                onChange={(item: any) => {
                                                    setValue(
                                                        `student_attendances.${index}.goal_achieved`,
                                                        !!item.value ? item.value : 0
                                                    );
                                                    clearErrors(`student_attendances.${index}.goal_achieved`);
                                                }}
                                                isDisabled={!checkPermission('course', 'U')}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.goal_achieved`} />
                                        </div>

                                        <div className="col-span-2">
                                            <input
                                                name={`student_attendances.${index}.rewards_coin`}
                                                placeholder={t('EVALUATE.ENTER_REWARD_COINS')}
                                                className="border border-ooolab_blue_4 rounded w-full py-1 px-2"
                                                type="number"
                                                pattern="[0-9]*"
                                                onInput={(e: any) => {
                                                    const valueChange = e.target.validity.valid
                                                        ? e.target.value
                                                        : getValues(`student_attendances.${index}.rewards_coin`);
                                                    setValue(`student_attendances.${index}.rewards_coin`, valueChange);
                                                }}
                                                {...register(`student_attendances.${index}.rewards_coin`, {
                                                    pattern: {
                                                        value: VALIDATE_FIELD.NUMBER,
                                                        message: t('FORM_CONST.NUMBER_ONLY_AND_NUMBERS_GREATER_THAN_ZERO'),
                                                    },
                                                })}
                                                disabled={!checkPermission('course', 'U')}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.rewards_coin`} />
                                        </div>
                                        <div className="col-span-4">
                                            <input
                                                type="text"
                                                maxLength={300}
                                                name={`student_attendances.${index}.instructor_comment`}
                                                placeholder={t('EVALUATE.ENTER_COMMENT')}
                                                className="border border-ooolab_blue_4 rounded w-full py-1 px-2"
                                                {...register(`student_attendances.${index}.instructor_comment`, {
                                                    maxLength: {
                                                        value: 250,
                                                        message: t('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 250 })
                                                    }
                                                })}
                                                disabled={!checkPermission('course', 'U')}
                                            />
                                            <ErrorMessageForm errors={errors} name={`student_attendances.${index}.instructor_comment`} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {loading && <Spinner />}
                        {!fields?.length && !loading && <NoContentWithImage />}
                    </div>
                </div>
            </div>
        </form>
    );
}
