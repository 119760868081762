import { LIBRARY_ICONS, LibraryTypeEnum, onCheckTypeSize } from 'components/Library/LibraryContants';
import { H5P_LIBRARY } from 'constant/h5p.const';
import LocationDetails from '../LocationDetails/LocationDetails';
import dayjs from 'dayjs';
import { TagList } from '_shared';
import './DetailsFilePopup.scss';
import BlockContent from './BlockContent';
import { OpenBox } from 'assets/icon';

export default function Details({
    translator,
    item,
    subType,
    isHidden,
    arrayLocation,
    result,
    typeTags,
    customFields,
    handleUpdateTag,
    isLoading,
}) {
    return (
        <div>
            <div className="imageCover">
                {item?.sub_object_type == 'scorm' ? (
                    <span className="text-gray-500">
                        <OpenBox width={110} height={90} />
                    </span>
                ) : (
                    <img
                        alt="libary-icon"
                        className=""
                        src={
                            item?.object_type
                                ? LIBRARY_ICONS(translator)?.find((item) => item?.value === subType)?.icon
                                : LIBRARY_ICONS(translator)[0].icon
                        }
                    />
                )}
            </div>
            <BlockContent title={translator('DETAILS')}>
                <div>
                    <p className="content-item">
                        {translator('NAME')}:<p className="content-value">{item?.name}</p>
                    </p>
                    <p className="content-item ">
                        {translator('CONTENT_TYPE')}:{' '}
                        <span className="content-value">
                            {item?.object_type
                                ? LIBRARY_ICONS(translator)?.find((item) => item?.value === subType)?.label
                                : LIBRARY_ICONS(translator)[0].label}
                            {item?.sub_object_type == 'scorm' ? '(SCORM)' : null}
                        </span>
                    </p>
                    <p className="content-item !hidden">
                        {translator('Lesson ID')}:<p className="content-value">{item?.object_id}</p>
                    </p>
                    <p
                        className={`content-item  ${isHidden ||
                            item?.object_type === LibraryTypeEnum.h5p ||
                            item?.object_type === LibraryTypeEnum.lesson ||
                            item?.object_type === LibraryTypeEnum.assignment
                            ? ' !hidden'
                            : ''
                            }`}
                    >
                        {translator('SIZE')}:{' '}
                        <span className="content-value">{item?.size ? `${onCheckTypeSize(item?.size)}` : '-'}</span>
                    </p>
                    <p className={`content-item`}>
                        {translator('LOCATION')}:{' '}
                        <span className="content-value">
                            <LocationDetails
                                NameDefault={translator('DASHBOARD.SIDEBAR.INTERNAL_LIBRARY', true)}
                                arr={arrayLocation || []}
                                containerClassName="z-10 sticky top-0"
                            />
                        </span>
                    </p>
                    <p className={`content-item `}>
                        <div className="itemLabel">{translator('OWNER')}: </div>
                        <div className="content-value avatar">
                            <div className="avatar-img">
                                <img alt="avatar-icon" src={item?.created_by?.avatar_url} />
                            </div>
                            <div className="content-value">{item?.created_by?.display_name || item?.created_by?.name}</div>
                        </div>
                    </p>
                    <p className="content-item">
                        {translator('LAST_MODIFIED')}:{' '}
                        <span className="content-value">
                            {dayjs(item?.updated_at).tz(result?.time_zone).format('MMM DD, YYYY') || '-'}
                        </span>
                    </p>
                    <p className="content-item">
                        {translator('CREATED')}:{' '}
                        <span className="content-value">
                            {dayjs(item?.created_at).tz(result?.time_zone).format('MMM DD, YYYY') || '-'}
                        </span>
                    </p>
                </div>
            </BlockContent>
            {typeTags && item?.object_type != 'scorm' ? (
                <div className="detailsFilePopup-tags">
                    <TagList customFields={customFields} setCustomFields={handleUpdateTag} loading={isLoading} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
}
