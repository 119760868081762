import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import ButtonComponent from 'components/Button/ButtonComponent';
import { checkPermission, classNames } from 'utils/commonFun';
import { useBoolean } from 'hooks/custom';
import { OptionPeople } from './componentSelect'
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import MenuContext from '../../GroupList/MenuContext';
import groupService from 'services/group.service';
import ModalSelect from './ModalSelect';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import workspaceMiddleware from 'middleware/workspace.middleware';
import ModalCreate from 'components/Management/components/ModalCreateWrapper';
import { groupBy, isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getTypePerson } from '../../utils'
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';

const GroupProfile = () => {
    const {
        register,
        formState: { errors, dirtyFields },
        handleSubmit,
        reset,
        getValues,
        watch,
    } = useForm({});
    const formWatch = watch();
    const { t: translator } = useTranslation();
    const [peopleSelected, setPeopleSelected] = useState<any>([])

    const [defaultOptionClass, setDefaultOptionClass] = useState([])
    const [defaultOptionPeople, setDefaultOptionPeople] = useState([])

    const [isRemoveAll, setRemoveAll] = useState(false)
    const [selectedId, setSelectedId] = useState('')
    const [isSave, setSave] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [data, setData] = useState({
        name: '',
        description: ''
    })

    const timeout = useRef<any>()

    const {
        booleanValue: openModalError,
        toggleBooleanValue: toggleModalError,
    } = useBoolean();
    const {
        booleanValue: modalAddClass,
        toggleBooleanValue: toggleModalAddClass,
    } = useBoolean();
    const {
        booleanValue: openModalConfirmRemoveClass,
        toggleBooleanValue: toggleModalConfirmRemoveClass,
    } = useBoolean();
    const {
        booleanValue: openModalConfirmRemovePeople,
        toggleBooleanValue: toggleModalConfirmRemovePeople,
    } = useBoolean();
    const {
        booleanValue: modalAddPeople,
        toggleBooleanValue: toggleModalAddPeople,
    } = useBoolean();
    const params = useParams<{ id: string; groupId: string }>();
    const [paginationClass, setPaginationClass] = useState({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'name',
        total: 0,
    });

    const [paginationPeople, setPaginationPeople] = useState({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'role',
        total: 0,
    });

    useEffect(() => {
        const curentData = getValues()
        const listUpdateKeys = Object.keys(dirtyFields);
        const fieldChanges = []
        listUpdateKeys.forEach((i) => {
            if (!isEqual(curentData[i]?.trim(), data[i]?.trim() || '')) {
                return fieldChanges.push(i)
            }
        });
        if (fieldChanges.length > 0 && curentData['name']?.trim() !== '') setSave(true)
        else setSave(false)
    }, [formWatch])

    const formatList = (items, isSelected) => {
        if (items.length < 0) return []
        const classListOptions = items.map((item) => {
            if (isSelected) {
                return {
                    ...item,
                    label: item.name || item.display_name,
                    value: item.id,
                    isSelected
                }
            }
            return {
                ...item,
                label: item.name || item.display_name,
                value: item.id,
            }
        })
        return classListOptions
    }

    const { dispatch: workspaceDispatch } = useContext(GetWorkspaceContext);

    useEffect(() => {
        groupService.getGroupDetailService(params.id, params.groupId)
            .then(res => {
                const data = res?.data
                reset(
                    {
                        name: data?.name,
                        description: data?.description,
                    },
                    {
                        keepDirty: false,
                    }
                );
                setData(data)
                workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                    {
                        name: data?.name,
                        routeId: 'groupId',
                    },
                ])
            })
        return (
            workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                {
                    name: '',
                    routeId: '',
                },
            ])
        )
    }, []);

    useEffect(() => {
        groupService.getPeopleSelectedService(params.id, params.groupId, { skip_pagination: true, order: 'asc', sort_by: 'display_name' })
            .then(res => {
                const {
                    items,
                    page,
                    per_page,
                    order,
                    sort_by,
                    total,
                } = res.data;
                setPeopleSelected(formatList(items || [], true) || [])
                setPaginationPeople({ ...paginationPeople, page, per_page, order, sort_by, total });
            })
    }, [refresh])

    const submitForm = (val) => {
        const data = {
            name: val.name?.trim(),
            description: val.description?.trim() || null
        }
        if (data.description === '') data.description = null
        groupService.updateGroupService(params.id, params.groupId, data)
            .then(res => {
                setData(res.data)
                workspaceMiddleware.setCurrentRouteDetail(workspaceDispatch, [
                    {
                        name: res.data?.name,
                        routeId: 'groupId',
                    },
                ]);
            })
    }

    const handleSelectClass = (value) => {
        setDefaultOptionClass(value)
    }

    const handleSelectPeople = (value) => {
        setDefaultOptionPeople(value)
    }

    const handleAddPeople = () => {
        const people = defaultOptionPeople.filter(({ value: id1 }) => !peopleSelected.some(({ value: id2 }) => id2 === id1));
        const formatPeople = people.length > 0 ? people.map(item => item.id) : []
        groupService.addPeopleService(params.id, params.groupId, { people: formatPeople })
            .then(res => {
                setRefresh(!refresh)
                closeModalAddPeople()
            })
            .catch(error => {
                toggleModalError()
            })
    }

    const handleSelectClassId = (id) => {
        setSelectedId(id)
        toggleModalConfirmRemoveClass()
    }

    const handleSelectPeopleId = (id) => {
        setSelectedId(id)
        toggleModalConfirmRemovePeople()
    }

    const handleRemoveClass = () => {
        groupService.removeClassService(params.id, params.groupId, selectedId, {"keep_people": !isRemoveAll})
        .then (res => {
            toggleModalConfirmRemoveClass()
            setSelectedId('')
            setRefresh(!refresh)
            setRemoveAll(false)
            Toastify.success();
        })
        .catch(() => Toastify.success())
    }
        
    const handleRemovePeople = () => {
        groupService.removePeopleService(params.id, params.groupId, selectedId)
        .then (res => {
            toggleModalConfirmRemovePeople()
            setSelectedId('')
            setRefresh(!refresh)
            Toastify.success();
        })
        .catch(() => Toastify.success())
    }

    const closeModalAddPeople = () => {
        toggleModalAddPeople()
        setDefaultOptionPeople([])
    }

    const closeModalAddClass = () => {
        toggleModalAddClass()
        setDefaultOptionClass([])
    }

    const getUrlDetail = (type, id) => {
        if (type === 'instructor') return `/workspace/${params.id}/management/users/${id}`
        if (type === 'student') return `/workspace/${params.id}/management/users/${id}`
    }

    const sortAlphabet = (arr, key) => {
        return arr.sort(function (a, b) {
            if (a[key] < b[key]) {
                return -1;
            }
            if (a[key] > b[key]) {
                return 1;
            }
            return 0;
        })
    }

    const handleSortByRole = () => {
        if (peopleSelected.length === 0) return []
        //add field type
        const people = peopleSelected.map(item => {
            return {
                ...item, type: getTypePerson(item.membership.type || item.membership.role, translator)
            }
        })
        sortAlphabet(people, 'type');
        const groupPeople = groupBy(people, 'type')
        const orderGroup = {
            admin: groupPeople?.["admin"] || [],
            member: groupPeople?.["member"] || [],
            ...groupPeople,
        }
        const rerult = Object.keys(orderGroup).map((group) => {
            return sortAlphabet(orderGroup[group], 'display_name')
        })
        return rerult
    }


    return (
        <form className="px-ooolab_p_5" onSubmit={handleSubmit(submitForm)}>
            <div className=" px-ooolab_p_5 py-ooolab_p_5 flex items-center justify-between">
                <div>{translator('GROUP.OVERVIEW')}</div>
                {checkPermission('customGroupDetails', 'U') && <div className="flex items-center text-ooolab_xs font-semibold">
                    <button
                        type="submit"
                        className={`mr-ooolab_m_3 px-ooolab_p_2 py-ooolab_p_1_e  bg-enterprise disabled:opacity-50 text-white rounded`}
                        disabled={!isSave}
                    >
                        {translator('GROUP.SAVE')}
                    </button>
                </div>}

            </div>
            <div className="px-ooolab_p_5 grid grid-cols-3 gap-x-ooolab_w_8 gap-y-ooolab_w_5 max-w-5xl">
                <div className="col-span-3 text-ooolab_xs">
                    <label
                        htmlFor="name"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block"
                    >
                        {translator("GROUP.NAME")} <span className="text-red-500">*</span>
                    </label>
                    <input
                        className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal"
                        type="text"
                        id="name"
                        placeholder={translator("GROUP.NAME_PLANCEHOLDER")}
                        {...register('name', {
                            required: {
                                value: true,
                                message: `${translator(
                                    'FORM_CONST.REQUIRED_FIELD'
                                )}`,
                            },
                            validate: {
                                shouldNotContainSpace: (value) =>
                                    !!value.trim()
                                        ? true
                                        : `${translator(
                                            'FORM_CONST.REQUIRED_FIELD'
                                        )}`,
                            },
                            maxLength: {
                                value: 100,
                                message: translator('GROUP.GROUP_NAME_MAX_LENGTH'),
                            },
                        })}
                        disabled={!checkPermission('customGroupDetails', 'U')}
                    />
                    {/* @ts-ignore */}
                    <ErrorMessage
                        className="text-red-500 text-ooolab_10px"
                        errors={errors}
                        name="name"
                        as="p"
                    />
                </div>


                <div className="col-span-3 text-ooolab_xs">
                    <label
                        htmlFor="description"
                        className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block"
                    >
                        {translator('GROUP.DESC')}
                    </label>
                    <textarea
                        className="border lg:w-full md:w-3/4 w-1/2 border-ooolab_bar_color text-ooolab_dark_1 p-ooolab_p_2 rounded font-normal h-20"

                        id="description"
                        placeholder={translator('GROUP.DESC_PLACEHOLDER')}
                        {...register('description')}
                        disabled={!checkPermission('customGroupDetails', 'U')}
                    />
                </div>
                <div className="col-span-3 text-ooolab_xs">
                    <div className='flex justify-between'>
                        <label
                            htmlFor="group-class"
                            className="text-ooolab_dark_1 font-semibold leading-ooolab_24px block w-full border-b border-b-[#EBEBF0] py-2"
                        >
                            {translator('GROUP.PEOPLE')}
                        </label>
                        {checkPermission('customGroupDetails', 'U') && <ButtonComponent
                            title=""
                            classStyle={classNames(
                                'w-fit justify-center bg-enterprise p-1 rounded hover:opacity-70 max-h-7'
                            )}
                            onClickButton={toggleModalAddPeople}
                            icon={<PlusIcon className="w-5 h-5 text-white" />}
                        >
                        </ButtonComponent>}

                    </div>
                    <div className=' max-h-[500px] min-h-[200px] pt-6 overflow-scroll pb-6 custom-scrollbar'>
                        {peopleSelected.length > 0 ?
                            <div className='mt-3 pl-2 pr-1'>
                                {
                                    handleSortByRole().map(group => {
                                        return group.map(item => {
                                            return (
                                                <div key={item.key} className="mb-2 flex justify-between">
                                                    <div className='flex'>
                                                        <img className='rounded-full w-5 h-5 mr-2 ' src={item.avatar_url} alt="" />
                                                        <div className='max-w-[220px] w-[220px] flex'>
                                                            <span className='w-20 max-w-[80px] font-semibold pr-2'>{`(ID:${item.id})`}</span>
                                                            <span className='truncate text-ellipsis'>
                                                                {item?.membership?.status === 'deactivate' ? item.label + ` (${translator("DEACTICVATED")})` : item.label}
                                                                {item?.nick_name ? ` / ${item?.nick_name}` : ''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='capitalize'>
                                                        {getTypePerson((item?.membership?.type === 'parent' || item?.membership?.type === 'student' || item?.membership?.type === 'instructor'
                                                            ? item?.membership?.type
                                                            : item?.membership?.user_role?.name
                                                        ) || null, translator)}
                                                    </div>
                                                    {checkPermission('customGroupDetails', 'U') && <MenuContext
                                                        id={item.value}
                                                        workspace={params.id}
                                                        handleRemove={handleSelectPeopleId}
                                                        redirectUrl={(['student'].indexOf(item?.membership?.type) !== -1) ? getUrlDetail(item?.membership?.type, item.id) : false}
                                                    />}

                                                </div>
                                            )
                                        })
                                    })
                                }

                            </div>
                            :
                            <NoContentWithImage content={translator("GROUP.NO_PEOPLE")} />
                        }
                    </div>
                </div>
            </div>

            <ModalSelect
                modalName={translator("GROUP.MODAL_NAME_ADD_PEOPLE")}
                label={translator("GROUP.SELECT_PEOPLE_LABEL")}
                placeholder={(<span className='text-xs font-normal'>{translator("GROUP.SELECT_PEOPLE_PLACEHOLDER")}</span>)}
                isOpenModal={modalAddPeople}
                closeMOdal={toggleModalAddPeople}
                options={OptionPeople}
                type="people"
                optionSelected={peopleSelected}
                handleSelectOption={handleSelectPeople}
                // handleSearchOption={handleSearchPeople}
                handleAddOption={handleAddPeople}
                preSelectedOption={defaultOptionPeople}
                placeholderOnFocus={(<div className='flex items-center'><SearchIcon className='w-4 h-4 mr-2' />  <span className='text-xs font-normal'>{translator("GROUP.SEARCH_PEOPLE_PLACEHOLDER_FOCUS")}</span></div>)}
            />

            <ModalCreate
                isOpen={openModalConfirmRemoveClass}
                onClose={toggleModalConfirmRemoveClass}
                title={"Confirmation"}
                styles=' rounded-[16px] p-6 '
            >
                <div className='mt-6'>
                    <p className=' text-xs font-normal text-center text-ooolab_dark_1'>{translator("GROUP.CONFIRM_REMOVE_CLASS")}</p>
                    <div className='flex justify-center mt-6 '>
                        <input
                            className='mr-2 '
                            type="checkbox"
                            checked={isRemoveAll}
                            onChange={(e) => setRemoveAll(e.target.checked)}
                        />{" "}
                        <p className='text-xs font-normal text-ooolab_dark_1'>{translator("GROUP.CONFIRM_REMOVE_ALL")}</p>
                    </div>
                </div>
                <div className='flex justify-center'>
                    <ButtonComponent
                        title={translator("GROUP.CANCEL")}
                        classStyle={classNames(
                            'w-fit justify-center rounded text-white mt-6 p-2 text-xs border border-ooolab_dark_1 text-ooolab_dark_1 mr-4'
                        )}
                        onClickButton={toggleModalConfirmRemoveClass}
                    >
                    </ButtonComponent>
                    <ButtonComponent
                        title={translator("GROUP.REMOVE")}
                        classStyle={classNames(
                            'w-fit justify-center rounded text-white mt-6  p-2 text-xs  bg-enterprise'
                        )}
                        onClickButton={handleRemoveClass}
                    >
                    </ButtonComponent>
                </div>
            </ModalCreate>

            <ModalCreate
                isOpen={openModalConfirmRemovePeople}
                onClose={toggleModalConfirmRemovePeople}
                title={translator("GROUP.CONFIRM")}
                styles=' rounded-[16px] p-6 '
            >
                <div className='mt-6'>
                    <p className=' text-xs font-normal text-center text-ooolab_dark_1'>{translator("GROUP.CONFIRM_REMOVE_PEOPLE")}</p>
                    
                </div>   
                <div className='flex justify-center mt-5 gap-5'>
                    <ButtonOutline
                        type="button"
                        onClick={toggleModalConfirmRemovePeople}
                    >
                        {translator("CANCEL")}
                    </ButtonOutline>
                    <ButtonPrimary
                        type={"button"}
                        onClick={() => handleRemovePeople()}
                    >
                        {translator("GROUP.REMOVE")}
                    </ButtonPrimary>
                </div>
            </ModalCreate>

            <ModalCreate
                isOpen={openModalError}
                onClose={toggleModalError}
                title={translator("GROUP.ERROR")}
                styles=' rounded-[16px] p-6 '
            >
                <div className='mt-6'>
                    <p className=' text-base font-semibold text-center text-red-500'>{translator("GROUP.ERROR")} Error</p>

                </div>
                <div className='mt-6'>
                    <p className=' text-xs font-normal text-center text-ooolab_dark_1 px-6'>{translator("GROUP.DUPLICATE")} </p>
                </div>
                <div className='flex justify-center'>
                    <ButtonPrimary
                        type="button"
                        onClick={toggleModalError}
                    >
                            {translator("GROUP.OK")}
                    </ButtonPrimary>
                </div>
            </ModalCreate>

        </form>
    );
};

export default GroupProfile;


