import React from 'react';
import { TableSkillAssociatedCourse } from './TableSkillAssociatedCourse';
import SearchInput from '_shared/components/SearchInput/SearchInput';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';

export const SkillAssociatedCourse = ({ setKeyword, loading, indexPage, fetchList, pagination, data = [] }) => {
    return (
        <div className="py-6 border-t border-gray-300">
            <div className="mb-3 flex justify-between items-center">
                <h1 className="text-base font-semibold">Associated Course(s)</h1>
                <SearchInput
                    className={`max-w-sm ${!data?.length ? 'bg-gray-50' : ''}`}
                    placeholder="Search a Course"
                    onChange={(value) => setKeyword(value)}
                />
            </div>
            <div className="h-[400px] overflow-auto relative">
                {loading ? (
                    <div className="loading h-full flex items-center justify-center">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <TableSkillAssociatedCourse data={data} />
                )}
            </div>
            {!!data?.length && <Pagination
                total={pagination.total}
                per_page={pagination.per_page}
                callback={(page) => fetchList(page)}
                indexPage={indexPage}
                page={pagination?.page}
            />}
        </div>
    );
};
