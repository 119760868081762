import React, { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { REMINDER_TYPE } from 'components/Workspace/Course/CourseSetting/components/Reminder/Reminder';
import { InputField } from 'components/InputForm/InputForm';
import { VALIDATE_FIELD } from 'utils/validate';
import workspaceService from 'services/workspace.service';
import { classNames, isEmptyNullUndefine } from 'utils/commonFun';

export const ModalAttendanceReminders = ({ notAllowEdit, onClose }) => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();

    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors, isValid, isDirty },
        setValue,
        getValues,
        watch,
        clearErrors,
        setError,
        reset,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            reminders: [],
        },
    });

    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'reminders',
    });

    const fetchSettingReminders = () => {
        workspaceService.getSettingWorkspaceReminder(params?.id).then((res) => {
            console.log(res?.data);
            reset?.({ reminders: res?.data || [] });
        });
    };

    const onSubmitForm = (values) => {
        setLoading(true);
        workspaceService
            .settingWorkspaceReminder(params?.id, values)
            .then(() => {
                Toastify.success();
                onClose?.();
            })
            .catch(() => Toastify.error())
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchSettingReminders();
    }, []);

    return (
        <form className="text-sm text-gray-800" onSubmit={handleSubmit(onSubmitForm)}>
            <div className="flex flex-col space-y-2">
                <p>{t('THIS_CONFIGURATION_IS_APPLIED_TO_ALL_COURSES')}</p>
                <p>{t('SET_THE_TIME_AFTER_THE_SESSION_END_TIME_TO_SEND_A_REMINDER_IF_ATTENDANCE_HASNT_BEEN_MARKED_MAXIMUM_5')}</p>
                {fields?.map((item, index) => (
                    <div key={item?.id} className="flex items-center justify-between px-4 py-2 rounded-lg bg-gray-50">
                        <div className="flex items-center space-x-2">
                            <p className={!!errors?.reminders?.[index] ? 'mb-[18px]' : ''}>
                                {index + 1}. {t('AFTER_SESSION_END')}:
                            </p>
                            <div
                                className={classNames(
                                    'flex items-center gap-1 truncate relative min-w-[98px]',
                                    errors?.reminders?.[index] ? 'min-h-[52px] !items-start' : ''
                                )}
                            >
                                <InputField
                                    name={`reminders.${index}.hours`}
                                    classNameInput="h-8 !w-16"
                                    className="whitespace-normal"
                                    labelHorizontal={t('HOUR_S')}
                                    type="number"
                                    register={register(`reminders.${index}.hours`, {
                                        validate: {
                                            minValue: (value) => {
                                                const watchHours = watch(`reminders.${index}.hours`);
                                                const watchMins = watch(`reminders.${index}.minutes`);
                                                const currentHours = value || 0;
                                                const currentMinutes = watchMins || 0;
                                                const isHoursValue = isEmptyNullUndefine(watchHours) || isNaN(watchHours);
                                                const isMinsValue = isEmptyNullUndefine(watchMins) || isNaN(watchMins);

                                                if (value > 1000) return t('FORM_CONST.MAX_LENGTH_FIELD', { number: 1000 });

                                                if (!VALIDATE_FIELD.NUMBER_.exec(value)) return t('FORM_CONST.NUMBER_ONLY');

                                                if (currentHours == 0 && currentMinutes == 0)
                                                    return t('TIME_MUST_BE_AT_LEAST_1_MINUTE');

                                                if (isEmptyNullUndefine(watchMins)) {
                                                    console.log('locked minutes')
                                                    setError(`reminders.${index}.minutes`, {
                                                        type: 'minValue',
                                                        message: t('FORM_CONST.MIMUTES')
                                                    });
                                                }
                                                
                                                if (!isHoursValue && !isMinsValue && currentMinutes < 60) {
                                                    clearErrors(`reminders.${index}.hours`);
                                                    clearErrors(`reminders.${index}.minutes`);
                                                }

                                                return true;
                                            },
                                        },
                                        valueAsNumber: true,
                                    })}
                                    errors={errors}
                                    isRequired
                                    disabled={notAllowEdit}
                                    isArray
                                />
                            </div>
                            <div
                                className={classNames(
                                    'flex items-center gap-1 truncate relative min-w-[98px]',
                                    errors?.reminders?.[index] ? 'min-h-[52px] !items-start' : ''
                                )}
                            >
                                <InputField
                                    name={`reminders.${index}.minutes`}
                                    classNameInput="h-8 !w-16"
                                    className="whitespace-normal"
                                    labelHorizontal={t('MINUTES')}
                                    type="number"
                                    register={register(`reminders.${index}.minutes`, {
                                        validate: {
                                            minValue: (value) => {
                                                const watchHours = watch(`reminders.${index}.hours`);
                                                const watchMins = watch(`reminders.${index}.minutes`);
                                                const currentHours = watchHours || 0;
                                                const currentMinutes = value || 0;
                                                const isHoursValue = isEmptyNullUndefine(watchHours) || isNaN(watchHours);
                                                const isMinsValue = isEmptyNullUndefine(watchMins) || isNaN(watchMins);

                                                if (!VALIDATE_FIELD.MUNUTES.exec(value)) return t('FORM_CONST.MIMUTES');

                                                if (currentHours == 0 && currentMinutes == 0) {
                                                    return t('TIME_MUST_BE_AT_LEAST_1_MINUTE');
                                                }

                                                if (isEmptyNullUndefine(watchHours)) {
                                                    setError(`reminders.${index}.hours`, {
                                                        type: 'minValue',
                                                        message: t('FORM_CONST.NUMBER_ONLY')
                                                    });
                                                }

                                                if (!isHoursValue && !isMinsValue && currentHours <= 1000) {
                                                    clearErrors(`reminders.${index}.hours`);
                                                    clearErrors(`reminders.${index}.minutes`);
                                                }

                                                return true;
                                            },
                                        },
                                        valueAsNumber: true,
                                    })}
                                    isRequired
                                    errors={errors}
                                    disabled={notAllowEdit}
                                    isArray
                                />
                            </div>
                        </div>
                        <button type="button" title={''} onClick={() => !notAllowEdit && remove(index)}>
                            <TrashIcon className="w-5 h-5 text-red-500 cursor-pointer" />
                        </button>
                    </div>
                ))}
                <ButtonOutline
                    type="button"
                    size="M"
                    className="buttonOutline--isPrimary w-fit"
                    disabled={fields?.length === 5}
                    onClick={() => {
                        append({ hours: null, minutes: null, event: REMINDER_TYPE.pending_session_attendance }, { shouldFocus: true });
                        const indexNew = fields?.length;
                        if (indexNew != 0) {
                            setError(`reminders.${indexNew}.hours`, {
                                type: 'minValue',
                                message: t('FORM_CONST.NUMBER_ONLY')
                            });
                            setError(`reminders.${indexNew}.minutes`, {
                                type: 'minValue',
                                message: t('FORM_CONST.MIMUTES')
                            });
                        }
                    }}
                >
                    <PlusIcon className="w-5 h-5" />
                    <span>{t('ADD_NOTIFICATION')}</span>
                </ButtonOutline>
            </div>
            <div className="flex justify-center mt-5 space-x-4">
                <ButtonOutline type="button" size="M" onClick={() => onClose?.()}>
                    {t('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary type="submit" size="M" disabled={!isValid || !isDirty || !fields?.length || loading}>
                    {t('SAVE')}
                </ButtonPrimary>
            </div>
        </form>
    );
};
