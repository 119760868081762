import { FC, useState, useEffect, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import { UserContext } from 'contexts/User/UserContext';
import workspaceService from 'services/workspace.service';
import './DetailsFilePopup.scss';
import { LessonInterface } from 'types/GetListOfWorkspace.type';
import Details from './Details';
import Materials from './Materials';
interface DetailsFilePopupProp {
    item: any;
}

const DetailsFilePopup: FC<DetailsFilePopupProp> = ({ item }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [customFields, setCustomFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [lessonDetail, setLessonDetail] = useState<LessonInterface>();
    const TAB = {
        DETAILS: 'details',
        MATERIALS: 'materials',
    };

    const [tab, setTab] = useState(TAB.DETAILS);

    const type = useMemo(() => {
        return item?.object_type;
    }, [item]);
    const subType = useMemo(() => {
        let result = type;
        if (type === 'image') {
            result = item?.sub_object_type?.split('.')[1];
        }
        return result;
    }, [item]);

    const isHidden = useMemo(() => {
        let check = false;
        switch (item?.object_type) {
            case LibraryTypeEnum.folder:
                check = true;
                break;
            case LibraryTypeEnum.h5p:
            case LibraryTypeEnum.lesson:
                break;
            default:
                break;
        }
        return check;
    }, [item]);

    const typeTags = useMemo(() => {
        switch (item?.object_type) {
            case LibraryTypeEnum.lesson:
                return 'lessons';
            case LibraryTypeEnum.h5p:
                return 'h5p';
            case LibraryTypeEnum.assignment:
                return 'exercises';
            default:
                return 'files';
        }
    }, [item]);

    const {
        userState: { result },
    } = useContext(UserContext);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));
    const getCustomFields = async (page: number) => {
        setIsLoading(true);
        try {
            const body = {
                order: 'desc',
                page: page,
                sort_by: 'created_at',
                skip_pagination: true,
            };
            let response: any;

            if (typeTags == 'h5p') {
                response = await workspaceService.getH5pCustomFieldService(params.id, item.object_id, body);
            } else {
                response = await workspaceService.getCustomFieldByTypeService(params.id, {
                    ...body,
                    type: typeTags,
                    id: item.object_id,
                });
            }
            if (response) {
                setCustomFields(response?.data);
            }
            setIsLoading(false);
        } catch {
            setIsLoading(false);
        }
    };

    const handleUpdateTag = async (value) => {
        setCustomFields(value);
        if (typeTags == 'h5p') {
            const custom_fields = value?.map((customField) => {
                return {
                    custom_field_id: customField?.id,
                    data: customField?.data,
                };
            });
            await workspaceService.upsertCustomfiledH5p(params.id, item.object_id, { custom_fields });
        } else {
            await workspaceService.updateCustomFieldByTypeService(params.id, {
                custom_fields: value.map((item) => ({ custom_field_id: item.id, ...item })),
                id: item.object_id,
                type: typeTags,
            });
        }
    };

    useEffect(() => {
        if (typeTags && item && item?.object_type != 'scorm') {
            getCustomFields(1);
        }
    }, [typeTags]);

    const arrayLocation = useMemo(() => {
        let arrFormat = [];
        if (item?.path?.length) {
            item?.path?.map((item) => {
                const format = {
                    name: item?.name,
                    classNames: '',
                };
                arrFormat.push(format);
            });
        }
        return arrFormat;
    }, [item?.path]);

    const getLesson = async () => {
        setIsLoading(true);
        const response = await workspaceService.getLessonDetail(params.id, item?.object_id);
        if (response) {
            setLessonDetail(response);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (!lessonDetail && tab === TAB.MATERIALS) getLesson();
    }, [tab]);

    return (
        <>
            {item?.object_type === LibraryTypeEnum.lesson && item?.sub_object_type !== 'scorm' && (
                <div className="lessonTab">
                    <div
                        onClick={() => setTab(TAB.DETAILS)}
                        className={`lessonTab-item ${tab === TAB.DETAILS ? 'lessonTab-active' : 'lessonTab-default'}`}
                    >
                        {translator('DETAILS')}
                    </div>
                    <div
                        onClick={() => setTab(TAB.MATERIALS)}
                        className={`lessonTab-item ${tab === TAB.MATERIALS ? 'lessonTab-active' : ' lessonTab-default'}`}
                    >
                        {translator('MATERIALS')}
                    </div>
                </div>
            )}
            {tab === TAB.MATERIALS ? (
                <Materials lesson={lessonDetail} isLoading={isLoading} />
            ) : (
                <Details
                    translator={translator}
                    item={item}
                    subType={subType}
                    isHidden={isHidden}
                    arrayLocation={arrayLocation}
                    result={result}
                    typeTags={typeTags}
                    customFields={customFields}
                    handleUpdateTag={handleUpdateTag}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default DetailsFilePopup;
