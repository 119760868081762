import { Menu, Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { Fragment } from 'react/jsx-runtime';
import SelectBox from 'components/V2/SelectBox';
import { useEffect, useState } from 'react';
import skillService from 'services/skill.service';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type ValueFilterSkillType = {
    skill_ids?: string,
    skill_level_ids?: string
    defaultSkill?: any
    hasReset?: boolean
    onChangeValue?: (value?: any) => void
}

export const SkillFilter = ({ hasReset, onChangeValue, defaultSkill }: ValueFilterSkillType) => {
    const { t: translate } = useTranslation();
    const [inputSearchSkill, setInputSearchSkill] = useState<string>();
    const [skillSelected, setSkillSelected] = useState([]);
    const [skillLevels, setSkillLevels] = useState([]);
    const [skillLevelsOriginal, setSkillLevelsOriginal] = useState([]);
    const [levelSelected, setLevelSelected] = useState([]);
    const [loading, setLoading] = useState(false);
    const params: { id: string } = useParams();
    const [pagination, setPagination] = useState<any>({ page: 1, per_page: 10, total: 0 });
    const [skillOptions, setSkillOptions] = useState([]);
    const [hasFilter, setHasFilter] = useState(false);
    const [inputSearchLevel, setInputSearchLevel] = useState<string>();

    useEffect(() => {
        if (defaultSkill?.id) {
            setSkillSelected([defaultSkill]);
            onChangeValue({
                skill_ids: JSON.stringify([defaultSkill?.value]),
                skill_level_ids: JSON.stringify(defaultSkill?.levels?.map(item => item.id)),
            })
            setLevelSelected(defaultSkill?.levels);
            setHasFilter(true);
        }
    }, [defaultSkill]);

    const getSkillOptions = (page = 1) => {
        setLoading(true);
        const payload = {
            ...pagination,
            page,
            order: 'asc',
            q: inputSearchSkill?.trim(),
            skip_pagination: true,
            sort_by: 'name',
            status: 'published'
        }
        skillService
            .getSkillsService(params.id, payload)
            .then((res) => {
                const formatSkill = res?.data?.items.map((item) => {
                    return {
                        ...item,
                        value: item?.id,
                        levels: item?.levels?.map((level) => {
                            return {
                                ...level,
                                name: level?.name,
                                value: level?.id,
                            };
                        }),
                    };
                });

                if (page === 1) setSkillOptions(formatSkill);
                else setSkillOptions([...skillOptions, ...formatSkill]);
                setPagination({ ...pagination, page: res?.data?.page, total: res?.data?.total });
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handleCheckSkillAll = () => {
        const skillNoSelect = skillOptions?.filter(item => !skillSelected.find(kill => kill.id == item.id));
        setSkillSelected([...skillSelected, ...skillNoSelect]);
    }

    const handleCheckLevelslAll = () => {
        const levelNoSelect = skillLevels?.filter(item => !levelSelected.find(level => level.id == item.id));
        setLevelSelected([...levelSelected, ...levelNoSelect]);
    }

    useEffect(() => {
        if (skillSelected?.length == 1) {
            const levels = skillSelected?.map(item => item?.levels)?.flat()?.map(level => (
                {
                    ...level,
                    name: level?.name,
                    value: level?.id
                }
            ));
            levels?.sort((a, b) => {
                if (a?.name > b.name) {
                    return 1;
                }
                return -1;
            })
            setSkillLevels(levels);
            setSkillLevelsOriginal(levels);
        } else {
            setLevelSelected([]);
        }

    }, [skillSelected]);

    useEffect(() => {
        getSkillOptions(1);
    }, [inputSearchSkill]);

    useEffect(() => {
        if (!inputSearchLevel) {
            setSkillLevels(skillLevelsOriginal);
        } else {
            const newLevels = skillLevelsOriginal?.filter(item => item?.name?.toLowerCase()?.includes(inputSearchLevel?.toLowerCase()));
            setSkillLevels(newLevels);
        }

    }, [inputSearchLevel]);

    useEffect(() => {
        if (hasReset) {
            setSkillSelected([]);
            setLevelSelected([]);
            setHasFilter(false);
        }
    }, [hasReset]);

    return (
        <Popover as="div" className="relative inline-block text-left z-10">
            {({ open, close }) => (
                <>
                    <Popover.Button className={`${open ? '!border-primary-500' : ''} ${(skillSelected?.length > 0 || levelSelected?.length > 0) && hasFilter ? 'border-blue-500 bg-blue-50 text-gray-800' : 'border-gray-300 text-gray-400'} flex items-center text-sm border rounded px-3 py-2 max-h-[38px]`}>
                        {translate('SKILL')}
                        {open ? <ChevronUpIcon className="w-3 h-3 text-gray-500 ml-2" /> : <ChevronDownIcon className="w-3 h-3 text-gray-500 ml-2" />}
                    </Popover.Button>
                    {open && (
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Popover.Panel className="absolute left-0 mt-2 w-[285px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                <>
                                    <div className='p-4'>
                                        <div className='mb-4'>
                                            <label className='text-xs  text-gray-800 mb-1'>{translate('SKILLS')}</label>
                                            <SelectBox
                                                label={translate('SELECT_SKILL(S)')}
                                                onSearch={setInputSearchSkill}
                                                onSelect={setSkillSelected}
                                                data={skillOptions}
                                                loading={loading}
                                                selectedItems={skillSelected}
                                                total={pagination?.total}
                                                showmore={() => getSkillOptions(pagination.page + 1)}
                                                styles={{ container: 'w-full' }}
                                                hasShowLabel={false}
                                                hasSelectAll={true}
                                                handleCheckAll={() => handleCheckSkillAll()}
                                            />
                                        </div>
                                        <div className='mb-0'>
                                            <label className='text-xs text-gray-800 mb-1'>{translate('LEVELS')}</label>
                                            <SelectBox
                                                label={translate('SELECT_LEVEL(S)')}
                                                onSearch={setInputSearchLevel}
                                                onSelect={setLevelSelected}
                                                data={skillLevels}
                                                loading={loading}
                                                selectedItems={levelSelected}
                                                total={skillLevels?.length}
                                                showmore={() => { }}
                                                styles={{ container: 'w-full' }}
                                                hasShowLabel={false}
                                                isDisabled={skillSelected?.length != 1}
                                                hasSelectAll={true}
                                                handleCheckAll={() => handleCheckLevelslAll()}
                                            />
                                        </div>
                                    </div>

                                    <div className='flex justify-between border-t border-gray-300 py-2 px-4'>
                                        <button type='button' className={`${skillSelected?.length == 0 && levelSelected?.length == 0 ? 'text-gray-500' : 'text-primary-500'}  text-sm`}
                                            disabled={skillSelected?.length == 0 && levelSelected?.length == 0}
                                            onClick={() => {
                                                setLevelSelected([]);
                                                setSkillSelected([]);
                                            }}
                                        >{translate('Clear selection')}</button>
                                        <button type='button'
                                            className={`disabled:bg-gray-100 disabled:text-gray-500 bg-primary-500 text-white text-sm rounded  px-2 py-[2px]`}
                                            onClick={() => {
                                                onChangeValue({
                                                    skill_ids: JSON.stringify(skillSelected?.map(item => item.id)),
                                                    skill_level_ids: JSON.stringify(levelSelected?.map(item => item.id)),
                                                })
                                                close();
                                                if (skillSelected?.length > 0) {
                                                    setHasFilter(true);
                                                } else {
                                                    setHasFilter(false);
                                                }
                                            }}
                                            disabled={(skillSelected?.length == 0 || skillSelected?.length == 1) && levelSelected?.length == 0 && !hasFilter}
                                        >{translate('APPLY')}</button>
                                    </div>
                                </>
                            </Popover.Panel>
                        </Transition>
                    )}
                </>
            )}
        </Popover>
    )
}