import { useState, useEffect, useCallback } from 'react';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import debounce from 'lodash/debounce';
import './SessionFilter.scss';
import { LearnerAndGroupSearch } from '_shared/components/LearnerAndGroupSearch/LearnerAndGroupSearch';
import { useLocation, useParams } from 'react-router-dom';
import { ATTENDANCE_TYPE, MARKED_ATTENDANCE, SESSION_STATUS } from 'constant/course.const';
import courseService from 'services/course.service';
import { useTranslation } from 'react-i18next';
import Select from 'components/Select/Select';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
    hasFilterLearner: boolean;
    learner_group_ids?: string;
    className?: string;
    showMarkedAttendance?: boolean;
}

export const SessionFilter = ({
    onChangeFilter,
    isFirstLoad,
    hasFilterLearner,
    learner_group_ids = null,
    className = '',
    showMarkedAttendance = false,
}: IFilter) => {
    const params: { id: string; courseId: string } = useParams();
    const location: any = useLocation();
    const { t } = useTranslation();

    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('');

    const [listInstructor, setListInstructor] = useState([]);
    const [instructorSelected, setInstructorSelected] = useState([]);
    const [sessionStatus, setSessionStatus] = useState<any>([]);
    const [attendanceType, setAttendanceType] = useState<any>([]);
    const [markedAttendance, setMarkedAttendance] = useState<any>();
    const [learningGroup, setLearningGroup] = useState<any>();
    const [pagination, setPagination] = useState<any>({
        page: 1,
    });

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    const getInstructor = async (page) => {
        let payload = {
            page: page ? page : pagination.page,
            per_page: 10,
            q: inputSearchInstructor,
            order: 'asc',
            sort_by: 'invited_at',
        };
        if (!payload?.q) delete payload.q;
        const response = await courseService.getInstructorEnrolled(params.id, params?.courseId, payload);
        if (response) {
            const formatedData = response?.data?.items?.map((item) => {
                return {
                    name: item?.instructor?.display_name,
                    id: item?.instructor?.id,
                    value: item?.instructor?.id,
                };
            });
            if (page === 1) setListInstructor(formatedData);
            else setListInstructor([...listInstructor, ...formatedData]);
            setPagination({ ...pagination, total: response?.data?.total, page });
        }
    };

    useEffect(() => {
        if (location?.state) {
            let filterValues: any = {};

            if (location?.state?.instructor_id) {
                setInstructorSelected(location?.state?.instructor_id);
                filterValues.instructor_id = location?.state?.instructor_id[0]?.value;
            }
            if (location?.state?.session_status) {
                setSessionStatus(location?.state?.session_status);
                filterValues.session_status = location?.state?.session_status?.map((i) => i?.value);
            }

            Object.keys(filterValues).length && updateFilter(filterValues);
        }
    }, []);

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                session_status: !!sessionStatus?.length ? sessionStatus?.map((i) => i?.value) : null,
                instructor_id: instructorSelected ? instructorSelected[0]?.value : '',
                session_attendance: !!attendanceType?.length ? attendanceType?.map((i) => i?.value) : null,
                marked_attendance: markedAttendance?.value,
            };
            if (learningGroup) {
                listFilter = {
                    ...listFilter,
                    ...learningGroup,
                };
            }
            if (!listFilter?.session_status) delete listFilter?.session_status;
            if (!listFilter?.session_attendance) delete listFilter?.session_attendance;
            if (!listFilter?.instructor_id) delete listFilter?.instructor_id;
            if (!listFilter?.learner_ids || listFilter?.learner_ids == '') delete listFilter?.learner_ids;
            if (!listFilter?.learning_group_ids || listFilter?.learning_group_ids == '') delete listFilter?.learning_group_ids;
            if (listFilter?.marked_attendance == undefined) delete listFilter?.marked_attendance;
            updateFilter(listFilter);
        }
    }, [sessionStatus, instructorSelected, learningGroup, attendanceType, markedAttendance]);

    useEffect(() => {
        getInstructor(1);
    }, [inputSearchInstructor]);

    return (
        <>
            <div className={`sessionFilter ${className}`}>
                {hasFilterLearner ? <LearnerAndGroupSearch onChangeFilter={setLearningGroup} /> : null}
                <SelectBox
                    label={t('INSTRUCTOR')}
                    isMulti={false}
                    onSearch={setInputSearchInstructor}
                    onSelect={setInstructorSelected}
                    data={listInstructor}
                    selectedItems={instructorSelected}
                    total={pagination?.total}
                    showmore={() => getInstructor(pagination?.page + 1)}
                />
                <SelectBox
                    label={t('STATUS')}
                    isMulti
                    onSelect={setSessionStatus}
                    data={SESSION_STATUS(t)}
                    selectedItems={sessionStatus}
                    total={Object.keys(SESSION_STATUS(t))?.length}
                />
                {!hasFilterLearner && !learner_group_ids && (
                    <SelectBox
                        label={t('COURSES.ATTENDANCE')}
                        isMulti
                        onSelect={setAttendanceType}
                        data={ATTENDANCE_TYPE(t)}
                        selectedItems={attendanceType}
                        total={Object.keys(ATTENDANCE_TYPE(t))?.length}
                    />
                )}
                {showMarkedAttendance && (
                    <Select
                        placeholder={t('COURSES.ATTENDANCE')}
                        options={MARKED_ATTENDANCE(t)}
                        value={markedAttendance}
                        onChange={setMarkedAttendance}
                        isClearable
                    />
                )}
            </div>
        </>
    );
};
