import { Switch } from '@headlessui/react';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Toast } from 'utils/sweetAlert';
import { LibraryTypeEnum } from 'components/Library/LibraryContants';
import fileServices from 'services/upload.service';
import h5pService from 'services/h5p.service';
import ButtonComponent from 'components/Button/ButtonComponent';
import DialogComponent from 'components/Dialog/DialogComponent'
import workspaceService from 'services/workspace.service';
import libraryService from 'services/library.service';
import { objectToBase64, utf8_to_b64 } from 'utils/commonFun';
interface IModal {
  isOpen: boolean;
  onClose: (id?: string) => void;
  link?: string;
  value?: any;
}

export default function ModalShareLink({ isOpen, onClose, link, value }: IModal) {
  const [isCheck, setCheck] = useState(false)
  const { t: translator } = useTranslation();
  const [linkSource, setLinkSource] = useState<string>(link);
  const [hasScorm, setHasScorm] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(linkSource);
    Toast.fire({
      icon: 'success',
      title: translator('LIBRARY.LIBRARY_LINK_COPIED')
    });
  }
  const { t } = useTranslation();

  const setBase64String = (obj, path = 'preview-library') => {
    objectToBase64(obj)
      .then(base64String => setLinkSource(`${window.location.origin}/${path}?info=${base64String}`))
      .catch(error => setLinkSource(`${window.location.origin}/${path}?info=${btoa(utf8_to_b64(JSON.stringify(obj)))}`));
  }

  useEffect(() => {
    setCheck(value?.is_shared);
    const getLibraryDetail = async () => {
      const response = await fileServices.getFile(value?.workspaceId, value?.object_id);
      const data = {
        id: response?.data?.id,
        name: response?.data?.name,
        object_id: response?.data?.object_id,
        object_type: response?.data?.object_type,
        file: response?.data?.file,
        sub_object_type: response?.data?.sub_object_type
      }
      setBase64String(data);
    }
    const getH5p = () => {
      h5pService.h5pEditPromise({
        workspaceId: value?.workspaceId,
        contentId: value?.object_id
      }).then((response: any) => {
        const data = {
          title: response?.metadata?.title || value?.name,
          uid: response?.uid
        }
        setBase64String(data, 'preview-library-h5p');
      });
    }

    const getLesson = () => {
      workspaceService.getLessonDetail(value.workspaceId, value.object_id).then((response: any) => {
        const data = {
          title: response?.title,
          uid: response?.uid,
          wsid: value?.workspaceId,
        }
        response?.sections?.learning_material?.forEach(learn => {
          learn?.section_contents?.forEach(section => {
            if (section?.library_content?.object_type == 'scorm') {
              setHasScorm(true);
            }
          })
        });
        response?.sections?.supplementary_material?.forEach(learn => {
          learn?.section_contents?.forEach(section => {
            if (section?.library_content?.object_type == 'scorm') {
              setHasScorm(true);
            }
          })
        });
        setBase64String(data, 'preview-library-lesson');
      });
    }

    if (value?.object_type != LibraryTypeEnum.h5p && value?.object_type != LibraryTypeEnum.lesson) {
      getLibraryDetail();
    }
    if (value?.object_type === LibraryTypeEnum.h5p) {
      if (value?.uid) {
        const data = {
          title: value?.metadata?.title || value?.name,
          uid: value?.uid
        };
        setBase64String(data, 'preview-library-h5p');
      } else {
        getH5p();
      }
    }
    if (value?.object_type === LibraryTypeEnum.lesson) {
      getLesson();
    }

  }, [value]);

  const handleSetShare = (is_shared) => {
    libraryService.setShareLink(value.workspaceId, value?.id, {
      is_shared
    })
  }

  return (
    <DialogComponent
      title={translator("LIBRARY.SHARE_LINK")}
      isOpen={isOpen}
      onCloseModal={() => onClose(value?.id)}
      stylesTitle="text-base"
      child={
        (
          <>
            <div className='flex items-center justify-between mt-6'>
              {hasScorm ? t('This lesson contains SCORM content and cannot be shared.') : <>
                <div>
                  <div className='text-sm font-semi'>{t('MODALS.SHARE_LINK.SHARE_TO_WEB')}</div>
                  <div className='text-xs font-normal text-gray-500'>{t('MODALS.SHARE_LINK.ANYONE_WITH_THE_LINK_CAN_VIEW')}</div>
                </div>
                <div>
                  <Switch
                    checked={isCheck}
                    onChange={(value) => {
                      handleSetShare(value);
                      setCheck(value);
                    }}
                    className={`${isCheck ? 'bg-primary-500' : 'bg-gray-400'}
                    relative inline-flex h-[20px] w-[36px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span
                      aria-hidden="true"
                      className={`${isCheck ? 'translate-x-4' : 'translate-x-0'}
                        pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
              </>}
            </div>

            {
              isCheck && !hasScorm && (
                <div className='mt-4 pt-4  border-t border-gray-300 flex gap-2'>
                  <div className='w-full rounded px-3 pt-2 pb-1 bg-gray-200 text-sm text-dark-100 truncate'>{linkSource}</div>
                  <ButtonComponent
                    title={t('MODALS.SHARE_LINK.COPY_LINK')}
                    onClickButton={copy}
                    classStyle='secondary-button w-28 !text-center'
                  />
                </div>
              )
            }
            <div className='flex justify-center'>
              <ButtonComponent
                title={hasScorm ? t('OK') : t('MODALS.CLOSE')}
                onClickButton={() => onClose(value?.id)}
                classStyle='secondary-button mt-6 !border-gray-300 !text-gray-800 mx-auto'
              />
            </div>
          </>
        )
      }
    />
  )
}
