import { Toastify } from '_shared';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uploadService from 'services/upload.service';
import uploadLearningGroup from 'assets/img/uploadLearningGroup.png';
import workspaceService from 'services/workspace.service';
import { Link, useParams } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { ListOrder } from 'assets/icon';
import { checkPermission } from 'utils/commonFun';

export default function LeftMenu({ groupData }) {
    const [file, setFile] = useState(undefined);
    const { t } = useTranslation();
    const params: { id; groupId } = useParams();

    const handleUpload = (e) => {
        const file = e?.target?.files?.[0];
        if (file) {
            if (['image/png', 'image/jpe', 'image/jpeg'].indexOf(file.type) === -1) {
                return Toastify.error(t('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED'));
            }
            if (+(file?.size / 1024).toFixed(2) > 1024 * 100) {
                return (Toastify.error = t('FORM_CONST.FILE_SIZE_LESS_THAN', { size: '100MB' }));
            }
            setFile(e?.target?.files?.[0]);
            uploadService.uploadFile(
                e?.target?.files?.[0],
                (result) => {
                    workspaceService
                        .updateLearningGroupService(params?.id, params?.groupId, {
                            avatar_url: process.env.REACT_APP_HOST_CDN + result,
                        })
                        .then((res) => {
                            Toastify.success();
                        })
                        .catch(() => Toastify.error());
                },
                () => { }
            );
        }
    };

    return (
        <div className="px-5">
            <div className="py-4  border-b border-gray-300">
                <div
                    onClick={(e) => {
                    }}
                    className="w-40 h-40 bg-gray-100 rounded-full mx-auto relative group cursor-pointer"
                >
                    {(file || groupData?.avatar_url) && (
                        <img
                            className="w-40 h-40 object-cover bg-gray-100 rounded-full mx-auto"
                            src={file ? URL.createObjectURL(file) : groupData?.avatar_url}
                            alt="avatar"
                        />
                    )}
                    <img
                        className={`m-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${file || groupData?.avatar_url ? 'hidden group-hover:block' : 'block'
                            }`}
                        src={uploadLearningGroup}
                        alt="avatar-upload"
                    />
                    <input
                        accept={'image/png, image/jpe, image/jpeg, application/pdf'}
                        onChange={handleUpload}
                        className="hidden"
                        type="file"
                        placeholder={''}
                    />
                    <div className="absolute whitespace-nowrap flex -bottom-2 w-fit left-1/2 -translate-x-1/2 px-3 py-2 text-xs font-semibold text-primary-500 rounded-lg bg-primary-50 capitalize">
                        {t('LEARNING_GROUP.LEARNING_GROUP')}
                    </div>
                </div>
            </div>
            <div className="py-5 overflow-auto">
                {checkPermission('learningGroupDetails', 'R') && <div className="courseSideLeft-item">
                    <InformationCircleIcon className="w-5 h-5 text-gray-500" />
                    <div className="courseSideLeft-item-content">
                        <div className="courseSideLeft-item-name">{t('LEARNING_GROUP.INFORMATION')}</div>
                        <Link
                            to={`/workspace/${params.id}/management/learning-groups/${params.groupId}?tab=information`}
                            className="courseSideLeft-item-link"
                        >
                            {t('LEARNING_GROUP.SET_UP_DEATIL_INFORMATION')}
                        </Link>
                    </div>
                </div>
                }
                {checkPermission('learningGroupDetails', 'R') && <div className="courseSideLeft-item">
                    <ListOrder className="w-5 h-5 text-gray-500" />
                    <div className="courseSideLeft-item-content">
                        <div className="courseSideLeft-item-name">{t('LEARNING_GROUP.ENROLLMENTS')}</div>
                        <Link
                            to={`/workspace/${params.id}/management/learning-groups/${params.groupId}?tab=enrollment`}
                            className="courseSideLeft-item-link"
                        >
                            {t('LEARNING_GROUP.VIEW_ENROLLMENT_LIST')}
                        </Link>
                    </div>
                </div>}


            </div>
        </div>
    );
}
