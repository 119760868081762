import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DownloadIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { ButtonPrimary } from '_shared';
import { handleDownloadCSVTable, tableCourses, TableNameType, tableSessions } from './downloadConstant';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import DatePickerComponent from 'components/Reports/components/DatePicker';
import { UserContext } from 'contexts/User/UserContext';
import downloadPageService from 'services/reports/download-page.service';
import propertyService from 'services/property.service';
import { checkPermission } from 'utils/commonFun';
import { flattenData } from 'components/WorkspaceSetting/Property/components/propertyCommon';

export const TabCoursesData = () => {
    const { t } = useTranslation();
    const params: { id: string } = useParams();

    const MAX_FILTER_DATE = 30; // startDate + 30 = 31 days

    const {
        userState: { result },
    } = useContext(UserContext);

    const [loadingDownloadCourse, setLoadingDownloadCourse] = useState<boolean>(false);
    const [loadingDownloadSession, setLoadingDownloadSession] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState([
        new Date(dayjs().startOf('M').format('YYYY-MM-DD')),
        new Date(dayjs().endOf('M').format('YYYY-MM-DD')),
    ]);
    const [propertyList, setPropertyList] = useState([]);

    const workspaceId = params.id;
    const [startDate, endDate] = dateRange;

    const translator = (str: string, opt?: any) => t(`DOWNLOAD_DATA.${str}`, opt);

    const fetchProperties = () => {
        const payload = {
            skip_pagination: true,
            order: 'asc',
            sort_by: 'created_at',
            object_type: 'course',
        };

        propertyService
            .get(workspaceId, payload)
            .then((res) => {
                const { items, page, per_page, order, total } = res.data;
                setPropertyList(flattenData(items));
            })
            .catch()
            .finally();
    };

    const onDownloadCsv = async (tableName: TableNameType) => {
        try {
            let data = [];
            let fileName = `${dayjs(startDate).format('DD-MM-YYYY')} to ${dayjs(endDate).format('DD-MM-YYYY')} (${result.time_zone != 'UTC' ? 'UTC' + dayjs().tz(result.time_zone).format('Z').replace(':00', '') : result.time_zone
                })`;
            let extendData = {};
            let setLoading: any = function () { };
            const start_time = dayjs(startDate).tz(result.time_zone).startOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ');
            const end_time = dayjs(endDate).tz(result.time_zone).endOf('day').format('YYYY-MM-DDTHH:mm:ss.sssZ');

            switch (tableName) {
                case 'courses': {
                    setLoadingDownloadCourse(true);
                    const response = await downloadPageService.getExportCourses(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Courses_Create Date ' + fileName;
                    extendData = { translator: t, tz: result?.time_zone || 'UTC', properties: propertyList };
                    setLoading = setLoadingDownloadCourse;
                    break;
                }
                case 'sessions': {
                    setLoadingDownloadSession(true);
                    const response = await downloadPageService.getExportSessions(workspaceId, {
                        start_time,
                        end_time,
                        skip_pagination: true,
                    });
                    data = response?.data?.items || [];
                    fileName = 'Session_Create Date ' + fileName;
                    extendData = { translator: t, tz: result?.time_zone || 'UTC' };
                    setLoading = setLoadingDownloadSession;
                    break;
                }
                default:
                    break;
            }

            handleDownloadCSVTable({
                data,
                table: tableName,
                fileName,
                extendData,
                setLoading,
            });
        } catch (error) {
            setLoadingDownloadCourse(false);
            setLoadingDownloadSession(false);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    return (
        <div className="p-5 bg-white rounded-2xl shadow-card_common">
            <div className="flex justify-between items-center">
                <h1 className="text-xl font-semibold">{translator('COURSES_DATA')}</h1>
                <div className="relative min-w-[230px]">
                    <DatePickerComponent
                        onChange={setDateRange}
                        startDate={startDate}
                        endDate={endDate}
                        filterDate={(date) => {
                            if (startDate) {
                                const maxEndDate = new Date(dayjs(startDate).add(MAX_FILTER_DATE, 'day').format('YYYY-MM-DD'));
                                if (date > maxEndDate) return false;
                            }
                            return true;
                        }}
                        className="!rounded !px-2.5"
                    />
                </div>
            </div>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('COURSE')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="COURSE_TOOLTIP"
                            data-tip={translator('COURSE_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="COURSE_TOOLTIP"
                            content={translator('COURSE_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportCourseDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadCourse}
                            onClick={() => onDownloadCsv('courses')}
                        >
                            {loadingDownloadCourse ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {[...tableCourses, ...propertyList].map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2 truncate" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
            <>
                <div className="my-4 border-b" />
                <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold flex items-center space-x-2">
                        <span>{translator('SESSION')}</span>
                        <InformationCircleIcon
                            className="w-5 h-5 cursor-pointer"
                            data-for="SESSION_TOOLTIP"
                            data-tip={translator('SESSION_TOOLTIP')}
                        />
                        <TooltipCustom
                            classNameContainer="shadow-medium"
                            id="SESSION_TOOLTIP"
                            content={translator('SESSION_TOOLTIP')}
                            place="bottom"
                            type="light"
                        />
                    </h2>
                    {checkPermission('exportCourseDashboard', 'DL') && (
                        <ButtonPrimary
                            type="button"
                            size="M"
                            disabled={loadingDownloadSession}
                            onClick={() => onDownloadCsv('sessions')}
                        >
                            {loadingDownloadSession ? (
                                <CircleSpin className="!m-0" />
                            ) : (
                                <DownloadIcon className="w-5 h-5 text-white" />
                            )}
                            <span>{translator('Download')}</span>
                        </ButtonPrimary>
                    )}
                </div>
                <div className="bg-gray-50 rounded-lg p-4 mt-3 text-sm font-semibold">
                    <p className="text-gray-500">{translator('Sample Data')}</p>
                    <div className="mt-2 overflow-auto">
                        <div className="flex items-center">
                            {tableSessions.map((item, index) => (
                                <div key={index}>
                                    <p className="bg-blue-50 px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.columnName}
                                    </p>
                                    <p className="bg-white font-normal px-2.5 py-2" style={{ width: `${item.width}px` }}>
                                        {item.value}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};
