import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { AsyncSelectField } from 'components/Select';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formatOptionsAssignee, formatOptionsInstructor } from '../utils';
import './ModalNewEnrollment.scss';
import { InputField } from 'components/InputForm/InputForm';
import classService from 'services/class.service';
import courseService from 'services/course.service';
import { ENROLLMENT_TYPE } from '../constants';
import { FORM_CONST } from 'constant/form.const';
import { useAppDispatch } from 'hooks/hooks';
import { updatePeopleNumber } from 'store/features/courseSlice';

export default function ModalInviteInstructor({ onCloseModal }) {
    const dispatch = useAppDispatch();
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        register
    } = useForm({mode: 'onChange'});
    const { t: translator } = useTranslation();

    const [detailsEnrollment, setDetailsEnrollment] = useState<any>({});

    const params: any = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;

    const fetchInstructors = async (payload?: any) => {
        try {
          const res = await classService.getTeacherListService(workspaceId, payload)
          return {
            items: res?.data?.items || [],
            total: res?.data?.total || 0,
          };
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const handleInstructorLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            sort_by: 'email',
            order: 'desc',
            q: inputValue || null,
        };
        try {
          const  { items, total } = await fetchInstructors(payload);
          const newOptions = items?.map((el: any) => ({
              ...el?.user,
              id: el.id,
              label: el.user?.email,
              value: el.user?.id,
          }));
  
          return {
              options: newOptions,
              hasMore: additional?.page < total / 20,
              additional: {
                  page: additional?.page + 1,
              },
          };
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const  fetchAssigneeList = async (payload?: any) => {
        try {
          const res = await courseService.getEnrollments(workspaceId, courseId, payload)
          return {
            items: res?.data?.items || [],
            total: res?.data?.total || 0,
          };
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const handleAsigneeLoadOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            sort_by: 'email',
            order: 'desc',
            statuses: JSON.stringify(["active", "enrolled"]),
            q: inputValue || null,
        };
        try {
          const { items, total } = await fetchAssigneeList(payload);
          const newOptions = items?.map((el: any) => ({
              ...el,
              id:  el?.learning_group_id || el?.learners?.[0]?.id,
              label: el?.email,
              value: el?.learning_group_id || el?.learners?.[0]?.id,
          }));
  
          return {
              options: newOptions,
              hasMore: additional?.page < total / 20,
              additional: {
                  page: additional?.page + 1,
              },
          };
        } catch (error) {
          console.error('Error:', error);
        }
    };

    const onSubmit = (value) => {
        const instructorData = {
            vc_link: value?.vc_link,
            location: value?.location,
            instructors: value?.instructors?.map(instructor => instructor?.value),
            assigned_to_learners: value?.assignee?.filter(item => item?.type === ENROLLMENT_TYPE.INDIVIDUAL)?.map(item => item?.id),
            assigned_to_learning_groups: value?.assignee?.filter(item => item?.type === ENROLLMENT_TYPE.LEARNING_GROUP)?.map(item => item?.id)
        }
        delete value?.assignee;
        if (!instructorData?.location) delete instructorData.location;
        if (!instructorData?.vc_link) delete instructorData.vc_link;
        if (!instructorData?.assigned_to_learning_groups?.length) delete instructorData.assigned_to_learning_groups
        if (!instructorData?.assigned_to_learners?.length) delete instructorData.assigned_to_learners

        courseService.inviteInstructor(workspaceId, params?.courseId, instructorData)
        .then((res) => {
            if (res.data?.error) {
                return Toastify.error((res.data?.error?.body_params?.[0]?.msg));
            }
            Toastify.success(res.data?.message);
            courseService.getCountPeople(params.id, params.courseId).then((response: any) => {
                dispatch(updatePeopleNumber(response?.data));
            })
            onCloseModal(true);
        })
        .catch((error) => Toastify.error())
    }

    return (
        <form className="ModalNewEnrollment">
            <AsyncSelectField
                control={control}
                label={translator('COURSES.INSTRUCTORS')}
                name="instructors"
                placeholder={translator('COURSES.SELECT_INSTRUCTORS')}
                isRequired
                hideSelectedOptions
                closeMenuOnSelect={true}
                errors={errors}
                loadOptions={handleInstructorLoadOptions}
                formatOptionLabelCustom={formatOptionsInstructor}
                isMulti
            />

            <div className="field">
                <InputField 
                    label={translator('COURSES.VC_LINK')}
                    placeholder={translator('COURSES.ENTER_VC_LINK')}
                    name='vc_link'
                    errors={errors}
                    register={register('vc_link', {
                        pattern: {
                            value: FORM_CONST.URL_VALIDATE,
                            message: translator('COURSES.VALIDATE_URL'),
                        },
                        maxLength: {
                            value: 255,
                            message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                        },
                    })}
                />
            </div>

            <div className="field">
                <InputField 
                    label={translator('COURSES.LOCATION')}
                    placeholder={translator('COURSES.ENTER_LOCATION')}
                    name='location'
                    errors={errors}
                    register={register('location', {
                        maxLength: {
                            value: 255,
                            message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                        },
                    })}
                />
            </div>

            <div className="field">
              <AsyncSelectField
                    control={control}
                    label={translator('COURSES.ASSIGNED_TO')}
                    name="assignee"
                    placeholder={translator('COURSES.SELECT_LEARNER_LEARNING_GROUP')}
                    hideSelectedOptions
                    closeMenuOnSelect={true}
                    errors={errors}
                    loadOptions={handleAsigneeLoadOptions}
                    formatOptionLabelCustom={formatOptionsAssignee}
                    isMulti
                />
            </div>

            <div className="field group-button">
                <ButtonOutline
                    type="Button"
                    onClick={async () => {
                        await setDetailsEnrollment({});
                        onCloseModal();
                    }}
                >
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary type="button" onClick={handleSubmit(onSubmit)} disabled={!isValid}>
                    {translator('ADD')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
