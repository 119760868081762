import { useState, useEffect } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './Filter.scss';
import { STATUS_LEARNER_ENROLL_OPTION } from '../../constants';
import { useTranslation } from 'react-i18next';
import { SkillFilter, ValueFilterSkillType } from 'components/Skills/SkillFilter';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';

interface IFilter {
    onChangeFilter: (value: any) => void;
}

const Filter = ({
    onChangeFilter,
}: IFilter) => {
    const {t} = useTranslation();
    const query = useQueryParamsURL();
    const statusQuery = query.get('status') || query.get('statuses');

    const [keyword, setKeyword] = useDebouncedState('', 0);

    const [statusSelected, setStatusSelected] = useState([]);
    const [skillsFilter, setSkillsFilter] = useState<ValueFilterSkillType>();

    useEffect(() => {
        let listFilter: any = {
            q: keyword,
            statuses: JSON.stringify(statusSelected?.map(status => status?.value)),
            ...skillsFilter,
        };
        if (!listFilter.q) delete listFilter.q
        if (!statusSelected?.length || statusSelected.length === 4) delete listFilter.statuses;
        onChangeFilter(listFilter);
    }, [ keyword, statusSelected, skillsFilter]);

    useEffect(() => {
        if (statusQuery) {
            const status = statusQuery?.split(',');
            const statusList = [];
            STATUS_LEARNER_ENROLL_OPTION(t).forEach(element => {
                if (status.find(el => el === element.value)) statusList.push(element);
            });
            setStatusSelected(statusList);
        }
    }, [statusQuery]);

    return (
        <div className="FilterEnrollment">
            <SearchBox placeholder={t("Search by Learner Name, Enrollment ID")} className="FilterEnrollment-searchInput !max-w-[22rem]" onSubmit={(value) => setKeyword(value)} />
            <SelectBox
                label={t("STATUS")}
                onSelect={setStatusSelected}
                selectedItems={statusSelected}
                data={STATUS_LEARNER_ENROLL_OPTION(t)}
                total={4}
                isMulti
            />
            <SkillFilter onChangeValue={(value: any) => setSkillsFilter(value)} />
        </div>
    );
};

export default Filter;
