export const COURSE_STATUS = {
    "inactive": "Deactivate",
    "published": "Pushlished",
    "active": "Activate",
}

export const COURSE_SETTING_TAB = {
    "course": "Course",
    "scheduling": "Scheduling",
    "enrollmentType": "Enrollment Type",
    "assignment": "Assignment",
    "gradebook": "Gradebook",
    "certificate": "Certificate",
    "reminder": "Reminder"
}

export const SESSION_TYPE = [
    { name: 'Scheduled', value: 'scheduled' },
    { name: 'Learner Booking', value: 'learner_booking' },
    { name: 'Admin Booking', value: 'admin_booking' }
]

export const SESSION_STATUS = (t) => [
    { name: t('SESSION.UPCOMING'), value: 'not_started' },
    { name: t('SESSION.ON_GOING'), value: 'in_progress' },
    { name: t('SESSION.COMPLETED'), value: 'completed' },
    { name: t('SESSION.CANCELLED'), value: 'cancelled' },
];

export const ATTENDANCE_TYPE = (t) => [
    { name: t('SESSION.NO_VALUE'), value: 'not_available' },
    { name: t('SESSION.ABSENT'), value: 'absent' },
    { name: t('SESSION.PRESENT'), value: 'present' },
    { name: t('SESSION.LATE'), value: 'late' },
    { name: t('SESSION.CANCELLED'), value: 'cancelled' },
];

export const MARKED_ATTENDANCE = (t) => [
    { label: t('Marked'), value: true },
    { label: t('Unmarked'), value: false },
];

export const SESSION_TYPE_EMUN = {
    "scheduled": "Scheduled",
    "learner_booking": "Learner Booking",
    "admin_booking": "Admin Booking"
}

export const LEARNER_TYPE = [
    { label: 'Individual', value: 'individual', display_name: 'Learner' },
    { label: 'Learning Group', value: 'learning_group', display_name: 'Learning Group' },
]

export const LEARNER_TYPE_ENUM = {
    'individual': 'individual',
    'learning_group': 'learning_group'
}


export const ASSIGNMENT_STATUS = (t) => ({
    'in_progress': t('ASSIGNMENT_CENTER.IN_PROGRESS'),
    'waiting_for_grade': t("ASSIGNMENT_CENTER.WAITING_FOR_GRADE"),
    'overdue': t("ASSIGNMENT_CENTER.OVERDUE"),// not use
    'completed': t("ASSIGNMENT_CENTER.COMPLETED"),
    'assigned': t("ASSIGNMENT_CENTER.ASSIGNED"),
    'not_started': t('NOT_STARTED'),
    'cancelled': t('Cancelled'),
})
export const CURRENCY = [
    { label: 'USD', value: 'usd' }
]

export enum STATUS_PAYMENT {
    activated,
    deactivated
}