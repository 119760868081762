import React from "react";
import { ArrowDownRight, CircleInformationIcon } from "assets/icon";
import { formatThousand, getValueOrSign } from "utils/commonFun";
import { InformationCircleIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import { TooltipCustom } from "components/Tooltip/Tooltip";
export interface CardInfoProps {
    title?: string;
    value?: number;
    percent?: number;
    day?: number | string;
    hasPercent?: boolean;
    description?: string;
    content?: string;
    className?: string
}
export const CardInfo = ({ title, value, percent, day, hasPercent, description, content, className = '' }: CardInfoProps) => {
    return <>
        <div className={`flex flex-col rounded-lg border border-gray-200 p-5 bg-white h-full ${className}`}>
            <div className='flex items-center gap-1 max-w-sm mb-1.5'>
                <h3 className="text-sm text-gray-800">{title}</h3>
                {content && (
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for={`user-${title}`}
                        data-tip={content}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                            id={`user-${title}`}
                            content={content}
                            place="top"
                            type="light"
                        />
                    </div>
                )}
            </div>
            <div className="flex space-x-2 mt-auto">
                <div className="text-gray-800 text-4xl">
                    {hasPercent ? `${getValueOrSign(value)}` : formatThousand(value)}
                </div>
                {percent != null && day && <div className={`flex text-base rounded items-center px-0.5 h-[24px] mt-1
                    ${percent > 0 ? 'bg-green-100 text-green-600' : percent === 0 ? 'bg-gray-100 text-gray-500 ' : 'bg-red-100 text-red-500'}
                `}>
                    {day ? <><span className={`${percent > 0 ? 'rotate-[265deg] px-[2px] inline-block' : ''}`}>
                        {percent != 0 ? <ArrowDownRight /> : ''}
                    </span> {Math.abs(+percent) || 0}%</> : ''}
                </div>}
            </div>
            {description && <div className="text-[10px] text-gray-500">{description}</div>}
        </div>
    </>
}