import React, { Fragment, ReactNode, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ArrowLeftIcon, DownloadIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { downloadURI, downloadURL } from 'utils/commonFun';
import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { IframePdfViewer } from '_shared/PreviewContent/IframePDFViewer';
import VideoPlayer from '_shared/components/VideoPlayer/VideoPlayer';
import { hasCheckedStream } from 'constant/file.constant';
import { checkFileType } from 'components/Library/components/FIlterSearch/uitils';
import { Toastify } from '_shared/components';
import resourcesService from 'services/resources.service';
import { useParams } from 'react-router-dom';

interface DocumentPreviewProps {
    closeModal: () => void;
    isOpen: boolean;
    data: any;
    emptyDataCustom?: ReactNode;
}

export const PreviewContentFullScreen = ({ data, isOpen, closeModal, emptyDataCustom }: DocumentPreviewProps) => {
    const { t: translator } = useTranslation();
    const [downloading, setDownloading] = useState(false);
    const params: any = useParams()

    const download = () => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        downloadURI(data?.file_url, data?.file_name, () => {
            setDownloading(false);
        });
    };

    const downloadVideo = (data) => {
        if (downloading) {
            return;
        }
        setDownloading(true);
        resourcesService.getDownloadVideo(params?.id, {
            key: data?.file_key,
        })?.then((res) => {
            if (res?.data?.url) {
                downloadURL(res?.data?.url, data?.name);
                setDownloading(false);
            } else {
                Toastify.error()
                setDownloading(false);
            }
        }).catch((err) => {
            const errorMessage = err?.response?.data?.description || 'The file is not ready for download yet.'
            Toastify.error(errorMessage);
            setDownloading(false);

        });

    }

    const renderUI = (mimeType: string) => {
        let element: ReactNode = (
            <div className="flex items-center justify-center h-[calc(100vh-120px)]">
                <div className="bg-gray-600 rounded-lg text-center text-white px-6 py-4 text-lg w-[480px]">
                    {translator("LIBRARY.COULDN'T_PREVIEW_FILE")}
                </div>
            </div>
        );

        if (!data || data?.unavailable) return emptyDataCustom || element;

        switch (mimeType) {
            case 'jpeg':
            case 'png':
            case 'svg':
            case 'image/jpeg':
            case 'image/png':
            case 'image/svg+xml':
                element = (
                    <>
                        <img src={data?.file} alt={data?.name} className="h-full m-auto" />
                    </>
                );
                break;
            case 'txt':
            case 'text/plain':
                break;
            case 'pdf':
            case 'application/pdf':
                element = (
                    <>
                        <IframePdfViewer url={data?.file} />
                    </>
                );
                break;
            case 'csv':
            case 'doc':
            case 'docx':
            case 'ppt':
            case 'pptx':
            case 'xls':
            case 'xlsx':
            case 'text/csv':
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                element = (
                    <>
                        <iframe
                            className="document w-full h-full"
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${data?.file}`}
                        />
                    </>
                );
                break;
            case 'zip':
            case 'zip2':
            case 'rar':
            case 'application/zip':
            case 'application/x-zip-compressed':
            case 'application/vnd.rar':
                break;
            case 'drive_folder':
            case 'application/vnd.google-apps.folder':
                break;
            case 'h5p':
            case 'application/h5p':
                break;
            case 'mp3':
            case 'audio/mpeg':
                element = (
                    <div className="h-full flex items-center">
                        <audio controls src={data?.file} className="w-full"></audio>
                    </div>
                );
                break;
            case 'mp4':
            case 'quicktime':
            case 'mkv':
            case 'avi':
            case 'ms_avi':
            case 'video_webm':
            case 'm4v':
            case 'ts':
            case 'mts':
            case 'avchd':
            case 'hevc':
            case 'flv':
            case 'mxf':
            case 'video_3gp':
            case 'mpg':
            case 'video/mp4':
            case 'video/quicktime':
            case 'video/x-matroska':
            case 'video/avi':
            case 'video/x-msvideo':
            case 'video/webm':
            case 'video/x-m4v':
            case 'video/mp2t':
            case 'video/hevc':
            case 'video/x-flv':
            case 'application/mxf':
            case 'video/3gpp':
            case 'video/mpeg':
                element = hasCheckedStream(data?.file_url) ? (
                    <VideoPlayer data={data} color={'text-gray-800'} />
                ) : (
                    <iframe title="iframe-video" className="document w-full h-full" src={data?.file_url}></iframe>
                );
                break;
            default:
                break;
        }

        return element;
    };

    const icon = useMemo(() => LIBRARY_ICONS(translator)?.find((item) => item?.value === data?.object_type)?.icon, [data]);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[999]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full justify-center text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full transform transition-all">
                                    <div className="flex items-center justify-between space-x-2 py-4 px-5">
                                        <p className="flex items-center truncate">
                                            <ArrowLeftIcon className="w-7 h-7 mr-2 cursor-pointer text-white" onClick={closeModal} />
                                            {!!icon && <img src={icon} className="w-auto h-5 mr-2" alt="icon" />}
                                            <span className="text-sm font-semibold text-white inline-block truncate">
                                                {data?.name}
                                            </span>
                                        </p>
                                        <button
                                            className={`flex-none outline-none ${!data || data?.unavailable ? 'hidden' : ''}`}
                                            onClick={() => { checkFileType(data?.type || data?.file_mime_type) === 'video' ? downloadVideo(data) : download() }}
                                        >
                                            {downloading ? <CircleSpin /> : <DownloadIcon className="w-6 h-6 text-white" />}
                                        </button>
                                    </div>
                                    <div className="max-w-[80%] m-auto pb-[60px] h-[calc(100vh-60px)]">
                                        {renderUI(data?.file_mime_type)}
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};
