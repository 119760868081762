import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useParams } from 'react-router-dom';
import '_shared/components/Calendar/Calendar.scss';
import { UserContext } from 'contexts/User/UserContext';
import AloneSession from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/AloneSession';
import MultipleSession from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/MultipleSession';
import { FilterCalendar } from './SessionFilter/FilterCalendar';
import { WorkspaceService } from 'services';
import { SessionServiceFormat } from 'constant/util.const';
import ModalSessionDetail from 'components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/ModalSessionDetail/ModalSessionDetail';
import { useTranslation } from 'react-i18next';
import enLocale from '@fullcalendar/core/locales/en-gb';
import viLocale from '@fullcalendar/core/locales/vi';
import frLocale from '@fullcalendar/core/locales/fr';

const headerToolbar = {
    left: 'today prev,next title',
    right: 'timeGridDay,timeGridWeek,dayGridMonth',
};

export const ManagementCalendar = () => {
    const params = useParams<{ id: string }>();

    const [isFirstLoad, setFirstLoad] = useState(true);
    const [viewType, setViewType] = useState('');
    const [loading, setLoading] = useState(false);
    const [paramsCalendar, setParamsCalendar] = useState<any>(null);
    const [eventsCalendar, setEventsCalendar] = useState<any[]>();
    const [sessionDetail, setSessionDetail] = useState<any>(null);
    const [isOpenModalViewSessionDetail, setOpenModalViewSessionDetail] = useState(false);
    const [filter, setFilter] = useState<any>(null);
    const {t, i18n} = useTranslation();
    const lang = i18n.language;

    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params?.id;

    const fetchSessions = (params?: any) => {
        if (loading) return;

        setLoading(true);

        const payload = {
            skip_pagination: true,
            ...params,
            session_status: ['active'],
        };

        WorkspaceService.getSessionsWorkspace(workspaceId, payload)
            .then((res) => {
                setEventsCalendar(
                    res?.data?.items?.map((item) => {
                        return {
                            ...item,
                            start: dayjs(item?.start_time).tz(result?.time_zone).format(SessionServiceFormat),
                            end: dayjs(item?.end_time).tz(result?.time_zone)?.format(SessionServiceFormat),
                            initData: item,
                        };
                    }) || []
                );
                if (isFirstLoad) setFirstLoad(false);
            })
            .finally(() => setLoading(false));
    };

    const renderDayHeaderContent = ({ date, isToday, text, view, ...props }) => {
        if (view.type === 'dayGridMonth') return <span className="text-gray-500">{dayjs(date).locale(lang)?.format('ddd')}</span>;
        return (
            <>
                <p className="text-gray-500">{dayjs(date).locale(lang)?.format('ddd')}</p>
                <p className={`header-day ${isToday ? 'header-today' : ''}`}>{dayjs(date)?.locale(lang)?.format('DD')}</p>
            </>
        );
    };

    useEffect(() => {
        !!paramsCalendar && fetchSessions({ ...paramsCalendar, ...filter });
    }, [paramsCalendar, filter]);

    return (
        <>
            <div className="flex items-center justify-between px-5 py-[18px] text-gray-800">
                <h1 className="font-semibold text-xl">{t("SESSION.CALENDAR")}</h1>
                <FilterCalendar onChangeFilter={setFilter} isFirstLoad={isFirstLoad} />
            </div>
            <div className="Calendar Calendar-sessions">
                {/* @ts-ignore */}
                <FullCalendar
                    allDaySlot={false}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={headerToolbar}
                    eventClick={(data) => {
                        setSessionDetail([data?.event]);
                        setOpenModalViewSessionDetail(true);
                    }}
                    events={eventsCalendar}
                    eventContent={(eventInfo) => {
                        return (
                            <AloneSession
                                id={eventInfo?.event?.id}
                                startTime={eventInfo?.event?.extendedProps?.start_time}
                                endTime={eventInfo?.event?.extendedProps?.end_time}
                            />
                        );
                    }}
                    locale={lang === 'en' ? enLocale : lang === 'vi' ? viLocale : frLocale}
                    dayMaxEvents={1}
                    forceEventDuration={true}
                    moreLinkContent={(content: any) => {
                        if (content?.num === 0) return;
                        return <MultipleSession numberOfSession={content?.num} />;
                    }}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        omitZeroMinute: false,
                        meridiem: false,
                        hour12: false,
                    }}
                    timeZoneParam={result?.time_zone}
                    dayHeaderFormat={{ weekday: 'short' }}
                    dayHeaderContent={renderDayHeaderContent}
                    moreLinkClick={(value: any) => {
                        setSessionDetail(value?.allSegs?.map((item) => item?.event));
                        setOpenModalViewSessionDetail(true);
                    }}
                    timeZone={result?.time_zone}
                    datesSet={(arg) => {
                        const { startStr, endStr, view } = arg;
                        setParamsCalendar({
                            start_after: encodeURIComponent(dayjs(startStr).format()),
                            end_before: encodeURIComponent(dayjs(endStr).format()),
                        });
                        setViewType(view?.type);
                    }}
                    nowIndicator
                    slotMinTime="06:00:00"
                    slotMaxTime="30:00:00"
                />
            </div>
            {isOpenModalViewSessionDetail && (
                <ModalSessionDetail
                    sessions={sessionDetail}
                    isOpen={isOpenModalViewSessionDetail}
                    refreshData={() => fetchSessions({ ...paramsCalendar, ...filter })}
                    onClose={() => {
                        setOpenModalViewSessionDetail(false);
                        setSessionDetail(undefined);
                    }}
                />
            )}
        </>
    );
};
