import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import "./index.scss";

declare var window;

const ScormLoader = ({ launchScorm, onCallBack }) => {
    const params: { id: string, scormId: string } = useParams();
    const [LMSInitialize, setLMSInitialize] = useState(false);
    const iframeRef = useRef(null);
    const history = useHistory();
    const initialScorm = () => {
        const setting = {
            asyncCommit: true,
            "autocommit": true,
            "autocommitSeconds": 60,
            "dataCommitFormat": "json",
            "commitRequestDataType": "application/json;charset=UTF-8",
            "autoProgress": false,
            "logLevel": 1,
            "lmsCommitUrl": launchScorm?.integration?.track_url,
            responseHandler: (res) => {
                console.log('responseHandler', res)
            }
        }
        /*@ts-ignore*/
        window.API = new Scorm12API(setting);
        const dataFromLms = {
            cmi: launchScorm?.integration.cmi
        };
        window.API.loadFromJSON(dataFromLms, '');
        window.API.on("LMSInitialize", (value) => {
            setLMSInitialize(true);
        });
        window.API.on("LMSFinish", function (value) {
            onCallBack();
        });
        window.API.on('LMSSetValue.cmi.*', function (CMIElement, value) {
            console.log('LMSFinish', value);
            setTimeout(() => {
                onCallBack();
            }, 100);
            if (CMIElement == 'cmi.core.exit') {
                //history.push(`/workspace/${params?.id}/library?tab=library`);
            }
        });
    }
    useEffect(() => {
        if (launchScorm) {
            initialScorm();
        }
        return () => {
            if (LMSInitialize) {
                window.API.off("LMSInitialize", () => { });
            }
        }
    }, [launchScorm]);

    return (
        <iframe
            ref={iframeRef}
            src={launchScorm?.integration?.launch_url}
            title="SCORM Content"
            className="scorm-iframe"
        />
    );
};
export default ScormLoader;
