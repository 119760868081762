import React from 'react'

export default function AddNoteIcon({className = ''}) {
  return (
    <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7637_43655)">
    <path d="M14.8742 9.84002L14.285 9.25086L6.035 17.5009H2.5V13.965L11.9275 4.53669L16.6417 9.25086C16.7979 9.40713 16.8857 9.61905 16.8857 9.84002C16.8857 10.061 16.7979 10.2729 16.6417 10.4292L10.75 16.3217L9.57083 15.1434L14.8742 9.84002ZM13.1067 8.07252L11.9275 6.89419L4.16667 14.655V15.8342H5.345L13.1067 8.07252ZM15.4633 2.18002L17.8208 4.53669C17.9771 4.69296 18.0648 4.90489 18.0648 5.12586C18.0648 5.34683 17.9771 5.55875 17.8208 5.71502L16.6417 6.89419L13.1067 3.35836L14.285 2.18002C14.4413 2.0238 14.6532 1.93604 14.8742 1.93604C15.0951 1.93604 15.3071 2.0238 15.4633 2.18002Z" fill="#0071CE"/>
    </g>
    <defs>
    <clipPath id="clip0_7637_43655">
    <rect width="20" height="20" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  )
}
