import { FC, useContext, useMemo } from 'react';
import { CourseSubtab, CourseTab, CourseType, CourseViewType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { CircleInformationIcon } from 'assets/icon';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
}

const GradebookListOverviewTable: FC<IViewTable> = ({ data, refreshData }) => {
    const { t: translator } = useTranslation();
    const query = useQueryParamsURL();
    const { redirectToUrlCourse } = useUrlCourseNavigation();
    
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const {
        userState: { result },
    } = useContext(UserContext);

    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const tab = query.get('tab') as CourseTab;
    const subtab = query.get('subtab') as CourseSubtab;
    const enrollmentId = query.get('enrollmentId');
    const learnerId = query.get('learnerId');
    const learningGroupId = query.get('learningGroupId');
    const view = query.get('view') as CourseViewType;
    const viewGradebook = query.get('viewGradebook') as CourseViewType;

    const getValue = (value) => {
        if (value == null) {
            return '-';
        }
        return value + '%';
    }

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('LEARNER')} />,
                accessor: 'Learner/Learning Group',
                Cell: ({ row }: any) => {
                    const learner = row?.original?.user || row?.original?.learner;
                    const learningGroupId = row?.original?.learning_group?.id;
                    // const enrollmentId = query.get('enrollment_id') || query.get('enrollmentId') || null;
                    // const enrollmentQuery = enrollmentId ? `&enrollment_id=${enrollmentId}` : '';
                    return (
                        <div
                            className="flex gap-1 text-sm text-gray-800 ml-2 cursor-pointer"
                            onClick={() => {
                                // history.push(`/workspace/${params?.id}/management/courses/${params?.courseId}?tab=gradebook&
                                // ${learningGroupId}&subtab=assignment&isGroup=true${enrollmentQuery}` + `&isLearningGroup=${learningGroupId || null}`)
                                const viewPeople = view ? { view } : {};

                                redirectToUrlCourse({
                                    tab,
                                    subtab,
                                    learningGroupId,
                                    ...viewPeople,
                                    viewGradebook: 'assignment',
                                    learnerId: learner?.id,
                                });
                            }}
                        >
                            <div className='flex gap-2 items-center'
                                data-tip={learner?.name || learner?.display_name}
                                data-for={row?.original?.id + '_show-more-people'}>
                                <div
                                    className={`flex-none w-7 h-7 rounded-full overflow-hidden bg-cover`}
                                    style={{ backgroundImage: `url('${row?.original?.learning_group ? `https://ui-avatars.com/api?name=${learner?.name}&size=128&format=png` : learner?.avatar_url}')` }}
                                ></div>
                                <div className='grid'>
                                    <div className='truncate text-primary-500 font-semibold text-sm'>{learner?.name || learner?.display_name}</div>
                                </div>
                                {
                                    //@ts-ignore
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id={row?.original?.id + '_show-more-people'}
                                        getContent={(content) => <div>{content}</div>}
                                    />
                                }
                            </div>
                        </div>
                    );
                },
                width: "180"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.OVERALL_GRADE')} className="!justify-center" />,
                accessor: 'overall',
                Cell: ({ row }: any) => <RenderTableCell className='text-center bg-gray-100' value={getValue(row?.values?.overall)} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ATTENDANCE')} className="!justify-center" />,
                accessor: 'attendance',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.attendance)} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COURSE_COMPLETION')} className="!justify-center" />,
                accessor: 'course_completion',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.course_completion)} />,
                width: "140"
            },
            {
                Header: () => <div className='flex items-center gap-1 max-w-sm '>
                    <RenderTableHeader value={translator('COURSES.ASSIGNMENT_GRADE')} className="!justify-center" />
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                        data-tip={translator('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                            id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                            content={translator('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                            place="top"
                            type="light"
                        />
                    </div>
                </div>,
                accessor: 'assignment_score',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.assignment_score)} />,
                width: "150"
            },
            {
                Header: () => <RenderTableHeader value={translator('ASSIGNMENT_COMPLETION')} className="!justify-center" valueTooltips={translator('TOOLTIPS_ASSIGNMENT_COMPLETION')} />,
                accessor: 'assignment_completion',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={row?.values?.assignment_completion ? row?.values?.assignment_completion + '%' : '-'} />,
                width: "180"
            },
            {
                Header: () => <RenderTableHeader value={translator('ASSIGNMENT_ON_TIME')} className="!justify-center" valueTooltips={translator('TOOLTIPS_ASSIGNMENT_ON_TIME')} />,
                accessor: 'assignment_ontime',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={row?.values?.assignment_ontime ? row?.values?.assignment_ontime + '%' : '-'} />,
                width: "170"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.LESSON_GOAL_COMPLETION')} className="!justify-center" />,
                accessor: 'lesson_goal',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.lesson_goal)} />,
                width: "160"
            }
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={translator('No Gradebook')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default GradebookListOverviewTable;
