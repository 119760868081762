import { FC, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import { ASSIGNMENT_STATUS } from 'constant/course.const';
import { ExternalLinkIcon } from '@heroicons/react/outline';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline } from '_shared';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { useParams } from 'react-router-dom';

type TableLearningMaterialsProps = {
    data: any;
};

export const TableLearningMaterials: FC<TableLearningMaterialsProps> = ({ data }) => {
    const params: { id: string; courseId: string } = useParams();
    const { t } = useTranslation();
    const query = useQueryParamsURL();

    const [modalSessions, setModalSessions] = useState(null);

    const {
        userState: { result },
    } = useContext(UserContext);

    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const learnerId = query.get('learnerId');
    const learnerGroupeId = query.get('learnerGroupeId');
    const isGroup = !!learnerGroupeId && !learnerId ? true : false;

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t('LEARNING_MATERIALS')} />,
                accessor: 'title',
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row.values?.title} />;
                },
                width: '150',
            },
            {
                Header: () => <RenderTableHeader value={t('COMPLETED_DATE')} />,
                accessor: 'completed_at',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={`${
                            row.values?.completed_at
                                ? dayjs.utc(row.values?.completed_at).tz(result?.time_zone).format('DD/MM/YYYY')
                                : '-'
                        }`}
                    />
                ),
                width: '110',
            },
            {
                Header: () => <RenderTableHeader value={t('STATUS')} />,
                accessor: 'status',
                Cell: ({ row }: any) => {
                    return (
                        <div className={`pl-ooolab_p_1_e`}>
                            <p className={row.values?.status ? `status status-${row.values?.status}` : 'text-xs text-ooolab_dark_1'}>
                                {row.values?.status ? ASSIGNMENT_STATUS(t)[row.values?.status] : '-'}
                            </p>
                        </div>
                    );
                },
                width: '110',
            },
            {
                Header: () => <RenderTableHeader value={t(isGroup ? 'AVG_GRADE' : 'GRADE')} />,
                accessor: 'grade',
                Cell: ({ row }: any) => <RenderTableCell value={row?.values?.grade >= 0 ? Math.round(row?.values?.grade) : '-'} />,
                width: '60',
            },
            {
                Header: () => <RenderTableHeader value={t(isGroup ? 'AVG_COMPLETION_RATE' : 'COMPLETION_RATE')} />,
                accessor: 'progress_percent',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={row.values?.progress_percent >= 0 ? Math?.round(row.values?.progress_percent) + '%' : '-'}
                    />
                ),
                width: '110',
            },
            {
                Header: () => <RenderTableHeader value={t(isGroup ? 'AVG_TIME_SPENT' : 'TIME_SPENT')} />,
                accessor: 'total_time_spent',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={row.values?.total_time_spent >= 0 ? Math?.round(row?.values?.total_time_spent / 60) + 'm' : '-'}
                    />
                ),
                width: '80',
            },
            {
                Header: () => <RenderTableHeader value={t('SESSION_S')} />,
                accessor: 'sessions',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={
                            !!row.values?.sessions ? (
                                <p className="flex items-center space-x-1">
                                    <span className="font-semibold">{t('VIEW_ALL')}</span>
                                    <ExternalLinkIcon className="flex-none w-5 h-5 text-enterprise" />
                                </p>
                            ) : (
                                '-'
                            )
                        }
                        onClick={!!row.values?.sessions ? () => setModalSessions(row.original) : undefined}
                        className={!!row.values?.sessions ? '!text-enterprise' : ''}
                    />
                ),
                width: '70',
            },
        ],
        [data, result, isGroup]
    );

    const initialState = {
        hiddenColumns: isGroup ? ['completed_at', 'status', 'sessions'] : [],
    };

    return (
        <>
            <Table
                initialState={initialState}
                data={data}
                columns={columns}
                emptyContent={t('NO_LEARNING_MATERIALS')}
                emptyIcon={NO_CONTENT}
                classEmpty={'learning_materials-empty_data'}
            />
            <DialogComponent
                title={t('SESSION_S_LIST')}
                isOpen={!!modalSessions}
                onCloseModal={() => setModalSessions(null)}
                styles="max-w-lg !p-0"
                classNameHeader="px-6 py-4"
                child={
                    <>
                        <div className="px-6 py-3 text-sm text-gray-800 border-t border-gray-300 flex flex-col space-y-4">
                            <div>
                                <p className="text-xs font-semibold mb-0.5">{t('LESSON_NAME')}</p>
                                <p>{modalSessions?.title}</p>
                            </div>
                            <div>
                                <p className="text-xs font-semibold mb-2">{t('SESSION_S')}</p>
                                <div className="rounded-lg bg-gray-50 px-4 py-3 max-h-[272px] overflow-auto">
                                    <div className="mb-1 grid grid-cols-3">
                                        <p className="col-span-1 text-xs font-semibold">{t('SESSION_ID')}</p>
                                        <p className="col-span-2 text-xs font-semibold">{t('DATE')}</p>
                                    </div>
                                    {modalSessions?.sessions?.map((item, index) => {
                                        const date = item?.start_time;
                                        const startTime = item?.start_time;
                                        const endTime = item?.end_time;

                                        return (
                                            <div key={index} className="grid grid-cols-3 py-0.5">
                                                <p
                                                    className="col-span-1 text-enterprise font-semibold cursor-pointer leading-ooolab_22px"
                                                    onClick={() => {
                                                        window.open(
                                                            `/workspace/${workspaceId}/management/courses/${courseId}/session/${item?.id}/grade`,
                                                            '_blank'
                                                        );
                                                    }}
                                                >
                                                    {item?.id}
                                                </p>
                                                <p className="col-span-2 leading-ooolab_22px">
                                                    {item?.start_time && item?.end_time
                                                        ? dayjs.utc(date).tz(result?.time_zone).format('DD/MM/YYYY') +
                                                          ` (${dayjs
                                                              .utc(startTime)
                                                              .tz(result?.time_zone)
                                                              .format('HH:mm')} - ${dayjs
                                                              .utc(endTime)
                                                              .tz(result?.time_zone)
                                                              .format('HH:mm')})`
                                                        : '-'}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="px-6 py-4 flex justify-center border-t border-gray-300">
                            <ButtonOutline type="button" size="M" onClick={() => setModalSessions(null)}>
                                {t('CLOSE')}
                            </ButtonOutline>
                        </div>
                    </>
                }
            />
        </>
    );
};
