import React from 'react';
import ManagementMasterPage from 'pages/RouteMasterPage';
import ManagePermissionWrapper from 'pages/Management/ManagePermissionWrapper';
import SkillDetail from 'components/Management/SkillManagement/SkillDetail';

const CertificatesPage = () => {
    return (
        // <ManagePermissionWrapper>
            <SkillDetail>
                <ManagementMasterPage  />
            </SkillDetail>
        // </ManagePermissionWrapper>
    );
};

export default CertificatesPage;
