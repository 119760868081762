import React, { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'hooks/custom';
import { useParams } from 'react-router-dom';
import DialogComponent from 'components/Dialog/DialogComponent';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useAppSelector } from 'hooks/hooks';
import courseService from 'services/course.service';
import { toast } from 'react-toastify';
import { ENROLLMENT_STATUS } from 'components/Workspace/Course/CourseBuilder/components/CoursePeople/constants';

type ModalDeactivatedActiveLearnerProps = {
    type: 'deactivated' | 'active';
    isOpen: boolean;
    onClose: () => void;
    callback?: (data?: any) => void;
    data?: any;
};

export const ModalDeactivatedActiveLearner: FC<ModalDeactivatedActiveLearnerProps> = ({
    type = 'active',
    isOpen,
    onClose,
    callback,
    data: enrollment,
}) => {
    const { t } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const { setting } = useAppSelector((state) => state.course);

    const [numbersCredit, setNumbersCredit] = useState(0);
    const [loading, setLoading] = useState(false);

    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const enrollmentId = enrollment?.id;
    const learnerId = enrollment?.learner?.id || enrollment?.user?.id;

    const onDeactivateEnrollment = () => {
        const loadingToastId = Toastify.loading(t('COURSES.DEACTIVATING_ENROLLMENT'));
        courseService
            .updateEnrollmentDetail(workspaceId, courseId, enrollment?.id, { status: ENROLLMENT_STATUS.DEACTIVATED })
            .then((res) => {
                toast.dismiss(loadingToastId);
                Toastify.success(t('YOUR_WORK_HAS_BEEN_SAVED'));
                onClose?.();
                callback?.(res?.data);
            })

            .catch(() => {
                toast.dismiss(loadingToastId);
                Toastify.error();
            });
    };

    const onActiveEnrollment = () => {
        courseService
            .updateEnrollmentDetail(workspaceId, courseId, enrollment?.id, { status: ENROLLMENT_STATUS.ENROLLED })
            .then((res) => {
                Toastify.success();
                onClose?.();
                callback?.(res?.data);
            })
            .catch(() => Toastify.error());
    };

    const fetchRefundCredits = async () => {
        try {
            setLoading(true);
            const response: any = await courseService.getRefundCredits(workspaceId, courseId, enrollmentId, learnerId);
            setNumbersCredit(response?.data?.refund_credits || 0);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    if (type === 'active') {
        return (
            <DialogComponent
                title={t('COURSES.ACTIVE_CONFIRMATION')}
                isOpen={isOpen}
                onCloseModal={onClose}
                child={
                    <div className="flex flex-col">
                        <div className="mt-2 text-sm text-gray-800">{t('COURSES.ACTIVATE_ENROLLMENT_MESSAGE')}</div>
                        <div className="flex justify-center gap-4 mt-6 w-full">
                            <ButtonOutline type="button" onClick={onClose}>
                                {t('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary type="button" onClick={onActiveEnrollment}>
                                {t('ACTIVATE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
        );
    }

    const infoModal = useMemo(() => {
        let message =
            setting?.enrollment_type === 'credit'
                ? t('COURSES.DEACTIVATE_ENROLLMENT_CREDIT_MSG')
                : t('COURSES.DEACTIVATE_ENROLLMENT_MESSAGE');
        let labelBtn = setting?.enrollment_type === 'credit' ? t('YES') : t('DEACTIVATE');

        if (setting?.enrollment_type === 'credit') {
            if (enrollment?.type === 'individual') {
                if (numbersCredit > 0) {
                    message = t('DEACTIVATE_ENROLLMENT_CREDIT_INDIVIDUAL_MSG', { num_credits: numbersCredit });
                    labelBtn = t('YES');
                } else {
                    message = t('COURSES.DEACTIVATE_ENROLLMENT_MESSAGE');
                    labelBtn = t('DEACTIVATE');
                }
            }
        }

        return {
            message,
            labelBtn,
        };
    }, [enrollment, setting, t, numbersCredit]);

    useEffect(() => {
        if (type === 'deactivated' && setting?.enrollment_type === 'credit') fetchRefundCredits();
    }, []);

    return (
        <DialogComponent
            title={t('DEACTIVATE')}
            isOpen={isOpen}
            onCloseModal={onClose}
            child={
                <div className="flex flex-col">
                    {loading ? (
                        <div className="w-full">
                            <div className="flex animate-pulse space-x-4">
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-2 rounded bg-gray-200"></div>
                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="col-span-2 h-2 rounded bg-gray-200"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-2 text-sm text-gray-800">{infoModal.message}</div>
                    )}
                    <div className="flex justify-center gap-4 mt-6 w-full">
                        <ButtonOutline type="button" onClick={onClose}>
                            {t('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary
                            type="button"
                            className="buttonPrimary-danger"
                            disabled={loading}
                            onClick={onDeactivateEnrollment}
                        >
                            {infoModal.labelBtn}
                        </ButtonPrimary>
                    </div>
                </div>
            }
        />
    );
};
