import React, { useEffect, useState } from "react";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import { useParams } from "react-router-dom";
import { getIndexPage } from "utils/commonFun";
import courseService from "services/course.service";
import Pagination from "components/V2/Pagination";
import GradebookListOverviewTable from "./GradebookListOverviewTable";
import "./GradebookList.scss";
import SearchBox from 'components/V2/SearchBox';

export const GradebookOverview = ({ learningGroupId }) => {
    const params: { id: string, courseId: string } = useParams();
    const [isFirstLoad, setFirstLoad] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [searchText, setSearchText] = useState(null);
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });

    const getdata = (page: number | string = 1, filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
            q:searchText,
            learning_group_ids: learningGroupId
        };
        delete payload?.total;
        if (!payload?.q) delete payload?.q;
        courseService.getGradebooksLearningGroup(params?.id, params?.courseId, payload)
            .then((res) => {
                setFirstLoad(false);
                const { items, total } = res.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getdata(page);
    };

    useEffect(() => {
        if (learningGroupId) {
            getdata();
        }
    }, [learningGroupId, searchText]);

    return (<div className="CourseOverviewGradebookList !pt-0">
        <div className="CourseOverviewGradebookList-header">
                <SearchBox className="filterLearningPath-searchInput" onSubmit={(value) => setSearchText(value)} />
            </div>
        <div className="CourseOverviewGradebookList-table">
            {loading ? (
                <div className="CourseOverviewGradebookList-table--loading">
                    <CircleSpin color="text-primary-500" />
                </div>
            ) : 
                <GradebookListOverviewTable data={data} refreshData={getdata} />
            }
        </div>
        <div className="CourseOverviewGradebookList-pagination">
            <Pagination
                total={pagination.total}
                per_page={pagination.per_page}
                callback={(e) => handlePagination(e)}
                indexPage={indexPage}
                page={pagination?.page}
            />
        </div>
    </div>)
}