import { useEffect, useState } from 'react';
import Info from './Info';
import Enrollment from './enrollment'
import { useTranslation } from 'react-i18next';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';
import { ButtonOutline } from '_shared';
import LeftMenu from './LeftMenu';
import Status from '../GroupList/TableGroups/Status';
import workspaceService from 'services/workspace.service';

const listTab = ['INFORMATION', 'ENROLLMENT_', 'MEMBERS'];

const GroupDetail = ({ children }) => {
    const [data, setData] = useState<any>();
    const params = useParams<{ id: string; groupId: string, tab?: string }>();
    const history = useHistory();
    const { t: translator } = useTranslation();
    const search = useLocation()?.search;

    const location: any = useLocation();
    const tab = location?.state?.tab || new URLSearchParams(search).get('tab');

    const getGroupDetails = () => {
        workspaceService.getLearningGroupDetail(params?.id, params?.groupId)
            .then((res) => {
                setData(res?.data);
            })
            .catch((err) => {
                if (err?.response?.status === 404) history.push(`/not-found`);
            });
    };

    useEffect(() => {
        if (!tab) history.replace(`/workspace/${params?.id}/management/learning-groups/${params.groupId}?tab=information`);
    }, [])

    useEffect(() => {
        getGroupDetails();
    }, [params?.groupId]);
    return (
        <>
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.LEARNING_MANAGEMENT')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LEARNING_GROUP'), href: `/workspace/${params?.id}/management/learning-groups`, className: '' },
                    { name: data?.name, href: ``, className: '' },
                ]}
            />
            <div>
                <div className="py-4 px-5  w-full flex justify-between border-b border-gray-300">
                    <div className="flex gap-3 ">
                        <div className="text-xl font-semibold">{data?.name}</div>
                        <Status status={'active'} />
                    </div>
                    <div className="flex gap-3">

                        {checkPermission('learningGroupDetails', 'D') && (
                            <ButtonOutline
                                className="!border-red-500 !text-red-500 !hidden"
                                type="button"
                                onClick={() => { }}
                            >
                                {translator('SAVE')}
                            </ButtonOutline>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-4 gap-5 p-5 bg-gray-50">
                    <div className="col-span-1 bg-white rounded-lg">
                        <LeftMenu groupData={{ data }} />
                    </div>
                    <div className="col-span-3 ">
                        {tab === 'information' && (
                            <Info data={data} refreshData={getGroupDetails} />
                        )}
                        {tab === 'enrollment' && <Enrollment />}
                    </div>
                </div>
            </div>
        </>

    );
};

export default GroupDetail;
