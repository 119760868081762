import { FC, useContext, useMemo, useState } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import ActionMenu from './ActionMenu';
import './Table.scss';
import NO_CONTENT from 'assets/img/empty-state/report.png';
import { useParams } from 'react-router-dom';
import { UserContext } from 'contexts/User/UserContext';
import { DocumentPreview } from '_shared/components/DocumentPreview/DocumentPreview';
import { checkPermission, getFileExtensionFromUrl } from 'utils/commonFun';

interface IViewTable {
    data: CourseType[];
    refreshData: Function;
    userData: any;
}

const ViewTable: FC<IViewTable> = ({ data, refreshData, userData }) => {
    const { t: translator } = useTranslation();

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const initialState = {
        hiddenColumns: ['id'],
    };
    const [isOpenViewFile, setOpenViewFile] = useState(false);
    const [certificateSelect, setCertificateSelect] = useState<any>();

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('USER.CERTIFICATE_NAME')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className="text-primary-500 font-semibold"
                            value={row.values?.name}
                            onClick={() => {
                                setCertificateSelect(row?.original);
                                setOpenViewFile(true);
                            }}
                        />
                    );
                },
                accessor: 'name',
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={translator('USER.COURSE_NAME')} />,
                accessor: 'course',
                Cell: ({ row }: any) => {
                    return (
                        checkPermission('course', 'R') ? <RenderTableCell
                            className="text-primary-500 font-semibold cursor-pointer"
                            value={row.values?.course?.title}
                            onClick={() => window.open(`/workspace/${params?.id}/management/courses/${row.values?.course?.id}?tab=gradebook`, '_blank')}
                        /> :
                            <RenderTableCell
                                value={row.values?.course?.title}
                            />
                    );
                },
                width: '180',
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.OVERALL_GRADE')} />,
                Cell: ({ row }) => {
                    return (
                        <RenderTableCell
                            className="text-gray-800 font-normal"
                            value={row.values?.overall ? row.values?.overall + '%' : '-'}
                        />
                    );
                },
                accessor: 'overall',
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="h-full flex items-center justify-center">
                            <ActionMenu data={row?.original} refreshData={refreshData} position={row?.index > data?.length / 2 ? 'bottom-0' : ''} userData={userData} />
                        </div>
                    );
                },
                accessor: 'action',
                width: 60,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <>
            <Table
                data={data}
                columns={columns}
                initialState={initialState}
                emptyContent={translator('USER.NO_CERTIFICATES')}
                emptyIcon={NO_CONTENT}
            />
            {isOpenViewFile &&
                <DocumentPreview
                    closeModal={() => setOpenViewFile(false)}
                    isOpen={isOpenViewFile}
                    data={{
                        type: getFileExtensionFromUrl(certificateSelect?.file) === 'pdf' ? 'pdf' : 'image',
                        name: certificateSelect?.name,
                        url: certificateSelect?.file

                    }}
                />

            }
        </>
    );
};

export default ViewTable;
