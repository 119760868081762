import React, { useMemo, useState } from 'react';
import ActionsButton from 'components/ActionsButton';
import { useHistory } from 'react-router-dom';
import { classNames } from 'utils/commonFun';
import { CollapseIcon, DotIcon, EditLine, ExpandIcon } from 'assets/icon';
import { ImageAvatar } from 'components/Image';
import { filterTreeByName, getNodesToExpand, propertyType } from './propertyCommon';
import { useTranslation } from 'react-i18next';
import emptyIconProperty from 'assets/img/empty-state/empty-properties.png';
import { NoContentWithImage } from 'components/NoContent/NoContent';

export const TableProperty = ({ workspaceId, tab, subtab, data = [], translator, hasSearch = '' }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const [expandedNodes, setExpandedNodes] = useState(new Set());

    const toggleExpand = (uuid) => {
        setExpandedNodes((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(uuid)) {
                newSet.delete(uuid); // Collapse node
            } else {
                newSet.add(uuid); // Expand node
            }
            return newSet;
        });
    };

    const handleEditProperty = (id, idSelected = null) => {
        history.push(
            `/workspace/${workspaceId}/setting?tab=${tab}&subtab=${subtab}&id=${id}${idSelected ? `&idSelected=${idSelected}` : ''}`
        );
    };

    const renderTree = (nodes, level = 0) => {
        return nodes.map((node, index) => {
            let paddingLeft = `20px`;
            if (level === 1) paddingLeft = `48px`;
            else if (level === 2) paddingLeft = `68px`;

            return (
                <React.Fragment key={node.uuid}>
                    <div className="flex items-center py-2 px-4">
                        <div className="flex-1 flex items-center truncate">
                            <div style={{ paddingLeft }} className="relative h-5">
                                <p
                                    className={classNames(
                                        'absolute right-0 w-5 flex justify-center flex-none',
                                        node?.children?.length > 0 ? 'top-0' : 'top-[7px]'
                                    )}
                                >
                                    {node?.children?.length > 0 ? (
                                        <span className="cursor-pointer" onClick={() => toggleExpand(node.uuid)}>
                                            {expandedNodes.has(node.uuid) ? (
                                                <CollapseIcon className="" />
                                            ) : (
                                                <ExpandIcon className="" />
                                            )}
                                        </span>
                                    ) : (
                                        <DotIcon className={level > 0 ? 'w-1 h-1 mt-0.5' : ''} />
                                    )}
                                </p>
                            </div>
                            <p
                                className="pl-2 text-enterprise font-semibold cursor-pointer truncate"
                                onClick={() => {
                                    if (!node?.parent?.parent_id && !node?.parent_id) {
                                        handleEditProperty(node?.id);
                                    } else handleEditProperty(node?.parent?.parent_id || node?.parent_id, node?.id);
                                }}
                            >
                                {node.name}
                            </p>
                        </div>
                        <div className="flex-1">{propertyType?.[node?.property_type]?.title || '-'}</div>
                        <div className="w-40 truncate flex items-center space-x-2">
                            {!node?.created_by ? (
                                t('SYSTEM')
                            ) : (
                                <>
                                    <ImageAvatar imageSrc={node?.created_by?.avatar_url} />
                                    <span
                                        className="text-enterprise font-semibold cursor-pointer truncate"
                                        onClick={() =>
                                            window.open(
                                                `/workspace/${workspaceId}/management/users/${node?.created_by?.id}?tab=information`,
                                                '_blank'
                                            )
                                        }
                                    >
                                        {node?.created_by?.display_name}
                                    </span>
                                </>
                            )}
                        </div>
                        <div className="w-7 h-full flex items-center">
                            <ActionsButton
                                menus={[
                                    {
                                        label: t('EDIT'),
                                        icon: <EditLine />,
                                        isDisabled: false, // !checkPermission('', 'U'),
                                        action: () => {
                                            if (!node?.parent?.parent_id && !node?.parent_id) {
                                                handleEditProperty(node?.id);
                                            } else handleEditProperty(node?.parent?.parent_id || node?.parent_id, node?.id);
                                        },
                                        hide: false,
                                    },
                                ]}
                                position={index > data?.length / 2 ? 'bottom-10' : ''}
                            />
                        </div>
                    </div>
                    {node.children && expandedNodes.has(node.uuid) && renderTree(node.children, level + 1)}
                </React.Fragment>
            );
        });
    };

    const newData = useMemo(() => {
        let filteredTree = data;
        if (hasSearch) {
            filteredTree = filterTreeByName(data, hasSearch);
            setExpandedNodes(getNodesToExpand(filteredTree));
        }

        return filteredTree;
    }, [data, hasSearch]);

    return (
        <>
            <div className="rounded-t-lg border-b-[0.5px] border-gray-300 bg-gray-50 z-1 sticky top-0 py-2 px-4 font-semibold flex items-center">
                <div className="flex-1">{translator('NAME')}</div>
                <div className="flex-1">{translator('PROPERTY_TYPE')}</div>
                <div className="w-40">{translator('CREATED_BY')}</div>
                <div className="w-7"></div>
            </div>
            {data?.length > 0 ? (
                <div>{renderTree(newData)}</div>
            ) : (
                <div className="h-[calc(100%-37px)] flex items-center justify-center">
                    <NoContentWithImage content={translator('NO_PROPERTIES')} img={emptyIconProperty} />
                </div>
            )}
        </>
    );
};
