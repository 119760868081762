import { useState } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { ConfirmDeleteModal } from '_shared';
import { NoteSection } from '../NoteSection/NoteSection';
import './PreviewContent.scss';
import { CommentIcon } from 'assets/icon';
import PreviewContentCommon from '_shared/PreviewContent/PreviewContentCommon';

export default function PreviewContent({
    sectionSelected,
    contentSelected,
    viewType,
    translator,
    handleDeleteFileOfSection,
    updateNote,
    isShowNote= true,
    isShowDelete= true,
    colorProcess="text-white"
}) {
    const [isOpenModalConfirm, setOpenModalConfirm] = useState(false);
    const [isShowingNote, setIsShowingNote] = useState(false);
    const deleteFile = (value: boolean) => {
        if (value) {
            handleDeleteFileOfSection(sectionSelected, contentSelected?.id);
        }
        setOpenModalConfirm(false);
    };
    return (
        <div className="previewContent-content">
            <div className="previewContent custom-scrollbar">
                <div className="previewContent-header">
                    <div className="wrapper">
                        <img
                            alt="libary-icon"
                            className="image"
                            src={
                                contentSelected?.library_content?.object_type
                                    ? LIBRARY_ICONS(translator)?.find(
                                          (item) => item?.value === contentSelected?.library_content?.object_type
                                      )?.icon
                                    : LIBRARY_ICONS(translator)[0].icon
                            }
                        />
                        <div className="title">
                            {contentSelected?.library_content?.title || contentSelected?.library_content?.name || contentSelected?.name}{' '}
                        </div>
                    </div>
                    {isShowNote && <button type="button" className="previewContent-comment" onClick={() => setIsShowingNote((prev) => !prev)}>
                        <CommentIcon width={20} height={20} />
                    </button>}
                    {isShowDelete && <div onClick={() => setOpenModalConfirm(true)} className="flex items-center pr-4">
                        <TrashIcon className="w-5 h-5 text-gray-500 cursor-pointer hover:text-gray-800 " />
                    </div>}
                </div>
                <PreviewContentCommon isNewPage={true} contentSelected={contentSelected} viewType={viewType} colorProcess={colorProcess} />
                <ConfirmDeleteModal
                    isOpen={isOpenModalConfirm}
                    onClose={deleteFile}
                    titleModal={translator('LIBRARY.REMOVE_CONTENT')}
                    btnPrimaryTitleLoading={translator('LIBRARY.REMOVING')}
                    btnPrimaryTitle={translator('LIBRARY.REMOVE')}
                >
                    <div className="text-sm">{translator('LIBRARY.REMOVE_CONTENT_MESSAGE')}</div>
                </ConfirmDeleteModal>
            </div>
            <NoteSection
                isShowing={isShowingNote}
                onClose={(data) => {
                    setIsShowingNote(false);
                    updateNote(data);
                }}
                data={contentSelected?.note}
                onChange={updateNote}
            />
        </div>
    );
}
