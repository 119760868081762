import { ChevronDownIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import ReactSelect, { OptionsOrGroups, SelectComponentsConfig, StylesConfig, components } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import CreatableSelect from 'react-select/creatable';

const styles: StylesConfig = {
    control: (base: any, state) => ({
        ...base,
        boxShadow: 'none',
        cursor: 'pointer',
        backgroundColor: state.hasValue ? '#E5F4FF' : '#ffffff',
        borderColor: state.hasValue ? '#0071CE' : '#e5e7eb',
        '&:hover': {
            borderColor: state.hasValue ? '#0071CE' : '#e5e7eb',
        },
    }),
    option: (base: any, { isSelected }) => {
        return {
            ...base,
            color: '#1f2937',
            fontSize: 14,
            fontWeight: 400,
            background: isSelected ? '#E5F4FF' : '#FFFFFF',
            '&:hover': {
                background: '#E5F4FF',
            },
            cursor: 'pointer',
        };
    },
    valueContainer: (base) => ({
        ...base,
        fontSize: 14,
        fontWeight: 600,
        color: '#1f2937',
    }),
    indicatorSeparator: (base) => ({ ...base, display: 'none' }),
    dropdownIndicator: (base, { selectProps }) => ({
        ...base,
        paddingLeft: 0,
        paddingRight: 0,
        transition: 'all 0.3s ease-in-out',
        transform: selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
    }),
    // clearIndicator: (base) => ({
    //     ...base,
    //     paddingLeft: 0,
    //     paddingRight: 0,
    //     '& svg': {
    //         display: 'none'
    //     },
    //     '&::after': {
    //     content: '"✕"',
    //     fontSize: '14px', 
    //     fontWeight: 'normal',
    //     color: '#6B7280'
    //     }
    // }),
    indicatorsContainer: (base) => ({
        paddingRight: 12,
        display: 'flex',
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        fontSize: 14,
        color: '#6B7280',
    }),
    singleValue: (base) => ({
        ...base,
        fontWeight: 400,
        color: '#1f2937',
    }),
    menu: (base) => ({
        ...base,
        zIndex: 2,
        boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    })
};

type SelectProps = {
    selectRef?: any;
    name?: string;
    value: any;
    onChange: (newValue: any, actionMeta: any) => void;
    placeholder?: string;
    customStyles?: StylesConfig;
    className?: string;
    options: OptionsOrGroups<any, any>;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    componentsSelect?: SelectComponentsConfig<any, any, any>;
};

const Select: FC<SelectProps> = ({
    selectRef,
    name = 'select',
    value,
    onChange,
    placeholder = 'Select value',
    customStyles,
    className,
    options,
    isMulti = false,
    isClearable = false,
    isSearchable = true,
    componentsSelect,
}) => {
    return (
        <ReactSelect
            ref={selectRef}
            styles={{ ...styles, ...customStyles }}
            className={className}
            name={name}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={placeholder}
            value={value}
            options={options}
            components={{
                ClearIndicator: (props) => {
                    const { children, ...restProps } = props;
                    return (
                        <components.ClearIndicator {...restProps} className='!px-0 !text-gray-500'>
                            <XIcon width={16} />
                        </components.ClearIndicator>
                    );
                },
                DropdownIndicator: (props) => {
                    const { children, ...restProps } = props;

                    if (props.hasValue) {
                        return null;
                    }

                    return (
                        <components.ClearIndicator {...restProps} className='!px-0 !text-gray-500'>
                            <ChevronDownIcon width={12} />
                        </components.ClearIndicator>
                    );
                },
                SingleValue: ({ children, ...props }) => {
                    const placeholder = props.selectProps.placeholder;
                    
                    return (
                        <components.SingleValue {...props}>
                            {placeholder && <span style={{ fontWeight: 600, color: '#1f2937', marginRight: '4px' }}>{placeholder}: </span>}
                            {children}
                        </components.SingleValue>
                    );
                },
                ...componentsSelect,
            }}
        />
    );
};

export default Select;

type CreateSelectProps = {
    selectRef?: any;
    name?: string;
    value: any;
    onChange: (newValue: any, actionMeta: any) => void;
    placeholder?: string;
    customStyles?: StylesConfig;
    className?: string;
    options: OptionsOrGroups<any, any>;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    componentsSelect?: SelectComponentsConfig<any, any, any>;
};

const stylesCreatableSelect = {
    control: (styles: any) => ({
        ...styles,
        boxShadow: 'none',
        borderColor: '#EEF1FA',
    }),
    menu: (styles: any) => ({
        ...styles,
        height: 250,
        zIndex: 2,
    }),
    menuList: (styles: any) => ({
        ...styles,
        height: 250,
    }),
    option: (styles: any) => {
        return {
            ...styles,
            color: '#2E3A59',
            fontSize: 12,
            fontWeight: 400,
            background: '#ffffff',
            '&:hover': {
                background: '#DEEBFF',
            },
        };
    },
    multiValue: (styles: any) => {
        return {
            ...styles,
            background: '#EEF1FA',
        };
    },
    input: (styles: any) => ({
        ...styles,
        width: 90,
    }),
    indicatorsContainer: (styles) => ({
        ...styles,
        display: 'none',
    }),
    valueContainer: (base) => ({
        ...base,
        fontSize: 12,
        fontWeight: 600,
        color: '#2E3A59',
    }),
    placeholder: (base) => ({
        ...base,
        fontWeight: 400,
        color: '#8F90A6',
    }),
};

export const CreateSelect: FC<CreateSelectProps> = ({
    selectRef,
    name = 'create-select',
    value,
    onChange,
    placeholder = '',
    customStyles,
    className,
    options,
    isMulti = true,
    isClearable = true,
    isSearchable = true,
    componentsSelect,
}) => {
    return (
        <CreatableSelect
            ref={selectRef}
            styles={{ ...customStyles, ...stylesCreatableSelect }}
            className={className}
            name={name}
            onChange={onChange}
            isMulti={isMulti}
            isClearable={isClearable}
            isSearchable={isSearchable}
            placeholder={placeholder}
            value={value}
            options={options}
            components={{
                Control: (props: any) => {
                    return (
                        <components.Control {...props}>
                            <SearchIcon className="h-5 w-5 text-dark-100 ml-2" />
                            {props.children}
                        </components.Control>
                    );
                },
                ...componentsSelect,
            }}
        />
    );
};


type AsyncSelectFieldProps = {
    placeholder?: string;
    customStyles?: StylesConfig;
    className?: string;
    isMulti?: boolean;
    isClearable?: boolean;
    isSearchable?: boolean;
    componentsSelect?: SelectComponentsConfig<any, any, any>;
    label?: string;
    isDisabled?: boolean;
    loadOptions: LoadOptions<any, any, any>;
    hideSelectedOptions?: boolean;
    closeMenuOnSelect?: boolean;
    getOptionLabel?: any;
    getOptionValue?: any;
    value: any;
    onChange: (newValue: any, actionMeta: any) => void;
};

export const AsyncSelect: FC<AsyncSelectFieldProps> = ({
    placeholder = '',
    customStyles,
    className,
    isMulti = false,
    isClearable = false,
    isSearchable = true,
    componentsSelect,
    label,
    isDisabled,
    loadOptions,
    hideSelectedOptions = false,
    closeMenuOnSelect = true,
    getOptionLabel,
    getOptionValue,
    value,
    onChange
}) => {

    return (
        <AsyncPaginate
            loadOptions={loadOptions}
            additional={{
                page: 1,
            }}
            debounceTimeout={500}
            className={className}
            styles={{ ...customStyles, ...styles }}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            isMulti={isMulti}
            isClearable={isClearable}
            hideSelectedOptions={hideSelectedOptions}
            closeMenuOnSelect={closeMenuOnSelect}
            placeholder={placeholder}
            menuPlacement="auto"
            value={value}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onChange={onChange}
            components={{
                Option: ({ children, ...props }) => {
                    // FIX LAG
                    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
                    const newProps = Object.assign(props, { innerProps: rest });
                    return <components.Option {...newProps}>{children}</components.Option>;
                },
                ...componentsSelect,
            }}
        />
    );
};