import React from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import EmptyIcon from 'assets/img/empty-state/user.png';
import ActionMenu from './Action';
import Status from '../Components/Status';
import { USER_ROLE } from '../constant';

interface UserTableProps {
    workspaceId: string;
    dataTable: any;
    t: Function;
    loading?: boolean;
    refreshData?: any;
    location?: string;
    userSelected?: any[];
    setUserSelected?: Function;
    isSelect?: boolean
}

const UserTable: React.FC<UserTableProps> = ({ workspaceId, dataTable, t, refreshData, location, userSelected, setUserSelected, isSelect = false }) => {

    const onSelect = (user) => {
        const hasCheck = userSelected?.find((item) => item?.id === user?.id);
        if (hasCheck) {
            setUserSelected(userSelected.filter((item) => item?.id !== user.id));
        } else {
            setUserSelected([...userSelected, user]);
        }
    };

    const onSelectAll = () => {
        if (!!userSelected?.length) {
            setUserSelected([]);
        } else setUserSelected(dataTable);
    };

    const columns = React.useMemo(
        () => [
            location != 'clms'
                ? {
                    Header: () => (
                        <div className="w-full px-2 flex">
                            <input
                                type="checkbox"
                                checked={!!userSelected?.length}
                                onChange={() => onSelectAll()}
                            />
                        </div>
                    ),
                    Cell: ({ row }: any) => {
                        const hasCheck = userSelected?.find((item) => item?.id === row?.original?.id);
                        return (
                            <div className="px-2 flex w-full">
                                <input type="checkbox" checked={hasCheck} onChange={() => onSelect(row?.original)} />
                            </div>
                        );
                    },
                    accessor: 'userSelected',
                    width: '20',
                }
                : { accessor: 'userSelected', width: '0' },
            {
                Header: () => <RenderTableHeader value={t("USER.FULL_NAME")} />,
                Cell: ({ row }) => {
                    return (
                        <div className="flex items-center space-x-2 py-2">
                            <img className="w-6 h-6 rounded-full" src={row.original?.avatar_url} alt={'avatar' + row.original?.id} />
                            <Link
                                to={`/workspace/${workspaceId}/management/users/${row?.original?.membership?.user_id}?tab=information`}
                                className="text-ooolab_xs text-primary-500 font-semibold cursor-pointer truncate"
                                data-tip={row.original.display_name}
                                data-for={row.original?.id.toString() + 'display_name'}
                            >
                                {row.values.display_name}
                            </Link>
                            {/* @ts-ignore */}
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row.original?.id.toString() + 'display_name'}
                                getContent={(content) => <div className="w-56 h-auto">{content}</div>}
                                disable={row.original?.display_name?.length < 18}
                            />
                        </div>
                    );
                },
                accessor: 'display_name',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={t('USER.EMAIL')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row?.values?.email || '-'} />;
                },
                accessor: 'email',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={location === 'clms' ? t("USER.ROLE") : t("USER.TYPE")} />,
                Cell: ({ row }) => {
                    if (location !== 'clms') {
                        let type = ''
                        if (row?.values?.membership?.type === 'student') type = t('USER.LEARNER')
                        if (row?.values?.membership?.type === 'parent') type = t('USER.PARENT')
                        return <RenderTableCell className="capitalize" value={type} />;
                    }
                    return <RenderTableCell className="capitalize" value={USER_ROLE?.[row?.values?.membership?.user_role?.name?.toLowerCase().split(" ")?.join("_")] ? t("USER." + (row?.values?.membership?.user_role?.name?.split(" ")?.join("_")?.toUpperCase())) : row?.values?.membership?.user_role?.name} />;
                },
                accessor: 'membership',
                width: 100,
            },
            {
                Header: () => <RenderTableHeader value={t("USER.STATUS")} />,
                Cell: ({ row }) => {
                    const status = row?.original?.membership?.status;
                    return (
                        <Status user={row?.original} status={status} />
                    );
                },
                accessor: 'status',
                width: 80,
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <div className="CourseAction">
                            <ActionMenu data={row?.original} refreshData={refreshData} position={row.index > dataTable?.length / 2 ? 'bottom-0' : ''} />
                        </div>
                    );
                },
                accessor: 'action',
                width: 20,
            },
        ],
        [dataTable, userSelected, location]
    );

    return (
        <Table data={dataTable} columns={columns} emptyIcon={EmptyIcon} emptyContent={t("USER.NO_USERS")} />
    );
};

export default UserTable;
