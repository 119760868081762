
import React, { useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import "./GradebookDetails.scss";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { GradebookAssignment } from "./GradebookAssignment/GradebookAssignment";
import { StudyHistory } from "./StudyHistory/StudyHistory";
import courseService from "services/course.service";
import { GradebookOverview } from "./GradebookOverview/GradebookOverview";
import { checkPermission } from "utils/commonFun";
import { useTranslation } from "react-i18next";
import { CircleInformationIcon } from "assets/icon";
import { TooltipCustom } from "components/Tooltip/Tooltip";
const TAB = {
    assignment: "assignment",
    studyHistory: "study-history",
    overview: "overview"
};

export const GradebookDetails = ({hasUserName=true, enrollmentId=null, userData}) => {
    const params: { id: string, userId: string } = useParams();
    const history = useHistory();
    const search = useLocation()?.search;
    const tab = new URLSearchParams(search).get('tab');
    const courseId = new URLSearchParams(search).get('courseId');
    const subtab = new URLSearchParams(search).get('subtab');
    const workspaceId = params?.id
    const {t} = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);

    const [overview, setOverview] = useState<any>();
    const [courseInfo, setCourseInfo] = useState(undefined)

    useEffect(() => {
        if (courseId && checkPermission('course','R')) {
            courseService.getCourse({workspaceId, id: courseId})
            .then((res) => setCourseInfo(res?.data))
        }
    }, [courseId])
    
    useEffect(() => {
        const getOverview = async () => {
            const response = await courseService.getGradebooksByGroup(params?.id, courseId, params?.userId);
            if (response?.data) {
                setOverview(response?.data);
            }
        }
        if(checkPermission('course','R')){
            getOverview();
        }
    }, [params?.userId])
    
    return <>
        <div className="gradebookDetails -m-5">
            <div className="gradebookDetails-header">
                {hasUserName ? <h1 className="gradebookDetails-title">
                    <button onClick={() => history.push(`/workspace/${params?.id}/management/users/${params?.userId}?tab=${tab}`)}><ChevronLeftIcon className="w-5 h-5" /></button>
                    {courseInfo?.title}
                </h1> : null}
                <div className="gradebookDetails-info">
                    <div className="gradebookDetails-list">
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{t("COURSES.COURSE_COMPLETION")}</div>
                            <div className="gradebookDetails-value">{overview?.course_completion == null ? '-' : `${overview?.course_completion}%`}</div>
                        </div>
                        <div className="gradebookDetails-line"></div>
                        <div className="gradebookDetails-item">
                            <div className='flex items-center gap-1 max-w-sm'>
                                <div className="gradebookDetails-label">{t('COURSES.ASSIGNMENT_GRADE')} </div>
                                <div className="w-3 h-3 cursor-pointer flex items-center mt-[1px]"
                                    data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                    data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}>
                                    <CircleInformationIcon width='12' height='12' />
                                    <TooltipCustom
                                        classNameContainer="shadow-medium max-w-sm  text-center"
                                        id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                        content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                                        place="top"
                                        type="light"
                                    />
                                </div>
                            </div>
                            <div className="gradebookDetails-value">{overview?.assignment_score == null ? '-' : `${overview?.assignment_score}%`}</div>
                        </div>
                        <div className="gradebookDetails-line"></div>
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{translator("Attendance")}</div>
                            <div className="gradebookDetails-value">{overview?.attendance == null ? '-' : `${overview?.attendance}%`}</div>
                        </div>
                        <div className="gradebookDetails-line"></div>
                        <div className="gradebookDetails-item">
                            <div className="gradebookDetails-label">{t("COURSES.LESSON_GOAL_COMPLETION")}</div>
                            <div className="gradebookDetails-value">{overview?.lesson_goal == null ? '-' : `${overview?.lesson_goal}%`}</div>
                        </div>
                    </div>
                    <div className="gradebookDetails-overall">
                        {translator("Overall Grade")}: <span>{overview?.overall == null ? '-' : `${overview?.overall}%`}</span>
                    </div>
                </div>
            </div>
            <div className="gradebookDetails-content">
                <div className="gradebookDetailsTab px-5">
                    <div onClick={() => history.push(`/workspace/${params?.id}/management/users/${params?.userId}?tab=${tab}&subtab=assignment&enrollmentId=${enrollmentId}&view=gradebook&courseId=${courseId}`)}
                        className={`gradebookDetailsTab-item ${subtab === TAB.assignment ? 'gradebookDetailsTab-item--actived' : null}`}>{t("ASSIGNMENT.LIBRARY.Assignment")}</div>
                    <div onClick={() => history.push(`/workspace/${params?.id}/management/users/${params?.userId}?tab=${tab}&subtab=study-history&enrollmentId=${enrollmentId}&view=gradebook&courseId=${courseId}`)}
                        className={`gradebookDetailsTab-item ${subtab === TAB.studyHistory ? 'gradebookDetailsTab-item--actived' : null}`}> {t("COURSES.SESSION_HISTORY")}</div>
                </div>
                <div className="gradebookDetails-container">
                    {subtab === TAB.assignment ? <GradebookAssignment userData={userData}/> : null}
                    {subtab === TAB.studyHistory ? <StudyHistory userData={userData} /> : null}
                </div>
            </div>
        </div>
    </>
}