import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import './ModalNewEnrollment.scss';
import { InputField } from 'components/InputForm/InputForm';
import courseService from 'services/course.service';
import dayjs from 'dayjs';
import { useAppDispatch } from 'hooks/hooks';
import { updatePeopleNumber } from 'store/features/courseSlice';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { EditIcon } from 'assets/icon';
import { NoContentWithAction } from 'components/V2/NoContentWithAction/NoContentWithAction';
import NO_LEARNING_GROUP from 'assets/img/empty-stage-learning-group.png';
import NO_LEARNER from 'assets/img/empty-state/student.png';
import workspaceMiddleware from 'middleware/workspace.middleware';
import { User } from '_shared/components/User/User';
import SearchBox from 'components/V2/SearchBox';

export default function ModalNewEnrollment({ onCloseModal, isUseCredit = false }) {
    const dispatch = useAppDispatch();
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        register,
        reset,
    } = useForm({ mode: 'onChange' });
    const { t: translator } = useTranslation();
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'credits',
    });

    const [type, setType] = useState('individual');
    const [learners, setLearners] = useState([]);
    const [inputSearchLearner, setInputSearchLearner] = useDebouncedState('', 0);
    const [paginationLearner, setPaginationLearner] = useState({ page: 1, per_page: 10, total: 10 });
    const [loadinglearner, setLoadingLearner] = useState(false);

    const [learningGroups, setLearningGroups] = useState([]);
    const [inputSearchLearningGroup, setInputSearchLearningGroup] = useDebouncedState('', 0);
    const [paginationLearningGroup, setPaginationLearningGroup] = useState({ page: 1, per_page: 10, total: 10 });
    const [loadinglearningGroup, setLoadingLearningGroup] = useState(false);
    const [preventFocus, setPreventFocus] = useState(false);

    const params: any = useParams();
    const workspaceId = params.id;

    const getLearners = (page: number) => {
        setLoadingLearner(true);
        if (isUseCredit) {
            const param = {
                page: page || paginationLearner?.page,
                q: inputSearchLearner,
                query_for: 'for_enroll',
                account_type: 'personal',
                enroll_course_id: params?.courseId
            };
            workspaceService
                .getCreditAccount(workspaceId, param)
                .then((res) => {
                    const options = res?.data?.items?.map((el: any) => ({
                        ...el?.user,
                        credit_balance: el?.credit_balance,
                        id: el.user?.id,
                        name: el.user?.display_name,
                        value: el.user?.membership_id,
                    }));
                    if (page === 1) setLearners(options);
                    if (page > 1) setLearners([...learners, ...options]);
                    setPaginationLearner({ ...paginationLearner, page: res?.data?.page, total: res?.data?.total });
                })
                .finally(() => setLoadingLearner(false));
        } else {
            const param = {
                page: page || paginationLearner?.page,
                q: inputSearchLearner,
                status: ["invite", "active", "registered"],
                location: 'learner_portal',
                type: 'student',
                enroll_course_id: params?.courseId
            };
            if (!param?.q) delete param?.q;
            workspaceMiddleware
                //@ts-ignore
                .getWorkspaceMembersMiddleware(workspaceId, param)
                .then((res) => {
                    const options = res?.data?.items?.map((user: any) => ({
                        ...user,
                        id: user?.membership?.id,
                        name: user?.display_name,
                        value: user?.membership?.id,
                    }));
                    if (page === 1) setLearners(options);
                    if (page > 1) setLearners([...learners, ...options]);
                    setPaginationLearner({ ...paginationLearner, page: res?.data?.page, total: res?.data?.total });
                })
                .finally(() => setLoadingLearner(false));
        }
    };

    const getLearningGroups = (page: number) => {
        setLoadingLearner(true);
        let payload: any = {
            page: page || paginationLearningGroup?.page,
            sort_by: 'name',
            order: 'desc',
            q: inputSearchLearningGroup,
            status: 'active',
        };
        if (!payload?.q) delete payload?.q;
        workspaceService
            .getLearningGroupService(workspaceId, payload)
            .then((res) => {
                const options = res?.data?.items?.map((el: any) => ({
                    ...el,
                    id: el.id,
                    name: el.name,
                    value: el.id,
                }));
                if (page === 1) setLearningGroups(options);
                if (page > 1) setLearningGroups([...learningGroups, ...options]);
                setPaginationLearningGroup({ ...paginationLearningGroup, page: res?.data?.page, total: res?.data?.total });
            })
            .finally(() => setLoadingLearningGroup(false));
    };

    const onSubmit = (value) => {
        let members = [];
        if (isUseCredit) {
            members = value?.credits
                ?.filter((item) => item?.amount > 0)
                ?.map((item) => {
                    return {
                        membership_id: item?.value,
                        credit_amount: +item?.amount,
                        note: item?.note,
                    };
                });
        } else {
            members = (value?.learner || value?.credits)?.map((item) => {
                return {
                    membership_id: item?.value,
                };
            });
        }
        const enrollData = {
            // type,
            // name: value?.name,
            learning_group_id: value?.learning_group?.[0]?.value,
            start_date: dayjs(value?.start_date).format(),
            end_date: dayjs(value?.end_date)?.format(),
            members,
            // action: "new_enroll"
        };

        if (type === 'individual') delete enrollData.learning_group_id;

        courseService
            .enrollLearnerToCourse(workspaceId, params?.courseId, enrollData)
            .then((res) => {
                if (res?.data?.error?.code == 400) {
                    if (type === 'learning_group') {
                        return Toastify.error(
                            {
                                title: `Enroll learning group failed!`,
                                message: res?.data?.error?.body_params?.[0]?.msg?.includes('Invalid')
                                    ? `Learning group "${value?.learning_group?.[0]?.name}" is invalid` :
                                    res?.data?.error?.description?.includes('enrolled') ?
                                        `Learning group "${value?.learning_group?.[0]?.name}" is enrolled`
                                        :
                                        res?.data?.error?.description
                                ,
                            },
                            { autoClose: 5000 }
                        );
                    }
                    return Toastify.error(
                        {
                            title: `Enroll learner(s) failed!`,
                            message: res?.data?.error?.description,
                        },
                        { autoClose: 5000 }
                    );
                }
                if (res?.data?.error?.code == 500) {
                    return Toastify.error();
                }
                const numOfMember = enrollData?.members?.length;
                Toastify.success(
                    {
                        title: numOfMember === 1 ? translator('COURSES.LEARNER_ENROLLED_SUCCESSFULLY') : translator('COURSES.LEARNER_ENROLLED_SUCCESSFULLY'),
                        message:
                            numOfMember === 1
                                ? `1` + ' ' + translator("COURSES.LEARNER_HAS_BEEN_ENROOLED_SUCCESSFULLY")
                                : `${enrollData?.members?.length}` + ' ' + translator("COURSES.LEARNERS_HAVE_BEEN_ENROOLED_SUCCESSFULLY"),
                    },
                    { autoClose: 5000 }
                );
                courseService.getCountPeople(params.id, params.courseId).then((response: any) => {
                    dispatch(updatePeopleNumber(response?.data));
                });
                onCloseModal(true);
            })
            .catch(() => Toastify.error());
    };

    useEffect(() => {
        if (type === 'individual') getLearners(1);
    }, [inputSearchLearner, type]);

    useEffect(() => {
        if (type === 'learning_group') getLearningGroups(1);
    }, [inputSearchLearningGroup, type]);

    useEffect(() => {
        reset({
            start_date: new Date(),
            end_date: null,
            credits: [],
        });
    }, [type]);

    useEffect(() => {
        if (watch('learning_group')?.length === 0) {
            replace([]);
        }
    }, [watch('learning_group')]);

    const getCreditOfAccount = (item) => {
        setPreventFocus(true);
        clearInterval(intervalPreventFocus);
        var intervalPreventFocus = setInterval(() => {
            setPreventFocus(false);
        }, 1000);
        if (type === 'individual') {
            append(item);
        } else {
            const param: any = {
                account_type: 'learning_group',
                skip_pagination: true,
                learning_group_id: item?.value,
                query_for: 'for_enroll',
                enroll_course_id: params?.courseId

            };
            workspaceService.getCreditAccount(workspaceId, param).then((res) => {
                let learners = res?.data?.items?.map((creditAccount) => ({
                    userId: creditAccount?.user?.id,
                    avatar_url: creditAccount?.user?.avatar_url,
                    name: creditAccount?.user?.display_name,
                    value: creditAccount?.user?.membership_id,
                    credit_balance: creditAccount?.credit_balance,
                }));
                replace(learners);
            });
        }
    };

    const onRemove = (value, index) => {
        const credits = watch('credits')?.filter((item: any) => item?.value !== value);
        if (credits?.length === 0 && type === 'learning_group') {
            Toastify.warning(translator("COURSES.ALL_MEMBER_ARE_REMOVED"));
        }
        if (type === 'individual') {
            setValue('learner', credits);
        }
        remove(index);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="ModalNewEnrollment">
            <div className="ModalNewEnrollment-type">
                <div onClick={() => setType('individual')} className={`btn-type ${type === 'individual' ? 'active' : ''} `}>
                    {translator('COURSES.INDIVIDUAL')}
                </div>
                <div onClick={() => setType('learning_group')} className={`btn-type ${type === 'learning_group' ? 'active' : ''}`}>
                    {translator('COURSES.LEARNING_GROUP')}
                </div>
            </div>
            <div className="bg-gray-50 rounded-lg px-4 py-2 !mt-5">
                <div className="field !mt-0">
                    {type === 'individual' && (
                        <SelectBox
                            control={control}
                            label={translator('COURSES.LEARNER')}
                            name="learner"
                            placeholder={translator('COURSES.SELECT_LEARNERS')}
                            isRequired
                            errors={errors}
                            getData={getLearners}
                            isMulti={true}
                            onSearch={(value) => setInputSearchLearner(value)}
                            data={learners}
                            pagination={paginationLearner}
                            value={watch('learner')}
                            numberOfItemDisplayed={4}
                            loading={loadinglearner}
                            actionAfterRemoveItemSelected={(item, index) => {
                                remove(index)
                            }}
                            actionAfterSelectItem={(item) => {
                                getCreditOfAccount(item);
                            }}
                            actionAfterRemoveAllItem={() => replace([])}
                            emptyState={{
                                message: translator("COURSES.NO_LEARNER"),
                                img: NO_LEARNER,
                            }}
                            isShowSearchIcon={true}
                        />
                    )}
                    {type === 'learning_group' && (
                        <SelectBox
                            control={control}
                            label={translator('COURSES.LEARNING_GROUP')}
                            name="learning_group"
                            placeholder={translator('COURSES.SELECT_A_LEARNING_GROUP')}
                            isRequired
                            errors={errors}
                            getData={getLearningGroups}
                            isMulti={false}
                            onSearch={(value) => setInputSearchLearningGroup(value)}
                            data={learningGroups}
                            pagination={paginationLearningGroup}
                            value={watch('learning_group')}
                            numberOfItemDisplayed={3}
                            loading={loadinglearningGroup}
                            actionAfterSelectItem={(item) => {
                                getCreditOfAccount(item);
                            }}
                            actionAfterRemoveAllItem={() => replace([])}
                            emptyState={{
                                message: 'No Learning Group',
                                img: NO_LEARNING_GROUP,
                            }}
                            isShowSearchIcon={true}
                        />
                    )}
                </div>

                <div className="flex justify-center">
                    {isUseCredit && type === 'individual' && fields?.length === 0 && (
                        <NoContentWithAction styleNoContent="!text-xs" content={translator('COURSES.NO_LEARNER')} img={NO_LEARNER} />
                    )}
                    {type === 'learning_group' && watch('learning_group')?.length > 0 && fields?.length === 0 && (
                        <NoContentWithAction styleNoContent="!text-xs" content={translator('COURSES.NO_LEARNER')} img={NO_LEARNER} />
                    )}
                    {type === 'learning_group' && !watch('learning_group')?.length && (
                        <NoContentWithAction
                            styleNoContent="!text-xs"
                            content={translator('COURSES.NO_LEARNER_SELECTED')}
                            img={NO_LEARNING_GROUP}
                        />
                    )}
                </div>

                {(isUseCredit || type === 'learning_group') && fields?.length > 0 && (
                    <div className="field !mt-5 min-h-[90px] max-h-[300px] overflow-x-auto relative ">
                        {isUseCredit && (
                            <div className="grid grid-cols-8 text-xs font-semibold border-b border-gray-300 pb-2 sticky top-0 bg-gray-50 z-1 mb-2">
                                <div className="col-span-2 px-1">{translator('NAME')}</div>
                                <div className="col-span-2 px-1 truncate text-ellipsis">
                                    {translator('CREDITS')} <span className="text-red-500">*</span>
                                </div>
                                <div className="col-span-4 px-1">{translator('NOTES')}</div>
                            </div>
                        )}

                        {!isUseCredit && (
                            <div className="flex justify-between items-center">
                                <div className="text-xs font-semibold">{translator('COURSES.LEARNERS')}</div>
                                <SearchBox
                                    className="!w-1/2 bg-white"
                                    placeholder={translator('COURSES.SEARCH_A_LEARNER')}
                                    onSubmit={setInputSearchLearner}
                                />
                            </div>
                        )}

                        {fields
                            ?.map((item: any, index: number) => {
                                if (isUseCredit) {
                                    if (!item?.credit_balance) return;
                                }
                                if (type === 'learning_group' && inputSearchLearner && !item?.name?.toLowerCase().includes(inputSearchLearner?.toLowerCase())) return;
                                return (
                                    <div className="grid grid-cols-8 text-xs py-2.5 relative " key={index}>
                                        {isUseCredit ? (
                                            <div className="col-span-2 text-ellipsis truncate px-1 w-full mt-0.5">{item?.name}</div>
                                        ) : (
                                            <div className="col-span-8">
                                                <User
                                                    className="w-full"
                                                    name={item?.name}
                                                    avatar={item?.avatar_url}
                                                    link={`/workspace/${workspaceId}/management/users/${item?.userId}?tab=information`}
                                                />
                                            </div>
                                        )}
                                        {isUseCredit && (
                                            <div className="col-span-2 px-1 flex items-center gap-0.5 relative !text-gray-800 ">
                                                <InputField
                                                    name={`credits.${index}.amount`}
                                                    register={register(`credits.${index}.amount`, {
                                                        required: {
                                                            value: isUseCredit && item?.credit_balance > 0,
                                                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                                                        },
                                                        validate: {
                                                            number: (value) => {
                                                                return +value > 0 && +value <= item?.credit_balance
                                                                    ? true
                                                                    : `Input number 1 - ${item?.credit_balance}`;
                                                            },
                                                        },
                                                    })}
                                                    type="number"
                                                    className="!gap-0 "
                                                    classNameInput="h-6 disabled:cursor-not-allowed !w-[60px]"
                                                    disabled={item?.credit_balance === 0 || preventFocus}
                                                    errors={errors}
                                                    classError="whitespace-nowrap !text-[10px] !font-normal absolute -bottom-4"
                                                    labelHorizontal={`/${item?.credit_balance}`}
                                                    classLabelHorizontal="!text-gray-800 -ml-1"
                                                />
                                            </div>
                                        )}
                                        {isUseCredit && (
                                            <div className="col-span-4 px-1 flex items-center pr-6">
                                                <div className="flex space-x-2 w-full">
                                                    {!watch(`credits.${index}.note`) && !watch(`credits.${index}.isEditing`) && (
                                                        <div
                                                            className="text-primary-500 flex items-center gap-1 cursor-pointer"
                                                            onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                        >
                                                            <EditIcon fill="#0071ce" /> {translator("COURSES.ADD_NOTE")}
                                                        </div>
                                                    )}
                                                    {watch(`credits.${index}.isEditing`) && (
                                                        <div
                                                            className="relative"
                                                            onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                        >
                                                            <InputField
                                                                placeholder={translator("COURSES.ENTER_NOTE_HERE")}
                                                                name={`credits.${index}.note`}
                                                                errors={errors}
                                                                classNameInput="h-6 text-xs"
                                                                register={register(`credits.${index}.note`, {
                                                                    maxLength: {
                                                                        value: 1000,
                                                                        message: translator('FORM_CONST.MAX_LENGTH_TEXT', {
                                                                            maxLength: 1000,
                                                                        }),
                                                                    },
                                                                })}
                                                                classError="whitespace-nowrap !text-[10px] !font-normal absolute -bottom-4"
                                                                inputProps={{ autoFocus: true }}
                                                            />
                                                        </div>
                                                    )}

                                                    {watch(`credits.${index}.note`) && !watch(`credits.${index}.isEditing`) && (
                                                        <div
                                                            onClick={() => setValue(`credits.${index}.isEditing`, true)}
                                                            className="cursor-pointer text-ellipsis line-clamp-2 text-xs"
                                                        >
                                                            {' '}
                                                            {watch(`credits.${index}.note`)}{' '}
                                                        </div>
                                                    )}

                                                    {watch(`credits.${index}.isEditing`) &&
                                                        watch(`credits.${index}.note`)?.length <= 1000 && (
                                                            <span
                                                                className={`mt-0.5 ${!!errors[`credits.${index}.note`]
                                                                    ? 'cursor-not-allowed'
                                                                    : 'cursor-pointer'
                                                                    }`}
                                                                onClick={() => {
                                                                    setValue(`credits.${index}.note`, watch(`credits.${index}.note`));
                                                                    setValue(`credits.${index}.isEditing`, false);
                                                                }}
                                                            >
                                                                <CheckIcon className="w-4 h-4 text-blue-500 bg-white" />
                                                            </span>
                                                        )}
                                                    {watch(`credits.${index}.isEditing`) && (
                                                        <span
                                                            className="mt-0.5 cursor-pointer"
                                                            onClick={() => {
                                                                setValue(`credits.${index}.note`, item?.note);
                                                                setValue(`credits.${index}.isEditing`, false);
                                                            }}
                                                        >
                                                            <XIcon className="w-4 h-4 bg-white" />
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                        <div
                                            onClick={() => onRemove(item?.value, index)}
                                            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
                                        >
                                            <XIcon className="text-red-500 w-5 h-5" />
                                        </div>
                                    </div>
                                );
                            })}

                        {isUseCredit && fields?.filter((item: any) => item?.credit_balance === 0)?.length > 0 && (
                            <div className="text-xs text-gray-500 p-1">
                                {translator('COURSES.LEARNER_CANNOT_ENROLL_MESSAGE')}
                            </div>
                        )}
                        {isUseCredit &&
                            fields
                                ?.map((item: any, index: number) => {
                                    if (item?.credit_balance > 0) return;
                                    return (
                                        <div className="grid grid-cols-8 text-xs py-2 relative" key={index}>
                                            <div className="col-span-2 text-ellipsis truncate px-1 mt-0.5">{item?.name}</div>
                                            <div className="col-span-2 px-1 flex items-center gap-0.5">
                                                <InputField
                                                    name={`credits.${index}.amount_nocredit`}
                                                    className="!gap-0 text-gray-800"
                                                    classNameInput="h-6 disabled:cursor-not-allowed !w-[60px]"
                                                    disabled={item?.credit_balance === 0}
                                                    labelHorizontal={`/${item?.credit_balance}`}
                                                    classLabelHorizontal="!text-gray-800 -ml-1"
                                                />
                                            </div>
                                            <div
                                                onClick={() => onRemove(item?.value, index)}
                                                className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer"
                                            >
                                                <XIcon className="text-red-500 w-5 h-5" />
                                            </div>
                                        </div>
                                    );
                                })}
                    </div>
                )}
            </div>

            <div className="field group-datepicker">
                <div className="w-1/2">
                    <label>
                        {translator('COURSES.START_DATE')}
                        <span className="text-red-500"> *</span>
                    </label>
                    <DatePickerInput
                        control={control}
                        name="start_date"
                        placeholderText={translator('COURSES.SELECT_START_DATE')}
                        minDate={new Date()}
                        maxDate={watch('end_date')}
                        positionIcon="right"
                        isRequired
                        classnames="!h-[38px] !bg-white"
                        iconCls="!top-3"
                        styles="!h-[38px]"
                    />
                </div>
                <div className="w-1/2">
                    <label>
                        {translator('COURSES.END_DATE')}
                        <span className="text-red-500"> *</span>
                    </label>
                    <DatePickerInput
                        control={control}
                        name="end_date"
                        placeholderText={translator('COURSES.SELECT_END_DATE')}
                        minDate={watch('start_date')}
                        isRequired
                        positionIcon="right"
                        iconCls="!top-3"
                        classnames="!h-[38px] !bg-white"
                        styles="!h-[38px]"
                    />
                </div>
            </div>

            <div className="field group-button">
                <ButtonOutline type="Button" onClick={() => onCloseModal()}>
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={
                        !isValid ||
                        (watch('credits')?.filter((credit) => credit?.amount > 0)?.length === 0 && isUseCredit) ||
                        (type === 'learning_group' && watch('credits').length === 0 && !isUseCredit)
                    }
                >
                    {translator('ENROLL')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
