import { PlusIcon } from '@heroicons/react/outline';
import { ButtonOutline } from '_shared';
import { ReorderAlt } from 'assets/icon';
import TrashIcon from 'assets/icon/TrashIcon';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ConnectForm } from '..';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import ReactTooltip from 'react-tooltip';

export const Levels = ({ skillId, checkDuplicate }) => (
    <ConnectForm>
        {({ register, formState: { errors }, control, watch, setError, clearErrors }: any) => {
            const [isCheckDuplicate, setIsCheckDuplicate] = useState(true);
            const { t: translator } = useTranslation();
            const { fields, append, remove, swap } = useFieldArray({
                control,
                name: 'levels',
            });

            const [draggingIndex, setDraggingIndex] = useState(null);

            const handleDragStart = (e, index) => {
                setDraggingIndex(index);
                e.dataTransfer.effectAllowed = 'move';
                e.dataTransfer.setData('text/html', e.target.parentNode);
                e.dataTransfer.setDragImage(e.target.parentNode, 20, 20);
            };

            const handleDragOver = (event) => {
                event.preventDefault();
            };

            const handleDrop = (e, index) => {
                e.preventDefault();
                if (draggingIndex === null) return;
                swap(draggingIndex, index);
                setDraggingIndex(null);
            };

            const handleRemoveLevel = (index) => {
                if (fields?.length) {
                    remove(index);
                }
            };

            const handleAddLevel = () => {
                append({ name: null, description: null });
            };

            useEffect(() => {
                if (isCheckDuplicate) {
                    checkDuplicate();
                    setIsCheckDuplicate(false);
                }
            }, [watch(), isCheckDuplicate]);

            useEffect(() => {
                ReactTooltip.rebuild();
            }, [watch()]);

            return (
                <div className="">
                    <div className="flex justify-between items-center">
                        <h1 className="text-gray-800 text-lg font-semibold">{translator('LEVELS')}</h1>
                        <ButtonOutline
                            className="!text-primary-500 !border-primary-500 flex items-center gap-1"
                            type="button"
                            onClick={handleAddLevel}
                        >
                            <PlusIcon className="w-5 h-5 text-primary-500" /> {translator('ADD_LEVEL')}
                        </ButtonOutline>
                    </div>
                    <div>
                        {fields?.map((field, index: number) => {
                            const isDisable = (fields?.length <= 1 && skillId === 'new') || watch(`levels.${index}`)?.associated_learners > 0 || watch(`levels.${index}`)?.associated_courses > 0;
                            return (
                                <ul key={field?.id} className="mt-5 flex flex-col rounded-lg bg-gray-50 cursor-grab pb-5">
                                    <div className="flex justify-between items-center p-5">
                                        <div
                                            draggable
                                            onDragStart={(e) => handleDragStart(e, index)}
                                            onDrop={(e) => handleDrop(e, index)}
                                            onDragOver={handleDragOver}
                                            className="flex items-center gap-2 "
                                        >
                                            <ReorderAlt />
                                            <span className="font-semibold text-base text-black ">Level {index + 1}</span>
                                            {skillId !== 'new' && (
                                                <>
                                                    <span className="text-gray-800 text-base pl-2 border-l border-gray-300">
                                                        {watch(`levels.${index}`)?.associated_learners || 0} {translator('LEARNERS')}
                                                    </span>
                                                    <span className="text-gray-800 text-base pl-2 border-l border-gray-300">
                                                        {watch(`levels.${index}`)?.associated_courses|| 0} {translator(translator('ASSOCIATED_COURSES'))}
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <button
                                            type="button"
                                            disabled={isDisable}
                                            onClick={() => handleRemoveLevel(index)}
                                            aria-label={translator('REMOVE_LEVEL')}
                                            data-for={"TOOLTIPS_SKILL_LEVEL_CANNOT_DELETE" + field?.id}
                                            data-tip={translator('TOOLTIPS_SKILL_LEVEL_CANNOT_DELETE')}
                                        >
                                            <TrashIcon fill={isDisable ? '#6b7280' : undefined} />
                                        </button>
                                        {isDisable && (
                                            <TooltipCustom
                                                classNameContainer="shadow-medium"
                                                id={"TOOLTIPS_SKILL_LEVEL_CANNOT_DELETE" + field?.id}
                                                content={translator('TOOLTIPS_SKILL_LEVEL_CANNOT_DELETE')}
                                                place="left"
                                                type="light"
                                            />
                                        )}
                                    </div>
                                    <div className="px-5">
                                        <InputField
                                            label={translator('LEVEL_NAME')}
                                            placeholder={translator('ENTER_LEVEL_NAME')}
                                            name={`levels.${index}.name`}
                                            isRequired={true}
                                            register={register(`levels.${index}.name`, {
                                                required: translator('FORM_CONST.REQUIRED_FIELD'),
                                                validate: {
                                                    shouldNotContainSpace: (value) => {
                                                        return value?.trim()?.length
                                                            ? true
                                                            : `${translator('FORM_CONST.REQUIRED_FIELD')}`;
                                                    },
                                                },
                                                maxLength: {
                                                    value: 255,
                                                    message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                                },
                                            })}
                                            handleBlur={() => setIsCheckDuplicate(true)}
                                            errors={errors}
                                            isArray
                                        />
                                    </div>

                                    <div className="px-5 mt-5">
                                        <TextareaField
                                            label={translator('LEVEL_DESCRIPTION')}
                                            placeholder={translator('ENTER_LEVEL_DESCRIPTION')}
                                            name={`levels.${index}.description`}
                                            maxLength={1000}
                                            isCountter={true}
                                            register={{
                                                ...register(`levels.${index}.description`, {
                    
                                                    maxLength: {
                                                        value: 1000,
                                                        message: translator('FORM_CONST.MAX_LENGTH_FIELD'),
                                                    },
                                                }),
                                            }}
                                            errors={errors}
                                            isArray
                                        />
                                    </div>
                                </ul>
                            );
                        })}
                    </div>
                </div>
            );
        }}
    </ConnectForm>
);
