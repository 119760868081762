import { useState, useEffect } from 'react';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './Filter.scss';
import { useTranslation } from 'react-i18next';
import { REPORT_STATUS_LIST } from 'components/Workspace/Course/CourseBuilder/components/CourseReport/constant';
import workspaceService from 'services/workspace.service';
import { useParams } from 'react-router-dom';
import { checkPermission } from 'utils/commonFun';

interface IFilter {
    onChangeFilter: (value: any) => void;
    isFirstLoad: boolean;
}

const Filter = ({
    onChangeFilter,
    isFirstLoad,
}: IFilter) => {
    const params: { id: string } = useParams();
    const { t: translator } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('');
    const [courseSelected, setCourseSelected] = useState<any>([]);
    const [listCourse, setListCourse] = useState<any>([]);
    const [paginationCourse, setPaginationCourse] = useState<any>({ page: 1 });

    const getCourses = async (page) => {
        let payload: any = {
            title: inputSearchCourse,
            page: page ? page : paginationCourse.page,
            per_page: 20,
            order: 'desc',
            sort_by: 'updated_at',
        };

        if (!payload?.title) delete payload.title;

        const response = await workspaceService.getCoursesList(params.id, payload);

        if (response) {
            const formattedData = response?.items?.map((item) => {
                return {
                    id: item?.id,
                    name: item?.title,
                    value: item?.id,
                };
            });
            if (page === 1) setListCourse(formattedData);
            else setListCourse([...listCourse, ...formattedData]);
            setPaginationCourse({ ...paginationCourse, total: response?.total, page });
        }

        return [];
    };

    useEffect(() => {
        if (!isFirstLoad) {
            let listFilter: any = {
                name: keyword?.trim(),
                course_ids: JSON.stringify(courseSelected?.map(item => item?.id))
            };
            if (!courseSelected || courseSelected?.length == 0) delete listFilter.course_ids
            if (!listFilter?.name) delete listFilter.name
            onChangeFilter(listFilter);
        }
    }, [keyword, courseSelected]);

    useEffect(() => {
        if (checkPermission('course', 'R')) getCourses(1);
    }, [inputSearchCourse]);

    return (
        <div className="flex gap-3 flex-wrap relative z-10 w-full">
            <SearchBox placeholder={translator('SEARCH')} className="max-w-[280px]" onSubmit={(value) => setKeyword(value)} />
            {checkPermission('course', 'R') && <SelectBox
                label={translator("COURSE")}
                isMulti
                onSearch={setInputSearchCourse}
                onSelect={setCourseSelected}
                data={listCourse}
                selectedItems={courseSelected}
                total={paginationCourse?.total}
                showmore={() => getCourses(paginationCourse?.page + 1)}
            />}
        </div>
    );
};

export default Filter;
