import React, { useContext } from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { Link, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import EmptyIcon from 'assets/img/empty-state/user.png';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';
import { UserContext } from 'contexts/User/UserContext';

interface EnrollmentTableProps {
    workspaceId: string;
    dataTable: any;
}

const EnrollmentTable: React.FC<EnrollmentTableProps> = ({ workspaceId, dataTable }) => {
    const { t } = useTranslation();
    const {
        userState: { result },
    } = useContext(UserContext);
    const columns = React.useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={t("ENROLLMENT.COURSE_ENROLLMENT")} />,
                Cell: ({ row }) => {
                    return (
                        <div className="flex items-center py-2">
                            {/* @ts-ignore */}
                            <Link
                                to={`/workspace/${workspaceId}/management/courses/${row?.original?.course?.id}?tab=people&subtab=leaner&enrollmentId=${row?.original?.course_enrollment?.id}&view=information`}
                                className="text-xs text-primary-500 font-semibold cursor-pointer truncate"
                                data-tip={row.original?.course?.title}
                                data-for={row.original?.id?.toString() + '_name'}
                                target="_blank"
                            >
                                {row.original?.course?.title}
                            </Link>
                            {/* @ts-ignore */}
                            <ReactTooltip
                                place="bottom"
                                type="dark"
                                effect="float"
                                id={row.original?.id?.toString() + '_ame'}
                                getContent={(content) => <div className="w-56 h-auto">{content}</div>}
                                disable={row.original?.course?.title?.length < 18}
                            />
                        </div>
                    );
                },
                accessor: 'course',
                width: 180,
            },
            {
                Header: () => <RenderTableHeader value={t("ENROLLMENT.ENROLLMENT_START_DATE")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs(row?.original?.course_enrollment?.start_date)?.tz(result?.time_zone)?.format("DD/MM/YYYY") || '-'} />;
                },
                accessor: 'start_date',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={t("ENROLLMENT.ENROLLMENT_END_DATE")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs(row?.original?.course_enrollment?.end_date)?.tz(result?.time_zone)?.format("DD/MM/YYYY") || '-'} />;
                },
                accessor: 'end_date',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={t("ENROLLMENT.ENROLLMENT_DATE")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={dayjs(row?.original?.course_enrollment?.created_at)?.tz(result?.time_zone)?.format("DD/MM/YYYY") || '-'} />;
                },
                accessor: 'created_at',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={t("CREDITS")} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row?.original?.credit_balance?.total_credit} />;
                },
                accessor: 'total_credit',
                width: 140,
            },
            {
                Header: () => <RenderTableHeader value={t('STATUS')} />,
                accessor: 'display_status',
                Cell: ({ row }: any) => <EnrollmentStatus status={row?.values?.display_status} />,
                width: 120,
            },
        ],
        [dataTable]
    );

    return (
        <Table data={dataTable} columns={columns} emptyIcon={EmptyIcon} emptyContent={t("ENROLLMENT.NO_ENROLLMENTS")} />
    );
};

export default EnrollmentTable;
