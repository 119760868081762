import { downloadCSV } from "components/Reports/components/common";
import { ASSIGNMENT_STATUS } from "constant/course.const";
import dayjs from "dayjs";

export const ExportGradeCSV = (data, file_name, translator) => {
    try {
        let headers: string[], rows: string[][];
        headers = [
            'Assignment ID',
            'Assignment Name',
            'Learner ID',
            'Learner name',
            'Learning Group ID',
            'Learning Group Name',
            'Lesson ID',
            'Lesson Name',
            'Assignment Status',
            'Grade',
            'Completion',
            'Time Spent',
            'Due Date',
            'Submission Date',
            'Graded By'
        ];
        rows = data?.map((el: any, index) => [
            el?.id || '-',
            el?.name || '-',
            el?.learner?.id || '-',
            el?.learner?.display_name || '-',
            el?.learning_group?.id || '-',  
            el?.learning_group?.name || '-',    
            el?.lesson?.id || '-',
            el?.lesson?.title || '-',
            !!ASSIGNMENT_STATUS(translator)[el?.state] 
                ? ASSIGNMENT_STATUS(translator)[el?.state] + (el?.overdue_tag ? ' - Overdue' : '')
                : '-',
            el?.final_score != null && el?.state == 'completed' ? Math.round(el?.final_score) + '/100' : '-',
            el?.completion_percentage != null ? Math.round(el?.completion_percentage) + '%' : '-',
            el?.total_time_spent ? Math?.round(el?.total_time_spent / 60) + 'm' : '-',
            el?.end_time ? dayjs(el?.end_time).format("DD/MM/YYYY") : '-',
            el?.completion_date ? dayjs(el?.completion_date).format("DD/MM/YYYY") : '-',
            el?.graded_by?.display_name || '-',
        ]);
        downloadCSV([headers, ...rows], file_name);
    } catch {
    }
}