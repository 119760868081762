import dayjs from 'dayjs';
import _ from 'lodash';

export const WeekDay = (translator: Function) => {
    const type = {
        0: translator('WEEK.MONDAY'),
        1: translator('WEEK.TUESDAY'),
        2: translator('WEEK.WEDNESDAY'),
        3: translator('WEEK.THURSDAY'),
        4: translator('WEEK.FRIDAY'),
        5: translator('WEEK.SATURDAY'),
        6: translator('WEEK.SUNDAY'),
    };
    return type;
};

export const StatusContent = (translator: Function, t: string) => {
    let text = t;
    switch (t) {
        case 'draft':
            text = translator('STATUS_CONTENT.DRAFT');
            break;
        case 'pending':
            text = translator('STATUS_CONTENT.PENDING');
            break;
        case 'public':
            text = translator('STATUS_CONTENT.PUBLISH');
            break;

        default:
            break;
    }
    return text;
};

export const ListWeekDay = (translator: Function) => {
    const type = [
        {
            name: translator('WEEK.MON'),
            value: 0,
        },
        {
            name: translator('WEEK.TUE'),
            value: 1,
        },
        {
            name: translator('WEEK.WED'),
            value: 2,
        },
        {
            name: translator('WEEK.THU'),
            value: 3,
        },
        {
            name: translator('WEEK.FRI'),
            value: 4,
        },
        {
            name: translator('WEEK.SAT'),
            value: 5,
        },
        {
            name: translator('WEEK.SUN'),
            value: 6,
        },
    ];
    return type;
};

export const SessionDateFormat = 'ddd, DD MMM YYYY HH:mm';

export const SessionServiceFormat = 'YYYY-MM-DDTHH:mm:ss';

export const setEmptyOrStr = (v: any) => {
    if (_.isString(v) && _.isEmpty(v)) return undefined;
    else return v;
};

export const changeTimeZone = (date, timeZone) => {
    if (typeof date === 'string') {
        return new Date(
            new Date(date).toLocaleString('en-US', {
                timeZone,
            })
        );
    }
};

export const handleCheckEnv = () => {
    const env = process.env.REACT_APP_S3_PATH;
    let idWs;
    switch (env) {
        case 'dev':
            idWs = 142;
            break;
        case 'production':
            idWs = 113;
            break;
        case 'staging':
            idWs = 2;
            break;

        default:
            break;
    }

    return idWs;
};

export function roundNumber(value, precision = 2) {
    var multiplier = Math.pow(10, precision);
    return Math.round(value * multiplier) / multiplier;
}

export function roundNumberWithMins(value, precision = 2) {
    const mins = value / 60 || 0;
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(mins * multiplier) / multiplier;
}

export function roundNumberWithHours(value) {
    const hours = Math.floor(value / 3600) || 0;
    const mins = (value % 3600) / 60;
    var multiplier = Math.pow(10, 0);
    return `${hours}hrs ${Math.round(mins * multiplier) / multiplier}mins`;
}

export function addHoursAndMins(date, hours, mins) {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    newDate.setMinutes(newDate.getMinutes() + mins);
    return newDate;
}

export const defaultPagination = {
    page: 1,
    per_page: 20,
    order: 'desc',
    sort_by: 'updated_at',
    total: 1,
};

export const PREVIOUS_DAYS_COMPARE = 90;

export const WEEKDAYS = [
    {
        weekday: 0,
    },
    {
        weekday: 1,
    },
    {
        weekday: 2,
    },
    {
        weekday: 3,
    },
    {
        weekday: 4,
    },
    {
        weekday: 5,
    },
    {
        weekday: 6,
    },
];

export const PDF_VIEWER_PATH = '/pdfjs-dist/web/viewer.html?file=';
export const PDF_INSTANCE_PATH = '//mozilla.github.io/pdf.js/build/pdf.mjs';
export const PDF_WORKER_PATH = '//mozilla.github.io/pdf.js/build/pdf.worker.mjs';

export const ACCEPT_FILE_EXTENSIONS = ['video/mp4'];

export const STATUS_COLOR = {
    active: '#22C55E',
    increase: '#16A34A',
    enrolled: '#0071CE',
    deactivated: '#EF4444',
    decrease: '#EF4444',
    revoked: '#EF4444',
    expired: '#6B7280',
}

export const STATUS_BG_COLOR = {
    active: '#F0FDF4',
    enrolled: '#E5F4FF',
    deactivated: '#FEF2F2',
    revoked: '#FEF2F2',
    expired: '#F9FAFB',
}

export const STATUS_BORDER_COLOR = {
    active: '#86EFAC',
    revoked: '#FCA5A5',
    expired: '#D1D5DB',
}

export const STATUS_LEARNER_ENROLL_OPTION = (t) => [
    { name: t('Enrolled'), value: 'enrolled' },
    { name: t('ACTIVE'), value: 'active' },
    { name: t('Expired'), value: 'expired' },
    { name: t('DEACTIVATED'), value: 'deactivated' },
];
