import { PlusIcon, XIcon } from '@heroicons/react/outline';
import SelectBox from '_shared/components/Select';
import { User } from '_shared/components/User/User';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import skillService from 'services/skill.service';

interface IStep1 {
    control: any;
    watch: any;
    setError: any;
    userData?: any;
    errors: any;
    setIsCompletedStep1: any;
    setValue: any;
    clearErrors: any;
    userList?: any[];
}

export default function Step1(props: IStep1) {
    const { t: translator } = useTranslation();
    const params: { id: string } = useParams();
    const [pagination, setPagination] = React.useState<any>({ page: 1, per_page: 10, total: 0 });
    const [loading, setLoading] = React.useState(false);

    const { control, watch, setError, userData, errors, setValue, setIsCompletedStep1, clearErrors, userList } = props;

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'skills',
    });

    const [skillOptions, setSkillOptions] = useState([]);
    const [isCheckDuplicate, setIsCheckDuplicate] = useState(false);

    const getSkillOptions = (page = 1) => {
        setLoading(true);
        const param = {
            ...pagination,
            page,
            order: 'asc',
            sort_by: 'name',
            status: 'published'
        }
        skillService
            .getSkillsService(params.id, param)
            .then((res) => {
                const formatSkill = res?.data?.items.map((item) => {
                    return {
                        ...item,
                        value: item?.id,
                        levels: item?.levels?.map((level) => {
                            return {
                                ...level,
                                name: level?.name,
                                value: level?.id,
                            };
                        }),
                    };
                });
                if (page === 1) setSkillOptions(formatSkill);
                else setSkillOptions([...skillOptions, ...formatSkill]);
                setPagination({ ...pagination, page: res?.data?.page, total: res?.data?.total });
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getSkillOptions();
    }, []);

    const handleRemoveSkill = (index) => {
        if (fields?.length) {
            remove(index);
            checkDuplicate();
        }
    };

    const handleAddSkill = () => {
        append({ skill: null, level: null });
    };

    const checkDuplicate = () => {
        const skills = watch('skills');
        if (skills?.length > 1) {
            const duplicateIndex = skills?.map((item, index) => {
                if (!!!item?.skill?.[0]?.id || !!!item?.level?.[0]?.id) return -1;
                return skills.findIndex((skill, i) => {
                    return (
                        skill?.skill?.[0]?.id === item?.skill?.[0]?.id && skill?.level?.[0]?.id === item?.level?.[0]?.id && i !== index
                    );
                });
            });
            for (let i = 0; i < duplicateIndex.length; i++) {
                if (duplicateIndex[i] !== -1) {
                    setError(`skills[${i}].skill`, { type: 'manual', message: translator('DUPLICATE_SKILL') });
                } else {
                    clearErrors(`skills[${i}].skill`);
                }
            }
        }
    };

    useEffect(() => {
        if (isCheckDuplicate) {
            checkDuplicate();
            setIsCheckDuplicate(false);
        }
    }, [watch(), isCheckDuplicate]);

    useEffect(() => {
        if (fields?.length === 0 || !!!watch(`skills.${fields?.length - 1}.skill`) || !!!watch(`skills.${fields?.length - 1}.level`)) {
            setIsCompletedStep1(false);
        } else {
            setIsCompletedStep1(true);
        }
    }, [watch()]);

    return (
        <div className=''>
            <div className="text-gray-800 text-sm font-semibold">{translator('LEARNERS')}:</div>
            <div className="flex gap-2 flex-wrap mt-2">
                {userList ? userList?.map((user) => <div className='flex items-center gap-2'>
                    <User
                        name={user?.display_name}
                        avatar={user?.avatar_url}
                        link={`/workspace/${params.id}/management/users/${user?.id}?tab=information`}
                        className="!text-sm"
                    /> </div>) : <User
                    name={userData?.display_name}
                    avatar={userData?.avatar_url}
                    link={`/workspace/${params.id}/management/users/${userData?.id}?tab=information`}
                    className="!text-sm"
                />}

            </div>
            <div className="text-gray-800 text-sm mt-2 flex flex-col gap-2">
                <p>{translator('SKILL_SELECTION_CONTENT_1')}</p>
                <p>{translator('SKILL_SELECTION_CONTENT_2')}</p>
            </div>
            <div className="min-h-[380px] overflow-y-auto flex flex-col gap-2 mt-5">
                <div className="w-full flex">
                    <div className="text-sm font-semibold w-1/2 pl-6">{translator('SKILLS')}</div>
                    <div className="text-sm font-semibold w-1/2">{translator('LEVELS')}</div>
                </div>
                <div className="mt-2 flex flex-col gap-2">
                    {fields?.map((field, index: number) => {
                        return (
                            <div key={field?.id} className="flex gap-2 items-start">
                                <div className="flex-none w-4">{index + 1}.</div>
                                <div className="w-1/2">
                                    <SelectBox
                                        placeholder={translator('SELECT_A_SKILL')}
                                        data={skillOptions}
                                        value={watch(`skills.${index}.skill`)?.slice() || []}
                                        loading={loading}
                                        getData={getSkillOptions}
                                        pagination={pagination}
                                        control={control}
                                        name={`skills.${index}.skill`}
                                        errors={errors}
                                        isRequired={true}
                                        isMulti={false}
                                        numberOfItemDisplayed={1}
                                        formatOptionLabel={(item) => (
                                            <div className="text-ellipsis text-sm truncate max-w-md">{item?.name} </div>
                                        )}
                                        formatOptionValue={(item) => (
                                            <div className="text-ellipsis text-sm truncate max-w-md">{item?.name} </div>
                                        )}
                                        actionAfterSelectItem={() => {
                                            setValue(`skills.${index}.level`, null);
                                            setIsCheckDuplicate(true);
                                        }}
                                        actionAfterRemoveAllItem={() => {
                                            setValue(`skills.${index}.level`, null);
                                            setIsCheckDuplicate(true);
                                        }}
                                        actionAfterRemoveItemSelected={() => {
                                            setValue(`skills.${index}.level`, null);
                                            setIsCheckDuplicate(true);
                                        }}
                                        className={`!h-8 ${errors?.skills?.[index]?.skill ? '!border-red-500' : ''}`}
                                    />
                                </div>

                                <div className="w-1/2">
                                    <SelectBox
                                        placeholder={translator('SELECT_A_LEVEL')}
                                        data={watch(`skills.${index}.skill`)?.[0]?.levels || []}
                                        value={watch(`skills.${index}.level`) || []}
                                        loading={loading}
                                        pagination={pagination}
                                        control={control}
                                        name={`skills.${index}.level`}
                                        errors={errors}
                                        isRequired={true}
                                        isMulti={false}
                                        numberOfItemDisplayed={1}
                                        formatOptionLabel={(item) => (
                                            <div className="text-ellipsis text-sm truncate max-w-md">{item?.name} </div>
                                        )}
                                        formatOptionValue={(item) => (
                                            <div className="text-ellipsis text-sm truncate max-w-md">{item?.name} </div>
                                        )}
                                        className={`!h-8 ${errors?.skills?.[index]?.skill ? '!border-red-500' : ''}`}
                                        actionAfterSelectItem={() => setIsCheckDuplicate(true)}
                                        actionAfterRemoveAllItem={() => setIsCheckDuplicate(true)}
                                        actionAfterRemoveItemSelected={() => setIsCheckDuplicate(true)}
                                        isDisabled={!watch(`skills.${index}.skill`)?.length}
                                    />
                                </div>

                                <button
                                    onClick={() => handleRemoveSkill(index)}
                                    type="button"
                                    title={''}
                                    className="flex items-center justify-center flex-none text-red-500 hover:bg-gray-300 rounded-full w-8 h-8 transition"
                                >
                                    <XIcon className="w-5 h-5" />
                                </button>
                            </div>
                        );
                    })}
                </div>
                <div className="flex gap-2 mt-2 items-center">
                    <div className="flex-none w-4 text-gray-400 ">{fields?.length + 1}</div>
                    <button type="button" className="addSkillsModal-button" onClick={handleAddSkill}>
                        <PlusIcon className="w-5 h-5 mr-1" />
                        {translator('ADD_A_SKILL')}
                    </button>
                </div>
            </div>
        </div>
    );
}
