import httpClient, { RestfulService } from './restful.service';
import axios from './restful.service';
import qs from 'qs';
// CONSTANTS
import { WORKSPACE, HOST_URL, CLASS, ACADEMICS, LEARNING_GROUP, COMPANY, STUDENT } from 'constant/api.const';
// TYPES
import { CreateWorkspaceArgsType } from 'types/CreateWorkspace.type';
import {
    GetListOfWorkspaceBodyType,
    GetWorkspaceDetailArgs,
    LessonResponse,
    TagType,
    WorkspaceItem,
} from 'types/GetListOfWorkspace.type';
import { InviteMembersAgsType } from 'types/InviteMembers.type';
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import {
    CourseParam,
    CreateTagBody,
    ApprovalBody,
    TagResponse,
    TagsInBodyType,
    UpdateLessonBody,
    WorkspaceParams,
    UpdateCourseParam,
    AddTagCourseParam,
    CreateCourseParam,
} from 'types/ApiData.type';
import { Key } from 'readline';
import { CourseDetailType, ICourseResponse } from 'types/Courses.type';
import { CreateLessonParam, UpdateLessonParams } from 'types/Lesson.type';
import lodash, { isArray } from 'lodash';
import { CustomFieldsType } from 'types/CustomFields.type';
import { parseParams } from 'utils/commonFun';

const inviteMembers = async (args: InviteMembersAgsType) => {
    if (args) {
        const { members, workspaceId } = args;
        const res = await RestfulService.postApi(HOST_URL + WORKSPACE.INVITE_WORKSPACE(workspaceId), { ...members });
        if (!!res.data.error) {
            throw res.data;
        }
        return res;
    }
};

const resetInviteMembers = async (worksapceId, userId) => {
    const url = HOST_URL + `/workspaces/${worksapceId}/members/${userId}/resend-invitation`;
    return RestfulService.postApi(url);
};

const getListOfWorkspace = async (param?: Record<any, any>) => {
    const params =
        (param &&
            `${Object.keys(param)
                .map((i) => {
                    if (i && param[i]) return `${i}=${param[i]}`;
                    return null;
                })
                .join('&')}`) ||
        null;

    const url = `${HOST_URL}${WORKSPACE.GET_CURRENT_USER_OF_WORKSPACE}${params ? `?${params}` : ''}`;
    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const createWorkspace = (args: CreateWorkspaceArgsType) => {
    if (args) {
        const { name, avatar, default_currency_id } = args;
        return httpClient.post(HOST_URL + WORKSPACE.CREATE_WORKSPACE, {
            name,
            avatar,
            default_currency_id,
        });
    }
};

const getWorkspaceDetail = async (args: GetWorkspaceDetailArgs) => {
    if (args) {
        const { id } = args;
        const res = await RestfulService.getApi(HOST_URL + WORKSPACE.GET_WORKSPACE_DETAIL(id));

        if (!!res.data.error) {
            throw res.data;
        }
        return res.data;
    }
};

const updateWorkspaceInformation = async (workspaceId: string, params?: WorkspaceParams): Promise<WorkspaceItem | undefined> => {
    const res = await RestfulService.patchApi(`${HOST_URL}/workspaces/${workspaceId}`, params);

    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getWorkspaceMembers = async (args: GetWorkspaceDetailArgs, param?: Record<any, any>, parent?: boolean) => {
    if (args) {
        const params =
            (param &&
                `${Object.keys(param)
                    .map((i) => {
                        if (i && param[i]) return `${i}=${param[i]}`;
                        return null;
                    })
                    .join('&')}`) ||
            null;
        const { id } = args;
        const url = `${HOST_URL}/workspaces/${id}/members${params ? `?${params}` : ''}${parent ? '&type=student&type=parent' : ''}`;
        const res = await RestfulService.getApi(url);

        if (!!res.data.error) {
            throw res.data;
        }
        return res.data;
    }
};

const getWorkspaceMembersService = (workSpaceId: string, params?: Record<any, any>) => {
    if (workSpaceId) {
        const url = WORKSPACE.GET_WORKSPACE_MEMBERS(workSpaceId);
        return httpClient.get(url, { params, paramsSerializer: (p) => parseParams(p) });
    }
};

const updateWorkspaceMembers = async (workspaceId: string, userId: string, body) => {
    const req = await RestfulService.putApi(`${HOST_URL}/workspaces/${workspaceId}/members/${userId}`, {
        ...body,
    });
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 204) {
        return true;
    }
};

const updateWorkspacePartialMember = async (workspaceId: string, userId: string, body) => {
    const req = await RestfulService.patchApi(`${HOST_URL}/workspaces/${workspaceId}/members/${userId}`, {
        ...body,
    });
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 204) {
        return true;
    }
};

const updateWorkspaceMembersService = async (workspaceId: string, userId: string, body: { role: string; status: string }) => {
    if (workspaceId && userId) {
        const url = `/workspaces/${workspaceId}/members/${userId}`;
        return httpClient.put(url, body);
    }
};

const getLessonList = async (workspaceId: number | string, param?: Record<any, any>) => {
    const params =
        (param &&
            `${Object.keys(param)
                .map((i: any) => `${i}=${param[i]}`)
                .join('&')}`) ||
        null;
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons${params ? `?${params}` : ''}`;

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const createlessonTags = async (workspaceId: string, body: CreateTagBody): Promise<TagType | undefined> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/tags`;

    const req = await RestfulService.postApi(url, {
        name: body.name,
        color: JSON.stringify(body.color),
    });
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 200) {
        return req.data;
    }
};

const getLessonTags = async (workspaceId: string, name?: string, param?: Record<any, any>): Promise<TagResponse | null> => {
    const params =
        (param &&
            `${Object.keys(param)
                .map((i) => {
                    if (i && param[i]) return `${i}=${param[i]}`;
                    return null;
                })
                .join('&')}`) ||
        '';
    const url = `${HOST_URL}/workspaces/${workspaceId}/tags?${name ? `name=${name}&${params}` : params}`;

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getTagsService = (workspaceId: string, params: Record<any, any>): Promise<any | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/tags`;
        return httpClient.get(url, { params });
    }
};

const createTagService = async (workspaceId: string, body: any): Promise<TagType | undefined> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/tags`;

    const req = await RestfulService.postApi(url, {
        name: body.name,
        color: JSON.stringify(body.color),
        // type: []
    });
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 200) {
        return req.data;
    }
};

const updateTagService = async (workspaceId: string, tag_id: string, params: any): Promise<CustomFieldsType | undefined> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/tags/${tag_id}`;
    console.log('tag_id: ', tag_id);

    const req = await httpClient.put(url, params);
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 200) {
        return req.data;
    }
};

const createCustomFieldService = async (workspaceId: string, body: any): Promise<CustomFieldsType | undefined> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/custom-fields`;

    const req = await RestfulService.postApi(url, body);
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 200) {
        return req.data;
    }
};

const deleteCustomFieldService = (workspaceId: string, id: any): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/custom-fields/${id}`;
        return httpClient.delete(url);
    }
};

const getDetailCustomFieldService = (workspaceId: string, id: any): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/custom-fields/${id}`;
        return httpClient.get(url);
    }
};

const getCustomFieldsService = (workspaceId: string, params: Record<any, any>): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/custom-fields`;
        return httpClient.get(url, { params });
    }
};

const getLessonCustomFieldService = (workspaceId: string, lessonId: string): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/lessons/${lessonId}/custom-fields`;
        return httpClient.get(url);
    }
};

const getCourseCustomFieldService = (workspaceId: string, courseId: string): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/courses/${courseId}/custom-fields`;
        return httpClient.get(url);
    }
};

const updateCustomFieldsService = async (workspaceId: string, id: string, params: any): Promise<CustomFieldsType | undefined> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/custom-fields/${id}`;
    const req = await httpClient.put(url, params);
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 200) {
        return req.data;
    }
};

const getCustomFieldByTypeService = (workspaceId: string, params: any): Promise<TagResponse | null> => {
    if (workspaceId && params?.id) {
        const url = `/workspaces/${workspaceId}/${params.type}/${params.id}/custom-fields`;
        return httpClient.get(url, { params: { order: 'desc', sort_by: 'created_at' } });
    }
};

const updateCustomFieldByTypeService = (workspaceId: string, params: any): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/${params.type}/${params.id}/custom-fields`;
        return httpClient.post(url, { custom_fields: params?.custom_fields });
    }
};

const renameFolderOfResource = (workspaceId: string, params: any) => {
    const url = `/workspaces/${workspaceId}/resources/rename-folder`;
    return httpClient.post(url, params);
};

const createLesson = async (workspaceId: string, body?: CreateLessonParam) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons`;
    const resBody = body || {
        title: 'Untitiled Lesson',
        skill_summary: ' ',
    };

    const res = await RestfulService.postApi(url, resBody);
    if (!!res.data.error) {
        throw res.data;
    }
    return res;
};

const cloneLesson = async (workspaceId: string, lessonId: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/clone`;
    const res = await RestfulService.postApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res;
};

const upsertCustomfiled = async (workspaceId: string, lessonId: string, body: any) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/custom-fields`;

    const res = await RestfulService.postApi(url, body);
    if (!!res.data.error) {
        throw res.data;
    }
    return res;
};

const upsertCustomfiledCourse = async (workspaceId: string, courseId: string, body: any) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/custom-fields`;
    const res = await RestfulService.postApi(url, body);
    if (!!res.data.error) {
        throw res.data;
    }
    return res;
};

const upsertCustomfiledH5p = async (workspaceId: string, h5pId: string, body: any) => {
    const url = `${HOST_URL}/h5p/workspaces/${workspaceId}/${h5pId}/custom-fields`;
    const res = await RestfulService.postApi(url, body);
    if (!!res.data.error) {
        throw res.data;
    }
    return res;
};

const getH5pCustomFieldService = (workspaceId: string, h5pId: string, params?: any): Promise<TagResponse | null> => {
    if (workspaceId) {
        const url = `h5p/workspaces/${workspaceId}/${h5pId}/custom-fields`;
        return httpClient.get(url, { params });
    }
};

const getLessonDetail = async (workspaceId: string, lessonId: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}`;

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getAssociatedLearningPath = (workspaceId: string, lessonId: string, params?: any) => {
    return httpClient.get(`/workspaces/${workspaceId}/lessons/${lessonId}/learning-paths`, { params });
};

const updateLessonDetail = async (workspaceId: string, lessonId: string, requestBody: UpdateLessonBody) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}`;

    const res = await RestfulService.putApi(url, {
        ...requestBody,
    });
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const updatePartialLesson = async (workspaceId: string, lessonId: string, requestBody: UpdateLessonParams) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}`;

    const res = await RestfulService.patchApi(url, {
        ...requestBody,
    });
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const attachTagsForLesson = async (workspaceId: string, lessonId: string, listTags: TagsInBodyType) => {
    if (listTags.length) {
        const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/tags`;
        const body = { tags: listTags };
        const res = await RestfulService.postApi(url, {
            ...body,
        });
        if (!!res.data.error) {
            throw -1;
        }
        return 1;
    }
    return 0;
};

// const attachTagsForLesson = async (
//     workspaceId: string,
//     lessonId: string,
//     listTags: TagsInBodyType
// ) => {
//     if (listTags.length) {
//         const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/tags`;
//         const body = { tags: listTags };
//         const res = await RestfulService.postApi(url, {
//             ...body,
//         });
//         if (!!res.data.error) {
//             throw res.data;
//         }
//         return true;
//     }
// };

const detachTagsForLesson = async (workspaceId: string, lessonId: string, tagId: number) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/tags/${tagId}`;

    const res = await RestfulService.deleteApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const detachManyTagsForLesson = async (workspaceId: string, lessonId: string, listTags: TagsInBodyType) => {
    if (listTags.length) {
        const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/tags`;
        const body = { tags: listTags };
        const res = await RestfulService.deleteApi(url, {
            ...body,
        });
        if (!!res.data.error) {
            throw -1;
        }
        return 1;
    }
    return 0;
};

const createLessonApproval = async (workspaceId: string, lessonId: string, body: ApprovalBody) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/approval`;

    const res = await RestfulService.postApi(url, body);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const cancelLessonApproval = async (workspaceId: string, lessonId: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/approval`;

    const res = await RestfulService.deleteApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const approveLessonApproval = async (workspaceId: string, lessonId: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/approve`;

    const res = await RestfulService.postApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

//move lesson to trash
const removeLesson = async (workspaceId: string, lessonId: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/trash`;

    const res = await RestfulService.postApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

//recover from trash
const recoverLesson = async (workspaceId: string, lessonId: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/recover`;
    console.log('reqrqer');

    const res = await RestfulService.postApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const getPendingAdminList = async (workspaceId: string, order: 'asc' | 'desc', page: number, perPage?: number) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/contents?status=pending&sort_by=updated_at&page=${page}&per_page=${
        perPage || 10
    }&order=${order}`;

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getAdminList = async (workspaceId: string, params?: Record<any, any>) => {
    const p: string[] = [];
    if (params) {
        Object.keys(params).forEach((i) => {
            if (i && params[i]) {
                p.push(`${i}=${params[i]}`);
            }
        });
    }
    const url = `${HOST_URL}/workspaces/${workspaceId}/contents${p ? `?${p.join('&')}` : ''}`;

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const approvePendingItem = async (workspaceId: string, type: 'lesson' | 'course' | 'h5p_content', id: number) => {
    switch (type) {
        case 'lesson':
            const lessRes = await RestfulService.postApi(`${HOST_URL}/workspaces/${workspaceId}/lessons/${id}/approve`);
            if (lessRes.status === 204) {
                return true;
            }
            if (!!lessRes.data.error) {
                throw lessRes.data.error;
            }
            return false;
        case 'h5p_content':
            const h5PRes = await RestfulService.postApi(`${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/approve`);
            if (!!h5PRes.data.error) {
                throw h5PRes.data.error;
            }

            return true;

        case 'course':
            const courseRes = await RestfulService.postApi(`${HOST_URL}/workspaces/${workspaceId}/courses/${id}/approve`);
            if (!!courseRes.data.error) {
                throw courseRes.data.error;
            }
            return true;

        default:
            break;
    }
    return false;
};

const declinePendingItem = async (workspaceId: string, type: 'lesson' | 'course' | 'h5p_content', id: number) => {
    switch (type) {
        case 'lesson':
            const lessRes = await RestfulService.deleteApi(`${HOST_URL}/workspaces/${workspaceId}/lessons/${id}/approval`);
            if (!!lessRes.data.error) {
                throw lessRes.data.error;
            }
            if (lessRes.status === 204) {
                return true;
            }
            return false;
        case 'h5p_content':
            const h5PRes = await RestfulService.deleteApi(`${HOST_URL}/h5p/workspaces/${workspaceId}/${id}/approval`);
            if (!!h5PRes.data.error) {
                throw h5PRes.data.error;
            }
            if (h5PRes.status === 204) {
                return true;
            }
            return false;
        case 'course':
            const courseRes = await RestfulService.deleteApi(`${HOST_URL}/workspaces/${workspaceId}/courses/${id}/approval`);
            if (!!courseRes.data.error) {
                throw courseRes.data.error;
            }
            if (courseRes.status === 204) {
                return true;
            }
            return false;
        default:
            break;
    }
};



//courses
const getCoursesList = async (workspaceId: string, params?: CourseParam) => {
    const formatParams = {
        ...params,
    }
    let url = `${HOST_URL}/workspaces/${workspaceId}/courses${params ? `?${qs.stringify(formatParams)}` : ``}`;
    if (isArray(params?.enrollment_type)) {
        delete formatParams.enrollment_type
        const enrollment_type = params.enrollment_type;
        const  enrollmentTypeParams = enrollment_type.map((v) => (`enrollment_type=${v}`))?.join('&');
        url = `${HOST_URL}/workspaces/${workspaceId}/courses${`?${qs.stringify(formatParams)}&${enrollmentTypeParams}`}`;
    }

    const res = await RestfulService.getApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const getCourseDetail = async (workspaceId: string | number, courseId: string | number) => {
    if (courseId) {
        const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}`;

        const res = await RestfulService.getApi(url);
        if (!!res.data.error) {
            throw res.data;
        }
        return res.data;
    }
};

const updateCourseDetail = async (
    workspaceId: string | number,
    courseId: string | number,
    params?: UpdateCourseParam
): Promise<CourseDetailType> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}`;

    const res = await RestfulService.patchApi(url, params);
    if (!!res.data.error) {
        throw res.data;
    }
    return res.data;
};

const attachCourseTags = async (workspaceId: string | number, courseId: string | number, params?: AddTagCourseParam) => {
    if (params.tags.length) {
        const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/tags`;

        const res = await RestfulService.postApi(url, params);
        if (!!res.data.error) {
            throw -1;
        }
        return 1;
    }
    return 0;
};

// const attachCourseTags = async (
//     workspaceId: string | number,
//     courseId: string | number,
//     params?: AddTagCourseParam
// ): Promise<boolean> => {
//     const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/tags`;

//     const res = await RestfulService.postApi(url, params);
//     if (!!res.data.error) {
//         throw res.data;
//     }
//     return res.data;
// };

const detachCourseTag = async (workspaceId: string | number, courseId: string | number, tagId: number): Promise<boolean> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/tags/${tagId}`;

    const res = await RestfulService.deleteApi(url);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const detachCourseManyTags = async (workspaceId: string | number, courseId: string | number, params?: AddTagCourseParam) => {
    if (params.tags.length) {
        const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/tags`;
        const res = await RestfulService.deleteApi(url, params);
        if (!!res.data.error) {
            throw -1;
        }
        return 1;
    }
    return 0;
};

const createCourseApproval = async (
    workspaceId: string | number,
    courseId: string | number,
    approvalBody: {
        user_id: number;
        message: string;
    }
): Promise<boolean> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/approval`;

    const res = await RestfulService.postApi(url, approvalBody);
    if (!!res.data.error) {
        throw res.data;
    }
    return true;
};

const approveCourse = async (workspaceId: string | number, courseId: string | number): Promise<boolean> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/approve`;

    const req = await RestfulService.postApi(url);
    if (req.status === 204) {
        return true;
    } else throw new Error('Operation failed, please try again!');
};
const declineCourse = async (workspaceId: string | number, courseId: string | number): Promise<boolean> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/approval`;

    const req = await RestfulService.deleteApi(url);
    if (!!req.data.error) {
        throw req.data.error;
    }
    return true;
};

const createNewCourse = async (workspaceId: string | number, body: CreateCourseParam): Promise<CourseDetailType | undefined> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses`;

    const req = await RestfulService.postApi(url, {
        ...body,
    });
    if (!!req.data.error) {
        throw req.data.error;
    }
    return req.data;
};

const moveCourseToTrash = async (workspaceId: string | number, courseId: string | number): Promise<boolean> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/trash`;

    const req = await RestfulService.postApi(url);
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 204) {
        return true;
    }
};

const recoverCourseFromTrash = async (workspaceId: string | number, courseId: string | number): Promise<boolean> => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/recover`;

    const req = await RestfulService.postApi(url);
    if (!!req.data.error) {
        throw req.data.error;
    }
    if (req.status === 204) {
        return true;
    }
};

const getClassesMembers = (workspaceId: string, classId: string, bodyParams: any) => {
    if (workspaceId) {
        const url = CLASS.getClassMembers(workspaceId, classId);
        return httpClient.get(url, { params: bodyParams });
    }
};

const downloadCousrse = (workspaceId, courseId) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/courses/${courseId}/download`;
    const a = document.createElement('a');
    a.href = url;
    a.click();
};

const downloadLesson = (workspaceId, lessonId) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/download`;
    const a = document.createElement('a');
    a.href = url;
    a.click();
};

const downloadLessonAPIs = (workspaceId, lessonId) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/${lessonId}/download`;
    return axios({
        method: 'get',
        url,
        responseType: 'blob',
    });
};
const downloadAssignmentAPIs = (workspaceId, assignmentId) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/exercises/${assignmentId}/download`;
    return axios({
        method: 'get',
        url,
        responseType: 'blob',
    });
};

const getLessonGroupByCourse = async (workspaceId: string | number, title: string) => {
    const url = `${HOST_URL}/workspaces/${workspaceId}/lessons/courses`;

    const res = await RestfulService.getApi(url, {
        title,
    });
    if (!!res.data.error) {
        throw res.data.error;
    }
    return res.data;
};

const getAssignmentTemplatesService = async (workspaceId: string, params?: any) => {
    const url = CLASS.getAssignmentTemplates(workspaceId);

    const res = await httpClient.get(url, { params });

    if (!!res.data.error) {
        throw res.data.error;
    }
    return res.data;
};

const uploadToSen = (workspaceId: string, lessonId: string, bodyData?: any) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/lessons/${lessonId}/syncing`;
        return httpClient.post(url);
    }
};

const resetPasswordService = (workspaceId: string, parentId: number) => {
    const url = `/workspaces/${workspaceId}/parent/${parentId}/password`;
    return httpClient.patch(url);
};

const resetPasscodeService = (workspaceId: string, parentId: number) => {
    const url = `/workspaces/${workspaceId}/parent/${parentId}/passcode`;
    return httpClient.patch(url);
};

const getDetailEnrollmentOfStudentWithLearningPathService = (workspaceId: string, learningPathId, studentId, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.getDetailEnrollmentOfStudentWithLearningPath(workspaceId, learningPathId, studentId);
        return httpClient.get(url, { params: bodyParams });
    }
};

const getLearningPathsService = (workspaceId: string, bodyParams: any) => {
    if (workspaceId) {
        const url = ACADEMICS.getLearningPaths(workspaceId);
        return httpClient.get(url, { params: bodyParams });
    }
};

const getLearningLearnersService = (workspaceId: string, learningPathId: string, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.getLearningLearners(workspaceId, learningPathId);
        return httpClient.get(url, { params: bodyParams });
    }
};

const getGroupOfLearningPathService = (workspaceId: string, learningPathId: string, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.getLearningGroups(workspaceId, learningPathId);
        return httpClient.get(url, { params: bodyParams });
    }
};

const addLearnerIntoLearningPathService = (workspaceId: string, learningPathId: string, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.addLearnerIntoLearningPath(workspaceId, learningPathId);
        return httpClient.post(url, bodyParams);
    }
};
const getDetailIndividualEnrollment = (workspaceId: string, learningPathId: string, enrollmentId: string) => {
    if (workspaceId) {
        const url = ACADEMICS.IndividualEnrollment(workspaceId, learningPathId, enrollmentId);
        return httpClient.get(url);
    }
};
const getDetailGroupEnrollment = (workspaceId: string, groupId: string, enrollmentId: string) => {
    if (workspaceId) {
        const url = ACADEMICS.GroupEnrollment(workspaceId, groupId, enrollmentId);
        return httpClient.get(url);
    }
};

const UpdateIndividualEnrollment = (workspaceId: string, learningPathId: string, enrollmentId: string, params: any) => {
    if (workspaceId) {
        const url = ACADEMICS.IndividualEnrollment(workspaceId, learningPathId, enrollmentId);
        return httpClient.patch(url, params);
    }
};
const UpdateGroupEnrollment = (workspaceId: string, groupId: string, enrollmentId: string, params: any) => {
    if (workspaceId) {
        const url = ACADEMICS.GroupEnrollment(workspaceId, groupId, enrollmentId);
        return httpClient.patch(url, params);
    }
};

const addGroupIntoLearningPathService = (workspaceId: string, learningPathId: string, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.addGroupIntoLearningPath(workspaceId, learningPathId);
        return httpClient.post(url, bodyParams);
    }
};

const addInstructorIntoLearningPathService = (workspaceId: string, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.addInstructorIntoLearningPath(workspaceId);
        return httpClient.post(url, bodyParams);
    }
};
const removeInstructorIntoLearningPathService = (workspaceId: string, bodyParams) => {
    if (workspaceId) {
        const url = ACADEMICS.removeInstructorIntoLearningPath(workspaceId);
        return httpClient.post(url, bodyParams);
    }
};

const removeLearnerIntoLearningGroupService = (workspaceId: string, learningGroupId: string, memberId) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.removeLearnerIntoLearningGroup(workspaceId, learningGroupId, memberId);
        return httpClient.delete(url);
    }
};

const changeStatusMemberOfLearningGroup = (workspaceId: string, learningGroupId: string, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.changeStatusMemberOfLearningGroup(workspaceId, learningGroupId);
        return httpClient.post(url, params);
    }
};

const preCheckBeforeChangeStatusMemberOfLearningGroup = (workspaceId: string, learningGroupId: string, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.preCheckBeforeChangeStatusMemberOfLearningGroup(workspaceId, learningGroupId);
        return httpClient.post(url, params);
    }
};

const preCheckBeforeChangeStatusOfUser = (workspaceId: string, userId: string, params) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/members/${userId}/update-infor/pre-check`;
        return httpClient.get(url, params);
    }
};

const depositCredit = (workspaceId: string, bodyParams) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/credit-accounts/deposit`;
        return httpClient.post(url, bodyParams);
    }
};
const addLearnerIntoLearningGroupService = (workspaceId: string, learningGroupId: string, bodyParams) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.addLearnerIntoLearningGroup(workspaceId, learningGroupId);
        return httpClient.post(url, bodyParams);
    }
};

const updateCompanyService = (workspaceId: string, companyId: string, bodyParams) => {
    if (workspaceId) {
        const url = COMPANY.updateCompany(workspaceId, companyId);
        return httpClient.put(url, bodyParams);
    }
};

const deleteLearningPathsService = (workspaceId: string, learningPathId: string) => {
    if (workspaceId) {
        const url = ACADEMICS.deleteLearningPaths(workspaceId, learningPathId);
        return httpClient.delete(url);
    }
};

const createLearningPathService = (workspaceId: string, bodyParams: any) => {
    if (workspaceId) {
        const url = ACADEMICS.getLearningPaths(workspaceId);
        return httpClient.post(url, bodyParams);
    }
};

const getLearningPathDetailService = (workspaceId: string, learningPathId: string) => {
    if (workspaceId && learningPathId) {
        const url = ACADEMICS.getLearningPathDetail(workspaceId, learningPathId);
        return httpClient.get(url);
    }
};

const getLearningGroupService = (workspaceId: string, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getLearningGroup(workspaceId);
        return httpClient.get(url, { params });
    }
};

const getOpportunitiesService = (workspaceId: string, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getOpportunities(workspaceId);
        return httpClient.get(url, { params });
    }
};

const getMemberOfLearingGroupService = (workspaceId: string, learningGroupId, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getMemberOfLearingGroup(workspaceId, learningGroupId);
        return httpClient.get(url, { params });
    }
};

const getLearnersService = (workspaceId: string, bodyData: any) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getLearners(workspaceId);
        return httpClient.get(url, { params: bodyData });
    }
};

const getLearningGroupDetail = (workspaceId: string, learningGroupId) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getLearningGroupDetail(workspaceId, learningGroupId);
        return httpClient.get(url);
    }
};

const getEnrollmentOfGroupService = (workspaceId: string, learningGroupId, params: any) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getEnrollmentOfGroup(workspaceId, learningGroupId);
        return httpClient.get(url, { params });
    }
};

const getStudyHistorySevice = (workspaceId, learningGroupId, learningPathId, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getStudyHistory(workspaceId, learningGroupId, learningPathId);
        return httpClient.get(url, { params });
    }
};

const refundCreditOfSessionEnrollmentService = (workspaceId, sessionId, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.refundCreditOfSessionEnrollment(workspaceId, sessionId);
        return httpClient.post(url, params);
    }
};

const getEnrollmentOStudentService = (workspaceId: string, studentId: string, params: any) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getEnrollmentOfStudent(workspaceId, studentId);
        return httpClient.get(url, { params });
    }
};

const createLearningGroupService = (workspaceId: string, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.createLearningGroup(workspaceId);
        return httpClient.post(url, params);
    }
};

const updateLearningGroupService = (workspaceId: string, learningGroupId, params) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.updateLearningGroup(workspaceId, learningGroupId);
        return httpClient.put(url, params);
    }
};

const deleteLearningGroupService = (workspaceId: string, learningGroupId) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.updateLearningGroup(workspaceId, learningGroupId);
        return httpClient.delete(url);
    }
};

const updateStatusLearningPathService = (workspaceId: string, learningPathId: string, bodyParams: any) => {
    if (workspaceId && bodyParams) {
        const url = ACADEMICS.updateStatusLearningPath(workspaceId, learningPathId);
        return httpClient.post(url, bodyParams);
    }
};

const patchLearningPathService = (workspaceId: string, learningPathId: string, bodyParams: any) => {
    if (workspaceId && learningPathId) {
        const url = ACADEMICS.getLearningPathDetail(workspaceId, learningPathId);
        return httpClient.patch(url, bodyParams);
    }
};

const updateLearningPathService = (workspaceId: string, learningPathId: string, bodyParams: any) => {
    if (workspaceId && learningPathId) {
        const url = ACADEMICS.getLearningPathDetail(workspaceId, learningPathId);
        return httpClient.put(url, bodyParams);
    }
};

const createTeacherService = (workspaceId: string, bodyParams) => {
    if (workspaceId) {
        const url = CLASS.createTeacher(workspaceId);
        return httpClient.post(url, bodyParams);
    }
};

const getStudyHistory = (workspaceId: string, studentId: string, learningId: string, params: any) => {
    if (workspaceId) {
        const url = STUDENT.getStudyHistory(workspaceId, studentId, learningId);
        return httpClient.get(url, { params });
    }
};

const refundSessionHistory = (workspaceId: string, sessionenrollmentId: string, params?: any) => {
    if (workspaceId) {
        const url = STUDENT.refundSession(workspaceId, sessionenrollmentId);
        return httpClient.post(url, params);
    }
};

const getDetailEnrollment = (workspaceId: string, studentId: string, learningId: string) => {
    if (workspaceId) {
        const url = STUDENT.getDetailEnrollment(workspaceId, studentId, learningId);
        return httpClient.get(url);
    }
};

const getCreditHistoryCompany = (workspaceId: string, companyId: string, params: any) => {
    if (workspaceId) {
        const url = COMPANY.getCreditHistoryCompany(workspaceId, companyId);
        return httpClient.get(url, { params });
    }
};

const getSummaryCreditHistory = (workspaceId: string, companyId: string) => {
    if (workspaceId) {
        const url = COMPANY.getSummaryCreditHistory(workspaceId, companyId);
        return httpClient.get(url);
    }
};

const getCreditHistory = (workspaceId: string, studentId: string, params: any) => {
    if (workspaceId) {
        const url = STUDENT.getCreditHistory(workspaceId, studentId);
        return httpClient.get(url, { params });
    }
};

const getSummaryCreditHistories = (workspaceId: string, studentId: string) => {
    if (workspaceId) {
        const url = STUDENT.getSummaryCreditHistories(workspaceId, studentId);
        return httpClient.get(url);
    }
};

const addCreditInformationService = (workspaceId: string, params: any) => {
    if (workspaceId) {
        const url = STUDENT.addCreditInformation(workspaceId);
        return httpClient.post(url, params);
    }
};

const getCreditInformation = (workspaceId: string, saleContarctId: any) => {
    if (workspaceId) {
        const url = STUDENT.getCreditInformation(workspaceId, saleContarctId);
        return httpClient.get(url);
    }
};

const updateCreditInformation = (workspaceId: string, saleContarctId: any, bodyParams) => {
    if (workspaceId) {
        const url = STUDENT.updateCreditInformation(workspaceId, saleContarctId);
        return httpClient.put(url, bodyParams);
    }
};
const updateStatusCreditInformation = (workspaceId: string, saleContarctId: any, bodyParams) => {
    if (workspaceId) {
        const url = STUDENT.updateStatusCreditInformation(workspaceId, saleContarctId);
        return httpClient.post(url, bodyParams);
    }
};

const addCreditForLearningGroupService = (workspaceId: string, params: any) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.addCreditForLearningGroup(workspaceId);
        return httpClient.post(url, params);
    }
};

const getSummaryCreditHistoriesForGroupService = (workspaceId: string, groupId: string) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getSummaryCreditHistoriesForGroup(workspaceId, groupId);
        return httpClient.get(url);
    }
};

const getCreditHistoriesForGroupService = (workspaceId: string, groupId: any, params: any) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getCreditHistoriesForGroup(workspaceId, groupId);
        return httpClient.get(url, { params });
    }
};

const updateCreditInformationForGroupService = (workspaceId: string, saleContarctId: any, bodyParams) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.updateCreditInformationForGroup(workspaceId, saleContarctId);
        return httpClient.put(url, bodyParams);
    }
};

const updateStatusCreditInformationForGroupService = (workspaceId: string, saleContarctId: any, bodyParams) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.updateStatusCreditInformationForGroup(workspaceId, saleContarctId);
        return httpClient.post(url, bodyParams);
    }
};

const getStatusAddMemberToLearningGroupService = (workspaceId: string, learningGroupId: string) => {
    if (workspaceId) {
        const url = LEARNING_GROUP.getStatusAddMemberToLearningGroup(workspaceId, learningGroupId);
        return httpClient.get(url);
    }
};

const getCustomFieldsByNameMapDataService = async (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/custom-fields/data`;
        const response = await httpClient.get(url, { params });

        return {
            ...response?.data,
            items: response?.data?.items?.map((item) => ({
                name: item,
                id: item,
                value: item,
            })),
        };
    }
};

const onAuthWorkspace = (workspaceId: string) => {
    if (workspaceId) {
        const url = WORKSPACE.AUTH_WORKSPACES(workspaceId);
        return RestfulService.postApi(url);
    }
};

const getSlots = async (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/available-slots`;
        return await httpClient.post(url, params);
    }
};

const getRoles = async (workspaceId: string, params?: Record<any, any>) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/roles`;
        return await httpClient.get(url, { params });
    }
};

const deleteMember = async (workspaceId: string, id) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/members/${id}`;
        return await httpClient.delete(url);
    }
};

const getMemberDetail = async (workspaceId: string, id) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/members/${id}`;
        return await httpClient.get(url);
    }
};

const getStudentCourseEnrollmets = async (workspaceId: string, studentId, params: Record<any, any>) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/students/${studentId}/courses/enrollments`;
        return await httpClient.get(url, { params });
    }
};

const addUserContracts = (workspaceId: string, userId, params) => {
    const url = `/workspaces/${workspaceId}/members/${userId}/contracts`;
    return httpClient.post(url, params);
};

const deleteUserContract = (workspaceId: string, userId, contractId) => {
    const url = `/workspaces/${workspaceId}/members/${userId}/contracts/${contractId}`;
    return httpClient.delete(url);
};

const getUserContracts = (workspaceId: string, userId, params) => {
    const url = `/workspaces/${workspaceId}/members/${userId}/contracts`;
    return httpClient.get(url, { params });
};

const getSessionOfLearner = (workspaceId: string, userId, payload, isSessionStatus = false) => {
    const baseUrl = `${HOST_URL}/workspaces/${workspaceId}/students/${userId}/sessions`;
    const params = new URLSearchParams(payload as any);
    if (isSessionStatus) {
        params.append('session_status', 'rescheduled');
        params.append('session_status', 'active');
    }
    const url = `${baseUrl}?${params.toString()}`;
    return RestfulService.getApi(url);
};

const getSessionListLearner = (workspaceId: string, userId, params?: any) => {
    const url = `/workspaces/${workspaceId}/students/${userId}/sessions`;
    return httpClient.get(url, { params, paramsSerializer: (p) => parseParams(p) });
};

const getLearnerAndLearningGroup = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/learner-and-learning-group`;
    return httpClient.get(url, { params });
};

const createSingleInvoice = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/single`;
    return httpClient.post(url, params);
};

const updateInvoice = (workspaceId: string, invoiceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/${invoiceId}`;
    return httpClient.patch(url, params);
};

const createRecurringInvoice = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/recurring`;
    return httpClient.post(url, params);
};

const getInvoiceList = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices`;
    return httpClient.get(url, { params });
};

const getInvoiceDetail = (workspaceId: string, invoiceId: string) => {
    const url = `/workspaces/${workspaceId}/invoices/${invoiceId}`;
    return httpClient.get(url);
};
const getRecurringInvoiceDetail = (workspaceId: string, invoiceId: string) => {
    const url = `/workspaces/${workspaceId}/invoices/recurring/${invoiceId}`;
    return httpClient.get(url);
};

const cancelInvoice = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/cancel`;
    return httpClient.post(url, params);
};
const actionInvoice = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/action`;
    return httpClient.post(url, params);
};

const getRecurringInvoiceList = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/recurring`;
    return httpClient.get(url, { params });
};

const getTransactionOfRecurringInvoice = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/invoices/recurring/transactions`;
    return httpClient.get(url, { params });
};

const getPayers = (workspaceId, params) => {
    const url = `/workspaces/${workspaceId}/invoices/payers`;
    return httpClient.get(url, { params });
};

const getPermissionsUserWorkspace = () => {
    const url = WORKSPACE.PERMISSION_WORKSPACES;
    return httpClient.get(url);
};
const addNoteToInvoice = (workspaceId: string, invoiceId, params) => {
    const url = `/workspaces/${workspaceId}/invoices/${invoiceId}/note`;
    return httpClient.post(url, params);
};

const addNoteRecurringToInvoice = (workspaceId: string, invoiceId, params) => {
    const url = `/workspaces/${workspaceId}/invoices/recurring/${invoiceId}/note`;
    return httpClient.post(url, params);
};

const getTeachingAssistants = (workspaceId: string, params) => {
    const url = `/workspaces/${workspaceId}/teaching-assistants`;
    return httpClient.get(url, { params });
};

const getStudentDetailService = (workspaceId: string, studentId: string) => {
    if (workspaceId) {
        const url = `/workspaces/${workspaceId}/students/${studentId}`;
        return axios.get(url);
    }
};

const getCurrencyWorkspaceService = (params?: any) => {
    const url = WORKSPACE.CURRENCY;
    return httpClient.get(url, { params });
};

const getCertificatesByWorkspace = (workspace_id, params?: any) => {
    const url = `/workspaces/${workspace_id}/certificates`;
    return httpClient.get(url, { params });
};

const getCertificateDetail = (workspace_id: string, certificate_id: string) => {
    const url = `/workspaces/${workspace_id}/certificates/${certificate_id}`;
    return httpClient.get(url);
};

const createSubjectInstructorWorkspace = (workspace_id: string, params: any) => {
    const url = `/workspaces/${workspace_id}/instructors/field-data`;
    return httpClient.post(url, params);
};

const getFieldDataInstructorWorkspace = (workspace_id: string, params: any) => {
    const url = `/workspaces/${workspace_id}/instructors/field-data`;
    return httpClient.get(url, { params });
};

const getCreditAccount = (workspace_id: string, params: any) => {
    const url = `/workspaces/${workspace_id}/credit-accounts`;
    return httpClient.get(url, { params });
};

const getCreditAccounts = (workspaceId: string, studentId: string, params: any) => {
    if (workspaceId) {
        const url = STUDENT.getCreditAccounts(workspaceId, studentId);
        return httpClient.get(url, { params });
    }
};

const getCreditAccountsById = (workspaceId: string, creditId: string, params?: any) => {
    const url = STUDENT.getCreditAccountsById(workspaceId, creditId);
    return httpClient.get(url, { params });
};

const depositCreditAccounts = (workspaceId: string, bodyData: any) => {
    const url = STUDENT.depositCredit(workspaceId);
    return httpClient.post(url, bodyData);
};

const withdrawCreditAccounts = (workspaceId: string, bodyData: any) => {
    const url = STUDENT.withdrawCredit(workspaceId);
    return httpClient.post(url, bodyData);
};

const getCreditTransactions = (workspaceId: string, creditId: string, params?: any) => {
    const url = STUDENT.getCreditTransactions(workspaceId, creditId);
    return httpClient.get(url, { params });
};

const getCreditTransactionsById = (workspaceId: string, creditId: string, transactionId: string, params?: any) => {
    const url = STUDENT.getCreditTransactionsById(workspaceId, creditId, transactionId);
    return httpClient.get(url, { params });
};

const updateNoteCreditTransactionsById = (workspaceId: string, creditId: string, transactionId: string, params?: any) => {
    const url = STUDENT.updateNoteCreditTransactionsById(workspaceId, creditId, transactionId);
    return httpClient.patch(url, params);
};

const getEnrollCreditBalanceDetail = (workspaceId: string, enrollmentId: string, membershipId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/enrollment-credit-balances/get-detail`;
    return httpClient.get(url, { params: { enrollment_id: enrollmentId, membership_id: membershipId, ...params } });
};

const getLearningGroups = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/learning-groups`;
    return httpClient.get(url, { params });
};

const getSessionsWorkspace = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/sessions`;
    return httpClient.get(url, { params, paramsSerializer: (p) => parseParams(p) });
};

const cancelMultiSessionsWorkspace = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/sessions/bulk-cancel`;
    return httpClient.post(url, params);
};

const getSessionDetail = (workspaceId: string, sessionId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/sessions/${sessionId}`;
    return RestfulService.getApi(url, { params });
};

const updateSession = (workspaceId, payload) => {
    const id = payload?.id;
    delete payload?.id;
    return RestfulService.patchApi(HOST_URL + `/workspaces/${workspaceId}/sessions/${id}`, payload);
};

const cancelSession = async (workspaceId, session_id, payload) => {
    return RestfulService.deleteApi(HOST_URL + `/workspaces/${workspaceId}/sessions/${session_id}`, payload);
};

const getListPropertiesService = (workspaceId: string, params?: any) => {
    const url = `/workspaces/${workspaceId}/properties`;
    return httpClient.get(url, { params });
};

const getSettingWorkspaceReminder = async (workspaceId: string, params?: Record<any, any>) => {
    const url = `/workspaces/${workspaceId}/reminders`;
    return await httpClient.get(url);
};

const settingWorkspaceReminder = async (workspaceId: string, params?: Record<any, any>) => {
    const url = `/workspaces/${workspaceId}/reminders`;
    return await httpClient.post(url, params);
};

const createApiKeyWorkspace = (workspaceId: string, params: Record<any, any>) => {
    const url = `/workspaces/${workspaceId}/api-keys`;
    return httpClient.post(url, params);
};

const getApiKeyWorkspace = (workspaceId: string, params?: Record<any, any>) => {
    const url = `/workspaces/${workspaceId}/api-keys`;
    return httpClient.get(url, { params });
};

const updateApiKeyWorkspace = (workspaceId: string, keyId: string, params: Record<any, any>) => {
    const url = `/workspaces/${workspaceId}/api-keys/${keyId}`;
    return httpClient.patch(url, params);
};

const revokeApiKeyWorkspace = (workspaceId: string, keyId: string) => {
    const url = `/workspaces/${workspaceId}/api-keys/${keyId}`;
    return httpClient.delete(url);
};

export default {
    declinePendingItem,
    approvePendingItem,
    getPendingAdminList,
    inviteMembers,
    getListOfWorkspace,
    createWorkspace,
    getWorkspaceDetail,
    updateWorkspaceInformation,
    getWorkspaceMembers,
    getWorkspaceMembersService,
    updateWorkspaceMembersService,
    updateWorkspaceMembers,
    createlessonTags,
    getLessonList,
    getLessonTags,
    getTagsService,
    createLesson,
    getLessonDetail,
    getAssociatedLearningPath,
    updateLessonDetail,
    updatePartialLesson,
    attachTagsForLesson,
    detachTagsForLesson,
    createLessonApproval,
    cancelLessonApproval,
    approveLessonApproval,
    removeLesson,
    recoverLesson,
    getCoursesList,
    getCourseDetail,
    getAdminList,
    updateCourseDetail,
    attachCourseTags,
    detachCourseTag,
    createCourseApproval,
    approveCourse,
    declineCourse,
    createNewCourse,
    moveCourseToTrash,
    recoverCourseFromTrash,
    detachManyTagsForLesson,
    detachCourseManyTags,
    getClassesMembers,
    getLessonGroupByCourse,
    cloneLesson,
    downloadCousrse,
    downloadLesson,
    downloadLessonAPIs,
    getAssignmentTemplatesService,
    createTagService,
    updateTagService,
    getCustomFieldsService,
    updateCustomFieldsService,
    createCustomFieldService,
    deleteCustomFieldService,
    upsertCustomfiled,
    getLessonCustomFieldService,
    getCourseCustomFieldService,
    upsertCustomfiledCourse,
    getH5pCustomFieldService,
    upsertCustomfiledH5p,
    getDetailCustomFieldService,
    uploadToSen,
    resetPasswordService,
    resetPasscodeService,
    getLearningPathsService,
    createLearningPathService,
    getLearningPathDetailService,
    updateLearningPathService,
    patchLearningPathService,
    deleteLearningPathsService,
    getLearningLearnersService,
    updateStatusLearningPathService,
    addLearnerIntoLearningPathService,
    addInstructorIntoLearningPathService,
    getLearningGroupService,
    createLearningGroupService,
    updateLearningGroupService,
    deleteLearningGroupService,
    getLearningGroupDetail,
    getMemberOfLearingGroupService,
    addLearnerIntoLearningGroupService,
    removeLearnerIntoLearningGroupService,
    addGroupIntoLearningPathService,
    getGroupOfLearningPathService,
    createTeacherService,
    updateCompanyService,
    getLearnersService,
    getEnrollmentOfGroupService,
    getEnrollmentOStudentService,
    getOpportunitiesService,
    getStudyHistory,
    getDetailEnrollmentOfStudentWithLearningPathService,
    refundSessionHistory,
    getDetailEnrollment,
    getStudyHistorySevice,
    refundCreditOfSessionEnrollmentService,
    getCreditHistoryCompany,
    getSummaryCreditHistory,
    getDetailIndividualEnrollment,
    getDetailGroupEnrollment,
    UpdateIndividualEnrollment,
    UpdateGroupEnrollment,
    getCreditHistory,
    getSummaryCreditHistories,
    addCreditInformationService,
    getCreditInformation,
    updateCreditInformation,
    updateStatusCreditInformation,
    addCreditForLearningGroupService,
    getSummaryCreditHistoriesForGroupService,
    getCreditHistoriesForGroupService,
    updateCreditInformationForGroupService,
    updateStatusCreditInformationForGroupService,
    removeInstructorIntoLearningPathService,
    getStatusAddMemberToLearningGroupService,
    getCustomFieldByTypeService,
    updateCustomFieldByTypeService,
    getCustomFieldsByNameMapDataService,
    downloadAssignmentAPIs,
    onAuthWorkspace,
    getSlots,
    getRoles,
    deleteMember,
    resetInviteMembers,
    getMemberDetail,
    updateWorkspacePartialMember,
    getStudentCourseEnrollmets,
    addUserContracts,
    getUserContracts,
    deleteUserContract,
    getSessionOfLearner,
    getSessionListLearner,
    getLearnerAndLearningGroup,
    createSingleInvoice,
    getInvoiceList,
    getPayers,
    createRecurringInvoice,
    getRecurringInvoiceList,
    cancelInvoice,
    getPermissionsUserWorkspace,
    getInvoiceDetail,
    getRecurringInvoiceDetail,
    getTransactionOfRecurringInvoice,
    actionInvoice,
    updateInvoice,
    addNoteToInvoice,
    getTeachingAssistants,
    getStudentDetailService,
    getCurrencyWorkspaceService,
    getCertificatesByWorkspace,
    getCertificateDetail,
    renameFolderOfResource,
    createSubjectInstructorWorkspace,
    getFieldDataInstructorWorkspace,
    getCreditAccounts,
    depositCreditAccounts,
    withdrawCreditAccounts,
    getCreditAccountsById,
    getCreditTransactions,
    getCreditTransactionsById,
    updateNoteCreditTransactionsById,
    getEnrollCreditBalanceDetail,
    getCreditAccount,
    changeStatusMemberOfLearningGroup,
    depositCredit,
    getLearningGroups,
    preCheckBeforeChangeStatusMemberOfLearningGroup,
    preCheckBeforeChangeStatusOfUser,
    getSessionsWorkspace,
    cancelMultiSessionsWorkspace,
    getSessionDetail,
    updateSession,
    cancelSession,
    getListPropertiesService,
    addNoteRecurringToInvoice,
    getSettingWorkspaceReminder,
    settingWorkspaceReminder,
    createApiKeyWorkspace,
    getApiKeyWorkspace,
    updateApiKeyWorkspace,
    revokeApiKeyWorkspace,
};
