import { parseParams } from 'utils/commonFun';
import axios from './restful.service';
import qs from 'qs';

const create = (workspaceId: string, params?: Record<any, any>) => {
    return axios.post(`/workspaces/${workspaceId}/properties`, params);
};
const get = (workspaceId: string, params?: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties`, {
        params,
        paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' }); 
        },
    });
};
const getOptions = (workspaceId: string, propertyId: string, params?: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties/${propertyId}/options`, { params, paramsSerializer: (p) => parseParams(p) });
};
const detail = (workspaceId: string, propertyId, params?: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties/${propertyId}`, { params });
};
const update = (workspaceId: string, params: Record<any, any>) => {
    return axios.patch(`/workspaces/${workspaceId}/properties/${params.id}`, params);
};
const deleted = (workspaceId: string, id?: string) => {
    return axios.patch(`/workspaces/${workspaceId}/properties/${id}`);
};
const visibility = (workspaceId: string, params?: any) => {
    return axios.post(`/workspaces/${workspaceId}/properties/visibility`, params);
};

const updateCoursePropertyValue = (workspaceId: string, courseId: string, params: Record<any, any>) => {
    return axios.post(`/workspaces/${workspaceId}/courses/${courseId}/properties/values`, params);
};

const getPropertyOptions = (workspaceId: string, propertyId: string, params: Record<any, any>) => {
    return axios.get(`/workspaces/${workspaceId}/properties/${propertyId}/options`, { params });
};

const getFilterCourseProperty = (workspaceId: string) => {
    return axios.get(`/workspaces/${workspaceId}/courses/filters`);
};

const getFilterMembersProperty = (workspaceId: string) => {
    return axios.get(`/workspaces/${workspaceId}/members/filters`);
};

export default {
    create,
    get,
    detail,
    update,
    deleted,
    visibility,
    getOptions,
    updateCoursePropertyValue,
    getPropertyOptions,
    getFilterCourseProperty,
    getFilterMembersProperty
};
