import { ProgressOverview } from './CourseOverview/ProgressOverview';
import { GradebookList } from './GradebookList/GradebookList';
import { useHistory, useLocation } from 'react-router-dom';
import { GradebookDetails } from './GradebookDetails/GradebookDetails';
import { SkillFilter, ValueFilterSkillType } from 'components/Skills/SkillFilter';
import { useTranslation } from 'react-i18next';
import './CourseGradebook.scss';
import { useEffect, useState } from 'react';

export const CourseGradebook = ({ userData }) => {
    const { t: translator } = useTranslation();
    const search = useLocation()?.search;
    const enrollmentId = new URLSearchParams(search).get('enrollmentId');
    const subtab = new URLSearchParams(search).get('subtab');
    const [filterSkill, setFilterSkill] = useState<ValueFilterSkillType>();
    const [hasReset, setHasReset] = useState(false);
    const [defaultSkill, setDefaultSkill] = useState<ValueFilterSkillType>();
    const history: {location: {state}} = useHistory();

    const { state } = history?.location;
    useEffect(() => {
        if (state) {
            const { skill } = state;
            setTimeout(() => {
                setDefaultSkill(skill);
            }, 500);
        }
    }, [state]);

    return (
        <div className="LearnerGradebook">
            {subtab ? (
                <GradebookDetails enrollmentId={enrollmentId} userData={userData} />
            ) : (
                <>
                    <h2 className="text-lg font-semibold text-gray-800">{translator('Progress Overview')}</h2>
                    <div className="LearnerGradebook-filter z-10">
                        <span className="text-sm">{translator('APPLIED_FILTER(S)')}:</span>
                        <SkillFilter
                            hasReset={hasReset}
                            onChangeValue={(value) => {
                                setFilterSkill(value);
                                setHasReset(false);
                            }}
                            defaultSkill={defaultSkill}
                        />
                        <button
                            className={`border-l h-[32px] border-gray-200 px-3 text-sm ${!!filterSkill ? 'text-primary-500' : ''}`}
                            onClick={() => {
                                setFilterSkill(null);
                                setHasReset(true);
                            }}
                        >
                            {translator('RESET')}
                        </button>
                    </div>
                    <ProgressOverview filterSkill={filterSkill} />
                    <h2 className="text-lg font-semibold text-gray-800 mt-5 -mb-1">{translator('All Courses')}</h2>
                    <GradebookList filterSkill={filterSkill} />
                </>
            )}
        </div>
    );
};
