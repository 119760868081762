import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DialogComponent from 'components/Dialog/DialogComponent'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom';
import skillService from 'services/skill.service';

export default function ModalDeleteSkill({ isOpen, onClose, data, refreshData }) {
  const { t: translator } = useTranslation();
  const params: { id: string, userId: string } = useParams();
  const workspaseId = params?.id;
  const deleteSkill = () => {
    skillService.deleteLeanerSkillByIdService(workspaseId, params?.userId, data?.id)
      .then(() => {
        Toastify.success(translator("DELETE_SKILL_SUCCESS"))
        refreshData();
        onClose();
      })
      .catch(() => {
        Toastify.error(translator("DELETE_SKILL_FAILED"))
      })
  }
  return (
    <DialogComponent
      isOpen={isOpen}
      onCloseModal={onClose}
      title={translator('DELETE_SKILL')}
      child={
        <div className='mt-4 flex flex-col gap-2.5 text-sm text-gray-800'>
          {
            data?.enrolled_courses?.length > 0 && (
              <>
                <p>{translator('DELETE_SKILL_CONTENT_1')}</p>
                <ul className='list-disc ml-10 gap-1'>
                  {
                    data?.enrolled_courses?.map((course) => (
                      <li 
                        key={course?.id}
                        onClick={() => window.open(`/workspace/${params?.id}/management/courses/${course?.id}?tab=people&enrollmentId=${course?.enrollment_id}&view=information`, '_blank')} 
                        className='text-sm font-semibold text-primary-500' 
                      >
                        {course.title}
                      </li>
                    ))
                  }
                </ul>
                <p>{translator('DELETE_SKILL_CONTENT_2')}</p>
              </>
            )
          }
          <div>{translator('ARE_YOU_SURE_YOU_WANT_TO_DELETE_SKILL')}</div>
          <div className='mt-5 flex justify-center gap-5'>
            <ButtonOutline type="button" className='!border-primary-500 !text-primary-500'  onClick={deleteSkill}  >{translator('DELETE')}</ButtonOutline>
            <ButtonPrimary type="button" onClick={onClose}>{translator('CANCEL')}</ButtonPrimary>
          </div>
        </div>
      }
    />
  )
}
