import React, { useCallback } from 'react';
// PACKAGE
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FORM_CONST } from 'constant/form.const';
// CONTEXT
import { AuthContext } from 'contexts/Auth/AuthContext';
// UTILS
import { setStorageAuthApiData } from 'utils/handleLocalStorage';
// LOGIC
import { LoginFormProps, handleChangeEmail, handleChangePassword, onSubmit, onSSO } from './LoginFormFn';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { isUserLoggedIn } from 'utils/handleAuthorized';
import LoginFormFooter from '../LoginFormFooter';
import { EyeOffSVG, EyeSVG } from 'assets/icon';
import queryString from 'query-string';
import authService from 'services/auth.service';
import authMiddleware from 'middleware/auth.middleware';
import HeaderSignForm from 'components/User/HeaderSignForm/HeaderSignForm';
import { XIcon } from '@heroicons/react/solid';
import { HOST_URL } from 'constant/api.const';
import workspaceService from 'services/workspace.service';
import { handleCheckEnv } from 'constant/util.const';
import { AUTH_CONST } from 'constant/auth.const';


import './style.css'
import { ArrowSmLeftIcon } from '@heroicons/react/outline';
import { Toastify } from '_shared';

const LoginForm: React.FC<LoginFormProps> = ({ setAuthStorage, errorMsg, setErrorMsg }) => {
    const { t: translator } = useTranslation();

    const { authState, dispatch } = React.useContext(AuthContext);
    const { result, isLoading } = authState;
    const [show, updateShow] = React.useState(false);
    const changeEye = useCallback(() => updateShow(!show), [show]);
    const authError = authState.err;

    const history = useHistory();
    const params = queryString.parse(history.location.search);
    const paramUrl: { platform: string } = useParams();
    const [inputmail, setInputmail] = React.useState<boolean>(false);
    const [inputPsw, setInputPsw] = React.useState<boolean>(false);
    const location: any = useLocation();

    const handleThenLogin = async () => {
        if (params?.token && params?.usercode && params?.student) {
            const idWs = await handleCheckEnv();
            history.push(`/workspace/${idWs}/management/users/${params?.student}`);
        }
        workspaceService
            .getListOfWorkspace()
            .then((res) => {
                if (res.total > 0) {
                    history.push('/');
                } else {
                    history.push('/workspace/create');
                }
            })
            .catch((err) => console.error(err));
    };

    React.useEffect(() => {
        if (params?.state && !params?.error) {
            authMiddleware.loginLoading(dispatch);
            if (paramUrl?.platform === 'google') {
                authService
                    .callbackGoogle({
                        code: params.code,
                        response_type: 'cookie'
                    })
                    .then(async (result) => {
                        if (!result?.data?.error) {
                            await authMiddleware.authLogin(dispatch, result?.data);
                            handleThenLogin();
                        } else {
                            Toastify.error('Login failed, please try again!');
                        }
                    })
                    .catch((err) => {
                        console.error(err)
                        if (err?.error?.code === 403 && err?.error?.name === AUTH_CONST.FORBIDDEN) {
                            history.push('/forbidden')
                        }
                    });
            }
            if (paramUrl?.platform === 'facebook') {
                authService
                    .authCallBackfb({
                        state: params.state,
                        code: params.code,
                    })
                    .then(async (result) => {
                        await authMiddleware.authLogin(dispatch, result);
                        handleThenLogin();
                    })
                    .catch((err) => {
                        if (err?.error?.name === 'OAUTH_MISSING_EMAIL') {
                            window.location.href = `${HOST_URL}/oauth2/facebook/login?rerequest=${true}`;
                        }
                    });
            }
        } else if (params?.error || params?.error_code) {
            history.push('/login?auth=basic');
        }
        if (params?.token && params?.usercode) {
            // SSO
            onSSO(dispatch, params.token, params.usercode);
            handleThenLogin();
        }
        document.body.classList.remove('admin');
    }, []);

    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        trigger,
        formState: { errors, isValid },
    } = useForm();

    React.useEffect(() => {
        const isLoggedIn = isUserLoggedIn();
        if (isLoggedIn) {
            setAuthStorage(true);
            let lng = result?.language == 'en' ? 'en' : result?.language;
            if (!result?.language) {
                lng = 'en';
            }
            i18n.changeLanguage(lng ?? 'en');
            setStorageAuthApiData(result);
        }
        // if (result?.access_token) {
        //     setLocalStorageAuthApiData(result);
        //     setAuthStorage(isUserLoggedIn());
        // }
    }, [result]);

    React.useEffect(() => {
        if (authError && authError?.error) {
            setErrorMsg(authError.error?.description);
        } else setErrorMsg('');
    }, [authError]);

    return (
        <form onSubmit={handleSubmit(onSubmit(dispatch, getValues))}
            className={`form-container animate__animated ${location?.state?.state?.from === '/password/forgot' ? 'animate__fadeInLeft' : 'animate__fadeInRight'} animate__faster`} >
            <div className="pb-6 mb-1 relative">
                <div
                    className={`relative rounded-lg mt-3 ${getValues('email') ? 'border-2 border-blue_0' : 'input-form'
                        }  ${inputmail && 'input-focus'} ${(errors.email || errorMsg) && 'input-error'} `}
                >
                    <input
                        id="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full pl-3 pr-8 py-2 rounded-lg placeholder-gray_0 focus:outline-none text-sm"
                        {...register('email', {
                            required: true,
                            pattern: FORM_CONST.EMAIL_REGEX,
                            setValueAs: (value: any) => value.trim(),
                        })}
                        name="email"
                        onChange={handleChangeEmail(setValue, trigger, setErrorMsg)}
                        onBlur={() => setInputmail(false)}
                        onFocus={() => setInputmail(true)}
                        placeholder="Email"
                    />
                    {getValues('email') && (
                        <XIcon
                            onClick={() => {
                                setValue('email', '');
                                trigger('email');
                            }}
                            className="w-5 h-5 absolute close-icon cursor-pointer"
                        />
                    )}
                </div>
                {errors?.email?.type === 'required' && (
                    <span className="error-form">{translator('FORM_CONST.REQUIRED_FIELD')}</span>
                )}
                {errors?.email?.type === 'pattern' && (
                    <span className="error-form">{translator('FORM_CONST.EMAIL_FORMAT')}</span>
                )}
            </div>

            <div className="pb-6 relative">
                <div
                    className={` rounded-lg flex items-center px-3 py-2  ${getValues('password') ? 'border-2 border-blue_0' : 'input-form'
                        }  ${inputPsw && 'input-focus'} ${(errors?.password || errorMsg) && 'input-error'} `}
                >
                    <input
                        id="password"
                        name="password"
                        type={show ? 'text' : 'password'}
                        autoComplete="current-password"
                        required
                        {...register('password', {
                            required: {
                                value: true,
                                message: translator('FORM_CONST.REQUIRED_FIELD'),
                            },
                            // pattern: FORM_CONST.PASSWORD_REGEX,
                        })}
                        onChange={handleChangePassword(setValue, trigger, setErrorMsg)}
                        onBlur={() => setInputPsw(false)}
                        onFocus={() => setInputPsw(true)}
                        placeholder="Password"
                        className="appearance-none block w-full  placeholder-gray_0 focus:outline-none text-sm"
                    />
                    <div
                        onClick={changeEye}
                        className="flex justify-center items-center w-8 cursor-pointer group mr-1  "
                    >
                        {show ? <EyeOffSVG /> : <EyeSVG />}
                    </div>
                </div>
                {errors?.password?.type === 'required' && (
                    <span className="error-form">{translator('FORM_CONST.REQUIRED_FIELD')}</span>
                )}
                {errors?.password?.type === 'pattern' && (
                    <span className="error-form">{translator('FORM_CONST.PASSWORD_VALIDATE')}</span>
                )}
            </div>
            <div className="flex w-full justify-end items-center">
                <LoginFormFooter />
            </div>

            <div>
                <button
                    type="submit"
                    disabled={!isValid}
                    className={`w-full flex justify-center py-2 border border-transparent rounded-lg shadow-sm text-sm  focus:outline-none mt-7 font-semibold ${isValid ? 'bg-primary-500 text-white' : 'text-gray_0 bg-gray_0 cursor-not-allowed '
                        }`}
                >
                    {false ? (
                        <svg
                            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : null}
                    Sign in
                </button>
            </div>
            <div className="mt-6 flex justify-center">
                <p
                    className="w-fit flex items-center justify-center space-x-1 text-sm text-enterprise font-semibold hover:text-primary-300 cursor-pointer"

                    onClick={() => {
                        setErrorMsg(undefined);
                        history.push("/login?auth=otp", {
                            state: { from: "/login?auth=basic" }
                        })
                    }}
                >
                    <ArrowSmLeftIcon className="w-5 h-5" />
                    <span>{translator('BACK_TO_SINGLE_SIGN_ON')}</span>
                </p>
            </div>
        </form >
    );
};

export default LoginForm;
