import googleDriveIcon from 'assets/img/googleDriveIcon.png';
import h5pIcon from 'assets/img/h5pIcon.png';
import docIcon from 'assets/img/docIcon.png';
import pdfIcon from 'assets/img/pdfIcon.png';
import mp3Icon from 'assets/img/mp3Icon.png';
import mp4Icon from 'assets/img/mp4Icon.png';
import videoIcon from 'assets/img/video.png';
import pngIcon from 'assets/img/pngIcon.png';
import jpegIcon from 'assets/img/jpegIcon.png';
import pptIcon from 'assets/img/pptIcon.png';
import pptxIcon from 'assets/img/pptxIcon.png';
import xlsIcon from 'assets/img/xlsIcon.png';
import folderIcon from 'assets/img/folderIcon.png';
import lessonIcon from 'assets/img/lessonIcon.png';
import submissionIcon from 'assets/img/submission.png';
import assignmentIcon from 'assets/img/assignmentIcon.png';
import open_box from 'assets/img/open-box.png';
import recording from 'assets/img/recording.png';
import workspaceService from 'services/workspace.service';
import h5pMiddleware from 'middleware/h5p.middlware';
import { dowloadFile, downloadURI, downloadWithAxios, handleFail, onGetBlobFile } from 'utils/commonFun';
import fileServices from 'services/upload.service';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { hasCheckedStream } from 'constant/file.constant';
import resourcesService from 'services/resources.service';
import { useEffect } from 'react';
import microphoneIcon from 'assets/img/microphone.png';

let TIMER_DOWNLOAD;

export const LIBRARY_TAB = [
    {
        name: 'LIBRARY',
        value: 'library',
        index: 0,
    },
    {
        name: 'TRASH',
        value: 'trash',
        index: 1,
    },
];

export const LIBRARY_ICONS = (translator: (key: string) => string) => [
    {
        icon: folderIcon,
        value: 'folder',
        label: translator('FOLDER'),
    },
    {
        icon: googleDriveIcon,
        value: 'google',
        label: translator('DRIVE'),
    },
    {
        icon: h5pIcon,
        value: 'h5p',
        label: translator('H5P'),
    },
    {
        icon: docIcon,
        label: translator('DOCUMENT'),
        value: 'document',
    },
    {
        icon: docIcon,
        label: translator('DOCUMENT'),
        value: 'docx',
    },
    {
        icon: docIcon,
        label: translator('DOCUMENT'),
        value: 'doc',
    },
    {
        icon: pdfIcon,
        label: translator('PDF'),
        value: 'pdf',
    },
    {
        icon: mp3Icon,
        label: translator('AUDIO'),
        value: 'audio',
    },
    {
        icon: videoIcon,
        label: translator('VIDEO'),
        value: 'mp4',
    },
    {
        icon: videoIcon,
        label: translator('VIDEO'),
        value: 'video',
    },
    {
        icon: pngIcon,
        label: translator('IMGAGE'),
        value: 'png',
    },
    {
        icon: pngIcon,
        label: translator('IMGAGE'),
        value: 'image',
    },
    {
        icon: jpegIcon,
        label: translator('IMGAGE'),
        value: 'jpeg',
    },
    {
        icon: jpegIcon,
        label: translator('IMGAGE'),
        value: 'jpg',
    },
    {
        icon: pptIcon,
        label: translator('PPT'),
        value: 'presentation',
    },
    {
        icon: pptxIcon,
        label: translator('PPT'),
        value: 'ppt',
    },
    {
        icon: pptxIcon,
        label: translator('PPT'),
        value: 'pptx',
    },
    {
        icon: xlsIcon,
        label: translator('SPREADSHEET'),
        value: 'spreadsheet',
    },
    {
        icon: xlsIcon,
        label: translator('SPREADSHEET'),
        value: 'xlsx',
    },
    {
        icon: xlsIcon,
        label: translator('SPREADSHEET'),
        value: 'xls',
    },
    {
        icon: lessonIcon,
        label: translator('LESSON'),
        value: 'lesson',
    },
    {
        icon: docIcon,
        label: translator('COURSE'),
        value: 'course',
    },
    {
        icon: assignmentIcon,
        label: translator('Assignment'),
        value: 'assignment',
    },
    {
        icon: submissionIcon,
        label: translator('Submission'),
        value: 'submission',
    },
    {
        icon: open_box,
        label: translator('SCORM'),
        value: 'scorm',
    },
    {
        icon: recording,
        label: 'Recording',
        value: 'recording_task',
    },
];

export enum LibraryTypeEnum {
    image = 'image',
    video = 'video',
    document = 'document',
    audio = 'audio',
    lesson = 'lesson',
    h5p = 'h5p',
    folder = 'folder',
    pdf = 'pdf',
    presentation = 'presentation',
    spreadsheet = 'spreadsheet',
    file = 'file',
    assignment = 'assignment',
    submission = 'submission',
    scorm = 'scorm',
    recordingTask = 'recording_task',
}

export const onDownload = async (item: any, params: any, setLoading: any) => {
    setLoading(true);
    if (item.object_type === LibraryTypeEnum.lesson) {
        return workspaceService
            .downloadLessonAPIs(params?.id, item?.object_id)
            .then((res) => {
                downloadWithAxios(res.data, 'Lesson ' + item?.name);
            })
            .finally(() => setLoading(false));
    }
    if (item.object_type === LibraryTypeEnum.h5p) {
        return h5pMiddleware
            .downloadH5PContentMiddleware(params?.id, item?.object_id, item?.name)
            .then((res) => {
                downloadWithAxios(res.data, item?.name + '.h5p');
            })
            .catch(() => {
                handleFail('Download fail');
            })
            .finally(() => setLoading(false));
    }
    if (item.object_type === LibraryTypeEnum.assignment) {
        return workspaceService
            .downloadAssignmentAPIs(params?.id, item?.object_id)
            .then((res) => {
                downloadWithAxios(res.data, item?.name);
            })
            .finally(() => setLoading(false));
    }

    try {
        const response = await fileServices.getFile(params?.id, item?.object_id);
        if (hasCheckedStream(response?.data?.file)) {
            TIMER_DOWNLOAD = setInterval(async () => {
                if (!TIMER_DOWNLOAD) {
                    return;
                }
                const res = await resourcesService.getDownloadUrl(params?.id, item?.object_id);
                if (res?.url) {
                    downloadURI(res?.url, item?.name);
                    setLoading(false);
                    clearInterval(TIMER_DOWNLOAD);
                }
            }, 1000);

        } else {
            try {
                dowloadFile(response?.data?.file, response?.data?.name, () => {
                    setLoading(false);
                });
            } catch (error) {

            } finally {
                setLoading(false);
            }
        }

    } catch (error) {

    } finally {
        setLoading(false);
    }

    useEffect(() => {
        return () => {
            clearInterval(TIMER_DOWNLOAD);
        }
    })
};

function processFiles(files: { name: string; content: string }[]): { name: string; content: string }[] {
    const nameCounts = new Map<string, number>();
    const processedFiles: { name: string; content: string }[] = [];
    files.forEach((file) => {
        const { name, content } = file;
        const baseName = name?.split('.')[0];
        if (!nameCounts.has(baseName)) {
            nameCounts.set(baseName, 0);
            processedFiles.push(file);
        } else {
            const count = nameCounts.get(baseName)! + 1;
            nameCounts.set(baseName, count);
            const newName = `${baseName}(${count}).${name?.split('.')[1]}`;
            processedFiles.push({ name: newName, content });
        }
    });

    return processedFiles;
}

export const downloadMultipleFiles = async (list, params: any, setLoading: any) => {
    const zip = new JSZip();
    setLoading(true);
    try {
        const responses = await Promise.all(
            list?.map(async (item) => {
                if (item.object_type === LibraryTypeEnum.lesson) {
                    return workspaceService.downloadLessonAPIs(params?.id, item?.object_id).then((res) => {
                        return {
                            name: item?.name + '.zip',
                            content: res?.data,
                        };
                    });
                }
                if (item.object_type === LibraryTypeEnum.h5p) {
                    return h5pMiddleware.downloadH5PContentMiddleware(params?.id, item?.object_id, item?.name).then((res) => {
                        return {
                            name: item?.name + '.h5p',
                            content: res?.data,
                        };
                    });
                }
                if (item.object_type === LibraryTypeEnum.assignment) {
                    return workspaceService.downloadAssignmentAPIs(params?.id, item?.object_id).then((res) => {
                        return {
                            name: item?.name + '.zip',
                            content: res?.data,
                        };
                    });
                } else {
                    return fileServices.getFile(params?.id, item?.object_id).then(async (res) => {
                        let name;
                        const url = res?.data?.file;
                        if (!res?.data?.name) name = url?.split('\\').pop().split('/').pop();
                        return {
                            name: item?.name || name,
                            content: await onGetBlobFile(url),
                        };
                    });
                }
            })
        );
        const processedFiles = processFiles(responses);
        processedFiles?.forEach((file, index) => {
            const fileName = file?.name || `file${index}`;
            const fileContent = file?.content;
            zip.file(fileName, fileContent);
        });
        const content = await zip.generateAsync({ type: 'blob' });
        const blob = new Blob([content]);
        const blobUrl = URL.createObjectURL(blob);
        saveAs(blob, 'library-downloaded-' + dayjs().format('YYYYMMDDHHmmss') + '.zip');
        URL.revokeObjectURL(blobUrl);
    } catch (error) {
        console.error('Error downloading');
    }
    setLoading(false);
};

export const onCheckTypeSize = (size: number) => {
    let type = 'KB';
    let num = size;
    if (size > 1024 * 1024) {
        type = 'MB';
        num = Math.round(size / (1024 * 1024));
    } else {
        num = Math.round(size / 1024);
    }
    return `${num} ${type}`;
};

export const CUSTOM_FIELDS_TYPES = [
    'learning_path',
    'lesson',
    'assignment',
    'h5p_content',
    //'course',
    'file',
];