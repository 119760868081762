import { XIcon, ClockIcon } from '@heroicons/react/outline';
import { TFunction } from 'i18next';
import React, { useState, FC, useEffect, useRef } from 'react';
import ErrorMessageForm from 'components/ErrorMessageForm';
import { classNames, UUID } from 'utils/commonFun';
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormTrigger } from 'react-hook-form';
import './style.scss';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CircleInformationIcon } from 'assets/icon';
import { Tooltip } from '_shared';
import ReactTooltip from 'react-tooltip';
import 'react-phone-number-input/style.css'
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

interface InputFormProps {
    text?: string;
    nameField?: string;
    setValue: UseFormSetValue<FieldValues>;
    getValues: UseFormGetValues<FieldValues>;
    translator: TFunction;
    register: UseFormRegister<FieldValues>;
    handleChange: any;
    trigger: UseFormTrigger<FieldValues>;
    apiError?: any;
    errors?: any;
}
const InputForm: React.FC<InputFormProps> = ({
    text,
    nameField,
    setValue,
    getValues,
    translator,
    register,
    handleChange,
    trigger,
    apiError,
    errors,
}) => {
    const [onFocus, setOnFocus] = useState<boolean>(false);

    return (
        <>
            <div
                className={`relative rounded-lg  ${getValues(nameField) ? 'border-2 border-blue_0' : 'input-form'}  ${onFocus && 'input-focus'
                    } ${errors && 'input-error'}`}
            >
                <input
                    id={nameField}
                    type="text"
                    required
                    className="appearance-none  w-full block px-3 py-2  rounded-lg placeholder-gray_0  focus:outline-none  text-sm  "
                    {...register(nameField, {
                        required: {
                            value: true,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                    })}
                    onFocus={() => setOnFocus(true)}
                    onBlur={() => setOnFocus(false)}
                    onChange={handleChange}
                    name={nameField}
                    placeholder={text}
                />
                {getValues(nameField) && (
                    <XIcon
                        onClick={() => {
                            setValue(`${nameField}`, '');
                            trigger(nameField);
                        }}
                        className="w-5 h-5 absolute close-icon cursor-pointer"
                    />
                )}
            </div>
        </>
    );
};

export default InputForm;

const handleErrorForm = (name: string, errors: any) => {
    if (!errors) return;
    const nameSplit = name.split('.');
    const field_1 = nameSplit[0];
    const field_2 = nameSplit[1];
    const field_3 = nameSplit[2];
    if (errors?.[field_1]?.[field_2]?.[field_3]) {
        return '!border-red-500';
    }
    return '';
};

type InputFieldProps = {
    inputProps?: any;
    isRequired?: boolean;
    label?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    classNameInput?: string;
    register?: any;
    errors?: any;
    maxLength?: number;
    type?: 'text' | 'number' | 'floatNumber';
    vertical?: boolean;
    textLength?: number,
    isCountter?: boolean,
    handleBlur?: () => void,
    labelHorizontal?: string,
    classError?: string;
    currency?: string;
    tooltipLabel?: string,
    isFloatNumber?: boolean;
    readOnly?: boolean;
    classLabelHorizontal?: string;
    defaultValue?: string;
    isArray?: boolean;
};

export const InputField: FC<InputFieldProps> = ({
    inputProps,
    isRequired = false,
    label,
    name,
    disabled = false,
    placeholder = '',
    className,
    classNameInput,
    register,
    errors,
    maxLength,
    type = 'text',
    vertical = true,
    textLength = 0,
    isCountter = false,
    handleBlur,
    labelHorizontal,
    classError = '',
    currency,
    tooltipLabel,
    isFloatNumber = false,
    readOnly = false,
    classLabelHorizontal,
    defaultValue = '',
    isArray = false
}) => {
    const id = UUID();
    useEffect(() => {
        if (maxLength && name && isCountter) {
            const input: any = document.getElementById(name);
            const counter: any = document.getElementById('counter' + name);
            input?.addEventListener('input', (e: any) => {
                const target = e.target;
                const currentLength = target.value?.length || 0;
                counter.innerHTML = `${currentLength}/${maxLength}`;
            });
            return () => input.removeEventListener('input');
        }
    }, []);

    return (
        <div
            className={classNames(
                'text-xs text-gray-800',
                vertical ? 'flex flex-col space-y-1' : 'grid grid-cols-5 gap-4',
                className
            )}
        >
            {!!label && (
                <div className='flex items-center gap-1  '>
                    <label htmlFor={id} className={classNames('font-semibold', !vertical ? 'flex items-center' : '')}>
                        {label}
                        {isRequired && <span className="text-red-500 "> *</span>}
                    </label>
                    {tooltipLabel && <span data-for={`tooltip-${name}`} data-tip={tooltipLabel} ><CircleInformationIcon height="10.5" width="10.5" fill="#1F2937" /></span>}
                    {/* @ts-ignore*/
                        <ReactTooltip
                            place="right"
                            type="light"
                            id={`tooltip-${name}`}
                            getContent={(content) => <div className="max-w-[280px] text-left text-xs font-normal p-1">{content}</div>}
                            className='shadow-lg opacity-100'
                        />
                    }
                </div>
            )}

            <div className={!vertical ? 'col-span-4' : ''}>
                <div className='relative flex items-center gap-2'>
                    <input
                        id={name}
                        name={name}
                        type={(type === 'number' || type === 'floatNumber') ? 'number' : type}
                        disabled={disabled}
                        placeholder={placeholder}
                        maxLength={maxLength}
                        className={classNames(
                            'w-full outline-none border border-gray-300 focus:border-[#2684FF] rounded p-2.5 hover:border-[#B3B3B3]',
                            isArray ? handleErrorForm(name, errors) : !!errors && errors[name] && '!border-red-500',
                            classNameInput
                        )}
                        readOnly={readOnly}
                        {...register}
                        {...inputProps}
                        onKeyDown={(evt) => {
                            if (type === 'number' && ["e", "E", "+", "-", ".", ","].includes(evt.key)) evt.preventDefault();
                            if (type === 'floatNumber' && ["e", "E", "+", "-", ","].includes(evt.key)) evt.preventDefault();
                            if (type === 'text' && isFloatNumber && ["e", "E", "+", "-", ","].includes(evt.key)) evt.preventDefault();
                        }
                        }
                        defaultValue={defaultValue}
                        onBlur={handleBlur}
                    />
                    {currency && <div className='absolute bottom-2.5 right-2 uppercase'>{currency}</div>}
                    {labelHorizontal && <div className={`text-xs text-gray-500 ${classLabelHorizontal}`}>{labelHorizontal}</div>}
                    {maxLength && name && isCountter && (
                        <span
                            className="absolute right-2 bottom-2 text-ooolab_10px text-gray-500"
                            id={'counter' + name}
                        >{`${textLength}/${maxLength}`}</span>
                    )}
                </div>
                {!!errors && <ErrorMessageForm classes={`errors ${classError}`} errors={errors} name={name} />}
            </div>
        </div>
    );
};

type TextareaFieldProps = {
    inputProps?: any;
    isRequired?: boolean;
    label?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    classNameTextarea?: string;
    register?: any;
    errors?: any;
    maxLength?: number;
    textLength?: string | number;
    isCountter?: boolean;
    handleBlur?: () => void;
    cols?: number;
    rows?: number;
    isArray?: boolean;
};

export const TextareaField: FC<TextareaFieldProps> = ({
    inputProps,
    isRequired = false,
    label,
    name,
    disabled = false,
    placeholder = '',
    className,
    classNameTextarea,
    register,
    errors,
    maxLength,
    textLength = 0,
    isCountter = false,
    handleBlur,
    rows = 4,
    cols = 50,
    isArray = false,
}) => {
    useEffect(() => {
        if (maxLength && name && isCountter) {
            const textarea: any = document.getElementById(name);
            const counter: any = document.getElementById('counter' + name);
            textarea?.addEventListener('input', (e: any) => {
                const target = e.target;
                const currentLength = target.value?.length || 0;
                counter.innerHTML = `${currentLength}/${maxLength}`;
            });
            return () => textarea.removeEventListener('input');
        }
    }, []);

    return (
        <div className={classNames('flex relative flex-col space-y-1 text-xs text-gray-800', className)}>
            {!!label && (
                <label htmlFor={name} className="font-semibold">
                    {label}
                    {isRequired && <span className="text-red-500"> *</span>}
                </label>
            )}
            <div className="relative">
                <textarea
                    id={name}
                    rows={rows}
                    cols={cols}
                    maxLength={maxLength}
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    className={classNames(
                        'w-full outline-none border border-gray-300  focus:border-[#2684FF] rounded p-2 hover:border-[#B3B3B3] pb-6',
                        isArray ? handleErrorForm(name, errors) : !!errors && errors[name] && '!border-red-500',
                        classNameTextarea
                    )}
                    {...register}
                    {...inputProps}
                    onBlur={handleBlur}
                ></textarea>
                {maxLength && name && isCountter && (
                    <span
                        className="absolute right-2 bottom-2 text-ooolab_10px text-gray-500 p-1 rounded bg-white"
                        id={'counter' + name}
                    >{`(${textLength}/${maxLength})`}</span>
                )}
            </div>
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </div>
    );
};

type RichTextFieldProps = {
    isRequired?: boolean;
    label?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    classNameTextarea?: string;
    errors?: any;
    control: Control<any>;
    rules?: any;
    maxLengthEditor?: number;
};

const configCkEditor = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'strikethrough',
        'link',
        'bulletedList',
        'numberedList',
        'blockQuote',
        '|',
        'undo',
        'redo'
    ],
    link: {
        addTargetToExternalLinks: true,
    },
};

export const RichTextField: FC<RichTextFieldProps> = ({
    isRequired = false,
    label,
    name,
    disabled = false,
    className,
    errors,
    control,
    rules,
    placeholder = '',
    maxLengthEditor = 1000,
}) => {
    const { t: translator } = useTranslation();
    const [valueEditor, setValueEditor] = useState('');

    const id = UUID();

    return (
        <div
            className={classNames(
                'relative ckeditor__custom flex flex-col space-y-1',
                !!errors && errors[name] && 'ckeditor__custom_error',
                className
            )}
        >
            {!!label && (
                <label htmlFor={id} className="font-semibold text-xs text-ooolab_dark_1">
                    {label}
                    {isRequired && <span className="text-red-500"> *</span>}
                </label>
            )}
            <Controller
                name={name}
                control={control}
                rules={{
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    ...rules,
                }}
                render={({ field: { value, onChange } }) => (
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        onChange={(event, editor) => {
                            // const valueDescription = editor.getData();
                            // updateData('description', valueDescription, {
                            //     shouldTouch: true,
                            // });
                            setValueEditor(editor.getData());
                            onChange(editor.getData());
                        }}
                        disabled={disabled}
                        config={{ ...configCkEditor, placeholder }}
                    />
                )}
            />
            <p className='absolute bottom-0.5 right-1 text-[10px] text-gray-500'>({valueEditor?.length || 0}/{maxLengthEditor})</p>
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </div>
    );
};

type LabelFormProps = {
    title: string;
    className?: string;
    isRequired?: boolean;
};

export const LabelForm: FC<LabelFormProps> = ({ title, className = '', isRequired = false }) => {
    return (
        <label className={`font-semibold text-xs block mb-1 ${className}`}>
            {title}
            {isRequired && <span className="text-red-500"> *</span>}
        </label>
    )
}


type InputFileFieldProps = {
    isRequired?: boolean;
    label?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    register?: any;
    errors?: any;
    vertical?: boolean;
    onChange?: (file?: any) => void;
    classError?: string;
    isTooltip?: boolean;
    tooltipLabel?: string;
    styleTooltip?: string;
    filename: string;
    textDescription?: string;
};

export const InputFileField: FC<InputFileFieldProps> = ({
    isRequired = false,
    label,
    name,
    disabled = false,
    placeholder = 'No file Chosen',
    className,
    register,
    errors,
    vertical = true,
    onChange,
    classError,
    isTooltip = false,
    tooltipLabel = 'text',
    styleTooltip = '',
    filename = '',
    textDescription = '',
}) => {
    const id = UUID();
    const uploadRef = useRef<HTMLInputElement>(null);

    return (
        <div
            className={classNames(
                'text-xs text-gray-800',
                vertical ? 'flex flex-col space-y-1' : 'grid grid-cols-5 gap-4',
                className
            )}
        >
            {!!label && (
                <div className='flex items-center gap-1'>
                    <label htmlFor={id} className={classNames('font-semibold', !vertical ? 'flex items-center' : '')}>
                        {label}
                        {isRequired && <span className="text-red-500 "> *</span>}
                    </label>
                    {isTooltip && <div className="relative ">
                        <Tooltip styles={styleTooltip} info={tooltipLabel}>
                            <CircleInformationIcon height="10.5" width="10.5" fill="#1F2937" />
                        </Tooltip>
                    </div>}

                </div>
            )}

            <div className={!vertical ? 'col-span-4' : ''}>
                <div
                    className={
                        classNames(
                            'relative flex items-center justify-between space-x-2 border border-gray-300 rounded px-3 py-2 max-h-[38px] hover:border-[#B3B3B3]',
                            !!errors && errors[name] && '!border-red-500',
                        )}
                >
                    {!filename ? <p className='w-full text-sm text-gray-400 select-none'>{placeholder}</p> : <p className='w-full text-sm text-gray-800 truncate'>{filename}</p>}
                    <input
                        {...register}
                        id={id + name}
                        ref={uploadRef}
                        name={name}
                        type='file'
                        accept=".png, .jpg, .jpeg"
                        className='hidden'
                        onChange={(e) => onChange?.(e?.target?.files?.[0])}
                    />
                    <button
                        type="button"
                        disabled={disabled}
                        onClick={() => uploadRef?.current?.click()}
                        className="flex-none text-enterprise text-xs font-semibold border border-enterprise rounded px-2 py-0.5 disabled:text-gray-400 disabled:border-gray-400 disabled:cursor-not-allowed"
                    >
                        Choose File
                    </button>
                </div>
                {!!errors && <ErrorMessageForm classes={`errors ${classError}`} errors={errors} name={name} />}
                {!!textDescription && <p className='mt-1 text-sm font-normal text-gray-500'>{textDescription}</p>}
            </div>
        </div>
    );
};

type PhoneInputFieldProps = {
    isRequired?: boolean;
    label?: string;
    name?: string;
    errors?: any;
    control: Control<any>;
    rules?: any;
    placeholder?: string;
    disabled?: boolean
    disableCountryCode?: boolean;
    classNameInput?: string;
}

export const PhoneInputField: FC<PhoneInputFieldProps> = ({
    isRequired,
    label,
    name,
    errors,
    control,
    rules,
    placeholder,
    disabled,
    classNameInput,
    disableCountryCode
}) => {
    const { t: translator } = useTranslation();
    const id = UUID();

    return (
        <div className='PhoneInput-container'>
            {!!label && (
                <label htmlFor={id} className="font-semibold text-xs text-ooolab_dark_1">
                    {label}
                    {isRequired && <span className="text-red-500"> *</span>}
                </label>
            )}
            <PhoneInputWithCountry
                className={`PhoneInput-custom mt-1 ${!!errors && errors[name] ? 'PhoneInput-custom-error' : ''} ${classNameInput || ''}`}
                name={name}
                control={control}
                rules={{
                    required: {
                        value: isRequired,
                        message: translator('FORM_CONST.REQUIRED_FIELD'),
                    },
                    ...rules
                }}
                placeholder={placeholder}
                disabled={disabled}
                disableCountryCode={disableCountryCode}
            />
            {!!errors && <ErrorMessageForm errors={errors} name={name} />}
        </div>
    )
}

type InputTimeFieldProps = {
    inputProps?: any;
    isRequired?: boolean;
    label?: string;
    name: string;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    classNameInput?: string;
    register: any;
    errors?: any;
    positionIcon?: 'left' | 'right';
    classError?: string;
    messageError?: string;
    regex?: string;
    maxLength?: number;
    onBlur?: () => void;
};

export const InputTimeField: FC<InputTimeFieldProps> = ({
    inputProps,
    isRequired = false,
    label,
    name,
    disabled = false,
    placeholder = '',
    className = '',
    classNameInput = '',
    register,
    errors,
    positionIcon = 'left',
    classError = '',
    messageError = '',
    regex = /^(?:[0-9]{2}):[0-5][0-9]$/,
    maxLength = 5,
    onBlur,
}) => {
    return (
        <div
            className={classNames(
                'text-xs text-gray-800',
                className
            )}
        >
            {!!label && (
                <div className='mb-1'>
                    <label htmlFor={name} className={classNames('font-semibold')}>
                        {label}
                        {isRequired && <span className="text-red-500 "> *</span>}
                    </label>
                </div>
            )}

            <div className='relative flex items-center gap-2'>
                <input
                    id={name}
                    name={name}
                    type='text'
                    disabled={disabled}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    className={classNames(
                        'w-full outline-none border border-gray-300 focus:border-[#2684FF] rounded p-2.5 hover:border-[#B3B3B3] pl-8',
                        positionIcon === 'right' && '!pl-2',
                        !!errors && errors[name] && '!border-red-500',
                        classNameInput
                    )}
                    {...register(name, {
                        pattern: {
                            value: regex,
                            message: messageError,
                        },
                    })}
                    onBlur={onBlur}
                    {...inputProps}
                />
                <ClockIcon className={`w-5 h-5 text-ooolab_dark_2 absolute top-2.5 ${positionIcon === 'right' ? 'right-2' : 'left-2'}`} />
            </div>
            {!!errors && <ErrorMessageForm classes={`errors ${classError}`} errors={errors} name={name} />}
        </div>
    );
};