(function () {
    window.scorm.constants = {
        SCORM_TRUE: 'true',
        SCORM_FALSE: 'false',

        STATE_NOT_INITIALIZED: 0,
        STATE_INITIALIZED: 1,
        STATE_TERMINATED: 2,

        LOG_LEVEL_DEBUG: 1,
        LOG_LEVEL_INFO: 2,
        LOG_LEVEL_WARNING: 3,
        LOG_LEVEL_ERROR: 4,
        LOG_LEVEL_NONE: 5,
    };
})();
