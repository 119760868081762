import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { UserContext } from 'contexts/User/UserContext';
import dayjs from 'dayjs';
import { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import NO_CONTENT_IMG from 'assets/img/empty-state/library.png';
import { H5P_LIBRARY } from 'constant/h5p.const';
import './FromLibrary.scss';
import { TYPE_CREATE_CONTENT, TYPE_LESSON_BUILDER } from 'components/Library/LessonBuilder/constants';
import { useTranslation } from 'react-i18next';

export default function Table({
    createContentType,
    translator,
    dataTable,
    pagination,
    handleSort,
    getList,
    loadingTable,
    fileSelected,
    onDoubleClickRow,
    handleClick,
    typeCreateLesson,
    hasLesson,
    hasLearningPath,
    listAdded = [],
    isShowContentType = false,
}) {
    const {
        userState: { result },
    } = useContext(UserContext);

    const {i18n: {language}} = useTranslation();
    
    return (
        <div className="tableLibrary relative">
            <div className="tableLibrary-header">
                <div className="tableLibrary-header-item name">{translator('NAME')}</div>
                <div className="tableLibrary-header-item">{translator('OWNER')}</div>

                <div className="tableLibrary-header-item action" onClick={handleSort}>
                    {translator('LAST_MODIFIED')}
                    {pagination?.order === 'desc' ? <ArrowDownIcon className="icon" /> : <ArrowUpIcon className="icon" />}
                </div>
                {
                    typeCreateLesson  !== TYPE_LESSON_BUILDER.assignments ? 
                    (createContentType === TYPE_CREATE_CONTENT.H5P_LIBRARY ? (
                        <div className="tableLibrary-header-item">{translator('H5P_TYPE')}</div>
                    ) : (
                        !hasLesson && <div className="tableLibrary-header-item">{translator('CONTENT_TYPE')}</div>
                    ))
                    : isShowContentType ? <div className="tableLibrary-header-item">{translator('CONTENT_TYPE')}</div>
                    : null
                }
            </div>
            {!dataTable?.length && !loadingTable ?
             <NoContentWithImage className='absolute inset-0' img={NO_CONTENT_IMG} />
             :
            <InfiniteScroll
                dataLength={dataTable?.length}
                next={() => 
                    dataTable?.length < pagination?.total &&
                    getList(pagination?.page + 1)
                }
                hasMore={dataTable?.length !== pagination?.total}
                loader={
                    loadingTable && (
                        <div className="tableLibrary-loading">
                            <CircleSpin color="text-primary-500" />
                        </div>
                    )
                }
                height={'h-60'}
                className="tableLibrary-scrollbar custom-scrollbar"
            >
                {dataTable?.map((item, index) => {
                    let type = item?.object_type;
                    const subType = item?.sub_object_type?.split('.')[1];
                    if (type === 'image' && subType) {
                        type = subType;
                    }
                    const isActive = fileSelected?.find((file) => file?.id === item?.id);
                    return (
                        <div
                            className={`tableLibrary-row ${isActive ? 'active' : ''}  ${listAdded?.find(idx => idx == item?.id) ? 'disabled': ''}`}
                            key={index}
                            onDoubleClick={() => onDoubleClickRow(item)}
                            onClick={(e) => handleClick(item, e)}
                            
                        >
                            <div className="tableLibrary-row-item name">
                                <div className="center-item">
                                    {!hasLearningPath ? <img
                                        alt="libary-icon"
                                        className="icon"
                                        src={
                                            item.object_type
                                                ? LIBRARY_ICONS(translator)?.find((item) => item?.value === type)?.icon
                                                : LIBRARY_ICONS(translator)[0].icon
                                        }
                                    /> : null }
                                    <div className="content-name">{item?.name ?? item?.title}</div>
                                </div>
                            </div>

                            <div className="tableLibrary-row-item">
                                <div className="center-item">
                                    <img alt="avatar-icon" className="icon" src={item.created_by?.avatar_url} />
                                    <div className="content-name">{item.created_by?.display_name}</div>
                                </div>
                            </div>
                            <div className="tableLibrary-row-item">{dayjs.utc(item?.updated_at)?.locale(language).tz(result?.time_zone).fromNow()}</div>
                            {   
                                createContentType === TYPE_CREATE_CONTENT.H5P_LIBRARY  &&
                                <div className="tableLibrary-row-item truncate text-ellipsis">
                                    {H5P_LIBRARY?.[item?.h5p_type?.replace('H5P.', '')] || '_'}
                                </div>
                            }
                            {
                                createContentType === TYPE_CREATE_CONTENT.LIBRARY && !hasLesson &&  typeCreateLesson !== TYPE_LESSON_BUILDER.assignments && 
                                <div className="tableLibrary-row-item truncate text-ellipsis">
                                    {item.object_type
                                        ? LIBRARY_ICONS(translator)?.find((type) => type?.value === item?.object_type)?.label
                                        : LIBRARY_ICONS(translator)[0].label}
                                </div>
                            }
                            {isShowContentType && (
                                <div className="tableLibrary-row-item truncate text-ellipsis">
                                    {item.object_type && LIBRARY_ICONS(translator)?.find((type) => type?.value === item?.object_type)?.label}
                                </div>
                            )}
                        </div>
                    );
                })}
            </InfiniteScroll>
            }
        </div>
    );
}
