import H5PPublicViewer from 'components/H5P/H5PPublicViewer';
import { LibraryTypeEnum, onDownload } from 'components/Library/LibraryContants';
import './PreviewContentCommon.scss';
import { ButtonPrimary } from '_shared/components';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AssignmentSubmission from 'components/Library/AssignmentBuilder/components/AssignmentSubmission/AssignmentSubmission';
import { IframePdfViewer } from './IframePDFViewer';
import { hasCheckedStream } from 'constant/file.constant';
import VideoPlayer from '_shared/components/VideoPlayer/VideoPlayer';
import { AspectRatioBox } from 'components/AspectRatioBox';
import SCORMPreviewComponent from 'components/ScormComponent/SCORMPreviewComponent';
import { getDetail } from 'components/Library/LessonBuilder/helpers';
import AssignmentRecording from './AssignmentRecording/AssignmentRecording';

export default function PreviewContentCommon({ contentSelected, viewType, isNewPage = false, isPublic = false, colorProcess = "text-white" }) {
    const params: any = useParams();
    const { t: translator } = useTranslation()
    const [loading, setLoading] = useState(false);
    const [size, setSize] = useState(0);
    const [scormValue, setScormValue] = useState({});

    useEffect(() => {
        if (contentSelected?.library_content?.file && !hasCheckedStream(contentSelected?.library_content?.file)) {
            axios({
                url: contentSelected?.library_content?.file,
                method: "HEAD"
            })
                .then(res => setSize(+res?.headers?.["content-length"] / 1000000))
        }

        if (LibraryTypeEnum.scorm == contentSelected?.library_content?.object_type) {
            getDetail(params?.id || contentSelected?.wsid, contentSelected, setScormValue, () => { });
        }

    }, [contentSelected])

    if (
        size > 20 &&
        (LibraryTypeEnum.document == contentSelected?.library_content?.object_type ||
            LibraryTypeEnum.presentation == contentSelected?.library_content?.object_type ||
            LibraryTypeEnum.spreadsheet == contentSelected?.library_content?.object_type
        )
    ) {
        return (
            <div className="no-render-file largePreview">
                <div className="detail">
                    {translator('LIBRARY.COULD_NOT_PREVIEW_FILE')} <br />
                    {translator('LIBRARY.THIS_FILE_TOO_LARGE_TO_PREVIEW')}
                </div>
                <ButtonPrimary
                    disabled={loading}
                    className="btn-download"
                    type="button"
                    onClick={() => onDownload(contentSelected?.library_content, params, setLoading)}
                >
                    {loading ? 'Downloading...' : 'Download'}
                </ButtonPrimary>
            </div>
        );
    }


    return (
        <>

            {LibraryTypeEnum.image === contentSelected?.library_content?.object_type && (
                <div className="previewContent-image">
                    <img className="object-contain" src={contentSelected?.library_content?.file} alt="" />
                </div>
            )}

            {LibraryTypeEnum.h5p === contentSelected?.library_content?.object_type && contentSelected?.library_content?.uid && (
                <div key={`iframe-${viewType}`} className="previewContent-h5p">
                    <H5PPublicViewer contentUid={contentSelected?.library_content?.uid} isPublic={isPublic}
                        args={{
                            workspaceId: params?.id,
                            contentId: contentSelected?.library_content?.object_id
                        }}
                    />
                </div>
            )}
            {LibraryTypeEnum.video == contentSelected?.library_content?.object_type && (
                hasCheckedStream(contentSelected?.library_content?.file) ? <VideoPlayer data={contentSelected?.library_content} color={colorProcess} /> :
                    <iframe className="document" src={contentSelected?.library_content?.file}></iframe>
            )}
            {(LibraryTypeEnum.document == contentSelected?.library_content?.object_type ||
                LibraryTypeEnum.presentation == contentSelected?.library_content?.object_type ||
                LibraryTypeEnum.spreadsheet == contentSelected?.library_content?.object_type) && (
                    <iframe
                        className="document"
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${contentSelected?.library_content?.file}`}
                    />
                )}
            {LibraryTypeEnum.audio == contentSelected?.library_content?.object_type && (
                <div className="audio">
                    <audio controls src={contentSelected?.library_content?.file} className="w-full max-w-[450px]"></audio>
                </div>
            )}
            {LibraryTypeEnum.pdf == contentSelected?.library_content?.object_type && (
                <IframePdfViewer url={contentSelected?.library_content?.file} />
            )}
            {LibraryTypeEnum.submission == contentSelected?.library_content?.object_type && (
                <AssignmentSubmission data={contentSelected} isNewPage={isNewPage} />
            )}
            {LibraryTypeEnum.recordingTask == contentSelected?.library_content?.object_type && (
                <AssignmentRecording data={contentSelected}/>
            )}
            {LibraryTypeEnum.scorm == contentSelected?.library_content?.object_type && (
                <SCORMPreviewComponent values={{ ...scormValue, wsid: contentSelected?.wsid }} />
            )}
        </>
    );
}
