import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CircleWarningIcon } from "assets/icon/CircleWarningIcon";
import OtpInput from "_shared/components/OtpInput";
import { useLocation } from "react-router-dom";
import authMiddleware from "middleware/auth.middleware";
import { AuthContext } from "contexts/Auth/AuthContext";
import authService from "services/auth.service";
import { useDocumentTitle } from "hooks/useDocumentTitle";
import { isUserLoggedIn } from 'utils/handleAuthorized';
import i18n from "translations/i18n";
import { setStorageAuthApiData } from "utils/handleLocalStorage";

const Logo = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/logo.svg`);

const TIME_LEFT = 300;

export const LoginOtpPage: React.FC<any> = ({ setAuthStorage }) => {
    const { t: translator } = useTranslation();
    const { authState, dispatch, config } = React.useContext(AuthContext);
    const [whiteLabel, setWhiteLabel] = useState<any>();
    const [otp, setOtp] = useState("");
    const [timeLeft, setTimeLeft] = useState(TIME_LEFT);
    const [isResendAvailable, setIsResendAvailable] = useState(false);
    const [endTime, setEndTime] = useState(() => {
        const savedEndTime = localStorage.getItem("endTime");
        return savedEndTime ? Number(savedEndTime) : Date.now() + TIME_LEFT * 1000;
    });
    const search = useLocation()?.search;
    const emailParam = new URLSearchParams(search).get('email');
    const email = decodeURIComponent(emailParam);
    const [error, setError] = useState<any>();
    const { result } = authState;
    useDocumentTitle('');
    useEffect(() => {
        if (config) {
            setWhiteLabel(config?.find(item => item?.page_name == "login"));
        }
    }, [config]);

    useEffect(() => {
        if (otp.trim()?.length === 6) {
            authMiddleware.loginOTP(dispatch, {
                otp_code: otp.trim(),
                email,
                response_type: 'cookie',
            }, (err) => {
                setError(err)
            });
        } else setError(null);
    }, [otp]);

    useEffect(() => {
        localStorage.setItem("endTime", String(endTime));

        const updateCountdown = () => {
            const currentTime = Date.now();
            const remainingTime = Math.max(
                0,
                Math.floor((endTime - currentTime) / 1000)
            );

            setTimeLeft(remainingTime);
            setIsResendAvailable(remainingTime === 0);
        };
        updateCountdown();
        const timer = setInterval(updateCountdown, 1000);
        return () => clearInterval(timer);
    }, [endTime]);

    const handleResendCode = () => {
        if (!isResendAvailable) return;
        authService.requestOTP({ email });
        const newEndTime = Date.now() + TIME_LEFT * 1000;
        setEndTime(newEndTime);
        localStorage.setItem("endTime", String(newEndTime));
        setIsResendAvailable(false);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `0${minutes}:${secs < 10 ? `0${secs}` : secs}`;
    };

    React.useEffect(() => {
        const isLoggedIn = isUserLoggedIn();
        if (isLoggedIn) {
            setAuthStorage(true);
            let lng = result?.language == 'en' ? 'en' : result?.language;
            if (!result?.language) {
                lng = 'en';
            }
            i18n.changeLanguage(lng ?? 'en');
            setStorageAuthApiData(result);
        }
    }, [result]);

    return (
        <div className="animate__animated animate__fadeInRight animate__fast min-h-screen flex items-center w-[500px] lg:w-[656px] mx-auto px-4 lg:px-0 py-10 text-gray-800 text-base select-none">
            <div className="w-full overflow-hidden">
                <img
                    className="block mb-6 mx-auto min-h-[100px] max-h-[100px]"
                    src={whiteLabel?.data?.web?.logo || Logo.default}
                    alt=""
                />
                <h1 className="text-gray-900 text-center text-2xl font-semibold leading-8">
                    {whiteLabel?.data?.web?.title ||
                        translator("CHECK_YOUR_EMAIL_FOR_A_CODE")}
                </h1>
                <p className="text-base mt-2 text-center max-w-2xl px-5 mx-auto">
                    <Trans
                        i18nKey="WEVE_SENT_A_6CHARACTER_CODE_TO_EMAIL_THE_CODE_EXPIRES_SHORTLY_SO_PLEASE_ENTER_IT_SOON"
                        values={{ email }}
                        components={{ strong: <strong /> }}
                    />
                </p>
                <div className="mt-6 min-h-[96px]">
                    <OtpInput
                        value={otp}
                        valueLength={6}
                        type="text"
                        classNameGroup="!max-w-full !gap-4 h-full"
                        classNameInput={`!text-[40px] !font-normal max-h-[96px] !py-7 ${otp.trim().length === 6 ? "!border-[#16A34A]" : ""
                            } ${!!error ? "!border-red-500" : ""}`}
                        onChange={(value) => setOtp(value)}
                    />
                    {!!error && (
                        <p className="mt-1 text-lg text-red-500 flex items-center space-x-1">
                            <CircleWarningIcon />
                            <span>{translator("INVALID_CODE")}</span>
                        </p>
                    )}
                </div>
                <div className="mt-6 text-center">
                    <Trans
                        i18nKey="DIDNT_GET_IT_RESEND_CODE_IN_TIME"
                        values={{
                            time_left: !isResendAvailable ? `in ${formatTime(timeLeft)}` : "",
                        }}
                    >
                        <button
                            className="text-enterprise disabled:text-gray-500 underline"
                            disabled={!isResendAvailable}
                            onClick={handleResendCode}
                        >
                            Resend Code
                        </button>
                    </Trans>
                </div>
            </div>
        </div>
    );
};
