import React, { FC, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import 'components/Library/components/SCORMPackage/SCORMPackage.scss';
import uploadService from 'services/upload.service';
import lessonService from 'services/lesson.service';
import { checkFileHeader } from 'utils/checkHeaderFile';
import { ACCEPT_FILE_TYPE_UPLOAD_SCORM } from './constants.scorm';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import scormService from 'services/scorm.service';

type UploadScormProps = {
    onClose: (value?: boolean) => void;
};

export const UploadScorm: FC<UploadScormProps> = ({ onClose }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; folderId: string } = useParams();
    const fileUploadRef = useRef(null);
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm();

    const [fileUpload, setFileUpload] = useState<any>();
    const [loading, setLoading] = useState(false);

    const handleUploadFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectFile = event?.target?.files?.[0];
        const fileName = selectFile?.name || '';
        const fileType = selectFile?.type || '';
        const fileSize = selectFile?.size || 0;
        const SCORM_FILE_SIZE = 1 * 1024 * 1024 * 1024; // 1GB
        let fileMimeType = 'unknown';

        if (!selectFile) return;

        try {
            fileMimeType = await checkFileHeader(selectFile);
        } catch (error) {
            Toastify.error('File upload failed!');
        }

        if (!ACCEPT_FILE_TYPE_UPLOAD_SCORM.includes(fileType) && fileMimeType !== 'zip') {
            event.target.value = ''
            return Toastify.error(translator('FORM_CONST.FILE_TYPE_IS_NOT_ALLOWED'));
        } else if (fileSize > SCORM_FILE_SIZE) {
            event.target.value = ''
            return Toastify.error(translator('File size exceeds 1GB'));
        }

        setFileUpload(selectFile);
    };

    const onSubmit = () => {
        if (!fileUpload) {
            return;
        }
        setLoading(true);
        uploadService.uploadFile(
            fileUpload,
            async (path: string) => {
                try {
                    const payload: any = {
                        file_key: path,
                        parent_id: params?.folderId,
                    };
                    if (!payload?.parent_id) delete payload.parent_id;

                    const response = await scormService.createScormContent(params.id, payload);
                    if (!response?.data?.error) {
                        Toastify.success('SCORM was created successfully!');
                        onClose(true);
                    } else {
                        Toastify.error('SCORM create failure!');
                    }
                    setLoading(false);
                } catch {
                    Toastify.error('SCORM create failure!');
                    setLoading(false);
                }
            },
            () => {
                Toastify.error('SCORM create failure!');
                setLoading(false);
            }
        );
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="text-gray-800 select-none">
                <div className="SCORMPackage flex flex-col space-y-3 mt-4">
                    <div className="SCORMPackage-item !mb-0 flex">
                        <div className="SCORMPackage-inputFile max-h-8 flex items-center">
                            <input
                                className="hidden"
                                placeholder={translator('SCORM.No package selected')}
                                type="file"
                                accept={ACCEPT_FILE_TYPE_UPLOAD_SCORM.join(',')}
                                multiple={false}
                                ref={fileUploadRef}
                                onChange={handleUploadFile}
                            />
                            <span
                                className={clsx('truncate', {
                                    '!text-gray-800': fileUpload?.name,
                                })}
                            >
                                {fileUpload?.name ? fileUpload?.name : translator('SCORM.No package selected')}
                            </span>
                        </div>
                        <div className="SCORMPackage-file max-h-8 flex items-center" onClick={() => fileUploadRef.current.click()}>
                            <span>{translator('SCORM.Choose a Package')}</span>
                        </div>
                    </div>
                    <div className="border border-gray-200 bg-gray-50 rounded-xl p-4 text-sm">
                        <p className="!font-normal">Please follow the instructions below to ensure a smooth upload process:</p>
                        <ul className=''>
                            <li className="bg-gray-50">Ensure your SCORM package is in <b>.zip</b> format.</li>
                            <li className="bg-gray-50">
                                The package should comply with SCORM <b>1.2</b> specifications for proper functionality.
                            </li>
                            <li className="bg-gray-50">Maximum upload size: <b>1GB</b>.</li>
                            <li className="bg-gray-50">Test your SCORM package locally before importing.</li>
                        </ul>
                    </div>
                </div>
                <div className="SCORMPackage-action">
                    <ButtonOutline type="button" onClick={() => onClose(false)}>
                        Cancel
                    </ButtonOutline>
                    <ButtonPrimary
                        className="flex"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={!fileUpload || loading}
                    >
                        {loading ? <CircleSpin className="mr-2" /> : null}
                        Upload
                    </ButtonPrimary>
                </div>
            </form>
        </>
    );
};
