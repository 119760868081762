import React from 'react';
import './style.css';
const BgLogin = require(`assets/themes/${process.env.REACT_APP_WHITELABEL}/bg-login-enterprise.png`);
const ImagesLogin: React.FC<any> = ({whiteLabel}: {whiteLabel: any}) => {
    return (
        <div className="min-h-screen  img-bg-login relative h-full bg-cover bg-center" style={{ 'backgroundImage': `url(" ${whiteLabel?.data?.web?.background || BgLogin}")` }}>
        </div>
    );
};

export default ImagesLogin;
