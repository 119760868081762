import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Table from './TableLearningGroupsPeople';
import Pagination from 'components/V2/Pagination';
import courseService from 'services/course.service';
import { getIndexPage } from 'utils/commonFun';
import { useAppSelector } from 'hooks/hooks';
import { useDebouncedState } from 'hooks/useDebounce';

export const LearningGroupPeople: React.FC = () => {
    const { t } = useTranslation();
    const params: { id: string; courseId: string } = useParams();

    const [keyword, setKeyword] = useDebouncedState('');

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });

    const workspaceId = params.id;
    const courseId = params.courseId;
    const {
        data: { settings },
    } = useAppSelector((state) => state.course);

    const fetchData = (page: number | string = 1) => {
        setLoading(true);

        const payload = {
            ...pagination,
            page,
            q: keyword,
        };
        delete payload?.total;
        if (!payload.q) delete payload.q;

        courseService
            .getLearningGroupsCoursePeople(workspaceId, courseId, payload)
            .then((res: any) => {
                setData(res?.data?.items);
                setPagination({ ...pagination, ...payload, total: res?.data?.total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, res?.data?.items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchData();
    }, [keyword]);

    return (
        <div className="learning_group-course_people">
            <div className="py-4">
                <SearchBox
                    placeholder={t('Search by Learning Group name')}
                    className="max-w-sm"
                    onSubmit={(value) => setKeyword(value)}
                />
            </div>
            <div className="h-[calc(100vh-410px)] overflow-auto">
                {loading ? (
                    <div className="flex justify-center items-center pt-10 h-full">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <Table data={data} />
                )}
            </div>
            <Pagination
                total={pagination.total}
                per_page={pagination.per_page}
                callback={(page) => fetchData(page)}
                indexPage={indexPage}
                page={pagination?.page}
            />
        </div>
    );
};
