import { FC, useContext, useMemo } from 'react';
import { CourseType } from 'types/Courses.type';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/User/UserContext';
import { useHistory, useParams } from 'react-router-dom';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import NO_CONTENT from 'assets/img/empty-state/instructor.png';
import ReactTooltip from 'react-tooltip';
import { CircleInformationIcon } from 'assets/icon';
import { TooltipCustom } from 'components/Tooltip/Tooltip';

interface IViewTable {
    data: CourseType[];
}

const GradebookListTable: FC<IViewTable> = ({ data }) => {
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;
    const params: { id: string, userId: string } = useParams();
    const {
        userState: { result },
    } = useContext(UserContext);
    const history = useHistory();

    const getValue = (value) => {
        if (value == null) {
            return '-';
        }
        return value + '%';
    }

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator("COURSES.COURSE_NAME")} />,
                accessor: 'course',
                Cell: ({ row }: any) => {
                    return (
                        <div className="flex gap-1 text-sm text-gray-800 ml-2 cursor-pointer"
                            onClick={() => history.push(`/workspace/${params?.id}/management/users/${params?.userId}?tab=gradebook&courseId=${row?.values?.course?.id}&subtab=assignment`)}>
                            <div className='flex gap-2 items-center'
                                data-tip={row?.values?.course?.title}
                                data-for={row?.original?.id + '_show-more-course'}>
                                <div className='grid'>
                                    <div className='truncate text-primary-500 font-semibold text-sm'>{row?.values?.course?.title}</div>
                                </div>
                                {
                                    //@ts-ignore
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id={row?.original?.id + '_show-more-course'}
                                        getContent={(content) => <div>{content}</div>}
                                    />
                                }
                            </div>
                        </div>
                    );
                },
                width: "180"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.OVERALL_GRADE')} className="!justify-center" />,
                accessor: 'overall',
                Cell: ({ row }: any) => <RenderTableCell className='text-center bg-gray-100' value={getValue(row?.values?.overall)} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.ATTENDANCE')} className="!justify-center" />,
                accessor: 'attendance',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.attendance)} />,
                width: "100"
            },
            {
                Header: () => <RenderTableHeader value={translator('COURSES.COURSE_COMPLETION')} className="!justify-center" />,
                accessor: 'course_completion',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.course_completion)} />,
                width: "140"
            },
            {
                Header: () => <div className='flex items-center gap-1 max-w-sm '>
                    <RenderTableHeader value={translator('REPORTS.ASSIGNMENT_GRADE')} className="!justify-center" />
                    <div className="w-4 h-4 cursor-pointer flex items-center"
                        data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                        data-tip={translator('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}>
                        <CircleInformationIcon width='14' height='14' fill='#1F2937' />
                        <TooltipCustom
                            classNameContainer="shadow-medium max-w-sm  text-center !text-gray-800 !font-normal"
                            id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                            content={translator('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                            place="top"
                            type="light"
                        />
                    </div>
                </div>,
                accessor: 'assignment_score',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.assignment_score)} />,
                width: "120"
            },

            {
                Header: () => <RenderTableHeader value={translator('COURSES.LESSON_GOAL_COMPLETION')} className="!justify-center" />,
                accessor: 'lesson_goal',
                Cell: ({ row }: any) => <RenderTableCell className='text-center' value={getValue(row?.values?.lesson_goal)} />,
                width: "160"
            }
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data || []}
            columns={columns}
            emptyContent={translator('COURSES.NO_GRADEBOOKS')}
            emptyIcon={NO_CONTENT}
        />
    );
};

export default GradebookListTable;
