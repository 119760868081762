import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import './CourseOverview.scss';
import { useTranslation } from 'react-i18next';
import { CircleInformationIcon } from 'assets/icon';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import { useAppSelector } from 'hooks/hooks';

export const CourseOverview = () => {
    const params: { id: string; courseId: string } = useParams();
    const { t } = useTranslation();
    const { setting } = useAppSelector((state) => state.course);

    const [overview, setOverview] = useState<any>();

    useEffect(() => {
        const getOverview = async () => {
            const response = await courseService.getGradebooksOverview(params?.id, params?.courseId, {});
            setOverview(response?.data);
        };
        if (params?.courseId) {
            getOverview();
        }
    }, [params?.courseId]);

    return (
        <div className="courseOverview">
            <h2 className="courseOverview-title">{t('COURSES.COURSE_OVERVIEW')}</h2>
            <div className="courseOverview-list">
                <div className="courseOverview-item">
                    <div className="courseOverview-label">{t('COURSES.OVERALL_GRADE')}</div>
                    <div className="courseOverview-value">{overview?.overall == null ? '-' : `${overview?.overall}%`}</div>
                </div>
                {setting?.course_completion_weight !== null && (
                    <div className="courseOverview-item">
                        <div className="courseOverview-label">{t('COURSES.COURSE_COMPLETION')}</div>
                        <div className="courseOverview-value">
                            {overview?.course_completion == null ? '-' : `${overview?.course_completion}%`}
                        </div>
                    </div>
                )}
                {setting?.assignment_grade_weight !== null && (
                    <div className="courseOverview-item">
                        <div className="flex items-center gap-1 max-w-sm">
                            <div className="courseOverview-label">{t('COURSES.ASSIGNMENT_GRADE')} </div>
                            <div
                                className="w-4 h-4 cursor-pointer flex items-center"
                                data-for="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                data-tip={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                            >
                                <CircleInformationIcon width="14" height="14" fill="#1F2937" />
                                <TooltipCustom
                                    classNameContainer="shadow-medium max-w-sm  text-center"
                                    id="ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS"
                                    content={t('ASSIGNMENT_GRADE_TOOLTIP_ANALYTICS')}
                                    place="top"
                                    type="light"
                                />
                            </div>
                        </div>
                        <div className="courseOverview-value">
                            {overview?.assignment_score == null ? '-' : `${overview?.assignment_score}%`}
                        </div>
                    </div>
                )}
                {setting?.lesson_goal_weight !== null && (
                    <div className="courseOverview-item">
                        <div className="courseOverview-label">{t('COURSES.LESSON_GOAL_COMPLETION')}</div>
                        <div className="courseOverview-value">{overview?.lesson_goal == null ? '-' : `${overview?.lesson_goal}%`}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
