import { downloadCSVWithWorker } from 'components/Reports/components/common';
import dayjs from 'dayjs';
import { capitalize, handleFail } from 'utils/commonFun';
import { PAYMENT_OPTION_LIST } from 'components/Ecommerce/Billing/constants';
import { accountCreditType, featureList } from 'constant/workspace.const';
import { ASSIGNMENT_STATUS, SESSION_STATUS } from 'constant/course.const';
import { COURSE_TYPE, courseEnrollmentType, courseStatus } from 'components/Workspace/Course/CoursesList/constants';
import { propertyType } from 'components/WorkspaceSetting/Property/components/propertyCommon';

const formatDate = 'MM/DD/YYYY';
const formatDateTime = 'MM/DD/YYYY HH:mm';
const formatFullDateTime = 'MM/DD/YYYY HH:mm:ss';

export const tabsDownloadData = [
    {
        name: 'LEARNERS_DATA',
        url: 'leaner-data',
        index: 0,
        roleName: featureList?.export_learner_dashboard,
    },
    {
        name: 'INSTRUCTORS_DATA',
        url: 'instructor-ta-data',
        index: 1,
        roleName: featureList?.export_instructor_dashboard,
    },
    {
        name: 'COURSES_DATA',
        url: 'courses-data',
        index: 2,
        roleName: featureList?.export_course_dashboard,
    },
];

export const tableLearnerInformation = [
    {
        columnName: 'Learner ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Learner',
        value: 'Christine Le',
        width: 150,
    },
    {
        columnName: 'Added Date (mm/dd)',
        value: '12/24/2024',
        width: 268,
    },
    {
        columnName: 'Learner Email',
        value: 'Christine@gmail.com',
        width: 200,
    },
    {
        columnName: 'Phone',
        value: '(+65)83428902',
        width: 150,
    },
    {
        columnName: 'Gender',
        value: 'Female',
        width: 150,
    },
    {
        columnName: 'DOB (mm/dd)',
        value: '04/10/1992',
        width: 186,
    },
    {
        columnName: 'Status',
        value: 'Active',
        width: 150,
    },
    {
        columnName: 'Parent Name',
        value: 'Dave Le',
        width: 172,
    },
    {
        columnName: 'Parent Email',
        value: 'Dave@gmail.com',
        width: 172,
    },
    {
        columnName: 'Parent ID',
        value: '325',
        width: 150,
    },
];

export const tableSessionPerformance = [
    {
        columnName: 'Session ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '32',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Marketing 101',
        width: 150,
    },
    {
        columnName: 'Enrollment Type',
        value: 'Credit',
        width: 150,
    },
    {
        columnName: 'Start Date (mm/dd)',
        value: '02/23/2024 10:00:00',
        width: 268,
    },
    {
        columnName: 'End Date (mm/dd)',
        value: '02/23/2024 12:00:00',
        width: 268,
    },
    {
        columnName: 'Session Status',
        value: 'Complete',
        width: 150,
    },
    {
        columnName: 'Learner',
        value: 'Christine Le',
        width: 150,
    },
    {
        columnName: 'Learner ID',
        value: '231',
        width: 150,
    },
    {
        columnName: 'Attendance',
        value: 'Present',
        width: 150,
    },
    {
        columnName: 'Lesson Goals',
        value: 'Yes',
        width: 150,
    },
    {
        columnName: 'Rewards',
        value: '-',
        width: 150,
    },
    {
        columnName: 'Instructor Comments',
        value: 'Good job!',
        width: 180,
    },
    {
        columnName: 'Credit Spent',
        value: '-1',
        width: 150,
    },
];

export const tableCreditPackage = [
    {
        columnName: 'Credit Package ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '32',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Marketing 101',
        width: 150,
    },
    {
        columnName: 'Credits Issued',
        value: '100',
        width: 150,
    },
    {
        columnName: 'Credits Available',
        value: '23',
        width: 150,
    },
    {
        columnName: 'Learner/Learning Group ID',
        value: '534',
        width: 268,
    },
    {
        columnName: 'Learner/Learning Group Name',
        value: 'Christine Le',
        width: 268,
    },
    {
        columnName: 'Enrollment Status',
        value: '-',
        width: 268,
    },
    {
        columnName: 'Program Note',
        value: '-',
        width: 150,
    },
    {
        columnName: 'Credit Package Status',
        value: 'Enrolled',
        width: 180,
    },
    {
        columnName: 'Created Date (mm/dd)',
        value: '02/16/2024',
        width: 268,
    },
    {
        columnName: 'Deactivated Reason',
        value: '-',
        width: 170,
    },
];

export const tableInvoice = [
    {
        columnName: 'Invoice ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Learner/ Learning Group ID',
        value: '10',
        width: 268,
    },
    {
        columnName: 'Learner/ Learner Group Name',
        value: 'Christine Le',
        width: 268,
    },
    {
        columnName: 'Created Date (mm/dd)',
        value: '02/24/2024',
        width: 268,
    },
    {
        columnName: 'Due Date (mm/dd)',
        value: '02/28/2024',
        width: 268,
    },
    {
        columnName: 'Payment Date (mm/dd)',
        value: '-',
        width: 268,
    },
    {
        columnName: 'Invoice Status',
        value: 'Overdue',
        width: 150,
    },
    {
        columnName: 'Payment Method',
        value: 'Cash',
        width: 150,
    },
    {
        columnName: 'Description',
        value: '-',
        width: 150,
    },
    {
        columnName: 'Total Amount',
        value: '100',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '32, 34, 35',
        width: 150,
    },
    {
        columnName: 'Notes',
        value: '-',
        width: 150,
    },
];

export const tableInstructorTAInformation = [
    {
        columnName: 'User ID',
        value: '56',
        // value2: '64',
        width: 150,
    },
    {
        columnName: 'User Name',
        value: 'Instructor',
        // value2: 'Teaching Assistant',
        width: 150,
    },
    {
        columnName: 'Added Date (mm/dd/yyyy)',
        value: '02/24/2024',
        // value2: '24/02/2024',
        width: 268,
    },
    {
        columnName: 'Status',
        value: 'Active',
        // value2: 'Active',
        width: 150,
    },
    {
        columnName: 'Email',
        value: 'Instructor@ooolab.com',
        // value2: 'TeachingAssistant@ooolab.com',
        width: 268,
    },
    {
        columnName: 'Role',
        value: 'Instructor',
        // value2: 'Teaching Assistant',
        width: 150,
    },
];

export const tableTeachingHistory = [
    {
        columnName: 'Session ID',
        value: '32',
        // value2: '32',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '232',
        // value2: '232',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Marketing 101',
        // value2: 'Marketing 101',
        width: 150,
    },
    {
        columnName: 'Start Date (mm/dd)',
        value: '02/23/2024 10:00:00',
        // value2: '23/02/2024 10:00:00',
        width: 268,
    },
    {
        columnName: 'End Date (mm/dd)',
        value: '02/23/2024 12:00:00',
        // value2: '23/02/2024 12:00:00',
        width: 268,
    },
    {
        columnName: 'Session Status',
        value: 'Completed',
        // value2: 'Completed',
        width: 150,
    },
    {
        columnName: 'User ID',
        value: '543',
        // value2: '543',
        width: 150,
    },
    {
        columnName: 'User Name',
        value: 'Instructor',
        // value2: 'Teaching Assistant',
        width: 150,
    },
    {
        columnName: 'Role',
        value: 'Instructor',
        // value2: 'Teaching Assistant',
        width: 150,
    },
    {
        columnName: 'Attendance',
        value: 'Marked',
        // value2: 'Teaching Assistant',
        width: 150,
    },
];

export const tableTransactionsCreditAccount = [
    {
        columnName: 'Transaction ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Transaction Date (mm/dd)',
        value: '09/26/2024 12:30:00',
        width: 250,
    },
    {
        columnName: 'Description',
        value: 'Deposited to the course Marketing 101',
        width: 300,
    },
    {
        columnName: 'Transaction Amount',
        value: '26',
        width: 170,
    },
    {
        columnName: 'Account Balance',
        value: '26',
        width: 150,
    },
    {
        columnName: 'Transaction Created by',
        value: 'Christine Le',
        width: 185,
    },
    {
        columnName: 'Credit Account Number',
        value: '2314',
        width: 200,
    },
    {
        columnName: 'Credit Account Type',
        value: 'Personal',
        width: 200,
    },
    {
        columnName: 'Learner ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Learner Name',
        value: 'Christine Le',
        width: 150,
    },
    {
        columnName: 'Learning Group ID',
        value: '-',
        width: 170,
    },
    {
        columnName: 'Learning Group Name',
        value: '-',
        width: 180,
    },
    {
        columnName: 'Notes',
        value: '-',
        width: 150,
    },
];

export const tableTransactionsCreditBalance = [
    {
        columnName: 'Transaction ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Transaction Date (mm/dd) ',
        value: '09/26/2024 12:30:00',
        width: 250,
    },
    {
        columnName: 'Description',
        value: 'Deposited to the course Marketing 101',
        width: 300,
    },
    {
        columnName: 'Transaction Amount',
        value: '26',
        width: 170,
    },
    {
        columnName: 'Account Balance',
        value: '26',
        width: 150,
    },
    {
        columnName: 'Transaction Created by',
        value: 'Christine Le',
        width: 185,
    },
    {
        columnName: 'Credit Balance ID',
        value: '2314',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '4221',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Marketing 101',
        width: 150,
    },
    {
        columnName: 'Learner ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Learner Name',
        value: 'Christine Le',
        width: 150,
    },
    {
        columnName: 'Enrollment ID',
        value: '114',
        width: 150,
    },
    {
        columnName: 'Enrollment Type',
        value: 'Learning Group',
        width: 150,
    },
    {
        columnName: 'Enrollment Name',
        value: 'Sales Cohort',
        width: 150,
    },
    {
        columnName: 'Notes',
        value: '-',
        width: 150,
    },
];

export const tableGrade = [
    {
        columnName: 'Assignment ID',
        value: '1234',
        width: 150,
    },
    {
        columnName: 'Assignment Name',
        value: 'Marketing 101 - Strategies',
        width: 200,
    },
    {
        columnName: 'Learner ID',
        value: '324',
        width: 150,
    },
    {
        columnName: 'Learner Name',
        value: 'Christine Le',
        width: 150,
    },
    {
        columnName: 'Learning Group ID',
        value: '-',
        width: 170,
    },
    {
        columnName: 'Learning group Name',
        value: '-',
        width: 180,
    },
    {
        columnName: 'Attempt Status',
        value: 'Assigned',
        width: 170,
    },
    {
        columnName: 'Grade',
        value: '50',
        width: 150,
    },
    {
        columnName: 'Total Grade',
        value: '100',
        width: 150,
    },
    {
        columnName: 'Grade(%)',
        value: '50%',
        width: 150,
    },
    {
        columnName: 'Completion rate',
        value: '100%',
        width: 150,
    },
    {
        columnName: 'Time Spent',
        value: '32m',
        width: 150,
    },
    {
        columnName: 'Due Date (mm/dd)',
        value: '09/26/2024',
        width: 200,
    },
    {
        columnName: 'Submission Date (mm/dd)',
        value: '09/26/2024',
        width: 250,
    },
    {
        columnName: 'Attempt order',
        value: '1/4',
        width: 150,
    },
    {
        columnName: 'Graded By',
        value: 'Alex Jackson',
        width: 150,
    },
    {
        columnName: 'Create Date',
        value: '09/23/2024',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '4221',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Marketing course 101',
        width: 170,
    },
    {
        columnName: 'Lesson ID',
        value: '4221',
        width: 150,
    },
    {
        columnName: 'Lesson Name',
        value: 'Marketing course 101',
        width: 170,
    },
];

export const tableCourses = [
    {
        columnName: 'Course ID',
        value: '1234',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Test',
        width: 150,
    },
    {
        columnName: 'Enrollment Type',
        value: 'Credit',
        width: 150,
    },
    {
        columnName: 'Status',
        value: 'Active',
        width: 150,
    },
    {
        columnName: 'Course Type',
        value: 'Online',
        width: 150,
    },
    // {
    //     columnName: 'Level',
    //     value: 'IELTS 4.0',
    //     width: 150,
    // },
    // {
    //     columnName: 'Subject',
    //     value: 'IELTS 4.0',
    //     width: 150,
    // },
    // {
    //     columnName: 'Language Delivery',
    //     value: '-',
    //     width: 170,
    // },
    // {
    //     columnName: 'Duration',
    //     value: '-',
    //     width: 150,
    // },
    // {
    //     columnName: 'Tags',
    //     value: '-',
    //     width: 150,
    // },
    {
        columnName: 'Active Learners',
        value: '3',
        width: 150,
    },
    {
        columnName: 'Total Learners',
        value: '5',
        width: 150,
    },
    {
        columnName: 'Active Instructors',
        value: '0',
        width: 150,
    },
    {
        columnName: 'Total Instructors',
        value: '1',
        width: 150,
    },
];

export const tableSessions = [
    {
        columnName: 'Session ID',
        value: '1234',
        width: 150,
    },
    {
        columnName: 'Start Time (mm/dd)',
        value: '02/24/2024 10:00',
        width: 200,
    },
    {
        columnName: 'End Time (mm/dd)',
        value: '02/24/2024 12:00',
        width: 200,
    },
    {
        columnName: 'Status',
        value: 'Completed',
        width: 150,
    },
    {
        columnName: 'Course ID',
        value: '1345',
        width: 150,
    },
    {
        columnName: 'Course Name',
        value: 'Test',
        width: 150,
    },
    {
        columnName: 'Learning Group ID',
        value: '-',
        width: 160,
    },
    {
        columnName: 'Lesson ID',
        value: '345',
        width: 150,
    },
    {
        columnName: 'Lesson Name',
        value: 'Test',
        width: 150,
    },
    {
        columnName: 'Virtual Classroom',
        value: 'https://meet.google.com/eyv-xoji-xhy?authuser=0',
        width: 400,
    },
    {
        columnName: 'Extra Link',
        value: 'https://meet.google.com/eyv-xoji-xhy?authuser=0',
        width: 400,
    },
    {
        columnName: 'Location',
        value: 'Ho Chi Minh',
        width: 150,
    },
    {
        columnName: 'Description',
        value: 'Lesson 23',
        width: 150,
    },
    {
        columnName: 'Lesson Notes',
        value: 'Speaking and writing',
        width: 180,
    },
    {
        columnName: 'Instructor Notes',
        value: 'Finished the lesson',
        width: 150,
    },
];

export type TableNameType =
    | 'learner-information'
    | 'session-performance'
    | 'credit-package'
    | 'invoice'
    | 'transactions-credit-account'
    | 'transactions-credit-balance'
    | 'instructor-ta-information'
    | 'teaching-history'
    | 'grade'
    | 'courses'
    | 'sessions'
    | '';

type DownloadTableType = {
    data: any[];
    fileName: string;
    table: TableNameType;
    extendData?: any;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const handleDownloadCSVTable = ({ data = [], table = '', fileName = '', extendData, setLoading }: DownloadTableType) => {
    if (!data.length || !table) {
        setLoading(false);
        handleFail('No data was found for the selected timeframe. Please adjust the date range and try again.');
        return;
    }

    let filename: string = fileName || table;
    let headers: any[] = [];
    let body: any[] = [];
    let tableData: any = [];

    const statusType = {
        invite: 'Invited',
        deactivate: 'Deactivated',
        active: 'Active',
    };

    switch (table) {
        // Learner
        case 'learner-information': {
            headers = tableLearnerInformation.map((el) => el.columnName);
            body = data.map((item) => {
                return [
                    item?.id || '-',
                    item?.display_name || '-',
                    item?.created_at ? dayjs(item?.created_at).tz(extendData?.tz).format(formatDate) : '-',
                    item?.email || '-',
                    item?.mobile_phone || '-',
                    capitalize(item?.gender) || '-',
                    item?.dob || '-',
                    statusType[item?.status] ? statusType[item?.status] : capitalize(item?.status) || '-',
                    item?.parent?.display_name || '-',
                    item?.parent?.email || '-',
                    item?.parent?.id || '-',
                ];
            });
            break;
        }
        case 'session-performance': {
            headers = tableSessionPerformance.map((el) => el.columnName);
            body = data.map((item) => {
                const enrollmentType = {
                    open_not_auto_enroll: 'Non-Credit',
                    open_auto_enroll: 'Non-Credit',
                    catalog: 'Non-Credit',
                    credit: 'Credit',
                };
                const goalAchieved = {
                    2: 'Yes',
                    1: 'No',
                    null: '-',
                };
                return [
                    item?.id || '-',
                    item?.course?.id || '-',
                    item?.course?.title || '-',
                    enrollmentType[item?.course?.enrollment_type] || '' || '-',
                    item?.start_time ? dayjs(item?.start_time).tz(extendData?.tz).format(formatFullDateTime) : '-',
                    item?.end_time ? dayjs(item?.end_time).tz(extendData?.tz).format(formatFullDateTime) : '-',
                    capitalize(item?.display_status) || '-',
                    item?.learner?.display_name || '-',
                    item?.learner?.id || '-',
                    capitalize(item?.attendance_type) || '-',
                    goalAchieved[item?.goal_achieved] || '' || '-',
                    item?.rewards_coin || '-',
                    item?.instructor_comment || '-',
                    item?.display_used_credit || '-',
                ];
            });
            break;
        }
        case 'credit-package': {
            headers = tableCreditPackage.map((el) => el.columnName);
            body = data.map((item) => {
                // let newStatus = item?.course_enrollment_sale_contract?.display_status || '';
                // if (
                //     !item?.course_enrollment_sale_contract?.display_status ||
                //     item?.course_enrollment_sale_contract?.display_status === 'active'
                // )
                //     newStatus = item?.status;
                return [
                    item?.id || '-',
                    item?.course?.id || '-',
                    item?.course?.title || '-',
                    item?.blocked_credit || item?.credit || '-',
                    item?.course_enrollment_sale_contract?.available_credit || '-',
                    item?.learner?.id || item?.learning_group?.id || '-',
                    item?.learner?.display_name || item?.learning_group?.name || '-',
                    capitalize(item?.course_enrollment?.display_status) || '-', // enrollment status
                    item?.crm_description_2 || '-',
                    capitalize(item?.status) || '-', // Credit Package Status
                    item?.created_at ? dayjs(item?.created_at).tz(extendData?.tz).format(formatDate) : '-',
                    item?.reason || '-',
                ];
            });
            console.log(body);
            break;
        }
        case 'invoice': {
            headers = tableInvoice.map((el) => el.columnName);
            body = data.map((item) => {
                const payment_option = PAYMENT_OPTION_LIST(extendData.translator).find((el) => el.value == item?.payment_option);
                return [
                    item?.id || '-',
                    item?.learner?.id || item?.learning_group?.id || '-',
                    item?.learner?.display_name || item?.learning_group?.name || '-',
                    item?.created_at ? dayjs(item?.created_at).tz(extendData?.tz).format(formatDate) : '-',
                    item?.due_at ? dayjs(item?.due_at).tz(extendData?.tz).format(formatDate) : '-',
                    item?.paid_at ? dayjs(item?.paid_at).tz(extendData?.tz).format(formatDate) : '-',
                    capitalize(item?.display_status) || '-',
                    item?.payment_option ? payment_option?.label || '-' : '-',
                    item?.note || '-',
                    item?.total_amount || '-', // extendData?.currency
                    item?.courses?.map((el) => el?.id).join(', ') || '-',
                    item?.notes || '-',
                ];
            });
            break;
        }
        case 'transactions-credit-account': {
            headers = tableTransactionsCreditAccount.map((el) => el.columnName);
            body = data.map((item) => {
                return [
                    item?.id || '-',
                    item?.created_at ? dayjs(item?.created_at).tz(extendData?.tz).format(formatFullDateTime) : '-',
                    item?.description + (!!item?.course ? ' ' + item?.course?.title : '') || '-',
                    item?.amount > 0 ? `${item?.txn_type === 'increase' ? '' : '-'}${item?.amount}` : '0',
                    item?.credit_balance || 0,
                    item?.created_by ? item?.created_by?.display_name : '-',
                    item?.credit_account?.id || '-',
                    item?.credit_account ? accountCreditType?.[item?.credit_account?.account_type]?.label : '-',
                    item?.learner?.id || '-',
                    item?.learner?.display_name || '-',
                    item?.learning_group?.id || '-',
                    item?.learning_group?.name || '-',
                    item?.note || '-',
                ];
            });
            break;
        }
        case 'transactions-credit-balance': {
            headers = tableTransactionsCreditBalance.map((el) => el.columnName);
            body = data.map((item) => {
                return [
                    item?.id || '-',
                    item?.created_at ? dayjs(item?.created_at).tz(extendData?.tz).format(formatFullDateTime) : '-',
                    (item?.description && item?.session?.start_time
                        ? `${item?.description} [${dayjs(item?.session?.start_time).tz(extendData?.tz).format(formatDateTime)}]`
                        : item?.description) || '-',
                    item?.amount > 0 ? `${item?.txn_type === 'increase' ? '' : '-'}${item?.amount}` : '0',
                    item?.credit_balance || 0,
                    item?.created_by ? item?.created_by?.display_name : '-',
                    item?.enrollment_credit_balance?.id || '-',
                    item?.course?.id || '-',
                    item?.course.title || '-',
                    item?.learner?.id || '-',
                    item?.learner?.display_name || '-',
                    item?.course_enrollment?.id || '-',
                    item?.course_enrollment ? accountCreditType?.[item?.course_enrollment?.type]?.label : '-',
                    (item?.course_enrollment && item?.course_enrollment?.type === 'individual'
                        ? item?.learner?.display_name
                        : item?.learning_group?.name) || '-',
                    item?.note || '-',
                ];
            });
            break;
        }
        case 'grade': {
            headers = tableGrade.map((el) => el.columnName);
            body = data.map((item) => {
                const attemptDetail = item?.assignment_attempt || null;
                let attemptStatus =
                    ASSIGNMENT_STATUS(extendData.translator)['assigned'] + (item?.assignment?.overdue_tag ? ' - Overdue' : '');
                let grade = null;
                const totalGrade = 100;
                let gradePercentage = null;
                let completionRate = null;
                let timeSpent = null;
                let submissionDate = null;
                let attemptOrder = null;
                let gradedBy = null;

                if (!!attemptDetail) {
                    attemptStatus = attemptDetail?.status
                        ? ASSIGNMENT_STATUS(extendData.translator)[attemptDetail?.status] + (item?.assignment?.overdue_tag ? ' - Overdue' : '')
                        : '-';
                    grade = attemptDetail?.final_score != null && attemptDetail?.status === 'completed' ? Math?.round(attemptDetail?.final_score) : null;
                    gradePercentage = grade != null ? Math.round((grade / totalGrade) * 100) + '%' : '-';
                    completionRate =
                        attemptDetail?.completion_percentage != null ? Math?.round(attemptDetail?.completion_percentage) + '%' : '-';
                    timeSpent =
                        attemptDetail?.total_time_spent != null ? Math?.round(attemptDetail?.total_time_spent / 60) + 'm' : '-';
                    submissionDate =
                        attemptDetail?.completion_date &&
                        (attemptDetail?.status == 'completed' || attemptDetail?.status == 'waiting_for_grade')
                            ? dayjs(attemptDetail?.completion_date).tz(extendData?.tz).format(formatDate)
                            : '-';
                    attemptOrder =
                        attemptDetail?.order_number != null && item?.assignment?.maximum_of_submission != null
                            ? `${attemptDetail?.order_number}/${item?.assignment?.maximum_of_submission}`
                            : '-';
                    gradedBy = item?.graded_by?.display_name || '-';
                }

                return [
                    item?.assignment?.id || '-',
                    item?.assignment?.name || '-',
                    item?.learner?.id || '-',
                    item?.learner?.display_name || '-',
                    item?.learning_group?.id || '-',
                    item?.learning_group?.name || '-',
                    attemptStatus,
                    grade || '-',
                    totalGrade,
                    gradePercentage,
                    completionRate,
                    timeSpent,
                    item?.assignment?.end_time ? dayjs(item?.assignment?.end_time).tz(extendData?.tz).format(formatDate) : '-',
                    submissionDate,
                    attemptOrder,
                    gradedBy,
                    item?.assignment?.created_at ? dayjs(item?.assignment?.created_at).tz(extendData?.tz).format(formatDateTime) : '-',
                    item?.course?.id || '-',
                    item?.course.title || '-',
                    item?.lesson?.id || '-',
                    item?.lesson?.title || '-',
                ];
            });
            break;
        }
        // Instructor - Teacher Assistant
        case 'instructor-ta-information': {
            headers = tableInstructorTAInformation.map((el) => el.columnName);
            body = data.map((item) => {
                return [
                    item?.id || '-',
                    item?.display_name || '-',
                    item?.created_at ? dayjs(item?.created_at).tz(extendData?.tz).format(formatDate) : '-',
                    statusType[item?.status] ? statusType[item?.status] : capitalize(item?.status) || '-',
                    item?.email || '-',
                    item?.user_role_name || '-',
                ];
            });
            break;
        }
        case 'teaching-history': {
            headers = tableTeachingHistory.map((el) => el.columnName);
            body = data.map((item) => {
                return [
                    item?.id || '-',
                    item?.course?.id || '-',
                    item?.course?.title || '-',
                    item?.start_time ? dayjs(item?.start_time).tz(extendData?.tz).format(formatFullDateTime) : '-',
                    item?.end_time ? dayjs(item?.end_time).tz(extendData?.tz).format(formatFullDateTime) : '-',
                    capitalize(item?.display_status) || '-',
                    item?.instructor?.id || '-',
                    item?.instructor?.display_name || '-',
                    item?.instructor?.user_role_name || '-',
                    item?.attendance || '-',
                ];
            });
            break;
        }
        // Courses - Sessions
        case 'courses': {
            headers = [...tableCourses, ...extendData?.properties].map((el) => el.columnName);
            body = data.map((item) => {
                const propertyValues: any[] = item?.properties_value || [];
                const propertyList: any[] = extendData?.properties?.map((pr) => {
                    let valueProperty = propertyValues.find((prv) => pr?.id === prv?.property_id)?.value || null;
                    if (valueProperty) {
                        if (Array.isArray(valueProperty)) valueProperty = valueProperty?.join(', ');
                        else if (pr?.property_type === propertyType.date_picker.value)
                            valueProperty = dayjs(valueProperty).tz(extendData?.tz).format(formatDate);
                    }
                    return valueProperty || '-';
                });
                return [
                    item?.id || '-',
                    item?.title || '-',
                    courseEnrollmentType?.[item?.settings?.enrollment_type]
                        ? extendData?.translator(courseEnrollmentType?.[item?.settings?.enrollment_type])
                        : '-',
                    courseStatus?.[item?.status] ? extendData?.translator(courseStatus?.[item?.status]) : '-',
                    item?.type?.length > 0 ? item?.type?.map((tp) => COURSE_TYPE?.[tp])?.join(', ') : '-',
                    // item?.level?.length > 0 ? item?.level?.join(', ') : '-',
                    // item?.subject?.length > 0 ? item?.subject?.join(', ') : '-',
                    // item?.language_delivery?.length > 0 ? item?.language_delivery?.join(', ') : '-',
                    // item?.duration?.length > 0 ? item?.duration?.join(', ') : '-',
                    // item?.tags?.length > 0 ? item?.tags?.join(', ') : '-',
                    item?.active_learner_cnt ?? '-',
                    item?.total_learner_cnt ?? '-',
                    item?.active_instructor_cnt ?? '-',
                    item?.total_instructor_cnt ?? '-',
                    ...propertyList,
                ];
            });
            break;
        }
        case 'sessions': {
            headers = tableSessions.map((el) => el.columnName);
            body = data.map((item) => {
                return [
                    item?.id || '-',
                    item?.start_time ? dayjs(item?.start_time).tz(extendData?.tz).format(formatDateTime) : '-',
                    item?.end_time ? dayjs(item?.end_time).tz(extendData?.tz).format(formatDateTime) : '-',
                    SESSION_STATUS(extendData?.translator)?.find((el) => el?.value === item?.display_status)?.name || '-',
                    item?.course?.id || '-',
                    item?.course?.title || '-',
                    item?.learning_group?.id || '-',
                    item?.lesson?.id || '-',
                    item?.lesson?.title || '-',
                    item?.vc_link || '-',
                    item?.extra_link || '-',
                    item?.location || '-',
                    item?.instructor_note || '-',
                    item?.lesson_notes || '-',
                    item?.instructor_notes || '-',
                ];
            });
            break;
        }
        default:
            break;
    }

    tableData = [headers, ...body];

    // handle download csv width web-worker
    const worker = new Worker(`${process.env.PUBLIC_URL}/csvWorker.js`);
    worker.postMessage({ data: tableData, fileName: filename });

    worker.onmessage = (e) => {
        const { csvContent, fileName } = e.data;
        downloadCSVWithWorker(csvContent, fileName);
        setLoading(false);
        worker.terminate();
    };

    worker.onerror = (error) => {
        console.error('Worker error:', error);
        setLoading(false);
        worker.terminate();
    };
};
