import dayjs from 'dayjs';
import { TagList } from '_shared';
import './DetailsFilePopup.scss';
import BlockContent from './BlockContent';
import { useEffect, useState } from 'react';

export default function Details({ translator, item, result, customFields, handleUpdateTag, isLoading }) {
    const [statistic, setStatistic] = useState({ unit: 0, lesson: 0, supplementary: 0, assignment: 0 })

    const getStatisticLessonInfo = () => {
        const unit = item?.sections?.length;
        let lesson = 0;
        let assignment = 0;
        let supplementary = 0
        for (const unit of (item?.sections || [])) {
            lesson = lesson + unit?.section_contents?.length
            for (const sectionContent of (unit?.section_contents || [])) {
                assignment = assignment + (sectionContent?.assignments || 0)
                supplementary = supplementary + (sectionContent?.supplementary_materials || 0)
            }
        }
        setStatistic({ unit, lesson, supplementary, assignment })
    }

    useEffect(() => {
        getStatisticLessonInfo()
    }, [item])
    return (
        <div>
            {item?.banner && (
                <div className="imageCover">
                    <img alt="libary-icon" className="banner" src={item?.banner} />
                </div>
            )}
            <BlockContent title={translator('DETAILS')}>
                <div>
                    <p className="content-item">
                        {translator('NAME')}:<p className="content-value">{item?.title}</p>
                    </p>
                    <p className="content-item">
                        {translator('DESCRIPTION')}:<p className="content-value">{item?.description}</p>
                    </p>
                    <p className={`content-item `}>
                        <div className="mr-1">{translator('OWNER')}: </div>
                        <div className="content-value avatar">
                            <div className="avatar-img">
                                <img alt="avatar-icon" src={item?.created_by?.avatar_url} />
                            </div>
                            <div className="content-value">{item?.created_by?.display_name || item?.created_by?.name}</div>
                        </div>
                    </p>
                    <p className="content-item">
                        {translator('LAST_MODIFIED')}:{' '}
                        <span className="content-value">
                            {dayjs(item?.updated_at).tz(result?.time_zone).format('MMM DD, YYYY') || '-'}
                        </span>
                    </p>
                    <p className="content-item">
                        {translator('CREATED')}:{' '}
                        <span className="content-value">
                            {dayjs(item?.created_at).tz(result?.time_zone).format('MMM DD, YYYY') || '-'}
                        </span>
                    </p>
                </div>

                <div className='mt-4'>
                    <div className="blockContent-header">
                        <div className="title">
                            {translator('LEARNING_PATH.LESSON_INFO', true)}
                        </div>
                        <div className="detailsFilePopup-line"></div>

                    </div>
                    <div className='lessonInfo'>
                        <div>{translator('LEARNING_PATH.UNITS', true)}: {statistic?.unit}</div>
                        <div>{translator('LEARNING_PATH.LESSONS', true)}: {statistic?.lesson}</div>
                        <div>{translator('LEARNING_PATH.ASSIGNMENTS', true)}: {statistic?.assignment}</div>
                        <div>{translator('LEARNING_PATH.SUPLLEMENTARY_MATERIALS', true)}: {statistic?.supplementary}</div>
                    </div>
                </div>
            </BlockContent>

            <div className="detailsFilePopup-tags">
                <TagList customFields={customFields} setCustomFields={handleUpdateTag} loading={isLoading} />
            </div>
        </div>
    );
}
