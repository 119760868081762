import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardContainer } from "components/Reports/components/CardContainer";
import { CardInfo } from "components/Reports/components/CardInfo";
import { Title } from "components/Reports/components/Title";
import { CircleSpin } from "components/UiComponents/CircleSpin";
import learnerReportService from "services/reports/learner-report.service";
import DatePickerComponent from "components/Reports/components/DatePicker";
import moment from "moment";

export const StudentAssignmentsOverview = () => {
    const { t } = useTranslation();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const params: { id: string, studentId: string } = useParams();
    const [loading, setLoading] = useState(true);
    const [assignments, setAssignments] = useState<any>();
    const search = useLocation()?.search;
    const type = new URLSearchParams(search).get('type');

    const getData = async () => {
        if (!startDate || !endDate) {
            return;
        }
        try {
            setLoading(true);
            const payload = {
                start_time: moment(new Date(startDate)).format('YYYY-MM-DD') + 'T00:00:00.000',
                end_time: moment(new Date(endDate)).format('YYYY-MM-DD') + 'T23:59:59.000',
                studentId: params?.studentId,
            }
            let response;
            if (type === 'learning-group') {
                response = await learnerReportService.assignmentsGroupStat(params.id, payload);
            } else {
                response = await learnerReportService.assignmentsStat(params.id, payload);
            }
            setAssignments(response?.data);

            setLoading(false);
        } catch {
            setLoading(false);
        }
    }

    const [startDate, setStartDate] = useState(new Date(moment().subtract(1, 'months').format('MM-DD-YYYY')));
    const [endDate, setEndDate] = useState(new Date());
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    useEffect(() => {
        getData();
    }, [startDate, endDate]);

    return <>
        <CardContainer>
            <div className="flex items-center">
                <Title>{translator('Overview')}</Title>
                <div className="ml-auto min-w-[132px]">
                    <DatePickerComponent onChange={onChange} startDate={startDate} endDate={endDate} />
                </div>
            </div>
            <div className="mt-4 min-h-[123px]">
                {loading ?
                    <div className="flex justify-center items-center w-full min-h-[123px]">
                        <CircleSpin color="text-primary-500" />
                    </div> :
                    <>
                        <div className="flex space-x-5">
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: assignments?.avg_grade || 0,
                                    percent: assignments?.avg_grade_changes_percent || 0,
                                    title: `${translator('Avg Grade')}`,
                                    hasPercent: true,
                                }} />
                            </div>
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: assignments?.avg_completion || 0,
                                    percent: assignments?.avg_completion_changes_percent || 0,
                                    title: `${translator('Avg Completion')}`,
                                    hasPercent: true,
                                    content: t('TOOLTIPS_ASSIGNMENT_COMPLETION')
                                }} />
                            </div>
                            <div className="w-1/3">
                                <CardInfo  {...{
                                    value: assignments?.avg_ontime || 0,
                                    percent: assignments?.avg_ontime || 0,
                                    title: `${t('AVG_ASSIGNMENT_ONE_TIME')}`,
                                    hasPercent: true,
                                    content: t('TOOLTIPS_ASSIGNMENT_ON_TIME')
                                }} />
                            </div>
                        </div>
                    </>
                }
            </div>
        </CardContainer>
    </>
}