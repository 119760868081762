export const H5P_LIBRARY: Record<any, any> = {
    InteractiveVideo: 'Interactive Video',
    CoursePresentation: 'Course Presentation',
    MultiChoice: 'Multiple Choice',
    QuestionSet: 'Quiz (Question Set)',
    Blanks: 'Fill in the Blanks',
    DragText: 'Drag the Words',
    Column: 'Column',
    DragQuestion: 'Drag and Drop',
    ImageHotspots: 'Image Hotspots',
    Accordion: 'Accordion',
    Dialogcards: 'Dialog Cards',
    SingleChoiceSet: 'Single Choice Set',
    MemoryGame: 'Memory Game',
    Flashcards: 'Flashcards',
    DocumentationTool: 'Documentation Tool',
    ArithmeticQuiz: 'ArithmeticQuiz',
    Chart: 'Chart',
    Collage: 'Collage',
    ImageHotspotQuestion: 'Find the Hotspot',
    GuessTheAnswer: 'Guess the Answer',
    IFrameEmbed: 'Iframe Embedder',
    MarkTheWords: 'Mark the Words',
    PersonalityQuiz: 'Personality Quiz',
    Questionnaire: 'Questionnaire',
    Summary: 'Summary',
    Timeline: 'Timeline',
    TrueFalse: 'True/False Question',
    ImageMultipleHotspotQuestion: 'Find Multiple Hotspots',
    ImageJuxtaposition: 'Image Juxtaposition',
    Audio: 'Audio',
    AudioRecorder: 'Audio Recorder',
    SpeakTheWords: 'Speak the Words',
    Agamotto: ' Agamotto (Image Blender)',
    ImageSequencing: 'Image Sequencing',
    SpeakTheWordsSet: 'Speak the Words Set',
    ImageSlider: 'Image Slider',
    Essay: 'Essay',
    ImagePair: 'Image Pairing',
    Dictation: 'Dictation',
    BranchingScenario: 'Branching Scenario',
    ThreeImage: 'Virtual Tour (360)',
    FindTheWords: 'Find the words',
    InteractiveBook: 'Interactive Book',
    KewArCode: 'KewAr Code',
    AdventCalendar: 'Advent Calendar',
    Crossword: 'Crossword',
    SortParagraphs: 'Sort the Paragraphs',
    MultiMediaChoice: 'Image Choice',
    AdvancedBlanks: 'Complex fill the blanks',
    ARScavenger: 'AR Scavenger',
    Cornell: 'Cornell',
    StructureStrip: 'Structure Strip',
    InfoWall: 'Information Wall',
};

export const H5P_STATUS: Record<any, any> = {
    pending: 'Pending',
    draft: 'Draft',
    public: 'Publish',
    archive: 'Archive',
    trash: 'Trash',
};

export const CONTENT_TYPE = (translator) => ({
    folder: translator('LIBRARY.CONTENT_TYPES.FOLDER'),
    video: translator('LIBRARY.CONTENT_TYPES.VIDEO'),
    audio: translator('LIBRARY.CONTENT_TYPES.AUDIO'),
    document: translator('LIBRARY.CONTENT_TYPES.DOCUMENT'),
    image: translator('LIBRARY.CONTENT_TYPES.IMAGE'),
    pdf: translator('LIBRARY.CONTENT_TYPES.PDF'),
    h5p: translator('LIBRARY.CONTENT_TYPES.H5P'),
    lesson: translator('LIBRARY.CONTENT_TYPES.LESSON'),
    scorm: 'SCORM',
    presentation: translator('LIBRARY.CONTENT_TYPES.PRESENTATION'),
    spreadsheet: translator('LIBRARY.CONTENT_TYPES.SPREADSHEET'),
    assignment: translator('LIBRARY.CONTENT_TYPES.ASSIGNMENT'),
});

export const CONTENT_TYPE_LESSON_BULDER = (translator) => ({
    folder: translator('LIBRARY.CONTENT_TYPES.FOLDER'),
    video: translator('LIBRARY.CONTENT_TYPES.VIDEO'),
    audio: translator('LIBRARY.CONTENT_TYPES.AUDIO'),
    document: translator('LIBRARY.CONTENT_TYPES.DOCUMENT'),
    image: translator('LIBRARY.CONTENT_TYPES.IMAGE'),
    pdf: translator('LIBRARY.CONTENT_TYPES.PDF'),
    presentation: translator('LIBRARY.CONTENT_TYPES.PRESENTATION'),
    spreadsheet: translator('LIBRARY.CONTENT_TYPES.SPREADSHEET'),
    h5p: translator('LIBRARY.CONTENT_TYPES.H5P'),
    scorm: 'SCORM',
});

export const FILE_TYPE = ['video', 'audio', 'document', 'image', 'pdf', 'presentation', 'spreadsheet'];
