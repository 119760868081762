import { createSlice } from '@reduxjs/toolkit';

type ScormState = {
    initialized: boolean;
    cmi: any;
};

const initialState: ScormState = {
    initialized: false,
    cmi: {
        core: {
            lesson_status: '',
        },
    },
};

const scormSlice = createSlice({
    name: 'scorm',
    initialState,
    reducers: {
        setInitialized(state, action) {
            state.initialized = action.payload;
        },
        setCMIValue(state, action) {
            state.cmi[action.payload.path] = action.payload.value;
        },
    },
});

export const { setInitialized, setCMIValue } = scormSlice.actions;
export default scormSlice.reducer;
