import React from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { classNames } from 'utils/commonFun';

const ErrorMessageForm: React.FC<{
    errors: any;
    name: string;
    classes?: string;
}> = ({ errors, name, classes = '' }) => {
    return (
        <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
                <p className={classNames('normal-case text-xs text-red-500 font-normal mt-1', classes)}>{message}</p>
            )}
        />
    );
};

export default ErrorMessageForm;
