import { CalendarIcon, PencilAltIcon } from '@heroicons/react/outline';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DatePicker from 'components/DatePicker/DatePicker';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { UserContext } from 'contexts/User/UserContext';
import { ENROLLMENT_STATUS, ENROLLMENT_TYPE, ENROLLMENT_TYPE_OPTION } from '../../constants';
import Spinner from 'components/Spinner';
import DialogComponent from 'components/Dialog/DialogComponent';
import './Detail.scss';
import { useTranslation } from 'react-i18next';
import { setEnrollmentSelected } from 'store/features/courseSlice';
import { checkPermission } from 'utils/commonFun';
import { ListUser } from '_shared/components/User/User';
import ModalUpdateInstructor from './ModalUpdateInstructor';
import EnrollmentStatus from '_shared/components/EnrollmentStatus/EnrollmentStatus';
import { toast } from 'react-toastify';
import { ModalDeactivatedActiveLearner } from 'components/Workspace/Course/CourseBuilder/components/CoursePeople/Enrollment/components/ModalDeactivatedActiveLearner';

export default function EnrollmentInformation() {
    const { enrollmentSelected, setting } = useAppSelector((state) => state.course);
    const dispatch = useAppDispatch();
    const params: { id: string; courseId: string } = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId;
    const [data, setData] = useState<any>([]);
    const [dateInfo, setDateInfo] = useState({
        startDate: new Date(),
        endDate: new Date(),
        defaultEndDate: new Date(),
    });
    const { t: translator } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [isOpenModalUpdateInstructor, setOpenModalUpdateInstructor] = useState(false);
    const [isOpenModalUpdateEndDate, setOpenModalUpdateEndDate] = useState(false);
    const {
        userState: { result },
    } = useContext(UserContext);

    const [modalDeactivatedActive, setModalDeactivatedActive] = useState<'deactivated' | 'active' | null>(null);
    // const [isOpenModalActivate, setOpenModalActivate] = useState(false);

    // const onDeactivateEnrollment = () => {
    //     const loadingToastId = Toastify.loading(translator('COURSES.DEACTIVATING_ENROLLMENT'));
    //     courseService
    //         .updateEnrollmentDetail(workspaceId, courseId, enrollmentSelected?.id, { status: ENROLLMENT_STATUS.DEACTIVATED })
    //         .then((res) => {
    //             toast.dismiss(loadingToastId);
    //             Toastify.success();
    //             setModalDeactivatedActive(false);
    //             dispatch(setEnrollmentSelected({ ...enrollmentSelected, ...res?.data }));
    //         })

    //         .catch(() => {
    //             toast.dismiss(loadingToastId);
    //             Toastify.error();
    //         });
    // };

    // const onActiveEnrollment = () => {
    //     courseService
    //         .updateEnrollmentDetail(workspaceId, courseId, enrollmentSelected?.id, { status: ENROLLMENT_STATUS.ENROLLED })
    //         .then((res) => {
    //             Toastify.success();
    //             setOpenModalActivate(false);
    //             dispatch(setEnrollmentSelected({ ...enrollmentSelected, ...res?.data }));
    //         })
    //         .catch(() => Toastify.error());
    // };

    useEffect(() => {
        const dataEnrollmentDetail = [
            {
                label: translator('Learner Email'),
                value: enrollmentSelected?.learner?.email,
                hide:
                    enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP ||
                    (!enrollmentSelected?.learner?.email && enrollmentSelected?.parent?.email),
            },
            {
                label: translator('Parent Email'),
                value: enrollmentSelected?.learner?.email,
                hide: enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP || !enrollmentSelected?.parent?.email,
            },
            {
                label: translator('COURSES.ENROLLED_DATE'),
                value: dayjs.utc(enrollmentSelected?.enrolled_at).tz(result?.time_zone)?.format('DD/MM/YYYY'),
            },
            {
                label: translator('COURSES.START_DATE'),
                value: dayjs.utc(enrollmentSelected?.start_date).tz(result?.time_zone)?.format('DD/MM/YYYY'),
            },
        ];
        if (enrollmentSelected?.type === ENROLLMENT_TYPE.INDIVIDUAL) {
            dataEnrollmentDetail?.unshift({
                label: translator('COURSES.LEARNER_NAME'),
                value: enrollmentSelected?.learner?.display_name,
            });
        }
        if (enrollmentSelected?.type === ENROLLMENT_TYPE.LEARNING_GROUP) {
            dataEnrollmentDetail?.unshift({
                label: translator('COURSES.LEARNING_GROUP_NAME'),
                value: enrollmentSelected?.learning_group?.name,
            });
        }
        setData(dataEnrollmentDetail);
        setDateInfo({
            endDate: new Date(dayjs.utc(enrollmentSelected?.end_date).tz(result?.time_zone)?.format()),
            startDate: new Date(dayjs.utc(enrollmentSelected?.start_date).tz(result?.time_zone)?.format()),
            defaultEndDate: new Date(dayjs.utc(enrollmentSelected?.end_date).tz(result?.time_zone)?.format()),
        });
    }, [enrollmentSelected, translator, setting?.enrollment_type]);

    const onUpdateEndDate = () => {
        courseService
            .updateEnrollmentDetail(workspaceId, courseId, enrollmentSelected?.id, { end_date: dateInfo?.endDate })
            .then((res) => {
                Toastify.success();
                setDateInfo({ ...dateInfo, defaultEndDate: dateInfo?.endDate });
                setOpenModalUpdateEndDate(false);
                dispatch(setEnrollmentSelected({ ...enrollmentSelected, ...res?.data }));
            })
            .catch(() => Toastify.error());
    };

    if (loading) {
        return (
            <div className="w-full h-full flex justify-center items-center">
                <Spinner />
            </div>
        );
    }

    return (
        <div className="EnrollmentInformation">
            {data
                ?.filter((item) => !item?.hide)
                ?.map((item, index) => {
                    return (
                        <div key={index} className="EnrollmentInformation-field">
                            <div className="label">{item?.label}</div>
                            <div className="value text-ellipsis truncate">{item?.value || '-'}</div>
                        </div>
                    );
                })}
            <div className="EnrollmentInformation-field">
                <div className="label">{translator('COURSES.END_DATE')}</div>
                <div className="value">
                    <input className="w-20" value={dayjs(dateInfo?.defaultEndDate)?.format('DD/MM/YYYY')} title={''} />
                    {checkPermission('coursePeopleEnrollment', 'U') && (
                        <div onClick={() => setOpenModalUpdateEndDate(true)} className="changeEndDate">
                            {translator('COURSES.CHANGE_DATE')}
                            <CalendarIcon className="icon" />
                        </div>
                    )}
                </div>
            </div>

            <div className="EnrollmentInformation-field">
                <div className="label">{translator('COURSES.INSTRUCTORS')}</div>
                <div className="value">
                    <div className="flex gap-1">
                        <ListUser
                            fontsize="!text-sm"
                            id="instructor-tooltip"
                            users={enrollmentSelected?.instructors?.map((instructor) => ({
                                ...instructor,
                                link: `/workspace/${params?.id}/management/users/${instructor?.id}/tab=information`,
                            }))}
                        />
                        <div className="flex gap-1 w-20 items-center">
                            <span className="text-sm text-primary-500">{translator('EDIT')}</span>
                            <PencilAltIcon
                                className="text-primary-500 cursor-pointer w-5"
                                onClick={() => setOpenModalUpdateInstructor(true)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="EnrollmentInformation-field !hidden">
                <div className="label">{translator('COURSES.TEACHING_ASSISTANTS')}</div>
                <div className="value">
                    <ListUser
                        fontsize="!text-sm"
                        id="teaching-assistant-tooltip"
                        users={enrollmentSelected?.teaching_assistants?.map((item) => ({
                            ...item,
                            link: `/workspace/${params?.id}/management/users/${item?.id}/tab=information`,
                        }))}
                    />
                </div>
            </div>

            <div className="EnrollmentInformation-field">
                <div className="label">{translator('COURSES.STATUS')}</div>
                <div className="value">
                    <div className="flex gap-2 items-center">
                        <div>
                            <EnrollmentStatus status={enrollmentSelected?.display_status} />
                        </div>
                        {enrollmentSelected?.display_status !== ENROLLMENT_STATUS.EXPIRED && (
                            <div className=" cursor-pointer">
                                {enrollmentSelected?.display_status === ENROLLMENT_STATUS.ACTIVE ||
                                enrollmentSelected?.display_status == ENROLLMENT_STATUS.ENROLLED ? (
                                    <div
                                        onClick={() => setModalDeactivatedActive('deactivated')}
                                        className=" text-sm text-red-500 font-normal"
                                    >
                                        {translator('DEACTIVATE')}
                                    </div>
                                ) : (
                                    <div
                                        onClick={() => setModalDeactivatedActive('active')}
                                        className=" text-enterprise text-sm font-normal"
                                    >
                                        {translator('ACTIVATE')}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <DialogComponent
                title={translator('COURSES.CHANGE_END_DATE')}
                isOpen={isOpenModalUpdateEndDate}
                onCloseModal={() => setOpenModalUpdateEndDate(false)}
                styles="max-w-md "
                child={
                    <div className="ModalChangeEndDate">
                        <DatePicker
                            onChange={(value) => setDateInfo({ ...dateInfo, endDate: value })}
                            mindate={dateInfo?.startDate}
                            selected={dateInfo?.endDate}
                            dateFormat={'dd/MM/yyyy'}
                            inputProps={{ className: 'ModalChangeEndDate-input' }}
                            positionIcon="right"
                            placeholder={translator('COURSES.ENTER_DATE')}
                        />
                        <div className="GroupBtn">
                            <ButtonOutline type="button" onClick={() => setOpenModalUpdateEndDate(false)}>
                                {translator('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary disabled={!dateInfo.endDate} type="button" onClick={onUpdateEndDate}>
                                {translator('UPDATE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            {isOpenModalUpdateInstructor && (
                <DialogComponent
                    title={translator('COURSES.EDIT_INSTRUCTOR')}
                    isOpen={isOpenModalUpdateInstructor}
                    onCloseModal={() => setOpenModalUpdateInstructor(false)}
                    styles="!max-w-lg"
                    child={
                        <ModalUpdateInstructor onCloseModal={() => setOpenModalUpdateInstructor(false)} data={enrollmentSelected} />
                    }
                />
            )}
            <ModalDeactivatedActiveLearner
                type={modalDeactivatedActive}
                isOpen={!!modalDeactivatedActive}
                onClose={() => setModalDeactivatedActive(null)}
                data={enrollmentSelected}
                callback={(data) => dispatch(setEnrollmentSelected({ ...enrollmentSelected, ...data }))}
            />
            {/* {isOpenModalDeactivate && (
                <DialogComponent
                    title={translator('DEACTIVATE')}
                    isOpen={isOpenModalDeactivate}
                    onCloseModal={() => setOpenModalDeactivate(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">
                                {setting?.enrollment_type === 'credit'
                                    ? translator('COURSES.DEACTIVATE_ENROLLMENT_CREDIT_MSG')
                                    : translator('COURSES.DEACTIVATE_ENROLLMENT_MESSAGE')}
                            </div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalDeactivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" className="buttonPrimary-danger" onClick={onDeactivateEnrollment}>
                                    {setting?.enrollment_type === 'credit' ? translator('YES') : translator('DEACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )}
            {isOpenModalActivate && (
                <DialogComponent
                    title={translator('COURSES.ACTIVE_CONFIRMATION')}
                    isOpen={isOpenModalActivate}
                    onCloseModal={() => setOpenModalActivate(false)}
                    child={
                        <div className="flex flex-col">
                            <div className="mt-2 text-sm text-gray-800">{translator('COURSES.ACTIVATE_ENROLLMENT_MESSAGE')}</div>
                            <div className="flex justify-center gap-4 mt-6 w-full">
                                <ButtonOutline type="button" onClick={() => setOpenModalActivate(false)}>
                                    {translator('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="button" onClick={onActiveEnrollment}>
                                    {translator('ACTIVATE')}
                                </ButtonPrimary>
                            </div>
                        </div>
                    }
                />
            )} */}
        </div>
    );
}
