import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import SearchBox from 'components/V2/SearchBox';
import { ATTENDANCE_TYPE, SESSION_STATUS } from 'constant/course.const';

interface IFilter {
    onChangeFilter: (value: any) => void;
    className?: string;
}

export const FilterSessionHistory = ({ onChangeFilter, className = '' }: IFilter) => {
    const { t } = useTranslation();

    const [keyword, setKeyword] = useDebouncedState('');

    const [sessionStatus, setSessionStatus] = useState<any>([]);
    const [attendanceType, setAttendanceType] = useState<any>([]);

    const updateFilter = useCallback(
        debounce((listFilter) => onChangeFilter(listFilter), 1500),
        []
    );

    useEffect(() => {
        let listFilter: any = {
            session_status: !!sessionStatus?.length ? sessionStatus?.map((i) => i?.value) : null,
            session_attendance: !!attendanceType?.length ? attendanceType?.map((i) => i?.value) : null,
            q: keyword,
        };

        if (!listFilter?.session_status) delete listFilter?.session_status;
        if (!listFilter?.session_attendance) delete listFilter?.session_attendance;
        if (!listFilter?.q) delete listFilter?.q;
        console.log(listFilter)
        updateFilter(listFilter);
    }, [sessionStatus, attendanceType, keyword]);

    return (
        <div className={`sessionHistoryFilter flex items-center space-x-2 ${className}`}>
            <SearchBox placeholder={t('Search')} className="max-w-sm" onSubmit={(value) => setKeyword(value)} />
            <SelectBox
                label={t('STATUS')}
                isMulti
                onSelect={setSessionStatus}
                data={SESSION_STATUS(t)}
                selectedItems={sessionStatus}
                total={Object.keys(SESSION_STATUS(t))?.length}
            />
            <SelectBox
                label={t('COURSES.ATTENDANCE')}
                isMulti
                onSelect={setAttendanceType}
                data={ATTENDANCE_TYPE(t)}
                selectedItems={attendanceType}
                total={Object.keys(ATTENDANCE_TYPE(t))?.length}
            />
        </div>
    );
};
