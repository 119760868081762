import React, { useMemo, useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { LIBRARY_ICONS } from 'components/Library/LibraryContants';
import { PreviewContentFullScreen } from '_shared/PreviewContent/PreviewContentFullScreen';

export const SubmissionTask = ({ task }: { task: any }) => {
    const { t: translator } = useTranslation();
    const [previewFile, setPreviewFile] = useState(null);

    return (
        <div className="text-base">
            <div
                className="submission-description"
                dangerouslySetInnerHTML={{
                    __html: task?.submission_instruction?.replaceAll(/\n/g, "<br>"),
                }}
            ></div>
            <div className="mt-2 flex flex-col space-y-2 px-4 py-3 rounded-2xl bg-gray-50 truncate">
                <p className="flex items-center space-x-2">
                    <PaperClipIcon className="h-6 w-6 text-gray-800" />
                    <span className="font-semibold">Submission File(s)</span>
                </p>
                {!!task?.task_result?.files?.length ? task?.task_result?.files?.map((f, i) => {
                    const object_type = LIBRARY_ICONS(translator).find((el) => el?.value?.includes(f?.file_name?.split('.')?.[1]));
                    const file = {
                        ...f,
                        name: f?.file_name,
                        file: f?.file_url,
                        object_type: f?.file_name?.split('.').reverse()?.[0] || null,
                    };
                    return (
                        <p className="truncate text-enterprise cursor-pointer" key={i} onClick={() => setPreviewFile(file)}>
                            {i + 1 + '. ' + file?.file_name}
                        </p>
                    );
                }) : <p className='text-gray-500'>No Submission.</p>}
            </div>
            <PreviewContentFullScreen isOpen={!!previewFile} data={previewFile} closeModal={() => setPreviewFile(null)} />
        </div>
    );
};
