import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { InputField, LabelForm } from 'components/InputForm/InputForm';
import { ExclamationCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import { CalendarIcon, CheckIcon } from '@heroicons/react/outline';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { User } from '_shared/components/User/User';
import workspaceService from 'services/workspace.service';
import { getIndexPage } from 'utils/commonFun';
import { ResponseData } from 'types/ApiData.type';
import { useBoolean } from 'hooks/custom';
import DialogComponent from 'components/Dialog/DialogComponent';
import { Toast } from 'utils/sweetAlert';
import { useForm } from 'react-hook-form';
import { DatePickerField } from 'components/DatePicker';
import { STATUS_BG_COLOR, STATUS_BORDER_COLOR, STATUS_COLOR } from 'constant/util.const';
import { EditIcon } from 'assets/icon';

export type ApiKeyType = {
    id: number;
    workspace_id: number;
    prefix: string;
    expires_at: Date;
    revoked_at: Date;
    created_at: Date;
    updated_at: Date;
    created_by: {
        id: number;
        membership_id: number;
        email: string;
        first_name: string;
        last_name: string;
        display_name: string;
        avatar_url: string;
    };
    api_key?: string;
};

const ApiKeyIntegration = () => {
    const params: any = useParams();
    const { t } = useTranslation();

    const { booleanValue: modalRegenerateApiKey, toggleBooleanValue: toggleModalRegenerateApiKey } = useBoolean();
    const { booleanValue: modalUpdateApiKey, toggleBooleanValue: toggleModalUpdateApiKey } = useBoolean();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingCreateUpdate, setLoadingCreateUpdate] = useState<boolean>(false);
    const [dataTable, setDataTable] = useState<ApiKeyType[]>([]);
    const [indexPage, setIndexPage] = useState('0-0');
    const [firstApiKeyWorkspace, setFirstApiKeyWorkspace] = useState<boolean>(false);
    const [apiKeySelected, setApiKeySelected] = useState<ApiKeyType | null>(null);
    const [pagination, setPagination] = useState<Partial<ResponseData>>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'created_at',
        total: 0,
    });

    const {
        userState: { result },
    } = useContext(UserContext);

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        reset,
        control,
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            expireDate: null,
        },
    });

    const workSpaceId = params?.id;
    const defaultExpire = dayjs().add(1, 'year').toDate();

    const fetchApiKeys = (page = 1, isSetValue = true) => {
        if (loading) return;

        setLoading(true);

        const payload = {
            ...pagination,
            page: page ? page : pagination.page,
            skip_pagination: true,
            // is_revoked: true,
            // is_expired: true,
        };
        delete payload?.total;

        workspaceService
            .getApiKeyWorkspace(workSpaceId, payload)
            .then((res) => {
                const { items, total } = res.data;
                setDataTable(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
                if (isSetValue) {
                    const keySelected: ApiKeyType = items?.filter((el) => checkApiKey(el) === 'active')?.[0];
                    reset({ expireDate: keySelected?.expires_at ? new Date(keySelected?.expires_at) : null });
                    setApiKeySelected(keySelected || null);
                }
            })
            .finally(() => setLoading(false));
    };

    const onCreateApiKey = () => {
        setLoadingCreateUpdate(true);

        workspaceService
            .createApiKeyWorkspace(workSpaceId, { expires_at: defaultExpire })
            .then((res) => {
                Toastify.success();
                setFirstApiKeyWorkspace(!!res?.data);
                fetchApiKeys(1, false);
                reset({ expireDate: res?.data?.expires_at ? new Date(res?.data?.expires_at) : null });
                setApiKeySelected(res?.data || null);
            })
            .catch(() => {
                Toastify.error();
            })
            .finally(() => setLoadingCreateUpdate(false));
    };

    const onUpdateApiKey = () => {
        setLoadingCreateUpdate(true);
        workspaceService
            .updateApiKeyWorkspace(workSpaceId, `${apiKeySelected?.id}`, { expires_at: watch('expireDate') })
            .then((res) => {
                Toastify.success();
                fetchApiKeys(1, !firstApiKeyWorkspace);
                if (firstApiKeyWorkspace) {
                    const api_key = apiKeySelected?.api_key;
                    reset({ expireDate: res?.data?.expires_at ? new Date(res?.data?.expires_at) : null });
                    setApiKeySelected({ ...apiKeySelected, ...res?.data, api_key });
                }
            })
            .catch(() => {
                Toastify.error();
            })
            .finally(() => {
                setLoadingCreateUpdate(false);
                toggleModalUpdateApiKey();
            });
    };

    const onRevokeRegenerateApiKey = async () => {
        try {
            setLoadingCreateUpdate(true);
            if (apiKeySelected) await workspaceService.revokeApiKeyWorkspace(workSpaceId, `${apiKeySelected?.id}`);
            const res = await workspaceService.createApiKeyWorkspace(workSpaceId, { expires_at: defaultExpire });
            Toastify.success();
            fetchApiKeys(1, false);
            setFirstApiKeyWorkspace(!!res?.data);
            reset({ expireDate: res?.data?.expires_at ? new Date(res?.data?.expires_at) : null });
            setApiKeySelected(res?.data || null);
        } catch (error) {
            Toastify.error();
        } finally {
            setLoadingCreateUpdate(false);
            toggleModalRegenerateApiKey();
        }
    };

    const handleCloseUpdateApiKey = () => {
        const tempDate = apiKeySelected?.expires_at || null;
        reset({ expireDate: tempDate ? new Date(tempDate) : null });
        toggleModalUpdateApiKey();
    };

    const columns = useMemo(
        () => [
            {
                accessor: 'id',
            },
            {
                Header: () => <RenderTableHeader value={t('KEY_PREFIX')} className="!text-sm" />,
                accessor: 'prefix',
                Cell: ({ row }) => {
                    return <RenderTableCell value={row.values?.prefix} className="!text-sm" />;
                },
                width: '200',
            },
            {
                Header: () => <RenderTableHeader value={t('CREATED_DATE')} className="!text-sm" />,
                accessor: 'created_at',
                Cell: ({ row }: any) => (
                    <RenderTableCell
                        value={dayjs.utc(row.values?.created_at).tz(result?.time_zone).format('DD/MM/YYYY')}
                        className="!text-sm"
                    />
                ),
                width: '100',
            },
            {
                Header: () => <RenderTableHeader value={t('CREATED_BY')} className="!text-sm" />,
                accessor: 'created_by',
                Cell: ({ row }: any) => (
                    <div className="h-full flex items-center cursor-default">
                        <User
                            avatar={row?.values?.created_by?.avatar_url}
                            name={row?.values?.created_by?.display_name}
                            isOpenNewTab={true}
                            link={`/workspace/${workSpaceId}/management/users/${row?.values?.created_by?.id}?tab=information`}
                            className="!text-sm"
                        />
                    </div>
                ),
                width: '150',
            },
            {
                Header: () => <RenderTableHeader value={t('EXPIRATION_DATE')} className="!text-sm" />,
                accessor: 'expires_at',
                Cell: ({ row }: any) => (
                    <div className="pl-ooolab_p_1_e h-full flex items-center space-x-1">
                        <RenderTableCell
                            value={dayjs.utc(row.values?.expires_at).tz(result?.time_zone).format('DD/MM/YYYY')}
                            className="!text-sm !pl-0"
                        />
                        {checkApiKey(row?.original) === 'active' && (
                            <button title={''} type="button" onClick={toggleModalUpdateApiKey}>
                                <EditIcon fill="#0071CE" />
                            </button>
                        )}
                    </div>
                ),
                width: '120',
            },
            {
                Header: () => <RenderTableHeader value={t('STATUS')} className="!text-sm" />,
                accessor: 'status',
                Cell: ({ row }: any) => {
                    let statusDisplay = checkApiKey(row?.original);
                    let color = STATUS_COLOR[statusDisplay];
                    let bg = STATUS_BG_COLOR[statusDisplay];
                    let borderColor = STATUS_BORDER_COLOR[statusDisplay];

                    return (
                        <div className="flex items-center h-full">
                            <span className="px-2 py-[1px] rounded border" style={{ borderColor: borderColor, color, background: bg }}>
                                {t(statusDisplay.toUpperCase())}
                            </span>
                        </div>
                    );
                },
                width: '80',
            },
        ],
        [dataTable]
    );

    const checkApiKey = (value) => {
        const revokedDate = value?.revoked_at;
        const expireDate = value?.expires_at;

        let statusDisplay = 'active';

        if (!!revokedDate) {
            statusDisplay = 'revoked';
        } else if (dayjs().isAfter(dayjs(expireDate))) {
            statusDisplay = 'expired';
        }

        return statusDisplay;
    };

    useEffect(() => {
        fetchApiKeys();
    }, []);

    return (
        <form
            onSubmit={handleSubmit(onUpdateApiKey)}
            className="mt-8 bg-white p-6 rounded-2xl flex flex-col space-y-4 text-gray-800 font-normal text-sm"
        >
            <div className="flex items-center justify-between pb-4 border-b border-gray-300">
                <h1 className="text-xl font-semibold">{t('API_KEY_INTEGRATION')}</h1>
                {!firstApiKeyWorkspace ? (
                    !dataTable?.length ? (
                        <ButtonPrimary type="button" disabled={loadingCreateUpdate} onClick={onCreateApiKey}>
                            {loadingCreateUpdate && <CircleSpin className="mr-1" />}
                            <span> {t('GENERATE_KEY')}</span>
                        </ButtonPrimary>
                    ) : (
                        <ButtonOutline
                            type="button"
                            disabled={loadingCreateUpdate}
                            className="buttonOutline--isPrimary"
                            onClick={toggleModalRegenerateApiKey}
                        >
                            {loadingCreateUpdate && <CircleSpin className="mr-1" />}
                            <span> {t('REGENERATE_KEY')}</span>
                        </ButtonOutline>
                    )
                ) : (
                    <p className="text-green-500 font-semibold flex items-center space-x-1">
                        <CheckIcon className="h-6 w-6" />
                        <span>{t('API_KEY_GENERATED')}</span>
                    </p>
                )}
            </div>
            {loading ? (
                <div className="flex items-center justify-center">
                    <CircleSpin color="text-primary-500" />
                </div>
            ) : !dataTable?.length ? (
                <p className="text-gray-500 text-base">{t('NO_API_KEY_MESSAGE')}</p>
            ) : (
                <>
                    {firstApiKeyWorkspace && (
                        <div>
                            <div className="flex items-center justify-between space-x-2">
                                <div className="flex-1">
                                    <LabelForm title={t('API Key')} className="!text-sm" />
                                    <InputField
                                        defaultValue={apiKeySelected?.api_key}
                                        disabled
                                        classNameInput="max-h-10 !py-3 bg-gray-100 !text-sm !text-[#8F90A6]"
                                    />
                                </div>
                                <div className="flex-none flex items-center space-x-4">
                                    <div>
                                        <p className="opacity-0 h-6">.</p>
                                        <ButtonPrimary
                                            type="button"
                                            disabled={false}
                                            className="max-h-10 !py-3"
                                            onClick={() => {
                                                navigator.clipboard.writeText(apiKeySelected?.api_key || '');
                                                Toast.fire({
                                                    icon: 'success',
                                                    title: t('LIBRARY.LIBRARY_LINK_COPIED'),
                                                });
                                            }}
                                        >
                                            {t('COPY_KEY')}
                                        </ButtonPrimary>
                                    </div>
                                    <div className="w-36">
                                        <LabelForm title={t('EXPIRATION_DATE')} className="!text-sm" />
                                        <p
                                            className="rounded px-3 py-2.5 max-h-10 border border-gray-300 cursor-pointer flex items-center justify-between"
                                            onClick={toggleModalUpdateApiKey}
                                        >
                                            <span>{dayjs(apiKeySelected?.expires_at).format('DD/MM/YYYY')}</span>
                                            <CalendarIcon className="w-5 h-5" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <div className="relative">
                                    <span className="absolute -top-[1px] left-1">●</span>
                                    <p className="pl-4">
                                        <Trans i18nKey="API_KEY_MESSAGE_1">
                                            <strong>only displayed once</strong>
                                            <strong>We do not store or display it again</strong>
                                        </Trans>
                                    </p>
                                </div>
                                <div className="relative">
                                    <span className="absolute -top-[1px] left-1">●</span>
                                    <p className="pl-4">
                                        <Trans i18nKey="API_KEY_MESSAGE_2">
                                            <strong>1 year</strong>
                                        </Trans>
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                    <div>
                        <h2 className="text-base font-semibold mb-2">{t('API_KEY_CREATION_LOG')}</h2>
                        <div className='border border-gray-300 border-t-0 rounded-lg overflow-hidden'>
                            <Table
                                initialState={{ hiddenColumns: ['id'] }}
                                data={dataTable}
                                columns={columns}
                                emptyContent={t('NO_API_KEY_MESSAGE')}
                            />
                        </div>
                    </div>
                    {!!dataTable?.length && !firstApiKeyWorkspace && (
                        <div className="flex flex-col space-y-3">
                            <p>
								<Trans i18nKey="API_KEY_MESSAGE_3" values={{ created_by: (apiKeySelected || dataTable?.[0])?.created_by?.display_name }}>
									<strong
										className="text-enterprise cursor-pointer"
										onClick={() => {
											window.open(
												`/workspace/${workSpaceId}/management/users/${(apiKeySelected || dataTable?.[0])?.created_by?.id}?tab=information`,
												'_blank'
											);
										}}
									>
										{(apiKeySelected || dataTable?.[0])?.created_by?.display_name}
									</strong>
								</Trans>
							</p>
                            <p>{t('API_KEY_MESSAGE_4')}</p>
                            <p className="flex items-center space-x-3 px-3 py-2 border border-orange-300 bg-orange-50 rounded-xl">
                                <ExclamationCircleIcon className="h-6 w-6 text-orange-500" />
                                <span>{t('API_KEY_WARNING')}</span>
                            </p>
                        </div>
                    )}
                </>
            )}
            <DialogComponent
                isOpen={modalRegenerateApiKey}
                title={
                    <div className="flex items-center space-x-3">
                        <ExclamationIcon className="h-7 w-7 text-yellow-500" />
                        <p>{t('REGENERATE_API_KEY_CONFIRMATION')}</p>
                    </div>
                }
                onCloseModal={toggleModalRegenerateApiKey}
                maskClosable={false}
                width="!max-w-[652px] px-6"
                child={
                    <div className="mt-1">
                        <div className="">
                            <p className="">{t('REGENERATE_API_KEY_CONFIRMATION_MESSAGE_1')}</p>
                            <p className="mt-5">{t('REGENERATE_API_KEY_CONFIRMATION_MESSAGE_2')}</p>
                        </div>
                        <div className="flex items-center justify-center space-x-4 mt-4">
                            <ButtonOutline
                                type="button"
                                className="buttonOutline--isPrimary"
                                disabled={loading}
                                onClick={toggleModalRegenerateApiKey}
                            >
                                {t('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary
                                type="button"
                                disabled={loadingCreateUpdate}
                                className=""
                                onClick={onRevokeRegenerateApiKey}
                            >
                                {loadingCreateUpdate && <CircleSpin className="mr-1" />}
                                <span>{t('GENERATE_NEW_KEY')}</span>
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            <DialogComponent
                isOpen={modalUpdateApiKey}
                title={t('EDIT_EXPIRATION_DATE')}
                onCloseModal={handleCloseUpdateApiKey}
                maskClosable={false}
                styles="max-w-lg"
                child={
                    <div className="mt-2">
                        <div className="space-y-4 py-2">
                            <div>
                                <LabelForm title={t('KEY_PREFIX')} className="!text-sm" />
                                <p className="text-base font-normal">{apiKeySelected?.prefix}</p>
                            </div>
                            <div>
                                <LabelForm title={t('EXPIRATION_DATE')} className="!text-sm" />
                                <DatePickerField
                                    control={control}
                                    placeholder={t('EXPIRATION_DATE')}
                                    name="expireDate"
                                    errors={errors}
                                    minDate={dayjs().add(1, 'day').toDate()}
                                    isClearable={false}
                                    formatDate="dd/MM/yyyy"
                                    positionIcon="right"
                                    classNameInput="!text-base max-h-10"
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-center space-x-4 mt-4">
                            <ButtonOutline
                                type="button"
                                className="buttonOutline--isPrimary"
                                disabled={loading}
                                onClick={handleCloseUpdateApiKey}
                            >
                                {t('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary type="button" disabled={loadingCreateUpdate || !isDirty} onClick={onUpdateApiKey}>
                                {loadingCreateUpdate && <CircleSpin className="mr-1" />}
                                <span>{t('UPDATE')}</span>
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
        </form>
    );
};

export default ApiKeyIntegration;
