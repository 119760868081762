import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./TeachingOverview.scss";
import instructorsService from "services/instructors.service";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import moment from "moment";

export const TeachingOverview = ({ start = null, end=null, hasById=true, dateRange }) => {
    const params: {id: string, userId: string} = useParams();
    const {t: translator} = useTranslation();
    const [overview, setOverview] = useState<any>();
    
    useEffect(() => {
        const getOverview = async () => {
            let response;
            if (hasById && dateRange?.[0] && dateRange?.[1]) {
                response = await instructorsService.getSummary(params?.id, params?.userId, {
                    from_date: dayjs(dateRange?.[0]).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                    to_date: dayjs(dateRange?.[1]).format("YYYY-MM-DDT23:59:59.000Z"),
                });
            } else if(start && end) {
                response = await instructorsService.getSummary(params?.id, null, {
                    from_date: moment(start).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
                    to_date: moment(end).format("YYYY-MM-DDT23:59:59.000Z"),
                });
            }
            setOverview(response?.data);
        }
        getOverview();
    }, [params, dateRange, start, end]);

    return <div className="TeachingOverview z-10">
        <div className="TeachingOverview-list">
            <div className="TeachingOverview-item">
                <div className="TeachingOverview-label">{translator("SESSIONS_TAUGHT")}</div>
                <div className="TeachingOverview-value">{overview?.total_session == null ? '-' : `${overview?.total_session}`}</div>
            </div>
            <div className="TeachingOverview-item">
                <div className="TeachingOverview-label">{translator('TOTAL_TEACHING_HOURS')}</div>
                <div className="TeachingOverview-value">{overview?.total_time == null ? '-' : `${(overview?.total_time/3600)?.toFixed(2)} hours`}</div>
            </div>
        </div>
    </div>
}