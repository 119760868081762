import SCORMPreview from 'components/ScormComponent';
import * as React from 'react';
// COMPONENTS
export interface UserAccountSettingPageProps {
    setAuthStorage: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScormPreviewPage: React.FC<UserAccountSettingPageProps> = ({
    setAuthStorage,
}) => {
    return <SCORMPreview />;
};
