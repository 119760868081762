import React from 'react';
import { useTranslation } from 'react-i18next';
import { AspectRatioBox } from 'components/AspectRatioBox';
import H5PPublicViewer from 'components/H5P/H5PPublicViewer';
import H5PAssignmentContentListEntryComponent from 'components/H5P/H5PAssignment/H5PAssignmentContentEntryComponent';
import h5pServices from 'services/h5p.service';
import { useParams } from 'react-router-dom';

export const H5PTask = ({ countTasks = 0, task, attemptId = undefined, readOnly = false }) => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string; } = useParams();

    const [entryState, setEntryState] = React.useState({
        editing: false,
        playing: false,
        loading: false,
        saved: false,
        saving: false,
        saveError: false,
        saveErrorMessage: '',
    });

    return (
        <div className={`min-h-[100px]`}>
            {/* <AspectRatioBox>
                <H5PPublicViewer
                    contentUid={task?.h5p_uid}
                    args={{
                        workspaceId: params?.id,
                        courseId: params?.courseId,
                        assignmentId: task?.assignmentId,
                        attemptId,
                        contentId: task?.id,
                    }}
                />
            </AspectRatioBox> */}
            <H5PAssignmentContentListEntryComponent
                argsId={{
                    workspaceId: params?.id,
                    courseId: params?.courseId,
                    assignmentId: task?.assignmentId,
                    attemptId,
                    contentId: task?.id,
                    readOnly,
                    countTasks: countTasks
                }}
                // h5pPlayerPromise={() => h5pServices.h5pPlayerPromise({
                //     workspaceId: params?.id,
                //     courseId: params?.courseId,
                //     assignmentId: params?.assignmentId,
                //     attemptId,
                //     contentId: task?.id,
                // })}
                h5pPlayerPromise={async () => {
                    setEntryState({ ...entryState, loading: true });
                    try {
                        const res = await h5pServices.h5pPlayerPromise({
                            workspaceId: params?.id,
                            courseId: params?.courseId,
                            assignmentId: task?.assignmentId,
                            attemptId,
                            contentId: task?.id,
                        });
                        return res;
                    } catch (error) {
                        if (error?.response?.data?.error?.code == 403 && error?.response?.data?.error?.name == 'LIMIT_EXCEEDED') {
                        }
                    }
                }}
                entryState={entryState}
                setEntryState={setEntryState}
                setFinishContent={() => { }}
            />
        </div>
    );
};
