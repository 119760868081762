import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { timeList } from 'constant/workspace.const';
import { StudentList } from '../components/StudentList/StudentList';
import { DownloadStudent } from '../components/StudentList/DownloadStudent';
import learnerReportService from 'services/reports/learner-report.service';
import { DownloadLearnerGroup } from '../components/StudentList/DownloadLearnerGroup';

const StudentDashboard = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const translator = (str: string, opt?: any) => t(`REPORTS.${str}`, opt);
    const [selectedDate, setSelectDate] = useState(timeList[1]);
    const [students, setStudents] = useState<any>();
    const [loadingStudents, setLoadingStudents] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [searchText, setSearchText] = useState<string>(null);
    const [type, setType] = useState('individual');

    const getStudentsData = async (page = 1) => {
        try {
            setLoadingStudents(true);
            let payload: any = {
                classId: params.classId,
                page,
                q: searchText?.trim(),
                type
            }
            if (!payload.q) {
                delete payload.q;
            }
            let response;
            response = await learnerReportService.getLearner(params.id, payload);

            if (response?.data?.items) {
                setStudents(response?.data);
            }
            setLoadingStudents(false);
        } catch {
            setLoadingStudents(false);
        }
    }

    const handlePagination = (page: number) => {
        getStudentsData(page);
    }

    const handleDownloadCSV = async () => {
        try {
            setIsDownloading(true);
            const response = await learnerReportService.getLearner(params.id, {
                page: students?.page,
                skip_pagination: true,
                type
            });
            if (type === 'individual') {
                DownloadStudent(response?.data);
            } else {
                DownloadLearnerGroup(response?.data);
            }
            setIsDownloading(false);
        } catch {
            setIsDownloading(false);
        }
    }

    useEffect(() => {
        getStudentsData(1);
    }, [selectedDate?.id, searchText, type]);

    return (
        <div className="w-full">
            <PageBreadcrumb
                pageNameDefault={translator('ANALYTICS')}
                pages={[{ name: translator('STUDENT_DASHBOARD'), href: '', className: '' }]}
                containerClassName=" z-10 sticky top-0 w-full"
            />
            <div>
                <div className="flex items-center justify-between p-5 border-b boder-gray-50">
                    <h1 className="text-xl text-gray-800 font-semibold">{translator('Learner Dashboard')}</h1>
                </div>
                <div className='p-5 pb-0 bg-gray-50'>
                    <div className='mt-5'>
                        <StudentList
                            students={students}
                            onSearchStudent={setSearchText}
                            loadingStudents={loadingStudents}
                            handlePaginationStudent={handlePagination}
                            handleDownloadCSV={handleDownloadCSV}
                            isDownloading={isDownloading}
                            type={type}
                            setType={setType}
                            className="h-[calc(100vh-390px)] min-h-[500px] overflow-auto"
                            hasDashboard={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentDashboard;
