import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SearchBox from 'components/V2/SearchBox';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './FilterUser.scss';
import { LOCATION, USER_ROLE, USER_STATUS_LIST } from '../../constant';
import { WorkspaceService } from 'services';
import { useHistory, useParams } from 'react-router-dom';
import { DropdownAsync } from '_shared/components/Form/DropdownAsync/DropdownAsync';
import propertyService from 'services/property.service';
import { PlusIcon } from '@heroicons/react/outline';
import { SkillFilter } from 'components/Skills/SkillFilter';

interface IFilter {
    onChangeFilter: (value: any) => void;
    location: LOCATION.learner_portal | LOCATION.clms;
    setLocation: any
}

const Filter = ({ onChangeFilter, location, setLocation }: IFilter) => {
    const { t } = useTranslation();
    const params: { id } = useParams();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchRole, setInputSearchRole] = useDebouncedState('');
    const [roleSelected, setRoleSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([]);
    const [loadingRole, setLoadingRole] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [rolepagination, setRolePagination] = useState<any>({
        page: 1,
    });
    const [selectedTags, setSelectedTags] = useState<any>([]);
    const [filterByTags, setFilterByTags] = useState<any>();
    const [hasReset, setHasReset] = useState(false);
    const [propertyList, setPropertyList] = useState([]);
    const [skillFilter, setSkillFilter] = useState<any>();
    const [filterReset, setFilterReset] = useState<any>(false);
    const history: {location: {state}} = useHistory();
    const [defaultSkill, setDefaultSkill] = useState<any>([]);

    const { state } = history?.location;
    useEffect(() => {
        if (state) {
            const {skill, location} = state;
            setDefaultSkill(skill);
            setLocation(location);
        }
    }
    , [state]);

    const getRoleList = (page: number = 1) => {
        setLoadingRole(true);
        const request: any = {
            page: page ? page : rolepagination.page,
            per_page: 10,
            name: inputSearchRole,
        };
        if (!request.name) delete request.name;
        if (location === LOCATION.learner_portal) {
            setLoadingRole(false)
            return setRoleList([
                { name: t("USER.LEARNER"), value: 'student' },
                { name: t("USER.PARENT"), value: 'parent' },
            ])
        }
        WorkspaceService.getRoles(params.id, request)
            .then((res) => {
                const formatedData = res?.data?.items?.map((item) => {
                    return {
                        name: USER_ROLE?.[item?.name] || item?.name,
                        id: item?.id,
                        value: item?.name,
                    };
                });
                setRolePagination({ ...rolepagination, total: res?.data?.total, page });
                if (page === 1) setRoleList(formatedData);
                else setRoleList([...roleList, ...formatedData]);
            })
            .finally(() => setLoadingRole(false));
    };

    useEffect(() => {
        if (inputSearchRole?.trim()) getRoleList(1);
    }, [inputSearchRole]);

    useEffect(() => {
        if (roleSelected?.length) setRoleSelected([])
        getRoleList(1);
    }, [location]);

    useEffect(() => {
        setRoleSelected([])
        if (location === LOCATION.learner_portal) {
            propertyService.getFilterMembersProperty(params?.id).then((res) => {
                const result: any[] = [];
                if (res?.data?.length) {
                    res?.data?.map((item) => {
                        result.push({
                            id: item.id,
                            value: item.name,
                            name: item.name,
                            option: item?.property_options
                        });
                    })
                }
                setPropertyList(result)
            })
        }
    }, [location]);

    useEffect(() => {
        let listFilter: any = {
            user_role_name: roleSelected?.map((role) => role?.value),
            q: keyword,
            status: statusSelected?.map(item => item?.value),
            location
        };
        if (skillFilter && location === LOCATION.learner_portal) {
            listFilter = {
                ...listFilter,
                ...skillFilter
            }
        }
        if (location === LOCATION.learner_portal) {
            delete listFilter.user_role_name;
            listFilter.type = roleSelected?.map((role) => role?.value)
        }
        if (!listFilter?.status) delete listFilter.status;
        if (!listFilter.type?.length) delete listFilter.type;
        if (filterByTags?.tags) {
            const listCheckedTags: Record<number, number[]> = {};
            if (filterByTags?.tags) {
                Object.keys(filterByTags?.tags)?.forEach((key) => {
                    if (filterByTags?.tags[key]?.length > 0) {
                        listCheckedTags[Number(key)] = filterByTags?.tags[key]?.map((item) => item?.id);
                    }
                });
            }
            listFilter = {
                ...listFilter,
                extra_filters: JSON.stringify(listCheckedTags)
            }
        }
        onChangeFilter(listFilter);
    }, [roleSelected, keyword, statusSelected, location, filterByTags, skillFilter]);

    const onChangeAddFilter = (values) => {
        setHasReset(false);
        setSelectedTags(values);
        const tags = filterByTags?.tags || {};
        if (Object.keys(tags)?.length < values?.length) {
            const newItem = values[values?.length - 1];
            if (newItem) {
                tags[newItem.id] = [];
            }
        } else {
            Object.keys(tags).map(key => {
                if (values.filter(item => item.value == key).length == 0) {
                    delete tags[key];
                }
            });
        }
        setFilterByTags(prev => ({
            ...prev,
            tags
        }));
    }
    return (
        <>
            <div className="filterBar">
                <SearchBox className="filterBar-searchInput" onSubmit={(value) => setKeyword(value)} />

                <div className="filterBar-roleLocation">
                    <div
                        onClick={() => setLocation(LOCATION.clms)}
                        className={`btn-default ${location === LOCATION.clms ? 'bg-blue-50  text-primary-500' : 'text-gray-500 bg-white'
                            }`}
                    >
                        CLMS
                    </div>
                    <div
                        onClick={() => setLocation(LOCATION.learner_portal)}
                        className={`btn-default ${location === LOCATION.learner_portal ? 'bg-blue-50  text-primary-500' : 'text-gray-500 bg-white'
                            }`}
                    >
                        {t('USER.LEARNER_PORTAL')}
                    </div>
                </div>

                <SelectBox
                    label={location === LOCATION.learner_portal ? t('USER.TYPE') : t('USER.ROLE')}
                    onSearch={setInputSearchRole}
                    onSelect={setRoleSelected}
                    data={roleList}
                    loading={loadingRole}
                    selectedItems={roleSelected}
                    total={location === LOCATION.learner_portal ? 2 : rolepagination?.total}
                    showmore={() => getRoleList(rolepagination?.page + 1)}
                />

                <SelectBox
                    label={t('USER.STATUS')}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={USER_STATUS_LIST(t)}
                    total={USER_STATUS_LIST(t)?.length}
                    isMulti={true}
                />
                {<div className={`${location === LOCATION.learner_portal ? 'inline-block' : 'hidden'}`}>
                    <SkillFilter 
                        onChangeValue={(value) => {
                            setSkillFilter(value);
                            setFilterReset(false);
                        }} 
                        hasReset={filterReset} 
                        defaultSkill={defaultSkill}
                    />
                </div>}
                {location === LOCATION.learner_portal ?
                    <>

                        {selectedTags?.map((item) => (
                            <div key={item.id}>
                                <DropdownAsync
                                    placeholder={item.name}
                                    items={propertyList?.find((i) => i.id === item.id)?.option?.map((opt) => ({
                                        id: opt.id,
                                        value: opt.label,
                                        name: opt.label,
                                    }))}
                                    onChange={(value) => {
                                        setFilterByTags(prev => {
                                            const tags = prev?.tags ?? {};
                                            tags[item.id] = value;
                                            return {
                                                ...prev,
                                                tags
                                            }
                                        })
                                    }}
                                    onRemove={() => {
                                        setSelectedTags(prev => {
                                            return prev.filter(tag => tag.id != item.id);
                                        });
                                        setFilterByTags(prev => {
                                            const tags = prev?.tags ?? {};
                                            delete tags[item.id];
                                            return {
                                                ...prev,
                                                tags
                                            }
                                        });
                                    }}
                                    isRemoved={true}
                                    isAll={true}
                                    otherObject
                                />
                            </div>
                        ))}

                        <DropdownAsync
                            className="dropdownAsync--actived"
                            placeholder={t("USER.ADD_FILTER")}
                            items={propertyList}
                            onChange={onChangeAddFilter}
                            isReset={hasReset}
                            selectedValue={selectedTags}
                            otherObject
                        >
                            <PlusIcon className="dropdownAsync--icon" aria-hidden="true" />
                            <span className='text-primary-500'>{t("USER.ADD_FILTER")}</span>

                        </DropdownAsync> </>
                    : ''
                }


                <div className="line"></div>

                <button
                    onClick={() => {
                        setRoleSelected([]);
                        onChangeFilter({});
                        setStatusSelected([]);
                        setFilterByTags(null);
                        setSelectedTags(null);
                        setLocation(LOCATION.clms);
                        setSkillFilter(null);
                        setFilterReset(true);
                    }}
                    className="btn-reset"
                    disabled={!roleSelected?.length && !statusSelected?.length && !selectedTags?.length && !skillFilter}
                >
                    {t('RESET')}
                </button>
            </div >
        </>
    );
};

export default Filter;
