import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './CoursePeople.scss';
import { checkPermission } from 'utils/commonFun';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';
import { CoursePeopleSubtab } from 'types/Courses.type';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import EnrollmentDetail from './Enrollment/Detail/EnrollmentDetail';
import Learner from './Learner/Learner';
import Instructor from './Instructor/Instructor';
import { LearningGroupPeople } from './LearningGroups';
import TeachingAssistant from './TeachingAssistant/TeachingAssistant';
import Enrollment from './Enrollment/Enrollment';

const ListTab = (translator) => [
    { label: translator('COURSES.LEARNERS'), value: 'learner' },
    { label: translator('COURSES.INSTRUCTORS'), value: 'instructor' },
    { label: translator('LEARNING_GROUPS'), value: 'learning-group' },
    // { label: translator("TEACHING_ASSISTANT"), value: 'teaching-assistant'},
    // { label: translator("ENROLLMENT.ENROLLMENTS"), value: 'enrollment' },
];

const CoursePeople = () => {
    const { t } = useTranslation();
    const { redirectToUrlCourse } = useUrlCourseNavigation();
    const query = useQueryParamsURL();

    const subtab = query.get('subtab');
    const enrollmentId = query.get('enrollmentId');
    const learningGroupId = query.get('learningGroupId');

    useEffect(() => {
        if (!subtab) {
            redirectToUrlCourse({
                tab: 'people',
                subtab: 'learner',
            });
        }
    }, [subtab]);

    if (enrollmentId || learningGroupId) {
        return <EnrollmentDetail />;
    }

    return (
        <div className="CoursePeople">
            <div className="CoursePeople-title">{t('People')}</div>
            <div className={`CoursePeople-tablist  `}>
                {checkPermission('coursePeople', 'R') &&
                    ListTab(t).map((i) => (
                        <div
                            key={i.value}
                            className={`tagItem ${subtab === i?.value ? 'active ' : 'disable'}`}
                            onClick={() =>
                                redirectToUrlCourse({
                                    tab: 'people',
                                    subtab: i?.value as CoursePeopleSubtab,
                                })
                            }
                        >
                            {i?.label}
                        </div>
                    ))}
            </div>
            {checkPermission('coursePeopleEnrollment', 'R') && (
                <>
                    {subtab === 'learner' && <Learner />}
                    {subtab === 'instructor' && <Instructor />}
                    {subtab === 'learning-group' && <LearningGroupPeople />}
                    {/* {subtab === 'enrollment' && <Enrollment />} */}
                    {/* {subtab === 'teaching-assistant' && <TeachingAssistant />} */}
                </>
            )}
        </div>
    );
};

export default CoursePeople;
