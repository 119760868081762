import { useContext, useEffect, useMemo, useState } from "react";
import { TextareaField } from "components/InputForm/InputForm";
import { AsyncSelectField } from "components/Select";
import { formatLesson } from "components/Workspace/Course/CourseBuilder/components/CourseScheduling/Calendar/components/options";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import courseService from "services/course.service";
import { ButtonOutline, ButtonPrimary } from "_shared";
import "./BookingForm.scss";
import { getAvatarUserByName } from "utils/commonFun";
import moment from "moment";
import dayjs from "dayjs";
import { useAppSelector } from "hooks/hooks";
import { UserContext } from "contexts/User/UserContext";
import { ListUser } from "_shared/components/User/User";

export const BookingForm = ({onClose, bookSlot, enrollmentSelected, data = null}) => {
    const {
        userState: { result },
    } = useContext(UserContext);
    const params: { id: string, courseId: string } = useParams();
    const { t: translator } = useTranslation();
    const {sessions} = useAppSelector(state => state.booking);
    const [slotAvaiable, setSlotAvaiable] = useState([]);
    const { control,
        formState: { errors, isValid },
        register,
        getValues,
        handleSubmit,
        setValue,
        watch,
        reset,
        trigger
    } = useForm({
        mode: 'onChange'
    });

    const fetchLessonOfCourse = async (bodyParams?: any) => {
        try {
            const res = await courseService.getLessonOfCourse(params?.id, params.courseId, bodyParams);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleLoadLessonOptions = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            q: inputValue || null,
        };
        try {
            const { items, total } = await fetchLessonOfCourse(payload);
            const newOptions = items?.map((el: any) => ({
                ...el,
                id: el.id,
                label: el?.title,
                value: el.id,
            }));
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onSubmit = (value) => {
        onClose({
            date: bookSlot?.date,
            slot: {
                ...value,
                instructor: bookSlot?.instructor,
                group_id: enrollmentSelected?.learning_group?.id,
                learners: enrollmentSelected?.learners
            },
        });
    }

    const startDate = useMemo(() => {
        return data ? data?.session_time?.start_time : bookSlot?.date;
    }, [data, bookSlot])

    useEffect(() => {
        if (data) {
            reset({
                note: data?.note,
                lesson: data?.lesson,
                session_time: data?.session_time
            })
        }
    }, [data]);

    useEffect(() => {
        let slotAvaiableValues = bookSlot?.slotAvaiable;
        const sessionData = sessions?.map(item => item?.slots).flat();
        if (!data && sessions?.length > 0 && sessionData?.length > 0) {
            slotAvaiableValues = slotAvaiableValues?.filter(item => sessionData?.find(slot => slot?.session_time?.schedule_time_range_id != item?.schedule_time_range_id))
        }
        setSlotAvaiable(slotAvaiableValues);
    }, [sessions, bookSlot])

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <div className="bookingForm">
            <div className="bookingForm-learner">
                <div className="w-1/2">
                    <div className="bookingForm-label !mb-0">
                        {translator('COURSES.LEARNER')}
                    </div>
                    <div className="bookingForm-value">
                        <ListUser id="learner" users={enrollmentSelected?.learners?.map(user => (
                            {
                                ...user,
                                avatar: user?.avatar_url || getAvatarUserByName(user?.name)
                            }
                        ))}
                        className="!text-gray-800 !text-sm !font-normal"
                        />
                        {/* <div className="bookingForm-user">
                            <div className="bookingForm-user-avatar" 
                                style={{backgroundImage: `url(${enrollmentSelected?.learners?.[0]?.avatar_url})`}}>
                                    {!enrollmentSelected?.learners?.[0]?.learners?.avatar_url ? getAvatarUserByName(enrollmentSelected?.learners?.[0]?.learners?.name) : null}
                                </div>
                            <div className="bookingForm-user-name !mb-0 grid">
                                <span className="truncate">{enrollmentSelected?.learners?.[0]?.name}</span>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="w-1/2">
                    <div className="bookingForm-label !mb-0">
                        {translator('INSTRUCTOR')}
                    </div>
                    <div className="bookingForm-value">
                        <div className="bookingForm-user">
                            <div className="bookingForm-user-avatar" style={{backgroundImage: `url(${bookSlot?.instructor?.avatar_url})`}}>
                                    {!bookSlot?.instructor?.avatar_url ? getAvatarUserByName(bookSlot?.instructor?.display_name) : null}</div>
                            <div className="bookingForm-user-name !mb-0 grid">
                                <span className="truncate">{bookSlot?.instructor?.display_name}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bookingForm-date">
                <div className="bookingForm-label mt-[1px]">
                    Date
                </div>
                <div className="bookingForm-value">
                    {dayjs(bookSlot?.date).format('dddd, MMMM DD, YYYY')}
                </div>
            </div>
            <div className="bookingForm-time">
                <div className="bookingForm-label">
                    Session Time <span className="text-red-500">*</span>
                </div>
                <div className="bookingForm-timeList">
                    {slotAvaiable?.map(item => (
                        <button type="button" 
                            key={item.schedule_time_range_id} 
                            className={`bookingForm-timeList-item ${watch('session_time')?.schedule_time_range_id == item?.schedule_time_range_id ? 'actived': ''}`}
                            onClick={() => {
                                setValue('session_time', item);
                                trigger("session_time")

                            }}
                            disabled={(item?.status == 'touched' && item.occupied_slots?.length > 0)  || (moment(startDate).isSame(new Date(), 'day') && +new Date(item?.start_time) < + new Date())}
                        >
                            {`${dayjs(item?.start_time).tz(result?.time_zone).format('HH:mm')} - ${dayjs(dayjs(item?.start_time).add(item?.duration, 'minutes')).tz(result?.time_zone).format('HH:mm')}`}
                        </button>
                    ))}
                </div>
                <input name="session_time" {...register('session_time', {required: true})} hidden />
            </div>
            <div className="bookingForm-lesson">
                <div className="bookingForm-value">
                    <AsyncSelectField
                        control={control}
                        label={translator('COURSES.LESSON')}
                        name="lesson"
                        placeholder={translator('COURSES.SELECT_A_LESSON')}
                        hideSelectedOptions
                        closeMenuOnSelect={true}
                        errors={errors}
                        loadOptions={handleLoadLessonOptions}
                        formatOptionLabelCustom={formatLesson}
                        isRequired={true}
                    />
                </div>
            </div>
            <div className="bookingForm-lesson">
                <div className="bookingForm-label">
                    {translator('DESCRIPTION')}
                </div>
                <div className="bookingForm-value">
                    <TextareaField
                        label={''}
                        name="note"
                        className="col-span-4"
                        placeholder={translator('ENTER') + translator('DESCRIPTION')}
                        register={register('note')}
                        textLength={getValues("note")?.length?.toString()}
                        maxLength={250}
                        isCountter
                    />
                </div>
            </div>
            <div className="bookingForm-action">
                <ButtonOutline type="button" size="M" onClick={() => onClose()}>Cancel</ButtonOutline>
                <ButtonPrimary type="submit" size="M" onClick={() => { }} disabled={!isValid}>
                    {data ? 'Update' : 'Book'}
                </ButtonPrimary>
            </div>
        </div>
    </form>)
}