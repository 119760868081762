import React, { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import './header-skill.scss';
import { useTranslation } from 'react-i18next';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { TrashIcon } from '@heroicons/react/outline';
import { TooltipCustom } from 'components/Tooltip/Tooltip';
import skillService from 'services/skill.service';

type HeaderSkillProps = {
    skillDetail: any;
    loading: boolean;
    isUpdate: boolean;
    isDisableCreate: boolean;
    isDisableUpdate: boolean;
    loadingDelete: boolean;
    onCancel: () => void;
    onDelete: () => void;
    onRefresh: () => void;
};

export const HeaderSkill: FC<HeaderSkillProps> = ({
    skillDetail,
    loading,
    isUpdate,
    isDisableCreate,
    loadingDelete,
    isDisableUpdate,
    onCancel,
    onDelete,
    onRefresh,
}) => {
    const { t } = useTranslation();
    const params: { id: string; skillId: string } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    const workspaceId = params?.id;
    const skillId = params?.skillId;

    const onPublicUnpublishSkill = () => {
        const status = skillDetail?.status === 'draft' ? 'published' : 'draft';

        setIsLoading(true);
        skillService
            .updateSkillByIdService(workspaceId, skillId, { status })
            .then((res) => {
                Toastify.success();
                onRefresh();
            })
            .catch(() => Toastify.error())
            .finally(() => setIsLoading(false));
    };

    const isDisableDelete = useMemo(() => skillDetail?.associatedCourses?.length > 0, [skillDetail]);

    return (
        <div className="header_skill flex justify-between items-center space-x-2">
            <div className="flex justify-between items-center space-x-2 truncate">
                {skillId === 'new' ? (
                    <h1 className="text-xl font-semibold">{t('Create a New Skill')}</h1>
                ) : (
                    <>
                        <h1 className="text-xl font-semibold truncate">{skillDetail?.name}</h1>
                        {skillDetail?.status && <p className={`status status-${skillDetail?.status}`}>{skillDetail?.status}</p>}
                    </>
                )}
            </div>
            <div className="flex-none flex items-center space-x-2">
                {(() => {
                    if (skillId === 'new') {
                        return (
                            <>
                                <ButtonOutline
                                    type="button"
                                    className="buttonOutline--isPrimary"
                                    disabled={loading}
                                    onClick={onCancel}
                                >
                                    {t('CANCEL')}
                                </ButtonOutline>
                                <ButtonPrimary type="submit" disabled={loading || isDisableCreate} className="">
                                    {(loading) && <CircleSpin className="mr-1" />}
                                    <span>{t('CREATE')}</span>
                                </ButtonPrimary>
                            </>
                        );
                    }

                    let elementPublic = null;
                    let elementUnpublish = null;
                    let elementUpdate = null;
                    const elementDelete = (
                        <>
                            <ButtonOutline
                                type="button"
                                className="!border-red-500 !text-red-500 disabled:!border-gray-400 disabled:!text-gray-400"
                                disabled={loadingDelete || isDisableDelete}
                                onClick={onDelete}
                                data-for="TOOLTIPS_SKILL_CANNOT_DELETE"
                                data-tip={t('TOOLTIPS_SKILL_CANNOT_DELETE')}
                            >
                                <TrashIcon className={`w-5 h-5 ${(loadingDelete || isDisableDelete) ? 'text-gray-400' : 'text-red-500'}`} />
                                <span>{t('DELETE')}</span>
                            </ButtonOutline>
                            {isDisableDelete && (
                                <TooltipCustom
                                    classNameContainer="shadow-medium"
                                    id="TOOLTIPS_SKILL_CANNOT_DELETE"
                                    content={t('TOOLTIPS_SKILL_CANNOT_DELETE')}
                                    place="left"
                                    type="light"
                                />
                            )}
                        </>
                    );

                    if (isUpdate) {
                        elementUpdate = (
                            <ButtonPrimary type="submit" disabled={loading || isDisableUpdate} className="">
                                {loading && <CircleSpin className="mr-1" />}
                                <span>{t('SAVE_CHANGES')}</span>
                            </ButtonPrimary>
                        );
                    } else {
                        if (skillDetail?.status === 'published') {
                            elementUnpublish = (
                                <ButtonOutline
                                    type="button"
                                    disabled={isLoading}
                                    onClick={onPublicUnpublishSkill}
                                    className="buttonOutline--isPrimary"
                                >
                                    {(isLoading) && <CircleSpin className="mr-1" />}
                                    <span>{t('UNPUBLISH')}</span>
                                </ButtonOutline>
                            );
                        } else {
                            elementPublic = (
                                <ButtonPrimary
                                    type="button"
                                    disabled={isLoading}
                                    onClick={onPublicUnpublishSkill}
                                    className=""
                                >
                                    {(isLoading) && <CircleSpin className="mr-1" />}
                                    <span>{t('PUBLISH')}</span>
                                </ButtonPrimary>
                            );
                        }
                    }

                    return (
                        <>
                            {elementDelete}
                            {elementPublic}
                            {elementUnpublish}
                            {elementUpdate}
                        </>
                    );
                })()}
            </div>
        </div>
    );
};
