import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/outline';
import { NextIcon, PreviousIcon } from 'assets/icon';
import { Popover } from '@headlessui/react';
import { ListIcon, ZoomOutIcon, ZoomInIcon } from 'assets/icon';
import { useTranslation } from 'react-i18next';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import { getDetail } from 'components/Library/LessonBuilder/helpers';
import { VIEW_TYPE } from 'components/Library/LessonBuilder/constants';

import ModalShareLink from 'components/V2/Modals/ModalShareLink';
import Spinner from 'components/Spinner';
import PencilIcon from 'assets/icon/PencilIcon';
import CopyLinkIcon from 'assets/icon/CopyLinkIcon';
import PreviewContentCommon from '_shared/PreviewContent/PreviewContentCommon';
import assignmentService from '../../../services/assignment.service';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './AssignmentPreview.scss';
import ReactTooltip from "react-tooltip";
import { checkPermission } from 'utils/commonFun';

let intervalRemoveIconZoom;

const AssignmentPreview: React.FC = () => {
    const params: { id: string; assignmentId: string } = useParams();
    const [index, setIndex] = useState(0);
    const [page, setPage] = useState(0);
    const [sectionIndex, setSectionIndex] = useState(0);
    const [showLoading, setShowLoading] = useState(true);
    const [currentData, setCurrentData] = useState<any>();
    const [contentSelected, setContentSelected] = useState<any>();
    const [assignment, setAssignment] = useState<any>();

    const [isZoom, setZoom] = useState(false);
    const [openModalShareLink, setOpenModalShareLink] = useState(false);

    const data = useMemo(() => {
        let idx: number = 0;
        const data = assignment?.sections?.map((item, sectionIndex: number) => ({
            ...item,
            index: sectionIndex,
            sectionIndex,
            idxTotal: idx++,
            firstFile: index === 0 ? sectionIndex : -1,
            object_id: `${item?.id}-${sectionIndex}-${index}`,
        })) || [];
        return data;
    }, [assignment, params?.assignmentId]);

    const sections = useMemo(
        () =>
            assignment?.sections?.map((item) => ({
                title: item?.library_content?.name,
                files: [item],
            })) || [],
        [assignment, page]
    );

    useEffect(() => {
        if (params?.assignmentId) {
            try {
                const getAssignment = async () => {
                    const response = await assignmentService.getAssignment({
                        workspaceId: params?.id,
                        assignmentId: params.assignmentId
                    });
                    if (response?.data) {
                        setAssignment(response?.data);
                    }
                }
                getAssignment();
            } catch { }
        }
    }, [params?.assignmentId]);

    useEffect(() => {
        if (data) {
            setCurrentData(data?.[0]);
        }
    }, [data]);

    const beforeChange = (next: number) => {
        if (sectionIndex !== data[next]?.sectionIndex) {
            setSectionIndex(data[next]?.sectionIndex);
        }
    };

    const getIndexFile = (id) => data?.findIndex((item) => item?.object_id === id);

    const nextSlide = () => {
        setContentSelected(null);
        setTimeout(() => {
            beforeChange(index + 1);
            setCurrentData(data[index + 1]);
            setIndex(index + 1);
        })
    };

    const preSlide = () => {
        beforeChange(index - 1);
        setContentSelected(null);
        setCurrentData(data[index - 1]);
        setIndex(index - 1);
    };

    const history = useHistory();

    useEffect(() => {
        if (currentData?.library_content?.object_type != 'h5p') return;
        setShowLoading(true);
        intervalRemoveIconZoom = setInterval(() => {
            const iframe = document?.getElementsByClassName('h5p-iframe')[0];
            if (iframe) {
                //@ts-ignore
                const zoom = iframe?.contentWindow?.document?.getElementsByClassName('h5p-footer-toggle-full-screen')?.[0];
                if (zoom) {
                    zoom.style.visibility = 'hidden';
                }
                setShowLoading(false);
                clearInterval(intervalRemoveIconZoom);
            }
            if (!data?.length || data[index].library_content?.object_type !== 'h5p') {
                clearInterval(intervalRemoveIconZoom);
                setShowLoading(false);
            };
        }, 1000);
    }, [isZoom, index, currentData, contentSelected]);

    useEffect(() => {
        const keydown = (event) => {
            if (event.code === 'ArrowLeft' && index !== 0) preSlide();
            if (event.code === 'ArrowRight' && index !== data?.length - 1) nextSlide();
        };
        window.addEventListener('keydown', keydown);
        return () => {
            window?.removeEventListener('keydown', keydown);
        };
    }, [index]);

    const openFullScreen = () => {
        const elem = document.getElementById('presentation-mode-lesson');
        if (elem) {
            elem.requestFullscreen();
            //@ts-ignore
        } else if (elem.webkitRequestFullscreen) {
            /* Safari */
            //@ts-ignore
            elem.webkitRequestFullscreen();
            //@ts-ignore
        } else if (elem.msRequestFullscreen) {
            /* IE11 */
            //@ts-ignore
            elem.msRequestFullscreen();
        }
    };

    /* Close fullscreen */
    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            //@ts-ignore
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            //@ts-ignore
            document.webkitExitFullscreen();
            //@ts-ignore
        } else if (document.msExitFullscreen) {
            /* IE11 */
            //@ts-ignore
            document.msExitFullscreen();
        }
    };

    const { t } = useTranslation();

    useEffect(() => {
        if (currentData) {
            if (currentData?.library_content?.object_type === 'submission') {
                setContentSelected(currentData);
                return;
            };
            getDetail(params?.id, currentData, setContentSelected, currentData?.library_content?.object_type != 'h5p' && setShowLoading);
        }
    }, [currentData]);

    const onGoback = () => {
        const folderId = assignment?.path?.slice(-1)?.[0]?.id;
        if (folderId) {
            history.push(`/workspace/${params?.id}/library/${folderId}`)
        } else {
            history.push(`/workspace/${params?.id}/library?tab=library`)
        }
    }

    return (
        <div
            id="presentation-mode-lesson"
            className="presentation-mode-assignment"
        >
            <div className={`header ${isZoom ? 'group' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} header-container group-hover:flex`}>
                    <div className="header-icon" onClick={onGoback}>
                        <div className="background-icon">
                            <ChevronLeftIcon className="icon" />
                        </div>
                        {t('DASHBOARD.SIDEBAR.LIBRARY')}
                    </div>
                    <div className="header-title">{assignment?.title}</div>
                    <div className="header-group-icon">
                        {checkPermission("libraryContentAssignment", 'U') && <div className="sub-icon" onClick={() => history.push(`/workspace/${params.id}/assignment/${params.assignmentId}`)}>
                            <PencilIcon />
                        </div>}

                        {false && <div
                            className="sub-icon"
                            onClick={() => {
                                setOpenModalShareLink(true);
                                closeFullscreen();
                                setZoom(false);
                            }}
                        >
                            <CopyLinkIcon />
                        </div>}
                    </div>
                </div>
            </div>
            <div className={`main-content`}>
                <div key={`iframe-${isZoom}`} className={`file-container ${isZoom ? 'zoom' : ''} `}>
                    {showLoading && (
                        <div className="file-container-loading">
                            <Spinner />
                        </div>
                    )}
                    {!showLoading && !data?.length && (
                        <div className="flex items-center">
                            <NoContentWithImage />
                        </div>
                    )}
                    {contentSelected?.id && currentData?.id ?
                        <PreviewContentCommon isNewPage={true}
                            key={`previewContent-${params?.assignmentId}`}
                            contentSelected={contentSelected}
                            viewType={VIEW_TYPE.WEB}
                            colorProcess="text-gray-800"
                        /> : null}
                </div>
            </div>

            {/* @ts-ignore */}
            <div className={`navigation ${isZoom ? 'group z-100' : ''}`}>
                <div className={`${isZoom ? 'hidden' : 'flex'} navigation-container group-hover:flex`}>
                    <Popover className="relative">
                        <Popover.Button
                            data-for="file-selected-name"
                            data-tip={currentData?.library_content?.name}
                            className="navigation-dropdown"
                        >
                            <ListIcon />
                            <div className="navigation-dropdown-button">
                                <div
                                    className="file-name"
                                >
                                    {currentData?.library_content?.name}
                                </div>{' '}
                                {/*@ts-ignore*/
                                    <ReactTooltip
                                        place="top"
                                        type="dark"
                                        effect="float"
                                        id="file-selected-name"
                                        getContent={(value) => <div>{value}</div>}
                                    />}
                                <ChevronDownIcon className="icon" />
                            </div>
                        </Popover.Button>
                        <Popover.Panel className="navigation-panel">
                            {sections?.map((section, index) => {
                                return (
                                    <div key={index}>
                                        {section?.files?.map((file: any, indexFile) => {
                                            return (
                                                <div key={`${file?.uid}`} className='w-full'>
                                                    <div
                                                        key={`${file?.uid}`}
                                                        onClick={() => {
                                                            setContentSelected(null);
                                                            setCurrentData({ ...file, object_id: `${file?.id}-${index}-${indexFile}` });
                                                            setIndex(getIndexFile(`${file?.id}-${index}-${indexFile}`));
                                                        }}
                                                        className={`assignment-item ${indexFile !== 0 ? 'm-0' : ''} ${currentData?.object_id === `${file?.id}-${index}-${indexFile}` ? 'active' : ''
                                                            } `}
                                                        data-for={`file-name-${file?.id}`}
                                                        data-tip={file?.library_content?.name}
                                                    >
                                                        {file?.library_content?.name}
                                                        {/*@ts-ignore*/
                                                            <ReactTooltip
                                                                place="top"
                                                                type="dark"
                                                                effect="float"
                                                                id={`file-name-${file?.id}`}
                                                                getContent={(value) => <div>{value}</div>}
                                                            />}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </Popover.Panel>
                    </Popover>
                    <div className="navigation-button">
                        <div className={`${index > 0 ? 'active' : ''}`} onClick={preSlide}>
                            <PreviousIcon />
                        </div>
                        <div className="line"></div>
                        <div id="counter-slide" className="index-page">
                            {data?.length > 0 ? index + 1 : 0}
                        </div>
                        /
                        <div id="total-slide" className="total-slide">
                            {data?.length}
                        </div>
                        <div className="line"></div>
                        <div className={` ${index < data?.length - 1 ? 'active' : ''}`} onClick={nextSlide}>
                            <NextIcon />
                        </div>
                    </div>
                    <div
                        id="zoom-presentation"
                        onClick={() => {
                            isZoom ? closeFullscreen() : openFullScreen();
                            setZoom(!isZoom);
                        }}
                        className="button-zoom"
                    >
                        {isZoom ? <ZoomOutIcon /> : <ZoomInIcon />}
                    </div>
                </div>
            </div>
            {openModalShareLink && (
                <div className="w-full h-full">
                    <ModalShareLink
                        isOpen={openModalShareLink}
                        onClose={() => setOpenModalShareLink(false)}
                        value={{
                            object_type: 'lesson',
                            workspaceId: params.id,
                            object_id: params.assignmentId,
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default AssignmentPreview;
