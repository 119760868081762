import httpClient, { RestfulService } from './restful.service';
import { HOST_URL } from 'constant/api.const';

const updateLesson = async (workspaceId, lessonId, body) => {
    if (lessonId) {
        const res = await RestfulService.patchApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}`,
            {
                ...body,
            });
        return res.data;
    }
};

const createSection = async (workspaceId, lessonId, body) => {
    if (lessonId) {
        const res = await RestfulService.postApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections`,
            {
                ...body,
            });
        return res.data;
    }
};

const addContentToSection = async (workspaceId, lessonId, sectionId, body) => {
    if (lessonId) {
        const res = await RestfulService.postApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections/${sectionId}/contents`,
            {
                ...body,
            });
        return res.data;
    }
};

const renameSection = async (workspaceId, lessonId, sectionId, body) => {
    if (lessonId) {
        const res = await RestfulService.patchApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections/${sectionId}`,
            {
                ...body,
            });
        return res.data;
    }
};

const deleteSection = async (workspaceId, lessonId, sectionId) => {
    if (lessonId) {
        const res = await RestfulService.deleteApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections/${sectionId}`);
        return res.data;
    }
};

const deleteContent = async (workspaceId, lessonId, sectionId, contentId ) => {
    if (lessonId) {
        const res = await RestfulService.deleteApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections/${sectionId}/contents/${contentId}`);
        return res.data;
    }
};

const sortSection = async (workspaceId, lessonId, body) => {
    if (lessonId) {
        const res = await RestfulService.patchApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections/sort`,
            {
                ...body,
            });
        return res.data;
    }
}

const sortContent = async (workspaceId, lessonId, sectionId, body) => {
    if (lessonId) {
        const res = await RestfulService.patchApi(HOST_URL +
            `/workspaces/${workspaceId}/lessons/${lessonId}/sections/${sectionId}/contents/sort`,
            {
                ...body,
            });
        return res.data;
    }
}

const UpdateNote = async (params) => {
    if (params?.lessonId) {
        const res = await RestfulService.patchApi(HOST_URL +
            `/workspaces/${params?.workspaceId}/lessons/${params?.lessonId}/sections/${params?.sectionId}/contents/${params?.contentId}/note`,
            {
                note: params.note
            });
        return res.data;
    }
}

const playLesson = (params) => {
    return RestfulService.getApi(HOST_URL +`/lesson/${params?.uid}/play`);
}

const playAssignment = (uid) => {
    return RestfulService.getApi(HOST_URL +`/exercise/${uid}/play`);
}

const createSCORM = (id: string, params: { file_key: string, parent_id?: number }) => {
    return httpClient.post(`/workspaces/${id}/scorm-contents`, params);
}

const getSCORM = (id: string, scorm_id: string | number) => {
    return httpClient.get(`/workspaces/${id}/scorm-contents/${scorm_id}`);
}

const getSCOsContentSCORM = (id: string, scorm_id: string | number) => {
    return httpClient.get(`/workspaces/${id}/scorm-contents/${scorm_id}/scos`);
}

const launchSCOsContentSCORM = (id: string, scorm_id: string | number, scorm_scos_id: string) => {
    return httpClient.get(`/workspaces/${id}/scorm-contents/${scorm_id}/scos/${scorm_scos_id}/launch`);
}

const trackSCOsContentSCORM = (id: string, scorm_id: string | number, scorm_scos_id: string) => {
    return httpClient.post(`/workspaces/${id}/scorm-contents/${scorm_id}/scos/${scorm_scos_id}/track`);
}

export default {
    updateLesson,
    createSection,
    deleteSection,
    renameSection,
    addContentToSection,
    deleteContent,
    sortSection,
    sortContent,
    UpdateNote,
    playLesson,
    playAssignment,
    createSCORM,
    getSCORM,
    getSCOsContentSCORM,
    launchSCOsContentSCORM,
    trackSCOsContentSCORM,
};
