import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebouncedState } from 'hooks/useDebounce';
import SearchBox from 'components/V2/SearchBox';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useTranslation } from 'react-i18next';
import sampleCourseProperty from 'assets/img/example-course-property.png';
import sampleLearnerProperty from 'assets/img/example-learner-property.png';
import propertyService from 'services/property.service';
import { getIndexPage } from 'utils/commonFun';
import { flattenData } from '../propertyCommon';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import Pagination from 'components/V2/Pagination';
import { TableShowHideProperty } from './TableShowHideProperty';

export const ShowHideCourseLeanerProperty = ({ tab, subtab }) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const [keyword, setKeyword] = useDebouncedState('');

    const [hasUpdate, setHasUpdate] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [propertyList, setPropertyList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'created_at',
        total: 0,
    });

    const workspaceId = params.id;

    const translator = (str: string, opt?: any) => t(`DASHBOARD.WORKSPACE_SETTING.CUSTOM_PROPERTY.${str}`, opt);

    const fetchPropertyList = (page = 1) => {
        setLoading(true);
        const payload = {
            ...(keyword ? { name: keyword } : {}),
            page: page || pagination.page,
            per_page: pagination.per_page,
            order: pagination.order,
            sort_by: pagination.sort_by,
            object_type: subtab,
        };

        propertyService
            .get(workspaceId, payload)
            .then((res) => {
                const { items, page, per_page, order, total } = res.data;
                setPropertyList(flattenData(items));
                setPagination({ ...pagination, page, per_page, order, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const updateProperties = () => {
        setLoadingUpdate(true);

        const listHideProperty = [];
        const listShowProperty = [];

        for (const item of propertyList) {
            if (item?.is_visible) listShowProperty.push(item?.id);
            else listHideProperty.push(item?.id);
        }

        const payloads = {
            list_hide_property_id: listHideProperty,
            list_show_property_id: listShowProperty,
        };

        propertyService
            .visibility(workspaceId, payloads)
            .then((res) => {
                Toastify.success();
                fetchPropertyList();
                setHasUpdate(false);
            })
            .catch(() => Toastify.error())
            .finally(() => setLoadingUpdate(false));
    };

    const handleUpdateVisibility = (id, isVisible) => {
        !hasUpdate && setHasUpdate(true);
        setPropertyList((prevItems) => {
            const updatedItems = [...prevItems];

            const updateChildrenVisibility = (currentId, visibility) => {
                for (let item of updatedItems) {
                    if (item?.id === currentId) {
                        item.is_visible = visibility;
                        if (!!item?.parent) item.parent.is_visible = propertyList.find(el => el?.id === item?.parent?.id)?.is_visible || false;
                        if (item?.children?.length > 0) {
                            item.children.forEach((child) => updateChildrenVisibility(child?.id, visibility));
                        }
                    }
                }
            };

            updateChildrenVisibility(id, isVisible);

            return updatedItems;
        });
    };

    useEffect(() => {
        fetchPropertyList();
    }, []);

    return (
        <div className="show-hide-property select-none">
            <div className="px-5 py-4 flex justify-between items-center border-b border-gray-300">
                {/* <SearchBox className="w-full max-w-xs" placeholder={t('SEARCH')} onSubmit={(e: any) => setKeyword(e)} /> */}
                <h1 className='text-base font-semibold text-gray-800'>{translator('SHOW_HIDE_PROPERTY')}</h1>
                <div className="flex items-center space-x-2">
                    <ButtonOutline
                        type="button"
                        disabled={loading}
                        onClick={() => history.push(`/workspace/${workspaceId}/setting?tab=${tab}&subtab=${subtab}`)}
                        className="!text-enterprise !border-enterprise"
                    >
                        {t('CANCEL')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" disabled={loadingUpdate || !hasUpdate} onClick={updateProperties} className="">
                        {t('SAVE')}
                    </ButtonPrimary>
                </div>
            </div>
            <div className="p-5 h-[calc(100vh-265px)]">
                <div className="flex rounded-lg border border-gray-300 h-full">
                    <div className="w-1/2">
                        <div className="h-[calc(100vh-356px)] overflow-y-auto">
                            {loading ? (
                                <div className="flex items-center justify-center mt-4">
                                    <CircleSpin color="text-primary-500" className="m-0" />
                                </div>
                            ) : (
                                <TableShowHideProperty
                                    data={propertyList}
                                    tab={tab}
                                    subtab={subtab}
                                    translator={translator}
                                    workspaceId={workspaceId}
                                    handleUpdateVisibility={handleUpdateVisibility}
                                />
                            )}
                        </div>
                        {!!propertyList?.length && (
                            <Pagination
                                callback={(page) => fetchPropertyList(page)}
                                total={pagination?.total}
                                page={pagination?.page}
                                per_page={pagination?.per_page}
                                indexPage={indexPage}
                                className="pl-5"
                            />
                        )}
                    </div>
                    <div
                        className="w-1/2 h-full rounded-r-lg"
                        style={{
                            backgroundImage: `url(${subtab === 'course' ? sampleCourseProperty : sampleLearnerProperty})`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};
