import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import scormService from 'services/scorm.service';
import SCORMPreviewComponent from './SCORMPreviewComponent';
import "./index.scss";


const SCORMPreview = () => {
    const paramsURL: { id: string, scormId: string } = useParams();
    const history = useHistory();
    const { t: translate } = useTranslation();
    const [scos, setScos] = useState<any>();

    const getScormContent = async () => {
        try {
            const res = await scormService.getScormContent(paramsURL?.id, { scorm_content_id: paramsURL?.scormId });
            setScos(res);

        } catch (error) {

        }
    }

    const onGoback = () => {
        history.push(`/workspace/${paramsURL?.id}/library?tab=library`);
    }

    useEffect(() => {
        getScormContent();
    }, []);

    return <>
        <div className='scormHeader-container'>
            <div className="scormHeader-icon" onClick={() => onGoback()}>
                <div className="background-icon">
                    <ChevronLeftIcon className="icon" />
                </div>
                {translate('DASHBOARD.SIDEBAR.LIBRARY')}
            </div>
            <div className="scormHeader-title">
                {scos?.title}
            </div>
            <div></div>
        </div>
        {
            <div className='py-[47px]'>
                <div className='relative flex gap-4 w-full max-w-[1200px] mx-auto border border-gray-200 rounded-2xl'>
                    <SCORMPreviewComponent values={scos} />
                </div>
            </div>
        }
    </>

};

export default SCORMPreview;
