import moment from "moment";
import { PlatFormConst } from "types/ActivityDashboard.type";
import { downloadCSV } from "../components/common";

export const DownloadActivity = (data) => {
    let headers: string[], rows: string[][];
    let file_name = `User Activity List`;
    headers = [
        'No.',
        'User ID',
        'User Name',
        'Platform',
        'User Role',
        'Last Activity'
    ];
    rows = data?.map((el: any, index) => [
        index + 1,
        el?.id || 'N/A',
        el?.display_name || '-',
        el?.membership?.locations?.length > 0 ? PlatFormConst[el?.membership?.locations?.[0]] :  '-',
        el?.membership?.user_role ? el?.membership?.user_role?.name : el?.membership?.type == 'student' ? 'Learner' : 'Parent',
        el?.last_activity_at ? moment(el?.last_activity_at).format('MM/DD/YYYY') : '-',

    ]);
    downloadCSV([headers, ...rows], file_name);
}
