import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { SelectField } from 'components/Select';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './ModalNewSession.scss';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import courseService from 'services/course.service';
import dayjs from 'dayjs';
import DialogComponent from 'components/Dialog/DialogComponent';
import TimePickerInput from 'components/Management/components/Form/TimePicker';
import { ErrorMessage } from '@hookform/error-message';
import { FORM_CONST } from 'constant/form.const';
import AddSlot from './AddSlot/AddSlot';
import { UserContext } from 'contexts/User/UserContext';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import { useAppSelector } from 'hooks/hooks';

const PER_PAGE = 20;

export default function ModalNewSession({ isOpen, onClose, refreshData }) {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        setValue,
        watch,
        register,
        reset,
        getValues
    } = useForm({ mode: 'onChange' });
    const { t } = useTranslation();
    const { userState: { result } } = useContext(UserContext)

    const { fields, update, replace } = useFieldArray({
        control,
        name: 'time_range',
    });

    const { setting } = useAppSelector((state) => state.course);

    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('single');
    const [paginationLesson, setPaginationLesson] = useState({ page: 1, total: 0 })
    const [lessons, setLessions] = useState([])
    const [inputSearchLesson, setInputSearchLesson] = useDebouncedState('', 0);
    const [loadingLesson, setLoadingLesson] = useState(false)

    const [paginationInstructor, setPaginationInstructor] = useState({ page: 1, total: 0 })
    const [instructorsList, setLInstructorsList] = useState([])
    const [inputSearchInstructor, setInputSearchInstructor] = useDebouncedState('', 0)
    const [loadingInstructor, setLoadingInstructor] = useState(false)


    const [paginationEnrollment, setPaginationEnrollment] = useState({ page: 1, total: 0 })
    const [enrollments, setEnrollments] = useState([])
    const [inputSearchEnrollment, setInputSearchEnrollment] = useDebouncedState('', 0)
    const [loadingEnrollment, setLoadingEnrollment] = useState(false)

    const [learnerOfLearningGroup, setLearnerOfLearningGroup] = useState([])
    const [inputSearchLearnerOfLearningGroup, setInputSearchLearnerOfLearningGroup] = useDebouncedState('', 0)

    const params: any = useParams();
    const workspaceId = params.id;
    const courseId = params.courseId;

    const getEnrollmentOfCourse = (page: number = 1) => {
        setLoadingEnrollment(true)
        const param = {
            page: page,
            per_page: 20,
            q: inputSearchEnrollment,
            // enrollment_as: watch('type')?.value,
            statuses: JSON.stringify(["active", "enrolled"]),
        }
        if (!param.q) delete param.q
        courseService.getEnrollments(params.id, courseId, param)
            .then(res => {
                const listFormated = res?.data?.items?.map((item) => {
                    if (watch('type')?.value === 'individual') {
                        const learner = item.learner;
                        return {
                            credit_balance: learner?.credit_balance,
                            id: learner?.id,
                            name: learner?.display_name,
                            avatar_url: learner?.avatar_url,
                            value: learner.id,
                            disabled:  setting?.enrollment_type === 'credit' && (!learner?.credit_balance?.available_credit || learner?.credit_balance?.available_credit === 0),
                        }
                    }
                    return {
                        ...item,
                        id: item.id,
                        name: item?.learning_group?.name,
                        value: item?.learning_group?.id,
                        avatar_url: item?.learning_group?.avatar_url,
                        learners: item?.learners?.map(learner => {
                            return {
                                credit_balance: learner?.credit_balance,
                                id: learner?.id,
                                name: learner?.display_name,
                                avatar_url: learner?.avatar_url,
                                value: learner.id,
                                disabled: setting?.enrollment_type === 'credit' && (!learner?.credit_balance?.available_credit || learner?.credit_balance?.available_credit === 0),
                            }
                        })
                    }

                })
                if (page === 1) setEnrollments(listFormated)
                if (page > 1) {
                    setEnrollments([...enrollments, ...listFormated])
                }
                setPaginationEnrollment({ total: res?.data?.total, page: res?.data?.page })
            })
            .finally(() => setLoadingEnrollment(false))
    }

    const getInstructors = (page: number = 1) => {
        setLoadingInstructor(true)
        const param = {
            page: page,
            per_page: 20,
            q: inputSearchInstructor,
            status: 'active',
        }
        courseService.getInstructorEnrolled(params.id, courseId, param)
        .then(res => {
            const listFormated = res?.data?.items?.map((item) => {
                return {
                    ...item,
                    id: item?.instructor?.id,
                    name: item.instructor?.display_name,
                    value: item?.instructor?.id,
                    avatar_url: item?.instructor?.avatar_url
                }
            })
            if (page === 1) setLInstructorsList(listFormated)
            if (page > 1) {
                setLInstructorsList([...instructorsList, ...listFormated])
            }
            setPaginationInstructor({total: res?.data?.total, page: res?.data?.page})
        })
        .finally(() => setLoadingInstructor(false))
    }

    const getLessons = (page: number = 1) => {
        setLoadingLesson(true)
        const param = {
            page: page,
            per_page: PER_PAGE,
            q: inputSearchLesson
        }
        courseService.getLessonOfCourse(params.id, params.courseId, param)
        .then(res => {
            const listFormated = res?.data?.items?.map((item) => {
                return {
                    ...item,
                    id: item?.id,
                    name: item?.title,
                    value: item?.id,
                }
            })
            if (page === 1) setLessions(listFormated)
            if (page > 1) {
                setLessions([...lessons, ...listFormated])
            }
            setPaginationLesson({total: res?.data?.total, page: res?.data?.page})
        })
        .finally(() => setLoadingLesson(false))
    }


    const onSubmit = (value) => {
        
        const session = {
            learners: value?.learners?.map((learner) => learner?.value),
            instructors: value?.instructors?.map((instructor) => instructor?.value),
            learning_group: value?.learning_group?.[0]?.value,
            start_time: dayjs(value?.date).hour(dayjs(value?.start_time).hour()).minute(dayjs(value?.start_time)?.minute())?.format(),
            end_time: dayjs(value?.date)?.hour(dayjs(value?.end_time).hour()).minute(dayjs(value?.end_time)?.minute())?.format(),
            location: value?.location,
            vc_link: value?.vc_link,
            extra_link: value?.extra_link,
            // record_link: value?.record_link,
            instructor_note: value?.instructor_note,
            lesson_id: value?.lesson?.[0]?.value,
        };
        if (value.type?.value === 'individual') delete session.learning_group;
        if (type === 'single') {
            setLoading(true);
            courseService
                .createSingleSession(workspaceId, params?.courseId, session)
                .then((res) => {
                    if (res.data?.error) {
                        return Toastify.error(res.data?.error?.description);
                    }
                    Toastify.success(t("SESSION.CREATE_SESSION_SUCCESS"));
                    onClose();
                    reset({});
                    setType('single');
                    refreshData();
                })
                .catch(() => Toastify.error())
                .finally(() => setLoading(false));
        }

        if (type === 'fixed') {
            delete session.start_time;
            delete session.end_time
            console.log('value?.time_range: ', value?.time_range);
            const paramFixedSession = {
                repeating_cycle: value?.repeat_type?.value,
                time_slots: value?.time_range?.filter(time => time?.value)?.map(time => {
                    return {
                        day: time?.weekday,
                        slots: time?.timeSlot?.map(slot => ({
                            start: dayjs(slot?.slotStartTime)?.format('HH:mm'),
                            end: dayjs(slot?.slotEndTime)?.format('HH:mm'),
                        }))
                    }
                }),
                start_date: dayjs(value?.start_date)?.format('YYYY-MM-DD'),
                end_date: dayjs(value?.end_date)?.format('YYYY-MM-DD'),
                use_lesson: value?.use_lesson
            }
            setLoading(true);
            courseService
                .createFixedSession(workspaceId, params?.courseId, { ...session, ...paramFixedSession })
                .then((res) => {
                    if (res.data?.error) {
                        return Toastify.error(res.data?.error?.description);
                    }
                    Toastify.success(t("SESSION.CREATE_SESSION_SUCCESS"));
                    onClose();
                    reset({});
                    setType('single');
                    refreshData();
                })
                .catch(() => Toastify.error())
                .finally(() => setLoading(false));
        }
    };

    useEffect(() => {
        reset({
            learners: [],
            instructors: [],
            learning_group: [],
            start_time: undefined,
            end_time: undefined,
            location: setting?.location,
            vc_link: setting?.vc_link,
            extra_link: '',
            instructor_note: '',
            lesson_id: '',
            repeating_cycle: '',
            time_slots: [],
            start_date: undefined,
            end_date: undefined,
            use_lesson: false,
            type: { label: t("SESSION.INDIVIDUAL"), value: 'individual' }
        })
    }, [isOpen, type, setting?.location]);

    useEffect(() => {
        setValue('start_time', undefined)
        setValue('end_time', undefined)
    }, [watch('date')])

    useEffect(() => {
        setValue("learners", null)
    }, [watch('type')])

    useEffect(() => {
        setValue("learning_group", null)
    }, [watch('type')])

    useEffect(() => {
        getEnrollmentOfCourse(1);
    }, [inputSearchEnrollment, watch('type')])

    useEffect(() => {
        getLessons(1);
    }, [inputSearchLesson])

    useEffect(() => {
        getInstructors(1);
    }, [inputSearchInstructor])

    return (
        <DialogComponent
            title={t("SESSION.BOOK_SESSIONS")}
            isOpen={isOpen}
            onCloseModal={onClose}
            styles='max-w-xl'
            child={
                <form onSubmit={handleSubmit(onSubmit)} className="NewSession">
                    <div className="NewSession-type">
                        <div onClick={() => setType('single')} className={`btn-type ${type === 'single' ? 'active' : ''} `}>
                            {t('SESSION.SINGLE_SESSION')}
                        </div>
                        <div onClick={() => setType('fixed')} className={`btn-type ${type === 'fixed' ? 'active' : ''}`}>
                            {t('SESSION.RECURRING_SESSION')}
                        </div>
                    </div>
                    <div className='field'>
                        <label className='text-xs'> {watch('type')?.value === 'individual' ? 'Learner(s)' : 'Learning Group'} <span className="text-red-500">*</span></label>
                        <div className="grid grid-cols-6 gap-2">
                            <SelectField
                                control={control}
                                placeholder={t('SESSION.ENTER_ENROLLMENT_NAME')}
                                isRequired
                                name="type"
                                options={[
                                    { label: t("SESSION.INDIVIDUAL"), value: 'individual' },
                                    { label: t("SESSION.LEARNING_GROUP"), value: 'learning_group' },
                                ]}
                                className="font-normal col-span-2"
                            />
                            <div className="col-span-4">
                                {watch('type')?.value === 'learning_group' ?
                                    <div className="field">
                                        <div className="sessionEdit-form-input">
                                            <SelectBox
                                                control={control}
                                                name="learning_group"
                                                placeholder={t('SESSION.SELECT_LEARNING_GROUP')}
                                                isRequired
                                                placeholderSearchInput={t('SESSION.SEARCH_A_NAME')}
                                                pagination={paginationEnrollment}
                                                onSearch={(value) => setInputSearchEnrollment(value)}
                                                getData={getEnrollmentOfCourse}
                                                data={enrollments}
                                                numberOfItemDisplayed={1}
                                                errors={errors}
                                                value={watch('learning_group') || []}
                                                loading={loadingEnrollment}
                                                isMulti={false}
                                                isShowSearchIcon={true}
                                                actionAfterSelectItem={(item) => {
                                                    let learners = item?.learners
                                                    if(setting?.enrollment_type === 'credit') {
                                                        setValue('learners', item?.learners?.filter(learner => learner?.credit_balance?.available_credit > 0));
                                                        return;
                                                    }
                                                    return setValue('learners', learners)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className="field">
                                        <div className="sessionEdit-form-input">
                                            <SelectBox
                                                control={control}
                                                name="learners"
                                                placeholder={t('SESSION.SELECT_LEARNERS')}
                                                isRequired
                                                placeholderSearchInput={t('SESSION.SEARCH_A_NAME')}
                                                pagination={paginationEnrollment}
                                                onSearch={(value) => setInputSearchEnrollment(value)}
                                                getData={(page) => getEnrollmentOfCourse(page)}
                                                data={enrollments}
                                                numberOfItemDisplayed={2}
                                                isMulti={true}
                                                errors={errors}
                                                value={watch('learners') || []}
                                                loading={loadingEnrollment}
                                                isShowSearchIcon={true}
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {
                        watch('type')?.value === 'learning_group' && (
                            <div className="field">
                                <div className="sessionEdit-form-input">
                                    <label className="sessionEdit-form-label">
                                        {t('SESSION.LEARNERS')}
                                        <span className="text-red-500"> *</span>
                                    </label>
                                    <SelectBox
                                        control={control}
                                        name="learners"
                                        placeholder={t('SESSION.SELECT_LEARNERS')}
                                        isRequired
                                        placeholderSearchInput={t('SESSION.SEARCH_A_NAME')}
                                        pagination={paginationEnrollment}
                                        onSearch={(value) => {
                                            const learners = watch('learning_group')?.[0]?.learners?.filter(learner => learner?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) !== -1)
                                            setInputSearchLearnerOfLearningGroup(value)
                                            setLearnerOfLearningGroup(learners)
                                        }}
                                        data={inputSearchLearnerOfLearningGroup ? learnerOfLearningGroup : watch('learning_group')?.[0]?.learners}
                                        numberOfItemDisplayed={3}
                                        isMulti={true}
                                        errors={errors}
                                        value={watch('learners') || []}
                                        isDisabled={!watch('learning_group')?.length}
                                        loading={loadingEnrollment}
                                        isShowSearchIcon={true}
                                    />
                                </div>
                            </div>
                        )
                    }


                    <div className="field">
                        <label className="sessionEdit-form-label">
                            {t('SESSION.INSTRUCTORS')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <SelectBox
                            control={control}
                            label={''}
                            name="instructors"
                            placeholder={t('SESSION.SELECT_LEARNING_INSTRUCTORS')}
                            isRequired
                            placeholderSearchInput={t('SESSION.SEARCH_A_NAME')}
                            pagination={paginationInstructor}
                            onSearch={(value) => setInputSearchInstructor(value)}
                            getData={getInstructors}
                            data={instructorsList}
                            numberOfItemDisplayed={3}
                            isMulti={true}
                            errors={errors}
                            value={watch('instructors') || []}
                            loading={loadingInstructor}
                            isShowSearchIcon={true}
                        />
                    </div>

                    {type === 'single' && (
                        <>
                            <div className="field">
                                <div className="w-full">
                                    <label>
                                        {t('DATE')}
                                        <span className="text-red-500"> *</span>
                                    </label>
                                    <DatePickerInput
                                        control={control}
                                        name="date"
                                        placeholderText={t('SESSION.SELECT_A_DATE')}
                                        minDate={new Date()}
                                        isRequired
                                        classnames="!pl-7 !h-10"
                                        iconCls="!top-[12px]"
                                    />
                                </div>
                            </div>
                            <div className="field">
                                <label className="text-dark-300 text-xs font-semibold">
                                    {t('TIME')} <span className="text-red-500"> *</span>{' '}
                                </label>
                                <div className=" grid grid-cols-2 gap-4">
                                    <div className="col-span-1 ">
                                        <TimePickerInput
                                            control={control}
                                            name="start_time"
                                            placeholderText={t('SESSION.START_TIME')}
                                            filter={(time) => {
                                                const dateSelected = dayjs(watch('date'))?.hour(dayjs().get('hour')).minute(dayjs().get('minute'))
                                                const startTime = dayjs(watch('date'))?.hour(dayjs(time).get('hour')).minute(dayjs(time).get('minute'))
                                                if (!watch('end_time') && (startTime?.isAfter(dateSelected) || (startTime?.isAfter(dayjs())))) return true
                                                if (watch('end_time') && dayjs(time)?.isBefore(dayjs(watch('end_time'))) && (startTime?.isAfter(dateSelected) || startTime?.isAfter(dayjs()))) return true
                                                return false

                                            }}
                                            disabled={!watch('date')}
                                            placement="bottom"
                                            classnames="mt-1 text-xs !rounded !px-2 !py-2.5 !pl-7"
                                            iconCls=" w-4 h-4 !top-[14px] !left-2 !right-auto  "
                                            styles="!h-full"
                                        />
                                        {//@ts-ignore
                                            <ErrorMessage
                                                className="text-ooolab_error text-xs mt-1"
                                                errors={errors}
                                                name="start_time"
                                                as="p"
                                            />
                                        }
                                    </div>
                                    <div className="col-span-1 ">
                                        <TimePickerInput
                                            control={control}
                                            name="end_time"
                                            placeholderText={t('SESSION.END_TIME')}
                                            disabled={!watch('date') || !watch('start_time')}
                                            filter={(time) => {
                                                if (watch('start_time')) return dayjs(time)?.isAfter(watch('start_time'));
                                                return dayjs(time)?.isAfter(new Date());
                                            }}
                                            classnames="mt-1 text-xs !rounded !p-2 !py-2.5 !pl-7 "
                                            iconCls=" w-4 h-4 !top-[14px] !left-2 !right-auto"
                                            styles="!h-full"
                                        />
                                        {//@ts-ignore
                                            <ErrorMessage
                                                className="text-ooolab_error text-xs mt-1"
                                                errors={errors}
                                                name="end_time"
                                                as="p"
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="field">
                                <label className="sessionEdit-form-label">
                                    {t('SESSION.LESSON')}
                                </label>
                                <SelectBox
                                    control={control}
                                    label={''}
                                    name="lesson"
                                    placeholder={t('SESSION.SELECT_A_LESSON')}
                                    placeholderSearchInput={t('SESSION.SEARCH_A_NAME')}
                                    pagination={paginationLesson}
                                    onSearch={(value) => setInputSearchLesson(value)}
                                    formatOptionLabel={(item) => <div className="w-full text-xs text-ellipsis truncate">{item?.name}</div>}
                                    formatOptionValue={(item) => <div className="text-xs max-w-[480px] text-ellipsis truncate">{item?.name}</div>}
                                    getData={getLessons}
                                    data={lessons}
                                    numberOfItemDisplayed={1}
                                    isMulti={false}
                                    errors={errors}
                                    value={watch('lesson') || []}
                                    loading={loadingLesson}
                                />
                            </div>
                        </>
                    )}

                    {type === 'fixed' && (
                        <>
                            <AddSlot
                                timeRange={watch('time_range')}
                                control={control}
                                register={register}
                                errors={errors}
                                watch={watch}
                                setValue={setValue}
                                fields={fields}
                                update={update}
                                replace={replace}
                                getValues={getValues}
                            />
                        </>
                    )}

                    <div className="field">
                        <InputField
                            label={t('SESSION.VC_LINK')}
                            placeholder={t('SESSION.ENTER_VC_LINK')}
                            name="vc_link"
                            errors={errors}
                            register={register('vc_link', {
                                pattern: {
                                    value: FORM_CONST.URL_VALIDATE,
                                    message: t('SESSION.VALIDATE_LINK'),
                                },
                            })}
                        />
                    </div>

                    <div className="field">
                        <InputField
                            label={t('SESSION.EXTRA_LINK')}
                            placeholder={t('SESSION.ENTER_EXTRA_LINK')}
                            name="extra_link"
                            errors={errors}
                            register={register('extra_link', {
                                pattern: {
                                    value: FORM_CONST.URL_VALIDATE,
                                    message: t('SESSION.VALIDATE_LINK'),
                                },
                            })}
                        />
                    </div>

                    <div className="field">
                        <InputField
                            label={t('SESSION.LOCATION')}
                            placeholder={t('SESSION.ENTER_LOCATION')}
                            name="location"
                            errors={errors}
                            register={register('location', {
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                        />
                    </div>

                    <div className="field">
                        <TextareaField
                            label={t('DESCRIPTION')}
                            maxLength={255}
                            placeholder={t('ENTER') + t('DESCRIPTION')}
                            name="instructor_note"
                            errors={errors}
                            textLength={watch('instructor_note')?.value?.length}
                            isCountter={true}
                            register={register('instructor_note', {
                                maxLength: {
                                    value: 255,
                                    message: t('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                        />
                    </div>

                    <div className="field group-button">
                        <ButtonOutline
                            type="Button"
                            onClick={() => {
                                onClose();
                                setType('single');
                                reset({});
                            }}
                        >
                            {t('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary type="submit" onClick={handleSubmit(onSubmit)} disabled={!isValid || loading}>
                            {t('BOOK')}
                        </ButtonPrimary>
                    </div>
                </form>
            }
        />
    );
}
