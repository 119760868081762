import { FC, SetStateAction, Dispatch, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EyesIcon } from 'assets/icon/EyesIcon';
import { CircleInformationIcon } from 'assets/icon/CircleInfomationIcon';
import { PresentationIcon } from 'assets/icon/PresentationIcon';
import { EditIcon } from 'assets/icon/EditIcon';
import { TextIcon } from 'assets/icon/TextIcon';
import { MoveIcon } from 'assets/icon/MoveIcon';
import { CopyFileIcon } from 'assets/icon/CopyFileIcon';
import { DownloadIcon } from 'assets/icon/DownloadIcon';
import { ShareIcon } from 'assets/icon/ShareIcon';
import { GetLinkIcon } from 'assets/icon';
import ActionsButton from 'components/ActionsButton';
import { CloudUploadIcon, TrashIcon } from '@heroicons/react/outline';
import { FolderOpen } from 'assets/icon/FolderOpen';
import { LibraryTypeEnum, onDownload } from '../LibraryContants';
import { checkPermission } from 'utils/commonFun';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';

interface ActionMenuProp {
    item: any;
    previewLibrary: (value: any) => void;
    setOpenModalRename: Dispatch<SetStateAction<boolean>>;
    onMoveFile: any;
    setSelectedFile: Dispatch<(prevState: undefined) => undefined>;
    setOpenModalDelete: Dispatch<SetStateAction<boolean>>;
    onDetails?: (value: any) => void;
    isDetail?: boolean;
    selectedFiles?: any[];
    setSelectedFiles?: Dispatch<SetStateAction<any[]>>;
    setOpenModalDuplicate: Dispatch<SetStateAction<boolean>>;
    setModalShareLink: Dispatch<SetStateAction<boolean>>;
    setLoadingDownload: Dispatch<SetStateAction<boolean>>;
    isPopupDetails?: boolean;
    setOpenModalStatus?: (value?: boolean) => void,
    positon?: string
    setModalGetLinkVideo: Dispatch<SetStateAction<boolean>>;
}

const ActionMenu: FC<ActionMenuProp> = ({
    item,
    previewLibrary,
    setOpenModalRename,
    onMoveFile,
    setSelectedFile,
    setOpenModalDelete,
    onDetails,
    isDetail,
    selectedFiles = [],
    setOpenModalDuplicate,
    setModalShareLink,
    setLoadingDownload,
    setSelectedFiles,
    isPopupDetails = false,
    setOpenModalStatus,
    positon,
    setModalGetLinkVideo,
}) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    const { getWorkspaceDetailState: { featureFlags } } = useContext(GetWorkspaceContext);

    const translator = (str: string, newKey?: boolean, opt?: any) => (newKey ? t(str, opt) : t(`LIBRARY.${str}`, opt));
    return (
        <>
            <ActionsButton
                styleOptions={`${positon ? positon : 'top-0'} right-[35px]`}
                disable={selectedFiles?.length > 1}
                menus={[
                    {
                        label: t('EDIT'),
                        icon: <EditIcon />,
                        isDisabled: (!checkPermission('libraryContentLessonsOrScorm', 'U') || !checkPermission('libraryContentAssignment', 'U') || !checkPermission('libraryContentFile', 'U')),
                        action: async () => {
                            if (LibraryTypeEnum.h5p == item.object_type) {
                                return history.push(`/workspace/${params?.id}/h5p-content/${item.object_id}`, {
                                    folderId: item?.path ? item?.path[item?.path?.length - 1]?.id : null
                                });
                            }
                            if (LibraryTypeEnum.assignment == item.object_type) {
                                return history.push(`/workspace/${params?.id}/assignment/${item.object_id}`);
                            }
                            history.push(`/workspace/${params?.id}/lesson/${item.object_id}`);
                        },
                        hide:
                            selectedFiles?.length > 1 ||
                            (item?.object_type != LibraryTypeEnum.h5p &&
                                item?.object_type != LibraryTypeEnum.lesson &&
                                item?.object_type != LibraryTypeEnum.assignment) ||
                            isPopupDetails || item?.sub_object_type === 'scorm' ||
                            item?.object_type === LibraryTypeEnum.scorm,
                    },
                    {
                        label: t('PREVIEW'),
                        icon: <EyesIcon width="18" height="16" fill="#111827" />,
                        isDisabled: (!checkPermission('libraryContent', 'R') || !checkPermission('libraryContentFile', 'R')),
                        action: () => previewLibrary(item),
                        hide:
                            selectedFiles?.length > 1 ||
                            item?.object_type == LibraryTypeEnum.folder ||
                            item?.object_type === LibraryTypeEnum.lesson ||
                            item?.object_type === LibraryTypeEnum.h5p ||
                            item?.object_type === LibraryTypeEnum.assignment ||
                            isPopupDetails
                    },
                    {
                        label: t('OPEN'),
                        icon: <FolderOpen />,
                        isDisabled: selectedFiles?.length > 1 || !checkPermission('libraryContentFolder', 'R'),
                        action: () => {
                            history.push(`/workspace/${params?.id}/library/${item?.id}`);
                        },
                        hide: selectedFiles?.length > 1 || item?.object_type != LibraryTypeEnum.folder || isPopupDetails || item?.object_type === 'scorm',
                    },
                    {
                        label: t('DETAILS'),
                        icon: <CircleInformationIcon width="18" height="16" fill="#111827" />,
                        isDisabled: selectedFiles?.length > 1 || !checkPermission('libraryContent', 'R'),
                        action: () => {
                            onDetails(item);
                        },
                        class: `${item?.sub_object_type !== 'scorm' ? 'border-b border-gray-300' : null}`,
                        hide: isDetail || isPopupDetails || item?.object_type == LibraryTypeEnum.spreadsheet ||
                            item?.object_type === LibraryTypeEnum.scorm,
                    },
                    {
                        label: t('PRESENTATION_MODE'),
                        icon: <PresentationIcon />,
                        isDisabled: selectedFiles?.length > 1 || !checkPermission('libraryContent', 'R'),
                        action: async () => {
                            if (item?.object_type == LibraryTypeEnum.h5p)
                                history.push(`/workspace/${params?.id}/h5p-content/${item?.object_id}/preview`);
                            if (item?.object_type == LibraryTypeEnum.lesson)
                                history.push(`/workspace/${params?.id}/lesson/${item?.object_id}/preview`);
                            if (item?.object_type == LibraryTypeEnum.scorm)
                                history.push(`/workspace/${params?.id}/scorm/${item?.object_id}/preview`);
                            if (item?.object_type == LibraryTypeEnum.assignment)
                                history.push(`/workspace/${params?.id}/assignment/${item?.object_id}/preview`);
                        },
                        hide:
                            selectedFiles?.length > 1 ||
                            (item?.object_type !== LibraryTypeEnum.h5p &&
                                item?.object_type !== LibraryTypeEnum.lesson &&
                                item?.object_type !== LibraryTypeEnum.scorm &&
                                item?.object_type !== LibraryTypeEnum.assignment) ||
                            isPopupDetails ||
                            item?.object_type === LibraryTypeEnum.scorm,
                        class: `${item?.sub_object_type === 'scorm' ? 'border-b border-gray-300' : null}`,
                    },
                    {
                        label: t(item?.status == "draft" ? "PUBLISH" : "REVERT_TO_DRAFT"),
                        icon: <CloudUploadIcon className='w-5' />,
                        isDisabled: (!checkPermission('libraryContentLessonsOrScorm', 'RD') || !checkPermission('libraryContentLessonsOrScorm', 'PL')),
                        hide: true, // item?.sub_object_type != 'scorm',
                        action: (e) => {
                            setOpenModalStatus(true);
                            setSelectedFile(item);
                        },
                    },
                    {
                        label: t('RENAME'),
                        icon: <TextIcon />,
                        isDisabled: (!checkPermission('libraryContentLessonsOrScorm', 'U') || !checkPermission('libraryContentAssignment', 'U') || !checkPermission('libraryContentFile', 'U') || !checkPermission('libraryContentFolder', 'U')),
                        hide: (selectedFiles?.length > 1 || !checkPermission('libraryContent', 'U') || item?.object_type === LibraryTypeEnum.scorm),
                        action: (e) => {
                            setOpenModalRename(true);
                            setSelectedFile(item);
                        },
                    },
                    {
                        label: t('MOVE_TO'),
                        icon: <MoveIcon />,
                        isDisabled: (!checkPermission('libraryContentLessonsOrScorm', 'U') || !checkPermission('libraryContentAssignment', 'U') || !checkPermission('libraryContentFile', 'U') || !checkPermission('libraryContentFolder', 'U')),
                        action: (e) => {
                            onMoveFile();
                            setSelectedFiles([item]);
                        },
                        class: `${item?.sub_object_type === 'scorm' ? 'border-b border-gray-300' : null}`,
                        hide: !checkPermission('libraryContent', 'U') || item?.object_type === 'scorm'
                    },
                    {
                        label: t('DUPLICATE'),
                        icon: <CopyFileIcon />,
                        isDisabled: selectedFiles?.length > 1 || (!checkPermission('libraryContentLessonsOrScorm', 'DC') || !checkPermission('libraryContentAssignment', 'DC') || !checkPermission('libraryContentFile', 'DC') || !checkPermission('libraryContentFolder', 'DC')),
                        action: async () => {
                            setOpenModalDuplicate(true);
                            setSelectedFile(item);
                        },
                        hide: selectedFiles?.length > 1 || item?.object_type === LibraryTypeEnum.folder ||
                            item?.object_type === 'scorm' || item?.object_type === LibraryTypeEnum.scorm
                    },
                    {
                        label: t('SHARE'),
                        icon: <ShareIcon />,
                        action: () => {
                            setModalShareLink(true);
                        },
                        isDisabled: !checkPermission('libraryContent', 'SR'),
                        hide: item?.object_type === LibraryTypeEnum.folder || item?.object_type === LibraryTypeEnum.assignment ||
                            item?.object_type === 'scorm' || !featureFlags?.systemShareLink,
                    },
                    {
                        label: t('GET_URL_FOR_H5P'),
                        icon: <GetLinkIcon />,
                        isDisabled: !checkPermission('libraryContentFile', 'DL'),
                        action: () => {
                            setSelectedFile(item);
                            setModalGetLinkVideo(true);
                        },
                        hide: selectedFiles?.length > 1 || item?.object_type != LibraryTypeEnum.video || item?.object_type === 'scorm',
                    },
                    {
                        label: t('DOWNLOAD'),
                        icon: <DownloadIcon width="18" height="16" fill="#111827" />,
                        isDisabled: item?.object_type === LibraryTypeEnum.folder || (!checkPermission('libraryContentLessonsOrScorm', 'DL') || !checkPermission('libraryContentAssignment', 'DL') || !checkPermission('libraryContentFile', 'DL')),
                        action: () => {
                            onDownload(item, params, setLoadingDownload);
                        },
                        hide: selectedFiles?.length > 1 || item?.object_type === LibraryTypeEnum.folder || isPopupDetails || !featureFlags?.systemDownload ||
                            item?.object_type === LibraryTypeEnum.scorm,
                        class: 'border-b border-gray-300',
                    },
                    {
                        label: t('DELETE'),
                        icon: <TrashIcon width="18" height="16" className="text-gray-900" />,
                        isDisabled: selectedFiles?.length > 1 || (!checkPermission('libraryContentLessonsOrScorm', 'D') || !checkPermission('libraryContentAssignment', 'D') || !checkPermission('libraryContentFile', 'D') || !checkPermission('libraryContentFolder', 'D')),
                        hide: (selectedFiles?.length > 1 || !checkPermission('libraryContent', 'U') || item?.object_type === LibraryTypeEnum.scorm),
                        action: async (e) => {
                            setOpenModalDelete(true);
                            setSelectedFile(item);
                        },
                    },
                ]}

            />
        </>
    );
};

export default ActionMenu;
