import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/outline';
import { useBoolean } from 'hooks/custom';
import { useDebouncedState } from 'hooks/useDebounce';
import SearchBox from 'components/V2/SearchBox';
import Pagination from 'components/V2/Pagination';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import DialogComponent from 'components/Dialog/DialogComponent';
import { checkPermission, getIndexPage } from 'utils/commonFun';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { ShowHideIcon } from 'assets/icon';
import { addUniqueId } from './components/propertyCommon';
import propertyService from 'services/property.service';
import { CreateNewProperty } from './components/CreateNewProperty';
import { TableProperty } from './components/TableProperty';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { ShowHideCourseLeanerProperty } from './components/ShowHideProperty/ShowHideCourseLeanerProperty';
import { EditProperty } from './components/EditProperty';

export const CoursePropertyTab = ({ tab, subtab }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const params: any = useParams();
    const query = useQueryParamsURL();

    const { booleanValue: modalCreateProperty, toggleBooleanValue: toggleModalCreateProperty } = useBoolean();
    const [keyword, setKeyword] = useDebouncedState('');

    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('1-50');
    const [propertyList, setPropertyList] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        per_page: 20,
        order: 'asc',
        sort_by: 'created_at',
        total: 0,
    });

    const workspaceId = params.id;

    const translator = (str: string, opt?: any) => t(`DASHBOARD.WORKSPACE_SETTING.CUSTOM_PROPERTY.${str}`, opt);

    const handleClose = (value: boolean) => {
        if (value) {
            fetchProperties();
        }
        toggleModalCreateProperty();
    };

    const fetchProperties = (page = 1) => {
        setLoading(true);
        const payload = {
            page: page || pagination.page,
            per_page: pagination.per_page,
            order: pagination.order,
            sort_by: pagination.sort_by,
            ...(keyword ? { q: keyword?.trim() } : {}),
            object_type: subtab,
        };

        propertyService
            .get(workspaceId, payload)
            .then((res) => {
                const { items, page, per_page, order, total } = res.data;
                setPropertyList(addUniqueId(items));
                setPagination({ ...pagination, page, per_page, order, total });
                setIndexPage(getIndexPage(page, per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        fetchProperties();
    }, [keyword]);

    if (query.get('visible')) {
        return <ShowHideCourseLeanerProperty tab={tab} subtab={subtab} />;
    } else if (query.get('id')) {
        return <EditProperty propertyList={propertyList} onRefresh={() => {
            fetchProperties();
        }} propertyId={query.get('id')} />;
    }

    return (
        <>
            <div className="px-5 py-4 flex justify-between items-center border-b border-gray-300">
                <SearchBox className="w-full max-w-xs" placeholder={t('SEARCH')} value={keyword} onSubmit={(e: any) => setKeyword(e)} />
                <div className="flex items-center space-x-2">
                    <ButtonOutline
                        type="button"
                        onClick={() => history.push(`/workspace/${workspaceId}/setting?tab=${tab}&subtab=${subtab}&visible=${subtab}`)}
                        className="!text-enterprise !border-enterprise"
                    >
                        <ShowHideIcon className="w-5 h-5 mr-1" /> {translator('SHOW_HIDE_PROPERTY')}
                    </ButtonOutline>
                    <ButtonPrimary type="button" disabled={false} onClick={toggleModalCreateProperty} className="">
                        <PlusIcon className="w-5 h-5" /> {t('CREATE')}
                    </ButtonPrimary>
                </div>
            </div>
            <div className="p-5">
                <div className="border border-gray-300 rounded-lg">
                    <div className="h-[calc(100vh-356px)] overflow-y-auto property-custom-scrollbar">
                        {loading ? (
                            <div className="flex items-center justify-center mt-4">
                                <CircleSpin color="text-primary-500" className="m-0" />
                            </div>
                        ) : (
                            <TableProperty
                                translator={translator}
                                workspaceId={workspaceId}
                                tab={tab}
                                subtab={subtab}
                                data={propertyList}
                                hasSearch={keyword}
                            />
                        )}
                    </div>
                    {!!propertyList?.length && (
                        <Pagination
                            callback={(page) => fetchProperties(page)}
                            total={pagination?.total}
                            page={pagination?.page}
                            per_page={pagination?.per_page}
                            indexPage={indexPage}
                            className="pl-5"
                        />
                    )}
                </div>
            </div>
            {modalCreateProperty && <DialogComponent
                isOpen={modalCreateProperty}
                onCloseModal={toggleModalCreateProperty}
                title={translator('CREATE_A_NEW_PROPERTY')}
                styles="max-w-lg"
                child={
                    <CreateNewProperty
                        type={subtab}
                        data={''}
                        onClose={handleClose}
                        properties={propertyList
                            .map((item) => {
                                if (item?.children?.length > 0) {
                                    return [item, ...item?.children];
                                }
                                return item;
                            })
                            .flat(1)}
                    />
                }
            />}
        </>
    );
};
