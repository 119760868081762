import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { SelectField } from 'components/Select';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import { PAYMENT_GETWAY_OPTIONS, PAYMENT_OPTION, PAYMENT_OPTION_LIST, SCHEDULE_TYPE_ENUM, SCHEDULE_TYPE_LIST } from '../../constants';
import { TimePickerField } from 'components/DatePicker';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import './CreateInvoice.scss';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import paymentService from 'services/payment.service';
import { GuideAddingBank, GuidePaymentGetway } from '../components/GuideAddingBank';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import SelectBox from '_shared/components/Select';
import { useDebouncedState } from 'hooks/useDebounce';
import workspaceMiddleware from 'middleware/workspace.middleware';

let loadingSubmit = false;
export default function CreateSingleInvoice({ onClose, onRefreshData, invoiceId = undefined }) {
    const params: { id } = useParams();
    const workspaceId = params.id;
    const { t: translator } = useTranslation();
    const {
        handleSubmit,
        formState: { errors, isDirty },
        control,
        setValue,
        watch,
        register,
        reset,
        clearErrors,
        setError,
    } = useForm({ mode: 'onChange' });

    const [paymentList, setPaymentList] = useState([]);
    const [paymentGetwayOptions, setPaymentGetwayOptions] = useState([]);
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: workspaceDetailInformation } = getWorkspaceDetailState;

    const [learnerList, setLearnerList] = useState([]);
    const [inputSearchLearner, setInputSearchLearner] = useDebouncedState('', 0);
    const [paginaginLearner, setPaginationLearner] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingLearners, setLoadingLearners] = useState(false);

    const getLearners = (page: number) => {
        setLoadingLearners(true);
        let payload: any = {
            page,
            per_page: paginaginLearner.per_page,
            q: inputSearchLearner || null,
            status: ["invite", "active", "registered"],
            sort_by: 'created_at',
            location: 'learner_portal'
        };
        if (!payload?.title) delete payload?.title;
        if (!payload.q) delete payload.q;

        workspaceMiddleware
            .getWorkspaceMembersMiddleware(workspaceId, payload)
            .then((res) => {
                const newOptions = res?.data?.items?.map((el: any) => {
                    return {
                        avatar_url: el?.avatar_url,
                        id: el?.id,
                        name: el?.display_name,
                        value: el?.id,
                    };
                });
                if (page === 1) setLearnerList(newOptions);
                else setLearnerList([...learnerList, ...newOptions]);
                setPaginationLearner({
                    ...paginaginLearner,
                    page: res?.data?.page,
                    total: res?.data?.total,
                });
            })
            .finally(() => setLoadingLearners(false));
    };

    const [courseList, setcourseList] = useState([]);
    const [inputSearchCourse, setInputSearchCourse] = useDebouncedState('', 0);
    const [paginaginCourse, setPaginationCourse] = useState({ total: 0, page: 1, per_page: 10 });
    const [loadingCourse, setLoadingCourse] = useState(false);

    const getCourses = (page: number) => {
        setLoadingCourse(true);
        let payload: any = {
            page,
            per_page: paginaginCourse.per_page,
            title: inputSearchCourse || null,
        };
        if (!payload?.title) delete payload?.title;

        workspaceService
            .getCoursesList(workspaceId, payload)
            .then((res) => {
                const newOptions = res?.items?.map((el: any) => {
                    return {
                        id: el?.id,
                        name: el?.title,
                        value: el?.id,
                    };
                });
                if (page === 1) setcourseList(newOptions);
                else setcourseList([...courseList, ...newOptions]);
                setPaginationCourse({ ...paginaginCourse, page: res?.page, total: res?.total });
            })
            .finally(() => setLoadingCourse(false));
    };

    const getPaymenListt = () => {
        paymentService.getListPaymentDetail(workspaceId, { skip_pagination: true }).then((res) => {
            const payments = res?.data?.items?.map((item) => {
                return {
                    ...item,
                    label: item?.bank_name,
                    value: item?.id,
                };
            });
            setPaymentList(payments);
        });
    };

    const fetchPaymentDetails = () => {
        paymentService.getIntegrationList(params?.id).then((res) => {
            const arrTemp = [];
            res.data?.items?.forEach((el: any) => {
                const payItem = PAYMENT_GETWAY_OPTIONS(translator).find((item) => item?.name === el?.type);
                if (!!payItem && el?.enabled) arrTemp.push({ ...payItem, value: el?.id });
            });
            setPaymentGetwayOptions(arrTemp);
        });
    };

    const getScheduleAt = (scheduleDate, scheduleTime) => {
        return `${dayjs(scheduleDate).format('YYYY-MM-DD')} ${dayjs(scheduleTime).format('HH:mm')} `;
    };

    const createInvoice = (values) => {
        const scheduleAt = getScheduleAt(values?.schedule_date, values?.schedule_time);
        const payload = {
            learner_ids: values?.learners?.map((learner) => learner?.value || learner?.id),
            course_ids: values?.courses?.map((course) => course?.value),
            amount: values?.amount,
            payment_option: values?.payment_option?.value,
            payment_detail_id: values?.payment_option?.value === 'bank_transfer' ? values?.payment_detail?.value : undefined,
            payment_gateway_id: values?.payment_option?.value === 'payment_gateway' ? values?.payment_detail?.value : undefined,
            note: values?.note || null,
            schedule_type: values?.schedule_type?.value,
            schedule_at: dayjs(scheduleAt).format(),
            due_at: dayjs(values?.due_at)?.format(),
            notes: values?.notes || null,
        };
        if (!payload.learner_ids?.length) delete payload.learner_ids;
        if (!payload.note?.length) delete payload.note;
        if (values?.schedule_type?.value === SCHEDULE_TYPE_ENUM.now) {
            delete payload.schedule_at;
        }

        if (loadingSubmit) return;
        loadingSubmit = true;

        if (invoiceId) {
            workspaceService
                .updateInvoice(workspaceId, invoiceId, payload)
                .then((res) => {
                    Toastify.success();
                    onRefreshData();
                    onClose();
                })
                .catch((err) => {
                    Toastify.error();
                })
                .finally(() => loadingSubmit = false);
        } else {
            workspaceService
                .createSingleInvoice(workspaceId, payload)
                .then((res) => {
                    Toastify.success(translator('CREATE_INVOICE_SUCCESSFULLY'));
                    onRefreshData();
                    onClose();
                })
                .catch(() => {
                    Toastify.error(translator('CREATE_INVOICE_FAILED'));
                })
                .finally(() => loadingSubmit = false);
        }
    };

    useEffect(() => {
        if (watch('schedule_type')?.value === SCHEDULE_TYPE_ENUM.now) {
            setValue('schedule_date', new Date());
            setValue('schedule_time', new Date());
        }
        !invoiceId && setValue('due_at', null);
    }, [watch('schedule_type')]);

    useEffect(() => {
        !invoiceId && setValue('due_at', null);
    }, [watch('schedule_date'), watch('schedule_time')]);

    const getInvoiceDetail = () => {
        workspaceService.getInvoiceDetail(params?.id, invoiceId).then((res) => {
            const data = res?.data;
            const learners =
                data?.learners?.map((item) => {
                    return {
                        name: item?.display_name || item?.name,
                        value: item?.id,
                        id: item?.id,
                        type: 'learner',
                        avatar_url: item?.avatar_url
                    };
                }) || [];
            reset({
                ...data,
                objects: learners,
                courses: data?.courses?.map((course) => {
                    return {
                        name: course?.title,
                        value: course?.id,
                    };
                }),
                amount: data?.amount,
                payment_option: {
                    value: data?.payment_option,
                    label: PAYMENT_OPTION(translator)[data?.payment_option],
                },
                note: data?.note,
                schedule_type: {
                    label: translator('SCHEDULE'),
                    value: SCHEDULE_TYPE_ENUM.schedule,
                },
                schedule_date: new Date(data?.schedule_at),
                schedule_time: new Date(data?.schedule_at),
                due_at: new Date(data?.due_at),
                notes: data?.notes,
            });
        });
    };

    useEffect(() => {
        if (invoiceId) {
            getInvoiceDetail();
        }
    }, [invoiceId]);

    useEffect(() => {
        getPaymenListt();
        fetchPaymentDetails();
    }, []);

    useEffect(() => {
        if (watch('payment_option')?.value === 'bank_transfer') {
            if (paymentList?.length) {
                const paymentDetail = paymentList?.find((item) => item?.id === watch('payment_detail_id'));
                setValue('payment_detail', {
                    ...paymentDetail,
                    label: paymentDetail?.bank_name,
                    value: paymentDetail?.id,
                });
            }
        } else if (watch('payment_option')?.value === 'payment_gateway') {
            if (paymentGetwayOptions?.length) {
                const paymentDetail = paymentGetwayOptions?.find((item) => item?.value === watch('payment_gateway_id'));
                setValue('payment_detail', paymentDetail);
            }
        }
    }, [paymentList, paymentGetwayOptions, watch('payment_detail_id'), watch('payment_gateway_id')]);

    useEffect(() => {
        getCourses(1);
    }, [inputSearchCourse]);

    useEffect(() => {
        getLearners(1);
    }, [inputSearchLearner]);

    return (
        <form className="CreateInvoice mt-2" onSubmit={handleSubmit(createInvoice)} noValidate>
            <SelectBox
                control={control}
                label={translator('USER.TEACHING_HISTORY.LEARNERS')}
                name="learners"
                placeholder={translator('SEARCH_LEARNERS')}
                errors={errors}
                isMulti={true}
                loading={loadingLearners}
                value={watch('learners') || []}
                onSearch={setInputSearchLearner}
                getData={getLearners}
                data={learnerList}
                pagination={paginaginLearner}
                numberOfItemDisplayed={3}
                isRequired
                formatOptionValue={(item) => (
                    <>
                        <img className="item-avatar" src={item?.avatar_url} />
                        <span className="text-ellipsis truncate max-w-[95px]">{item?.name}</span>
                    </>
                )}
            />

            <div className="CreateInvoice-grid">
                <div className="col-span-1">
                    <SelectBox
                        control={control}
                        label={translator('COURSES_LABEL')}
                        name="courses"
                        placeholder={translator('SEARCH_COURSES')}
                        errors={errors}
                        isMulti={true}
                        loading={loadingCourse}
                        value={watch('courses') || []}
                        onSearch={setInputSearchCourse}
                        getData={getCourses}
                        data={courseList}
                        pagination={paginaginCourse}
                        numberOfItemDisplayed={2}
                        formatOptionLabel={(item) => <div className="text-ellipsis truncate">{item?.name}</div>}
                        formatOptionValue={(item) => <div className="text-ellipsis truncate max-w-[55px]">{item?.name}</div>}
                    />
                </div>

                <InputField
                    isRequired
                    label={translator('AMOUNT')}
                    placeholder={translator('ENTER_AMOUNT')}
                    name="amount"
                    className="col-span-1"
                    errors={errors}
                    type="floatNumber"
                    register={register('amount', {
                        required: {
                            value: true,
                            message: translator('FORM_CONST.REQUIRED_FIELD'),
                        },
                        min: {
                            value: 1,
                            message: 'Please enter at least 1',
                        },
                        max: {
                            value: 1000000000,
                            message: 'Please enter no more than 1,000,000,000',
                        },
                    })}
                    currency={workspaceDetailInformation?.default_currency?.code}
                />
                <SelectField
                    control={control}
                    label={translator('PAYMENT_OPTION')}
                    className="col-span-1"
                    placeholder={translator('SELECT_PAYMENT_OPTION')}
                    isRequired
                    name="payment_option"
                    options={PAYMENT_OPTION_LIST(translator)}
                    errors={errors}
                    onChange={(newValue) => {
                        setValue('payment_detail', null);
                        setValue('payment_detail_id', null);
                        setValue('payment_gateway_id', null);
                        if (newValue?.value === 'cash') clearErrors('payment_detail');
                        else setError('payment_detail', { message: translator('FORM_CONST.REQUIRED_FIELD') });
                    }}
                />
                <SelectField
                    control={control}
                    label={translator('PAYMENT_DETAIL')}
                    placeholder={
                        !watch('payment_option') || watch('payment_option')?.value === 'cash'
                            ? '-'
                            : translator('SELECT_PAYMENT_DETAIL')
                    }
                    isDisabled={!watch('payment_option') || watch('payment_option')?.value === 'cash'}
                    className="col-span-1"
                    name="payment_detail"
                    options={
                        watch('payment_option')?.value === 'bank_transfer'
                            ? paymentList?.filter((payment) => payment?.status === 'activated')
                            : watch('payment_option')?.value === 'payment_gateway'
                                ? paymentGetwayOptions
                                : []
                    }
                    isRequired={watch('payment_option') && watch('payment_option')?.value !== 'cash'}
                    noOptionsMessage={() =>
                        watch('payment_option')?.value === 'payment_gateway' ? (
                            <GuidePaymentGetway />
                        ) : watch('payment_option')?.value === 'bank_transfer' ? (
                            <GuideAddingBank />
                        ) : (
                            ''
                        )
                    }
                    errors={errors}
                />
            </div>
            <TextareaField
                label={translator('DESCRIPTION')}
                placeholder={translator('ENTER_DESCRIPTION')}
                name="note"
                maxLength={100}
                isCountter
                className="mt-5"
                textLength={watch('note')?.length}
                register={register('note', {
                    maxLength: {
                        value: 1000,
                        message: translator('MAX_LENGTH_TEXT', { maxLength: 100 }),
                    },
                })}
            />

            <TextareaField
                label={translator('NOTES')}
                placeholder={translator('ENTER_NOTES')}
                name="notes"
                maxLength={1000}
                isCountter
                className="mt-5"
                textLength={watch('notes')?.length}
                register={register('notes', {
                    maxLength: {
                        value: 1000,
                        message: translator('MAX_LENGTH_TEXT', { maxLength: 1000 }),
                    },
                })}
            />

            <div className="grid grid-cols-4 gap-5 mt-5">
                <SelectField
                    control={control}
                    label={translator('SCHEDULE')}
                    placeholder={translator('SELECT_SCHEDULE_TYPE')}
                    className="col-span-2 capitalize"
                    name="schedule_type"
                    isRequired
                    errors={errors}
                    options={SCHEDULE_TYPE_LIST(translator)}
                />
                <div className="col-span-2 flex gap-3 h-full schedule-date">
                    <div>
                        <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                            {translator('DATE')}
                            <span className="text-red-500"> *</span>
                        </label>
                        <DatePickerInput
                            control={control}
                            classnames={`!h-[38px] ${errors['schedule_date'] ? 'border-red-500' : ''}`}
                            name="schedule_date"
                            placeholderText={translator('SELECT_DATE')}
                            minDate={new Date()}
                            isRequired
                            disabled={watch('schedule_type')?.value === 'now'}
                            positionIcon="right"
                        />
                        {/* @ts-ignore */}
                        <ErrorMessage
                            className="text-red-500 text-xs mt-2 font-semibold"
                            errors={errors}
                            name="schedule_date"
                            as="div"
                        />
                    </div>
                    <div>
                        <TimePickerField
                            control={control}
                            label={translator('TIME')}
                            name="schedule_time"
                            placeholder={translator('SELECT_TIME')}
                            disabled={watch('schedule_type')?.value === SCHEDULE_TYPE_ENUM.now}
                            isRequired
                            isClearable={false}
                            positionIcon="right"
                            timeDurations={5}
                            filterTimeFunc={(time) => {
                                const hour = dayjs(time).get('hour');
                                const mins = dayjs(time).get('minute');
                                const timeInDay = dayjs(watch('schedule_date')).hour(hour).minute(mins);
                                if (watch('schedule_date')) {
                                    return dayjs(timeInDay)?.isAfter(dayjs());
                                }
                            }}
                            errors={errors}
                        />
                    </div>
                </div>
            </div>

            {watch('schedule_type')?.value === SCHEDULE_TYPE_ENUM.schedule &&
                watch('schedule_date') &&
                watch('schedule_time') &&
                dayjs(getScheduleAt(watch('schedule_date'), watch('schedule_time')))?.isBefore(new Date()) && (
                    <p className="text-red-500 text-xs font-semibold">{translator('VALIDATE_TIME_IN_THE_PAST')}</p>
                )}

            <div className="relative w-1/2 pr-2.5 mt-5 due_at">
                <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                    {translator('DUE_DATE_AND_TIME')}
                    <span className="text-red-500"> *</span>
                </label>
                <DatePickerInput
                    control={control}
                    name="due_at"
                    placeholderText={translator('SELECT_DUE_DATE_AND_TIME')}
                    classnames={`flex ${errors['due_at'] ? 'border-red-500' : ''}`}
                    minDate={
                        new Date(
                            `${dayjs(watch('schedule_date')).format('YYYY-MM-DD')} ${dayjs(watch('schedule_time')).format('HH:mm')}`
                        )
                    }
                    filterTimeFunc={(time) => {
                        const endTime: any = new Date(
                            `${dayjs(watch('schedule_date')).format('YYYY-MM-DD')} ${dayjs(watch('schedule_time')).format('HH:mm')}`
                        );
                        if (endTime) {
                            return dayjs(time)?.isAfter(dayjs(endTime));
                        }
                    }}
                    isRequired
                    positionIcon="right"
                    formatDate="yyyy-MM-dd・HH:mm"
                    showTimeSelect={true}
                    timeIntervals={5}
                />
                {/* @ts-ignore */}
                <ErrorMessage className="text-red-500 text-xs mt-1 font-semibold" errors={errors} name="due_at" as="div" />
                {dayjs(watch('due_at'))?.isBefore(new Date()) && (
                    <p className="text-red-500 text-xs mt-1 font-semibold">{translator('VALIDATE_TIME_IN_THE_PAST')}</p>
                )}
                {dayjs(watch('due_at'))?.isBefore(dayjs(getScheduleAt(watch('schedule_date'), watch('schedule_time')))) && (
                    <p className="text-red-500 text-xs mt-1 font-semibold">{translator('DUE_AT_LESS_THAN_EQUAL_SCHEDULE_AT')}</p>
                )}
            </div>

            <div className="mt-5 flex gap-5 justify-center">
                <ButtonOutline type="button" onClick={onClose}>
                    {translator('CANCEL')}
                </ButtonOutline>
                <ButtonPrimary disabled={!isDirty || loadingSubmit} type="submit" onClick={() => { }}>
                    {invoiceId ? translator('SAVE') : translator('CREATE')}
                </ButtonPrimary>
            </div>
        </form>
    );
}
