import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { useTranslation } from 'react-i18next';
import { ConnectForm } from '..';

export const Information = () => (
    <ConnectForm>
        {({ register, formState: { errors } }) => {
            const { t: translator } = useTranslation();
            return (
                <div>
                    <h1 className="text-gray-800 text-lg font-semibold">{translator('SKILL_INFORMATION')}</h1>
                    <div className="mt-5 flex flex-col gap-5">
                        <InputField
                            label={translator('NAME')}
                            placeholder={translator('ENTER_SKILL_NAME')}
                            name="name"
                            isRequired
                            register={register('name', {
                                required: translator('FORM_CONST.REQUIRED_FIELD'),
                                validate: {
                                    shouldNotContainSpace: (value) => {
                                        return value?.trim()?.length ? true : `${translator('FORM_CONST.REQUIRED_FIELD')}`;
                                    },
                                },
                                maxLength: {
                                    value: 255,
                                    message: translator('FORM_CONST.VALIDATE_255_CHARACTERS'),
                                },
                            })}
                            errors={errors}
                        />

                        <TextareaField
                            label={translator('DESCRIPTION')}
                            placeholder={translator('ENTER_DESCRIPTION')}
                            name="description"
                            maxLength={1000}
                            isCountter={true}
                            register={{
                                ...register('description', {
                                    // required: {
                                    //     value: true,
                                    //     message: translator('FORM_CONST.REQUIRED_FIELD'),
                                    // },
                                    maxLength: {
                                        value: 1000,
                                        message: translator('FORM_CONST.MAX_LENGTH_FIELD'),
                                    },
                                }),
                            }}
                            errors={errors}
                        />
                    </div>
                </div>
            );
        }}
    </ConnectForm>
);
