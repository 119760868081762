import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedState } from 'hooks/useDebounce';
import SelectBox from 'components/V2/SelectBox';
import './Filter.scss';
import SearchBox from 'components/V2/SearchBox';
import courseService from 'services/course.service';
import { SwitchToggle } from '_shared/components/Form/SwitchToggle/SwitchToggle';
import { useForm } from 'react-hook-form';

enum EnrollmentType {
    learner = 'individual',
    learningGroup = 'learning_group'
}

interface IFilter {
    onChangeFilter: (value: any) => void;
}

const Filter = ({
    onChangeFilter,
}: IFilter) => {
    const params: any = useParams();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useDebouncedState('', 0);
    const [inputSearchLearner, setInputSearchLearner] = useDebouncedState('');
    const [listLearners, setListLearners] = useState([]);
    const [learnerSelected, setLearnerSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([])
    const [overdueSelected, setOverdueSelected] = useState<any[]>([])
    const [loadingLearner, setLoadingLearner] = useState(false);

    const [inputSearchLearningGroup, setInputSearchLearningGroup,] = useDebouncedState('');
    const [listLearningGroups, setListLearningGroup] = useState([]);
    const [learningGroupSelected, setLearningGroupSelected] = useState([]);
    const [loadingLearningGroup, setLoadingLearningGroup] = useState(false);

    const translatorCourse = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`COURSES.${str}`, opt)
    }
    const [loadingFilterLesson, setLoadingFilterLesson] = useState(false);
    const [lessonSelected, setLessonSelected] = useState([])
    const [lessonList, setLessonList] = useState([]);
    const [pagingLesson, setPagingLesson] = useState<any>({
        page: 1,
    });
    const [inputSearchLesson, setInputSearchLesson] = useDebouncedState('');
    const workspaceId = params.id;
    const courseId = params.courseId;

    const [paginationLearner, setPaginationLearner] = useState<any>({
        page: 1,
    });
    const [paginationGroup, setPaginationGroup] = useState<any>({
        page: 1,
    });

    const { control, setValue, getValues, watch } = useForm({ defaultValues: {} });

    const getLessonList = (page: number = 1, inputSearchLesson = undefined) => {
        setLoadingFilterLesson(true);
        const request: any = {
            page: page ? page : pagingLesson.page,
            per_page: 10,
            q: inputSearchLesson,
        };

        courseService.getLessonOfCourse(params.id, courseId, request)
            .then((res) => {
                const formattedData = res?.data?.items?.map((item) => {
                    return {
                        name: item?.title,
                        id: item?.id,
                        value: item?.id,
                    };
                });
                setPagingLesson({ ...pagingLesson, total: res?.data?.total, page });
                if (page === 1) setLessonList(formattedData);
                else setLessonList([...lessonList, ...formattedData]);
            })
            .finally(() => setLoadingFilterLesson(false));
    };

    useEffect(() => {
        getLessonList(1, inputSearchLesson);
    }, [inputSearchLesson]);

    const getListLearner = (page: number = 1) => {
        
        setLoadingLearner(true);
        const payload = {
            page: page || paginationLearner.page,
            per_page: 10,
            q: inputSearchLearner,
        }
        if (!payload?.q) delete payload?.q
        courseService.getLearners(workspaceId, courseId, payload).then((res: any) => {
            const formattedData = res?.data?.items?.map((item) => {
                return {
                    name: item?.display_name,
                    id: item?.id,
                    value: item?.id,
                };
            }) || [];
            setPaginationLearner({ ...paginationLearner, total: res?.data?.total, page });
            if (page === 1) setListLearners(formattedData);
            else setListLearners([...listLearners, ...formattedData]);
        }).finally(() => {
            setLoadingLearner(false);
        });

    }


    const getListEnroll = (page: number = 1, enrollmentType = EnrollmentType.learner) => {
        if (EnrollmentType.learner === enrollmentType) {
            setLoadingLearner(true);
        } else {
            setLoadingLearningGroup(true);
        }
        const payload = {
            page: page ? page : (EnrollmentType.learner === enrollmentType ? paginationLearner.page : paginationGroup.page),
            per_page: 10,
            q: EnrollmentType.learner === enrollmentType ? inputSearchLearner : inputSearchLearningGroup,
            enrollment_as: enrollmentType,
        }
        if (!payload?.q) delete payload?.q
        courseService.getEnrollments(
            workspaceId, courseId, payload

        )
            .then((res: any) => {
                let formattedData = [];
                if (EnrollmentType.learner === enrollmentType) {
                    formattedData = res?.data?.items?.map((item) => {
                        return {
                            name: item?.learners[0].display_name,
                            id: item?.learners[0].id,
                            value: item?.learners[0].id,
                        };
                    }) || [];
                } else {
                    formattedData = res?.data?.items?.map(item => {
                        return {
                            id: item?.learning_group?.id,
                            name: item?.learning_group?.name,
                            value: item?.learning_group?.id
                        }
                    }
                    ) || []
                }

                if (EnrollmentType.learner === enrollmentType) {
                    setPaginationLearner({ ...paginationLearner, total: res?.total, page });
                    if (page === 1) setListLearners(formattedData);
                    else setListLearners([...listLearners, ...formattedData]);

                } else {
                    setPaginationGroup({ ...paginationGroup, total: res?.total, page });
                    if (page === 1) setListLearningGroup(formattedData);
                    else setListLearningGroup([...listLearningGroups, ...formattedData]);
                }
            })
            .finally(() => {
                if (EnrollmentType.learner === enrollmentType) {
                    setLoadingLearner(false);
                } else {
                    setLoadingLearningGroup(false);
                }
            });
    };

    useEffect(() => {
        getListLearner(1)
    }, [inputSearchLearner]);
    useEffect(() => {
        getListEnroll(1, EnrollmentType.learningGroup);
    }, [inputSearchLearningGroup]);

    useEffect(() => {
        let listFilter: any = {
            q: keyword,
            state: statusSelected?.[0]?.value,
            overdue_tag: overdueSelected?.[0]?.value,
            lesson_ids: lessonSelected?.map(lesson => lesson?.id)?.join(','),
            learner_ids: learnerSelected?.map(learner => learner?.id)?.join(','),
            learning_group_ids: learningGroupSelected?.map(group => group?.id)?.join(','),
        };
        if (statusSelected.length === 4) delete listFilter.status
        if (!listFilter?.state) delete listFilter.state;
        if (!listFilter?.lesson_ids) delete listFilter.lesson_ids;
        if (!listFilter?.learning_group_ids) delete listFilter.learning_group_ids;
        if (!listFilter?.learner_ids) delete listFilter.learner_ids;
        if (!listFilter?.title) delete listFilter.title;

        onChangeFilter(listFilter);
    }, [learnerSelected, keyword, statusSelected, learningGroupSelected, lessonSelected, overdueSelected]);

    return (
        <>
            <div className="filterAssignmentCourse">
                <SearchBox placeholder={translatorCourse('SEARCH_GRADE')} onSubmit={(value) => setKeyword(value)} className='w-full max-w-[220px]' />
                <SelectBox
                    label={translatorCourse('LEANER')}
                    onSearch={setInputSearchLearner}
                    onSelect={setLearnerSelected}
                    data={listLearners}
                    loading={loadingLearner}
                    selectedItems={learnerSelected}
                    total={paginationLearner?.total}
                    showmore={() => getListLearner(paginationLearner?.page + 1)}
                />
                <SelectBox
                    label={translatorCourse('LEARNING_GROUP')}
                    onSearch={setInputSearchLearningGroup}
                    onSelect={setLearningGroupSelected}
                    data={listLearningGroups}
                    loading={loadingLearningGroup}
                    selectedItems={learningGroupSelected}
                    total={paginationGroup?.total}
                    showmore={() => getListEnroll(paginationGroup?.page + 1, EnrollmentType.learningGroup)}
                />
                <SelectBox
                    label={translatorCourse('STATUS')}
                    onSelect={setStatusSelected}
                    selectedItems={statusSelected}
                    data={[
                        { name: t("ASSIGNMENT_CENTER.ASSIGNED"), value: 'assigned' },
                        { name: t("ASSIGNMENT_CENTER.IN_PROGRESS"), value: 'in_progress' },
                        { name: t("ASSIGNMENT_CENTER.WAITING_FOR_GRADE"), value: 'waiting_for_grade' },
                        // { name: t("ASSIGNMENT_CENTER.OVERDUE"), value: 'overdue' },
                        { name: t("ASSIGNMENT_CENTER.COMPLETED"), value: 'completed' },
                    ]}
                    total={4}
                    isMulti={false}
                />
                <SelectBox
                    label={'Overdue'}
                    onSelect={setOverdueSelected}
                    selectedItems={overdueSelected}
                    isMulti={false}
                    data={[
                        { name: t('YES'), value: true },
                        { name: t('NO'), value: false },
                    ]}
                    total={2}
                />
                <SelectBox
                    label={translatorCourse('LESSON')}
                    onSearch={setInputSearchLesson}
                    selectedItems={lessonSelected}
                    data={lessonList}
                    total={pagingLesson?.total}
                    onSelect={setLessonSelected}
                    loading={loadingFilterLesson}
                    styles={{ container: "flex-nowrap" }}
                    showmore={() => getLessonList(pagingLesson?.page + 1)}
                />
            </div>
        </>
    );
};

export default Filter;
