import React, { useEffect, useState } from 'react';
import { CircleSpin } from 'components/UiComponents/CircleSpin';
import { useParams } from 'react-router-dom';
import { classNames, getIndexPage } from 'utils/commonFun';
import courseService from 'services/course.service';
import GradebookListTable from './GradebookListTable';
import './GradebookList.scss';
import Pagination from 'components/V2/Pagination';
import { GradebookListFilter } from './GradebookListFilter/GradebookListFilter';
import { useTranslation } from 'react-i18next';
import { useQueryParamsURL } from 'hooks/useQueryParamsURL';
import { useUrlCourseNavigation } from 'hooks/useUrlCourseNavigation';
import { CourseProgressHistorySubtab } from 'types/Courses.type';

const ListTab = (translator) => [
    { label: translator('COURSES.LEARNERS'), value: 'learner' },
    { label: translator('LEARNING_GROUPS'), value: 'learning-group' },
];

export const GradebookList = () => {
    const { t: translator } = useTranslation();
    const params: { id: string; courseId: string } = useParams();
    const query = useQueryParamsURL();
    const { redirectToUrlCourse } = useUrlCourseNavigation();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState('0-0');
    const [pagination, setPagination] = useState<any>({
        page: 1,
        per_page: 20,
        order: 'desc',
        sort_by: 'updated_at',
        total: 0,
    });

    const subtab = query.get('subtab');

    const getdata = (page: number | string = 1, filter = undefined) => {
        setLoading(true);
        const payload = {
            ...pagination,
            ...filter,
            page,
        };
        delete payload?.total;
        courseService
            .getGradebooks(params?.id, params?.courseId, payload)
            .then((res) => {
                const { items, total } = res.data;
                setData(items);
                setPagination({ ...pagination, ...payload, total });
                setIndexPage(getIndexPage(payload?.page, pagination?.per_page, items?.length));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const handlePagination = (page: number) => {
        getdata(page);
    };

    const handleFilter = (value) => {
        getdata(1, value);
    };

    useEffect(() => {
        if (!subtab) {
            redirectToUrlCourse({
                tab: 'gradebook',
                subtab: 'learner',
            });
        }
    }, [subtab]);

    return (
        <div className="CourseGradebookList">
            <h2 className="CourseGradebookList-title">{translator('COURSES.PROGGESS_HISTORY')}</h2>
            <div className='w-full text-xs flex gap-4 items-center border-b border-gray-300'>
                {ListTab(translator).map((i) => (
                    <div
                        key={i.value}
                        className={classNames(
                            'cursor-pointer  py-1 text-center font-semibold text-xs flex focus:outline-none',
                            subtab === i?.value ? 'text-primary-500 border-b-2 border-primary-500 ' : 'text-gray-500',
                        )}
                        onClick={() =>
                            redirectToUrlCourse({
                                tab: 'gradebook',
                                subtab: i?.value as CourseProgressHistorySubtab,
                            })
                        }
                    >
                        {i?.label}
                    </div>
                ))}
            </div>
            <div className="CourseGradebookList-filter">
                <GradebookListFilter onChangeFilter={handleFilter} tab={subtab as CourseProgressHistorySubtab} />
            </div>
            <div className="CourseGradebookList-table">
                {loading ? (
                    <div className="CourseGradebookList-table--loading">
                        <CircleSpin color="text-primary-500" />
                    </div>
                ) : (
                    <div className="w-full h-full">
                        <GradebookListTable data={data} refreshData={getdata} tab={subtab as CourseProgressHistorySubtab} />
                    </div>
                )}
            </div>
            <div className="CourseGradebookList-pagination">
                <Pagination
                    total={pagination.total}
                    per_page={pagination.per_page}
                    callback={(e) => handlePagination(e)}
                    indexPage={indexPage}
                    page={pagination?.page}
                />
            </div>
        </div>
    );
};
