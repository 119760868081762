import { EyeIcon, PencilAltIcon, PlusIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import CancelChanges from 'assets/img/cancel.png';
import SaveChanges from 'assets/img/save-changes.png';
import ActionsCircleButton from 'components/ActionsCircleButton';
import Modal from 'components/Modal';
import NotificationWithUndo from 'components/Notification/NotificationWIthUndo';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useBoolean } from 'hooks/custom';
import lodash, { debounce } from 'lodash';
import workspaceMiddleware from 'middleware/workspace.middleware';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { getLocalStorageAuthData } from 'utils/handleLocalStorage';
import { SectionState } from '../../../types/Lesson.type';
import ModalRename from '../CourseDetail/component/ModalRename';
import SectionH5P from '../Lesson/components/SectionH5P';
import { handleRecoverLesson } from '../Lesson/LessonFN';
import RenderLessonH5P from './component/RenderLessonH5P';
import { createNewLesson, handleRemoveLesson, updateLesson } from './LessonBuilderFN';
import './style.css';
import { ActionType, initialLesson, LessonBuilderType, lessonReducer, LESSON_ACTIONS } from './utils';
import workspaceService from 'services/workspace.service';
import TagFields from './component/TagField';
import ButtonComponent from 'components/Button/ButtonComponent';
import dayjs from 'dayjs';
import { UserContext } from 'contexts/User/UserContext';
import { NoContentWithImage } from 'components/NoContent/NoContent';
import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { Toastify } from '_shared';

const statusShadow: Record<any, string> = {
    draft: 'shadow-ooolab_lesson_status bg-ooolab_dark_50 ',
    pending: 'shadow-ooolab_lesson_status_pending bg-ooolab_warning',
    public: 'shadow-ooolab_alert_success bg-ooolab_alert_success',
};

const LessonBuilder: React.FC = ({ children }) => {
    const featureFlags = JSON.parse(process.env.REACT_APP_FEATURE_FLAGS);
    const { t: translator } = useTranslation();
    const userInfo = getLocalStorageAuthData();
    const params: { id: string; lessonId: string } = useParams();
    const history = useHistory();
    const { location } = history;
    const { state }:{state:any} = location;
    const { dispatch, getWorkspaceDetailState } = useContext(GetWorkspaceContext);

    const { currentLesson, tagList, isUpdatingLesson, updateStatus, tagResult, result: workspaceDetail } = getWorkspaceDetailState;
    const [lesson, dispatchLesson] = useReducer<React.Reducer<LessonBuilderType, ActionType>>(
        lessonReducer,
        initialLesson(params.lessonId)
    );
    const { booleanValue: isCreating, toggleBooleanValue: toggleIsCreating } = useBoolean();

    const {
        userState: { result },
    } = useContext(UserContext);

    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [modalRename, setModalRename] = useState(false);
    const [modalCreate, setModalCreate] = useState(false);
    const [modalCreateError, setModalCreateError] = useState(false);
    const [modalClose, setModalClose] = useState(false);
    const [modalDelete, setModalDelete] = useState(false);
    const [prevAdmin, setPrevAdmin] = useState(false);
    const [unitProgress, setUnitProgress] = useState<string>('');
    const [unitNumber, setUnitNumber] = useState<string>('');
    const [lessonNumber, setLessonNumber] = useState<string>('');
    const [lessonGoal, setLessonGoal] = useState<string>('');
    const [activeTab, setActiveTab] = useState<string>('DETAIL');
    const [errorLessonGoal, setErrorLessonGoal] = useState(false)

    const handleAddTags = (e: number) => {
        const item = tagList.items.find((i) => i.id === e);
        const tmp = [...(lesson.tags || []), item];
        dispatchLesson({
            type: LESSON_ACTIONS.ADD_TAGS,
            value: tmp,
        });
    };

    const handleRemoveTag = (e: number) => {
        const targetIndex = lesson?.tags.findIndex((i) => i.id === e);
        if (targetIndex !== -1) {
            const tmp = [...lesson.tags];
            tmp.splice(targetIndex, 1);
            dispatchLesson({
                type: LESSON_ACTIONS.REMOVE_TAGS,
                value: tmp,
            });
        }
    };

    const handleEditSection = (sec: SectionState) => {
        dispatchLesson({
            type: LESSON_ACTIONS.EDIT_SECTIONS,
            value: sec,
        });
    };

    const handleCreateNewLesson = useCallback(() => {
        if (!lessonGoal) {
            setErrorLessonGoal(true)
            return
        } 
        
        setErrorLessonGoal(false)

        toggleIsCreating();
        if (lesson.params.unit_progress === '')
            lesson.params = {
                ...lesson.params,
                unit_progress: null,
            };
            const tmpBody = {
                ...lesson.params,
                parent_id: state?.folderId ? parseInt(state?.folderId) : null,
            };
            !tmpBody?.parent_id && delete tmpBody?.parent_id;
        if (lesson.tags.length) {
            tmpBody.tags = lesson.tags.map((i) => ({ tag_id: i.id }));
        }
        const custom_fields = lesson.custom_fields.map((customField) => {
            return {
                custom_field_id: customField.id,
                data: customField.data,
            };
        });
        createNewLesson({
            workspaceId: params.id,
            body: tmpBody,
            successCallback: (id) => {
                workspaceService.upsertCustomfiled(params.id, id, { custom_fields }).then((res) => {
                    !modalSuccess && setModalSuccess(true);
                    dispatchLesson({
                        type: LESSON_ACTIONS.SET_STATUS,
                        value: null,
                    });
                });
                toggleIsCreating();
                setModalCreate(true);
                setTimeout(() => history.push(`/workspace/${params.id}/library`), 2000);
            },
            errorCallback: () => {
                setModalCreateError(true);
                toggleIsCreating();
            },
        });
    }, [lesson]);

    const handleSaveLesson = useCallback(() => {
        let deletedTags: number[] = [];
        let addedTags: number[] = [];

        if (!lessonGoal) {
            setErrorLessonGoal(true)
            return
        } 

        setErrorLessonGoal(false)

        //tags received from api
        const originListTags = currentLesson?.tags?.length ? currentLesson.tags.map((i) => i.id) : [];
        //tags user interacted before submitting
        const modifiedListTags = lesson?.tags?.map((i) => i.id) || [];

        if (!modifiedListTags.length && !originListTags.length) {
            deletedTags = [];
            addedTags = [];
        }

        if (!modifiedListTags.length && originListTags.length) {
            addedTags = [];
            deletedTags = originListTags;
        }

        if (modifiedListTags.length && !originListTags.length) {
            deletedTags = [];
            addedTags = modifiedListTags;
        }

        if (modifiedListTags.length && originListTags.length) {
            addedTags = [...lodash.difference(modifiedListTags, originListTags)];
            originListTags.forEach((i) => {
                if (!modifiedListTags.includes(i)) {
                    deletedTags.push(i);
                }
            });
        }
        if (lesson.params.unit_progress === '')
            lesson.params = {
                ...lesson.params,
                unit_progress: null,
            };
        updateLesson(
            dispatch,
            params.id,
            params.lessonId,
            {
                ...lesson.params,
            },
            {
                attachTags: {
                    tags: addedTags?.map((i) => ({ tag_id: i })),
                },
                detachTags: deletedTags,
            }
        );
        const custom_fields = lesson.custom_fields?.map((customField) => {
            return {
                custom_field_id: customField.id,
                data: customField.data,
            };
        });
        workspaceService.upsertCustomfiled(params.id, params.lessonId, { custom_fields }).then((res) => {
            !modalSuccess && setModalSuccess(true);
            dispatchLesson({
                type: LESSON_ACTIONS.SET_STATUS,
                value: null,
            });
        });
    }, [lesson]);

    const handleAddSection = () => {
        const tmp = { ...lesson };
        const { sections } = tmp;
        const newSections = {
            title: 'Untitled',
            files: [],
            description: ' ',
        };
        if (sections) {
            sections.push(newSections);
        } else {
            const newArr = [];
            newArr.push(newSections);
            tmp.sections = newArr;
        }
        dispatchLesson({
            type: LESSON_ACTIONS.ADD_SECTION,
            value: sections,
        });
    };

    const handleDeleteSection = useCallback(() => {
        dispatchLesson({
            type: LESSON_ACTIONS.DELETE_SECTIONS,
            value: lesson.sectionIndex,
        });
    }, [lesson.sectionIndex]);

    const handleMoveSections = useCallback(
        (dragIdx: number, hoverIdx: number) => {
            const tmpSelected = [...lesson.sections];
            const draggedLesson = lesson.sections[dragIdx];
            let tmp;
            if (dragIdx < hoverIdx) {
                tmp = [
                    ...tmpSelected.slice(0, dragIdx),
                    ...tmpSelected.slice(dragIdx + 1, hoverIdx + 1),
                    draggedLesson,
                    ...tmpSelected.slice(hoverIdx + 1),
                ];
            } else {
                tmp = [
                    ...tmpSelected.slice(0, hoverIdx),
                    draggedLesson,
                    ...tmpSelected.slice(hoverIdx, dragIdx),
                    ...tmpSelected.slice(dragIdx + 1),
                ];
            }
            dispatchLesson({
                type: LESSON_ACTIONS.MOVE_SECTIONS,
                value: tmp,
            });
        },
        [lesson.sections]
    );

    const handleClickLessonH5P = (e: SectionState, idx: number) => {
        dispatchLesson({
            type: LESSON_ACTIONS.SET_SECTION,
            value: {
                currentSection: {
                    description: e.description,
                    files: e.files?.map((j) => ({
                        ...j,
                    })),
                    title: e.title,
                    type: e.type,
                },
                sectionIndex: idx,
            },
        });
    };

    const checkUnitProgress = () => {
        return unitProgress ? !(+unitProgress >= 0 && +unitProgress <= 100) : false;
    };

    const debounceInput = useCallback(
        debounce((nextValue: string, asyncFunction: (e: string) => void) => {
            asyncFunction(nextValue);
        }, 1000),
        []
    );

    useEffect(() => {
        if (history.location?.state) {
            setPrevAdmin(false);
            const historyState: any = history.location.state;
            if (historyState?.prevPath) {
                if (historyState?.prevPath.split('/')[3] === 'admin') {
                    setPrevAdmin(true);
                }
            }
        }
        if (params.lessonId === 'new') {
            dispatchLesson({
                type: LESSON_ACTIONS.CAN_EDIT,
                value: true,
            });
        } else {
            workspaceMiddleware.getLessonDetail(dispatch, params.id, params.lessonId);
        }
        // if (workspaceDetail.id === -1) {
        //     workspaceMiddleware.getWorkspace(dispatch, {
        //         id: params.id,
        //     });
        // }
        return () => {
            workspaceMiddleware.setCurrentRouteDetail(dispatch, []);
            setModalSuccess(false);
        };
    }, []);

    useEffect(() => {
        if (currentLesson) {
            setUnitProgress(currentLesson?.unit_progress ?? '');
            setUnitNumber(currentLesson?.unit_number ?? '');
            setLessonNumber(currentLesson?.lesson_number ?? '');
            setLessonGoal(currentLesson?.lesson_goal ?? '');
            dispatchLesson({
                type: LESSON_ACTIONS.SET_LESSONS_FROM_LESSON,
                value: currentLesson.sections || [],
            });

            dispatchLesson({
                type: LESSON_ACTIONS.SET_TAGS,
                value: currentLesson.tags,
            });
            if (workspaceDetail.id !== -1) {
                const {
                    membership: { user_id: currentUserId, role },
                } = workspaceDetail;
                // dispatchLesson({
                //     type: LESSON_ACTIONS.CAN_EDIT,
                //     value:
                //         currentLesson.status === 'draft' &&
                //         (currentLesson.created_by.id === currentUserId ||
                //             role.toLocaleLowerCase() === 'admin'),
                // });
                dispatchLesson({
                    type: LESSON_ACTIONS.CAN_EDIT,
                    value: true,
                });
            }
            workspaceMiddleware.setCurrentRouteDetail(dispatch, [{ name: currentLesson.title, routeId: 'lessonId' }]);
        } else {
            workspaceMiddleware.setCurrentRouteDetail(dispatch, [{ name: 'New', routeId: 'lessonId' }]);
        }
    }, [currentLesson]);

    useEffect(() => {
        if (updateStatus === 'done') {
            setTimeout(() => {
                workspaceMiddleware.getLessonDetail(dispatch, params.id, params.lessonId);
                dispatchLesson({
                    type: LESSON_ACTIONS.RESET,
                    value: 'init',
                });
            }, 750);
            !modalSuccess && setModalSuccess(true);
        } else if (updateStatus === 'error') {
            setModalError(true);
        }
    }, [updateStatus, tagResult]);

    useEffect(() => {
        if (workspaceDetail.id !== -1 && params.lessonId !== 'new') {
            const {
                membership: { user_id: currentUserId, role },
            } = workspaceDetail;
            // dispatchLesson({
            //     type: LESSON_ACTIONS.CAN_EDIT,
            //     value:
            //         currentLesson?.status === 'draft' &&
            //         (currentLesson?.created_by.id === currentUserId ||
            //             role.toLocaleLowerCase() === 'admin'),
            // });
            dispatchLesson({
                type: LESSON_ACTIONS.CAN_EDIT,
                value: true,
            });
            dispatchLesson({
                type: LESSON_ACTIONS.CAN_PUBLISH,
                value: role.toLocaleLowerCase() === 'admin',
            });
        }
    }, [workspaceDetail]);

    const getLessonCustomField = () => {
        workspaceService.getLessonCustomFieldService(params.id, params.lessonId).then((res: any) => {
            dispatchLesson({
                type: LESSON_ACTIONS.SET_CUSTOM_FIELD_LIST,
                value: res.data,
                status: 'init',
            });
        });
    };

    useEffect(() => {
        if (params.lessonId === 'new') {
            workspaceService.getCustomFieldsService(params.id, { type: 'lesson', skip_pagination: true }).then((res: any) => {
                const data = res.data.items.map((item) => {
                    return {
                        data: [],
                        name: item.name,
                        id: item.id,
                    };
                });
                dispatchLesson({
                    type: LESSON_ACTIONS.SET_CUSTOM_FIELD_LIST,
                    value: data,
                    status: 'init',
                });
            });
        } else getLessonCustomField();
    }, []);

    const uploadTosen = () => {
        workspaceService
            .uploadToSen(params.id, params.lessonId)
            .then((res) => {
                Toastify.success('Upload successfully!');
                setTimeout(() => {
                    workspaceMiddleware.getLessonDetail(dispatch, params.id, params.lessonId);
                }, 1500);
            })
            .catch(() => Toastify.error(' upload failed'));
    };

    const handleSyncToSen = async () => {
        if (checkUnitProgress() || lesson.status !== 'touched') {
            uploadTosen();
        } else {
            await handleSaveLesson();
            uploadTosen();
        }
    };

    return (
        <div className="w-full h-screen">
            <PageBreadcrumb
                pageNameDefault={translator(
                    'DASHBOARD.SIDEBAR.CONTENT_MANAGEMENT'
                )}
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.LIBRARY'), href: `/workspace/${params.id}/library`, className: '' },
                    { name: currentLesson ? currentLesson.title : translator('LESSON.NEW_LESSON'), href: ``, className: '' },
                ]}
                containerClassName=" z-10 sticky top-0"
            />
            <div className="flex items-center justify-end p-4">
                <div className="col-span-2 flex justify-end items-center ">
                    {/* <button
                        onClick={() => handleEditLesson()}
                        disabled={lesson.status !== 'touched'}
                        className={`${
                            lesson.status !== 'touched'
                                ? 'bg-ooolab_dark_50 text-white cursor-not-allowed'
                                : 'bg-primary-500 text-white'
                        } relative invisible inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 mr-2`}
                    >
                        {translator('LESSON.SAVE')}
                    </button> */}
                    {!isUpdatingLesson && updateStatus === 'done' && lesson.status === 'init' ? (
                        <p className="text-ooolab_dark_2 text-xs mr-2">{translator('LESSON.ALL_CHANGES_SAVED')}</p>
                    ) : null}
                    {lesson.canEdit && params.lessonId !== 'new' && (
                        <div className="flex items-center justify-end">
                            {featureFlags?.isSyncToSen ? (
                                <>
                                    {currentLesson?.syncing_info && currentLesson?.syncing_info?.sync_success_at ? (
                                        <div className="text-xs font-medium text-[#8F90A6] mr-5">
                                            Last Sync:{' '}
                                            {dayjs(currentLesson?.syncing_info.sync_success_at)
                                                .tz(result?.time_zone)
                                                .format('DD MMM YYYY HH:mm')}
                                        </div>
                                    ) : null}
                                    <button
                                        onClick={handleSyncToSen}
                                        className={` bg-primary-500 text-white relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md  focus:outline-none focus:ring-2 mr-4`}
                                    >
                                        Sync to SEN
                                    </button>
                                </>
                            ) : null}

                            <button
                                onClick={handleSaveLesson}
                                disabled={checkUnitProgress() || lesson.status !== 'touched'}
                                className={`${
                                    checkUnitProgress() || lesson.status !== 'touched'
                                        ? 'bg-ooolab_dark_50 text-white cursor-not-allowed'
                                        : 'bg-primary-500 text-white'
                                } relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 mr-2`}
                            >
                                {translator('LESSON.SAVE')}
                            </button>
                            <div className="w-8 h-8 rounded-full  ml-4 flex justify-center items-center group hover:shadow-ooolab_box_shadow_2 ">
                                <XIcon
                                    className="w-5 h-5 text-ooolab_dark_2 cursor-pointer focus:outline-none  group-hover:text-primary-500"
                                    onClick={() => setModalClose(true)}
                                />
                            </div>
                        </div>
                    )}
                    {params.lessonId === 'new' && (
                        <div className="flex items-center justify-end mx-4 w-full">
                            <ButtonComponent
                                title={translator('LESSON.SAVE_LESSON')}
                                disable={lesson.status !== 'touched' || isCreating}
                                classStyle="primary-button disabled:!bg-gray-400 disabled:!text-white"
                                onClickButton={() => handleCreateNewLesson()}
                            />


                            {/* <button
                                onClick={() => handleCreateNewLesson()}
                                className={`${
                                    lesson.status !== 'touched'
                                        ? 'bg-ooolab_dark_50 text-white cursor-not-allowed'
                                        : 'bg-primary-500 text-white'
                                } relative w-fit inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500 mr-2`}
                            >
                                {translator('LESSON.SAVE_LESSON')}
                            </button> */}
                            <div className="w-8 h-8 rounded-full  ml-4 flex justify-center items-center group hover:shadow-ooolab_box_shadow_2 ">
                                <XIcon
                                    className="w-5 h-5 text-ooolab_dark_2 cursor-pointer focus:outline-none  group-hover:text-primary-500"
                                    onClick={() => setModalClose(true)}
                                />
                            </div>
                        </div>
                    )}

                    {/* <PublishLesson
                        status={currentLesson?.status}
                        canPublish={lesson.canPublish}
                    /> */}
                </div>
            </div>
            <div className="h-[calc(100vh-76px)] grid auto-rows-max grid-cols-6 pb-2 mx-2 border-t">
                {/* Search bar */}
                <div className="col-span-2 h-full border-r px-2 py-2 overflow-y-auto custom-scrollbar max-h-[calc(100vh-76px)]">
                    <div className="space-y-4">
                        {/* title lesson */}
                        <div className="flex justify-between">
                            {/* <input
                                id="lesson-name-input"
                                className="focus:outline-none disabled:bg-white disabled:cursor-not-allowed border-b border-white focus:border-ooolab_gray_2"
                                onChange={(e) => {
                                    debounceInput(e.target.value, (p: string) =>
                                        dispatchLesson({
                                            type: LESSON_ACTIONS.SET_TITLE,
                                            value: p,
                                        })
                                    );
                                }}
                            /> */}
                            <span>{lesson.params.title || currentLesson?.title}</span>
                            {/* <p className="capitalize flex items-center">
                                <span
                                    className={`w-ooolab_w_2_root h-ooolab_h_2 rounded-full mr-ooolab_m_2  ${
                                        statusShadow[
                                            currentLesson?.status || 'draft'
                                        ]
                                    }`}
                                />
                                <span>
                                    {StatusContent(
                                        translator,
                                        currentLesson?.status
                                    )}
                                </span>
                            </p> */}
                        </div>

                        {/* lesson tools */}
                        <div className="margin-0 text-ooolab_dark_2">
                            {params.lessonId !== 'new' ? (
                                <ActionsCircleButton
                                    onclick={() => history.push(`/workspace/${params.id}/lesson/${params.lessonId}/preview`)}
                                    icon={<EyeIcon className="w-4 h-4" />}
                                />
                            ) : null}
                            {/* {(params.lessonId === 'new' ||
                                currentLesson?.status === 'draft') && (
                                <ActionsCircleButton
                                    icon={
                                        <PencilAltIcon
                                            onClick={() => setModalRename(true)}
                                            className="w-4 h-4"
                                        />
                                    }
                                />
                            )} */}
                            {/* {params.lessonId !== 'new' && (
                                <ActionsCircleButton
                                    icon={
                                        <DownloadIcon
                                            onClick={() =>
                                                workspaceService.downloadLesson(
                                                    params.id,
                                                    params.lessonId
                                                )
                                            }
                                            className="w-4 h-4"
                                        />
                                    }
                                />
                            )} */}

                            <ActionsCircleButton icon={<PencilAltIcon onClick={() => setModalRename(true)} className="w-4 h-4" />} />
                            {/* {((currentLesson?.status !== 'public' &&
                            )}
                            {((currentLesson?.status !== 'public' &&
                                currentLesson?.created_by.id ===
                                    workspaceDetail.membership.user_id) ||
                                workspaceDetail.membership.role === 'admin') &&
                            params.lessonId !== 'new' ? (
                                <ActionsCircleButton
                                    onclick={() => setModalDelete(true)}
                                    icon={<TrashIcon className="w-4 h-4" />}
                                />
                            ) : null} */}
                            {params.lessonId !== 'new' && (
                                <ActionsCircleButton onclick={() => setModalDelete(true)} icon={<TrashIcon className="w-4 h-4" />} />
                            )}
                        </div>

                        <div className="flex mb-4">
                            <ButtonComponent
                                title="Detail"
                                classStyle={`px-3 py-1 border border-ooolab_bar_color rounded-full font-semibold text-sm  ${
                                    activeTab === 'DETAIL' ? 'bg-primary-500  text-white' : 'text-dark-100'
                                }`}
                                onClickButton={() => setActiveTab('DETAIL')}
                            />

                            <ButtonComponent
                                title="Tags"
                                classStyle={`ml-2 px-3 py-1 border border-ooolab_bar_color rounded-full font-semibold text-sm ${
                                    activeTab === 'TAGS' ? 'bg-primary-500  text-white' : 'text-dark-100'
                                }`}
                                onClickButton={() => setActiveTab('TAGS')}
                            />
                        </div>

                        {activeTab === 'DETAIL' && (
                            <>
                                {/* author */}
                                <div className="w-full min-h-18 max-h-30 border-ooolab_bar_color border rounded-lg flex flex-wrap pb-2">
                                    <label
                                        className="text-sm text-ooolab_dark_1 font-semibold border-b border-r border-ooolab_bar_color h-8 px-2 rounded-lg inline-flex items-center py-auto mr-2"
                                        htmlFor="author"
                                    >
                                        {translator('LESSON.AUTHOR')}
                                    </label>
                                    <div className="flex items-center space-x-1">
                                        <img
                                            className="w-5 h-5 rounded-full"
                                            src={
                                                params.lessonId === 'new' ? userInfo.avatar_url : currentLesson?.created_by.avatar_url
                                            }
                                            alt="img"
                                        />
                                        <p className="text-ooolab_dark_1 truncate">{currentLesson?.created_by.display_name || ''}</p>
                                    </div>
                                </div>

                                {params.lessonId !== 'new' && (
                                    <div className="w-full min-h-18 max-h-30 border-ooolab_bar_color border rounded-lg flex flex-wrap pb-2">
                                        <label
                                            className="text-sm text-ooolab_dark_1 font-semibold border-b border-r border-ooolab_bar_color h-8 px-2 rounded-lg inline-flex items-center py-auto mr-2"
                                            htmlFor="author"
                                        >
                                            {translator('LESSON.CREATED_ON')}
                                        </label>
                                        <div className="flex items-center space-x-1">
                                            <p className="text-ooolab_dark_1 truncate">
                                                {dayjs(currentLesson?.created_at).format('MM/DD/YYYY')}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                {/* Unit */}
                                <div className="w-full min-h-18 max-h-30 border-ooolab_bar_color border rounded-lg flex flex-wrap pb-2">
                                    <p className="text-sm text-ooolab_dark_1 font-semibold border-b border-r border-ooolab_bar_color h-8 px-2 rounded-lg inline-flex items-center py-auto mr-2">
                                        {translator('LESSON.UNIT')}
                                    </p>
                                    <input
                                        type="text"
                                        pattern="[0-9]*"
                                        placeholder={translator('LESSON.ENTER_UNIT_NUMBER')}
                                        value={unitNumber}
                                        onInput={(e: any) => {
                                            const valueChange = e.target.validity.valid ? e.target.value : unitNumber;
                                            setUnitNumber(valueChange);
                                            dispatchLesson({
                                                type: LESSON_ACTIONS.SET_UNIT_NUMBER,
                                                value: valueChange === '' ? null : valueChange,
                                            });
                                        }}
                                        // maxLength={10}
                                        className="h-8 inline-flex items-end w-[calc(100%-55px)]"
                                    />
                                </div>

                               
                                {/* Lesson Goal */}
                                <div className="w-full min-h-18 h-full border-ooolab_bar_color border rounded-lg pb-2 relative">
                                    <p className=" bg-white absolute left-0 top-0 text-sm text-ooolab_dark_1 font-semibold border-b border-r border-ooolab_bar_color h-8 px-2 rounded-lg inline-flex items-center py-auto mr-2">
                                        {translator('LESSON.LESSON_GOAL')}
                                    </p>
                                    <textarea
                                        placeholder={translator('LESSON.ENTER_LESSON_GOAL')}
                                        value={lessonGoal}
                                        onInput={(e: any) => {
                                            const valueChange = e.target.validity.valid ? e.target.value : lessonGoal;
                                            setLessonGoal(valueChange);
                                            dispatchLesson({
                                                type: LESSON_ACTIONS.SET_LESSON_GOAL,
                                                value: valueChange === '' ? null : valueChange,
                                            });
                                        }}
                                        rows={2}
                                        maxLength={200}
                                        className=" focus:outline-none px-2 py-1 w-full"
                                        style={{textIndent: 100}}
                                    />
                                </div>
                                {errorLessonGoal && <span className='text-error-500 text-xs mt-1'>{translator('FORM_CONST.REQUIRED_FIELD')}</span>}
                            </>
                        )}
                    </div>

                    {activeTab === 'TAGS' &&
                        (lesson.custom_fields.length > 0 ? (
                            lesson.custom_fields.map((customField) => {
                                const fieldName = customField.name?.split(' ')?.join('_').trim();
                                return (
                                    <TagFields
                                        key={customField.id}
                                        title={customField.name}
                                        translator={translator}
                                        id={customField.id}
                                        data={customField?.data || []}
                                        dispatchLesson={dispatchLesson}
                                        customFields={lesson.custom_fields}
                                    />
                                );
                            })
                        ) : (
                            <NoContentWithImage content={translator("TAG_FIELD.NO_TAG_FIELDS")} />
                        ))}

                    {/* Section */}
                    {activeTab === 'DETAIL' && (
                        <div className="w-full mt-8 relative">
                            <div className="flex justify-between sticky top-0 bg-white left-0 w-full ">
                                <p>
                                    <span>{translator('LESSON.SECTIONS')}</span>
                                    <span className="ml-ooolab_m_3 bg-ooolab_light_blue_0 p-ooolab_p_2 rounded-full w-ooolab_w_6 h-ooolab_h_6 inline-flex justify-center items-center">
                                        {lesson.sections?.length || 0}
                                    </span>
                                </p>
                                {lesson.canEdit && (
                                    <div
                                        onClick={() => handleAddSection()}
                                        className="flex justify-center items-center focus:outline-none w-8 h-6 rounded-md border cursor-pointer"
                                    >
                                        <PlusIcon className="w-4 h-4" />
                                    </div>
                                )}
                            </div>
                            <div className="py-ooolab_p_2 overflow-y-scroll custom-scrollbar max-h-96 mt-4">
                                {lesson.sections?.map((i, index) => (
                                    <RenderLessonH5P
                                        key={`${i.title}-${index}`}
                                        data={i}
                                        index={index}
                                        currentSectionIndex={lesson.sectionIndex}
                                        onClickLessonH5p={handleClickLessonH5P}
                                        moveCard={handleMoveSections}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className="col-span-4 h-full px-2 py-2 relative overflow-y-auto custom-scrollbar max-h-[calc(100vh-76px)]">
                    <SectionH5P
                        onEdit={handleEditSection}
                        onDelete={handleDeleteSection}
                        section={lesson.currentSection}
                        isActive={lesson.sectionIndex !== -1}
                        isEditable={lesson.canEdit}
                    />
                </div>
            </div>
            <ModalRename
                title={lesson.params.title || currentLesson?.title || ''}
                isOpen={modalRename}
                onClose={() => setModalRename(false)}
                onConfirm={(e) => {
                    dispatchLesson({
                        type: LESSON_ACTIONS.SET_TITLE,
                        value: e,
                    });
                }}
            />
            <Modal
                isOpen={modalSuccess}
                onClose={() => setModalSuccess(false)}
                title={translator('MODALS.SUCCESS.SAVED_LESSON')}
                imgSrc={SaveChanges}
            />
            {/* <Modal
                isOpen={modalError}
                onClose={() => setModalError(false)}
                title={translator('MODALS.ERRORS.SAVING_FAIL')}
                imgSrc={CancelChanges}
            /> */}
            {/* lesson creates successfully */}
            <Modal
                isOpen={modalCreate}
                onClose={() => history.push(`/workspace/${params.id}/lesson`)}
                closable={false}
                title={
                    <div className="mb-ooolab_m_1">
                        <p>{translator('MODALS.SUCCESS.LESSON_CREATE')}</p>
                        <p className="text-ooolab_dark_2 text-ooolab_xs mt-ooolab_m_2">
                            {translator('MODALS.SUCCESS.LESSON_REDIRECT_TO_LIST')}
                        </p>
                    </div>
                }
                imgSrc={SaveChanges}
            />

            {/* Create lesson fail */}
            <Modal
                isOpen={modalCreateError}
                onClose={() => setModalCreateError(false)}
                closable={false}
                title={translator('MODALS.ERRORS.LESSON_CREATE')}
                imgSrc={CancelChanges}
                mainBtn={
                    <button
                        onClick={() => setModalCreateError(false)}
                        className="focus:outline-none text-ooolab_sm bg-red-500 text-white shadow-ooolab_login_1 px-ooolab_p_3 py-ooolab_p_1_e rounded-lg"
                    >
                        {translator('MODALS.CLOSE')}
                    </button>
                }
            />
            <Modal
                isOpen={modalClose}
                onClose={() => setModalClose(false)}
                closable={false}
                title={translator('MODALS.BTN_CLOSE')}
                imgSrc={CancelChanges}
                mainBtn={
                    <button
                        onClick={() => history.push(`/workspace/${params.id}/lesson`)}
                        className="focus:outline-none text-sm bg-red-500 text-white shadow-ooolab_login_1 px-3 py-1 rounded-md"
                    >
                        {translator('MODALS.BTN_SUBMIT')}
                    </button>
                }
                subBtn={
                    <button
                        onClick={() => setModalClose(false)}
                        className="focus:outline-none text-sm text-black shadow-ooolab_login_1 px-3 py-1 rounded-md"
                    >
                        {translator('MODALS.BTN_CANCEL')}
                    </button>
                }
            />
            <Modal
                isOpen={modalDelete}
                onClose={() => setModalDelete(false)}
                closable
                title={translator('MODALS.CONFIRM_DELETE_MODAL.TITLE_TEXT')}
                imgSrc={CancelChanges}
                mainBtn={
                    <button
                        onClick={() =>
                            handleRemoveLesson(
                                params.id,
                                params.lessonId,
                                () => {
                                    history.push(`/workspace/${params.id}/lesson`);
                                    toast(
                                        <NotificationWithUndo
                                            onClickCancel={() => handleRecoverLesson(dispatch, params.id, params.lessonId)}
                                            imageContent={<TrashIcon className="bg-primary-500 text-white" />}
                                            textContent={translator('MODALS.SUCCESS.REMOVE_LESSON')}
                                            type="success"
                                        />,
                                        {
                                            closeOnClick: false,
                                            autoClose: 5000,
                                            hideProgressBar: true,
                                            closeButton: false,
                                            bodyStyle: {
                                                padding: 0,
                                            },
                                            delay: 500,
                                            position: 'bottom-left',
                                            className: 'shadow-ooolab_box_shadow_2 min-w-ooolab_w_80',
                                        }
                                    );
                                },
                                () => {}
                            )
                        }
                        className="focus:outline-none text-ooolab_sm bg-red-500 text-white shadow-ooolab_login_1 px-ooolab_p_3 py-ooolab_p_1_e rounded-lg"
                    >
                        {translator('MODALS.CONFIRM_DELETE_MODAL.YES_DO_IT')}
                    </button>
                }
                subBtn={
                    <button
                        onClick={() => setModalDelete(false)}
                        className="focus:outline-none text-ooolab_sm text-black shadow-ooolab_login_1 px-ooolab_p_3 py-ooolab_p_1_e rounded-lg"
                    >
                        {translator('MODALS.CONFIRM_DELETE_MODAL.NO_CANCEL')}
                    </button>
                }
            />

            <Prompt
                when={lesson.status === 'touched' && !modalCreate}
                message={() => {
                    return `${translator('MODALS.UNSAVED_PROMPT')}`;
                }}
            />
            <ToastContainer />
        </div>
    );
};

export default LessonBuilder;
