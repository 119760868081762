import PageBreadcrumb from 'components/Breadcrumb/PageBreadcrumb';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import workspaceService from 'services/workspace.service';
import Status from '../InvoiceList/components/Status';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import ModalCancelnvoice from '../InvoiceList/components/ModalCancelnvoice';
import Information from './Information';
import { HANDLE_ACTION_ENUM, INVOICE_ACTION_BY_STATUS, INVOICE_TYPE, RECURRING_INVOICE_STATUS_ENUM } from '../constants';
import TableInvoice from '../InvoiceList/AllInvoices/Table';
import Pagination from 'components/V2/Pagination';
import { getIndexPage } from 'utils/commonFun';
import './style.scss';
import File from './File';
import DialogComponent from 'components/Dialog/DialogComponent';
import CreateSingleInvoice from '../InvoiceList/CreateInvoice/CreateSingleInvoice';
import { checkPermission } from 'utils/commonFun';

export default function UserDetail({ children }) {
    const { t: translator } = useTranslation();
    const params = useParams<{ id: string; invoiceId: string }>();
    const [invoiceDetail, setInvoiceDetail] = useState(undefined);
    const [transactions, setTransaction] = useState([]);
    const [isOpenModalCancelInvoice, setOpenModalCancelInvoice] = useState(false);
    const search = useLocation()?.search;
    const invoiceType = new URLSearchParams(search).get('type');
    const [loading, setLoading] = useState(false);
    const [indexPage, setIndexPage] = useState<string>('1-50');
    const [isOpenModalUpdateInvoice, setOpenModalUpdateInvoice] = useState(false);
    const [pagination, setPagination] = useState({
        per_page: 20,
        total: undefined,
        page: 1,
        sort_by: 'schedule_at',
        order: 'desc',
    });

    const getInvoiceDetail = () => {
        workspaceService.getInvoiceDetail(params?.id, params?.invoiceId).then((res) => {
            setInvoiceDetail(res?.data);
        });
    };

    const getRecurringInvoiceDetail = () => {
        workspaceService.getRecurringInvoiceDetail(params?.id, params?.invoiceId).then((res) => {
            setInvoiceDetail(res?.data);
        });
    };

    const onRefreshData = () => {
        if (invoiceType === INVOICE_TYPE.single) {
            getInvoiceDetail();
        }
        if (invoiceType === INVOICE_TYPE.recurring) {
            getRecurringInvoiceDetail();
            getTransactionOfRecurringInvoice();
        }
    }

    const getTransactionOfRecurringInvoice = (page = 1) => {
        setLoading(true);
        workspaceService
            .getTransactionOfRecurringInvoice(params?.id, { page, recurring_invoice_id: params?.invoiceId })
            .then((res) => {
                const { items, page, total } = res?.data;
                setTransaction(items);
                setPagination({ ...pagination, page, total });
                setIndexPage(getIndexPage(page, pagination?.per_page, items?.length));
                setLoading(false);
            });
    };

    useEffect(() => {
        if (invoiceType === INVOICE_TYPE.single) {
            getInvoiceDetail();
        }
        if (invoiceType === INVOICE_TYPE.recurring) {
            getRecurringInvoiceDetail();
            getTransactionOfRecurringInvoice();
        }
    }, [invoiceType]);

    const handlePagination = (page: number) => {
        getTransactionOfRecurringInvoice(page);
    };

    const handleActionInvoice = (action) => {
        const invoice = {
            invoice_id: invoiceDetail?.id,
            invoice_type: invoiceDetail?.STATUS === RECURRING_INVOICE_STATUS_ENUM.active ? INVOICE_TYPE.recurring : INVOICE_TYPE.single,
            action,
        };
        workspaceService
            .actionInvoice(params?.id, invoice)
            .then(() => {
                let message = ''
                if (action === HANDLE_ACTION_ENUM.send_invoice) message = translator('SEND_INVOICE_SUCCESS');
                if (action === HANDLE_ACTION_ENUM.resend_invoice) message = translator('RESEND_INVOICE_SUCCESS');
                if (action === HANDLE_ACTION_ENUM.create_receipt) message = translator('CREATE_RECEIPT_INVOICE_SUCCESS');
                if (action === HANDLE_ACTION_ENUM.refund) message = translator('REFUND_INVOICE_SUCCESS');
                Toastify.success(message);
                setTimeout(() => {
                    getInvoiceDetail();
                }, 2000)
            })
            .catch(() => Toastify.error());
    };

    return (
        <div className="InvoiceDetail">
            <PageBreadcrumb
                pageNameDefault={translator('DASHBOARD.SIDEBAR.ECOMMERCE')}
                containerClassName=" z-10 sticky top-0"
                pages={[
                    { name: translator('DASHBOARD.SIDEBAR.DASHBOARD'), href: `/workspace/${params?.id}/e-commerce/billing?tab=all-invoices`, className: '' },
                    { name: invoiceDetail?.name || params?.invoiceId, href: '', className: '' },
                ]}
            />
            <div className="InvoiceDetail-StatusBar">
                <div className="InvoiceDetail-StatusBar--status">
                    <div className="name">{invoiceDetail?.name || `Invoice ID: ${params?.invoiceId}`}</div>
                    <Status invoiceId={invoiceDetail?.id} status={invoiceDetail?.display_status} translator={translator} isShowAction={false} />
                </div>
                {checkPermission('invoice', 'U') && <div className="InvoiceDetail-StatusBar--action">
                    {INVOICE_ACTION_BY_STATUS.edit.includes(invoiceDetail?.display_status) && (
                        <ButtonPrimary onClick={() => setOpenModalUpdateInvoice(true)} type="button">
                            {translator('EDIT_INVOICE')}
                        </ButtonPrimary>
                    )}
                    {INVOICE_ACTION_BY_STATUS.sent_invoice.includes(invoiceDetail?.display_status) && (
                        <ButtonPrimary onClick={() => handleActionInvoice(HANDLE_ACTION_ENUM.send_invoice)} type="button">
                            {translator('SEND_INVOICE')}
                        </ButtonPrimary>
                    )}
                    {INVOICE_ACTION_BY_STATUS.resent_invoice.includes(invoiceDetail?.display_status) && (
                        <ButtonPrimary onClick={() => handleActionInvoice(HANDLE_ACTION_ENUM.resend_invoice)} type="button">
                            {translator('RESEND_INVOICE')}
                        </ButtonPrimary>
                    )}
                    {INVOICE_ACTION_BY_STATUS.refund.includes(invoiceDetail?.display_status) && (
                        <ButtonPrimary
                            onClick={() => handleActionInvoice(HANDLE_ACTION_ENUM.refund)}
                            type="button"
                        >
                            {translator('REFUND_BTN')}
                        </ButtonPrimary>
                    )}
                    {INVOICE_ACTION_BY_STATUS.create_receipt.includes(invoiceDetail?.display_status) && (
                        <ButtonOutline
                            className="!text-primary-500 !border-primary-500"
                            onClick={() => handleActionInvoice(HANDLE_ACTION_ENUM.create_receipt)}
                            type="button"
                        >
                            {translator('CREATE_RECIEPT')}
                        </ButtonOutline>
                    )}

                    {(INVOICE_ACTION_BY_STATUS.cancel.includes(invoiceDetail?.display_status) || invoiceDetail?.display_status === RECURRING_INVOICE_STATUS_ENUM.active) && (
                        <ButtonOutline
                            className="!text-red-500 !border-red-500"
                            onClick={() => setOpenModalCancelInvoice(true)}
                            type="button"
                        >
                            {translator('CANCEL_INVOICE_BTN')}
                        </ButtonOutline>
                    )}
                </div>}

            </div>

            <div className="InvoiceDetail-information">
                <Information invoice={invoiceDetail} refreshData={onRefreshData} />
            </div>

            {invoiceType === INVOICE_TYPE.single && (
                <div className="InvoiceDetail-doc">
                    <div className="InvoiceDetail-doc--invoice h-fit">
                        <div className="title">{translator('INVOICE')}</div>
                        <div className="mt-5">
                            {invoiceDetail?.generated_files?.filter((file) => file?.file_type === 'manual_invoice')?.length ? (
                                <div className='flex flex-col gap-4'>
                                    {
                                        invoiceDetail?.generated_files?.filter((file) => file?.file_type === 'manual_invoice')?.map((file) => {
                                            return (
                                                <File
                                                    file={{
                                                        url: file?.file_url,
                                                        name: file?.file_name,
                                                        type: 'application/pdf'
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="no-data">{translator('NO_INVOICE')}</div>
                            )}
                        </div>
                    </div>
                    <div className="InvoiceDetail-doc--invoice h-fit">
                        <div className="title">{translator('RECEIPT')}</div>
                        <div className="mt-5">
                            {invoiceDetail?.generated_files?.filter((file) => file?.file_type === 'purchase_receipt' || file?.file_type === 'manual_receipt')?.length ? (
                                <div className='flex flex-col gap-4'>
                                    {
                                        invoiceDetail?.generated_files?.filter((file) => file?.file_type === 'purchase_receipt' || file?.file_type === 'manual_receipt')?.map((file) => {
                                            return (
                                                <File
                                                    file={{
                                                        url: file?.file_url,
                                                        name: file?.file_name,
                                                        type: 'application/pdf'
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            ) : (
                                <div className="no-data">{translator('NO_RECIEPT')}</div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {invoiceType === INVOICE_TYPE.recurring && (
                <div className="InvoiceDetail-information">
                    <div className="transaction-table">
                        <div className="title">{translator('INVOICES')}</div>
                        <div className='table-invoice relative'>
                            <TableInvoice 
                                dataTable={transactions} 
                                workspaceId={params?.id} 
                                translator={translator} 
                                refreshData={() => {
                                    getRecurringInvoiceDetail();
                                    getTransactionOfRecurringInvoice();
                                }}
                            />
                        </div>
                    </div>
                    <Pagination
                        indexPage={indexPage}
                        page={pagination?.page}
                        per_page={pagination?.per_page}
                        total={pagination.total}
                        callback={handlePagination}
                    />
                </div>
            )}

            <ModalCancelnvoice
                isOpen={isOpenModalCancelInvoice}
                onClose={() => setOpenModalCancelInvoice(false)}
                refreshData={() => {
                    if (invoiceType === INVOICE_TYPE.recurring) {
                        getRecurringInvoiceDetail();
                        getTransactionOfRecurringInvoice();
                        return;
                    }
                    getInvoiceDetail()
                }}
                invoice={{
                    invoice_id: invoiceDetail?.id,
                    invoice_type: invoiceType === INVOICE_TYPE.recurring ? INVOICE_TYPE.recurring : INVOICE_TYPE.single,
                    action: HANDLE_ACTION_ENUM.cancel
                }}
            />

            <DialogComponent
                isOpen={isOpenModalUpdateInvoice}
                onCloseModal={() => setOpenModalUpdateInvoice(false)}
                title={translator('EDIT_SINGLE_INVOICE')}
                child={
                    <CreateSingleInvoice
                        invoiceId={invoiceDetail?.id}
                        onClose={() => setOpenModalUpdateInvoice(false)}
                        onRefreshData={getInvoiceDetail}
                    />
                }
            />
        </div>
    );
}
