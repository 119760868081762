import { FC, useContext, useMemo } from 'react';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { useTranslation } from 'react-i18next';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { useHistory, useParams } from 'react-router-dom';
import ActionMenu from './ActionMenu';

interface CourseSkillsListProps {
    data: any[];
    refreshData?: Function;
    isTemplate?: boolean
}

const CourseSkillsList: FC<CourseSkillsListProps> = ({
    data, refreshData, isTemplate = false
}) => {
    const { t: translator } = useTranslation();
    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;

    const initialState = {
        hiddenColumns: ['id'],
    };

    const columns = useMemo(
        () => [
            {
                Header: () => <RenderTableHeader value={translator('SKILL')} />,
                Cell: ({ row }) => {
                    return <RenderTableCell value={row?.original?.skill_level?.skill?.name} />;
                },
                accessor: 'skill_level',
            },
            {
                Header: () => <RenderTableHeader value={translator('LEVEL')} />,
                accessor: 'level',
                Cell: ({ row }: any) => {
                    return <RenderTableCell value={row?.original?.skill_level?.name} />
                }
            },
            {
                Header: () => <RenderTableHeader value={''} />,
                Cell: ({ row }: any) => {
                    return (
                        <ActionMenu
                            data={row?.original}
                            refreshData={refreshData}
                            position={row?.index > data?.length / 2 ? 'bottom-0' : ''}
                            isTemplate={isTemplate}
                        />
                    );
                },
                accessor: 'action',
                width: 20,
            },
        ],
        [data, WorkspaceDetailInformation]
    );

    return (
        <Table
            data={data}
            columns={columns}
            initialState={initialState}
            emptyContent={''}
            emptyIcon={''}
        />
    );
};

export default CourseSkillsList;
