import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CourseSubtab, CourseTab, CourseViewType } from 'types/Courses.type';

interface CourseParams {
    tab?: CourseTab;
    subtab?: CourseSubtab;
    enrollmentId?: string | number;
    learningGroupId?: string | number;
    view?: CourseViewType;
    viewGradebook?: CourseViewType;
    [key: string]: any;
}

/**
 * Custom hook: redirect or new tab for course navigation
 * @returns Navigation utilities for course pages
 */
export const useUrlCourseNavigation = () => {
    const history = useHistory();
    const params: any = useParams();
    const location = useLocation();

    const { workspaceId, courseId } = useMemo(() => {
        let extractedWorkspaceId = params?.workspaceId;
        let extractedCourseId = params?.courseId;

        if (!extractedWorkspaceId || !extractedCourseId) {
            const pathParts = location.pathname.split('/');

            const workspaceIndex = pathParts.findIndex((part) => part === 'workspace');
            if (workspaceIndex !== -1 && workspaceIndex + 1 < pathParts.length) {
                extractedWorkspaceId = pathParts[workspaceIndex + 1];
            }

            const coursesIndex = pathParts.findIndex((part) => part === 'courses');
            if (coursesIndex !== -1 && coursesIndex + 1 < pathParts.length) {
                extractedCourseId = pathParts[coursesIndex + 1];
            }
        }

        return {
            workspaceId: extractedWorkspaceId,
            courseId: extractedCourseId,
        };
    }, [params, location.pathname]);

    /**
     * Build URL for course navigation
     * @param additionalParams - Parameters to include in the URL
     * @returns URL string
     */
    const buildUrl = useCallback(
        (additionalParams: CourseParams = {}) => {
            if (!workspaceId || !courseId) {
                console.warn('WorkspaceId or courseId not found in the current URL');
                return '';
            }

            const baseUrl = `/workspace/${workspaceId}/management/courses/${courseId}`;

            if (Object.keys(additionalParams).length === 0) {
                return baseUrl;
            }

            const queryString = Object.entries(additionalParams)
                .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
                .join('&');

            return `${baseUrl}?${queryString}`;
        },
        [workspaceId, courseId]
    );

    /**
     * Build full URL including domain
     * @param additionalParams - Parameters to include in the URL
     * @returns Full URL string
     */
    const buildFullUrl = useCallback(
        (additionalParams: CourseParams = {}) => {
            const domain = window.location.origin;
            const relativeUrl = buildUrl(additionalParams);

            if (!relativeUrl) {
                return '';
            }

            return `${domain}${relativeUrl}`;
        },
        [buildUrl]
    );

    /**
     * Redirect to the specified course URL
     * @param additionalParams - Parameters to include in the URL
     * @returns Whether redirection was successful
     */
    const redirectToUrl = useCallback(
        (additionalParams: CourseParams = {}) => {
            const url = buildUrl(additionalParams);

            if (!url) {
                return false;
            }

            history.push(url);
            return true;
        },
        [history, buildUrl]
    );

    /**
     * Open course URL in a new tab
     * @param additionalParams - Parameters to include in the URL
     * @returns Whether opening new tab was successful
     */
    const openInNewTab = useCallback(
        (additionalParams: CourseParams = {}) => {
            const url = buildFullUrl(additionalParams);

            if (!url) {
                return false;
            }

            window.open(url, '_blank', 'noopener,noreferrer');
            return true;
        },
        [buildFullUrl]
    );

    return {
        buildUrlCourse: buildUrl,
        buildFullUrlCourse: buildFullUrl,
        redirectToUrlCourse: redirectToUrl,
        openInNewTabCourse: openInNewTab,
        workspaceId,
        courseId,
    };
};
