import React, { useContext, useState } from 'react';
import Table, { RenderTableHeader, RenderTableCell } from 'components/V2/Table/TableCustom';
import { ListUser } from '_shared/components/User/User';
import dayjs from 'dayjs';
import { INVOICE_TYPE, PAYMENT_OPTION } from '../../constants';
import Action from './Action';
import { UserContext } from 'contexts/User/UserContext';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Status from '../components/Status';
import DialogComponent from 'components/Dialog/DialogComponent';
import { checkPermission } from 'utils/commonFun';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { PriceFormat } from '_shared/components/Price/PriceFormat';
import ModalAddDesciption from '../components/ModalAddDesciption';
import ModalAddNotes from '../components/ModalAddNotes';

interface AllInvoiceTableProps {
  workspaceId: string;
  dataTable: any;
  translator: Function;
  loading?: boolean;
  refreshData?: any;
  location?: string;
}

const AllInvoiceTable: React.FC<AllInvoiceTableProps> = ({ workspaceId, dataTable, translator, refreshData }) => {
  const {
    userState: { result },
  } = useContext(UserContext);
  const history = useHistory();
  const params: { id } = useParams()
  const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
  const { result: workspaceDetailInformation } = getWorkspaceDetailState;

  const search = useLocation()?.search;
  const invoiceType = new URLSearchParams(search).get('type');
  
  const columns = React.useMemo(
    () => [
      {
        Header: () => <RenderTableHeader value={translator('INVOICE_ID')} />,
        Cell: ({ row }) => {
          return (
            <RenderTableCell onClick={() => history?.push(`/workspace/${params?.id}/e-commerce/billing/${row?.values?.id}?type=single`)} className='text-primary-500 font-semibold' value={row.values.id} />
          );
        },
        accessor: 'id',
        width: 60,
      },
      {
        Header: () => <RenderTableHeader value={translator('LEARNER_AND_LEARNING_GROUP')} />,
        Cell: ({ row }) => {
          return (
            <div className="px-2">
              <ListUser
                id={'leaners'}
                users={[...(row?.values?.learners || []), ...(row?.original?.learning_groups || [])]?.map((item) => {
                  let link = `/workspace/${workspaceId}/management/users/${item?.id}?tab=information`;
                  if (item?.name) link = `/workspace/${workspaceId}/management/learning-groups/${item?.id}`;
                  return {
                    name: item?.display_name || item?.name,
                    avatar: item?.avatar_url,
                    link
                  }
                })}
              />
            </div>
          );
        },
        accessor: 'learners',
        width: 140,
      },
      {
        Header: () => <RenderTableHeader value={translator('PAYMENT_OPTION')} />,
        Cell: ({ row }) => {
          return <RenderTableCell value={PAYMENT_OPTION(translator)?.[row?.values?.payment_option]} />;
        },
        accessor: 'payment_option',
        width: 100,
      },
      {
        Header: () => <RenderTableHeader className='justify-end px-2' value={translator('AMOUNT')} />,
        Cell: ({ row }) => {
          return <PriceFormat className="text-right w-full text-xs font-normal text-gray-800" value={row?.original?.amount} currency={workspaceDetailInformation.default_currency} />;
        },
        accessor: 'amount',
        width: 100,
      },
      {
        Header: () => <RenderTableHeader value={translator('DUE_DATE_AND_TIME')} />,
        Cell: ({ row }) => {
          return <RenderTableCell value={row?.values?.due_at ? dayjs.utc(row?.values?.due_at)?.tz(result?.time_zone)?.format('DD/MM/YYYY・HH:mm') : '-'} />;
        },
        accessor: 'due_at',
        width: 120,
      },
      {
        Header: () => <RenderTableHeader value={translator('STATUS')} />,
        Cell: ({ row }) => {
          return (
            <div className='text-xs'>
              <Status invoiceId={row?.original?.id} status={row?.values?.display_status} translator={translator} isShowAction={checkPermission('invoice', 'U')}/>
            </div>
          );
        },
        accessor: 'display_status',
        width: 120,
      },
      {
        Header: () => <RenderTableHeader value={translator('DESCRIPTION')} />,
        Cell: ({ row }) => {
          return <RenderTableCell onClick={() => { setOpenModalDesciption(true); setInvoiceSelected(row?.original) }} className='text-primary-500' value={translator('DETAIL')} />;
        },
        accessor: 'note',
        width: 80,
      },
      invoiceType === INVOICE_TYPE.recurring ? {
        Header: () => <RenderTableHeader value={translator('NOTES')} />,
        Cell: ({ row }) => {
          return <RenderTableCell onClick={() => { setOpenModalNote(true); setInvoiceSelected(row?.original) }} className='text-primary-500' value={row?.original?.notes ? translator("EDIT_NOTES") : translator('ADD_NOTES')} />;
        },
        accessor: 'notes',
        width: 80,
      } : {Header: () => null, Cell: () => null, accessor: 'notes', width: 0},
      {
        Header: () => <RenderTableHeader value={''} />,
        Cell: ({ row }) => {
          return <Action data={row?.original} refreshData={refreshData} position={row?.index > dataTable?.length/2 ? 'bottom-0':''} />;
        },
        accessor: 'action',
        width: 60,
      } 
    ],
    []
  );

  const [isOpenModalDesciption, setOpenModalDesciption] = useState(false);
  const [isOpenModalNote, setOpenModalNote] = useState(false);
  const [invoiceSelected, setInvoiceSelected] = useState(undefined)

  return (
    <>
      <Table data={dataTable} columns={columns} emptyContent={translator('NO_TRANSACTIONS')} />
      <DialogComponent
          title={!invoiceSelected?.note ? translator("ADD_DESCRIPTION") : translator("EDIT_DESCIPTION")}
          onCloseModal={() => setOpenModalDesciption(false)}
          isOpen={isOpenModalDesciption}
          child={
              <ModalAddDesciption refreshData={refreshData} data={invoiceSelected} onClose={() => setOpenModalDesciption(false)} />
          }
      />
      <DialogComponent
          title={!invoiceSelected?.notes ? translator("ADD_NOTES") : translator("EDIT_NOTES")}
          onCloseModal={() => setOpenModalNote(false)}
          isOpen={isOpenModalNote}
          child={
              <ModalAddNotes refreshData={refreshData} data={invoiceSelected} onClose={() => setOpenModalNote(false)} isSingleInvoice={true} />
          }
      />
    </>
  );
};

export default AllInvoiceTable;
