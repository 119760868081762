import DialogComponent from 'components/Dialog/DialogComponent';
import { InputField } from 'components/InputForm/InputForm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import courseService from 'services/course.service';
import { formatOptionsInstructorNonEmail } from '../../CoursePeople/utils';
import { AsyncSelectField, SelectField } from 'components/Select';
import DatePickerInput from 'components/Management/components/Form/DatePicker';
import dayjs from 'dayjs';
import { SCHEDULE_TYPE_ENUM, SCHEDULE_TYPE_LIST } from 'components/Ecommerce/Billing/constants';
import { TimePickerField } from 'components/DatePicker';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { ErrorMessage } from '@hookform/error-message';
import uploadService from 'services/upload.service';
import UploadFile from '_shared/components/UploadFile/UploadFile';
import axios from 'axios';
import { REPORT_STATUS, SCHEDULED_TYPE } from '../constant';
import { ENROLLMENT_TYPE } from '../../CoursePeople/constants';

const accept_file_upload = ['.pdf', 'application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];

interface ICreateReport {
    isOpen: boolean;
    onClose: () => void;
    refreshData: () => void;
    dataValues?: any;
}

export default function ModalAddReport({ isOpen, onClose, refreshData, dataValues }: ICreateReport) {
    const { t: translator } = useTranslation();
    const params: any = useParams();
    const workspaceId = params?.id;
    const courseId = params?.courseId || dataValues?.course_id;
    const [file, setFile] = useState<any>(undefined);
    const [loading, setLoading] = useState(false);
    const [progressInfo, setProgressInfo] = useState({
        progress: 0,
        error: '',
    });
    const [reportData, setReportData] = useState(dataValues);

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty, isSubmitted },
        register,
        watch,
        setValue,
        getValues,
        reset,
    } = useForm({ mode: 'onChange' });

    const fetchLearnerAndLearningGroup = async (payload?: any) => {
        try {
            const res = await courseService.getEnrollments(workspaceId, courseId, payload);
            return {
                items: res?.data?.items || [],
                total: res?.data?.total || 0,
            };
        } catch (error) {
            Toastify.error();
        }
    };

    const handleLoadLearnerAndLearningGroup = async (inputValue, _options, additional) => {
        let payload: any = {
            page: additional.page,
            per_page: 20,
            sort_by: 'email',
            order: 'desc',
            statuses: JSON.stringify(["active", "enrolled"]),
            q: inputValue || null,
        };
        try {
            const { items, total } = await fetchLearnerAndLearningGroup(payload);
            let newOptions = [];
            items?.forEach((el: any) => {
                if (el.type === ENROLLMENT_TYPE.LEARNING_GROUP) {
                    newOptions.push(
                        {
                            id: el?.learning_group?.id,
                            display_name: el?.learning_group?.name,
                            value: el?.learning_group?.id,
                            avatar_url: el?.learning_group?.avatar_url,
                            type: el?.type,
                        }
                    );
                    if (el?.learners?.length > 0) {
                        el?.learners?.forEach((learnerItem) => {
                            newOptions.push({
                                id: learnerItem?.id,
                                display_name: learnerItem?.display_name,
                                value: learnerItem?.id,
                                avatar_url: learnerItem?.avatar_url,
                                type: 'individual'
                            })
                        })
                    }
                }
                else {
                    newOptions.push({
                        id: el?.learners?.[0]?.id,
                        display_name: el?.learners?.[0]?.display_name,
                        value: el?.learners?.[0]?.id,
                        avatar_url: el?.learners?.[0]?.avatar_url,
                        type: el?.type,
                    });
                }
            });
            return {
                options: newOptions,
                hasMore: additional?.page < total / 20,
                additional: {
                    page: additional?.page + 1,
                },
            };
        } catch (error) {
            Toastify.error();
        }
    };

    useEffect(() => {
        if (
            (watch('schedule_type')?.value === SCHEDULED_TYPE.now && reportData?.schedule_type === SCHEDULED_TYPE.schedule) ||
            (watch('schedule_type')?.value === SCHEDULED_TYPE.now && !reportData)
        ) {
            setValue('schedule_date', new Date());
            setValue('schedule_time', new Date());
        }
    }, [watch('schedule_type')]);

    const getUrlFile = (file, callback) => {
        uploadService.uploadFile(
            file,
            (result) => {
                const fileData = {
                    file_mime_type: file?.type,
                    file_key: result,
                };
                callback(fileData);
            },
            () => {
                setProgressInfo(() => ({
                    progress: 0,
                    error: 'Upload failed',
                }));
            },
            (event) => {
                setProgressInfo(() => ({
                    progress: Math.round((100 * event.loaded) / event.total),
                    error: '',
                }));
            }
        );
    };

    const getScheduleTime = (schedule_date, schedule_time) => {
        return `${dayjs(schedule_date).format('YYYY-MM-DD')} ${dayjs(schedule_time).format('HH:mm')} `;
    };

    const createCustomReport = (data) => (fileData) => {
        const schedule_at = getScheduleTime(data?.schedule_date, data?.schedule_time);
        const payload = {
            name: data?.name,
            ...fileData,
            schedule_at: dayjs(schedule_at)?.format(),
            schedule_type: data?.schedule_type?.value,
            learner_ids: data?.assignee?.filter((item) => item?.type === ENROLLMENT_TYPE.INDIVIDUAL)?.map((item) => item?.id),
            learning_group_ids: data?.assignee
                ?.filter((item) => item?.type === ENROLLMENT_TYPE.LEARNING_GROUP)
                ?.map((item) => item?.id),
        };
        if (payload?.schedule_type === SCHEDULED_TYPE.now) delete payload.schedule_at;
        setLoading(true);
        courseService
            .CreateCustomReport(workspaceId, courseId, payload)
            .then(() => {
                Toastify.success(translator('COURSES.CREATE_REPORT_SUCCESS'));
                refreshData();
                onClose();
                setFile(undefined);
                reset({});
            })
            .catch(() => {
                Toastify.error();
            })
            .finally(() => setLoading(false));
    };

    const updateCustomReport = (data) => (fileData) => {
        getScheduleTime(data?.schedule_date, data?.schedule_time);
        const schedule_at = getScheduleTime(data?.schedule_date, data?.schedule_time);
        let payload: any = {};
        if (data?.status === REPORT_STATUS.released) {
            payload = {
                name: data?.name,
            };
        }
        if (reportData?.status === REPORT_STATUS.scheduled) {
            payload = {
                name: data?.name,
                ...data,
                ...fileData,
                schedule_at: dayjs(schedule_at)?.format(),
                schedule_type: data?.schedule_type?.value,
                learner_ids: data?.assignee?.filter((item) => item?.type === ENROLLMENT_TYPE.INDIVIDUAL)?.map((item) => item?.id),
                learning_group_ids: data?.assignee
                    ?.filter((item) => item?.type === ENROLLMENT_TYPE.LEARNING_GROUP)
                    ?.map((item) => item?.id),
            };
            if (payload?.schedule_type === SCHEDULED_TYPE.now) {
                payload.schedule_at = null;
            }
        }
        setLoading(true);
        courseService
            .updateCustomReport(workspaceId, courseId, data?.id, payload)
            .then(() => {
                Toastify.success();
                refreshData();
                onClose();
                setFile(undefined);
                reset({});
            })
            .catch(() => {
                Toastify.error();
            })
            .finally(() => setLoading(false));
    };

    const onSubmit = (values) => {
        setLoading(true);
        if (reportData) {
            //not update report file
            if (file?.url) {
                const fileData = {
                    file_mime_type: reportData?.file_mime_type,
                    file_key: reportData?.file_key,
                };
                return updateCustomReport({ ...values, status: reportData?.status, id: reportData?.id })(fileData);
            }
            // has update report file
            return getUrlFile(file, (fileData) => updateCustomReport({ ...values, id: reportData?.id })(fileData));
        }
        return getUrlFile(file, (fileData) => createCustomReport(values)(fileData));
    };

    useEffect(() => {
        () => {
            reset({});
            setFile(undefined);
        };
    }, []);

    const getFileInfo = async (url, callback) => {
        const file = await axios({ url, method: 'HEAD' });
        callback(file);
    };

    useEffect(() => {
        const getCustomReportById = async () => {
            try {
                const res = await courseService.getCustomReportById(workspaceId, courseId, dataValues?.id);
                setReportData(res?.data);
            } catch (error) {

            }
        }
        if (courseId && dataValues?.id) {
            getCustomReportById();
        }
    }, [courseId, dataValues]);

    useEffect(() => {
        if (reportData) {
            getFileInfo(reportData?.file_url, (file) => {
                setFile({
                    type: file?.headers?.['content-type'],
                    url: reportData?.file_url,
                    name: reportData?.file_url.substring(reportData?.file_url.lastIndexOf('/') + 1) || 'Report',
                    lastModifiedDate: file?.headers?.['last-modified'],
                    size: +file?.headers?.['content-length'],
                });
                setProgressInfo({ progress: 100, error: undefined });
                const learners = reportData?.learners?.map((item) => ({ ...item, type: ENROLLMENT_TYPE.INDIVIDUAL, value: item?.id })) || [];
                const learningGroups =
                    reportData?.learning_groups?.map((item) => ({ ...item, type: ENROLLMENT_TYPE.LEARNING_GROUP, value: item?.id })) || [];
                reset({
                    schedule_date: new Date(reportData?.released_at),
                    schedule_time: new Date(reportData?.released_at),
                    schedule_type: {
                        label: reportData.schedule_type === SCHEDULED_TYPE.schedule ? SCHEDULED_TYPE.schedule : SCHEDULED_TYPE.now,
                        value: reportData.schedule_type,
                    },
                    name: reportData?.name,
                    assignee: [...learners, ...learningGroups],
                });
            });
        }
    }, [reportData]);

    return (
        <DialogComponent
            isOpen={isOpen}
            onCloseModal={onClose}
            title={reportData ? translator('COURSES.EDIT_REPORT') : translator('COURSES.ADD_REPORT')}
            styles="max-w-lg"
            child={
                <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    {dataValues?.course?.title && <InputField
                        label={translator('COURSES.COURSE_NAME')}
                        name="course_name"
                        disabled={true}
                        defaultValue={dataValues?.course?.title}
                    />}
                    <div className="mt-5">
                        <InputField
                            label={translator('COURSES.REPORT_NAME')}
                            placeholder={translator('COURSES.ENTER_REPORT_NAME')}
                            name="name"
                            errors={errors}
                            isRequired
                            register={register('name', {
                                required: {
                                    value: true,
                                    message: translator('FORM_CONST.REQUIRED_FIELD'),
                                },
                                maxLength: {
                                    value: 256,
                                    message: translator('FORM_CONST.MAX_LENGTH_TEXT', { maxLength: 256 }),
                                },
                            })}
                        />
                    </div>

                    <div className="mt-5">
                        <UploadFile
                            onReload={() => onSubmit(getValues())}
                            file={file}
                            setFile={setFile}
                            progressInfo={progressInfo}
                            setProgressInfo={setProgressInfo}
                            accept_file_upload={accept_file_upload}
                            status={reportData?.status}
                        />
                        {isSubmitted && (progressInfo?.error || !file) && (
                            <p className="text-red-500 text-xs mt-2 font-semibold">{translator('FORM_CONST.REQUIRED_FIELD')}</p>
                        )}
                    </div>

                    <AsyncSelectField
                        control={control}
                        label={translator('COURSES.LEARNER_OR_LEARNING_GROUP')}
                        name="assignee"
                        placeholder={translator('COURSES.SELECT_LEARNER_OR_LEARNING_GROUP')}
                        hideSelectedOptions
                        closeMenuOnSelect={true}
                        errors={errors}
                        loadOptions={handleLoadLearnerAndLearningGroup}
                        formatOptionLabelCustom={formatOptionsInstructorNonEmail}
                        isMulti
                        className="mt-5"
                        isRequired
                        isDisabled={reportData?.status === REPORT_STATUS.released}
                    />
                    <div className="grid grid-cols-4 gap-5 mt-5">
                        <SelectField
                            control={control}
                            label={translator('COURSES.RELEASE_TO_LEARNER_PORTAL')}
                            placeholder={translator('COURSES.SELECT_RELEASE_TYPE')}
                            className="col-span-2"
                            name="schedule_type"
                            isRequired
                            errors={errors}
                            options={SCHEDULE_TYPE_LIST(translator)}
                            isDisabled={reportData?.status === 'released'}
                        />
                        <div className="col-span-2 flex gap-3 h-full">
                            <div>
                                <label htmlFor="start-date" className="text-gray-800 text-xs font-semibold block mb-1">
                                    {translator('DATE')}
                                    <span className="text-red-500"> *</span>
                                </label>
                                <DatePickerInput
                                    control={control}
                                    classnames="!h-[38px]"
                                    name="schedule_date"
                                    placeholderText={translator('SELECT_DATE')}
                                    minDate={new Date()}
                                    isRequired
                                    disabled={
                                        watch('schedule_type')?.value === SCHEDULED_TYPE.now ||
                                        reportData?.status === REPORT_STATUS.released
                                    }
                                    positionIcon="right"
                                />
                                {/* @ts-ignore */}
                                <ErrorMessage
                                    className="text-red-500 text-xs mt-2 font-semibold"
                                    errors={errors}
                                    name="schedule_date"
                                    as="div"
                                />
                            </div>
                            <div>
                                <TimePickerField
                                    control={control}
                                    label={translator('TIME')}
                                    name="schedule_time"
                                    placeholder={translator('SELECT_TIME')}
                                    disabled={
                                        watch('schedule_type')?.value === SCHEDULE_TYPE_ENUM.now ||
                                        reportData?.status === REPORT_STATUS.released
                                    }
                                    isRequired
                                    isClearable={false}
                                    positionIcon="right"
                                    disableInput={true}
                                    timeDurations={5}
                                    filterTimeFunc={(time) => {
                                        const hour = dayjs(time).get('hour');
                                        const mins = dayjs(time).get('minute');
                                        const timeInDay = dayjs(watch('schedule_date')).hour(hour).minute(mins);
                                        if (watch('schedule_date')) {
                                            return dayjs(timeInDay)?.isAfter(dayjs());
                                        }
                                    }}
                                />
                                {/* @ts-ignore */}
                                <ErrorMessage
                                    className="text-red-500 text-xs mt-2 font-semibold"
                                    errors={errors}
                                    name="schedule_time"
                                    as="div"
                                />
                            </div>
                        </div>
                    </div>

                    {watch('schedule_type')?.value === SCHEDULED_TYPE.schedule &&
                        reportData?.status === REPORT_STATUS.scheduled &&
                        watch('schedule_date') &&
                        watch('schedule_time') &&
                        dayjs(getScheduleTime(watch('schedule_date'), watch('schedule_time')))?.isBefore(new Date()) && (
                            <p className="text-red-500 text-xs mt-2 font-semibold">{translator('VALIDATE_TIME_IN_THE_PAST')}</p>
                        )}

                    <div className="mt-5 flex gap-5 justify-center">
                        <ButtonOutline type="button" onClick={onClose}>
                            {translator('CANCEL')}
                        </ButtonOutline>
                        <ButtonPrimary disabled={file?.error || !file || progressInfo?.error || loading} type="submit">
                            {reportData ? translator('SAVE') : translator('ADD')}
                        </ButtonPrimary>
                    </div>
                </form>
            }
        />
    );
}
