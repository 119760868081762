import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PanelSetting } from '../PanelSetting/PanelSetting';
import { useForm } from 'react-hook-form';
import { InputFile } from '_shared/components/Form/InputFile/InputFile';
import { typeOption } from 'components/Academics/LearningPath/LearningPathDetail/learningPath.consts';
import { InputField, TextareaField } from 'components/InputForm/InputForm';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { ButtonOutline, ButtonPrimary, Toastify } from '_shared';
import { useParams } from 'react-router-dom';
import { courseUpdate, setCourse } from 'store/features/courseSlice';
import { DeleteIcon, VideoUpload } from 'assets/icon';
import uploadService from 'services/upload.service';
import './CourseInformation.scss';
import { checkPermission } from 'utils/commonFun';
import { ACCEPT_FILE_NONE_MIME_TYPE, VIDEO_TYPES } from 'constant/form.const';
import { GetWorkspaceContext } from 'contexts/Workspace/WorkspaceContext';
import { defaultRole, roleType } from 'components/Management/RolesPermissions/RolesPermissionsContants';
import { VideoPreview } from '_shared/components/DocumentPreview/VideoPreview';
import DialogComponent from 'components/Dialog/DialogComponent';
import ChangeVideoIcon from 'assets/icon/ChangeVideoIcon';
import PlayVideoIcon from 'assets/icon/PlayVideoIcon';
import Spinner from 'components/Spinner';
import workspaceService from 'services/workspace.service';
import { Switch } from '@headlessui/react';
import Properties from '_shared/components/PropertyTypeComponent';
import debounce from 'lodash/debounce';
import moment from 'moment';
import propertyService from 'services/property.service';
import { propertyType } from 'components/WorkspaceSetting/Property/components/propertyCommon';
import { SelectField } from 'components/Select';
import { CourseSkills } from '../CourseSkills/CourseSkills';

const formatPropertiesObj = (properties) => {
    const propertiesArr = [];
    const pushProperties = (properties, parent) => {
        properties.forEach((child: any) => {
            const name = `${child?.name?.split(' ')?.join('_')?.replace(".", "_")?.toLowerCase()}`;
            propertiesArr.push({
                name,
                property_id: child?.id,
                property_type: child?.property_type,
                is_visible: child?.is_visible,
                label: child?.name,
                parent: { ...parent }

            });
            if (child?.children) {
                pushProperties(child?.children, {
                    name: name,
                    is_visible: child?.is_visible,
                    property_type: child?.property_type,
                    property_id: child?.id,
                });
            }
        });
    };
    pushProperties(properties, '');
    return propertiesArr;
};

export const CourseInformation = () => {
    const params: { id: string; courseId: string } = useParams();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { data } = useAppSelector((state) => state.course);
    const refUploadVideo = useRef(null);
    const [isOpenModalViewVideo, setOpenModalViewVideo] = useState(false);
    const [isOpenModalRemoveVideo, setOpenModalRemoveVideo] = useState(false);
    const [loadingVideo, setLoadingVideo] = useState(false);
    const [properties, setProperties] = useState<any>([]);
    const [isInitiated, setIsInitiated] = useState(false);
    const [defaultProperties, setDefaultProperties] = useState<any>(undefined);

    const translatorlearning = (str: string, newKey?: boolean, opt?: any) => {
        return newKey ? t(str, opt) : t(`LEARNING_PATH.LEARNING_PATH_DETAIL.${str}`, opt);
    };

    const [hasSetting, setHasSetting] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue,
        getValues,
        reset,
        watch,
    } = useForm({
        mode: 'onChange',
        defaultValues: useMemo(() => {
            return {
                title: data?.title,
                short_description: data?.short_description,
                type: null,
                level: [],
                subject: [],
                age: [],
                language_delivery: [],
                duration: [],
                tags: [],
                id: null,
                properties: {},
            };
        }, [data]),
    });

    const { getWorkspaceDetailState } = useContext(GetWorkspaceContext);
    const { result: WorkspaceDetailInformation } = getWorkspaceDetailState;

    const checkImageUpload = useCallback((file: any) => {
        const mimeType = VIDEO_TYPES;
        const fileSize = file?.size || 0;
        let fileExtenion = file?.type || '';
        const fileName = file?.name;
        const fileExtension = fileName?.split('.').pop();

        for (const mimeType in ACCEPT_FILE_NONE_MIME_TYPE) {
            if (ACCEPT_FILE_NONE_MIME_TYPE[mimeType].includes(fileExtension)) {
                fileExtenion = mimeType;
                break;
            }
        }
        const fileModify = new File([file], file.name, {
            type: fileExtenion,
        });

        if (!mimeType.includes(fileExtenion) || +(fileSize / (1024 * 1024)) > 100) {
            Toastify.error(t('COURSES.WARNING_VIDEO'));
        } else {
            setLoadingVideo(true);
            uploadService.uploadFile(
                fileModify,
                async (path: string) => {
                    setTimeout(() => {
                        dispatch(
                            courseUpdate({
                                workspaceId: params?.id,
                                id: params?.courseId,
                                video: path,
                            })
                        );
                        setLoadingVideo(false);
                    }, 10000);
                },
                () => { }
            );
        }
    }, []);

    const initFormValue = async () => {
        let typeSelect = [];
        const paramData = { page: 1, object_type: 'course', skip_pagination: true, sort_by: 'created_at', order: 'asc' };
        const responseProperties = await workspaceService.getListPropertiesService(params?.id, paramData);
        const properties = formatPropertiesObj(responseProperties?.data?.items);
        setProperties(properties);
        if (data?.type?.length > 0) {
            typeSelect = typeOption(translatorlearning).filter((item) => data?.type?.find((value) => value == item?.value));
        }
        const propertiesData = {};
        properties?.forEach((item: any) => {
            const property = data?.properties_value?.find(
                (property: any) => property?.property_id === item?.property_id || property?.id === item?.property_id
            );
            const value = property?.value;
            propertiesData[item?.name] = {
                value: value,
                property_type: item?.property_type,
                property_id: item?.property_id,
            };
            if (
                item?.property_type === propertyType.multiple_selection?.value ||
                item?.property_type === propertyType.single_selection?.value
            ) {
                propertiesData[item?.name] = {
                    value: value?.map((propertyValue) => ({ name: propertyValue?.label, value: propertyValue?.id, children: [] })),
                    property_type: item?.property_type,
                    property_id: item?.property_id,
                };
            }
            if (item?.property_type === propertyType.date_picker?.value) {
                propertiesData[item?.name] = {
                    value: value ? new Date(value) : null,
                    property_type: item?.property_type,
                    property_id: item?.property_id,
                };
            }
        });
        const courseValue = {
            ...data,
            type: typeSelect,
            properties: propertiesData,
        };
        reset(courseValue);
        setDefaultProperties(propertiesData);
        setIsInitiated(true);
    };

    useEffect(() => {
        if (data?.title && !isInitiated) {
            initFormValue();
        }
    }, [data, t, isInitiated]);

    const updateCourseProperties = useCallback(
        debounce(() => {
            handleSubmit(() => {
                const properties = watch('properties');
                const propertiesData = [];
                const propertiesValue = []
                for (const key in properties) {
                    if (properties.hasOwnProperty(key)) {
                        const element = properties[key];
                        if (element?.property_type === propertyType.date_picker?.value) {
                            const value = {
                                property_id: element?.property_id,
                                value: element?.value ? moment(element?.value).format('YYYY-MM-DD') : null,
                            }
                            propertiesData.push(value);
                            propertiesValue.push(value)
                        } else if (
                            element?.property_type === propertyType.multiple_selection?.value ||
                            element?.property_type === propertyType.single_selection?.value
                        ) {
                            const value = element?.value?.map((item) => item?.value);
                            propertiesData.push({
                                property_id: element?.property_id,
                                value: value?.length > 0 ? value : null,
                            });
                            propertiesValue.push({
                                property_id: element?.property_id,
                                value: value?.length > 0 ? element?.value?.map((item) => ({ label: item?.name, id: item?.value })) : null,
                            });
                        } else {
                            propertiesData.push({
                                property_id: element?.property_id,
                                value: element?.value || null,
                            });
                            propertiesValue.push({
                                property_id: element?.property_id,
                                value: element?.value || null,
                            });
                        }
                    }
                }
                if (propertiesData?.length > 0) {
                    propertyService.updateCoursePropertyValue(params?.id, params?.courseId, { values: propertiesData });
                    dispatch(
                        setCourse({
                            properties_value: Object.values(propertiesValue),
                        })
                    );
                }
            })();
        }, 1000),
        [params?.courseId]
    );

    const updateCourse = useCallback(
        debounce((value, payload) => {
            dispatch(
                courseUpdate({
                    ...payload,
                    type: value?.type?.map((item) => item.value),
                    title: value?.title,
                    short_description: value?.short_description,
                })
            );
        }, 1000),
        [params?.courseId]
    );

    useEffect(() => {
        if (params?.courseId !== 'new' && isInitiated) {
            const subscription = watch((value, { name }) => {
                const payload = {
                    workspaceId: params?.id,
                    id: params?.courseId,
                };
                if (name === 'type' && value?.type?.length > 0 || name === 'title' || name === 'short_description') {
                    updateCourse(value, payload)
                }
                if (name?.includes('properties')) {
                    updateCourseProperties()
                }
            });
            return () => subscription.unsubscribe();
        }
    }, [watch(), params?.courseId]);

    const onClickUpload = () => {
        refUploadVideo.current?.click();
    };
    const [isViewAllProperties, setIsViewAllProperties] = useState(false);

    return (
        <div className="courseInformation">
            <div className="courseBuilder-title flex justify-between">
                {t('COURSES.INFORMATION')}
                <div className="flex gap-1 items-center">
                    <p className="text-gray-800 text-sm">{t('View All Properties')}</p>
                    <Switch
                        checked={isViewAllProperties}
                        onChange={setIsViewAllProperties}
                        className={`group inline-flex h-6 w-11 items-center rounded-full bg-gray-400 transition  ${isViewAllProperties ? 'bg-primary-500' : ''
                            }`}
                    >
                        <span
                            className={`w-4 h-4 translate-x-1 rounded-full bg-white transition ${isViewAllProperties ? 'translate-x-6' : ''
                                }`}
                        />
                    </Switch>
                </div>
            </div>
            {!hasSetting &&
                WorkspaceDetailInformation?.membership?.user_role?.name != defaultRole.instructor &&
                WorkspaceDetailInformation?.membership?.user_role?.name != roleType.default ? (
                <PanelSetting onClose={() => setHasSetting(true)} />
            ) : null}
            <form className="max-w-[608px]">
                <div className="courseInformation-item">
                    <label className="courseInformation-label">{t('COURSES.INTRODUCE_VIDEO')}</label>
                    <div className="group relative overflow-hidden">
                        {/* @ts-ignore */}
                        <InputFile
                            onChange={checkImageUpload}
                            imgDefault={data?.video}
                            className="h-[240px]"
                            icon={<VideoUpload />}
                            textHelp={translatorlearning('COURSES.MESSAGE_UPLOAD_VIDEO', true)}
                            hasVideo={true}
                            isDisable={!checkPermission('courseInformation', 'U')}
                            accept="video/*"
                            ref={refUploadVideo}
                        />
                        {data?.video && (
                            <div className="bg-[#0000004d] z-50 hidden group-hover:flex absolute inset-0 justify-center flex-col items-center text-white text-sm font-semibold gap-5">
                                <div className="py-1 px-2 cursor-pointer flex items-center gap-2" onClick={onClickUpload}>
                                    {' '}
                                    <ChangeVideoIcon /> {t('Change Video')}
                                </div>
                                <div
                                    className="py-1 px-2 cursor-pointer flex items-center gap-2"
                                    onClick={() => setOpenModalViewVideo(true)}
                                >
                                    <PlayVideoIcon /> {t('PREVIEW')}
                                </div>
                                <div
                                    className="py-1 px-2 cursor-pointer flex items-center gap-2"
                                    onClick={() => setOpenModalRemoveVideo(true)}
                                >
                                    <DeleteIcon width="20px" height="20px" />
                                    {t('REMOVE')}
                                </div>
                            </div>
                        )}
                        {loadingVideo && (
                            <div className="bg-[#0000004d] z-50 absolute inset-0 flex justify-center items-center">
                                <Spinner style="text-white" />
                            </div>
                        )}
                    </div>
                </div>
                <div className="courseInformation-item">
                    <InputField
                        label={t('COURSES.COURSE_NAME')}
                        name="title"
                        isRequired
                        maxLength={255}
                        placeholder={t('COURSES.COURSE_NAME')}
                        register={register('title', {
                            required: t('FORM_CONST.REQUIRED_FIELD'),
                            validate: {
                                shouldNotContainSpace: (value) => {
                                    return value?.trim()?.length ? true : `${t('FORM_CONST.REQUIRED_FIELD')}`;
                                },
                            },
                        })}
                        errors={errors}
                        classNameInput="py-2"
                        disabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <SelectField
                        control={control}
                        isRequired
                        label={t('COURSES.COURSE_TYPE')}
                        name="type"
                        placeholder={t('COURSES.SELECT_COURSE_TYPE')}
                        isSearchable={false}
                        isMulti={true}
                        options={typeOption(translatorlearning)}
                        customStyles={{
                            control: (base) => {
                                return { ...base, minHeight: '0px', height: 'auto' };
                            },
                            valueContainer: (base) => {
                                return { ...base, paddingTop: 0, fontSize: 13, fontWeight: 600 };
                            },
                            indicatorsContainer: (base) => {
                                return { ...base, padding: '0px 8px', height: '30px' };
                            },
                        }}
                        errors={errors}
                        isDisabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>
                <div className="courseInformation-item">
                    <TextareaField
                        label={t('COURSES.DESCRIPTION')}
                        name="description"
                        className="col-span-4"
                        placeholder={t('COURSES.ENTER_DESCRIPTION')}
                        register={register('short_description')}
                        textLength={getValues('short_description')?.length?.toString()}
                        maxLength={1000}
                        isCountter
                        disabled={!checkPermission('courseInformation', 'U')}
                    />
                </div>

                <div>
                    <Properties
                        formState={{ errors }}
                        control={control}
                        register={register}
                        watch={watch}
                        setValue={setValue}
                        isViewAllProperties={isViewAllProperties}
                        properties={properties}
                        isInitiated={isInitiated}
                        defaultProperties={defaultProperties}
                    />
                </div>
                <CourseSkills />
            </form>
            <DialogComponent
                title={'Remove Video'}
                isOpen={isOpenModalRemoveVideo}
                onCloseModal={() => setOpenModalRemoveVideo(false)}
                child={
                    <div className="mt-5">
                        <div className="text-left text-sm text-gray-800">
                            <span>{t('Are you sure you want to remove this video?')}</span>
                        </div>
                        <div className="flex justify-center gap-5 mt-6">
                            <ButtonOutline type="button" size="M" onClick={() => setOpenModalRemoveVideo(false)}>
                                {t('CANCEL')}
                            </ButtonOutline>
                            <ButtonPrimary
                                className="buttonPrimary-danger"
                                type="button"
                                size="M"
                                onClick={() => {
                                    dispatch(
                                        courseUpdate({
                                            workspaceId: params?.id,
                                            id: params?.courseId,
                                            video: null,
                                        })
                                    );
                                    setOpenModalRemoveVideo(false);
                                }}
                            >
                                {t('REMOVE')}
                            </ButtonPrimary>
                        </div>
                    </div>
                }
            />
            {isOpenModalViewVideo && (
                <VideoPreview
                    data={{ file_url: data?.video, name: 'video.mp4' }}
                    isOpen={isOpenModalViewVideo}
                    closeModal={() => setOpenModalViewVideo(false)}
                />
            )}
        </div>
    );
};
