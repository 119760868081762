import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { classNames } from 'utils/commonFun';
import ReactTooltip from 'react-tooltip';

type ActionsButtonProps = {
    menus: any[];
    disable?: boolean;
    styleOptions?: string;
    onClick?: () => void;
    className?: any;
    classNameIcon?: any;
    contentTip?: string;
    position?: string;
};

const index: FC<ActionsButtonProps> = ({ menus, disable, styleOptions, onClick, className, classNameIcon, position }) => {
    const isCheckMenu = () => {
        const isCheck = menus?.find((i) => !i?.isDisabled);
        return !isCheck ? true : false;
    };
    const menusFilterHidden = () => {
        const menusFilter = menus?.filter((i) => !i?.hide);
        return menusFilter;
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={classNames(
                            'w-8 h-8 mr-1 p-2 rounded-full group outline-none',
                            disable || !menusFilterHidden()?.length
                                ? 'cursor-not-allowed pointer-events-none opacity-30'
                                : 'hover:bg-ooolab_light_blue_0 active:outline-none hover:text-primary-500',

                            className
                        )}
                        onClick={() => onClick?.()}
                    >
                        <DotsVerticalIcon className={`w-4 h-4 ${classNameIcon}`} />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        show={!disable && open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className={classNames(
                                'z-70 absolute right-0 mt-2 w-[200px] divide-y divide-gray-100 rounded-lg bg-white shadow-lg  ring-black ring-opacity-5 focus:outline-none overflow-hidden',
                                styleOptions,
                                position ? position : 'origin-top-right'
                            )}
                        >

                            <div className="">
                                {menus.map((item: any, index) =>
                                    !item?.hide ? (
                                        <Menu.Item key={index}>
                                            {({ active, close }) => (
                                                <>
                                                    <button
                                                        type="button"
                                                        className={classNames(
                                                            'w-full flex items-center space-x-2  px-4 py-2 text-ooolab_dark_1 text-sm disabled:cursor-not-allowed',
                                                            active ? 'bg-ooolab_blue_0' : '',
                                                            item.isDisabled ? 'hover:bg-ooolab_blue_0 opacity-30' : '',
                                                            item?.class
                                                        )}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            item?.action?.();
                                                            close?.();
                                                        }}
                                                        data-for={`user-${index}`}
                                                        data-tip={item?.contentTip}
                                                        disabled={item.isDisabled}
                                                    >
                                                        {item?.icon}
                                                        <span>{item?.label}</span>
                                                    </button>
                                                    {

                                                        item?.isDisabled && item?.contentTip ? (
                                                            /*@ts-ignore*/
                                                            <ReactTooltip
                                                                place="left"
                                                                type="light"
                                                                effect="float"
                                                                className='opacity-100 shadow-medium'
                                                                id={`user-${index}`}
                                                                getContent={(content) => (
                                                                    <div className="w-[240px] text-xs font-normal text-center">
                                                                        {content}
                                                                    </div>
                                                                )}
                                                            />
                                                        ) : null
                                                    }
                                                </>
                                            )}
                                        </Menu.Item>
                                    ) : null
                                )}
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default index;
